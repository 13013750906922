<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка роли</p>
    </div>
    <tabs>
      <tab name="Общая информация" :selected="true">
        <div>
          <div class="row">
            <div class="col-12" v-if="!item.id">
              <div class="form-group required">
                <ComponentInput
                  v-model="item.name"
                  label="Внутреннее название"
                  required
                  maxLength="50"
                  placeholder="Введите название роли"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group required">
                <ComponentInput
                  v-model="item.title"
                  label="Название"
                  required
                  maxLength="250"
                  placeholder="Введите название роли"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <ComponentInput v-model="item.description" label="Описание" placeholder="Введите описание" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group required">
                <ComponentInput
                  v-model="item.sessionLifetime"
                  type="integer"
                  label="Время жизни сессии пользователя, мин."
                  required
                />
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="checkboxInput">
              <input type="checkbox" v-model="item.isActive" :disabled="item.id === 1" id="chActive" />
              <label for="chActive">Запись активна</label>
            </div>
          </div>
        </div>

        <div>
          <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
          <ButtonStock title="Отмена" v-on:click="close" />
        </div>
      </tab>
      <tab name="Фильтры по умолчанию" :selected="false">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Маршрут</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="page.items?.length && !isLoading">
              <tr v-for="item in page.items" :key="item.id">
                <td>
                  {{ item.route }}
                </td>
                <td>
                  <a href="javascript:" @click.prevent="onRouteClick(item.route)">Перейти</a>
                </td>
                <td>
                  <IconComponent @click="deleteFilterItem(item.id)" name="delete" />
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="5">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="5">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
        <div>
          <ButtonStock title="Закрыть" v-on:click="close" />
        </div>
      </tab>
      <tab name="Настройка виджетов" :selected="false">
        <table class="widget-table w-100 mb-16">
          <tbody v-if="widgets?.length > 0 && !loadingWidgets">
            <tr v-for="item in widgets" :key="item.id">
              <td class="p-1 w-50">{{ item.name }}</td>
              <td class="checkbox-container w-50">
                <ComponentInput
                  :modelValue="item.roleCanView.some((x) => x == id)"
                  @update:modelValue="onUpdateRole(item, $event)"
                  type="checkbox"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <ButtonStock class="mr-8" title="Сохранить" v-on:click="updateWidgets" />
          <ButtonStock title="Отмена" v-on:click="close" />
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_WIDGET from '@/modules/home/api/widget';

  import API from '../api/roles';

  export default {
    name: 'RoleCard',
    components: {
      ComponentInput,
      ButtonStock,
      tabs,
      tab,
      IconComponent,
    },
    mixins: [filtermanager],
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        page: {},
        widgets: [],
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          roleId: this.$route.params.id,
        },
        isLoading: false,
        roleId: null,
        loadingWidgets: false,
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadWidgets() {
        this.loadingWidgets = true;
        var profile = this.$store.state.auth?.oidc?.profile;
        if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));

        this.roleId = profile.roleIds[0];

        API_WIDGET.getWidgetTypes().then((response) => {
          this.widgets = response.data;
          this.loadingWidgets = false;
        });
      },
      onUpdateRole(item, val) {
        if (val == true) item.roleCanView = [...item.roleCanView, this.id];
        else item.roleCanView = item.roleCanView.filter((x) => x != this.id);
        console.log(item);
      },
      loadFilters(append) {
        this.isLoading = !append;
        API.searchFilters(this.request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadFilters(addToExist);
      },
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
            if (this.item.isGroupRole)
              this.$router.push({ name: 'RoleGroupCard', params: { id: this.item.id, action: 'edit' } });
          });
          this.loadFilters(false);
          this.loadWidgets();
        } else {
          this.item = {
            isActive: true,
            organizationId: 1,
          };
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.update(this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            console.log(this.item);
            if (this.id > 0) {
              this.loadCard();
            } else {
              this.$router.push({ name: 'Roles' });
            }
          })
          .catch((error) => {
            console.error(error?.response?.data);
            var mess = error?.response?.data ? error?.response?.data : Constants.commonError;
            mess = mess
              .replace('Role name', 'Роль с внутренним названием')
              .replace('is already taken', 'уже используется');
            Constants.alert.fire('Сохранение', mess, 'error');
          });
      },
      close() {
        this.$router.back();
      },
      onRouteClick(route) {
        this.$router.push({ path: route });
      },
      deleteFilterItem(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.deleteFilter(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadFilters(false);
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      updateWidgets() {
        API_WIDGET.updateWidgetTypes(this.widgets)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          })
          .catch((error) => {
            Constants.alert.fire(
              'Сохранение',
              error.response && error.response.data ? error.response.data : 'Настройки виджетов не сохранены',
              'error',
            );
          });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .checkbox-container::v-deep {
    input[type='checkbox'] {
      position: relative !important;
      width: 20px !important;
      height: 20px !important;
      left: 0 !important;
      top: 0 !important;
      z-index: 0 !important;
      margin: 0 auto;
    }
  }
  .widget-table {
    td {
      min-height: 32px;
      height: 32px;
    }
  }
</style>
