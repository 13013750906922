import axios from 'axios';

export default {
  getLibrary: function () {
    return axios.get('/api/library/GetFoldersTree');
  },
  updateFolder: function (data) {
    if (data.id > 0) return axios.put(`/api/library/updateFolder?id=${data.id}`, data);
    else return axios.post(`/api/library/createFolder`, data);
  },
  updateDocument: function (data) {
    if (data.id > 0) return axios.put(`/api/library/updateDocument`, data);
    else return axios.post(`/api/library/createDocument`, data);
  },
  downloadZippedFiles(id) {
    return axios.get(`/api/library/DownloadFolderZip?id=${id}`, { responseType: 'blob' });
  },
  deleteFolder: function (id) {
    return axios.delete(`/api/library/deleteFolder?id=${id}`);
  },
  deleteDocument: function (id) {
    return axios.delete(`/api/library/deleteDocument?id=${id}`);
  },
  logFileDownload: function (id) {
    return axios.post(`/api/library/logDocumentDownload?id=${id}`);
  },
  getDocumentHistory: function (id) {
    return axios.get(`/api/library/documentHistory?id=${id}`);
  },
  getFolderHistory: function (id) {
    return axios.get(`/api/library/folderHistory?id=${id}`);
  },
};
