export const routes = [
  {
    path: '/mpk/account/:id?',
    name: 'AccountCochair',
    component: () => import('../views/AccountCochair'),
  },
  {
    path: '/mpk/participants',
    name: 'Participants',
    component: () => import('../views/Participants'),
  },
  {
    path: '/mpk/participants/:action/:id',
    name: 'ParticipantCard',
    component: () => import('../views/ParticipantCard'),
  },
  {
    path: '/mpk/commissions',
    name: 'Commissions',
    component: () => import('../views/Commissions'),
  },
  {
    path: '/mpk/commissions/:type/:action/:id',
    name: 'CommissionCard',
    component: () => import('../views/CommissionCard'),
  },
  {
    path: '/mpk/events',
    name: 'Events',
    component: () => import('../views/Events'),
  },
  {
    path: '/mpk/events/:action/:id',
    name: 'EventCard',
    component: () => import('../views/EventCard'),
  },
  {
    path: '/mpk/events/add',
    name: 'EventCardAdd',
    component: () => import('../views/EventCardAdd'),
  },
  {
    path: '/preparation/monitoring',
    name: 'AssignmentMonitoring',
    component: () => import('../views/AssignmentMonitoring'),
  },
  {
    path: '/mpk/assignments',
    name: 'Assignments',
    component: () => import('../views/Assignments'),
  },
  {
    path: '/mpk/assignments/:action/:id',
    name: 'AssignmentCard',
    component: () => import('../views/AssignmentCard'),
  },
  {
    path: '/mpk/events/request/:id',
    name: 'EventRequestForm',
    component: () => import('../views/EventRequestForm'),
  },
  {
    path: '/Preparation/Requests',
    name: 'EventRequests',
    component: () => import('../views/EventRequests'),
  },
  {
    path: '/preparation/requests/view/:id',
    name: 'EventRequestView',
    component: () => import('../views/EventRequestView'),
  },
  {
    path: '/business-tips',
    name: 'business-tips',
    component: () => import('../views/BusinessTips'),
  },
  {
    path: '/business-tips/:id/:type',
    name: 'card-business-tips',
    component: () => import('../views/CardBusinessTips'),
  },
];
