export default {
  methods: {
    truncate(str, maxLen) {
      if (str && str.length > maxLen) {
        return str.substr(0, maxLen) + '...';
      }
      return str;
    },
    makeChartLabel(str, maxLen) {
      if (str.length > maxLen) {
        let text = str;
        let result = [];
        while (text.length > maxLen) {
          var idx = text.lastIndexOf(' ', maxLen);
          if (idx > -1) {
            result.push(text.substr(0, idx));
            text = text.substr(idx);
          }
        }
        result.push(text);
        return result;
      }
      return str;
    },
    /**
     * метод форматирования числа
     * @param {*} value - значение
     * @param {*} decimalPlaces - кол-во знаков после запятой
     * @param {*} groupSep - знак для разделения разрядов числа (1 000)
     * @param {*} decimalSep - разделительный знак числа
     * @param {*} removeTrailingZeros - флаг для удаления нуля на границе числа
     * @returns
     */
    formatNumber(value, decimalPlaces = 2, groupSep = ' ', decimalSep = ',', removeTrailingZeros = false) {
      if (typeof value === 'undefined' || value === null) return '';
      const parts = removeTrailingZeros
        ? String(parseFloat(value.toFixed(decimalPlaces))).split('.')
        : value.toFixed(decimalPlaces).split('.');
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, groupSep) + (decimalPart ? decimalSep + decimalPart : '');
    },
  },
};
