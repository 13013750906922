<template>
  <!-- Тестовая страница для регистра мпк -->
  <div class="container-page">
    <div class="earn-apparatus">
      <p class="earn-apparatus__title title-inner">Поручения Загранаппарат</p>

      <div class="earn-apparatus__header-container">
        <div class="earn-apparatus__header-countryBlock">
          <p class="country">Страна</p>
          <Multiselect
            v-model="filter.countryId"
            :options="computedCountryList"
            valueProp="id"
            trackBy="text"
            label="text"
            placeholder="Выберите страну"
            searchable
            noOptionsText="Список пуст"
            noResultsText="Ничего не найдено"
            @select="changeFilter"
          />
        </div>
        <div class="earn-apparatus__header-btnsBlock">
          <ButtonStock @click="addCard" title="Создать поручение" v-if="canEdit" />
          <ButtonStock @click="goToMonitoring" title="Мониторинг реализации" />
          <ButtonStock @click="onDowloadBtn" title="Экспорт" :disabled="exportInProgress" />
        </div>
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div class="earn-apparatus__table-container">
        <div class="earn-apparatus__table table-container js-table-arrow">
          <table>
            <colgroup>
              <col class="th-shrink" />
              <col class="th-shrink" />
              <col class="th-common" />
              <col />
              <col class="th-common" />
              <col />
              <col class="th-common" />
              <col class="th-common" />
              <col class="th-common" />
              <col class="th-common" />
              <col class="th-common" />
              <col class="th-common" />
            </colgroup>
            <thead>
              <tr>
                <th v-if="canEdit" class="filter"></th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.CountryName)"
                    @click="changeSorting(SortColumnMap.CountryName)"
                  >
                    Страна
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.AssignmentName)"
                    @click="changeSorting(SortColumnMap.AssignmentName)"
                  >
                    Наименование поручения
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.ReceiptDate)"
                    @click="changeSorting(SortColumnMap.ReceiptDate)"
                  >
                    Дата поступления
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.CorrespondentName)"
                    @click="changeSorting(SortColumnMap.CorrespondentName)"
                  >
                    Корреспондент
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.Deadline)"
                    @click="changeSorting(SortColumnMap.Deadline)"
                  >
                    Срок
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.ResponsibleEmpolyees)"
                    @click="changeSorting(SortColumnMap.ResponsibleEmpolyees)"
                  >
                    Ответственный
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.UrgencyId)"
                    @click="changeSorting(SortColumnMap.UrgencyId)"
                  >
                    Приоритет
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.EventName)"
                    @click="changeSorting(SortColumnMap.EventName)"
                  >
                    Связанное мероприятие/объект
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.TypeName)"
                    @click="changeSorting(SortColumnMap.TypeName)"
                  >
                    Тип поручения
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.StatusName)"
                    @click="changeSorting(SortColumnMap.StatusName)"
                  >
                    Статус
                  </SortControl>
                </th>
                <th>
                  <SortControl
                    :order="getSortingState(SortColumnMap.Report)"
                    @click="changeSorting(SortColumnMap.Report)"
                  >
                    Отчет
                  </SortControl>
                </th>
              </tr>
              <tr>
                <th v-if="canEdit" class="filter"></th>
                <th class="filter"></th>
                <th class="filter">
                  <FilterInput
                    placeholder="Введите название..."
                    v-model="filter.name"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <div class="date-filter-group">
                    <div class="date-filter-group__item">
                      <FilterInput
                        type="date"
                        v-model="filter.receiptDateFrom"
                        v-on:valueChangedDebounced="changeFilter"
                      />
                    </div>
                    <div class="date-filter-group__item">
                      <FilterInput
                        type="date"
                        v-model="filter.receiptDateTo"
                        v-on:valueChangedDebounced="changeFilter"
                      />
                    </div>
                  </div>
                </th>
                <th class="filter">
                  <FilterInput
                    type="select2"
                    placeholder="Выберите..."
                    v-model="filter.correspondentIds"
                    v-on:valueChangedDebounced="changeFilter"
                    :select2Options="computedCorrespondentsList"
                    :select2Settings="select2SettingsMultiple"
                  />
                </th>
                <th class="filter nowrap">
                  <div class="date-filter-group">
                    <div class="date-filter-group__item">
                      <FilterInput
                        type="date"
                        v-model="filter.deadlineFrom"
                        v-on:valueChangedDebounced="changeFilter"
                      />
                    </div>
                    <div class="date-filter-group__item">
                      <FilterInput type="date" v-model="filter.deadlineTo" v-on:valueChangedDebounced="changeFilter" />
                    </div>
                  </div>
                </th>
                <th class="filter">
                  <FilterInput
                    type="select2"
                    placeholder="Выберите..."
                    v-model="filter.responsibleEmployeeIds"
                    v-on:valueChangedDebounced="changeFilter"
                    :select2Options="computedResponsiblesList"
                    :select2Settings="select2SettingsMultiple"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="urgencyList"
                    placeholder="Выберите..."
                    type="select2"
                    v-model="filter.urgencyId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th>
                  <FilterInput
                    :select2Options="eventList"
                    placeholder="Выберите..."
                    type="select2"
                    v-model="filter.eventKey"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="typeList"
                    placeholder="Выберите..."
                    type="select2"
                    v-model="filter.typeId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="statusList"
                    placeholder="Выберите..."
                    type="select2"
                    v-model="filter.statusId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    placeholder="Введите текст..."
                    v-model="filter.report"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
              </tr>
            </thead>
            <tbody v-if="page.items?.length">
              <tr v-for="item in page.items" :key="item.id" @dblclick="viewCard(item.id)">
                <td v-if="canEdit">
                  <Toolbar @edit="editCard(item.id)" @delete="deleteCard(item.id)" />
                </td>
                <td>{{ item.countryName }}</td>
                <td>{{ item.assignmentName }}</td>
                <td>{{ $momentFormat(item.receiptDate, 'DD MMMM YYYY') }}</td>
                <td>
                  <EmployeeList v-if="item.correspondent" :items="[item.correspondent]" />
                </td>
                <td>{{ $momentFormat(item.deadline, 'DD MMMM YYYY') }}</td>
                <td>
                  <EmployeeList :items="item.responsibleEmployees" />
                </td>
                <td>{{ item.urgency.name }}</td>
                <td>{{ item.eventName }}</td>
                <td>{{ item.type.name }}</td>
                <td>
                  <AssignmentStatus :assignment="item" />
                </td>
                <td>
                  <SimpleLink
                    v-if="item.report && item.reportDocument"
                    @click.prevent="downloadReportFile(item.reportDocument)"
                  >
                    {{ item.report }}
                  </SimpleLink>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginationRedesigned
          v-if="page.pagesTotal > 1"
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>

      <!-- modal -->
      <ModalComponent
        title="Форма создания поручения"
        @close="closeCard"
        v-model="dialogState"
        headType="redesigned"
        bodyType="redesigned"
      >
        <EapAssignmentCardAdd
          v-bind:nn="dialogNn"
          v-bind:id="dialogId"
          v-on:save="afterSaveCard"
          v-on:close="closeCard"
          :countryId="card.countryId"
          :projectId="card.projectId"
        />
      </ModalComponent>

      <ModalComponent
        title="Скачать"
        @close="downloadDialog = false"
        v-model="downloadDialog"
        headType="redesigned"
        bodyType="redesigned"
      >
        <div>
          <div class="mb-32">
            <div class="mb-4 required">
              <ComponentInput
                v-model="selectedFileFormat"
                type="select2"
                :select2-options="fileFormats"
                :select2-settings="select2SettingsNoClear"
                label="Выберите формат файла"
                required
              />
            </div>
          </div>
          <ButtonStock @click="downloadFile" title="Скачать" :disabled="exportInProgress" />
        </div>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import ComponentInput from '@/common/components/ComponentInput';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import SimpleLink from '@/common/components/SimpleLink';
  import SortControl from '@/common/components/SortControl';
  import Toolbar from '@/common/components/Toolbar';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  // Additional
  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/assignment';
  import AssignmentStatus from '../components/AssignmentStatus';
  import EapAssignmentCardAdd from '../components/EapAssignmentCardAdd.vue';
  import EmployeeList from '../components/EmployeeList.vue';
  import { SortColumn } from '../utils/constants.js';

  export default {
    name: 'EapAssignments',

    components: {
      Toolbar,
      SortControl,
      ButtonStock,
      FilterInput,
      ModalComponent,
      EapAssignmentCardAdd,
      ComponentInput,
      DefaultFilter,
      EmployeeList,
      AssignmentStatus,
      Multiselect,
      PaginationRedesigned,
      SimpleLink,
    },

    mixins: [security, filtermanager],

    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          countryId: null,
          name: '',
          deadlineFrom: null,
          deadlineTo: null,
          statusId: null,
          typeId: null,
          eventKey: null,
          urgencyId: null,
          report: '',
          correspondentIds: null,
          responsibleEmployeeIds: null,
          receiptDateFrom: null,
          receiptDateTo: null,
          sortColumn: SortColumn.Deadline,
          sortOrder: Constants.sortOrder.descending,
        },
        page: {},
        canEdit: false,

        select2Settings: Constants.select2Settings,
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        select2SettingsNoClear: {
          language: Constants.langRu,
          allowClear: false,
        },
        countryList: [],
        typeList: [],
        statusList: [],
        urgencyList: [],
        eventList: [],

        dialogState: false,
        dialogNn: 1,
        dialogId: 0,
        exportInProgress: false,

        card: {
          projectId: null,
          countryId: null,
        },

        downloadDialog: false,
        selectedFileFormat: 1,
        fileFormats: [
          { id: 1, text: 'Файл Excel (XLSX)' },
          { id: 2, text: 'Файл Word (DOCX)' },
          { id: 3, text: 'Презентация PowerPoint (PPTX)' },
          { id: 4, text: 'Файл PDF (PDF)' },
        ],

        availableResponsibles: null,
        availableCorrespondents: null,
      };
    },

    computed: {
      // для начального отображения выбранного значения в `@vueform/multiselect`
      computedCountryList() {
        return [...this.countryList];
      },
      SortColumnMap() {
        return SortColumn;
      },
      // computed для списков Select2 предотвращают блокировку скролла
      computedCorrespondentsList() {
        return this.mapPersons(this.availableCorrespondents);
      },
      computedResponsiblesList() {
        return this.mapPersons(this.availableResponsibles);
      },
    },

    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.EapAssignmentAccess);
      this.checkPermission(Constants.Permissions.EapAssignmentEdit).then((response) => {
        this.canEdit = response.data;
      });

      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
          this.filter.countryId = this.$route.query?.countryId ?? null;
        }
        Utils.loadSelectOptions('countryList', this.countryList);
        Utils.loadSelectOptions('eapAssignmentTypeList', this.typeList);
        Utils.loadSelectOptions('eapAssignmentStatusList', this.statusList);
        Utils.loadSelectOptions('eapAssignmentUrgencyList', this.urgencyList);
        this.loadPage(false);
        this.loadEvents();
        this.fetchResponsibles();
        this.fetchCorrespondents();
      });
    },

    methods: {
      loadEvents() {
        API.getEventForList().then((response) => {
          this.eventList = [{ id: '', text: Constants.emptySelectOptionText, selected: true }];
          if (response.data && response.data.length) {
            response.data.forEach((i) => this.eventList.push({ id: i.eventId + ';' + i.eventType, text: i.eventName }));
          }
        });
      },
      goToMonitoring() {
        this.$router.push({ name: 'EapAssignmentMonitoring' });
      },
      addCard() {
        this.dialogState = true;
        this.dialogNn++;
        this.dialogId = 0;
      },
      editCard(id) {
        this.dialogState = true;
        this.dialogNn++;
        this.dialogId = id;
      },
      viewCard(id) {
        this.$router.push({ name: 'EapAssignmentCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      afterSaveCard() {
        this.dialogState = false;
        this.resetCard();
        this.loadPage(false);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        var params = Object.assign(this.filter, this.request);
        API.search(params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });

        if (this.$route.params.projectId && this.$route.params.countryId) {
          this.card.projectId = this.$route.params.projectId;
          this.card.countryId = this.$route.params.countryId;
          this.addCard();
        }
      },
      resetCard() {
        this.card = {
          projectId: null,
          countryId: null,
        };
      },
      closeCard() {
        this.dialogState = false;
        this.resetCard();
      },
      changeSorting(id) {
        if (this.filter.sortColumn == id) this.filter.sortOrder = this.filter.sortOrder == 0 ? 1 : 0;
        else this.filter.sortOrder = 0;
        this.filter.sortColumn = id;
        this.loadPage(false);
      },
      getSortingState(columnId) {
        if (this.filter.sortColumn !== columnId) {
          return null;
        }
        return ['ascending', 'descending'][this.filter.sortOrder] ?? null;
      },
      onDowloadBtn() {
        this.selectedFileFormat = 1;
        this.downloadDialog = true;
      },
      downloadFile() {
        this.exportInProgress = true;
        const params = Object.assign({ formatId: this.selectedFileFormat }, this.filter);
        API.export(params)
          .then(() => {
            this.downloadDialog = false;
            this.exportInProgress = false;
          })
          .catch(() => {
            Constants.alert.fire('Скачивание файла', 'Не удалось сформировать отчет', 'error');
            this.downloadDialog = false;
            this.exportInProgress = false;
          });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      async fetchResponsibles() {
        this.availableResponsibles = await API.getResponsibleCandidates({
          countryId: this.countryId,
        }).then((response) => response?.data);
      },
      async fetchCorrespondents() {
        this.availableCorrespondents = await API.getCorrespondentsCandidates({
          countryId: this.countryId,
        }).then((response) => response?.data);
      },
      mapPersons(list) {
        return list?.map?.((person) => ({
          id: person.id,
          text: person.fullName,
        }));
      },
      getReportFileUrl(reportDocument) {
        return Utils.getFileUrlGuid(reportDocument.guid);
      },
      downloadReportFile(reportDocument) {
        const url = this.getReportFileUrl(reportDocument);
        Utils.downloadFile(url, reportDocument.originalName, null, true).catch((error) => {
          console.error('Во время скачивания файла произошла ошибка: ', error);
        });
      },
    },

    watch: {
      'filter.countryId'(countryId) {
        this.$router.replace({
          query: countryId ? { countryId } : null,
        });
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .earn-apparatus {
    &__title {
      margin-bottom: 24px;

      @media (max-width: $laptop) {
        margin-bottom: 16px;
      }
    }
    &__header {
      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 40px;

        @media (max-width: $laptop) {
          margin-bottom: 24px;
        }
      }
      &-countryBlock {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 12px;
        max-width: 360px;
        width: 100%;

        .country {
          font: $fira-16;
        }

        .multiselect {
          flex: 1 1 0%;
        }

        @media (max-width: $mobile) {
          max-width: 100%;

          .multiselect {
            flex-basis: 100%;
          }
        }
      }
      &-btnsBlock {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        gap: 8px;

        @media (max-width: $mobile) {
          flex: 1 1 0%;
          grid-auto-flow: row;
        }
      }
    }
    &__table {
      margin-bottom: 12px;
      table {
        thead {
          th {
            position: relative;
            vertical-align: bottom;
            font: $fira-16-M;
            color: $dark-grey-1;
            padding: 16px 20px 20px;
            border-bottom: 2px solid $green;
            &:nth-of-type(5) {
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid $light-grey-5;
          }
          td {
            padding: 20px;
            vertical-align: top;
            font: $fira-14;
            p {
              margin-bottom: 0px;
            }
          }
          button {
            margin-top: 12px;
          }
        }
      }
      &-footer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 18px;
        p {
          font-size: 12px;
          line-height: 18px;
          color: $green;
          border-bottom: 1px dashed $green;
          margin-bottom: 0px;
        }
      }
    }
  }

  .date-filter-group {
    display: flex;
    align-items: center;
    gap: 8px;

    & :deep(.v3dp__input_wrapper input) {
      min-height: calc(1.25rem + 10px) !important;
      font: $fira-14;
    }
  }

  .date-filter-group__item {
    flex: 0 1 auto;
    min-width: 84px;
  }

  .filter {
    :deep(.input-group-wrapper input:not([type='checkbox']):not([type='time'])) {
      padding: 0 8px;
    }
  }

  .th-shrink {
    width: 0;
  }
  .th-common {
    min-width: 204px;
  }
</style>
