export const IMPLEMENTATION = 'implementation';

export const IMPLEMENTATION_ACTIONS_NAME = {
  fetchEventsHistory: `${IMPLEMENTATION}/fetchEventsHistory`,
  fetchMoreEventsHistory: `${IMPLEMENTATION}/fetchMoreEventsHistory`,
  fetchAgreementsHistory: `${IMPLEMENTATION}/fetchAgreementsHistory`,
  fetchMoreAgreementsHistory: `${IMPLEMENTATION}/fetchMoreAgreementsHistory`,
};

export const IMPLEMENTATION_MUTATIONS_NAME = {
  setEventsHistory: 'setEventsHistory',
  addEventsHistory: 'addEventsHistory',
  setAgreementsHistory: 'setAgreementsHistory',
  addAgreementsHistory: 'addAgreementsHistory',
};

export const IMPLEMENTATION_GETTERS_NAME = {
  getEventsHistory: `${IMPLEMENTATION}/getEventsHistoryData`,
  getAgreementsHistory: `${IMPLEMENTATION}/getAgreementsHistoryData`,
};
