<template>
  <div v-if="summary" class="investments investmentsEconRev-container mb-20">
    <div class="economicsTypeBlock">
      <p class="economicsTypeBlock-title">Инвестиции</p>
      <ul v-if="indicatorsListFilter?.length" class="indicators__list">
        <li v-for="item in indicatorsListFilter" :key="item.id" class="indicators__itemCustom">
          <p class="text-align-left fs14 fw600 mb-6">{{ item.title }}</p>
          <div class="d-flex align-items-center">
            <strong class="fs22 fw700 mr-8">
              {{ item.count == 0 ? '-' : item.count }}
            </strong>
            <div>
              <div class="fs12">млн $</div>
              <p class="analytics-source-text fs10" v-if="item.period">Период: {{ item.period }}</p>
            </div>
          </div>
          <!-- <div class="award" v-if="item.awards">
            <p>1 место - {{summary.inFlowsLeaderCountry}}</p>
            <p>{{summary.inFlowsRussiaPlace}} место - Россия
              <span v-if="summary.inFlowsRussiaValue">({{summary.inFlowsRussiaValue.toFixed(2)}})</span></p>
          </div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import Api from '../../api';

  export default {
    name: 'investmentsEconRev',
    mixins: [text],
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        summary: null,
        indicatorsList: [],
        dateOptions: [],
        selectDate: null,
      };
    },
    computed: {
      indicatorsListFilter() {
        return this.indicatorsList.filter((item) => item.count != '');
      },
    },
    created() {
      Api.getInvestementYearList(this.countryId).then((response) => {
        this.dateOptions = response.data;
        this.selectDate = this.dateOptions[0].id * 1;

        this.loadSummary();
      });
    },
    methods: {
      loadSummary() {
        Api.getInvestmentSummary(this.countryId, this.selectDate).then((response) => {
          this.summary = response.data;

          this.indicatorsList = [
            {
              id: 1,
              title: 'Входящие ПИИ,\n' + 'запас',
              count: this.formatNumber(this.summary.inStock, 0),
              period: this.summary.investmentPosition?.year,
            },
            {
              id: 2,
              title: 'Исходящие ПИИ,\n' + 'запас',
              count: this.formatNumber(this.summary.outStock, 0),
              period: this.summary.investmentPosition?.year,
            },
            {
              id: 3,
              title: 'Входящие ПИИ,\n' + 'поток',
              count: this.formatNumber(this.summary.inFlows, 0),
              awards: true,
              period: this.summary.investmentPosition?.year,
            },
            {
              id: 4,
              title: 'Исходящие ПИИ,\n' + 'поток',
              count: this.formatNumber(this.summary.outFlows, 0),
              period: this.summary.investmentPosition?.year,
            },
            {
              id: 5,
              title: 'Международная\n' + 'инвестиционная позиция',
              count: this.formatNumber(this.summary.investmentPosition?.value, 0),
              period: this.summary.investmentPosition?.year,
            },
          ];
        });
      },
    },
    watch: {
      countryId: function () {
        this.loadSummary();
      },
      selectDate: function () {
        this.loadSummary();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  .investments {
    .select2::v-deep {
      outline: none;
      background-color: #ffffff;
      // Контейнер ширина
      .select2-container {
        outline: none;
        width: 100% !important;
        max-width: 150px !important;
        border-radius: 40px;
        // Текст
        .select2-selection__rendered {
          outline: none;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: $color8;
          margin-top: 0 !important;
        }
      }
      // Контейнер высота
      .select2-container--default .select2-selection--single {
        outline: none;
        height: auto !important;
        border: 1px solid $color8 !important;
        background-color: transparent !important;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow {
        outline: none;
        top: 50% !important;
        width: 10px;
        right: 14px;
        transform: translateY(-50%) !important;
      }
      // Плейсхолдер
      .select2-container--default .select2-selection--single .select2-selection__placeholder {
        outline: none;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #989898;
      }
      /* Модификация стрелки */
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        outline: none;
        border-color: unset;
        border-style: unset;
        border-width: unset;
        width: 10px;
        height: 10px;
        border-radius: 0;
        border-left: 3px solid $color8 !important;
        border-bottom: 3px solid $color8 !important;
        transform: rotate(315deg);
        transition: 0.3s;
        mask-image: unset;
      }
      .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        transform: rotate(135deg);
        margin-top: -3px !important;
      }
    }
  }
  .pie-indicators {
    margin-top: 40px;
    p {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 113.9%;
      text-align: center;
      color: #333333;
    }
    strong {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #333333;
    }
    h3 {
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #333333;
    }
  }
  .indicators__item-period {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #333333;
  }
  .indicators {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px 40px;
    }
    &__item {
      max-width: 150px;
      width: 100%;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      border: 1px solid #06a0d8a9;
      .award {
        margin-top: 30px;
        p {
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #333333;
        }
      }
      strong {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
        white-space: nowrap;
      }
      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
      &-title {
        text-align: center;
        margin-bottom: 24px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        color: #333333;
      }
    }
  }
  h2 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  h3 {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
  }

  @media (max-width: 564px) {
    .indicators__item {
      max-width: 100%;
      text-align: center;
      div {
        justify-content: center;
      }
    }

    .pie-charts {
      flex-direction: column;
    }
  }
  .indicators__itemCustom {
    min-width: 150px;
  }
</style>
