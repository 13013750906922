<template>
  <div :class="[modClass ? `date-component-${modClass}` : '']">
    <label :for="id" v-if="label" class="form-label" :class="{}">{{ label }}</label>
    <datepicker
      :id="id"
      startingView="day"
      :locale="locale"
      :inputFormat="dateFormat"
      v-model="date"
      :placeholder="placeholder"
      :style="{ background: !disabled ? '#fff' : null }"
      :clearable="clearable && !disabled"
      :disabled="disabled"
      :required="required"
      :class="[{ error: v$.date.$error }]"
      :typeable="typeable"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      :minimumView="minimumViewDate"
      v-on:blur="blurHandler"
    />
    <svg
      v-if="dateIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      class="svg"
      :class="{ svg_disabled: disabled }"
      @click="openCalendar"
    >
      <path
        d="M15.5009 2.5V7.5M19.0714 10.062V6.3C19.0714 5.57098 18.4958 4.98 17.7857 4.98H6.2143C5.50422 4.98 4.92859 5.57098 4.92859 6.3V10.062M19.0714 10.062V18.18C19.0714 18.909 18.4958 19.5 17.7857 19.5H6.2143C5.50422 19.5 4.92859 18.909 4.92859 18.18V10.062M19.0714 10.062H4.92859M8.50085 2.5V7.5"
        stroke-width="2"
      />
    </svg>
  </div>
  <div class="invalid-msg" v-if="v$.date.$error">
    <template v-if="v$.date.required.$invalid">Поле обязательно для заполнения</template>
    <template v-else-if="v$.date.minValue.$invalid">
      Значение должно быть не ранее
      {{ $momentFormat(lowerLimit, 'YYYY') }}
    </template>
    <template v-else-if="v$.date.maxValue.$invalid">
      Значение должно быть не позднее
      {{ $momentFormat(upperLimit, 'YYYY') }}
    </template>
    <template v-else-if="v$.date.validDate.$invalid"> Неверный формат даты. </template>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { maxValue, minValue, requiredIf } from '@vuelidate/validators';
  import { ru } from 'date-fns/locale';
  import moment from 'moment';

  const dateFormat = 'YYYY';

  const validDate = (value) => {
    if (!value) return true;
    const m = moment(value, dateFormat);
    return m.isValid();
  };

  export default {
    props: {
      modelValue: String,
      clearable: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      id: String,
      label: {
        type: String,
        default: '',
      },
      typeable: {
        type: Boolean,
        default: true,
      },
      lowerLimit: {
        type: Date,
        default: new Date(1900, 0, 1),
      },
      upperLimit: {
        type: Date,
        default: new Date(2099, 0, 1),
      },
      modClass: {
        type: String,
        default: '',
        validator: (value) => ['redesigned', 'filter-small'].includes(value),
      },
      placeholder: {
        type: String,
        default: 'дд.мм.гггг',
      },
      dateIcon: {
        type: Boolean,
      },
      minimumViewDate: {
        type: String,
        default: 'day',
        validator: (v) => ['day', 'month', 'year'].includes(v),
      },
      dateFormatProps: {
        type: String,
        default: 'dd.MM.y', // bugfix: used 'y' instead of 'yyyy' because of padding year with zeroes when typing
      },
    },
    created() {
      if (this.modelValue) this.date = this.modelValue ? new Date(Date.parse(this.modelValue)) : null;
    },
    data() {
      return {
        dateFormat: this.dateFormatProps, //"dd.MM.y", // bugfix: used 'y' instead of 'yyyy' because of padding year with zeroes when typing
        date: null,
        locale: ru,
      };
    },
    methods: {
      blurHandler(e) {
        this.date = e.target.value ? moment(e.target.value, dateFormat).toDate() : null;
      },
      openCalendar() {
        const input = document.getElementById(this.id);
        input.focus();
      },
    },
    watch: {
      modelValue(newValue) {
        this.date = newValue ? new Date(Date.parse(newValue)) : null;
      },
      date(newValue) {
        const hasNewValue = newValue != null && newValue != 'Invalid Date';
        if (this.dateFormatProps === 'y') {
          this.$emit('update:modelValue', hasNewValue ? moment(newValue).format('YYYY') : null);
        } else {
          this.$emit('update:modelValue', hasNewValue ? moment(newValue).format('YYYY-MM-DD') : null);
        }
      },
      disabled(newValue) {
        if (newValue) {
          this.date = null;
        }
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        date: {
          required: requiredIf(function () {
            return this.required;
          }),
          minValue: minValue(this.lowerLimit),
          maxValue: maxValue(this.upperLimit),
          validDate,
        },
      };
    },
  };
</script>
