import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    isLoading: false,
    isLoadingMore: false,
    tableData: {},
  },
};

export const NAVIGATOR_SCENARIOS = 'navigatorScenarios';

export const NAVIGATOR_SCENARIOS_ACTIONS_NAME = {
  fetchScenarios: `${NAVIGATOR_SCENARIOS}/fetchScenarios`,
  fetchMoreScenarios: `${NAVIGATOR_SCENARIOS}/fetchMoreScenarios`,
};

export const NAVIGATOR_SCENARIOS_MUTATIONS_NAME = {
  setScenarios: `setScenarios`,
  addScenarios: `addScenarios`,
};

export const NAVIGATOR_SCENARIOS_GETTERS_NAME = {
  getScenarios: `${NAVIGATOR_SCENARIOS}/getScenarios`,
  getScenariosTotalItem: `${NAVIGATOR_SCENARIOS}/getScenariosTotalItem`,
  getIsLoading: `${NAVIGATOR_SCENARIOS}/getIsLoading`,
  getIsLoadingMore: `${NAVIGATOR_SCENARIOS}/getIsLoadingMore`,
};
