import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    nsiTnvedSearch: [],
    nsiTnvedByIDs: [],
  },
};

export const NSITNVED = 'nsitnved';

export const NSITNVED_ACTIONS_NAME = {
  searchNSITnved: `${NSITNVED}/searchNSITnved`,
  getNSITnvedByIDs: `${NSITNVED}/getNSITnvedByIDs`,
};

export const NSITNVED_MUTATIONS_NAME = {
  setNSITnvedByIDs: 'setNSITnvedByIDs',
  setNSITnvedByIDsDelete: 'setNSITnvedByIDsDelete',
  setNSITnved: 'setNSITnved',
};

export const NSITNVED_GETTERS_NAME = {
  getNSITnvedByIDsList: `${NSITNVED}/getNSITnvedByIDsList`,
  getSearchNSITnved: `${NSITNVED}/getSearchNSITnved`,
};
