<template>
  <section class="export-potential-map">
    <div>
      <p class="export-potential-map_title my-4">Карта перспективности страновых рынков</p>
      <form class="mb-4" @submit.prevent="onSubmit" v-if="productList && productList.length > 0">
        <div class="d-flex align-items-center">
          <div class="select2">
            <SelectComponentExportPotentialMap
              :options="productList"
              v-model="filter"
              :searchable="true"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Наименование или код ТН ВЭД"
              noOptionsText="Список пуст."
              noResultsText="Ничего не найдено."
            />
          </div>
          <input class="ml-2 submit-btn" type="submit" value="Показать на карте" />
        </div>
      </form>
      <div v-if="loading" class="d-flex align-items-center justify-content-center">
        <Loader class="loader export-potential-map_form-loader" v-if="loading" />
      </div>
      <div v-show="productList && productList.length > 0" class="map-wrapper export-potential-map__map">
        <div>
          <div id="mapContainerExportPotential"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  import Utils from '@/common/utils';

  import Loader from '@/components/Loader';

  import worldMap from '../../../../common/custom.geo.json';
  import Api from '../api/exportpotential';
  import SelectComponentExportPotentialMap from './SelectComponentExportPotentialMap';

  export default {
    components: { /*ComponentInput,*/ Loader, SelectComponentExportPotentialMap },
    data() {
      return {
        filter: {},
        loading: null,
        center: [40, 0],
        world: worldMap,
        map: null,
        geoJsonLayer: null,
        countries: [],
        productList: [],
      };
    },
    computed: {
      ...mapGetters(['getExportPotentialMap']),
    },
    watch: {
      getExportPotentialMap() {
        if (this.geoJsonLayer) this.restyleGeoJsonLayer();
        else this.setupLeafletMap();
        this.loading = false;
      },
    },
    methods: {
      ...mapActions([
        //"downloadExportPotentialMapSelect",
        'downloadExportPotentialMap',
      ]),
      async onSubmit() {
        this.loading = true;
        if (!this.filter) {
          this.loading = false;
          return false;
        }
        let request = {
          codeNsiTnved: this.filter,
          //ParrentsCodesNsiTnved: [this.filter],
          isActive: true,
        };
        this.downloadExportPotentialMap(request);
      },
      styleMap(feature) {
        let props = {};
        props.color = '#E3E3E3';
        props.active = false;
        let code = parseInt(feature.properties.iso_n3);
        let country = this.getExportPotentialMap[code] ? this.getExportPotentialMap[code] : null;

        feature.properties.countryStatus = 0;

        if (country) {
          let value = country.value.toFixed(1),
            exportValue = country.exportValue.toFixed(1),
            exportFact = country.exportFact.toFixed(1);
          props.exportValue = exportValue >= 0.1 ? exportValue : '0.1<';
          props.exportFact = exportFact >= 0.1 ? exportFact : '0.1<';
          props.value = value >= 0.1 ? value : '0.1<';

          props.name = country.name;
          props.code = country.code;
          props.label = country.label;

          props.active = true;

          if (value < 0) {
            props.color = '#E3E3E3';
          } else if (value < 0.1) {
            props.color = '#99CEFF';
          } else if (value < 0.3) {
            props.color = '#74BCFF';
          } else if (value < 0.5) {
            props.color = '#309CFF';
          } else if (value < 0.8) {
            props.color = '#0081F9';
          } else if (value < 1.1) {
            props.color = '#0264BE';
          } else if (value < 2) {
            props.color = '#0061BB';
          } else if (value < 4) {
            props.color = '#0061BB';
          } else if (value < 6) {
            props.color = '#0061BB';
          } else {
            props.color = '#00386C';
          }
        }

        feature.props = props;

        this.loading = false;
        if (code == 304) {
          return {
            color: props.color,
            fillColor: 'white',
            weight: 1,
            opacity: 0.5,
          };
        }
        return {
          color: '#60a7ee',
          fillColor: props.color,
          weight: 1,
          opacity: 0.7,
        };
      },
      onEachFeature(feature, layer) {
        const self = this;
        if (feature.props.active) {
          const content = `
          <div class="potential-table__tooltip">
            <div class="potential-table__tooltip-close"></div>
            <div class="potential-table__tooltip-title">
              <div class="potential-table__tooltip-code">
                ${feature.props.code}
                <br>
                ${feature.props.label}
                <br/>
                <span>${feature.props.name}</span>
              </div>


              <p class="potential-table__tooltip-hint"> Источник: Данные Министерства экономического развития РФ </p>
            </div>
            <div class="potential-table__tooltip-content">
              <div class="potential-table__tooltip-block">
                <div>Экспортный потенциал </div>
                <div class="potential-table__tooltip-value">
                  $${feature.props.exportValue} млн
                </div>
              </div>
              <div class="potential-table__tooltip-block">
                <div> Средний зеркальный экспорт за 5 лет </div>
                <div class="potential-table__tooltip-value">
                  $${feature.props.exportFact} млн
                </div>
              </div>
              <div class="potential-table__tooltip-block">
                Нереализованный экспортный потенциал
                <div class="potential-table__tooltip-value">
                  $${feature.props.value} млн
                </div>
              </div>
            </div>
          </div>
        `;

          if (feature.props.active) {
            layer.bindPopup(content, feature.properties.countryStatus.toString());
            layer.on('mouseup', () => {
              layer.openPopup();
            });
          }
        }

        layer.on('mouseover', function () {
          this.unbindTooltip();
          const country = self.countries.find((c) => c.code === Number(feature.properties.iso_n3));
          if (country) this.bindTooltip(country?.fullName ?? '').openTooltip();
          // layer.openPopup();
        });
        layer.on('mouseout', function () {
          this.unbindTooltip();
        });
      },
      setupLeafletMap: function () {
        let mapContainer = window.document.getElementById('mapContainerExportPotential');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        this.loading = true;
        if (this.map) {
          this.map.remove();
          this.map = null;
        }
        this.map = L.map('mapContainerExportPotential');
        this.map.setView(this.center, 1.4);

        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.map);
        this.geoJsonLayer = L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
        }).addTo(this.map);
        this.restyleGeoJsonLayer();
        this.map.whenReady(() => {
          this.loading = false;
        });
      },
      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },
      restyleLayer(layer) {
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },

      loadProductList() {
        Api.getProductsListWithExportPotential().then((ret) => {
          let data = ret?.data
            .filter((item) => item.isActive)
            .map((item) => {
              if (item.name && item.code && item.id) {
                return {
                  label: `${item.code} - ${item.name}`,
                  code: item.code,
                  id: item.id,
                  value: item.code,
                  searchText: item.name,
                  level: item.level,
                };
              }
            });
          const selectData = data.sort((a, b) => a.label.localeCompare(b.label));
          this.productList = selectData;
        });
      },
    },

    async mounted() {
      Utils.searchNsi('countryListWithData', {}).then((response) => {
        this.countries = response.data.map((c) => ({
          id: c.id,
          text: c.name,
          fullName: c.nameFull || c.name,
          code: c.code,
          groupId: c.countryGroupId,
        }));
      });

      await this.loadProductList();
    },
  };
</script>

<style scoped lang="scss">
  .submit-btn {
    input [type='submit'] {
      white-space: nowrap;
      border: none;
      padding: 13px 22px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      background-color: #c6c7c7;
      transition: 0.2s ease-in-out;
      &:hover,
      &.active {
        background-color: #0093ba;
      }
      background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
      width: 156px;
      border: 0;
      height: 40px;
      text-align: center;

      border-radius: 4px;
    }
  }
  .export-potential-map {
    form {
      display: flex;
      margin-bottom: 30px;
    }
  }

  .select2 {
    min-width: 520px;
    max-width: 620px;
  }
  .export-potential-map_title {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  .leaflet-popup-content-wrapper {
    padding: 12px !important;
  }
  #mapContainerExportPotential {
    background-color: #ffffff;
    width: 100%;
    height: 500px;
  }
</style>

<style lang="scss">
  @import '../../../../assets/theme/default/colors';
  .export-potential-map {
    .potential-table {
      &__tooltip {
        position: static;
        border: 0;
        display: block;
        width: auto;
        padding: 16px;
        &-title {
          padding-bottom: 24px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
        &-code {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: $color9;
        }
        &-block {
          padding: 8px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px dashed $color3;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          div {
            display: flex;
            align-items: center;
          }
          &:last-child {
            border-bottom: unset;
          }
        }
        &-box {
          width: 12px;
          height: 12px;
          margin-right: 12px;
        }
        &-value {
          color: $color9;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          margin-left: 12px;
        }
      }
    }
    .leaflet-control,
    .leaflet-pane,
    .leaflet-top,
    .leaflet-bottom {
      z-index: 99;
    }
  }
</style>
