<template>
  <Swiper
    @swiper="onSwiper"
    :slidesPerView="1"
    :spaceBetween="25"
    :allowTouchMove="false"
    :onlyExternal="true"
    navigation
    :modules="modules"
  >
    <SwiperSlide class="news-component__item" v-for="news in newses" :key="news.id">
      <img v class="news-component__img" src="~@/assets/images/news-image.png" alt="Фото новости" />
      <h3 class="news-component__title">
        {{ news.title }}
      </h3>
      <p class="news-component__date">
        {{ news.date }}
      </p>
      <p class="news-component__about">
        {{ news.about }}
      </p>
    </SwiperSlide>
  </Swiper>
</template>

<script>
  import { Navigation } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
  import 'swiper/swiper-bundle.css';

  export default {
    name: 'newsInner',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      newses: {
        type: Object,
        default: () => {},
      },
    },
    setup() {
      const onSwiper = (swiper) => {
        window.swiper = swiper;
      };
      return {
        modules: [Navigation],
        onSwiper,
      };
    },
  };
</script>

<style lang="scss">
  // News
  .news-component {
    &__img {
      width: 100%;
      height: 233px;
      object-fit: cover;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 10px;
    }
    &__date {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: #343c44;
      margin-bottom: 10px;
    }
    &__about {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      margin-bottom: 0px;
    }
  }
  .swiper {
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 25%;
    width: 10px;
    height: 10px;
    border-top: 40px solid transparent;
    border-left: 40px solid rgba(255, 255, 255, 0.5);
    border-right: 0px solid rgba(255, 255, 255, 0.5);
    border-bottom: 0px solid rgba(255, 255, 255, 0.5);
  }
  .swiper-button-prev {
    transform: rotate(45deg);
  }
  .swiper-button-next {
    transform: rotate(225deg);
  }

  @media (max-width: 425px) {
    .news-component__about {
      /* 3 точие */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      line-clamp: 7;
      box-orient: vertical;
    }
  }
</style>
