export function topRegionsAgreementsInfo(state) {
  return state.topRegionsAgreements;
}

export function topEffectiveRegionsInfo(state) {
  return state.topEffectiveRegions;
}

export function getPassportKeys(state) {
  return state.passportKeys;
}

export function getPassportPartnerCountries(state) {
  return state.passportPartnerCountries;
}

export function getPassportTradeTurnovers(state) {
  return state.passportTradeTurnovers;
}

export function getPassportMainInformation(state) {
  return state.passportMainInformation;
}

export function getPassportPartnersCount(state) {
  return state.passportPartnersCount;
}

export function getPassportCurEvents(state) {
  return state.passportCurrentEvents;
}

export function getPassportPrevEvents(state) {
  return state.passportPreviousEvents;
}

export function getPassportCurEventsCount(state) {
  return state.passportCurrentEventsCount;
}

export function getPassportPrevEventsCount(state) {
  return state.passportPreviousEventsCount;
}

export function getPassportListOfAgreements(state) {
  return state.passportListOfAgreements;
}
export function getResponsibleAuthorityInfo(state) {
  return state.responsibleAuthority;
}
