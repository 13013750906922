// eslint-disable-next-line
tinymce.addI18n('ru', {
  Redo: '\u0412\u0435\u0440\u043d\u0443\u0442\u044c',
  Undo: '\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c',
  Cut: '\u0412\u044b\u0440\u0435\u0437\u0430\u0442\u044c',
  Copy: '\u041a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c',
  Paste: '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c',
  'Select all': '\u0412\u044b\u0434\u0435\u043b\u0438\u0442\u044c \u0432\u0441\u0435',
  'New document': '\u041d\u043e\u0432\u044b\u0439 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442',
  Ok: 'OK',
  Cancel: '\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c',
  'Visual aids':
    '\u0412\u0438\u0437\u0443\u0430\u043b\u044c\u043d\u044b\u0435 \u043f\u043e\u0434\u0441\u043a\u0430\u0437\u043a\u0438',
  Bold: '\u0416\u0438\u0440\u043d\u044b\u0439 \u0448\u0440\u0438\u0444\u0442',
  Italic: '\u041a\u0443\u0440\u0441\u0438\u0432',
  Underline: '\u041f\u043e\u0434\u0447\u0435\u0440\u043a\u0438\u0432\u0430\u043d\u0438\u0435',
  Strikethrough: '\u0417\u0430\u0447\u0435\u0440\u043a\u0438\u0432\u0430\u043d\u0438\u0435',
  Superscript: '\u041d\u0430\u0434\u0441\u0442\u0440\u043e\u0447\u043d\u044b\u0439',
  Subscript: '\u041f\u043e\u0434\u0441\u0442\u0440\u043e\u0447\u043d\u044b\u0439',
  'Clear formatting':
    '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435',
  Remove: '\u0423\u0434\u0430\u043b\u0438\u0442\u044c',
  'Align left':
    '\u0412\u044b\u0440\u043e\u0432\u043d\u044f\u0442\u044c \u043f\u043e \u043b\u0435\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  'Align center':
    '\u0412\u044b\u0440\u043e\u0432\u043d\u044f\u0442\u044c \u043f\u043e \u0446\u0435\u043d\u0442\u0440\u0443',
  'Align right':
    '\u0412\u044b\u0440\u043e\u0432\u043d\u044f\u0442\u044c \u043f\u043e \u043f\u0440\u0430\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  'No alignment':
    '\u0412\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435 \u043d\u0435 \u0437\u0430\u0434\u0430\u043d\u043e',
  Justify:
    '\u0412\u044b\u0440\u043e\u0432\u043d\u044f\u0442\u044c \u0442\u0435\u043a\u0441\u0442 \u043f\u043e \u0448\u0438\u0440\u0438\u043d\u0435',
  'Bullet list':
    '\u041c\u0430\u0440\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a',
  'Numbered list':
    '\u041d\u0443\u043c\u0435\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a',
  'Decrease indent': '\u0423\u043c\u0435\u043d\u044c\u0448\u0438\u0442\u044c \u043e\u0442\u0441\u0442\u0443\u043f',
  'Increase indent': '\u0423\u0432\u0435\u043b\u0438\u0447\u0438\u0442\u044c \u043e\u0442\u0441\u0442\u0443\u043f',
  Close: '\u0417\u0430\u043a\u0440\u044b\u0442\u044c',
  Formats: '\u0424\u043e\u0440\u043c\u0430\u0442\u044b',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    '\u0412\u0430\u0448 \u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u043d\u0435 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442 \u043f\u0440\u044f\u043c\u043e\u0439 \u0434\u043e\u0441\u0442\u0443\u043f \u043a \u0431\u0443\u0444\u0435\u0440\u0443 \u043e\u0431\u043c\u0435\u043d\u0430. \u0418\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0439\u0442\u0435 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0435 \u0441\u043e\u0447\u0435\u0442\u0430\u043d\u0438\u044f \u043a\u043b\u0430\u0432\u0438\u0448: Ctrl+X/C/V.',
  Headings: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043a\u0438',
  'Heading 1': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 1',
  'Heading 2': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 2',
  'Heading 3': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 3',
  'Heading 4': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 4',
  'Heading 5': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 5',
  'Heading 6': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 6',
  Preformatted:
    '\u041f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439',
  Div: 'Div',
  Pre: 'Pre',
  Code: '\u041a\u043e\u0434',
  Paragraph: '\u0410\u0431\u0437\u0430\u0446',
  Blockquote: '\u0411\u043b\u043e\u043a \u0446\u0438\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u044f',
  Inline: '\u0412\u0441\u0442\u0440\u043e\u0435\u043d\u043d\u044b\u0439',
  Blocks: '\u0411\u043b\u043e\u043a\u0438',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    '\u0412\u0441\u0442\u0430\u0432\u043a\u0430 \u043e\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0432 \u0432\u0438\u0434\u0435 \u043f\u0440\u043e\u0441\u0442\u043e\u0433\u043e \u0442\u0435\u043a\u0441\u0442\u0430, \u043f\u043e\u043a\u0430 \u043d\u0435 \u043e\u0442\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u0434\u0430\u043d\u043d\u0443\u044e \u043e\u043f\u0446\u0438\u044e.',
  Fonts: '\u0428\u0440\u0438\u0444\u0442\u044b',
  'Font sizes': '\u0420\u0430\u0437\u043c\u0435\u0440 \u0448\u0440\u0438\u0444\u0442\u0430',
  Class: '\u041a\u043b\u0430\u0441\u0441',
  'Browse for an image':
    '\u0412\u044b\u0431\u043e\u0440 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f',
  OR: '\u0418\u041b\u0418',
  'Drop an image here':
    '\u041f\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0441\u044e\u0434\u0430',
  Upload: '\u041f\u0435\u0440\u0435\u0434\u0430\u0442\u044c',
  'Uploading image':
    '\u0417\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0438',
  Block: '\u0411\u043b\u043e\u043a',
  Align: '\u0412\u044b\u0440\u043e\u0432\u043d\u044f\u0442\u044c',
  Default: '\u041f\u043e \u0443\u043c\u043e\u043b\u0447\u0430\u043d\u0438\u044e',
  Circle: '\u041a\u0440\u0443\u0436\u043e\u043a',
  Disc: '\u0422\u043e\u0447\u043a\u0430',
  Square: '\u041a\u0432\u0430\u0434\u0440\u0430\u0442',
  'Lower Alpha':
    '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435 \u043b\u0430\u0442\u0438\u043d\u0441\u043a\u0438\u0435',
  'Lower Greek':
    '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435 \u0433\u0440\u0435\u0447\u0435\u0441\u043a\u0438\u0435',
  'Lower Roman': '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435 \u0440\u0438\u043c\u0441\u043a\u0438\u0435',
  'Upper Alpha':
    '\u0417\u0430\u0433\u043b\u0430\u0432\u043d\u044b\u0435 \u043b\u0430\u0442\u0438\u043d\u0441\u043a\u0438\u0435',
  'Upper Roman': '\u041f\u0440\u043e\u043f\u0438\u0441\u043d\u044b\u0435 \u0440\u0438\u043c\u0441\u043a\u0438\u0435',
  'Anchor...': '\u042f\u043a\u043e\u0440\u044c...',
  Anchor: '\u042f\u043a\u043e\u0440\u044c',
  Name: '\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435/\u0418\u043c\u044f',
  ID: 'ID',
  'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'ID \u0434\u043e\u043b\u0436\u0435\u043d \u043d\u0430\u0447\u0438\u043d\u0430\u0442\u044c\u0441\u044f \u0441 \u0431\u0443\u043a\u0432\u044b \u0438 \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u0442\u044c \u0442\u043e\u043b\u044c\u043a\u043e \u0431\u0443\u043a\u0432\u044b, \u0446\u0438\u0444\u0440\u044b, \u0447\u0435\u0440\u0442\u043e\u0447\u043a\u0443, \u0442\u043e\u0447\u043a\u0443, \u0437\u0430\u043f\u044f\u0442\u0443\u044e \u0438\u043b\u0438 \u0437\u043d\u0430\u043a \u043f\u043e\u0434\u0447\u0435\u0440\u043a\u0438\u0432\u0430\u043d\u0438\u044f.',
  'You have unsaved changes are you sure you want to navigate away?':
    '\u0423 \u0432\u0430\u0441 \u0435\u0441\u0442\u044c \u043d\u0435\u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u043d\u044b\u0435 \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u044f. \u0412\u044b \u0443\u0432\u0435\u0440\u0435\u043d\u044b, \u0447\u0442\u043e \u0445\u043e\u0442\u0438\u0442\u0435 \u0432\u044b\u0439\u0442\u0438?',
  'Restore last draft':
    '\u0412\u043e\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u0438\u0442\u044c \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0439 \u0447\u0435\u0440\u043d\u043e\u0432\u0438\u043a',
  'Special character...': '\u0421\u043f\u0435\u0446. \u0441\u0438\u043c\u0432\u043e\u043b\u044b...',
  'Special Character':
    '\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0441\u0438\u043c\u0432\u043e\u043b',
  'Source code': '\u0418\u0441\u0445\u043e\u0434\u043d\u044b\u0439 \u043a\u043e\u0434',
  'Insert/Edit code sample':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c/\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0440\u0438\u043c\u0435\u0440 \u043a\u043e\u0434\u0430',
  Language: '\u042f\u0437\u044b\u043a',
  'Code sample...': '\u041f\u0440\u0438\u043c\u0435\u0440 \u043a\u043e\u0434\u0430...',
  'Left to right': '\u0421\u043b\u0435\u0432\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043e',
  'Right to left': '\u0421\u043f\u0440\u0430\u0432\u0430 \u043d\u0430\u043b\u0435\u0432\u043e',
  Title: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a',
  Fullscreen: '\u041f\u043e\u043b\u043d\u044b\u0439 \u044d\u043a\u0440\u0430\u043d',
  Action: '\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0435',
  Shortcut: '\u0411\u044b\u0441\u0442\u0440\u0430\u044f \u043a\u043b\u0430\u0432\u0438\u0448\u0430',
  Help: '\u0421\u043f\u0440\u0430\u0432\u043a\u0430',
  Address: '\u0410\u0434\u0440\u0435\u0441',
  'Focus to menubar':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u043f\u0430\u043d\u0435\u043b\u0438 \u043c\u0435\u043d\u044e',
  'Focus to toolbar':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u043f\u0430\u043d\u0435\u043b\u0438 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432',
  'Focus to element path':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u0435 \u043f\u0443\u0442\u0438',
  'Focus to contextual toolbar':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u043a\u043e\u043d\u0442\u0435\u043a\u0441\u0442\u043d\u043e\u0439 \u043f\u0430\u043d\u0435\u043b\u0438 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432',
  'Insert link (if link plugin activated)':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443 (\u0435\u0441\u043b\u0438 \u043f\u043b\u0430\u0433\u0438\u043d link \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d)',
  'Save (if save plugin activated)':
    '\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c (\u0435\u0441\u043b\u0438 \u043f\u043b\u0430\u0433\u0438\u043d save \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d)',
  'Find (if searchreplace plugin activated)':
    '\u041d\u0430\u0439\u0442\u0438 (\u0435\u0441\u043b\u0438 \u043f\u043b\u0430\u0433\u0438\u043d searchreplace \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d)',
  'Plugins installed ({0}):':
    '\u0423\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u043d\u044b\u0435 \u043f\u043b\u0430\u0433\u0438\u043d\u044b ({0}):',
  'Premium plugins:': '\u041f\u0440\u0435\u043c\u0438\u0443\u043c \u043f\u043b\u0430\u0433\u0438\u043d\u044b:',
  'Learn more...': '\u0423\u0437\u043d\u0430\u0442\u044c \u0431\u043e\u043b\u044c\u0448\u0435...',
  'You are using {0}': '\u0412\u044b \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0435\u0442\u0435 {0}',
  Plugins: '\u041f\u043b\u0430\u0433\u0438\u043d\u044b',
  'Handy Shortcuts': '\u0413\u043e\u0440\u044f\u0447\u0438\u0435 \u043a\u043b\u0430\u0432\u0438\u0448\u0438',
  'Horizontal line':
    '\u0413\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u0430\u044f \u043b\u0438\u043d\u0438\u044f',
  'Insert/edit image':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  'Alternative description':
    '\u0410\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u043d\u043e\u0435 \u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435',
  Accessibility:
    '\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u0438',
  'Image is decorative':
    '\u0414\u0435\u043a\u043e\u0440\u0430\u0442\u0438\u0432\u043d\u043e\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  Source: '\u0418\u0441\u0445\u043e\u0434\u043d\u044b\u0439 \u043e\u0431\u044a\u0435\u043a\u0442',
  Dimensions: '\u0420\u0430\u0437\u043c\u0435\u0440\u044b',
  'Constrain proportions':
    '\u041e\u0433\u0440\u0430\u043d\u0438\u0447\u0438\u0442\u044c \u043f\u0440\u043e\u043f\u043e\u0440\u0446\u0438\u0438',
  General: '\u041e\u0431\u0449\u0438\u0435',
  Advanced: '\u0414\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0435',
  Style: '\u0421\u0442\u0438\u043b\u044c',
  'Vertical space':
    '\u0418\u043d\u0442\u0435\u0440\u0432\u0430\u043b \u043f\u043e \u0432\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u0438',
  'Horizontal space':
    '\u0418\u043d\u0442\u0435\u0440\u0432\u0430\u043b \u043f\u043e \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u0438',
  Border: '\u0413\u0440\u0430\u043d\u0438\u0446\u0430',
  'Insert image':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  'Image...': '\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435...',
  'Image list':
    '\u0421\u043f\u0438\u0441\u043e\u043a \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439',
  Resize: '\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440',
  'Insert date/time':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0434\u0430\u0442\u0443/\u0432\u0440\u0435\u043c\u044f',
  'Date/time': '\u0414\u0430\u0442\u0430/\u0432\u0440\u0435\u043c\u044f',
  'Insert/edit link':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443',
  'Text to display':
    '\u041e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0435\u043c\u044b\u0439 \u0442\u0435\u043a\u0441\u0442',
  Url: 'URL-\u0430\u0434\u0440\u0435\u0441',
  'Open link in...': '\u041e\u0442\u043a\u0440\u044b\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443 \u0432...',
  'Current window': '\u0422\u0435\u043a\u0443\u0449\u0435\u0435 \u043e\u043a\u043d\u043e',
  None: '\u041d\u0435\u0442',
  'New window': '\u041d\u043e\u0432\u043e\u0435 \u043e\u043a\u043d\u043e',
  'Open link': '\u041f\u0435\u0440\u0435\u0439\u0442\u0438 \u043f\u043e \u0441\u0441\u044b\u043b\u043a\u0435',
  'Remove link': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443',
  Anchors: '\u042f\u043a\u043e\u0440\u044f',
  'Link...': '\u0421\u0441\u044b\u043b\u043a\u0430...',
  'Paste or type a link':
    '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043b\u0438 \u0432\u0441\u0442\u0430\u0432\u044c\u0442\u0435 \u0441\u0441\u044b\u043b\u043a\u0443',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    '\u0412\u0432\u0435\u0434\u0435\u043d\u043d\u044b\u0439 URL \u044f\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0430\u0434\u0440\u0435\u0441\u043e\u043c \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b. \u0412\u044b \u0436\u0435\u043b\u0430\u0435\u0442\u0435 \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043d\u0435\u043e\u0431\u0445\u043e\u0434\u0438\u043c\u044b\u0439 \u043f\u0440\u0435\u0444\u0438\u043a\u0441 \xabmailto:\xbb?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    '\u0412\u0432\u0435\u0434\u0435\u043d\u043d\u044b\u0439 URL \u044f\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0432\u043d\u0435\u0448\u043d\u0435\u0439 \u0441\u0441\u044b\u043b\u043a\u043e\u0439. \u0412\u044b \u0436\u0435\u043b\u0430\u0435\u0442\u0435 \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043d\u0435\u043e\u0431\u0445\u043e\u0434\u0438\u043c\u044b\u0439 \u043f\u0440\u0435\u0444\u0438\u043a\u0441 \xabhttp://\xbb?',
  'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
    '\u0412\u0432\u0435\u0434\u0435\u043d\u043d\u044b\u0439 \u0412\u0430\u043c\u0438 URL-\u0430\u0434\u0440\u0435\u0441 \u044f\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0432\u043d\u0435\u0448\u043d\u0435\u0439 \u0441\u0441\u044b\u043b\u043a\u043e\u0439. \u0425\u043e\u0442\u0438\u0442\u0435 \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043d\u0435\u043e\u0431\u0445\u043e\u0434\u0438\u043c\u044b\u0439 \u043f\u0440\u0435\u0444\u0438\u043a\u0441 https: //?',
  'Link list': '\u0421\u043f\u0438\u0441\u043e\u043a \u0441\u0441\u044b\u043b\u043e\u043a',
  'Insert video': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0432\u0438\u0434\u0435\u043e',
  'Insert/edit video':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u043e',
  'Insert/edit media':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430',
  'Alternative source':
    '\u0410\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u043d\u044b\u0439 \u0438\u0441\u0442\u043e\u0447\u043d\u0438\u043a',
  'Alternative source URL':
    'URL \u0430\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u043d\u043e\u0433\u043e \u0438\u0441\u0442\u043e\u0447\u043d\u0438\u043a\u0430',
  'Media poster (Image URL)':
    '\u041f\u043e\u0441\u0442\u0435\u0440 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 (URL \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f)',
  'Paste your embed code below:':
    '\u0412\u0441\u0442\u0430\u0432\u044c\u0442\u0435 \u0432\u0441\u0442\u0440\u043e\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0434 \u043d\u0438\u0436\u0435:',
  Embed: '\u0412\u0441\u0442\u0440\u043e\u0435\u043d\u043d\u044b\u0439 \u043a\u043e\u0434',
  'Media...': '\u041c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430...',
  'Nonbreaking space':
    '\u041d\u0435\u0440\u0430\u0437\u0440\u044b\u0432\u043d\u044b\u0439 \u043f\u0440\u043e\u0431\u0435\u043b',
  'Page break': '\u0420\u0430\u0437\u0440\u044b\u0432 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b',
  'Paste as text': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043a\u0430\u043a \u0442\u0435\u043a\u0441\u0442',
  Preview:
    '\u041f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u0439 \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440',
  Print: '\u041f\u0435\u0447\u0430\u0442\u044c',
  'Print...': '\u041d\u0430\u043f\u0435\u0447\u0430\u0442\u0430\u0442\u044c...',
  Save: '\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c',
  Find: '\u041d\u0430\u0439\u0442\u0438',
  'Replace with': '\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c \u043d\u0430',
  Replace: '\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c',
  'Replace all': '\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c \u0432\u0441\u0435',
  Previous: '\u041f\u0440\u0435\u0434\u044b\u0434\u0443\u0449\u0438\u0439',
  Next: '\u041f\u0440\u043e\u0434\u043e\u043b\u0436\u0438\u0442\u044c',
  'Find and Replace': '\u041d\u0430\u0439\u0442\u0438 \u0438 \u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c',
  'Find and replace...': '\u041d\u0430\u0439\u0442\u0438 \u0438 \u0437\u0430\u043c\u0435\u043d\u0438\u0442\u044c...',
  'Could not find the specified string.':
    '\u0417\u0430\u0434\u0430\u043d\u043d\u0430\u044f \u0441\u0442\u0440\u043e\u043a\u0430 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u0430/',
  'Match case': '\u0421 \u0443\u0447\u0435\u0442\u043e\u043c \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430',
  'Find whole words only':
    '\u041d\u0430\u0439\u0442\u0438 \u0442\u043e\u043b\u044c\u043a\u043e \u0446\u0435\u043b\u044b\u0435 \u0441\u043b\u043e\u0432\u0430',
  'Find in selection':
    '\u0418\u0441\u043a\u0430\u0442\u044c \u0432 \u0432\u044b\u0434\u0435\u043b\u0435\u043d\u043d\u043e\u043c',
  'Insert table': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0442\u0430\u0431\u043b\u0438\u0446\u0443',
  'Table properties': '\u0421\u0432\u043e\u0439\u0441\u0442\u0432\u0430 \u0442\u0430\u0431\u043b\u0438\u0446\u044b',
  'Delete table': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0442\u0430\u0431\u043b\u0438\u0446\u0443',
  Cell: '\u042f\u0447\u0435\u0439\u043a\u0430',
  Row: '\u0421\u0442\u0440\u043e\u043a\u0430',
  Column: '\u0421\u0442\u043e\u043b\u0431\u0435\u0446',
  'Cell properties': '\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u044b \u044f\u0447\u0435\u0439\u043a\u0438',
  'Merge cells': '\u041e\u0431\u044a\u0435\u0434\u0438\u043d\u0438\u0442\u044c \u044f\u0447\u0435\u0439\u043a\u0438',
  'Split cell': '\u0420\u0430\u0437\u0431\u0438\u0442\u044c \u044f\u0447\u0435\u0439\u043a\u0443',
  'Insert row before':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u0443\u044e \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u0432\u0435\u0440\u0445\u0443',
  'Insert row after':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u0443\u044e \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u043d\u0438\u0437\u0443',
  'Delete row': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443',
  'Row properties': '\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u044b \u0441\u0442\u0440\u043e\u043a\u0438',
  'Cut row': '\u0412\u044b\u0440\u0435\u0437\u0430\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443',
  'Cut column': '\u0412\u044b\u0440\u0435\u0437\u0430\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446',
  'Copy row': '\u041a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443',
  'Copy column':
    '\u041a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446',
  'Paste row before':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u0432\u0435\u0440\u0445\u0443',
  'Paste column before':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446 \u0441\u043b\u0435\u0432\u0430',
  'Paste row after':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u043d\u0438\u0437\u0443',
  'Paste column after':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446 \u0441\u043f\u0440\u0430\u0432\u0430',
  'Insert column before':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u043e\u0439 \u0441\u0442\u043e\u043b\u0431\u0435\u0446 \u0441\u043b\u0435\u0432\u0430',
  'Insert column after':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u043e\u0439 \u0441\u0442\u043e\u043b\u0431\u0435\u0446 \u0441\u043f\u0440\u0430\u0432\u0430',
  'Delete column': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446',
  Cols: '\u0421\u0442\u043e\u043b\u0431\u0446\u044b',
  Rows: '\u0421\u0442\u0440\u043e\u043a\u0438',
  Width: '\u0428\u0438\u0440\u0438\u043d\u0430',
  Height: '\u0412\u044b\u0441\u043e\u0442\u0430',
  'Cell spacing':
    '\u0412\u043d\u0435\u0448\u043d\u0438\u0439 \u043e\u0442\u0441\u0442\u0443\u043f \u044f\u0447\u0435\u0439\u043a\u0438',
  'Cell padding':
    '\u0412\u043d\u0443\u0442\u0440\u0435\u043d\u043d\u0438\u0439 \u043e\u0442\u0441\u0442\u0443\u043f \u044f\u0447\u0435\u0439\u043a\u0438',
  'Row clipboard actions':
    '\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044f \u0441 \u0431\u0443\u0444\u0435\u0440\u043e\u043c \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043b\u044f \u0441\u0442\u0440\u043e\u043a\u0438',
  'Column clipboard actions':
    '\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044f \u0441 \u0431\u0443\u0444\u0435\u0440\u043e\u043c \u043e\u0431\u043c\u0435\u043d\u0430 \u0434\u043b\u044f \u0441\u0442\u043e\u043b\u0431\u0446\u0430',
  'Table styles': '\u0421\u0442\u0438\u043b\u0438 \u0442\u0430\u0431\u043b\u0438\u0446\u044b',
  'Cell styles': '\u0421\u0442\u0438\u043b\u0438 \u044f\u0447\u0435\u0439\u043a\u0438',
  'Column header': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a \u0441\u0442\u043e\u043b\u0431\u0446\u0430',
  'Row header': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a \u0441\u0442\u0440\u043e\u043a\u0438',
  'Table caption': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a \u0442\u0430\u0431\u043b\u0438\u0446\u044b',
  Caption: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a',
  'Show caption': '\u041f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u043f\u043e\u0434\u043f\u0438\u0441\u044c',
  Left: '\u041f\u043e \u043b\u0435\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  Center: '\u041f\u043e \u0446\u0435\u043d\u0442\u0440\u0443',
  Right: '\u041f\u043e \u043f\u0440\u0430\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  'Cell type': '\u0422\u0438\u043f \u044f\u0447\u0435\u0439\u043a\u0438',
  Scope: '\u041e\u0431\u043b\u0430\u0441\u0442\u044c \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f',
  Alignment: '\u0412\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  'Horizontal align':
    '\u0413\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  'Vertical align':
    '\u0412\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  Top: '\u041f\u043e \u0432\u0435\u0440\u0445\u0443',
  Middle: '\u041f\u043e \u0441\u0435\u0440\u0435\u0434\u0438\u043d\u0435',
  Bottom: '\u041f\u043e \u043d\u0438\u0437\u0443',
  'Header cell':
    '\u042f\u0447\u0435\u0439\u043a\u0430 \u0432\u0435\u0440\u0445\u043d\u0435\u0433\u043e \u043a\u043e\u043b\u043e\u043d\u0442\u0438\u0442\u0443\u043b\u0430',
  'Row group': '\u0413\u0440\u0443\u043f\u043f\u0430 \u0441\u0442\u0440\u043e\u043a',
  'Column group': '\u0413\u0440\u0443\u043f\u043f\u0430 \u0441\u0442\u043e\u043b\u0431\u0446\u043e\u0432',
  'Row type': '\u0422\u0438\u043f \u0441\u0442\u0440\u043e\u043a\u0438',
  Header: '\u0412\u0435\u0440\u0445\u043d\u0438\u0439 \u043a\u043e\u043b\u043e\u043d\u0442\u0438\u0442\u0443\u043b',
  Body: '\u0422\u0435\u043b\u043e',
  Footer: '\u041d\u0438\u0436\u043d\u0438\u0439 \u043a\u043e\u043b\u043e\u043d\u0442\u0438\u0442\u0443\u043b',
  'Border color': '\u0426\u0432\u0435\u0442 \u0433\u0440\u0430\u043d\u0438\u0446\u044b',
  Solid: '\u0421\u043f\u043b\u043e\u0448\u043d\u043e\u0439',
  Dotted: '\u0422\u043e\u0447\u043a\u0430\u043c\u0438',
  Dashed: '\u0427\u0435\u0440\u0442\u043e\u0447\u043a\u0430\u043c\u0438',
  Double: '\u0414\u0432\u043e\u0439\u043d\u043e\u0439',
  Groove: '\u041f\u0430\u0437',
  Ridge: '\u0428\u0438\u043f',
  Inset: '\u0412\u0441\u0442\u0430\u0432\u043a\u0430',
  Outset: '\u0412\u044b\u0440\u0435\u0437\u043a\u0430',
  Hidden: '\u0421\u043a\u0440\u044b\u0442\u044b\u0439',
  'Insert template...': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0448\u0430\u0431\u043b\u043e\u043d...',
  Templates: '\u0428\u0430\u0431\u043b\u043e\u043d\u044b',
  Template: '\u0428\u0430\u0431\u043b\u043e\u043d',
  'Insert Template': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0448\u0430\u0431\u043b\u043e\u043d',
  'Text color': '\u0426\u0432\u0435\u0442 \u0442\u0435\u043a\u0441\u0442\u0430',
  'Background color': '\u0426\u0432\u0435\u0442 \u0444\u043e\u043d\u0430',
  'Custom...': '\u041d\u0430\u0441\u0442\u0440\u0430\u0438\u0432\u0430\u0435\u043c\u044b\u0439...',
  'Custom color':
    '\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0446\u0432\u0435\u0442',
  'No color': '\u0411\u0435\u0437 \u0446\u0432\u0435\u0442\u0430',
  'Remove color': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0446\u0432\u0435\u0442',
  'Show blocks': '\u041f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0431\u043b\u043e\u043a\u0438',
  'Show invisible characters':
    '\u041f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u043d\u0435\u0432\u0438\u0434\u0438\u043c\u044b\u0435 \u0441\u0438\u043c\u0432\u043e\u043b\u044b',
  'Word count': '\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u043b\u043e\u0432',
  Count: '\u041f\u043e\u0434\u0441\u0447\u0435\u0442',
  Document: '\u0414\u043e\u043a\u0443\u043c\u0435\u043d\u0442',
  Selection: '\u0412\u044b\u0431\u043e\u0440',
  Words: '\u0421\u043b\u043e\u0432\u0430',
  'Words: {0}': '\u0421\u043b\u043e\u0432: {0}',
  '{0} words': '{0} \u0441\u043b\u043e\u0432',
  File: '\u0424\u0430\u0439\u043b',
  Edit: '\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c',
  Insert: '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c',
  View: '\u0412\u0438\u0434',
  Format: '\u0424\u043e\u0440\u043c\u0430\u0442',
  Table: '\u0422\u0430\u0431\u043b\u0438\u0446\u0430',
  Tools: '\u0418\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b',
  'Powered by {0}': '\u041f\u043e\u0434 \u0443\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435\u043c {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    '\u041f\u043e\u043b\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u043e\u0433\u043e \u0442\u0435\u043a\u0441\u0442\u0430. \u041d\u0430\u0436\u043c\u0438\u0442\u0435 ALT-F9, \u0447\u0442\u043e\u0431\u044b \u043e\u0442\u043a\u0440\u044b\u0442\u044c \u043c\u0435\u043d\u044e, ALT-F10, \u0447\u0442\u043e\u0431\u044b \u043e\u0442\u043a\u0440\u044b\u0442\u044c \u043f\u0430\u043d\u0435\u043b\u044c \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432, ALT-0, \u0447\u0442\u043e\u0431\u044b \u043e\u0442\u043a\u0440\u044b\u0442\u044c \u0441\u043f\u0440\u0430\u0432\u043a\u0443.',
  'Image title':
    '\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f',
  'Border width': '\u0428\u0438\u0440\u0438\u043d\u0430 \u0440\u0430\u043c\u043a\u0438',
  'Border style': '\u0421\u0442\u0438\u043b\u044c \u0440\u0430\u043c\u043a\u0438',
  Error: '\u041e\u0448\u0438\u0431\u043a\u0430',
  Warn: '\u041f\u0440\u0435\u0434\u0443\u043f\u0440\u0435\u0436\u0434\u0435\u043d\u0438\u0435',
  Valid: '\u0414\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u044b\u0439',
  'To open the popup, press Shift+Enter':
    '\u0427\u0442\u043e\u0431\u044b \u043e\u0442\u043a\u0440\u044b\u0442\u044c \u0432\u0441\u043f\u043b\u044b\u0432\u0430\u044e\u0449\u0435\u0435 \u043e\u043a\u043d\u043e, \u043d\u0430\u0436\u043c\u0438\u0442\u0435 Shift+Enter',
  'Rich Text Area':
    '\u041f\u043e\u043b\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u043e\u0433\u043e \u0442\u0435\u043a\u0441\u0442\u0430',
  'Rich Text Area. Press ALT-0 for help.':
    '\u041f\u043e\u043b\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u043e\u0433\u043e \u0442\u0435\u043a\u0441\u0442\u0430. \u041d\u0430\u0436\u043c\u0438\u0442\u0435 ALT-0, \u0447\u0442\u043e\u0431\u044b \u043e\u0442\u043a\u0440\u044b\u0442\u044c \u0441\u043f\u0440\u0430\u0432\u043a\u0443.',
  'System Font': '\u0421\u0438\u0441\u0442\u0435\u043c\u043d\u044b\u0439 \u0448\u0440\u0438\u0444\u0442',
  'Failed to upload image: {0}':
    '\u041e\u0448\u0438\u0431\u043a\u0430 \u043f\u0440\u0438 \u043f\u0435\u0440\u0435\u0434\u0430\u0447\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f: {0}',
  'Failed to load plugin: {0} from url {1}':
    '\u041e\u0448\u0438\u0431\u043a\u0430 \u043f\u0440\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0435 \u043f\u043b\u0430\u0433\u0438\u043d\u0430: {0} \u0438\u0437 URL {1}',
  'Failed to load plugin url: {0}':
    '\u041e\u0448\u0438\u0431\u043a\u0430 \u043f\u0440\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0435 URL \u043f\u043b\u0430\u0433\u0438\u043d\u0430: {0}',
  'Failed to initialize plugin: {0}':
    '\u041e\u0448\u0438\u0431\u043a\u0430 \u043f\u0440\u0438 \u0438\u043d\u0438\u0446\u0438\u0430\u043b\u0438\u0437\u0430\u0446\u0438\u0438 \u043f\u043b\u0430\u0433\u0438\u043d\u0430: {0}',
  example: '\u043f\u0440\u0438\u043c\u0435\u0440',
  Search: '\u041f\u043e\u0438\u0441\u043a',
  All: '\u0412\u0441\u0435',
  Currency: '\u0412\u0430\u043b\u044e\u0442\u0430',
  Text: '\u0422\u0435\u043a\u0441\u0442',
  Quotations: '\u0426\u0438\u0442\u0430\u0442\u044b',
  Mathematical: '\u041c\u0430\u0442\u0435\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0435',
  'Extended Latin':
    '\u0420\u0430\u0441\u0448\u0438\u0440\u0435\u043d\u043d\u0430\u044f \u043b\u0430\u0442\u044b\u043d\u044c',
  Symbols: '\u0421\u0438\u043c\u0432\u043e\u043b\u044b',
  Arrows: '\u0421\u0442\u0440\u0435\u043b\u043a\u0438',
  'User Defined':
    '\u041e\u043f\u0440\u0435\u0434\u0435\u043b\u044f\u0435\u043c\u044b\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u043c',
  'dollar sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0434\u043e\u043b\u043b\u0430\u0440\u0430',
  'currency sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0432\u0430\u043b\u044e\u0442\u044b',
  'euro-currency sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0435\u0432\u0440\u043e',
  'colon sign': '\u0414\u0432\u043e\u0435\u0442\u043e\u0447\u0438\u0435',
  'cruzeiro sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043a\u0440\u0443\u0437\u0435\u0439\u0440\u043e',
  'french franc sign':
    '\u0421\u0438\u043c\u0432\u043e\u043b \u0444\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u043e\u0433\u043e \u0444\u0440\u0430\u043d\u043a\u0430',
  'lira sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043b\u0438\u0440\u044b',
  'mill sign':
    '\u0421\u0438\u043c\u0432\u043e\u043b \u0434\u0435\u0441\u044f\u0442\u043e\u0439 \u0447\u0430\u0441\u0442\u0438 \u0446\u0435\u043d\u0442\u0430',
  'naira sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043d\u0430\u0439\u0440\u044b',
  'peseta sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043f\u0435\u0441\u0435\u0442\u044b',
  'rupee sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0440\u0443\u043f\u0438\u0438',
  'won sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0432\u043e\u043d\u044b',
  'new sheqel sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0448\u0435\u043a\u0435\u043b\u044f',
  'dong sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0434\u043e\u043d\u0433\u0430',
  'kip sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043a\u0438\u043f\u044b',
  'tugrik sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0442\u0443\u0433\u0440\u0438\u043a\u0430',
  'drachma sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0434\u0440\u0430\u0445\u043c\u044b',
  'german penny symbol': '\u0441\u0438\u043c\u0432\u043e\u043b \u043f\u0444\u0435\u043d\u043d\u0438\u0433\u0430',
  'peso sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043f\u0435\u0441\u043e',
  'guarani sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0433\u0443\u0430\u0440\u0430\u043d\u0438',
  'austral sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0430\u0443\u0441\u0442\u0440\u0430\u043b\u0430',
  'hryvnia sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0433\u0440\u0438\u0432\u043d\u0438',
  'cedi sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0441\u0435\u0434\u0438',
  'livre tournois sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043b\u0438\u0432\u0440\u044b',
  'spesmilo sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0441\u043f\u0435\u0441\u043c\u0438\u043b\u043e',
  'tenge sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0442\u0435\u043d\u044c\u0433\u0435',
  'indian rupee sign':
    '\u0421\u0438\u043c\u0432\u043e\u043b \u0438\u043d\u0434\u0438\u0439\u0441\u043a\u043e\u0439 \u0440\u0443\u043f\u0438\u0438',
  'turkish lira sign':
    '\u0421\u0438\u043c\u0432\u043e\u043b \u0442\u0443\u0440\u0435\u0446\u043a\u043e\u0439 \u043b\u0438\u0440\u044b',
  'nordic mark sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043c\u0430\u0440\u043a\u0438',
  'manat sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u043c\u0430\u043d\u0430\u0442\u0430',
  'ruble sign': '\u0421\u0438\u043c\u0432\u043e\u043b \u0440\u0443\u0431\u043b\u044f',
  'yen character': '\u0441\u0438\u043c\u0432\u043e\u043b \u0438\u0435\u043d\u044b',
  'yuan character': '\u0441\u0438\u043c\u0432\u043e\u043b \u044e\u0430\u043d\u044f',
  'yuan character, in hong kong and taiwan':
    '\u0421\u0438\u043c\u0432\u043e\u043b \u044e\u0430\u043d\u044f, \u0413\u043e\u043d\u043a\u043e\u043d\u0433 \u0438 \u0422\u0430\u0439\u0432\u0430\u043d\u044c',
  'yen/yuan character variant one':
    '\u0441\u0438\u043c\u0432\u043e\u043b \u0438\u0435\u043d\u044b/\u044e\u0430\u043d\u044f, \u0432\u0430\u0440\u0438\u0430\u043d\u0442 1',
  Emojis: '\u0421\u043c\u0430\u0439\u043b\u0438\u043a\u0438',
  'Emojis...': '\u0421\u043c\u0430\u0439\u043b\u0438\u043a\u0438...',
  'Loading emojis...':
    '\u0417\u0430\u0433\u0440\u0443\u0437\u043a\u0430 \u0441\u043c\u0430\u0439\u043b\u0438\u043a\u043e\u0432...',
  'Could not load emojis':
    '\u041d\u0435 \u043f\u043e\u043b\u0443\u0447\u0438\u043b\u043e\u0441\u044c \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u0441\u043c\u0430\u0439\u043b\u0438\u043a\u0438',
  People: '\u041b\u044e\u0434\u0438',
  'Animals and Nature':
    '\u0416\u0438\u0432\u043e\u0442\u043d\u044b\u0435 \u0438 \u043f\u0440\u0438\u0440\u043e\u0434\u0430',
  'Food and Drink': '\u0415\u0434\u0430 \u0438 \u043d\u0430\u043f\u0438\u0442\u043a\u0438',
  Activity: '\u0414\u0435\u044f\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c',
  'Travel and Places':
    '\u041f\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0438\u044f \u0438 \u043c\u0435\u0441\u0442\u0430',
  Objects: '\u041e\u0431\u044a\u0435\u043a\u0442\u044b',
  Flags: '\u0424\u043b\u0430\u0433\u0438',
  Characters: '\u0421\u0438\u043c\u0432\u043e\u043b\u044b',
  'Characters (no spaces)':
    '\u0421\u0438\u043c\u0432\u043e\u043b\u044b (\u0431\u0435\u0437 \u043f\u0440\u043e\u0431\u0435\u043b\u043e\u0432)',
  '{0} characters': '{0} \u0441\u0438\u043c\u0432\u043e\u043b.',
  'Error: Form submit field collision.':
    '\u041e\u0448\u0438\u0431\u043a\u0430: \u043a\u043e\u043d\u0444\u043b\u0438\u043a\u0442 \u043f\u043e\u043b\u0435\u0439 \u043e\u0442\u043f\u0440\u0430\u0432\u043a\u0438 \u0444\u043e\u0440\u043c\u044b.',
  'Error: No form element found.':
    '\u041e\u0448\u0438\u0431\u043a\u0430: \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0444\u043e\u0440\u043c\u044b.',
  'Color swatch': '\u041e\u0431\u0440\u0430\u0437\u0435\u0446 \u0446\u0432\u0435\u0442\u0430',
  'Color Picker': '\u041f\u0438\u043f\u0435\u0442\u043a\u0430 \u0446\u0432\u0435\u0442\u0430',
  'Invalid hex color code: {0}':
    '\u041d\u0435\u0432\u0435\u0440\u043d\u044b\u0439 HEX-\u043a\u043e\u0434 \u0446\u0432\u0435\u0442\u0430: {0}',
  'Invalid input': '\u041d\u0435\u0432\u0435\u0440\u043d\u043e\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435',
  R: 'R',
  'Red component':
    '\u041a\u0440\u0430\u0441\u043d\u0430\u044f \u043a\u043e\u043c\u043f\u043e\u043d\u0435\u043d\u0442\u0430',
  G: 'G',
  'Green component':
    '\u0417\u0435\u043b\u0435\u043d\u0430\u044f \u043a\u043e\u043c\u043f\u043e\u043d\u0435\u043d\u0442\u0430',
  B: 'B',
  'Blue component': '\u0421\u0438\u043d\u044f\u044f \u043a\u043e\u043c\u043f\u043e\u043d\u0435\u043d\u0442\u0430',
  '#': '#',
  'Hex color code': 'HEX-\u043a\u043e\u0434 \u0446\u0432\u0435\u0442\u0430',
  'Range 0 to 255': '\u0414\u0438\u0430\u043f\u0430\u0437\u043e\u043d \u043e\u0442 0 \u0434\u043e 255',
  Turquoise: '\u0411\u0438\u0440\u044e\u0437\u043e\u0432\u044b\u0439',
  Green: '\u0417\u0435\u043b\u0435\u043d\u044b\u0439',
  Blue: '\u0421\u0438\u043d\u0438\u0439',
  Purple: '\u0420\u043e\u0437\u043e\u0432\u044b\u0439',
  'Navy Blue': '\u0422\u0435\u043c\u043d\u043e-\u0441\u0438\u043d\u0438\u0439',
  'Dark Turquoise': '\u0422\u0435\u043c\u043d\u043e-\u0431\u0438\u0440\u044e\u0437\u043e\u0432\u044b\u0439',
  'Dark Green': '\u0422\u0435\u043c\u043d\u043e-\u0437\u0435\u043b\u0435\u043d\u044b\u0439',
  'Medium Blue': '\u0421\u0440\u0435\u0434\u043d\u0438\u0439 \u0441\u0438\u043d\u0438\u0439',
  'Medium Purple':
    '\u0423\u043c\u0435\u0440\u0435\u043d\u043d\u043e \u043f\u0443\u0440\u043f\u0443\u0440\u043d\u044b\u0439',
  'Midnight Blue': '\u0427\u0435\u0440\u043d\u043e-\u0441\u0438\u043d\u0438\u0439',
  Yellow: '\u0416\u0435\u043b\u0442\u044b\u0439',
  Orange: '\u041e\u0440\u0430\u043d\u0436\u0435\u0432\u044b\u0439',
  Red: '\u041a\u0440\u0430\u0441\u043d\u044b\u0439',
  'Light Gray': '\u0421\u0432\u0435\u0442\u043b\u043e-\u0441\u0435\u0440\u044b\u0439',
  Gray: '\u0421\u0435\u0440\u044b\u0439',
  'Dark Yellow': '\u0422\u0435\u043c\u043d\u043e-\u0436\u0435\u043b\u0442\u044b\u0439',
  'Dark Orange': '\u0422\u0435\u043c\u043d\u043e-\u043e\u0440\u0430\u043d\u0436\u0435\u0432\u044b\u0439',
  'Dark Red': '\u0422\u0435\u043c\u043d\u043e-\u043a\u0440\u0430\u0441\u043d\u044b\u0439',
  'Medium Gray': '\u0423\u043c\u0435\u0440\u0435\u043d\u043d\u043e \u0441\u0435\u0440\u044b\u0439',
  'Dark Gray': '\u0422\u0435\u043c\u043d\u043e-\u0441\u0435\u0440\u044b\u0439',
  'Light Green': '\u0421\u0432\u0435\u0442\u043b\u043e-\u0437\u0435\u043b\u0435\u043d\u044b\u0439',
  'Light Yellow': '\u0421\u0432\u0435\u0442\u043b\u043e-\u0436\u0435\u043b\u0442\u044b\u0439',
  'Light Red': '\u0421\u0432\u0435\u0442\u043b\u043e-\u043a\u0440\u0430\u0441\u043d\u044b\u0439',
  'Light Purple': '\u0421\u0432\u0435\u0442\u043b\u043e-\u0444\u0438\u043e\u043b\u0435\u0442\u043e\u0432\u044b\u0439',
  'Light Blue': '\u0421\u0432\u0435\u0442\u043b\u043e-\u0441\u0438\u043d\u0438\u0439',
  'Dark Purple': '\u0422\u0435\u043c\u043d\u043e-\u0444\u0438\u043e\u043b\u0435\u0442\u043e\u0432\u044b\u0439',
  'Dark Blue': '\u0422\u0435\u043c\u043d\u043e-\u0441\u0438\u043d\u0438\u0439',
  Black: '\u0427\u0435\u0440\u043d\u044b\u0439',
  White: '\u0411\u0435\u043b\u044b\u0439',
  'Switch to or from fullscreen mode':
    '\u041f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u043f\u043e\u043b\u043d\u043e\u044d\u043a\u0440\u0430\u043d\u043d\u044b\u0439 \u0440\u0435\u0436\u0438\u043c',
  'Open help dialog': '\u041e\u0442\u043a\u0440\u044b\u0442\u044c \u0441\u043f\u0440\u0430\u0432\u043a\u0443',
  history: '\u0438\u0441\u0442\u043e\u0440\u0438\u044f',
  styles: '\u0441\u0442\u0438\u043b\u0438',
  formatting: '\u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435',
  alignment: '\u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  indentation: '\u043e\u0442\u0441\u0442\u0443\u043f',
  Font: '\u0428\u0440\u0438\u0444\u0442',
  Size: '\u0420\u0430\u0437\u043c\u0435\u0440',
  'More...': '\u0411\u043e\u043b\u044c\u0448\u0435...',
  'Select...': '\u0412\u044b\u0431\u0440\u0430\u0442\u044c...',
  Preferences: '\u041f\u0440\u0435\u0434\u043f\u043e\u0447\u0442\u0435\u043d\u0438\u044f',
  Yes: '\u0414\u0430',
  No: '\u041d\u0435\u0442',
  'Keyboard Navigation':
    '\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441 \u043f\u043e\u043c\u043e\u0449\u044c\u044e \u043a\u043b\u0430\u0432\u0438\u0430\u0442\u0443\u0440\u044b',
  Version: '\u0412\u0435\u0440\u0441\u0438\u044f',
  'Code view': '\u041f\u0440\u043e\u0441\u043c\u043e\u0442\u0440 \u043a\u043e\u0434\u0430',
  'Open popup menu for split buttons':
    '\u041e\u0442\u043a\u0440\u044b\u0442\u044c \u0432\u0441\u043f\u043b\u044b\u0432\u0430\u044e\u0449\u0435\u0435 \u043c\u0435\u043d\u044e \u0434\u043b\u044f \u0440\u0430\u0437\u0434\u0435\u043b\u0435\u043d\u0438\u044f \u043a\u043d\u043e\u043f\u043e\u043a',
  'List Properties': '\u0421\u043f\u0438\u0441\u043e\u043a \u0441\u0432\u043e\u0439\u0441\u0442\u0432',
  'List properties...': '\u0421\u043f\u0438\u0441\u043e\u043a \u0441\u0432\u043e\u0439\u0441\u0442\u0432...',
  'Start list at number':
    '\u041d\u0430\u0447\u0430\u0442\u044c \u043d\u0443\u043c\u0435\u0440\u0430\u0446\u0438\u044e \u0441',
  'Line height': '\u0412\u044b\u0441\u043e\u0442\u0430 \u0441\u0442\u0440\u043e\u043a\u0438',
  'Dropped file type is not supported':
    '\u0422\u0438\u043f \u0444\u0430\u0439\u043b\u0430 \u043d\u0435 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442\u0441\u044f',
  'Loading...': '\u0417\u0430\u0433\u0440\u0443\u0437\u043a\u0430...',
  'ImageProxy HTTP error: Rejected request':
    '\u041e\u0448\u0438\u0431\u043a\u0430 HTTP ImageProxy: \u0437\u0430\u043f\u0440\u043e\u0441 \u043e\u0442\u043a\u043b\u043e\u043d\u0435\u043d',
  'ImageProxy HTTP error: Could not find Image Proxy':
    '\u041e\u0448\u0438\u0431\u043a\u0430 HTTP ImageProxy: \u043d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u043d\u0430\u0439\u0442\u0438 Image Proxy',
  'ImageProxy HTTP error: Incorrect Image Proxy URL':
    '\u041e\u0448\u0438\u0431\u043a\u0430 HTTP ImageProxy: \u043d\u0435\u0432\u0435\u0440\u043d\u044b\u0439 URL-\u0430\u0434\u0440\u0435\u0441 Image Proxy',
  'ImageProxy HTTP error: Unknown ImageProxy error':
    '\u041e\u0448\u0438\u0431\u043a\u0430 HTTP ImageProxy: \u043d\u0435\u0438\u0437\u0432\u0435\u0441\u0442\u043d\u0430\u044f \u043e\u0448\u0438\u0431\u043a\u0430 ImageProxy',
});
