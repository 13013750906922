import countryAnalytics from '@/modules/countryАnalytics/countryAnalytics';

export const routes = [
  {
    path: '/Country',
    name: 'countryAnalytics',
    component: countryAnalytics,
  },
  {
    path: '/worldtrade/tecworld',
    name: 'TecWorldView',
    component: () => import('../TecWorldView'),
  },
  {
    path: '/worldtrade/foreigntradeturnoverworld',
    name: 'ForeignTradeTurnoverWorldView',
    component: () => import('../ForeignTradeTurnoverWorldView'),
  },
];
