export function setListGroups(state, res) {
  const { data = {} } = res;
  state.tableInfo = data;
  state.loadPage = false;
}

export function addListGroups(state, res) {
  const { data = {} } = res;
  state.tableInfo.itemCount = data.itemCount;
  state.tableInfo.itemsTotal = data.itemsTotal;
  state.tableInfo.pageNumber = data.pageNumber;
  state.tableInfo.pagesTotal = data.pagesTotal;
  state.tableInfo.items = state.tableInfo.items.concat(data.items);
  state.loadMore = false;
}
