import axios from 'axios';

import Utils from '@/common/utils';

export default {
  async search(request) {
    return await axios.get('api/eventlog/search', { params: request });
  },
  async find(id) {
    return await axios.get(`api/eventlog/${id}`, {});
  },
  async getModules() {
    return await axios.get('api/eventlog/getmodules', {});
  },
  async getSeverities() {
    return await axios.get('api/eventlog/getseverities', {});
  },
  async getEntityTypes() {
    return await axios.get('api/eventlog/getentitytypes', {});
  },
  async getPermissions(request) {
    return await axios.get('api/permission/search', { params: request });
  },
  async deleteAll(request) {
    return await axios.delete('api/eventlog/deleteAll', { params: request });
  },
  async exportExcel(request) {
    return Utils.downloadFile('/api/eventlog/exportExcel', 'AuditLog.xlsx', request);
  },
  async uploadFile(file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`api/eventlog/uploaddata`, formData);
  },
};
