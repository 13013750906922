import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/mpkCommission/search', { params: request });
  },
  searchForList: function (request) {
    var params = Object.assign({ pageNumber: 1, pageSize: 0, shortFormat: true }, request);
    return axios.get('/api/mpkCommission/search', { params: params });
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/mpkCommission/exportExcel', 'MpkCommissions.xlsx', request);
  },
  find: function (id) {
    return axios.get(`/api/mpkCommission/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/mpkCommission/update?id=${data.id}`, data);
    else return axios.post(`/api/mpkCommission/create`, data);
  },
  delete: function (id) {
    return axios.delete('/api/mpkCommission/delete?id=' + id);
  },
  getListByCountry: function (id) {
    return axios.get(`/api/mpkCommission/listByCountry?id=${id}`);
  },
};
