<template>
  <footer>
    <div class="container">
      <div class="footer__inner">
        <div class="footer__inner-col">
          <img class="symbol" src="@/assets/images/gerbOpacit.png" alt="Герб" />
        </div>

        <div class="footer__inner-col">
          <SocialMedia></SocialMedia>
        </div>

        <div class="footer__inner-col">
          <p class="mb-2">Служба технической поддержки:</p>

          <p class="mb-2">
            <a href="mailto:supportved@economy.gov.ru" target="_blank">supportved@economy.gov.ru</a>
          </p>
        </div>
      </div>
    </div>
    <!-- кнопка назад -->
    <button title="Вернуться назад" class="IllBe-back" @click="buttonBackEvent()"></button>
  </footer>
</template>

<script>
  import { debounce } from 'lodash';

  import SocialMedia from '@/common/components/SocialMedia.vue';

  export default {
    components: {
      SocialMedia,
    },
    methods: {
      buttonBackEvent: debounce(function () {
        //чтобы не пропускал защиту множеством кликов и не попадал на ненужные страницы
        this.$router.go(-1);
      }, 300),
      // this.buttonBack++
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/colors.scss';

  footer {
    width: 100%;
    padding: 50px 0 50px 0;
    z-index: 400;
    background: linear-gradient(89.88deg, #0178c8 0.1%, #01b1aa 99.9%);
  }
  .footer {
    &__inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 680px) {
        .social-container {
          order: 6;
        }
        &-col {
          margin-right: 0;
        }
      }

      a,
      p {
        font-family: 'Fira Sans', sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: $whiteMist;
        text-decoration: none;
        margin-bottom: 0px;
      }
      &-col {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 200px;
        width: 100%;
        &:last-of-type {
          margin-right: 0px;
        }
        &:nth-of-type(5) {
          p {
            &:nth-of-type(1) {
              margin-bottom: 14px;
            }
          }
          a {
            text-decoration: underline;
          }
        }
      }
      &-menu {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }
  }
  .symbol {
    width: 83px;

    &-list {
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      gap: 8px;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 1240px) {
    .footer__inner-col {
      margin-bottom: 32px;
    }
  }

  @media (max-width: 768px) {
    .footer__inner-col {
      margin-bottom: 0;
      margin-left: auto;
      align-items: end;
      &:first-child {
        align-items: start;
        grid-row: 1 / 3;
      }
    }
    .footer__inner {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 2fr;
      gap: 2%;
      row-gap: 15%;
      p,
      a {
        width: max-content;
      }
    }
  }
</style>
