<template>
  <BarChart :chart-data="data" :options="options" css-classes="chart-container" :key="keyChart" />
  <slot name="customLegend">
    <div v-if="isShowCustomLegend" class="legend">
      <div class="legend__wrapper">
        <div class="legend__label" @click="onLegendClicked(0)">
          <span :style="{ background: colorFirst }"></span>
          <p id="legend-label-0" :style="{ color: legendLabelColor }">{{ labelFirst }}</p>
        </div>
        <div class="legend__label" @click="onLegendClicked(1)">
          <span :style="{ background: colorSecond }"></span>
          <p id="legend-label-1" :style="{ color: legendLabelColor }">
            {{ labelSecond }}
          </p>
        </div>
      </div>
    </div>
  </slot>
</template>

<script>
  import { computed, ref } from 'vue';
  import { BarChart } from 'vue-chart-3';
  import { BarController, BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';
  Chart.register(BarController, CategoryScale, LinearScale, BarElement);
  export default {
    name: 'columnChartDouble',
    components: {
      BarChart,
    },
    data: () => ({
      keyChart: false,
    }),
    props: {
      labelAlignX: {
        type: String,
        default: 'top',
      },
      labelAlignY: {
        type: String,
        default: 'center',
      },
      labelAnchorX: {
        type: String,
        default: 'top',
      },
      labelAnchorY: {
        type: String,
        default: 'center',
      },
      labelSecond: {
        type: String,
        default: '',
      },
      labelFirst: {
        type: String,
        default: '',
      },
      colorFirst: {
        type: String,
        default: '#01A39D',
      },
      colorSecond: {
        type: String,
        default: '#0093BA',
      },
      barPercentage: {
        type: Number,
        default: 0.4,
      },
      chartData: {
        type: Array,
      },
      chartDataTwo: {
        type: Array,
      },
      labels: {
        type: Array,
      },
      indexAxis: {
        type: String,
        default: 'x',
      },
      formatLabel: {
        type: String,
        default: '',
      },
      enableTooltip: {
        type: Boolean,
        default: true,
      },
      isShowCustomLegend: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const dataValues = ref(props.chartData);
      const dataValues2 = ref(props.chartDataTwo);
      const data = computed(() => ({
        labels: props.labels,
        datasets: [
          {
            label: props.labelFirst,
            data: dataValues.value,
            backgroundColor: props.colorFirst,
            barPercentage: props.barPercentage,
          },
          {
            label: props.labelSecond,
            data: dataValues2.value,
            backgroundColor: props.colorSecond,
            barPercentage: props.barPercentage,
          },
        ],
      }));

      const options = ref({
        indexAxis: props.indexAxis,
        locale: 'ru-RU',
        clip: false,
        layout: {
          padding: {
            right: props.indexAxis === 'y' ? 70 : 0,
          },
        },
        scales: function () {
          return {
            yAxes: {
              beginAtZero: true,
              ticks: {
                callback: (value) => {
                  return value + props.formatLabel;
                },
                autoSkip: false,
              },
            },
          };
        },
        plugins: {
          tooltip: { enabled: props.enableTooltip },
          legend: {
            display: !props.isShowCustomLegend,
            position: 'bottom',
          },

          title: {
            text: 'Bar',
          },
          datalabels: {
            clamp: true,
            align: props.indexAxis === 'y' ? props.labelAlignY : props.labelAlignX,
            offset: props.labelAlignY === 'end' && props.indexAxis === 'y' ? 0 : -10,
            anchor: props.indexAxis === 'y' ? props.labelAnchorY : props.labelAnchorX,
            color: props.indexAxis === 'y' ? '#000' : '#787f8f',
            textAlign: 'center',
            font: {
              fontSize: 12,
              lineHeight: 2,
            },
            formatter: function (value) {
              return value.toString().replace('.', ',');
            },
          },
          // xaxis:{
          //   categories: this.labelsXaxis
          // }
        },
      });
      return {
        data,
        options,
      };
    },
    computed: {
      legendLabelColor() {
        return this.indexAxis === 'y' ? '#000' : '#787f8f';
      },
    },
    methods: {
      onLegendClicked(i) {
        const hidden = !this.data.datasets[i].hidden;
        this.data.datasets[i].hidden = hidden;
        const legendLabelSpan = document.getElementById('legend-label-' + i);
        legendLabelSpan.style.textDecoration = hidden ? 'line-through' : '';
        this.keyChart = !this.keyChart;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .legend {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    &__wrapper {
      display: flex;
    }
    &__label {
      &:first-of-type {
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        min-width: 40px;
        height: 12px;
        margin-right: 5px;
      }
      p {
        font: $fira-12;
      }
    }
  }
  @media (max-width: $mobile) {
    .legend__wrapper {
      justify-content: start;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
</style>
