<template>
  <div class="container-page">
    <div>
      <p class="title-inner">
        {{ dictionary.title }}. {{ item && item.id ? 'Карточка редактирования' : 'Карточка добавления' }}
      </p>
    </div>

    <div v-if="item">
      <div class="row">
        <div class="col-12">
          <div class="form-group required">
            <ComponentInput
              v-model="item.name"
              label="Наименование"
              required
              maxLength="250"
              :disabled="isReadonly || !canEdit"
              placeholder="Введите наименование"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="item.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>

    <div>
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/dictionary';

  export default {
    name: 'DictionaryItemCard',
    components: {
      ButtonStock,
      ComponentInput,
    },
    mixins: [security],
    data() {
      return {
        name: this.$route.params.name,
        id: this.$route.params.id,
        isReadonly: this.$route.params.action === 'view',
        backUrl: `/nsi/dictionary/${this.$route.params.name}`,
        canEdit: false,
        dictionary: {},
        item: {
          name: '',
          isActive: true,
        },
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.NsiAccess);
      this.checkPermission(Constants.Permissions.NsiEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadCard();
    },
    methods: {
      loadCard() {
        API.getDictionary(this.name).then((response) => {
          this.dictionary = response.data;
        });
        if (this.id > 0) {
          API.getItem({ name: this.name, id: this.id }).then((response) => {
            this.item = response.data;
          });
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        var self = this;
        if (this.item.id) {
          API.update(this.name, this.item)
            .then(function () {
              Constants.alert.fire('Сохранение', 'Изменения сохранены.', 'success');
              self.$router.push({ path: self.backUrl });
            })
            .catch(function () {
              Constants.alert.fire('Сохранение', 'Ошибка при сохранении изменений.', 'error');
            });
        } else {
          API.create(this.name, this.item)
            .then(function () {
              Constants.alert.fire('Сохранение', 'Новая запись справочника сохранена.', 'success');
              self.$router.push({ path: self.backUrl });
            })
            .catch(function () {
              Constants.alert.fire('Сохранение', 'Ошибка при создании записи справочника.', 'error');
            });
        }
      },
      close() {
        this.$router.push({ path: this.backUrl });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
