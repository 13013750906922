<template>
  <div class="home container-page">
    <smart-widget-grid :resizable="false" :responsive="true" :layout="layout" :row-height="48" :margin="[20, 20]">
      <!-- Интерфейс по стране -->
      <template #0>
        <WidgetStock title="Интерфейс по стране" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="widget-custom-in">
            <SelectComponent class="mr-6" placeholder="Выберите страну" :options="countryList" v-model="testSelected" />
            <ButtonStock @click="jumpToSelectedCountry(testSelected)" title="Перейти" />
          </div>
        </WidgetStock>
      </template>

      <!-- Аналитика по стране -->
      <template #1>
        <WidgetStock title="Аналитика по стране" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget4 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- News -->
      <template #2>
        <WidgetStock title="Новости" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget2 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- Кнопки -->
      <template #3>
        <WidgetStock class="widget-transparent" title="Кнопки" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget1 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- Профили мпк -->
      <template #4>
        <WidgetStock title="профили МПК:" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget5 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- Календарь -->
      <template #5>
        <WidgetStock title="Календарь" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget6 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- Анализ СМИ  -->
      <template #6>
        <WidgetStock title="Анализ СМИ" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget11 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- Уведомления -->
      <template #7>
        <WidgetStock title="Уведомления" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget12 :countryId="countrySelected" />
        </WidgetStock>
      </template>

      <!-- Мои задачи  -->
      <template #8>
        <WidgetStock title="Мои задачи" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget3 :countryId="countrySelected" />
        </WidgetStock>
      </template>
    </smart-widget-grid>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  import SelectComponent from '@/common/components/selectComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import WidgetStock from '@/components/widgets/WidgetStock.vue';

  export default {
    name: 'home',
    components: {
      WidgetStock,
      ButtonStock,
      SelectComponent,
      Widget1: defineAsyncComponent(() => import('../components/Widget1.vue')),
      Widget2: defineAsyncComponent(() => import('../components/Widget2.vue')),
      Widget3: defineAsyncComponent(() => import('../components/Widget3.vue')),
      Widget4: defineAsyncComponent(() => import('../components/Widget4.vue')),
      Widget5: defineAsyncComponent(() => import('../components/Widget5.vue')),
      Widget6: defineAsyncComponent(() => import('../components/Widget6.vue')),
      // Widget7: defineAsyncComponent(() => import("../components/Widget7.vue")),
      // Widget8: defineAsyncComponent(() => import("../components/Widget8.vue")),
      // Widget9: defineAsyncComponent(() => import("../components/Widget9.vue")),
      Widget11: defineAsyncComponent(() => import('../components/Widget11.vue')),
      Widget12: defineAsyncComponent(() => import('../components/Widget12.vue')),
      //Widget13: defineAsyncComponent(() => import("../components/Widget13.vue")),
    },
    data() {
      return {
        countrySelected: null,
        testSelected: '',
        layout: [
          // i = id элемента template
          // Интерфейс по стране
          { x: 0, y: 0, w: 4, h: 3, i: '0' },
          // Аналитика по стране
          { x: 0, y: 1, w: 4, h: 3, i: '1' },
          // News
          { x: 4, y: 0, w: 4, h: 9, i: '2' },
          // Кнопки
          { x: 8, y: 0, w: 2, h: 4, i: '3' },
          // Профили мпк
          { x: 0, y: 2, w: 4, h: 3, i: '4' },
          // Календарь
          { x: 0, y: 3, w: 6, h: 9, i: '5' },
          // Анализ СМИ
          { x: 6, y: 3, w: 4, h: 9, i: '6' },
          // Уведомления
          { x: 0, y: 4, w: 4, h: 9, i: '7' },
          // Мои задачи
          { x: 4, y: 4, w: 6, h: 9, i: '8' },
        ],
        countryList: [{ id: 0, text: 'Выберите страну' }],
      };
    },
    created() {
      Utils.loadSelectOptions('countryList', this.countryList);
    },
    methods: {
      gearMethod() {
        // console.log("Gear");
      },
      closeMethod() {
        // console.log("Close");
      },
      jumpToSelectedCountry(id) {
        if (id > 0) this.$router.push({ name: 'intefaceSelected', params: { id: id } });
        else this.$router.push({ name: 'home' });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
