import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/menu/menuFull', { params: request });
  },
  save: function (data) {
    return axios.put(`/api/menu/saveMenu`, data);
  },
};
