<template>
  <div class="market-access-container" v-if="countryId">
    <MarketAccessIndicators :countryId="countryId" @showMoreInfo="onShowMoreInfoClick" />
    <RestrictionMeasures :countryId="countryId" v-if="selectedIndicatorId == 1" />
    <MarketAccessAgreements
      v-if="selectedIndicatorId == 2 || selectedIndicatorId == 3"
      :title="
        selectedIndicatorId == 2
          ? 'Торгово-инвестиционные соглашения с РФ'
          : 'Торгово-инвестиционные соглашения с миром'
      "
      :isWithRussia="selectedIndicatorId == 2"
      :countryId="countryId"
    />
    <CountryBlockControl :countryId="countryId" />
  </div>
</template>

<script>
  import CountryBlockControl from '@/modules/countryAnalyticsNew/components/MarketAccess/nested/CountryBlockControl';
  import MarketAccessAgreements from '@/modules/countryAnalyticsNew/components/MarketAccess/nested/MarketAccessAgreements';
  import MarketAccessIndicators from '@/modules/countryAnalyticsNew/components/MarketAccess/nested/MarketAccessIndicators';
  import RestrictionMeasures from '@/modules/countryAnalyticsNew/components/MarketAccess/nested/RestrictionMeasures';

  export default {
    name: 'MarketAccessControl',
    components: { MarketAccessIndicators, MarketAccessAgreements, RestrictionMeasures, CountryBlockControl },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    emits: ['indicatorChanged'],
    data() {
      return {
        selectedIndicatorId: 1,
      };
    },
    created() {
      this.$emit('indicatorChanged', this.selectedIndicatorTitle);
    },
    methods: {
      onShowMoreInfoClick(id) {
        this.selectedIndicatorId = id;
      },
    },
    watch: {
      selectedIndicatorId: function () {
        this.$emit('indicatorChanged', this.selectedIndicatorTitle);
      },
    },
    computed: {
      selectedIndicatorTitle() {
        let indicatorId = this.selectedIndicatorId ?? '';
        switch (indicatorId.toString()) {
          case '1':
            return 'Реестр ограничительных мер, применяемых третьими странами в отношении российских товаров, услуг и инвестиций';
          case '2':
            return 'Торгово-инвестиционные соглашения с РФ';
          case '3':
            return 'Торгово-инвестиционные соглашения с миром';
          default:
            return '';
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
