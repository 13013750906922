<template>
  <button class="sort-control" :class="classes" type="button">
    <div class="sort-control__content">
      <slot></slot>
    </div>
    <IconComponent v-if="order" class="sort-control__icon" name="chevronDownSort" />
    <IconComponent v-else class="sort-control__icon" name="double-chevron" />
  </button>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'SortControl',

    props: {
      order: {
        type: [String],
        required: false,
        validator: (value) => {
          return ['ascending', 'descending'].includes(value);
        }
      }
    },

    components: {
      IconComponent
    },

    computed: {
      classes() {
        return {
          [`sort-control--order-${this.order}`]: this.order
        };
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sort-control {
    --sort-control-order: 0;
    all: unset;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $dark-grey-1;
    cursor: pointer;

    &:hover {
      color: $light-grey-12;
    }
  }

  .sort-control__content {
    all: unset;
    flex: 0 1 auto;
  }

  .sort-control__icon {
    flex: 0 0 auto;
    transform: rotate(calc(-0.5turn * var(--sort-control-order)));
    transition: 0.2s;

    :deep(path) {
      stroke: currentColor;
    }
  }

  .sort-control--order-ascending,
  .sort-control--order-descending {
    color: $light-grey-12;
  }

  .sort-control--order-ascending {
    --sort-control-order: 1;
  }

  .sort-control--order-descending {
    --sort-control-order: 0;
  }
</style>
