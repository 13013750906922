<template>
  <div class="investmentsWithRussia">
    <p class="hint text-align-right bgWhite">Источник: Банк России</p>
    <div class="investmentsWithRussia-head">
      <div class="investmentsWithRussia-head-title" v-if="false">
        <div class="block">
          <span class="category"> Исходящие ПИИ из России </span>
          <span>
            &mdash; <template v-if="summary">{{ getDisplayValue(lastYear?.outgoing) }}</template
            ><template v-else>...</template>
          </span>
          <div class="analytics-source-text" v-if="lastYear?.outgoing?.onDate">
            На дату: {{ $momentFormat(lastYear?.outgoing?.onDate, 'DD MMMM YYYY') }}
          </div>
        </div>

        <div class="block">
          <span class="category"> Входящие ПИИ в Россию </span>
          <span>
            &mdash; <template v-if="summary">{{ getDisplayValue(lastYear?.incoming) }}</template
            ><template v-else>...</template>
          </span>
          <div class="analytics-source-text" v-if="lastYear?.outgoing?.onDate">
            На дату: {{ $momentFormat(lastYear?.outgoing?.onDate, 'DD MMMM YYYY') }}
          </div>
        </div>
      </div>

      <div class="investmentsWithRussia-head-title">
        <div class="block">
          <span class="category"> Накопленные ПИИ из России </span>
          <span>
            &mdash; <template v-if="summary">{{ getDisplayValue(lastYear?.outgoing) }}</template
            ><template v-else>...</template>
          </span>
          <div class="analytics-source-text" v-if="lastYear?.incoming?.onDate">
            На дату: {{ $momentFormat(lastYear?.outgoing?.onDate, 'DD MMMM YYYY') }}
          </div>
        </div>

        <div class="block">
          <span class="category"> Накопленные ПИИ в Россию </span>
          <span>
            &mdash; <template v-if="summary">{{ getDisplayValue(lastYear?.incoming) }}</template
            ><template v-else>...</template>
          </span>
          <div class="analytics-source-text" v-if="lastYear?.incoming?.onDate">
            На дату: {{ $momentFormat(lastYear?.outgoing?.onDate, 'DD MMMM YYYY') }}
          </div>
        </div>
      </div>
    </div>

    <div class="investmentsWithRussia-chart" v-if="summary?.outgoing">
      <p class="chart-title">Накопленные ПИИ из России, млрд $</p>
      <ColumnChart
        :format-label="formatLabel"
        :selectTypeId="selectTypeId"
        :chart-data="summary.outgoing.map((item) => (item.value / 1000).toFixed(2))"
        :labels="summary.outgoing.map((item) => item.year)"
      />
    </div>

    <div class="investmentsWithRussia-chart" v-if="summary?.incoming">
      <p class="chart-title">Накопленные ПИИ в Россию, млрд $</p>
      <ColumnChart
        :format-label="formatLabel"
        :selectTypeId="selectTypeId"
        :chart-data="summary.incoming.map((item) => (item.value / 1000).toFixed(2))"
        :labels="summary.incoming.map((item) => item.year)"
      />
    </div>

    <InvestmentCooperationRussia :countryId="country" :summary="summary" />
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import ColumnChart from '@/components/columnChart';

  import Api from '@/modules/countryАnalytics/api';

  import InvestmentCooperationRussia from './InvestmentCooperationRussia.vue';

  export default {
    name: 'investmentsWithRussia',
    components: {
      ColumnChart,
      InvestmentCooperationRussia,
    },
    props: {
      country: {
        type: String,
        default: '',
      },
    },
    mixins: [text],
    data() {
      return {
        formatLabel: ' $ млрд',
        selectTypeId: 2,
        summary: null,
        lastYear: null,

        suff: ' млрд $ ',
      };
    },

    created() {
      this.loadPage();
    },

    methods: {
      loadPage() {
        this.summary = null;
        this.lastYear = null;
        Api.getInvestementRussia(this.country).then((response) => {
          this.summary = response.data;

          if (this.summary) {
            this.lastYear = {
              incoming: this.summary.incoming[0],
              outgoing: this.summary.outgoing[0],
            };
            this.summary.incoming.reverse();
            this.summary.outgoing.reverse();
          }

          //this.$forceUpdate();
        });
      },

      getDisplayValue(item) {
        if (!item) return '...';

        if (item.isConfidencial) return 'конфиденциально';

        if (item.isLess500) return this.formatNumber(0) + this.suff;

        return this.formatNumber(item.value / 1000, 1) + this.suff;
      },
    },

    watch: {
      country: function () {
        this.loadPage();
      },
    },
  };
</script>

<style scoped lang="scss">
  .investmentsWithRussia {
    &-head {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 40px;

      &-title {
        .block {
          &:not(:last-of-type) {
            margin-bottom: 22px;
          }
          span {
            font: $fira-16;
            color: #333333;
            &.category {
              font: $fira-16-M;
              color: $green;
            }
          }
        }

        .analytics-source-text {
          margin-top: 4px;
        }
      }
      .select2-container {
        max-width: 140px;
      }
    }
    &-chart {
      .chart-title {
        font: $fira-16-M;
        margin-bottom: 6px;
      }
      &:not(:last-of-type) {
        margin-bottom: 36px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import './src/assets/theme/default/colors';
  .select2::v-deep {
    outline: none;
    background-color: #ffffff;
    // Контейнер ширина
    .select2-container {
      outline: none;
      width: 100% !important;
      max-width: initial !important;
      border-radius: 40px;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $color8;
        margin-top: 0 !important;
      }
    }
    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: auto !important;
      border: 1px solid $color8 !important;
      background-color: transparent !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      width: 10px;
      right: 14px;
      transform: translateY(-50%) !important;
    }
    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #989898;
    }
    /* Модификация стрелки */
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 0;
      border-left: 3px solid $color8 !important;
      border-bottom: 3px solid $color8 !important;
      transform: rotate(315deg);
      transition: 0.3s;
      mask-image: unset;
    }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      transform: rotate(135deg);
      margin-top: -3px !important;
    }
  }
  .select2-container--open .select2-dropdown--below {
    border: 1px solid #01a39d;
  }
  .select2-container--default .select2-results > .select2-results__options {
    li {
      border-top: 1px solid #01a39d !important;
    }
    li:hover {
      background-color: #01a39d;
    }
  }
</style>
