import axios from 'axios';

export default {
  /**
   * Get запрос
   * @returns Возвращает количество действующих барьеров (за все года), сумму предполагаемого
   * ущерба и динамику изменения количества барьеров (негативная, нейтральная, положительная)
   */
  getActiveBarrier() {
    return axios.get('/api/restrictionmeasureanalytics/getactivebarrierquantityandallegeddamage');
  },
  /**
   * Get запрос
   * @returns Возвращает количество выявленных барьеров (за текущий год),
   * сумму предполагаемого ущерба и динамику изменения количества барьеров (негативная, нейтральная, положительная)
   */
  getIdentifiedBarrier() {
    return axios.get('/api/restrictionmeasureanalytics/getidentifiedbarrierquantityandallegeddamage');
  },
  /**
   * Get запрос
   * @returns Возвращает количество предотвращенных барьеров (за текущий год),
   * сумму предотвращенного ущерба и динамику изменения количества барьеров (негативная, нейтральная, положительная)
   */
  getRemovedBarrier() {
    return axios.get('/api/restrictionmeasureanalytics/getremovedbarrierquantityandpreventeddamage');
  },
  /**
   * Get запрос
   * @returns Возвращает пары вида "отрасль - количество барьеров" для построения круговой диаграммы.
   * Список отсортирован по количеству барьеров по убыванию, затем по названию отрасли в алфавитном порядке
   */
  getDiagramByIndustry() {
    return axios.get('/api/restrictionmeasureanalytics/getdiagrambyindustry');
  },
  /**
   * Get запрос
   * @returns Возвращает пары вида "страна - количество барьеров" для построения круговой диаграммы.
   * Список отсортирован по количеству барьеров по убыванию, затем по названию страны в алфавитном порядке
   */
  getDiagramByCountry() {
    return axios.get('api/restrictionmeasureanalytics/getdiagrambycountry');
  },
  /**
   * Get запрос
   * @returns Возвращает пары вида "тип меры - количество барьеров" для построения круговой диаграммы.
   * Список отсортирован по количеству барьеров по убыванию, затем по названию страны в алфавитном порядке
   */
  getDiagramByMeasureKind() {
    return axios.get('/api/restrictionmeasureanalytics/getdiagrambymeasurekind');
  },
  /**
   * Get запрос
   * @returns Возвращает данные для диаграммы количества выявленных барьеров по годам и по кварталам с суммой ущерба
   */
  getIdentifiedBarrierHistogram() {
    return axios.get('/api/restrictionmeasureanalytics/getidentifiedbarrierhistogram');
  },
  /**
   * Get запрос
   * @returns Возвращает данные для диаграммы количества устраненных барьеров по годам и по кварталам с суммой ущерба
   */
  getRemovedBarrierHistogram() {
    return axios.get('/api/restrictionmeasureanalytics/getremovedbarrierhistogram');
  },
};
