<template>
  <div class="container-page"></div>
</template>

<script>
  export default {
    name: 'OkvedCard',
  };
</script>

<style scoped></style>
