import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/widget/WidgetTypes', { params: request });
  },
  deactivateWidget: function (data) {
    return axios.put(`/api/widget/deactivateWidget`, data);
  },
  getWidgetTypes: function () {
    return axios.get('/api/widget/ListTypes');
  },
  updateWidgetTypes: function (items) {
    return axios.put(`/api/widget/updateWidgetTypes`, items);
  },
};
