<template>
  <div class="institutional-forms">
    <div class="commision-mpk__container">
      <!-- table -->
      <div class="commision-mpk__table table-container js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Полное название комиссии рабочей группы (РГ)</th>
              <th>Председатель Российской части МПК / российской части РГ</th>
              <th>Председатель иностранной части МПК/КВУ/ГВУ и РГ</th>
              <th>Заместитель Председателя Российской части МПК</th>
              <th>Ответственный секретарь российской части МПК/РГ</th>
              <th>Встреча</th>
              <th>Заседание МПК (заседание РГ)</th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput placeholder="Название" v-model="filter.name" v-on:valueChangedDebounced="changeFilter" />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="Председатель"
                  v-model="filter.chairman"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="Председатель"
                  v-model="filter.chairmanForeign"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="Заместитель"
                  v-model="filter.coChairman"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  placeholder="Секретарь"
                  v-model="filter.secretary"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    class="mr-2"
                    placeholder="от"
                    type="date"
                    v-model="filter.lastEventFrom"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                  <FilterInput
                    placeholder="до"
                    type="date"
                    v-model="filter.lastEventTo"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </div>
              </th>
              <th class="filter">
                <div class="range">
                  <FilterInput
                    class="mr-2"
                    placeholder="от"
                    type="date"
                    v-model="filter.lastEventZasedFrom"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                  <FilterInput
                    placeholder="до"
                    type="date"
                    v-model="filter.lastEventZasedTo"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length">
            <tr
              v-for="item in page.items"
              :key="item.id"
              @dblclick="viewCard(item)"
              :class="item.parentId ? '' : 'tr-commission'"
            >
              <td>{{ item.name }}</td>
              <td>
                <template v-if="item.chairman">
                  <span class="name">{{ item.chairman.name }}</span>
                  {{ item.chairman.position }}
                </template>
                <template v-else> (уточняется) </template>
              </td>
              <td>
                <template v-if="item.chairmanForeign">
                  <span class="name">{{ item.chairmanForeign.name }}</span>
                  {{ item.chairmanForeign.position }}
                </template>
                <template v-else> (уточняется) </template>
              </td>
              <td>
                <template v-if="!item.parentId">
                  <template v-if="item.coChairman">
                    <span class="name">{{ item.coChairman.name }}</span>
                    {{ item.coChairman.position }}
                  </template>
                  <template v-else> (уточняется) </template>
                </template>
              </td>
              <td>
                <template v-if="!item.parentId">
                  <template v-if="item.secretary">
                    <span class="name">{{ item.secretary.name }}</span>
                    {{ item.secretary.position }} {{ item.secretary.phone }}
                  </template>
                  <template v-else> (уточняется) </template>
                </template>
              </td>
              <td>
                <template v-if="item.lastEvent">
                  <span class="date">{{ $momentFormat(item.lastEvent.eventFirstDate, 'DD MMMM YYYY') }}</span>
                  {{ item.lastEvent.place }}
                </template>
                <template v-else> (уточняется) </template>
              </td>
              <td>
                <template v-if="item.lastEventZased">
                  <span class="date">{{ $momentFormat(item.lastEventZased.eventFirstDate, 'DD MMMM YYYY') }}</span>
                  {{ item.lastEventZased.place }}
                </template>
                <template v-else> (уточняется) </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>

    <!-- <h2>Список документов</h2>
    <ul class="docs__list d-flex justify-content-between">
      <li class="docs__item" @click="active = 1">
        <icon-component class="mr-2" name="docsIcon" />
        <p>Политическая обстановка</p>
      </li>
      <li class="docs__item" @click="active = 2">
        <icon-component class="mr-2" name="docsIcon" />
        <p>Членство страны в региональных блоках, международных организациях/объединениях</p>
      </li>
      <li class="docs__item" @click="active = 3">
        <icon-component class="mr-2" name="docsIcon" />
        <p>Зоны свободной торговли</p>
      </li>
    </ul>
    <div class="content mt-5">
      <div v-if="active === 1">
        <p>
          Lorem1 ipsum — классический текст-«рыба». Является искажённым отрывком
          из философского трактата Марка Туллия Цицерона «О пределах добра и
          зла», написанн на латинском языке, обнаружение
          сходства приписывается Ричарду МакКлинтоку.
        </p>
      </div>
      <div v-if="active === 2">
        <p>
          Lorem2 ipsum — классический текст-«рыба». Является искажённым отрывком

        </p>
      </div>
      <div v-if="active === 3">
        <p>
          Lorem3 ipsum — классический текст-«рыба». Является искажённым отрывком
          из философского трактата Марка Туллия Цицерона «О пределах добра и
          зла», написанного в 45 году до н. э. на латинском языке, обнаружение
          сходства приписывается Ричарду МакКлинтоку.
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
  //import IconComponent from "@/common/ui/IconComponent";
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';

  import API from '../../../mpk/api/commission';
  // import DefaultFilter from "@/common/components/DefaultFilter";

  export default {
    name: 'InstitutionalForms',
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    components: {
      FilterInput,
      // DefaultFilter
    },
    mixins: [security, filtermanager],
    data() {
      return {
        canView: false,
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},
        filter: {
          name: '',
          countryId: this.countryId,
          chairman: '',
          chairmanForeign: '',
          coChairman: '',
          secretary: '',
          lastEventFrom: null,
          lastEventTo: null,
          lastEventZasedFrom: null,
          lastEventZasedTo: null,
          nextEventZasedFrom: null,
          nextEventZasedTo: null,
        },
      };
    },
    watch: {
      countryId: function (newValue) {
        this.filter.countryId = newValue;
        this.changeFilter();
      },
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        if (this.countryId) {
          this.loadPage(false);
        }
      });

      this.checkPermission(Constants.Permissions.MpkCommissionAccess).then((response) => {
        this.canView = response.data;
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        var params = Object.assign(this.filter, this.request);
        API.search(params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
              this.updateValue++;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
            this.updateValue++;
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      viewCard(item) {
        var _type = 'mp';
        if (_type === 'mp' && item.parentId > 0) _type = 'rg';
        this.$router.push({ name: 'CommissionCard', params: { id: item.id, type: _type, action: 'view' } });
      },
    },
  };
</script>

<style scoped lang="scss">
  .docs__list {
    align-items: center;
  }
  h2 {
    margin-bottom: 24px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  .docs__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    a {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
    }
  }

  .tr-commission {
    background: rgba(232, 232, 232, 0.5);
  }
</style>
