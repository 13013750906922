<template>
  <div class="container-page">
    <div class="list-of-users">
      <!-- title -->
      <p class="list-of-users__title title-inner">Список уведомляемых пользователей</p>
      <!-- search -->
      <div class="list-of-users__search">
        <div class="list-of-users__search-container">
          <div class="list-of-users__search-inputBlock">
            <p>Поиск</p>
            <input placeholder="ФИО" type="text" v-model="searchName" v-on:keyup.enter="search(true)" />
            <IconComponent name="search2" @click="search(true)" />
          </div>
        </div>
      </div>
      <div class="mb-16" v-if="Object.keys(changedItemBag).length">
        <ButtonStock class="mr-2" title="Сохранить" @click="saveNotifications()" />
        <ButtonStock title="Сбросить" @click="resetNotifications()" />
      </div>
      <!-- table -->
      <div v-if="page.items">
        <div class="list-of-users__table table-container js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th rowspan="2">ФИО</th>
                <th rowspan="2">Адрес электронной почты</th>
                <th colspan="3">Уведомления</th>
              </tr>
              <tr>
                <th>Системные</th>
                <th>По электронной почте</th>
                <th>Push</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.items" :key="item.id">
                <td>{{ item.fullName }}</td>
                <td>{{ item.user?.email }}</td>
                <td class="checkboxInput">
                  <ComponentInput
                    type="checkbox"
                    v-model="item.user.notifyBySystemMessages"
                    :disabled="!canEdit"
                    @update:modelValue="changeNotification(item)"
                  />
                </td>
                <td class="checkboxInput">
                  <ComponentInput
                    type="checkbox"
                    v-model="item.user.notifyByEmail"
                    :disabled="!canEdit"
                    @update:modelValue="changeNotification(item)"
                  />
                </td>
                <td class="checkboxInput">
                  <ComponentInput
                    type="checkbox"
                    v-model="item.user.notifyByPush"
                    :disabled="!canEdit"
                    @update:modelValue="changeNotification(item)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // Additional
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/users';

  export default {
    name: 'UserNotifications',
    components: {
      IconComponent,
      ButtonStock,
      ComponentInput,
    },
    mixins: [security],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        searchName: '',
        filter: {
          fullName: '',
          sortOrder: '1',
        },
        canEdit: false,
        changedItemBag: {},
      };
    },
    mounted() {
      this.checkPermission(Constants.Permissions.UserNotificationEdit).then((response) => {
        this.canEdit = response.data;
        this.loadPage();
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        const request = Object.assign(this.filter, this.request);
        API.search(request).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
          this.$nextTick(() => {
            this.page.items.forEach((x) => {
              if (this.changedItemBag[x.user.id]) {
                x.user.notifyByEmail = this.changedItemBag[x.user.id].notifyByEmail;
                x.user.notifyByPush = this.changedItemBag[x.user.id].notifyByPush;
                x.user.notifyBySystemMessages = this.changedItemBag[x.user.id].notifyBySystemMessages;
              }
            });
          });
        });
      },
      search(isNameChange) {
        if (isNameChange) this.filter.fullName = this.searchName;
        this.request.pageNumber = 1;
        this.loadPage(false);
      },
      changeNotification(item) {
        this.changedItemBag[item.user.id] = {
          notifyByEmail: item.user.notifyByEmail,
          notifyByPush: item.user.notifyByPush,
          notifyBySystemMessages: item.user.notifyBySystemMessages,
        };
      },
      resetNotifications() {
        this.changedItemBag = {};
        this.loadPage(false);
      },
      saveNotifications() {
        API.saveNotifications(this.changedItemBag)
          .then(() => {
            Constants.alert.fire('Сохранение', 'Настройки уведомлений сохранены', 'success');
            this.request.pageNumber = 1;
            this.resetNotifications();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', 'Не удалось сохранить настройки.', 'error');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }

  .list-of-users {
    padding-bottom: 80px;
    &__search {
      &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px dashed #d7d7d7;
      }
      &-inputBlock {
        position: relative;
        width: 100%;
        input {
          width: 50%;
          padding: 8px 21px;
          border-radius: 5px;
        }
        .icon {
          position: absolute;
          bottom: 12px;
          right: calc(50% + 12px);
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: #343c44;
          margin-bottom: 8px;
        }
      }
    }
    &__table {
      table {
        thead {
          th {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #3b4d3e;
            padding: 16px;
            border-bottom: 1px solid #787f8f;
          }
          tr {
            &:nth-of-type(1) {
              th {
                &:nth-of-type(1) {
                  width: 40%;
                  border-right: 1px solid #787f8f;
                }
                &:nth-of-type(2) {
                  width: 40%;
                  border-right: 1px solid #787f8f;
                }
                &:nth-of-type(3) {
                  text-align: center;
                  width: 20%;
                }
              }
            }
            &:nth-of-type(2) {
              th {
                &:nth-of-type(1),
                &:nth-of-type(2) {
                  width: 10%;
                  border-right: 1px solid #787f8f;
                }
                &:nth-of-type(3) {
                  width: 10%;
                }
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #787f8f;
          }
          td {
            padding: 16px;
            font-size: 14px;
            line-height: 150%;
            color: #3b4d3e;
            &:not(:last-child) {
              border-right: 1px solid #787f8f;
            }
          }
        }
        .table-statusBlock {
          display: flex;
          p {
            margin-right: auto;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .checkboxInput::v-deep input[type='checkbox'] {
    position: relative !important;
    width: 20px !important;
    height: 20px !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 0 !important;
    margin: 0 auto;
  }
</style>
