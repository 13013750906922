<template>
  <div class="container-page">
    <!-- title -->
    <p v-if="showTitle" class="list-projects__title title-inner">Торгово-инвестиционные соглашения</p>

    <div v-if="!readonly" class="list-projects__search-visible">
      <div class="list-projects__search-visible-block">
        <ButtonStock title="Создать" @click="editCard(0)" />
      </div>
    </div>

    <!-- Results table -->
    <div class="list-projects__results-table table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th>Название</th>
            <th>Тип</th>
            <th>Стороны</th>
            <th>Охват</th>
            <th>Активность</th>
            <th>Источник данных</th>
            <th v-if="!readonly"></th>
            <th v-if="!readonly"></th>
          </tr>
          <tr>
            <th class="filter">
              <FilterInput v-model="filter.name" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="typeList"
                :select2-settings="select2Settings"
                :multiple="true"
                v-model="filter.typeId"
                placeholder="(Все)"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="countryList"
                :select2-settings="select2Settings"
                v-model="filter.sidesId"
                placeholder="(Все)"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="tradeItemTypeList"
                :select2-settings="select2Settings"
                v-model="filter.tradeItemTypesId"
                placeholder="(Все)"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterActiveInput
                v-model="filter.isActive"
                placeholder="(Все)"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.source" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th v-if="!readonly"></th>
            <th v-if="!readonly"></th>
          </tr>
        </thead>

        <tbody v-if="page.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id" @dblclick="viewCard(item.id)">
            <td>{{ item.name }}</td>
            <td>{{ item.type.name }}</td>
            <td>{{ item.sidesStr }}</td>
            <td>{{ item.tradeItemTypesStr }}</td>
            <td>{{ item.isActive ? 'Да' : 'Нет' }}</td>
            <td>
              <a class="info-link" v-for="info in item.additionalInfo" :key="info.id" :href="info.url">{{
                info.name
              }}</a>
            </td>
            <td v-if="!readonly">
              <IconComponent @click="editCard(item.id)" name="mode" />
            </td>
            <td v-if="!readonly">
              <IconComponent @click="deleteCard(item.id)" name="delete" />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" :colspan="emptyColSpan">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" :colspan="emptyColSpan">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  // Additional
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_NSI from '@/modules/nsi/api/nsi';

  import FilterActiveInput from '../../../common/components/FilterActiveInput';
  import FilterInput from '../../../common/components/FilterInput';
  import API from '../api/index';

  export default {
    name: 'Agreements',
    components: {
      FilterActiveInput,
      FilterInput,
      ButtonStock,
      IconComponent,
    },
    mixins: [filtermanager, security],
    props: {
      countryId: {
        type: String,
        default: '',
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
    },
    watch: {
      countryId: function (newValue) {
        this.filter.sidesIds = newValue ? [newValue] : null;
        this.filter.sidesId = newValue ? newValue : null;
        this.loadPage(false);
      },
    },
    mounted() {
      // this.checkPermission(Constants.Permissions.FreeTradeAgreement).then(
      //   (res) => {
      //     if (!res.data) {
      //       Constants.alert.fire(
      //         "Нет доступа",
      //         "У Вас нет доступа к соглашений о свободной торговле.",
      //         "error",
      //       );
      //       this.$router.push("/");
      //     } else {
      //       this.loadPage();
      //       this.loadDictionaries();
      //     }
      //   },
      //);
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }

        if (this.countryId) {
          this.filter.sidesIds = [this.countryId];
          this.filter.sidesId = this.countryId;
        }
        this.loadPage();
        this.loadDictionaries();
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);

        API.search({
          ...request,
          isPublic: false,
        })
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        var request = { pageNumber: 1, pageSize: 0 };
        Utils.loadSelectOptions('countryList', this.countryList);
        API_NSI.search('NsiFreeTradeAgreementType', request).then((response) => {
          if (response.data) {
            this.typeList = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_NSI.search('NsiTradeItemType', request).then((response) => {
          if (response.data) {
            this.tradeItemTypeList = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      editCard(id) {
        this.$router.push({ name: 'EditAgreement', params: { action: 'edit', id: id } });
      },
      viewCard(id) {
        this.$router.push({ name: 'EditAgreement', params: { action: 'view', id: id } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.filter.sidesIds = this.filter.sidesId ? [this.filter.sidesId] : null;
        this.filter.typeIds = this.filter.typeId ? [this.filter.typeId] : null;
        this.filter.tradeItemTypesIds = this.filter.tradeItemTypesId ? [this.filter.tradeItemTypesId] : null;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
    },
    data() {
      return {
        page: {
          items: null,
          pageNumber: null,
          pagesTotal: null,
        },
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          name: null,
          typeIds: null,
          sidesIds: null,
          tradeItemTypesIds: null,

          typeId: null,
          sideId: null,
          tradeItemTypeId: null,

          isActive: null,
          source: null,
        },

        tradeItemTypeList: [],
        countryList: [],
        typeList: [],
        select2Settings: Constants.select2Settings,

        isLoading: false,
      };
    },
    computed: {
      emptyColSpan() {
        return this.readonly ? 6 : 8;
      },
    },
  };
</script>

<style lang="scss">
  .list-projects__search-additional-inputBlock {
    .select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__clear {
      right: 22px;
      top: 50%;
    }
    .select2 .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-bottom: 6px;
    }
    &.date {
      .input-group-wrapper input:not([type='checkbox']) {
        line-height: 18px;
        padding: 0px 8px;
      }
      .clear-button-month {
        margin-left: -40px;
        margin-top: 3px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .info-link {
    display: block;
  }
  .table-container table.stockTable thead th.filter {
    padding: 6px 4px;
    &:nth-of-type(9) {
      .select2-search__field {
        width: 88%;
      }
    }
  }
  .filter-trigger {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
    &:after {
      content: ' ';
      display: block;
      width: 8px;
      height: 8px;
      border-left: 2.5px solid black;
      border-bottom: 2.5px solid black;
      transform: rotate(315deg);
      transition: 0.3s;
      margin-left: 8px;
    }
    &.active {
      &:after {
        transform: rotate(135deg);
      }
    }
  }
  .list-projects {
    &__search {
      &-visible {
        display: flex;
        margin-bottom: 16px;
        &-block {
          max-width: 130px;
          width: 100%;
          &:not(:last-of-type) {
            margin-right: 16px;
          }
          &.component {
            max-width: 400px;
          }
          &.download {
            margin-left: auto;
          }
        }
        button {
          width: 100%;
        }
      }
      &-additional {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &-col {
          max-width: 450px;
          width: 100%;
        }
        &-inputBlock {
          margin-bottom: 16px;
          .label {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
    &__results {
      &-table {
        margin-bottom: 42px;
        thead {
          &:nth-of-type(1) {
            text-align: center;
          }
        }
        tbody {
          tr {
            &.implemented {
              td {
                &:nth-of-type(1) {
                  color: #7de7a9;
                }
              }
            }
            &.realize {
              td {
                &:nth-of-type(1) {
                  color: #f1c507;
                }
              }
            }
            &.perspective {
              td {
                &:nth-of-type(1) {
                  color: #84c5eb;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .list-projects__search-visible {
      flex-wrap: wrap;
    }
    .list-projects__search-visible-block,
    .list-projects__search-visible-block.component {
      max-width: unset;
      margin-bottom: 16px;
    }
    .list-projects__search-visible-block:not(:last-of-type) {
      margin-right: 0px;
    }
  }
</style>
