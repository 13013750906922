<template>
  <div class="integration-indicators">
    <div class="country d-flex align-items-center">
      <IconComponent name="alertCircle" />
      <h3>{{ countryName }}&nbsp;</h3>
      <span>- член союза ЕАЭС</span>
    </div>
    <ul class="tab__list">
      <li class="tab__item" v-for="tab in tabData" @click="activeTab = tab.text" :key="tab.id">
        <button class="btn-default" :class="{ active: activeTab === tab.text }">
          {{ tab.text }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <Association v-if="activeTab === 'Право союза'" />
      <Tes v-if="activeTab === 'ТЭС'" />
      <InternalMarkets v-if="activeTab === 'Внутренние рынки ЕАЭС'" />
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent';

  import Association from '@/modules/countryАnalytics/components/integrationIndicators/association';
  import InternalMarkets from '@/modules/countryАnalytics/components/integrationIndicators/InternalMarkets';

  import Api from '../../../nsi/api/country';
  import Tes from './Tes';

  export default {
    name: 'IntegrationIndicators',
    components: { InternalMarkets, Tes, Association, IconComponent },
    props: {
      country: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        countryName: '',
        activeTab: 'Право союза',
        tabData: [
          { id: 1, text: 'Право союза' },
          { id: 2, text: 'Внутренние рынки ЕАЭС' },
          { id: 3, text: 'ТЭС' },
        ],
      };
    },
    mounted() {
      Api.search({ ids: [this.country] })
        .then((res) => (this.countryName = res.data.items[0].name))
        .catch((e) => console.error(e));
    },
    watch: {
      country: function (val) {
        {
          if (val) {
            Api.search({ ids: [this.country] })
              .then((res) => (this.countryName = res.data.items[0].name))
              .catch((e) => console.error(e));
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .tab-content {
    margin-top: 40px;
  }
  h3 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .country {
    margin-bottom: 20px;

    p {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .icon {
      margin-right: 6px;
    }
  }
  .tab {
    &__list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__item {
      button {
        background: #c6c7c7;
        padding: 13px 22px;
        border: none;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        margin: 6px auto;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }

      button {
        transition: 0.2s ease-in-out;
        &:hover,
        &.active {
          background-color: #1d87ee;
        }
      }
    }
  }
</style>
