import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/mpkEvent/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/mpkEvent/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/mpkEvent/update?id=${data.id}`, data);
    else return axios.post(`/api/mpkEvent/create`, data);
  },
  delete: function (id) {
    return axios.delete('/api/mpkEvent/delete?id=' + id);
  },
  postRequest: function (request) {
    return axios.post(`/api/mpkeventrequest/bulkcreate`, request);
  },
  getRequests: function (params) {
    return axios.get('/api/mpkeventrequest/search', { params: params });
  },
  getEventListWithRequest: function (params) {
    return axios.get('/api/mpkeventrequest/getEventList', { params: params });
  },
  getParticipants: function (id, params) {
    return axios.get(`/api/mpkevent/participants/${id}`, { params: params });
  },
  addParticipant: function (id, requestTypeId, data) {
    return axios.post(`/api/mpkevent/addparticipant/${id}/${requestTypeId}`, data);
  },
  deleteParticipant: function (id, requestTypeId, participantId) {
    return axios.delete(`/api/mpkevent/deleteparticipant/${id}/${requestTypeId}/${participantId}`, {});
  },
  exportWord: function (request) {
    return Utils.downloadFile('/api/mpkEvent/exportWord', 'MpkEventParticipants.docx', request);
  },
  exportListWord: function (request) {
    return Utils.downloadFile('/api/mpkEvent/exportListWord', 'MpkEventParticipantList.docx', request);
  },
  exportWordProtocol: function (id) {
    return Utils.downloadFile('/api/mpkEvent/exportWordProtocol', 'Protocol.docx', { id: id });
  },
  exportWordPovestka: function (id) {
    return Utils.downloadFile('/api/mpkEvent/exportWordPovestka', 'Povestka.docx', { id: id });
  },
  exportWordProject: function (id) {
    return Utils.downloadFile('/api/mpkEvent/exportWordProject', 'Project.docx', { id: id });
  },
  getUid: function () {
    return axios.get('/api/mpkevent/newuid', {});
  },
  generatePovestka: function (id) {
    return axios.get(`/api/mpkevent/GeneratePovestka?id=${id}`);
  },
  generateProtocol: function (id) {
    return axios.get(`/api/mpkevent/GenerateProtocol?id=${id}`);
  },
  savePovestka: function (id, text) {
    return axios.post(`/api/mpkevent/savePovestka?id=${id}`, { doc: text });
  },
  saveProtocol: function (id, text) {
    return axios.post(`/api/mpkevent/saveProtocol?id=${id}`, { doc: text });
  },
  saveProject: function (id, text) {
    return axios.post(`/api/mpkevent/saveProject?id=${id}`, { doc: text });
  },
  getMpkForEvent: function (request) {
    return axios.get('/api/mpkEvent/commissionSearch', { params: request });
  },
  confirmParticipantMpk: function (id, participantId) {
    return axios.put(`/api/mpkevent/confirmParticipantMpk/${id}/${participantId}`);
  },
  sendNotification: function (eventId, requestTypeId) {
    return axios.post(`/api/mpkevent/sendNotification?eventId=${eventId}&requestTypeId=${requestTypeId}`);
  },
  saveQuestion: function (id, text) {
    return axios.post(`/api/mpkevent/saveQuestion?id=${id}`, { doc: text });
  },
  exportWordQuestion: function (id) {
    return Utils.downloadFile('/api/mpkEvent/exportWordQuestion', 'Questions.docx', { id: id });
  },

  getBusinesstipsearch() {
    return axios.get('/api/mpkEvent/businesstipsearch');
  },
};
