import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/taskeap/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/taskeap/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/taskeap/update?id=${data.id}`, data);
    else return axios.post(`/api/taskeap/create`, data);
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/taskeap/exportExcel', 'Календарь задач.xlsx', request);
  },
  delete: function (id) {
    return axios.delete('/api/taskeap/delete?id=' + id);
  },
  getEmployeeList: function () {
    return axios.get(`/api/taskeap/getEmployeeList`);
  },
  getCuratorList: function () {
    return axios.get(`/api/taskeap/getCuratorList`);
  },
  getManagerList: function () {
    return axios.get(`/api/taskeap/getManagerList`);
  },
};
