<template>
  <div class="restriction-measure-container">
    <p class="restriction-measure-title" id="market-access-data">
      Реестр ограничительных мер, применяемых третьими странами в отношении российских товаров, услуг и инвестиций
    </p>

    <div class="d-flex align-items-center restriction-measure-selectors">
      <div class="country-analytics-new__selector country-analytics-new__filter-selector mr-16">
        <ComponentInput
          class="select2"
          v-model="tradeItemId"
          :placeholder="tradeItemTypeId == 1 ? 'Наименование или код товара' : 'Наименование или код услуги'"
          type="select2"
          :select2Settings="select2SettingsWithAjax"
        />
      </div>

      <SwitchComponent
        class="restriction_toggler"
        textTrue="Услуга"
        textFalse="Товар"
        :checked="false"
        @switchHandler="changeTradeItemType($event)"
      />

      <SwitchComponent
        class="restriction_toggler"
        :class="[{ restriction_toggler_okved: tradeItemTypeId == 2 }]"
        :textTrue="tradeItemTypeId == 1 ? 'Меры' : 'Запреты'"
        :textFalse="tradeItemTypeId == 1 ? 'Угрозы' : 'Ограничения'"
        @switchHandler="changeMeasureActive($event)"
      />
    </div>

    <div
      class="restriction-measure-table-container table-container js-table-arrow"
      v-if="!loading && page.items && page.items.length > 0"
    >
      <div class="stockTable">
        <table>
          <thead v-if="tradeItemTypeId == 1" class="products">
            <tr>
              <th>№ п/п</th>
              <th>Страна</th>
              <th>Наименование товара</th>
              <th>Коды товара<br />страны назначения</th>
              <th>Тип ограничения</th>
              <th>Дата окончания</th>
              <th>Описание</th>
            </tr>
          </thead>
          <thead v-if="tradeItemTypeId == 2" class="services">
            <tr>
              <th>№ п/п</th>
              <th>Страна</th>
              <th>Наименование услуги</th>
              <th>ОКВЭД</th>
              <th>Тип ограничения</th>
              <th>Дата окончания</th>
              <th>Описание меры</th>
            </tr>
          </thead>
          <tbody v-if="tradeItemTypeId == 1" class="products">
            <tr v-for="(restrictionItem, index) of page.items" v-bind:key="index">
              <td>{{ index + 1 + (request.pageNumber - 1) * 10 }}</td>
              <td>
                {{
                  restrictionItem.allCountries ? 'Все страны' : restrictionItem.countries?.map((x) => x.name).join('; ')
                }}
              </td>
              <td>
                {{
                  restrictionItem.allTradeItems ? 'Все товары' : restrictionItem.tnveds?.map((x) => x.name).join('; ')
                }}
              </td>
              <td>
                {{
                  restrictionItem.allTradeItems ? 'Все товары' : restrictionItem.tnveds?.map((x) => x.code).join('; ')
                }}
              </td>
              <td>{{ restrictionItem.measureKind?.name ?? '' }}</td>
              <td>{{ restrictionItem.endDate ? $momentFormat(restrictionItem.endDate, 'DD.MM.YYYY') : '' }}</td>
              <td>{{ restrictionItem.description ?? '' }}</td>
            </tr>
          </tbody>
          <tbody v-if="tradeItemTypeId == 2" class="services">
            <tr v-for="(restrictionItem, index) of page.items" v-bind:key="index">
              <td>{{ index + 1 + (request.pageNumber - 1) * 10 }}</td>
              <td>
                {{
                  restrictionItem.allCountries ? 'Все страны' : restrictionItem.countries?.map((x) => x.name).join('; ')
                }}
              </td>
              <td>
                {{
                  restrictionItem.allTradeItems ? 'Все услуги' : restrictionItem.okveds?.map((x) => x.name).join('; ')
                }}
              </td>
              <td>
                {{
                  restrictionItem.allTradeItems ? 'Все услуги' : restrictionItem.okveds?.map((x) => x.code).join('; ')
                }}
              </td>
              <td>{{ restrictionItem.measureKind?.name ?? '' }}</td>
              <td>{{ restrictionItem.endDate ? $momentFormat(restrictionItem.endDate, 'DD.MM.YYYY') : '' }}</td>
              <td>{{ restrictionItem.description ?? '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="!loading" class="restriction-measure-no-data">Нет данных.</div>

    <div v-if="!loading && page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import runtimeConfig from '@/common/runtime-config';

  import SwitchComponent from '@/components/buttons/switchComponent';

  import Api_RestrictionMeasure from '@/modules/foreignMarket/api/restrictmeasures';

  export default {
    name: 'RestrictionMeasures',
    components: { ComponentInput, SwitchComponent },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        tradeItemId: null,
        tradeItemTypeId: 1,
        measureActive: true,
        select2SettingsWithAjax: null,
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          isActive: true,
        },
        loading: false,
      };
    },
    created() {
      const self = this;
      this.select2SettingsWithAjax = {
        language: Constants.langRu,
        allowClear: true,
        ajax: {
          delay: 500,
          url: runtimeConfig.apiBaseUrl + '/api/nsi/tradeItemListDynamic',
          dataType: 'json',
          data: function (params) {
            var query = {
              term: params.term,
              tradeItemTypeId: self.tradeItemTypeId,
            };
            return query;
          },
          processResults: function (responseData) {
            return {
              results: responseData,
            };
          },
          cache: true,
        },
      };
      this.loadData();
    },
    methods: {
      changeTradeItemType(e) {
        this.tradeItemId = null;
        this.tradeItemTypeId = e ? 2 : 1;
        this.loadData();
      },
      changeMeasureActive(e) {
        this.measureActive = e;
        this.loadData();
      },
      loadData(append) {
        this.loading = true;
        let params = this.getFilterParams();
        Api_RestrictionMeasure.search(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getFilterParams() {
        let params = Object.assign({}, this.request);
        params.countryIds = [this.countryId];

        params.tradeItemTypeId = this.tradeItemTypeId;
        if (this.tradeItemId) {
          if (this.tradeItemTypeId == Constants.tradeItemType.tnved) params.tnvedIds = [this.tradeItemId];
          else params.okvedIds = [this.tradeItemId];
        }

        let barrierCategoryId = 1;
        if (this.measureActive) barrierCategoryId = this.tradeItemTypeId == Constants.tradeItemType.tnved ? 1 : 3;
        else barrierCategoryId = this.tradeItemTypeId == Constants.tradeItemType.tnved ? 2 : 4;
        params.barrierCategoryIds = [barrierCategoryId];

        return params;
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadData(addToExist);
      },
    },
    watch: {
      tradeItemId: function () {
        this.loadData();
      },
      countryId: function () {
        this.loadData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .restriction-measure-title {
    margin-top: 60px;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }
  .restriction-measure-selectors {
    margin-top: 35px;
  }
  .restriction_toggler {
    margin: 12px 90px 0;
  }
  .restriction-measure-table-container,
  .restriction-measure-no-data {
    margin-top: 30px;
  }
</style>
