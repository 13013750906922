<template>
  <ul class="project-tag-list d-flex flex-wrap align-items-center mt-3" v-if="dataInternal">
    <li v-if="showTotals" class="project-tag project-tag-not-clickable">
      Всего стран: {{ dataInternal.countryTotal }}
    </li>
    <li v-if="showTotals" class="project-tag project-tag-not-clickable">
      Всего отраслей: {{ dataInternal.industryTotal }}
    </li>
    <li
      class="project-tag project-tag-clickable"
      :style="styles[item.statusId]"
      :class="{ 'project-tag-clicked': clickedTag == item.statusId }"
      v-for="item in dataInternal.summary"
      :key="item.statusId"
      @click="onTagClick(item)"
    >
      {{ item.statusText }}: {{ item.itemCount }}
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'ProjectTagControl',
    emits: ['tagClicked'],
    components: {},
    props: {
      data: {
        type: Object,
        default: null,
      },
      showTotals: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        dataInternal: this.data,
        styles: {
          0: 'background: #0093BA;',
          1: 'background: linear-gradient(0deg, rgba(202, 202, 202, 0.5), rgba(202, 202, 202, 0.5)), #00BE35;',
          2: 'background: #5B74CF;',
          3: 'background: #F9C339;',
          4: 'background: #B6B6B6;',
          5: 'background: #DF5561;',
          6: 'background: #DEA943;',
        },
        clickedTag: 0,
      };
    },
    created() {},
    methods: {
      onTagClick(item) {
        this.clickedTag = item.statusId;
        this.$emit('tagClicked', item);
      },
    },
    watch: {
      data: {
        handler: function () {
          this.clickedTag = 0;
          this.dataInternal = this.data;
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .project-tag {
    padding: 10px;
    margin: 8px 0;
    gap: 4px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .project-tag:not(:last-child) {
    margin-right: 24px;
  }
  .project-tag-not-clickable {
    background: #edf4f9;
    color: #000000;
    cursor: default;
  }
  .project-tag-clickable {
    color: #ffffff;
  }

  .project-tag-clicked {
    box-shadow: 0px 0px 10px #7d7d7d;
  }
</style>
