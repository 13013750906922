<template>
  <WorldIndicator
    :title="title"
    :years="yearList"
    :items="itemList"
    @yearChanged="onYearChanged"
    :source-text="sourceText"
    titleId="world-hot-title"
  />
</template>

<script>
  import Api from '@/modules/countryAnalyticsNew/api/api';
  import WorldIndicator from '@/modules/countryAnalyticsNew/components/nested/WorldIndicator';

  export default {
    name: 'WorldHotIndicator',
    components: { WorldIndicator },
    data() {
      return {
        title: 'Горячие показатели торговли товарами России с миром',
        sourceText: 'Источник: ФТС России',
        yearList: [],
        itemList: [],
      };
    },
    created() {
      Api.getYearList(true, true).then((response) => {
        this.yearList = response.data;
      });
    },
    methods: {
      onYearChanged(data) {
        Api.getExportProductAll(data).then((response) => {
          let summary = response.data;
          this.itemList = [
            {
              title: 'Товарооборот',
              value: summary?.totalValue ?? 0,
              previousPercent: summary?.totalValuePreviousPercent ?? 0,
            },
            {
              title: 'Импорт',
              value: summary?.importValue ?? 0,
              previousPercent: summary?.importValuePreviousPercent ?? 0,
            },
            {
              title: 'Экспорт',
              value: summary?.exportValue ?? 0,
              previousPercent: summary?.exportValuePreviousPercent ?? 0,
            },
            {
              title: 'Несырьевой неэнергетический экспорт',
              value: summary?.exportNonEnergyValue ?? 0,
              previousPercent: summary?.exportNonEnergyValuePreviousPercent ?? 0,
            },
            {
              title: 'Сальдо',
              value: summary?.balance ?? 0,
              previousPercent: summary?.balancePreviousPercent ?? 0,
            },
          ];
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
