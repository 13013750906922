export function setLifeIndexSummary(state, res) {
  const { data = {} } = res;
  state.summaryLifeIndex = data;
}

export function setInvestmentInStock(state, res) {
  const { data = {} } = res;
  state.inStockInvestment = data;
}

export function setInvestmentOutStock(state, res) {
  const { data = {} } = res;
  state.outStockInvestment = data;
}

export function setInvestmentInFlows(state, res) {
  const { data = {} } = res;
  state.inFlowsInvestment = data;
}

export function setInvestmentOutFlows(state, res) {
  const { data = {} } = res;
  state.outFlowsInvestment = data;
}

export function setForeignTradeTurnoverWordBalance(state, res) {
  const { data = {} } = res;
  state.balanceForeignTradeTurnoverWord = data;
}

export function setForeignTradeTurnoverWordGdpValues(state, res) {
  const { data = {} } = res;
  state.gdpValuesForeignTradeTurnoverWord = data;
}

export function setUnfriendlyCountryInfo(state, res) {
  const { data = {} } = res;
  state.infoAboutUnfriendlyCountry = data;
}

export function setTopImportIndustries(state, res) {
  const { data = {} } = res;
  state.industriesOfTopImport = data;
}

export function setTopExportIndustries(state, res) {
  const { data = {} } = res;
  state.industriesOfTopExport = data;
}

export function setTopImportCountries(state, res) {
  const { data = {} } = res;
  state.countriesOfTopImport = data;
}

export function setTopExportCountries(state, res) {
  const { data = {} } = res;
  state.countriesOfTopExport = data;
}

export function setIndicatorBudgetDeficit(state, res) {
  const { data = {} } = res;
  state.budgetDeficitIndicator = data;
}

export function setIndicatorStockMarketCapitalization(state, res) {
  const { data = {} } = res;
  state.stockMarketCapitalizationIndicator = data;
}

export function setIndicatorFinancialAccount(state, res) {
  const { data = {} } = res;
  state.financialAccountIndicator = data;
}

export function setIndicatorGovernmentBondYields(state, res) {
  const { data = {} } = res;
  state.governmentBondYieldsIndicator = data;
}

export function setYearListTurnover(state, res) {
  const { data = [] } = res;
  state.turnoverYears = data;
}

export function setForeignTradeData(state, res) {
  const { data = {} } = res;
  state.foreignTradeData = data;
}

export function setMacroEconomicIndicatorValue(state, res) {
  const { data = {} } = res;
  state.valueOfMacroEconomicIndicator = data?.item;
}

export function setEconomicReviewSummary(state, res) {
  const { data = {} } = res;
  state.summaryEconomicReview = data;
}

export function setIndicatorCountryRating(state, res) {
  const { data = {} } = res;
  state.countryRatingIndicators = data;
}

export function setExportProductLast(state, res) {
  const { data = {} } = res;
  state.lastExportProduct = data;
}

export function setFerRegionsTradeValue(state, res) {
  const { data = {} } = res;
  state.ferRegionsTradeValues = data;
}

export function setFerRegionsTradeCountryCount(state, res) {
  const { data = {} } = res;
  state.ferRegionsTradeCountOfCountry = data;
}

export function setQuartalList(state, res) {
  const { data = [] } = res;
  state.quartals = data;
}

export function setQuartalSummary(state, res) {
  const { data = {} } = res;
  state.summaryQuartal = data;
}
