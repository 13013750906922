<template>
  <div class="container-page">
    <div class="mb-16 d-flex flex-row justify-content-between">
      <div class="mb-16 d-flex flex-row setting_container">
        <ButtonStock
          title="Очистить"
          @click="clear()"
          v-if="$store.state.auth?.oidc?.profile && $store.state.auth?.oidc?.profile.id == 1"
        />
        <ButtonStock class="ml-2" title="Экспорт" @click="exportExcel" :disabled="exportInProgress" />
      </div>
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable audit-table">
        <thead>
          <tr>
            <th @click="changeSorting(0)" :class="getClassSorting(0) + ' ' + 'cursor-pointer'">Заголовок страницы</th>
            <th @click="changeSorting(1)" :class="getClassSorting(1) + ' ' + 'cursor-pointer'">URL страницы</th>
            <th @click="changeSorting(2)" :class="getClassSorting(2) + ' ' + 'cursor-pointer'">
              Уникальные посетители
            </th>
            <th @click="changeSorting(3)" :class="getClassSorting(3) + ' ' + 'cursor-pointer'">
              Количество просмотров
            </th>
            <th @click="changeSorting(4)" :class="getClassSorting(4) + ' ' + 'cursor-pointer'">Дата и время</th>
          </tr>
          <tr>
            <th>
              <FilterInput
                v-model="filter.auditPageTitle"
                placeholder="Введите"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.url" placeholder="Введите" v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <div class="range">
                <FilterInput
                  type="number"
                  v-model="filter.visitorCountFrom"
                  placeholder="От"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput
                  type="number"
                  v-model="filter.visitorCountTo"
                  placeholder="до"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </div>
            </th>
            <th class="filter">
              <div class="range">
                <FilterInput
                  type="number"
                  v-model="filter.viewCountFrom"
                  placeholder="От"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput
                  type="number"
                  v-model="filter.viewCountTo"
                  placeholder="до"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </div>
            </th>
            <th class="filter">
              <div class="range">
                <FilterInput
                  type="date"
                  v-model="filter.lastVisitDateFrom"
                  class="mr-2"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput type="time" v-model="filter.lastVisitTimeFrom" v-on:valueChangedDebounced="changeFilter" />
              </div>
              <div class="range">
                <FilterInput
                  type="date"
                  class="mr-2"
                  v-model="filter.lastVisitDateTo"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput type="time" v-model="filter.lastVisitTimeTo" v-on:valueChangedDebounced="changeFilter" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="page?.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id">
            <td>{{ item.auditPageTitle }}</td>
            <td>
              <a :href="item.url">{{ item.url }}</a>
            </td>
            <td>{{ item.visitorCount }}</td>
            <td>{{ item.viewCount }}</td>
            <td>{{ $momentFormat(item.lastVisitDate, 'DD.MM.yyyy HH:mm:ss') }}</td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" colspan="8">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="8">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        :showCountPerPage="true"
        :currentCountPerPage="request.pageSize"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
        @recordsPerPageChanged="onRecordsPerPageChange($event)"
      />
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import Api from '@/modules/admin/api/auditPage';

  import FilterInput from '../../../common/components/FilterInput';

  export default {
    name: 'AuditLogVisits',
    components: { FilterInput, ButtonStock, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          url: '',
          viewCountFrom: null,
          viewCountTo: null,
          lastVisitDateFrom: null,
          lastVisitDateTo: null,
          auditPageTitle: '',
          visitorCountFrom: null,
          visitorCountTo: null,
        },
        page: {},
        isLoading: false,
        exportInProgress: false,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage();
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        if (this.filter.lastVisitDateFrom == 'Invalid date') this.filter.lastVisitDateFrom = null;
        if (this.filter.lastVisitDateTo == 'Invalid date') this.filter.lastVisitDateTo = null;
        const request = Object.assign({}, this.filter, this.request);

        if (
          request.lastVisitDateFrom != null &&
          this.filter.lastVisitTimeFrom &&
          this.filter.lastVisitTimeFrom.length == 5
        )
          request.lastVisitDateFrom += ' ' + this.filter.lastVisitTimeFrom + ':00';
        if (request.lastVisitDateTo != null && this.filter.lastVisitTimeTo && this.filter.lastVisitTimeTo.length == 5)
          request.lastVisitDateTo += ' ' + this.filter.lastVisitTimeTo + ':01';

        Api.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onPageChange(pageNumber, append) {
        this.request.pageNumber = pageNumber;
        this.loadPage(append);
      },
      clear() {
        var options = {
          title: 'Удаление',
          text: 'Вы действительно хотите удалить записи посещения?',
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          showCancelButton: true,
          icon: 'warning',
        };
        Constants.alert.fire(options).then((res) => {
          if (res.isConfirmed) {
            Api.deleteAll(this.request)
              .then(() => {
                this.request.pageNumber = 1;
                this.loadPage(false);
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Записи не удалены',
                  'error',
                );
              });
          }
        });
      },
      exportExcel() {
        this.exportInProgress = true;
        Api.exportExcel(this.request).then(() => {
          this.exportInProgress = false;
        });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      getClassSorting(id) {
        if (this.request.sortColumn == id) {
          if (this.request.sortOrder == 0) return 'table-header_ascending';
          else if (this.request.sortOrder == 1) return 'table-header_descending';
        }
        return '';
      },
      changeSorting(id) {
        if (this.request.sortColumn == id)
          switch (this.request.sortOrder) {
            case 0:
              this.request.sortOrder = 1;
              break;
            case 1:
              this.request.sortOrder = null;
              break;
            default:
              this.request.sortOrder = 0;
              break;
          }
        else this.request.sortOrder = 0;
        this.request.sortColumn = id;
        this.loadPage(false);
      },
      onRecordsPerPageChange(recordCount) {
        this.request.pageNumber = 1;
        this.request.pageSize = recordCount;
        this.loadPage();
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default/colors';
  .log-details-table {
    width: 100%;
    td {
      text-align: left;
      // white-space: pre;
      padding: 4px;
    }
    .table__additional-info {
      font-weight: normal !important;
      white-space: pre-wrap;
    }
  }
</style>

<style lang="scss" scoped>
  .filter::v-deep {
    .range {
      display: flex;
      .input-group-wrapper {
        input {
          margin-right: 4px;
          padding: 4px 8px;
          min-width: 100px;
          max-width: 100px;
        }
        input[type='number'] {
          min-width: 60px;
          max-width: 60px;
        }
      }
    }
  }

  .audit-table::v-deep {
    a {
      word-break: break-all;
    }
  }
  .setting_container::v-deep {
    align-items: center;
    justify-content: flex-end;
    .input-group-wrapper {
      min-width: 100px;
      max-width: 100px;
      margin: 4px 8px;
    }
    .setting_title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #343434;
    }
  }
</style>
