import { ECONOMIC_RELATIONS, ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_MUTATIONS_NAME } from './name';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getPassportKeyData(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportKeyData,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportKeyData,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportKeyData,
    },
    params,
  );
}

export async function getPassportPartnerCountriesData(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportPartnerCountriesData,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportPartnerCountriesData,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPartnerCountriesData,
    },
    params,
  );
}

export async function getPassportTradeTurnover(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportTradeTurnover,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportTradeTurnover,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportTradeTurnover,
    },
    params,
  );
}

export async function getPassportMainData(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportMainData,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportMainData,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportMainData,
    },
    params,
  );
}

export async function getPassportPartnerCountriesCount(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportPartnerCountriesCount,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportPartnerCountriesCount,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPartnerCountriesCount,
    },
    params,
  );
}

export async function getPassportCurrentEvents(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportCurrentEvents,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportCurrentEvents,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportCurrentEvents,
    },
    params,
  );
}

export async function getPassportPreviousEvents(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportPreviousEvents,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportPreviousEvents,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPreviousEvents,
    },
    params,
  );
}

export async function getPassportCurrentEventsCount(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportCurrentEventsCount,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportCurrentEventsCount,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportCurrentEventsCount,
    },
    params,
  );
}

export async function getPassportPreviousEventsCount(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportPreviousEventsCount,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportPreviousEventsCount,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPreviousEventsCount,
    },
    params,
  );
}

export async function getPassportAgreements(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportAgreements,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setPassportAgreements,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportAgreements,
    },
    params,
  );
}

export async function getMorePassportAgreements(context, params) {
  return request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getPassportAgreements,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.addPassportAgreements,
      name: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportAgreements,
    },
    params,
  );
}

export async function getTopRegionsAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topRegionsAgreements,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setTopRegionsAgreements,
      name: ECONOMIC_RELATIONS.getTopRegionsAgreements,
    },
    params,
  );
}

export async function getTopEffectiveRegions(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topEfficiency,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setTopEffectiveRegions,
      name: ECONOMIC_RELATIONS.getTopEffectiveRegions,
    },
    params,
  );
}

export async function getTopRegionsByTurnover(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topRegionsByTurnover,
      name: ECONOMIC_RELATIONS.getTopRegionsByTurnover,
    },
    params,
  );
}

export async function getTopRegionsByExport(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topRegionsByExport,
      name: ECONOMIC_RELATIONS.getTopRegionsByExport,
    },
    params,
  );
}

export async function getTopRegionsByImport(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topRegionsByImport,
      name: ECONOMIC_RELATIONS.getTopRegionsByImport,
    },
    params,
  );
}

export async function getTopRegionsByEvents(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topRegionsByEvents,
      name: ECONOMIC_RELATIONS.getTopRegionsByEvents,
    },
    params,
  );
}

export async function getTopRegionsByAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topRegionsByAgreements,
      name: ECONOMIC_RELATIONS.getTopRegionsByAgreements,
    },
    params,
  );
}

export async function getTopMonthRegionsByEvents(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topMonthRegionsByEvents,
      name: ECONOMIC_RELATIONS.getTopMonthRegionsByEvents,
    },
    params,
  );
}

export async function getTopMonthRegionsByAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.topMonthRegionsByAgreements,
      name: ECONOMIC_RELATIONS.getTopMonthRegionsByAgreements,
    },
    params,
  );
}

export async function getResponsibleAuthority(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.economicRelations.getResponsibleAuthority,
      mutate: ECONOMIC_RELATIONS_MUTATIONS_NAME.setResponsibleAuthority,
      name: ECONOMIC_RELATIONS.getResponsibleAuthority,
    },
    params,
  );
}
