<template>
  <ul class="employee-list">
    <li class="employee-list__item" v-for="employee in items" :key="employee.id">
      {{ formatEmployeeName(employee) }}
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'EmployeeList',
    props: {
      items: {
        type: Array,
        required: false
      },
    },
    methods: {
      formatEmployeeName(employee) {
        if (!employee) {
          return '';
        }

        const { surname } = employee;
        const name = employee.name[0] + '.';
        const patronymic = employee.patronymic ? employee.patronymic[0] + '.' : ''

        return `${surname} ${name}${patronymic}`;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .employee-list {
    list-style-type: '';
  }

  .employee-list__item {
    display: inline;

    &:not(:last-child) {
      &::after {
        content: ', ';
      }
    }
  }
</style>
