<template>
  <div class="international-trade internationalTradeEconRev mb-32" :class="{ notForTecRussiaTab: notForTecRussiaTab }">
    <div class="international-trade__switchblock d-flex justify-content-between mb-12">
      <p class="economicsTypeTitle mb-0">Торгово-экономическое сотрудничество с миром</p>
      <RedesignedSwitchComponent @switchHandler="switchHand($event)" />
      <div class="d-flex align-items-center">
        <p class="fs14 mr-6 gray">Источник: Международный торговый центр</p>

        <Multiselect
          class="select-country-analytic"
          label="text"
          :searchable="true"
          v-model="year"
          valueProp="id"
          track-by="text"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="yearList"
          :canClear="false"
          @select="loadData"
        />
      </div>
    </div>

    <TradeIndicatorsList justifyContent="start" class="indicators__list">
      <TradeIndicator
        :name="isProductMode ? 'Товарооборот' : 'Оборот услуг'"
        :divider="1000"
        :currentValue="
          currentTurnoverSummary?.[indicatorValue.TOTAL_VALUE] ?? currentSummary?.[indicatorValue.TOTAL_VALUE]
        "
        :previousPercent="currentTurnoverSummary?.totalValuePercent ?? currentSummary?.totalValuePercent"
        @click="
          resolveSelectTab({
            isCondition: turnoverMoreInfo,
            id: 1,
            label: isProductMode ? 'Товарооборот' : 'Оборот услуг',
          })
        "
        :isSelected="tabId === 1"
      />
      <TradeIndicator
        name="Экспорт"
        :divider="1000"
        :currentValue="
          currentTurnoverSummary?.[indicatorValue.EXPORT_VALUE] ?? currentSummary?.[indicatorValue.EXPORT_VALUE]
        "
        :previousPercent="currentTurnoverSummary?.exportValuePercent ?? currentSummary?.exportValuePercent"
        @click="
          resolveSelectTab({
            isCondition: exportMoreInfo,
            id: 2,
            label: 'Экспорт',
          })
        "
        :isSelected="tabId === 2"
      />
      <TradeIndicator
        name="Импорт"
        :divider="1000"
        :currentValue="
          currentTurnoverSummary?.[indicatorValue.IMPORT_VALUE] ?? currentSummary?.[indicatorValue.IMPORT_VALUE]
        "
        :previousPercent="currentTurnoverSummary?.importValuePercent ?? currentSummary?.importValuePercent"
        @click="
          resolveSelectTab({
            isCondition: importMoreInfo,
            id: 3,
            label: 'Импорт',
          })
        "
        :isSelected="tabId === 3"
      />
      <TradeIndicator
        name="Сальдо"
        :divider="1000"
        :currentValue="getBalanceForeignTradeTurnoverWord?.balanceValue"
        :previousPercent="getBalanceForeignTradeTurnoverWord?.balanceGrowthIndicator"
        :previousValue="getBalanceForeignTradeTurnoverWord?.previousBalanceValue"
      />
    </TradeIndicatorsList>

    <div v-if="tabId === 1 || tabId === 2 || tabId === 3">
      <h2 class="mt-5">
        <template v-if="isProductMode">Торговля товарами</template>
        <template v-else>Торговля услугами</template>, млн $ США
      </h2>
      <LineChartThree
        v-if="!isLoading && isProductMode"
        format-label=" млн $"
        :chart-data="totalTnvedValueByYears"
        :chart-data-second="exportTnvedValueByYears"
        :chart-data-three="importTnvedValueByYears"
        background-color-three="#2F84EB"
        point-color-three="#2F84EB"
        background-color="#01A39D"
        point-color="#01A39D"
        background-color-two="#787F8F"
        point-color-two="#787F8F"
        border-color="#01A39D"
        border-color-two="#787F8F"
        border-color-three="#2F84EB"
        :labels="chartYearList"
        :displayDataLabels="false"
      />
      <LineChartThree
        v-if="!isLoading && !isProductMode"
        format-label=" млн $"
        :chart-data="totalOkvedValueByYears"
        :chart-data-second="exportOkvedValueByYears"
        :chart-data-three="importOkvedValueByYears"
        background-color-three="#2F84EB"
        point-color-three="#2F84EB"
        background-color="#01A39D"
        point-color="#01A39D"
        background-color-two="#787F8F"
        point-color-two="#787F8F"
        border-color="#01A39D"
        border-color-two="#787F8F"
        border-color-three="#2F84EB"
        :labels="chartYearList"
        :displayDataLabels="false"
      />
      <div class="d-flex align-items-center mt-4">
        <div class="indicator-round d-flex align-items-center mr-4">
          <span class="rounded-circle"></span>
          <p v-if="isProductMode">Товарооборот</p>
          <p v-else>Оборот</p>
        </div>
        <div class="indicator-round d-flex align-items-center mr-4">
          <span class="rounded-circle"></span>
          <p>Экспорт</p>
        </div>
        <div class="indicator-round d-flex align-items-center">
          <span class="rounded-circle"></span>
          <p>Импорт</p>
        </div>
      </div>
    </div>

    <div class="supplyStructure" v-if="(tabId === 2 && isProductMode) || (tabId === 1 && isProductMode)">
      <div class="switch-wrapper my-5">
        <RedesignedSwitchComponent
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tovarnayaStructureExport = !switchRows.tovarnayaStructureExport"
        />
        <h2>Товарная структура экспорта</h2>
      </div>
      <template v-if="switchRows.tovarnayaStructureExport">
        <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
        <template v-if="!isLoading && currentSummary">
          <ColumnChartDouble
            labelAlignY="end"
            labelAnchorY="end"
            :enableTooltip="false"
            indexAxis="y"
            barPercentage="1"
            :format-label="null"
            color-first="#0791BA"
            color-second="#D3DFE5"
            label-first="Текущий период, млн $"
            label-second="Прошедший период, млн $"
            :chart-data="exportChartData"
            :chart-data-two="exportChartDataTwo"
            :labels="exportChartLabels"
            height="100%"
          />
        </template>
      </template>
      <template v-else>
        <!-- <h2 class="my-5" v-if="currentSummary">Товарная структура экспорта (таблица)</h2> -->
        <InternationalTradeExportTable
          name="Экспорт"
          :currentSummary="currentSummary"
          exportGroups="exportGroupsTotalCurrent"
          exportGroupsPrevious="exportGroupsTotalPrevious"
          topExportGroups="topExportGroups"
        />
      </template>
    </div>
    <div v-if="tabId === 4 && isProductMode">
      <div class="switch-wrapper my-5">
        <RedesignedSwitchComponent
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tovarnayaStructureExportSecond = !switchRows.tovarnayaStructureExportSecond"
        />
        <h2>Товарная структура экспорта</h2>
      </div>
      <template v-if="!isLoading && switchRows.tovarnayaStructureExportSecond">
        <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
        <ColumnChartDouble
          labelAlignY="end"
          labelAnchorY="end"
          :enableTooltip="false"
          indexAxis="y"
          barPercentage="1"
          :format-label="null"
          color-first="#0791BA"
          color-second="#D3DFE5"
          label-first="Текущий период"
          label-second="Прошедший период"
          :chart-data="itemsDataTwo.map((item) => item.count)"
          :chart-data-two="itemsDataTwo.map((item) => item.countTwo)"
          :labels="itemsDataTwo.map((item) => item.name)"
          height="100%"
        />
      </template>
      <template v-else>
        <div class="table-container mt-5 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th style="font-size: 14px" rowspan="2">Наименование</th>
                <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Экспорт</th>
              </tr>
              <tr>
                <th style="text-align: center">Текущий период</th>
                <th>Прошедший период</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: #ade0ff">
                <td class="font-weight-bold">Всего</td>
                <td class="font-weight-bold">
                  {{ reduceData + ' млн $' }}
                </td>
                <td class="font-weight-bold">
                  {{ reduceDataSecond + ' млн $' }}
                </td>
              </tr>
              <tr v-for="item in itemsDataTwo" :key="item.id">
                <td class="font-weight-bold">{{ item.name }}</td>
                <td class="font-weight-bold">{{ item.count + ' млн $' }}</td>
                <td class="font-weight-bold">{{ item.countTwo + ' млн $' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>

    <div v-if="(tabId === 3 && isProductMode) || (tabId === 1 && isProductMode)">
      <div class="switch-wrapper my-5">
        <RedesignedSwitchComponent
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tovarnayaStructureImport = !switchRows.tovarnayaStructureImport"
        />
        <h2>Товарная структура импорта</h2>
      </div>
      <template v-if="switchRows.tovarnayaStructureImport">
        <!-- <h2 class="my-5">Товарная структура импорта</h2> -->
        <ColumnChartDouble
          labelAlignY="end"
          labelAnchorY="end"
          :enableTooltip="false"
          indexAxis="y"
          barPercentage="1"
          :format-label="null"
          color-first="#0791BA"
          color-second="#D3DFE5"
          label-first="Текущий период, млн $"
          label-second="Прошедший период, млн $"
          :chart-data="importChartData"
          :chart-data-two="importChartDataTwo"
          :labels="importChartLabels"
        />
      </template>
      <template v-else>
        <h2 class="my-5" v-if="currentSummary">Товарная структура импорта (таблица)</h2>
        <InternationalTradeExportTable
          name="Импорт"
          :currentSummary="currentSummary"
          dividerLabel="млн $"
          exportGroups="importGroupsTotalCurrent"
          exportGroupsPrevious="importGroupsTotalPrevious"
          topExportGroups="topImportGroups"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import ColumnChartDouble from '@/components/columnChartDouble';
  import LineChartThree from '@/components/lineChartThree';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';

  import { COUNTRY_ANALYTICS_ACTIONS_NAME, COUNTRY_ANALYTICS_GETTERS_NAME } from '../../store/analytics/directoryNames';
  import { DataItems, InternationalYears, SecondDataItems } from '../../utils/constants';
  import TradeIndicator from './indicators/TradeIndicator.vue';
  import TradeIndicatorsList from './indicators/TradeIndicatorsList.vue';
  import InternationalTradeExportTable from './InternationalTradeExportTable.vue';
  import InternationalTradeMixin from './mixin/InternationalTradeMixin';

  export default {
    name: 'InternationalTradeEconRev',
    mixins: [text, InternationalTradeMixin],
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    components: {
      LineChartThree,
      ColumnChartDouble,
      RedesignedSwitchComponent,
      TradeIndicator,
      TradeIndicatorsList,
      InternationalTradeExportTable,
      Multiselect,
    },
    data() {
      return {
        year: 2020,
        isProductMode: true,
        tabId: null,
        label: null,
        yearList: InternationalYears,
        itemsData: DataItems,
        itemsDataTwo: SecondDataItems,
        summary: null,
        importValues: null,
        exportValues: null,
        importIndicatorId: 4,
        exportIndicatorId: 8,
        isLoading: false,
        tradeItemTypeId: 1,

        indicatorValue: Constants.indicator.value,

        turnoverMoreInfo: true,
        exportMoreInfo: true,
        importMoreInfo: true,

        switchRows: {
          tovarnayaStructureExport: true,
          tovarnayaStructureExportSecond: true,
          tovarnayaStructureImport: true,
        },
      };
    },

    computed: {
      ...mapGetters({
        getBalanceForeignTradeTurnoverWord: COUNTRY_ANALYTICS_GETTERS_NAME.getBalanceForeignTradeTurnoverWord,
        getTurnoverYearList: COUNTRY_ANALYTICS_GETTERS_NAME.getTurnoverYearList,
        getForeignTradeData: COUNTRY_ANALYTICS_GETTERS_NAME.getForeignTradeData,
        getValueOfMacroEconomicIndicator: COUNTRY_ANALYTICS_GETTERS_NAME.getValueOfMacroEconomicIndicator,
      }),
      reduceData() {
        const initialValue = 0;
        const result = this.itemsData
          .map((item) => item.count)
          .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        return result;
      },
      reduceDataSecond() {
        const initialValue = 0;
        const result = this.itemsData
          .map((item) => item.countTwo)
          .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        return result;
      },
      currentSummary() {
        return this.isProductMode ? this.summary?.worldSummaryTnved : this.summary?.worldSummaryOkved;
      },
      currentSummaryOkved() {
        return this.summary?.turnoverWorldOkved;
      },
      currentSummaryTnved() {
        return this.summary?.turnoverWorldTnved;
      },
      currentTurnoverSummary() {
        return this.isProductMode ? this.currentSummaryTnved : this.currentSummaryOkved;
      },
      totalOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.totalValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10];
      },
      exportOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.exportValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10].reverse();
      },
      importOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.importValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        else return [36, 40, 20, 31, 1, 10];
      },
      totalTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.totalValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(2) : 0;
          });
        else return [30, 4, 5, 3, 1, 10];
      },
      exportTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.exportValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10].reverse();
      },
      importTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length) {
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.importValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        } else return [36, 40, 20, 31, 1, 10];
      },
      chartYearList() {
        var list = this.getAvailableYears().map((x) => x.text);
        return list;
      },
      importChartData() {
        return this.currentSummary.topImportGroups.map((item) => (item.currentValue / 1000).toFixed(1));
      },
      importChartDataTwo() {
        return this.currentSummary.topImportGroups.map((item) => (item.previousValue / 1000).toFixed(1));
      },
      importChartLabels() {
        return this.currentSummary.topImportGroups.map((item) => this.makeChartLabel(item.title, 90));
      },
      exportChartData() {
        return this.currentSummary.topExportGroups.map((item) => (item.currentValue / 1000).toFixed(1));
      },
      exportChartDataTwo() {
        return this.currentSummary.topExportGroups.map((item) => (item.previousValue / 1000).toFixed(1));
      },
      exportChartLabels() {
        return this.currentSummary.topExportGroups.map((item) => this.makeChartLabel(item.title, 90));
      },
    },
    async created() {
      this.loadIndicatorVisibility();

      await this.getYearListTurnover({ countryId: this.countryId });
      this.yearList = this.getTurnoverYearList;
      this.year = 2021; //Это временно!!!
      this.loadData();
    },
    methods: {
      ...mapActions({
        getForeignTradeTurnoverWordBalance: COUNTRY_ANALYTICS_ACTIONS_NAME.getForeignTradeTurnoverWordBalance,
        getYearListTurnover: COUNTRY_ANALYTICS_ACTIONS_NAME.getYearListTurnover,
        getForeignTrade: COUNTRY_ANALYTICS_ACTIONS_NAME.getForeignTrade,
        getMacroEconomicIndicatorValue: COUNTRY_ANALYTICS_ACTIONS_NAME.getMacroEconomicIndicatorValue,
      }),
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.turnoverMoreInfo = response.data[10].moreInfoVisible;
          this.exportMoreInfo = response.data[11].moreInfoVisible;
          this.importMoreInfo = response.data[12].moreInfoVisible;
        });
      },
      async loadData() {
        this.isLoading = true;

        await this.getForeignTrade({ year: this.year, countryId: this.countryId });
        this.summary = this.getForeignTradeData;

        const macroImportIndicator = await this.getMacroEconomicIndicatorValue({
          countryId: this.countryId,
          indicatorId: this.importIndicatorId,
        });
        this.importValues = macroImportIndicator?.data?.items;

        const macroExportIndicator = await this.getMacroEconomicIndicatorValue({
          countryId: this.countryId,
          indicatorId: this.exportIndicatorId,
        });
        this.exportValues = macroExportIndicator?.data?.items;

        await this.getForeignTradeTurnoverWordBalance({
          countryId: this.countryId,
          year: this.year,
          tradeItemTypeId: this.tradeItemTypeId,
        });
      },
      switchHand(e) {
        this.isProductMode = e;
        this.tradeItemTypeId = this.isProductMode ? 1 : 2;
        this.selectTab(1, this.isProductMode ? 'Товарооборот' : 'Оборот услуг');
        this.getForeignTradeTurnoverWordBalance({
          countryId: this.countryId,
          year: this.year,
          tradeItemTypeId: this.tradeItemTypeId,
        });
        this.$forceUpdate();
      },
      selectTab(id, label) {
        if (id != this.tabId) {
          this.tabId = id;
          this.label = label;
        } else {
          // при повторном клике - сворачивание
          this.tabId = null;
          this.label = null;
        }
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
      summary: function (val) {
        if (val != null) {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  @import './styles/InternationalTradeEconRev';
  @import '../../styles/countryAnalyticsMultiselect.scss';
</style>
