import { PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getInfoPage(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.priorityImportAndImportDestination.getInfo,
      name: PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME.getInfoPage,
    },
    params,
  );
}

export async function loadFile(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.priorityImportAndImportDestination.loadFile,
      name: PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME.loadFile,
      method: 'post',
      isFormData: true,
      pathParams: ['year', 'month'],
    },
    params,
  );
}
