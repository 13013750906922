export const EVENTS_VES = 'eventsVES';

export const EVENTS_VES_ACTIONS_NAME = {
  searchEvents: `${EVENTS_VES}/searchEvents`,
  getEventCard: `${EVENTS_VES}/getEventCard`,
};

export const EVENTS_VES_MUTATIONS_NAME = {
  setEvents: `${EVENTS_VES}/setEvents`,
  setDataCardEvents: 'setDataCardEvents',
};

export const EVENTS_VES_GETTERS_NAME = {
  getCardDataEvent: `${EVENTS_VES}/getCardDataEvent`,
  getListOfEvents: `${EVENTS_VES}/getListOfEvents`,
};
