<template>
  <div class="connections-with-regions">
    <div class="connections-with-regions__header">
      <p class="connections-with-regions__header-title">
        Всего регионов РФ <span>{{ getFerRegionsTradeCountOfCountry }}</span>
      </p>
      <div class="connections-with-regions__search d-flex align-items-center">
        <p class="mr-6">Источник: ЕХД</p>

        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="getYearOptions"
          :canClear="false"
          @select="loadMapData"
        />
      </div>
    </div>
    <div class="mb-20">
      <TopCountryStatisticChart
        title=""
        :seriesProp="seriesData"
        :categories="[]"
        :options="options"
        :isShowLegend="true"
        :height="chartHeight"
        dataEmptyMessage="Данные отсутствуют"
        :key="keyChart"
      />
    </div>

    <div v-if="!isShowAll" class="d-flex">
      <div class="connections-with-regions__show-btn" @click="showMore">
        Показать еще
        <IconComponent name="plus-mini" class="connections-with-regions__show-btn-icon" />
      </div>
      <div class="connections-with-regions__show-btn" @click="showAll">
        Показать все <IconComponent name="plus-mini" class="connections-with-regions__show-btn-icon" />
      </div>
    </div>
    <div v-else-if="isShowHideBtn" class="connections-with-regions__show-btn" @click="hideChart">
      Скрыть
      <IconComponent name="arrowCurrentColor" class="icon--right connections-with-regions__hide-btn-icon" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import TopCountryStatisticChart from '@/components/ChartComponents/TopCountryStatisticChart.vue';

  import { BASE_VES_ACTIONS_NAME, BASE_VES_GETTERS_NAME } from '@/modules/ves/store/base/name';

  import { COUNTRY_ANALYTICS_ACTIONS_NAME, COUNTRY_ANALYTICS_GETTERS_NAME } from '../../store/analytics/directoryNames';
  import { CONNECTIONS_WITH_REGIONS_CHART_LIMIT } from '../../utils/constants';

  export default {
    name: 'ConnectionsWithRegions',
    components: {
      TopCountryStatisticChart,
      Multiselect,
      IconComponent,
    },
    mixins: [text],
    props: {
      countryId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      year: null,
      limit: CONNECTIONS_WITH_REGIONS_CHART_LIMIT,
      isShowAll: false,
      isShowHideBtn: false,
      keyChart: false,
      chartHeight: 600,
    }),
    created() {
      this.loadData();
    },
    computed: {
      ...mapGetters({
        getYearOptions: BASE_VES_GETTERS_NAME.getYearOptions,
        getFerRegionsTradeValues: COUNTRY_ANALYTICS_GETTERS_NAME.getFerRegionsTradeValues,
        getFerRegionsTradeCountOfCountry: COUNTRY_ANALYTICS_GETTERS_NAME.getFerRegionsTradeCountOfCountry,
      }),
      options() {
        return {
          indexAxis: 'y',
          locale: 'ru-RU',
          clip: false,
          layout: {
            padding: {
              right: 70,
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
              external: function (context) {
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // создать при первом рендере
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<div></div>';
                  document.body.appendChild(tooltipEl);
                }

                // скрыть, если нет тултипа
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // установить позицию
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                // установить наполнение (текст)
                if (tooltipModel.body) {
                  const { dataset, dataIndex } = tooltipModel.dataPoints[0];

                  const header = dataset.header;
                  const pref = dataset.year;
                  const post = dataset.percentLabels[dataIndex];
                  const percent = dataset.percents[dataIndex];
                  const body = `${dataset.dataLabels[dataIndex]} `;

                  let innerHtml = `
                  <div class="custom-chart-tooltip custom-chart-tooltip--connectionsWithRegions custom-chart-tooltip--economic">
                    <p class="mb-8">${header}</p>
                    <div class="d-flex">
                      <span class="circle" style="background: ${dataset.backgroundColor}"></span>
                      <p>${pref}: ${body}
                        ${percent > 0 ? `<span class="text-green">+${post}%` : `<span class="text-red">${post}%`}</span>
                      </p>
                    </div>
                  </div>
                  `;

                  let tableRoot = tooltipEl.querySelector('div');
                  tableRoot.innerHTML = innerHtml;
                }

                const position = context.chart.canvas.getBoundingClientRect();

                // установка стилей, позиции
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX / 2 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 60 + 'px';
                tooltipEl.style.cursor = 'pointer';
                tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                tooltipEl.style.pointerEvents = 'none';
              },
            },
            legend: {
              display: false,
            },
            datalabels: {
              clamp: true,
              align: 'end',
              offset: 0,
              anchor: 'end',
              color: '#231F20',
              family: 'Fira Sans',
              textAlign: 'end',
              font: {
                fontSize: 14,
              },
              formatter: (value) => {
                const parts = value.toFixed(1).split('.');
                const numberPart = parts[0];
                const decimalPart = parts[1];
                const thousands = /\B(?=(\d{3})+(?!\d))/g;
                return `${numberPart.replace(thousands, ' ')},${decimalPart}`;
              },
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 16,
                },
                color: '#000',
                crossAlign: 'far',
              },
            },
          },
        };
      },
      seriesData() {
        if (this.getFerRegionsTradeValues.tradeData == null) {
          return {
            datasets: [],
          };
        }

        const labels = [];
        const importValues = [];
        const exportValues = [];

        const convertData = Object.entries(this.getFerRegionsTradeValues.tradeData);

        convertData.map((el, index) => {
          labels.push(`${index + 1}  ${el[0]}`);
          importValues.push(el[1].import);
          exportValues.push(el[1].export);
        });

        const seriesData = {
          datasets: [
            {
              data: importValues.map(({ value }) => Number(value)),
              dataLabels: importValues.map(({ value }) => this.formatNumber(value, 1)),
              backgroundColor: '#01A39D',
              label: 'Импорт, млн ₽',
              year: this.year,
              percents: importValues.map(({ diffWithPreviousYearInPercentage }) =>
                Number(diffWithPreviousYearInPercentage),
              ),
              percentLabels: importValues.map(({ diffWithPreviousYearInPercentage }) =>
                this.formatNumber(diffWithPreviousYearInPercentage, 1),
              ),
              header: 'Импорт',
              barPercentage: '1',
            },
            {
              data: exportValues.map(({ value }) => Number(value)),
              dataLabels: exportValues.map(({ value }) => this.formatNumber(value, 1)),
              backgroundColor: '#D9D9D9',
              label: 'Экспорт, млн ₽',
              year: this.year,
              percents: exportValues.map(({ diffWithPreviousYearInPercentage }) =>
                Number(diffWithPreviousYearInPercentage),
              ),
              percentLabels: exportValues.map(({ diffWithPreviousYearInPercentage }) =>
                this.formatNumber(diffWithPreviousYearInPercentage, 1),
              ),
              header: 'Экспорт',
              barPercentage: '1',
            },
          ],
          labels,
        };
        return seriesData;
      },
    },

    watch: {
      countryId: {
        handler() {
          this.loadMapData();
        },
      },
    },
    methods: {
      ...mapActions({
        getYearLast: BASE_VES_ACTIONS_NAME.getYearLast,
        getFerRegionsTradeValue: COUNTRY_ANALYTICS_ACTIONS_NAME.getFerRegionsTradeValue,
        getFerRegionsTradeCountryCount: COUNTRY_ANALYTICS_ACTIONS_NAME.getFerRegionsTradeCountryCount,
      }),
      async loadData() {
        const yearsResponse = await this.getYearLast();

        if (yearsResponse.success) {
          this.year = this.getYearOptions[this.getYearOptions.length - 1].value;

          this.loadMapData();
        }
      },

      async loadMapData() {
        this.limit = CONNECTIONS_WITH_REGIONS_CHART_LIMIT;
        await this.fetchFerRegionsTradeValue(this.limit);
        await this.fetchFerRegionsTradeCountryCount();
        this.isShowAll = this.limit >= this.getFerRegionsTradeCountOfCountry;
        this.resolveChartHeight(this.limit);
      },

      async fetchFerRegionsTradeValue(limit) {
        await this.getFerRegionsTradeValue({ countryId: this.countryId, year: this.year, limit });
      },

      async fetchFerRegionsTradeCountryCount() {
        await this.getFerRegionsTradeCountryCount({ countryId: this.countryId, year: this.year });
      },

      async showMore() {
        this.limit += CONNECTIONS_WITH_REGIONS_CHART_LIMIT;
        await this.fetchFerRegionsTradeValue(this.limit);
        if (this.limit >= this.getFerRegionsTradeCountOfCountry) {
          this.isShowAll = true;
          this.isShowHideBtn = true;
        }
        this.resolveChartHeight(this.limit);
      },

      async showAll() {
        await this.fetchFerRegionsTradeValue(this.getFerRegionsTradeCountOfCountry);
        this.isShowAll = true;
        this.isShowHideBtn = true;
        this.resolveChartHeight(this.getFerRegionsTradeCountOfCountry);
      },

      async hideChart() {
        this.limit = CONNECTIONS_WITH_REGIONS_CHART_LIMIT;
        await this.loadMapData();
        this.isShowAll = false;
        this.isShowHideBtn = false;
        this.resolveChartHeight(this.limit);
      },
      resolveChartHeight(limit) {
        this.chartHeight = (limit / CONNECTIONS_WITH_REGIONS_CHART_LIMIT) * 600;
        this.keyChart = !this.keyChart;
      },
    },
  };
</script>

<style lang="scss">
  @import '@/components/ChartComponents/styles/custom-tooltip.scss';
</style>

<style scoped lang="scss">
  @import '../../styles/countryAnalyticsMultiselect.scss';

  .connections-with-regions {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      flex-wrap: wrap;
      gap: 8px;
    }
    &__header-title {
      color: $base;
      font: $fira-20-M;
      span {
        background: linear-gradient(90deg, $blue 0%, $green 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        display: inline-block;
        margin-left: 5px;
      }
    }
    &__search {
      display: flex;
      align-items: center;
      p {
        font: $fira-16;
        color: $base;
      }
    }
    &__show-btn {
      display: flex;
      align-items: center;
      font-family: 'Fira Sans';
      font-size: 15px;
      line-height: 20px;
      text-align: left;
      color: $green;
      cursor: pointer;

      &:first-of-type {
        margin-right: 20px;
      }
    }

    &__show-btn-icon {
      width: 14px;
      margin-left: 5px;
    }
    &__hide-btn-icon {
      transform: rotate(180deg);
      margin: 0 5px 3px;
    }

    .bar-chart-tooltip__legend-point {
      border-radius: 50%;
    }
    .bar-chart-tooltip__legend-name {
      border-radius: 50%;
      font-size: 15px;
      font-family: 'Fira Sans';
    }

    :deep(.bar-chart-tooltip__graph) {
      min-width: 800px !important;
    }
  }

  .select-country-analytic::v-deep {
    width: 150px !important;
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>
