<template>
  <div class="association">
    <h3 class="association__title">Количество принятых актов Союза (в единицах)</h3>
    <!--    <pie-chart :labels="labels" :chartData="[10, 20, 40, 24, 30, 9, 21]" />-->

    <ColumnChartHorizontal
      :barPercentage="1"
      index-axis="y"
      :colors="['#0093BA', '#5bc6e3', '#337280', '#96d2e7', '#0093BA']"
      :labels="labels?.map((item) => makeChartLabel(item, 45))"
      :chartData="[10, 20, 40, 24, 30, 9, 21]"
    />

    <div class="mt-5">
      <h3 class="mb-4">Количество принятых актов Союза по годам (в единицах)</h3>
      <ColumnChart
        :labels="['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']"
        :chart-data="[25, 50, 10, 100, 30, 45, 34, 23, 10, 100, 95]"
      />
    </div>
    <div class="table-inner mt-5">
      <h3 class="mb-4">Таблица с количеством принятых актов Союза (в единицах)</h3>
      <div class="table-container js-table-arrow">
        <table class="stockTable">
          <colgroup>
            <col width="12%" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
          </colgroup>
          <thead>
            <th>Акт</th>
            <th>2012</th>
            <th>2013</th>
            <th>2014</th>
            <th>2015</th>
            <th>2016</th>
            <th>2017</th>
            <th>2018</th>
            <th>2019</th>
            <th>2020</th>
            <th>2021</th>
            <th>2022</th>
          </thead>
          <tbody>
            <tr v-for="tr in tableData" :key="tr.id">
              <td v-for="(td, index) in tr.data" :key="index">{{ td }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-5">
      <h3 class="mb-4">Количество решений Суда Союза (в единицах)</h3>
      <ColumnChart
        :labels="['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']"
        :chart-data="[25, 50, 100, 100, 30, 45, 2, 23, 3, 100, 95]"
      />
    </div>
    <div class="table-container mt-5 js-table-arrow">
      <h3 class="p-3 mb-4">Таблица с количеством решений Суда Союза по годам</h3>
      <table class="stockTable">
        <thead>
          <th style="text-align: center">2012</th>
          <th>2013</th>
          <th>2014</th>
          <th>2015</th>
          <th>2016</th>
          <th>2017</th>
          <th>2018</th>
          <th>2019</th>
          <th>2020</th>
          <th>2021</th>
          <th>2022</th>
        </thead>
        <tbody>
          <tr v-for="tr in tableDataTwo" :key="tr.id">
            <td style="text-align: center; padding: 34px 27px" v-for="(td, index) in tr.data" :key="index">
              {{ td }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import ColumnChartHorizontal from '@/components/column-chart-horizontal';
  import ColumnChart from '@/components/columnChart';
  export default {
    name: 'association',
    components: { ColumnChartHorizontal, ColumnChart },
    mixins: [text],
    data() {
      return {
        labels: [
          'Официальные сообщения\n' + 'евразийской экономической\n' + 'комиссии',
          'Акты высшего евразийского\n' + 'экономического совета ',
          'Акты евразийского\n' + 'межправительственного\n' + 'совета ',
          'Акты евразийской\n' + 'экономической\n' + 'комиссии',
          'Акты суда евразийского\n' + 'экономического союза\n',
          'Документы\n' + 'ТС и ЕЭП',
          'Меморандумы,\n' + 'заявления',
        ],
        tableData: [
          {
            id: 1,
            data: [
              'Акты высшего евразийского экономического совета (83)',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
            ],
          },
          {
            id: 2,

            data: [
              'Акты евразийского межправите-тельственного  совета (54)',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
            ],
          },
          {
            id: 3,

            data: [
              'Акты евразийской экономической комиссии (63)',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
            ],
          },
          {
            id: 4,

            data: [
              'Официальные сообщения евразийской экономической комиссии (123)',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
              '10',
            ],
          },
          {
            id: 5,

            data: ['Меморандумы, заявления (23)', '10', '10', '10', '10', '10', '10', '10', '10', '10', '10', '10'],
          },
          {
            id: 6,

            data: ['Документы\n' + 'ТС и ЕЭП', '10', '10', '10', '10', '10', '10', '10', '10', '10', '10', '10'],
          },
        ],
        tableDataTwo: [
          {
            id: 1,
            data: ['10', '10', '10', '10', '10', '10', '10', '10', '10', '10', '10'],
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  h3 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 20px;
  }

  .table-container {
    border-radius: 16px;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
    overflow: auto;
    table {
      min-width: 1180px;
      &.stockTable {
        thead {
          th {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #787f8f;
            padding: 16px;
            border-bottom: 2px solid #01a39d;
            div {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #787f8f;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #d8d8d8;
          }
          td {
            padding: 16px;
            font-size: 12px;
            line-height: 18px;
            color: #535353;
            p {
              margin-bottom: 0px;
            }
          }
          button {
            margin-top: 12px;
          }
        }
      }
      &.blueGrayTable {
        thead {
          th {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #231f20;
            padding: 16px;
            background-color: #d3dfe5;
            border-bottom: 1px solid #787f8f;
            &:not(:last-of-type) {
              border-right: 1px solid #787f8f;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #787f8f;
            &:nth-of-type(even) {
              background-color: #f0f9ff;
            }
          }
          td {
            padding: 16px;
            font-size: 12px;
            line-height: 18px;
            color: #231f20;
            &:not(:last-of-type) {
              border-right: 1px solid #787f8f;
            }
            p {
              margin-bottom: 0px;
            }
          }
          .green {
            font-weight: 600;
            color: #01a39d;
            border-bottom: 1px dashed #01a39d;
            width: min-content;
          }
          .red {
            font-weight: 600;
            color: #eb5757;
          }
        }
      }
    }
  }
  .table-custom {
    th {
      padding: 13px 11px;
    }
    th,
    td {
      text-align: center;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #000000;
    }
    th:first-child,
    td:first-child {
      padding: 8px 14px 8px 8px;
      text-align: left;
    }
    tr:nth-child(odd) {
      background-color: #e5f5ff;
    }
    th {
      border-bottom: 1px solid #01a39d !important;
    }
  }
</style>
