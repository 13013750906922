<template>
  <component :is="component" class="simple-link">
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: 'SimpleLink',

    props: {
      component: {
        type: [String, Object],
        default: 'a',
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/global/variables.scss';

  .simple-link {
    all: unset;
    font: $fira-12;
    color: $blue-2;
    cursor: pointer;

    &:hover {
      color: $blue;
    }

    &:visited {
      color: $purple;
    }
  }
</style>
