<template>
  <section class="restriction">
    <div class="container">
      <div class="restriction__top-wrapper">
        <SelectComponent
          class="restriction__select"
          :placeholder="itemSelectLabel"
          :options="currentSelect"
          :canClear="true"
          v-model="searchItem"
          :settings="selectSettings"
        />

        <SelectComponent
          class="restriction__select"
          placeholder="Вид предмета торговли"
          :options="tradeItemTypes"
          :canClear="false"
          v-model="activeTradeItemType"
        />
      </div>

      <div class="restriction__show-wrapper">
        <p class="restriction_title" v-if="activeTradeItemType == 0">Все</p>
        <p class="restriction_title" v-if="activeTradeItemType == 1">Товары</p>
        <p class="restriction_title" v-if="activeTradeItemType == 2">Услуги</p>

        <SwitchComponent
          class="restriction_toggler mb-16"
          :class="[{ restriction_toggler_okved: activeTradeItemType == 2 }]"
          v-if="activeTradeItemType > 0"
          :textTrue="activeTradeItemType == 1 ? 'Меры' : 'Запреты'"
          :textFalse="activeTradeItemType == 1 ? 'Угрозы' : 'Ограничения'"
          @switchHandler="changeMeasureActive($event)"
        />

        <div class="table-container js-table-arrow" v-show="!loading && listData.length > 0">
          <div class="stockTable">
            <table>
              <thead v-if="activeTradeItemType < 2" class="products">
                <tr>
                  <th>№ п/п</th>
                  <th>Страна</th>
                  <th v-if="activeTradeItemType == 1">Наименование товара</th>
                  <th v-else>Наименование</th>
                  <th v-if="activeTradeItemType == 1">Коды товара<br />страны назначения</th>
                  <th v-else>Коды товара/<br />ОКВЭД</th>
                  <th>Тип ограничения</th>
                  <th>Дата окончания</th>
                  <th>Описание</th>
                </tr>
              </thead>
              <thead v-if="activeTradeItemType == 2" class="services">
                <tr>
                  <th>№ п/п</th>
                  <th>Страна</th>
                  <th>Наименование услуги</th>
                  <th>ОКВЭД</th>
                  <th>Тип ограничения</th>
                  <th>Дата окончания</th>
                  <th>Описание меры</th>
                </tr>
              </thead>
              <tbody v-if="activeTradeItemType < 2" class="products">
                <tr v-for="(restrictionItem, index) of listData" v-bind:key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      restrictionItem.allCountries
                        ? 'Все страны'
                        : restrictionItem.countries?.map((x) => x.name).join('; ')
                    }}
                  </td>
                  <td>
                    <template v-if="restrictionItem.tradeItemTypeId == 1">
                      {{
                        restrictionItem.allTradeItems
                          ? 'Все товары'
                          : restrictionItem.tnveds?.map((x) => x.name).join('; ')
                      }}
                    </template>
                    <template v-else>
                      {{
                        restrictionItem.allTradeItems
                          ? 'Все услуги'
                          : restrictionItem.okveds?.map((x) => x.name).join('; ')
                      }}
                    </template>
                  </td>
                  <td>
                    <template v-if="restrictionItem.tradeItemTypeId == 1">
                      {{
                        restrictionItem.allTradeItems
                          ? 'Все товары'
                          : restrictionItem.tnveds?.map((x) => x.code).join('; ')
                      }}
                    </template>
                    <template v-else>
                      {{
                        restrictionItem.allTradeItems
                          ? 'Все услуги'
                          : restrictionItem.okveds?.map((x) => x.code).join('; ')
                      }}
                    </template>
                  </td>
                  <td>{{ restrictionItem.measureKind?.name ?? '' }}</td>
                  <td>{{ restrictionItem.endDate ? $momentFormat(restrictionItem.endDate, 'DD.MM.YYYY') : '' }}</td>
                  <td>{{ restrictionItem.description ?? '' }}</td>
                </tr>
              </tbody>
              <tbody v-if="activeTradeItemType == 2" class="services">
                <tr v-for="(restrictionItem, index) of listData" v-bind:key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      restrictionItem.allCountries
                        ? 'Все страны'
                        : restrictionItem.countries?.map((x) => x.name).join('; ')
                    }}
                  </td>
                  <td>
                    {{
                      restrictionItem.allTradeItems
                        ? 'Все услуги'
                        : restrictionItem.okveds?.map((x) => x.name).join('; ')
                    }}
                  </td>
                  <td>
                    {{
                      restrictionItem.allTradeItems
                        ? 'Все услуги'
                        : restrictionItem.okveds?.map((x) => x.code).join('; ')
                    }}
                  </td>
                  <td>{{ restrictionItem.measureKind?.name ?? '' }}</td>
                  <td>{{ restrictionItem.endDate ? $momentFormat(restrictionItem.endDate, 'DD.MM.YYYY') : '' }}</td>
                  <td>{{ restrictionItem.description ?? '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <p v-show="!loading && listData.length === 0 && canOpenContent > 0">Нет ограничений по выбранным параметрам</p>
        <Loader v-show="loading || canOpenContent == 0"></Loader>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import SelectComponent from '@/common/components/selectComponent';

  import SwitchComponent from '@/components/buttons/switchComponent';
  import Loader from '@/components/Loader';

  export default {
    name: 'Restriction',
    components: { SwitchComponent, SelectComponent, Loader },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        measureActive: true,
        searchItem: null,
        listData: [],
        loading: false,
        tradeItemTypes: [
          { id: 0, text: 'Все' },
          { id: 1, text: 'Товар' },
          { id: 2, text: 'Услуга' },
        ],
        activeTradeItemType: null,
        isLoadingAllTypes: false,
        selectSettings: {
          matcher: this.onSearch,
        },
      };
    },
    computed: {
      ...mapGetters([
        'getRestrictionProductsSelect',
        'getRestrictionServicesSelect',
        'getRestrictionProductMeasure',
        'getRestrictionProductThreat',
        'getRestrictionServicesMeasure',
        'getRestrictionServicesThreat',
        'getRestrictionFullList',
      ]),
      canOpenContent() {
        return this.getRestrictionFullList?.length;
      },
      itemSelectLabel() {
        if (this.activeTradeItemType == 0) return 'Выберите';
        else if (this.activeTradeItemType == 1) return 'Выберите товар';
        else return 'Выберите услугу';
      },
      currentSelect() {
        if (this.activeTradeItemType == 0)
          return [{ text: 'Все записи', id: 0, code: '', searchText: '' }].concat(
            this.getRestrictionProductsSelect.concat(this.getRestrictionServicesSelect),
          );
        else if (this.activeTradeItemType == 1)
          return [{ text: 'Все товары', id: 0, code: '', searchText: '' }].concat(this.getRestrictionProductsSelect);
        else return [{ text: 'Все услуги', id: 0, code: '', searchText: '' }].concat(this.getRestrictionServicesSelect);
      },
    },
    created() {
      this.activeTradeItemType = this.tradeItemTypes[1].id;
    },
    methods: {
      ...mapActions([
        // загрузка активных ограничения для России
        'downloadRestrictionActive',
      ]),
      changeMeasureActive(e) {
        this.measureActive = e;
        this.changeListData();
      },
      changeListData() {
        this.loading = true;
        this.listData = [];
        if (this.activeTradeItemType == 1 && this.measureActive) {
          this.listData = this.getRestrictionProductMeasure.filter((restrict) => {
            return (
              (!this.searchItem ||
                this.searchItem == 0 ||
                restrict.allTradeItems ||
                restrict.tnvedIds.includes(Number(this.searchItem))) &&
              (!this.countryId || restrict.allCountries || restrict.countryIds.includes(this.countryId))
            );
          });
        } else if (this.activeTradeItemType == 1 && !this.measureActive) {
          this.listData = this.getRestrictionProductThreat.filter((restrict) => {
            return (
              (!this.searchItem ||
                this.searchItem == 0 ||
                restrict.allTradeItems ||
                restrict.tnvedIds.includes(Number(this.searchItem))) &&
              (!this.countryId || restrict.allCountries || restrict.countryIds.includes(this.countryId))
            );
          });
        } else if (this.activeTradeItemType == 2 && this.measureActive) {
          this.listData = this.getRestrictionServicesMeasure.filter((restrict) => {
            return (
              (!this.searchItem ||
                this.searchItem == 0 ||
                restrict.allTradeItems ||
                restrict.okvedIds.includes(Number(this.searchItem))) &&
              (!this.countryId || restrict.allCountries || restrict.countryIds.includes(this.countryId))
            );
          });
        } else if (this.activeTradeItemType == 2 && !this.measureActive) {
          this.listData = this.getRestrictionServicesThreat.filter((restrict) => {
            return (
              (!this.searchItem ||
                this.searchItem == 0 ||
                restrict.allTradeItems ||
                restrict.okvedIds.includes(Number(this.searchItem))) &&
              (!this.countryId || restrict.allCountries || restrict.countryIds.includes(this.countryId))
            );
          });
        } else {
          this.listData = this.getRestrictionFullList.filter((restrict) => {
            let result = !this.countryId || restrict.allCountries || restrict.countryIds.includes(this.countryId);
            if (result && this.searchItem && this.searchItem > 0) {
              if (restrict.tradeItemTypeId == 2)
                result = restrict.allTradeItems || restrict.okvedIds.includes(Number(this.searchItem));
              else if (restrict.tradeItemTypeId == 1)
                result = restrict.allTradeItems || restrict.tnvedIds.includes(Number(this.searchItem));
              else result = false;
            }
            return result;
          });
        }
        this.loading = false;
      },
      onSearch(params, data) {
        if (!params.term) return data;

        if (data.code.startsWith(params.term) || data.searchText.toLowerCase().includes(params.term.toLowerCase()))
          return data;
        else return null;
      },
    },
    watch: {
      canOpenContent() {
        this.changeListData();
      },
      activeTradeItemType: {
        handler: function () {
          this.isLoadingAllTypes = true;
          this.searchItem = null;
          this.changeListData();
          this.isLoadingAllTypes = false;
        },
      },
      searchItem: {
        handler: function () {
          if (this.isLoadingAllTypes || this.loading) return;

          this.changeListData();
        },
      },
      countryId: function () {
        this.changeListData();
      },
    },
    async mounted() {
      this.loading = true;
      this.downloadRestrictionActive();
      this.changeListData();
    },
  };
</script>

<style lang="scss">
  @import './styles/restriction.scss';
  .restriction_toggler_okved .one {
    left: -65px;
  }

  .restriction_toggler_okved .two {
    right: -95px;
  }

  .restriction_toggler_okved {
    margin-left: 70px !important;
  }
</style>
