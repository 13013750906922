<template>
  <div class="container-page">
    <div>
      <p class="title-inner">
        {{ pageTitle }}
      </p>

      <div class="mb-16 d-flex flex-row">
        <ButtonStock class="mr-2" title="Создать" @click="editCard(0)" v-if="canEdit" />

        <ButtonStock title="Экспорт" @click="exportExcel()" v-if="showExport" />
        <slot name="buttons"></slot>
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div v-if="page.items">
        <div class="table-container mb-16 js-table-arrow" v-if="page.items">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Статус</th>
                <th v-if="canEdit"></th>
                <th v-if="canEdit"></th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    placeholder="Введите название..."
                    v-model="filter.name"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterActiveInput v-model="filter.active" v-on:valueChangedDebounced="changeFilter" />
                </th>
                <th v-if="canEdit" class="filter"></th>
                <th v-if="canEdit" class="filter"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.items" :key="item.id">
                <td class="d-flex align-items-center">
                  <div
                    v-if="item.picture"
                    class="imagePreviewWrapper mr-8"
                    :style="{ 'background-image': `url(${item.picture})` }"
                  ></div>
                  {{ item.name }}
                </td>
                <td>
                  <ActiveComponent :value="item.isActive" />
                </td>
                <td v-if="canEdit">
                  <IconComponent @click="editCard(item.id)" name="mode" />
                </td>
                <td v-if="canEdit">
                  <IconComponent @click="deleteCard(item.id)" name="delete" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterActiveInput from '@/common/components/FilterActiveInput';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import ActiveComponent from '@/common/ui/ActiveComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/nsi';

  export default {
    name: 'NsiTable',
    components: {
      ButtonStock,
      IconComponent,
      ActiveComponent,
      FilterInput,
      FilterActiveInput,
      DefaultFilter,
    },
    mixins: [filtermanager],
    props: {
      pageTitle: String,
      apiRoute: String,
      cardName: String,
      canEdit: {
        type: Boolean,
        default: true,
      },
      showExport: {
        type: Boolean,
        default: false,
      },
      additionalFilter: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},

        filter: {
          name: '',
          active: null,
        },
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage(false);
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        var params = Object.assign({}, this.request);
        if (this.additionalFilter) {
          params = Object.assign(params, this.additionalFilter);
        }

        params.name = this.filter.name;
        params.isActive = this.filter.active;

        API.search(this.apiRoute, params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      editCard(id) {
        this.$router.push({ name: this.cardName, params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(this.apiRoute, id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((/*error*/) => {
                //Constants.alert.fire('Удаление',error.response && error.response.data ? error.response.data : "Запись не удалена", 'error');
                Constants.alert.fire(
                  'Удаление',
                  'Запись не удалена. Возможно, она используется в других объектах.',
                  'error',
                );
              });
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter, this.apiRoute);
        this.loadPage(false);
      },
      exportExcel() {
        API.exportExcel(this.apiRoute);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
    watch: {
      additionalFilter: {
        handler: function () {
          this.changeFilter();
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
  .table-container table {
    min-width: auto;
    width: 100%;
  }

  .imagePreviewWrapper {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
</style>
