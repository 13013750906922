<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка видов мер</p>
    </div>

    <div>
      <div class="row">
        <div class="col-12">
          <div class="form-group required">
            <ComponentInput
              v-model="item.barrierCategoryId"
              :select2Options="barrierCategories"
              label="Категория барьера"
              required
              placeholder="Выберите категорию барьера"
              :select2Settings="select2Settings"
              type="select2"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group required">
            <ComponentInput
              v-model="item.name"
              label="Название меры"
              required
              maxLength="250"
              placeholder="Введите название меры"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="item.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>

    <div>
      <ButtonStock class="mr-8 mb-2" title="Сохранить" v-on:click="save" />
      <ButtonStock title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/nsi';

  export default {
    name: 'MeasureKindCard',
    components: {
      ComponentInput,
      ButtonStock,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        apiRoute: 'NsiMeasureKind',
        cardName: 'MeasureKindCard',
        listName: 'MeasuresKinds',
        select2Settings: Constants.select2Settings,
        barrierCategories: [],
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadDictionaries() {
        API.search('nsibarriercategory', this.defaultRequest)
          .then((response) => {
            this.barrierCategories = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      loadCard() {
        if (this.id > 0) {
          API.find(this.apiRoute, this.id).then((response) => {
            this.item = response.data;
          });
        } else {
          this.item = {
            isActive: true,
          };
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.update(this.apiRoute, this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              this.$router.push({ name: this.cardName, params: { id: response.data.id, action: 'edit' } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: this.listName });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
