<template>
  <div class="healthcare healthCareCountryAnalitics">
    <div class="d-flex align-items-center justify-content-end">
      <p class="mr-6">Источник: Университет Джонса Хопкинса</p>
      <Multiselect
        class="select-country-analytic"
        label="text"
        :searchable="true"
        v-model="filter.year"
        valueProp="id"
        track-by="text"
        noResultsText="Ничего не найдено."
        noOptionsText="Список пуст."
        :options="data.arrayOfYears"
        :canClear="false"
        placeholder="Период"
        @select="changeFilter"
      />
    </div>
    <h3>Ключевые показатели</h3>
    <div class="healthcare__wrapper mt-4 my-4 d-flex align-items-center justify-content-between">
      <span>
        Новые случаи заражения COVID-19
        <br />
        <span class="count">
          {{ data.totalCountInfections ? data.totalCountInfections : 'Нет данных.' }}
        </span>
      </span>

      <span>
        Количество вакцинированных
        <br />
        <span class="count">
          {{ data.totalCountVaccinated ? data.totalCountVaccinated : 'Нет данных.' }}
        </span>
      </span>
    </div>

    <LineChartDouble
      v-if="!isLoading"
      :tension="0.4"
      color-datalabels="#000"
      background-color-two="#30d5c8"
      background-color="trasparent"
      :fill="true"
      point-color-two="#30d5c8"
      point-color="red"
      border-color-two="#30d5c8"
      border-color="red"
      format-label="тыс"
      :chart-data-second="data.arrayOfVaccinated"
      :chart-data="data.arrayOfInfections"
      :labels="data.arrayOfMonth"
      :displayDataLabels="false"
    />
    <div class="healthcare__indicators">
      <p class="healthcare__indicators-text">
        <span
          style="min-height: 14px; min-width: 14px; background-color: red"
          class="rounded-circle d-block mr-1"
        ></span
        >Новые случаи заражения COVID-19
      </p>
      <p class="healthcare__indicators-text">
        <span
          style="min-height: 14px; min-width: 14px; background-color: #30d5c8"
          class="rounded-circle d-block mr-1"
        ></span
        >Количество вакцинированных
      </p>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import Constants from '@/common/constants';

  import LineChartDouble from '@/components/lineChartDouble';

  import Api from '@/modules/importanalytics/api/healthcare.js';

  export default {
    name: 'healthcare',
    components: {
      LineChartDouble,
      Multiselect,
    },
    props: {
      country: String,
    },
    data() {
      return {
        months: Constants.monthListSimple,
        page: {},
        request: {
          pageNumber: 1,
          pageSize: 24, //Два года
        },
        isLoading: false,
        filter: {},
        data: {
          totalCountInfections: null,
          arrayOfInfections: [],
          totalCountVaccinated: null,
          arrayOfVaccinated: [],
          arrayOfYears: null,
          arrayOfMonth: [],
        },
      };
    },
    methods: {
      initialLoadPage(append) {
        this.isLoading = !append;
        this.filter.countryId = this.country;
        var params = Object.assign(this.filter, this.request);
        Api.search(params)
          .then((response) => {
            if (!append) {
              this.page = response.data;
              this.page.items = this.page.items.sort((a, b) => {
                if (a.year != b.year) return a.year - b.year;
                if (a.month != b.month) return a.month - b.month;
              });
              // Собираем года
              this.data.arrayOfYears = response.data.items
                .map((x) => {
                  return { text: x.year, id: x.year };
                })
                // Фильтр на дубли
                .filter(
                  (value, index, self) => index === self.findIndex((t) => t.id === value.id && t.text === value.text),
                );
              // Собираем месяц + год
              // В массив для графика
              this.data.arrayOfMonth = response.data.items.map((x) => {
                return `${this.months[x.month - 1]} ${x.year}`;
              });
              // Собираем инфицированных
              // В массив для графика
              this.data.arrayOfInfections = response.data.items.map((x) => {
                return x.covidNewCaseCount / 1000;
              });
              // В сумму
              this.data.totalCountInfections = response.data.items
                .map((x) => {
                  return x.covidNewCaseCount;
                })
                .reduce((partialSum, a) => partialSum + a, 0);
              // Собираем вакцинированных
              // В массив для графика
              this.data.arrayOfVaccinated = response.data.items.map((x) => {
                return x.vaccinatedCount / 1000;
              });
              // // В сумму
              this.data.totalCountVaccinated = response.data.items
                .map((x) => {
                  return x.vaccinatedCount;
                })
                .reduce((partialSum, a) => partialSum + a, 0);
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
            this.$nextTick(() => {
              if (this.data.arrayOfYears.length) {
                this.filter.year = this.data.arrayOfYears[this.data.arrayOfYears.length - 1].id;
                this.changeFilter();
              }
            });
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadPage(append) {
        this.isLoading = !append;
        this.filter.countryId = this.country;
        var params = Object.assign(this.filter, this.request);
        Api.search(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
              // В массив для графика
              this.data.arrayOfMonth = response.data.items.map((x) => {
                return `${this.months[x.month - 1]} ${x.year}`;
              });
              // Собираем инфицированных
              // В массив для графика
              this.data.arrayOfInfections = response.data.items.map((x) => {
                return x.covidNewCaseCount / 1000;
              });
              // В сумму
              this.data.totalCountInfections = response.data.items
                .map((x) => {
                  return x.covidNewCaseCount;
                })
                .reduce((partialSum, a) => partialSum + a, 0);
              // Собираем вакцинированных
              // В массив для графика
              this.data.arrayOfVaccinated = response.data.items.map((x) => {
                return x.vaccinatedCount / 1000;
              });
              // // В сумму
              this.data.totalCountVaccinated = response.data.items
                .map((x) => {
                  return x.vaccinatedCount;
                })
                .reduce((partialSum, a) => partialSum + a, 0);
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.loadPage(false);
      },
    },
    created() {
      this.initialLoadPage();
    },
    watch: {
      country: function () {
        this.filter.year = null;
        this.initialLoadPage(false);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  @import '../../styles/countryAnalyticsMultiselect.scss';
  .month {
    margin-top: 28px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__item {
      cursor: pointer;
      padding: 8px 16px;
      font: $fira-12-M;
      font-style: normal;
      text-align: center;
      color: #c6c7c7;
      transition: 0.2s ease-in-out;
      border: 1px solid transparent;
      &:hover {
        color: #01a39d;
      }
      &.active {
        color: #0193ba;
        border-radius: 4px;
        border-color: $colorGradient;
        border: 1px solid;
      }
    }
  }
  .healthcare {
    .select-country-analytic::v-deep {
      width: 150px !important;
      margin: 0;
      input:focus {
        border: unset;
        border-bottom: 1px solid $color8;
      }
    }
    &__indicators {
      display: flex;
      flex-wrap: wrap;
    }
    &__indicators-text {
      margin-top: 20px;
      margin-right: 24px;
      display: flex;
      font: $fira-12;
      font-style: normal;
      text-align: center;
      color: #333333;
    }
    &__wrapper {
      max-width: 409px;
      span {
        max-width: 114px;
        font: $fira-12;
        font-style: normal;
        text-align: center;
        color: #333333;
      }
      .count {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
    }
    h3 {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }
  }
</style>
