<template>
  <div class="container-page">
    <div>
      <p class="title-inner">
        {{ dictionary.title }}
      </p>

      <div class="mb-16 d-flex flex-row justify-content-between">
        <ButtonStock title="Создать" v-if="canEdit" @click="editCard(0)" />
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div v-if="page && dictionary">
        <div>
          <div class="table-container mb-16 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>Наименование</th>
                  <th>Статус</th>
                  <th v-if="canEdit"></th>
                  <th v-if="canEdit"></th>
                </tr>
                <tr>
                  <th class="filter">
                    <FilterInput
                      placeholder="Введите название..."
                      v-model="filter.name"
                      v-on:valueChangedDebounced="changeFilter"
                    />
                  </th>
                  <th class="filter">
                    <FilterActiveInput v-model="filter.active" v-on:valueChangedDebounced="changeFilter" />
                  </th>
                  <th v-if="canEdit" class="filter"></th>
                  <th v-if="canEdit" class="filter"></th>
                </tr>
              </thead>
              <tbody v-if="page.items?.length">
                <tr v-for="item in page.items" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>
                    <ActiveComponent :value="item.isActive" />
                  </td>
                  <td v-if="canEdit">
                    <IconComponent @click="editCard(item.id)" name="mode" />
                  </td>
                  <td v-if="canEdit">
                    <IconComponent @click="deleteCard(item)" name="delete" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="page.pagesTotal > 1">
            <Pagination
              :page-count="page.pagesTotal"
              :current-page="page.pageNumber"
              @change="onPageChange($event, false)"
              @more="onPageChange($event, true)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <p>Ничего не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterActiveInput from '@/common/components/FilterActiveInput';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import ActiveComponent from '@/common/ui/ActiveComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/dictionary';

  export default {
    name: 'DictionaryItems',
    components: {
      IconComponent,
      ButtonStock,
      ActiveComponent,
      FilterInput,
      FilterActiveInput,
      DefaultFilter,
    },
    mixins: [security, filtermanager],
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.NsiAccess);
      this.checkPermission(Constants.Permissions.NsiEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage();
      });
    },
    mounted() {
      console.log(this.$route);
    },
    data() {
      return {
        name: this.$route.params.name,
        request: {
          dictName: this.$route.params.name,
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          name: '',
          active: null,
        },
        canEdit: false,
        dictionary: {},
        page: {},
      };
    },
    methods: {
      loadPage(addToExist) {
        var params = Object.assign({}, this.request);
        params.name = this.filter.name;
        params.isActive = this.filter.active;

        API.getDictionary(this.name).then((response) => {
          this.dictionary = response.data;
        });
        API.getItems(params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      viewCard(id) {
        this.$router.push({
          name: 'dictionaryItemCard',
          params: { name: this.name, action: 'view', id: id },
        });
      },
      editCard(id) {
        if (this.canEdit) {
          this.$router.push({
            name: 'dictionaryItemCard',
            params: { name: this.name, action: 'edit', id: id },
          });
        } else {
          this.viewCard(id);
        }
      },
      deleteCard(item) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(this.name, item.id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись справочника удалена.', 'success');
                this.loadPage();
              })
              .catch(() => {
                Constants.alert.fire('Удаление', 'Ошибка при удалении записи справочника.', 'error');
              });
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
  .table-container table {
    min-width: auto;
    width: 100%;
  }
</style>
