import { BASE_VES_ACTIONS_NAME, BASE_VES_MUTATIONS_NAME } from './name.js';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getYearLast(context, params) {
  const { state } = context;
  state.loadYear = true;
  return await request.call(
    this,
    context,
    {
      url: links.ves.getYearFirst,
      mutate: BASE_VES_MUTATIONS_NAME.setYearFirst,
      name: BASE_VES_ACTIONS_NAME.getYearLast,
    },
    params,
  );
}
