<template>
  <div class="digital-map-project container-page big">
    <h2 class="title title--h2">Карта проектов</h2>
    <div class="digital-map-project__filters">
      <div class="digital-map-project__filters-wrapper">
        <div class="digital-map-project__filters-col">
          <span class="label-field">Страна</span>
          <SelectMultiComponent
            :options="countryList"
            placeholder="Все"
            modifier="rectangular"
            v-model="filter.countryIds"
          />
        </div>
        <div class="digital-map-project__filters-col">
          <span class="label-field">Отрасль</span>
          <SelectMultiComponent
            :options="industries"
            placeholder="Все"
            modifier="rectangular"
            v-model="filter.industryIds"
          />
        </div>
        <div class="digital-map-project__filters-col">
          <span class="label-field">Цель проекта</span>
          <SelectMultiComponent
            :options="purposes"
            placeholder="Все"
            modifier="rectangular"
            v-model="filter.purposeIds"
          />
        </div>
        <div class="digital-map-project__filters-col">
          <span class="label-field">Статус реализации проекта</span>
          <SelectMultiComponent
            :options="statusList"
            placeholder="Все"
            modifier="rectangular"
            v-model="filter.statusIds"
          />
        </div>
        <div class="digital-map-project__filters-col">
          <span class="label-field">МПК</span>
          <SelectMultiComponent
            :options="mpk"
            placeholder="Все"
            modifier="rectangular"
            v-model="filter.mpkComissionId"
          />
        </div>
        <div class="digital-map-project__filters-col">
          <div class="digital-map-project__filters-buttons">
            <ButtonComponent class="w-100" mod="gradient-bg" :disabled="!loaded" @click="changeFilter">
              Применить
            </ButtonComponent>
            <ButtonComponent class="w-100" :disabled="!loaded" @click="resetFilter">Сбросить</ButtonComponent>
          </div>
        </div>
      </div>
    </div>

    <Loader
      style="margin: 3rem auto"
      class="d-flex text-center align-items-center justify-content-center project-loader"
      v-if="!loaded"
    />

    <SummaryInfoList class="digital-map-project__summary-info" v-if="projects" :list="summaryInfo" />

    <div class="map-block" v-if="projects">
      <div class="map-block__body">
        <MapComponent
          class="map-block__map"
          v-show="loaded"
          :projects="projects.items"
          :maxProjects="projects.maxProjects"
          @onCountryHeaderClick="onCountryHeaderClick"
        />
      </div>
      <div class="map-block__footer">
        <MapScaleQuantity name="Количество проектов" averageCount="20" :totalCount="projects.maxProjects" />

        <ButtonStock btnType="secondary" title="Аналитика по проектам" @click="onProjectStatisticClick" />
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import MapScaleQuantity from '@/common/components/redesigned/MapScaleQuantity.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import filtermanager from '@/common/mixins/filtermanager';
  import API_COUNTRY from '@/common/utils';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import SummaryInfoList from '@/components/lists/SummaryInfoList.vue';
  import Loader from '@/components/Loader';

  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/index';
  import MapComponent from '../components/mapComponent';

  export default {
    name: 'ProjectsMap',
    components: {
      SummaryInfoList,
      ButtonComponent,
      SelectMultiComponent,
      Loader,
      MapComponent,
      ButtonStock,
      MapScaleQuantity,
    },
    mixins: [filtermanager],
    data() {
      return {
        countries: [],
        purposes: [],
        industries: [],
        statuses: [],
        mpk: [],
        filter: {
          countryIds: null,
          industryIds: null,
          statusIds: null,
          purposeIds: null,
          mpkComissionId: null,
        },
        projects: null,
        loaded: false,
      };
    },
    computed: {
      countryList() {
        return this.countries.map((i) => ({
          value: i.id,
          label: i.text,
        }));
      },
      statusList() {
        return this.statuses.map((i) => ({
          value: i.id,
          label: i.text,
        }));
      },
      summaryInfo() {
        return [
          { label: 'Всего проектов', value: this.projects?.itemsTotal },
          { label: 'Всего стран', value: this.projects?.countryTotal },
          { label: 'Всего отраслей', value: this.projects?.industryTotal },
          { label: 'МПК', value: this.projects?.projectsHasMpkTotal },
        ];
      },
    },
    created() {
      this.loadDictionaries();
      this.loadPage();
    },
    methods: {
      loadPage() {
        this.loaded = false;
        this.projects = null;
        let filters = {};
        this.filter.countryIds ? (filters.countryIds = [this.filter.countryIds]) : null;
        this.filter.industryIds ? (filters.industryIds = [this.filter.industryIds]) : null;
        this.filter.statusIds ? (filters.statusIds = [this.filter.statusIds]) : null;
        this.filter.purposeIds ? (filters.purposeIds = [this.filter.purposeIds]) : null;
        this.filter.mpkComissionId ? (filters.MpkComissionId = this.filter.mpkComissionId) : null;
        let params = Object.assign({}, filters);
        API.projectsByCountry(params).then((response) => {
          this.projects = response.data;
          this.loaded = true;
        });
      },
      loadDictionaries() {
        this.loaded = false;
        let request = { pageNumber: 1, pageSize: 100000 };
        API_COUNTRY.loadSelectOptions('countryList', this.countries, true);
        Utils.loadSelectOptions('mpkProjectStatusList', this.statuses);
        //отрасли
        API_NSI.search('NsiIndustry', request).then((response) => {
          if (response.data) {
            this.industries = response.data.items.map((o) => {
              return { label: o.name, value: o.id };
            });
            this.loaded = true;
          }
          this.industries.sort((a, b) => (a.vabel > b.label ? 1 : -1));
        });
        //цели
        API_NSI.search('MpkProjectPurpose', request).then((response) => {
          if (response.data) {
            this.purposes = response.data.items.map((o) => {
              return { label: o.name, value: o.id };
            });
            this.loaded = true;
          }
        });
        API.getMpkProject().then((response) => {
          if (response.data) {
            this.mpk = response.data.map((o) => {
              return { label: o.name, value: o.id };
            });
          }
        });
      },
      changeFilter() {
        this.saveFilter(this.filter);
        this.loadPage();
      },
      onProjectStatisticClick() {
        this.$router.push({
          name: 'ProjectsStatistics',
          params: {
            countryId: this.filter.countryIds,
            statusId: this.filter.statusIds,
            industryId: this.filter.industryIds,
          },
        });
      },
      onCountryHeaderClick(countryId) {
        if (countryId) {
          this.$router.push({
            name: 'Projects',
            params: {
              countryId: [countryId],
              statusId: this.filter.statusIds ? [this.filter.statusIds] : [],
              industryId: this.filter.industryIds ? [this.filter.industryIds] : [],
              purposeId: this.filter.purposeIds ? [this.filter.purposeIds] : [],
            },
          });
        }
      },
      resetFilter() {
        this.removeFilter();
        this.filter = {
          countryIds: null,
          industryIds: null,
          statusIds: null,
          purposeIds: null,
          mpkComissionId: null,
        };
        this.loadPage();
      },
    },
  };
</script>

<style src="../styles/digital-map-project.scss" scoped lang="scss" />

<style src="../styles/map-block.scss" scoped lang="scss" />

<style lang="scss">
  @import '@/assets/theme/default/title.scss';
  // fix outline in map
  .digital-map-project {
    .leaflet-interactive {
      outline: none;
    }
  }
</style>
