import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/task/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/task/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/task/update?id=${data.id}`, data);
    else return axios.post(`/api/task/create`, data);
  },
  saveReport: function (data) {
    return axios.put(`api/task/savereport?id=${data.id}`, {
      issueId: data.id,
      report: data.text,
      issueReportDocumentsIds: data.ids,
      reportFiles: data.files,
    });
  },
  delete: function (id) {
    return axios.delete('/api/task/delete?id=' + id);
  },
  getEmployeeList: function () {
    return axios.get(`/api/task/getEmployeeList`);
  },
  getManagerList: function () {
    return axios.get(`/api/task/getManagerList`);
  },
};
