import axios from 'axios';

export default {
  getListByIds: function (route, ids) {
    return axios.get('/api/nsi/' + route + '/listbyids', { params: { ids: ids } });
  },
  getTnVedScenarios: function (id) {
    return axios.get(`/api/nsi/nsitnved/scenarios/${id}`, {});
  },
};
