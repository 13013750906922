<template>
  <div class="international-trade-chart">
    <div class="international-trade-chart__header">
      <h2 v-if="title" class="title title--chart-analytics">{{ title }}</h2>
      <RedesignedSwitchComponent
        v-if="nameTable"
        :textTrue="textTrue"
        :textFalse="textFalse"
        @switchHandler="switchHandler"
        class="switch-back--big"
      />
    </div>
    <!-- Диаграмма -->
    <BarChartTooltip v-if="switchChart" v-bind="getChart" />
    <!-- Таблица -->
    <InternationalTradeExportTable
      v-else
      class="mt-0"
      dividerLabel="тыс $"
      :name="nameTable"
      :currentSummary="currentSummary"
      :exportGroups="exportGroups"
      :exportGroupsPrevious="exportGroupsPrevious"
      :topExportGroups="topExportGroups"
    />
  </div>
</template>

<script>
  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import Constants from '@/common/constants';

  import BarChartTooltip from '@/components/ChartComponents/BarChartTooltip.vue';

  import InternationalTradeExportTable from './InternationalTradeExportTable.vue';

  export default {
    name: 'InternationalTradeChart',
    components: {
      RedesignedSwitchComponent,
      BarChartTooltip,
      InternationalTradeExportTable,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      textTrue: {
        type: String,
        default: 'Диаграмма',
      },
      textFalse: {
        type: String,
        default: 'Таблица',
      },
      typeChart: {
        type: String,
        default: '',
      },
      nameTable: {
        type: String,
        default: '',
      },
      exportGroups: {
        type: String,
        default: '',
      },
      exportGroupsPrevious: {
        type: String,
        default: '',
      },
      topExportGroups: {
        type: String,
        default: '',
      },
      currentSummary: {
        type: Object,
        default: () => {},
      },
      switchChartProp: {
        type: Boolean,
        default: true,
      },
      legendName: {
        type: Object,
        default: () => {
          return {
            current: '',
            previous: '',
          };
        },
      },
    },
    data() {
      return {
        switchChart: this.switchChartProp,
        emptyLabels: ['', '', '', '', ''],
      };
    },
    computed: {
      getChart() {
        let seriesProp,
          categories = [];
        if (this.currentSummary && this.typeChart) {
          categories = this.currentSummary[this.typeChart].map((item) => ({
            id: item.id,
            fullName: item?.fullName || '',
            abbreviated: item?.title || '',
          }));
          seriesProp = {
            labels: this.emptyLabels,
            datasets: [
              {
                label: this.legendName?.current || '',
                data: this.currentSummary[this.typeChart].map((item) =>
                  (item.currentValue / Constants.MILLION).toFixed(1),
                ),
                backgroundColor: '#05B1E0',
              },
              {
                label: this.legendName?.previous || '',
                data: this.currentSummary[this.typeChart].map((item) =>
                  (item.previousValue / Constants.MILLION).toFixed(1),
                ),
                backgroundColor: '#00DEB6',
              },
            ],
          };
        }
        return {
          categories,
          seriesProp,
        };
      },
    },
    methods: {
      switchHandler() {
        this.switchChart = !this.switchChart;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/title.scss';

  .international-trade-chart {
    margin-top: 60px;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }

    @media (max-width: $mobile) {
      &__header {
        flex-direction: column;
      }
    }
  }
</style>
