export const routes = [
  {
    path: '/analytics-cross-cutting-task',
    name: 'analytics-cross-cutting-task',
    component: () => import('@/modules/analyticsForCrossCuttingTasks/pages/PageAnalyticsForCrossCuttingTasks'),
  },
  {
    path: '/analytics-cross-cutting-task/:id',
    name: 'analytics-cross-cutting-task-detail',
    component: () => import('@/modules/analyticsForCrossCuttingTasks/pages/PageAnalyticsForCrossCuttingTasksDetail'),
  },
];
