<template>
  <div class="financial-sector">
    <div class="d-flex justify-content-between">
      <h2>Ключевые показатели</h2>
      <div class="analytics-source-text">Источник: Trading Economics</div>
    </div>

    <ul class="indicators__list">
      <li class="indicators__item">
        <p>Доходность гос. ценных бумаг</p>
        <p v-if="data" class="d-block">{{ data.date }}</p>
        <div v-if="!!data" class="d-flex justify-content-center align-items-center">
          <strong>{{ data.yield !== null ? formatNumber(data.yield) + '%' : '—' }}</strong>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <span>Нет данных.</span>
        </div>
      </li>
      <li class="indicators__item">
        <p>Капитализация рынка акций</p>
        <p v-if="data" class="d-block">{{ data.date }}</p>
        <p class="d-block">
          Источник:
          <br />
          CompaniesMarketCap.com
        </p>
        <div v-if="!!data" class="d-block">
          <strong class="d-block">{{
            data.totalMarketCap !== null ? formatNumber(data.totalMarketCap / 1000000000.0) : '—'
          }}</strong>
          <span class="d-block" v-if="data?.totalMarketCap !== null">млрд $ США</span>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <span>Нет данных.</span>
        </div>
      </li>
    </ul>
    <h2 class="mt-5">Денежное предложение, {{ country?.currency ?? '-' }}</h2>
    <template v-if="!!data">
      <ColumnChart
        :key="chartKey"
        :format-label-function="formatLabel"
        :chart-data="chartData"
        :colors="['#37bde3', '#165e75', '#a3d8e5']"
        :labels="['m0', 'm1', 'm2', 'm3']"
      />
    </template>
    <template v-else>
      <div class="d-flex justify-content-center align-items-center">
        <span>Нет данных.</span>
      </div>
    </template>
    <p>*0 показатель означает отсутствие данных в источнике</p>

    <div class="indicators__description mt-4">
      <p class="indicators__description-item">М0 = наличные деньги в обращении (монеты, банкноты).</p>
      <p class="indicators__description-item">
        М1 = М0 + чеки, вклады до востребования (в том числе банковские дебетовые карты), остатки средств в национальной
        валюте на расчётных счетах организаций, текущих и иных счетах до востребования населения, нефинансовых и
        финансовых (кроме кредитных) организаций.
      </p>
      <p class="indicators__description-item">М2 = М1 + срочные вклады.</p>
      <p class="indicators__description-item">
        М3 = М2 + сберегательные вклады, сертификаты и государственные облигации.
      </p>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text.js';

  import ColumnChart from '@/components/columnChart';

  import CountryApi from '@/modules/nsi/api/country';

  import Api from '../../api';

  export default {
    name: 'FinancialSector',
    components: { ColumnChart },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    mixins: [text],
    data() {
      return {
        data: null,
        country: null,
      };
    },
    created() {
      if (this.countryId) {
        this.loadData();
      }
    },
    methods: {
      loadData() {
        Api.getFinancialSector(this.countryId).then((response) => {
          this.data = response.data;
          this.data.date = this.$momentFormat(this.data.modified, 'DD.MM.YYYY');
        });

        CountryApi.find(this.countryId)
          .then((response) => {
            this.country = response.data;
          })
          .catch(() => {
            this.country = null;
          });
      },
      formatLabel(value) {
        return (
          this.formatNumber(value, 0).replace('.', ',') +
          (this.maxNumbering > 0 ? ' ' + Constants.finSectorNumbering[this.maxNumbering] : '')
        );
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    computed: {
      chartKey() {
        return this.data ? (this.data.m0 + this.data.m1 + this.data.m2 + this.data.m3).toString() : '';
      },
      maxNumbering() {
        if (this.data)
          return Math.max(
            this.data.numberingM0 ?? -1,
            this.data.numberingM1 ?? -1,
            this.data.numberingM2 ?? -1,
            this.data.numberingM3 ?? -1,
          );
        else return 0;
      },
      chartData() {
        if (this.data) {
          let maxNumbering = this.maxNumbering;
          if (maxNumbering > 0) {
            return [
              (this.data.m0 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM0 ?? 0)),
              (this.data.m1 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM1 ?? 0)),
              (this.data.m2 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM2 ?? 0)),
              (this.data.m3 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM3 ?? 0)),
            ];
          } else return [this.data.m0, this.data.m1, this.data.m2, this.data.m3];
        } else return [];
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators__list {
    display: flex;
    gap: 16px 12px;
  }
  .indicators__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 180px;
    width: 100%;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
    border: 1px solid #06a0d8a9;
    border-radius: 5px;
    @media (max-width: 1042px) {
      max-width: calc((100% / 4) - 18px);
    }
    @media (max-width: 892px) {
      max-width: calc((100% / 3) - 18px);
    }
    @media (max-width: 546px) {
      max-width: calc((100% / 2) - 18px);
    }
    @media (max-width: 546px) {
      max-width: 100%;
    }
    p {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 113.9%;
      text-align: center;
      color: #333333;
      margin-bottom: 8px;
    }
    strong {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #333333;
    }
    span {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #333333;
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  p {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 113.9%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
  }
  .indicators__description-item {
    text-align: start;
  }
</style>
