<template>
  <div class="tinymce-component">
    <Editor
      v-model="content"
      :init="init"
      output-format="html"
      @focus="onFocus"
      @blur="onBlur"
    />
    <span v-if="placeholder && visiblePlaceholder && !content" class="tinymce-component__label">{{ placeholder }}</span>
  </div>
</template>

<script>
  // Missing plugin
  import Editor from '@tinymce/tinymce-vue';
  /* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
  import contentCss from 'tinymce/skins/content/default/content.css';
  /* content UI CSS is required */
  import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css';
  import 'tinymce/tinymce';
  import 'tinymce/themes/silver';
  import 'tinymce/icons/default';
  import 'tinymce/skins/ui/oxide/skin.css';
  /* Import plugins */
  import 'tinymce/plugins/advlist';
  import 'tinymce/plugins/autolink';
  import 'tinymce/plugins/lists';
  import 'tinymce/plugins/image';
  import 'tinymce/plugins/charmap';
  import 'tinymce/plugins/print';
  import 'tinymce/plugins/preview';
  import 'tinymce/plugins/anchor';
  import 'tinymce/plugins/searchreplace';
  import 'tinymce/plugins/visualblocks';
  import 'tinymce/plugins/code';
  import 'tinymce/plugins/fullscreen';
  import 'tinymce/plugins/insertdatetime';
  import 'tinymce/plugins/media';
  import 'tinymce/plugins/table';
  import 'tinymce/plugins/paste';
  import 'tinymce/plugins/help';
  import 'tinymce/plugins/wordcount';
  import 'tinymce/plugins/link';
  import '@/components/tinymce/langs/ru';

  export default {
    //eslint-disable-next-line
    name: 'TinyMceEditor',
    components: {
      Editor,
    },
    props: {
      modelValue: String,
      init: {
        type: Object,
        default: () => ({
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link charmap preview anchor',
            'searchreplace visualblocks code',
            'media table paste code wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor forecolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
          skin: false, // disable import of skins
          content_css: false, // disable import of css
          content_style: contentUiSkinCss.toString() + '\n' + contentCss.toString(), // add styles as strings
          language: 'ru',
        }),
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        content: this.modelValue,
        visiblePlaceholder: true,
      };
    },
    created() {
      if (this.modelValue) this.content = this.modelValue;
      else this.content = '';
    },
    watch: {
      modelValue: function (newValue) {
        this.content = newValue ?? '';
      },
      content: function (newValue) {
        this.$emit('update:modelValue', newValue ?? '');
      },
    },
    methods: {
      onFocus() {
        this.visiblePlaceholder = false;
      },
      onBlur() {
        this.visiblePlaceholder = true;
      },
    },
  };
</script>

<style lang="scss">
  .tinymce-component {
    position: relative;

    &__label {
      position: absolute;
      top: 55px;
      left: 14px;
      font: $fira-14-20;
      color: $dark-grey-1;
      z-index: 1;
      pointer-events: none;
    }

    .tox-edit-area {
      padding: 0 6px;
    }
  }
</style>
