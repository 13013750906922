<template>
  <div class="container__legends mt-4">
    <p>{{ title }}</p>
    <ul>
      <li v-for="(legendColor, index) of colors" v-bind:key="index">
        <div :style="'background-color: ' + legendColor.color"></div>
        <span>{{ legendColor.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MapLegendControl',
    data() {
      return {};
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      colors: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container__legends {
    margin-top: 0.5rem;
    display: flex;
    gap: 30px;

    ul {
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
      align-items: center;

      li {
        display: flex;
        gap: 8px;
        align-items: center;

        div {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
</style>
