<template>
  <div class="chart-container">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/assets/theme/default/global/mixin.scss';

  .chart-container {
    max-width: 848px;
    width: 100%;
    padding: 50px 40px;

    @include borderAndBgCard();

    @media (max-width: $desktop) {
      padding: 24px;
    }
    @media (max-width: $laptop) {
      overflow-x: auto;
      padding: 16px;
    }
  }
</style>
