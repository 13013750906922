<template>
  <!-- Тестовая страница карточки мпк -->
  <!-- Вид страниц как для админа -->
  <div class="container-page">
    <div class="card-mpk">
      <p class="card-mpk__title title-inner">Карточка поручения Загранаппарат</p>
      <div class="card-mpk__body">
        <!-- Карточка -->
        <div class="card-mpk__card">
          <!-- body card -->
          <div class="card-mpk__card-body">
            <!-- row -->
            <div class="card-mpk__card-row">
              <!-- cols -->
              <div class="card-mpk__card-col1">
                <p class="title-column">Страна</p>
                <p>
                  {{ item.countryName }}
                </p>
              </div>
              <div class="card-mpk__card-col2">
                <p class="title-column">Связанное мероприятие/объект</p>
                <p>
                  {{ item.eventName }}
                </p>
              </div>
            </div>
            <!-- row -->
            <div class="card-mpk__card-row">
              <!-- cols -->
              <div class="card-mpk__card-col1">
                <p class="title-column">Тип поручения</p>
                <p>
                  {{ item.type?.name }}
                </p>
              </div>
              <div class="card-mpk__card-col2">
                <p class="title-column">Приоритет</p>
                <p>
                  {{ item.urgency?.name }}
                </p>
              </div>
            </div>
            <!-- row -->
            <div class="card-mpk__card-row">
              <!-- cols -->
              <div class="card-mpk__card-col1">
                <p class="title-column">Наименование поручения</p>
                <p>
                  {{ item.assignmentName }}
                </p>
              </div>
              <div class="card-mpk__card-col2">
                <p class="title-column">Срок</p>
                <p>
                  {{ $momentFormat(item.deadline, 'DD MMMM YYYY') }}
                </p>
              </div>
            </div>

            <!-- row  report-->
            <div class="card-mpk__card-row report">
              <!-- cols -->
              <div class="card-mpk__card-col1 report-container">
                <p class="title-column">Отчет</p>
                <div class="report-column">
                  <!-- текст отчета здесь -->
                  <p class="report-text">
                    {{ item.report }}
                  </p>
                  <p class="report-text" v-if="item.reportDocument">
                    <a href="javascript:" @click.prevent="downloadFile(item)">Файл к отчету</a>
                  </p>

                  <div class="btn-container d-flex w100">
                    <template v-if="!item.postponed">
                      <template v-if="item.canEditStatus">
                        <template v-if="item.canApproveStatus">
                          <ButtonStock title="На согласование" @click="nextStep()" :disabled="!item.report" />
                          <ButtonStock btnType="secondary" @click="createReport()" title="Внести отчет" />
                        </template>
                        <template v-else>
                          <ButtonStock title="Внести отчет" @click="createReport()" />
                        </template>
                      </template>
                      <template v-else-if="item.canApproveStatus">
                        <ButtonStock title="Согласовать" @click="nextStep()" />
                        <ButtonStock btnType="secondary" @click="cancelReport()" title="Отклонить отчет" />
                      </template>
                    </template>

                    <ButtonStock
                      v-if="item.canPostponeStatus"
                      btnType="secondary"
                      :title="item.postponed ? 'Возобновить' : 'Приостановить'"
                      @click="setPostponed()"
                    />

                    <ButtonStock
                      v-if="item.refuses && item.refuses.length > 0"
                      btnType="secondary"
                      title="Причины отказа"
                      @click="showRefuseReason()"
                    />
                  </div>
                </div>
              </div>
              <div class="card-mpk__card-col2 status">
                <p class="title-column">Статус</p>
                <div class="d-flex status-container" :class="getStatusClassColor(item)" v-if="item.id">
                  <IconComponent :name="getStatusClassIcon(item)" />
                  <span>{{ item.statusName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <ButtonStock btnType="secondary" title="Закрыть" v-on:click="close" />
      </div>
      <ModalComponent title="Отклонить" @close="dialogCancel = false" v-model="dialogCancel">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <p>Причина</p>
            <textarea placeholder="Напишите причину отклонения" v-model="reason"></textarea>
            <div class="invalid-msg" v-if="v$.reason.$error">Поле обязательно для заполнения</div>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="cancelReportSend" />
            <ButtonStock @click="dialogCancel = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Внести отчет" @close="dialogMakeReport = false" v-model="dialogMakeReport">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <p>Отчет</p>
            <textarea placeholder="Вставьте или напишите здесь текст отчета" v-model="modal.report"></textarea>
            <div class="invalid-msg" v-if="v$.modal.report.$error">Поле обязательно для заполнения</div>
          </div>
          <div class="form__body-inputBlock mb-16">
            <p>Файл к отчету</p>
            <FileUpload
              width="100%"
              :entity="modal"
              @deleteFile="removeFile()"
              required
              prop-name="reportDocument"
              @uploaded="fileUploaded"
            />
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Сохранить" @click="createReportSend" />
            <ButtonStock @click="dialogMakeReport = false" btnType="whiteGreenBordered" title="Отмена" />
          </div>
        </div>
      </ModalComponent>

      <ModalComponent title="Причины отказа" @close="dialogRefuse = false" v-model="dialogRefuse">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <template v-for="reason in refuseReasons" :key="reason.id">
              <p>{{ reason.createdBy.fullName }} ({{ $momentFormat(reason.created, 'DD MMMM YYYY') }})</p>
              <div>{{ reason.reason }}</div>
            </template>
          </div>
          <div>
            <ButtonStock @click="dialogRefuse = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import FileUpload from '@/common/components/FileUpload';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/assignment';

  export default {
    name: 'EapAssignmentCard',
    components: {
      ButtonStock,
      IconComponent,
      ModalComponent,
      FileUpload,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        /**
         * @public
         * Используется в миксине security
         */
        isReadonly: this.$route.params.action === 'view',

        item: {},
        modal: {
          report: '',
          reportDocument: null,
        },
        reason: '',
        refuseReasons: [],

        dialogMakeReport: false,
        dialogCancel: false,
        dialogRefuse: false,
      };
    },
    computed: {},
    created() {
      this.loadCard();
      this.checkPermissionAndRedirect(Constants.Permissions.EapAssignmentAccess);
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
          });
        } else {
          this.close();
        }
      },
      setPostponed() {
        API.setPostponed(this.item.id, !this.item.postponed)
          .then((response) => {
            console.log(response);
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      createReport() {
        this.modal = Object.assign(
          {},
          {
            report: this.item.report,
            reportDocument: this.item.reportDocument,
          },
        );
        this.dialogMakeReport = true;
      },
      createReportSend() {
        this.v$.$touch();
        if (this.v$.modal.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          id: this.item.id,
          report: this.modal.report,
          reportDocument: this.modal.reportDocument,
        };

        API.updateReport(itemToSave)
          .then((response) => {
            console.log(response);
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogMakeReport = false;
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      cancelReport() {
        this.dialogCancel = true;
      },
      cancelReportSend() {
        this.v$.$touch();
        if (this.v$.reason.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          assignmentId: this.item.id,
          reason: this.reason,
        };

        API.refuseReport(itemToSave)
          .then((response) => {
            console.log(response);
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogCancel = false;
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      nextStep() {
        //отправляем отчет на следующий шаг
        API.approveReport({ id: this.item.id })
          .then((response) => {
            console.log(response);
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      getStatusClassIcon(el) {
        //cir-done //cir-late //cir-close //cir-inWork
        if (el.postponed) return 'cir-close';
        if (el.statusId == Constants.eapAssignemntStatusDoneId) return 'cir-done';
        return el.overdue ? 'cir-late' : 'cir-inWork';
      },
      getStatusClassColor(el) {
        //status-done //status-late //status-close //cir-inWork
        if (el.postponed) return 'status-close';
        if (el.statusId == Constants.eapAssignemntStatusDoneId) return 'status-done';
        return el.overdue ? 'status-late' : 'status-inWork';
      },
      close() {
        this.$router.push({ name: 'EapAssignments' });
      },
      showRefuseReason() {
        this.dialogRefuse = true;
        this.refuseReasons = this.item.refuses;
      },
      removeFile() {
        this.modal.reportDocument = null;
      },
      fileUploaded(e) {
        this.modal.reportDocument = e.data;
      },
      downloadFile(item) {
        var url = Utils.getFileUrl(item.reportDocument.id);
        Utils.downloadFile(url, item.reportDocument.originalName);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        modal: {
          report: { required },
        },
        reason: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .card-mpk {
    &__body {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__card {
      padding: 30px;
      /*max-width: 705px;*/
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      &-row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dashed #d7d7d7;
        p {
          font-weight: 300;
          font-size: 14px;
          line-height: 150%;
          color: #343c44;
          margin-bottom: 0px;
          &.title-column {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #343c44;
            margin-bottom: 10px;
          }
        }
      }
      &-col {
        &1 {
          padding-right: 16px;
          /*max-width: 431px;*/
          width: 60%;
        }
        &2 {
          /*max-width: 201px;*/
          width: 35%;
        }
      }
    }
    .report {
      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #787f8f;
      }
      &-text {
        min-height: 50px;
        margin-bottom: 16px;
      }
      &-column {
        .btn-container {
          button {
            margin-right: 8px;
            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .status {
      &-container {
        .icon {
          margin-right: 6px;
        }
        span {
          font-size: 14px;
        }
      }
    }
    .status {
      &-done {
        color: #24c20f;
      }
      &-close {
        color: #f04f4f;
      }
      &-late {
        color: #f04f4f;
      }
      &-inWork {
        color: #ffa800;
      }
    }
  }
  @media (max-width: 1125px) {
    .card-mpk .chat-btn .icon {
      display: none;
    }
    .card-mpk__body {
      flex-wrap: wrap;
    }
    .card-mpk__card {
      order: 1;
      max-width: unset;
    }
    .card-mpk__card-row {
      justify-content: space-between;
    }
    .card-mpk__card-col1,
    .card-mpk__card-col2 {
      max-width: unset;
    }
  }
  @media (max-width: 767px) {
    .card-mpk__card {
      padding: 30px 12px;
    }
  }
  @media (max-width: 710px) {
    .card-mpk__card-row {
      flex-wrap: wrap;
      .card-mpk__card-col1 {
        margin-bottom: 8px;
      }
      .card-mpk__card-col2,
      .card-mpk__card-col1 {
        padding-right: 0px;
      }
      &.report {
        .card-mpk__card-col2 {
          order: 0;
          margin-bottom: 8px;
        }
        .card-mpk__card-col1 {
          order: 1;
        }
        .btn-container {
          flex-wrap: wrap;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .card-mpk__card-row {
      &.report {
        .btn-container {
          flex-wrap: wrap;
          button {
            width: 100%;
            margin-right: 0px;
            &:nth-of-type(1) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  .deadline {
    color: #787f8f;
    display: block;
  }
</style>
