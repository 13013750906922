<template>
  <div class="home container-page">
    <smart-widget-grid
      :resizable="false"
      :responsive="true"
      v-model:layout.sync="layout"
      :row-height="48"
      :margin="[20, 20]"
      :responsive-layouts="layouts"
      @breakpoint-changed="breakpointChangedEvent"
    >
      <!-- Интерфейс по стране -->
      <template #0>
        <WidgetStock title="Интерфейс по стране" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="widget-custom-in">
            <SelectComponent class="mr-6" placeholder="Выберите страну" :options="country" v-model="testSelected" />
            <ButtonStock @click="jumpToSelectedCountry(testSelected)" title="Перейти" />
          </div>
        </WidgetStock>
      </template>

      <template #1>
        <WidgetStock :title="'профили МПК: ' + countrySelected" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget5 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Горячие показатели -->
      <template #2>
        <WidgetStock title="Горячие показатели" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget4 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Новости -->
      <template #3>
        <WidgetStock title="Дайджест СМИ" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget2 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Кнопки -->
      <template #4>
        <WidgetStock class="widget-transparent" title="Кнопки" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget1 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- поручения МПК -->
      <template #5>
        <WidgetStock title="поручения МПК" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget9 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Анализ СМИ -->
      <template #6>
        <WidgetStock title="Анализ СМИ" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget11 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Поручения загранаппарату -->
      <template #7>
        <WidgetStock title="Поручения загранаппарату" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget8 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- проекты -->
      <template #8>
        <WidgetStock title="проекты" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget7 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Уведомления -->
      <template #9>
        <WidgetStock title="Уведомления" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget12 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Мои задачи -->
      <template #10>
        <WidgetStock title="Мои задачи" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget3 :countryId="id" />
        </WidgetStock>
      </template>

      <!-- Календарь -->
      <template #11>
        <WidgetStock title="Календарь" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <Widget6 :countryId="id" />
        </WidgetStock>
      </template>
    </smart-widget-grid>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  import SelectComponent from '@/common/components/selectComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import WidgetStock from '@/components/widgets/WidgetStock.vue';

  let testLayouts = {
    md: [
      // "i" = id элемента template
      { x: 0, y: 0, w: 4, h: 3, i: '0' },
      // Интерфейс по стране
      { x: 0, y: 0, w: 4, h: 3.5, i: '1' },
      // Горячие показатели
      { x: 0, y: 1, w: 4, h: 6.5, i: '2' },
      // News
      { x: 4, y: 0, w: 4, h: 10, i: '3' },
      // Кнопки
      { x: 8, y: 0, w: 2, h: 4, i: '4' },
      // поручения МПК
      { x: 0, y: 2, w: 6, h: 9, i: '5' },
      // Анализ СМИ
      { x: 6, y: 2, w: 4, h: 9, i: '6' },
      // Поручения загранаппарату
      { x: 0, y: 3, w: 6, h: 9, i: '7' },
      // проекты
      { x: 6, y: 3, w: 4, h: 9, i: '8' },
      // Уведомления
      { x: 0, y: 4, w: 4, h: 9, i: '9' },
      // Мои задачи
      { x: 4, y: 4, w: 6, h: 9, i: '10' },
      // Календарь
      { x: 0, y: 5, w: 6, h: 9, i: '11' },
    ],
    xxs: [
      { x: 0, y: 0, w: 4, h: 4, i: '0' },
      // Кнопки
      { x: 0, y: 0, w: 2, h: 4, i: '4' },
      // Интерфейс по стране
      { x: 0, y: 1, w: 4, h: 4.5, i: '1' },
      // Горячие показатели
      { x: 0, y: 2, w: 4, h: 8.5, i: '2' },
      // News
      { x: 0, y: 3, w: 4, h: 10.5, i: '3' },
      // поручения МПК
      { x: 0, y: 4, w: 6, h: 9, i: '5' },
      // Анализ СМИ
      { x: 0, y: 5, w: 4, h: 9, i: '6' },
      // Поручения загранаппарату
      { x: 0, y: 6, w: 6, h: 9.5, i: '7' },
      // проекты
      { x: 0, y: 7, w: 4, h: 9, i: '8' },
      // Уведомления
      { x: 0, y: 8, w: 4, h: 9, i: '9' },
      // Мои задачи
      { x: 0, y: 9, w: 6, h: 9, i: '10' },
      // Календарь
      { x: 0, y: 10, w: 6, h: 9.5, i: '11' },
    ],
  };
  let needResolution = '';
  export default {
    name: 'intefaceSelected',
    components: {
      WidgetStock,
      ButtonStock,
      SelectComponent,

      Widget1: defineAsyncComponent(() => import('../components/Widget1.vue')),
      Widget2: defineAsyncComponent(() => import('../components/Widget2.vue')),
      Widget3: defineAsyncComponent(() => import('../components/Widget3.vue')),
      Widget4: defineAsyncComponent(() => import('../components/Widget4.vue')),
      Widget5: defineAsyncComponent(() => import('../components/Widget5.vue')),
      Widget6: defineAsyncComponent(() => import('../components/Widget6.vue')),
      Widget7: defineAsyncComponent(() => import('../components/Widget7.vue')),
      Widget8: defineAsyncComponent(() => import('../components/Widget8.vue')),
      Widget9: defineAsyncComponent(() => import('../components/Widget9.vue')),
      Widget11: defineAsyncComponent(() => import('../components/Widget11.vue')),
      Widget12: defineAsyncComponent(() => import('../components/Widget12.vue')),
    },
    data() {
      return {
        layouts: testLayouts,
        layout: testLayouts['md'],
        id: this.$route.params.id,
        testSelected: this.$route.params.id,
        country: [{ id: 0, text: 'Выберите страну' }],
        //countrySelected: null,
      };
    },
    created() {
      Utils.loadSelectOptions('countryList', this.country);
      //this.loadPage();
      this.whatResolutionsClient();
    },
    computed: {
      countrySelected() {
        if (this.id != null && this.id != undefined) {
          var el = this.country.find((element) => element.id == this.id);
          return el ? el.text : '';
        }
        return '';
      },
    },
    methods: {
      // loadPage() {
      //   if (this.id != null && this.id != undefined) {
      //     this.countrySelected = this.country.filter(element => element.id == this.id);
      //   }
      // },
      jumpToSelectedCountry(id) {
        if (id > 0) this.$router.push({ name: 'intefaceSelected', params: { id: id } });
        else this.$router.push({ name: 'sekretar' });
      },
      gearMethod() {
        console.log('gear');
      },
      closeMethod() {
        console.log('close');
      },
      breakpointChangedEvent: function (newBreakpoint, newLayout) {
        console.log('BREAKPOINT CHANGED breakpoint=', newBreakpoint, ', layout: ', newLayout);
      },
      whatResolutionsClient() {
        if (window.innerWidth <= 508) {
          needResolution = 'xxs';
        } else {
          needResolution = 'md';
        }
        this.layout = testLayouts[needResolution];
      },
    },
  };
</script>

<style lang="scss">
  .widget-custom-in {
    &.interface {
      flex-direction: column;
      font-size: 14px;
      overflow-x: auto;
      max-height: 125px;
      padding: 20px;
      .title {
        font-weight: 500;
      }
      ul {
        margin-bottom: 0px;
        li {
          padding-left: 12px;
        }
      }
    }
    &.hotIndocators {
      flex-direction: column;
      padding: 4px 14px;
      overflow-x: auto;
      max-height: 445px;
      .select {
        max-width: 150px;
        margin-bottom: 16px;
      }
      .hotIndocators-list {
        display: flex;
        flex-wrap: wrap;
      }
      .hotIndocators-item {
        width: 50%;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin-bottom: 8px;
        .title {
          font-size: 14px;
        }
        .data {
          display: flex;
          align-items: center;
          .bigNumber {
            font-weight: 700;
            font-size: 30px;
          }
          .smallNumber {
            font-size: 12px;
          }
          &.positive {
            .icon {
              transform: rotate(180deg);
            }
            .bigNumber,
            .smallNumber span,
            .icon {
              color: #00ad11;
            }
          }
          &.negative {
            .bigNumber,
            .smallNumber span,
            .icon {
              color: #de0000;
            }
          }
        }
      }
      button {
        max-width: 204px;
        margin-left: auto;
      }
    }
  }
  @media (max-width: 375px) {
    .widget-custom-in {
      &.interface {
        max-height: 160px;
      }
      &.hotIndocators {
        overflow-x: initial;
        max-height: unset;
        .hotIndocators-list {
          flex-wrap: nowrap;
          flex-direction: column;
          overflow-y: auto;
          max-height: 335px;
          margin-bottom: 8px;
        }
        .hotIndocators-item {
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .widget-custom-in {
      &.hotIndocators {
        .hotIndocators-list {
          max-height: 305px;
        }
      }
    }
  }
</style>
