export function getListCurrentBalance(state) {
  return state.currentBalance;
}

export function getListFinancialBalance(state) {
  return state.financialBalance;
}

export function getLegendCurrentBalance(state) {
  return state.legendCurrentBalance;
}

export function getLegendFinancialBalance(state) {
  return state.legendFinancialBalance;
}

export function getInfoGraphCurrentBalance(state) {
  return state.infoGraphCurrentBalance;
}

export function getInfoGraphFinancialBalance(state) {
  return state.infoGraphFinancialBalance;
}

export function getInfoGraphReserveAssetBalance(state) {
  return state.infoReserveAssetBalance;
}

export function getInfoGraphPureErrorsAndOmissions(state) {
  return state.infoPureErrorsAndOmissions;
}

export function getLoadCurrentBalance(state) {
  return state.loadCurrentBalance;
}

export function getLoadFinancialBalance(state) {
  return state.loadFinancialBalance;
}

export function getLoadReserveAssetBalance(state) {
  return state.loadReserveAssetBalance;
}

export function getLoadPureErrorsAndOmissions(state) {
  return state.loadPureErrorsAndOmissions;
}

export function getYearList(state) {
  return state.yearList;
}
