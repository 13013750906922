<template>
  <div class="modal-import">
    <div class="form-group">
      <label class="control-label">Файл</label>
      <input
        type="file"
        id="file-input"
        class="form-control"
        :accept="accept"
        @change="fileChanged($event)"
        ref="inputFile"
      />
      <div class="invalid-msg" v-if="v$.file.$error">Поле обязательно для заполнения</div>
    </div>

    <slot />

    <div class="modal-import__button">
      <ButtonComponent @click="doImport" mod="gradient-bg" class="button-redesign--popup-measure"
        >Загрузить</ButtonComponent
      >
      <ButtonComponent @click="close" mod="transparent-bg" class="button-redesign--popup-measure"
        >Отменить</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';

  export default {
    name: 'ImportModal',
    components: { ButtonComponent },
    emits: ['doimport', 'close'],
    props: {
      acceptType: String,
    },
    data() {
      return {
        file: null,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
    },

    created() {
      if (this.acceptType) this.accept = this.acceptType;
    },
    methods: {
      fileChanged(e) {
        this.file = e.target.files[0];
      },
      doImport() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
        this.$emit('doimport', this.file);
      },
      close() {
        this.reset();
        this.$emit('close');
      },
      reset() {
        this.file = null;
        this.$refs.inputFile.value = '';
        this.v$.$reset();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        file: { required },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .modal-import {
    &__button {
      display: flex;
      gap: 8px;
    }
  }
</style>
