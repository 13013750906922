<template>
  <div class="container-page">
    <div class="activity-page edit">
      <tabs>
        <tab name="Личная информация" :selected="true">
          <div class="tabs-inner_container card">
            <div class="main-info card-info">
              <div class="row d-flex flex-column flex-md-row" v-if="item.employee">
                <div class="col col-lg-4 pb-0">
                  <PictureUpload v-on:pictureSelected="pictureSelected" :initialPhoto="item.employee.picture" />
                </div>
                <div class="col col-lg-8">
                  <div class="form-group required">
                    <ComponentInput
                      v-model="item.employee.surname"
                      label="Фамилия"
                      required
                      :maxLength="250"
                      placeholder="Введите фамилию"
                    />
                  </div>
                  <div class="form-group required">
                    <ComponentInput
                      v-model="item.employee.name"
                      label="Имя"
                      required
                      :maxLength="250"
                      placeholder="Введите имя"
                    />
                  </div>
                  <div class="form-group">
                    <ComponentInput
                      v-model="item.employee.patronymic"
                      label="Отчество"
                      :maxLength="250"
                      placeholder="Введите отчество"
                    />
                  </div>
                </div>
              </div>
              <div class="card-row d-flex flex-column flex-md-row" v-if="item.employee">
                <div class="form-group col col-lg-12">
                  <label class="form-label">Страна</label>
                  <p>{{ item.employee.countryNames }}</p>
                </div>
              </div>
              <div class="card-row d-flex flex-column flex-md-row" v-if="item.employee">
                <div class="form-group col col-lg-6">
                  <label class="form-label">Организация</label>
                  <p>
                    {{ item.employee.organizationName
                    }}<span v-if="item.employee.organizationId == 1">, {{ item.employee.departmentName }}</span>
                  </p>
                </div>
                <div class="form-group col col-lg-6">
                  <label class="form-label">Должность</label>
                  <p>{{ item.employee.position }}</p>
                </div>
              </div>
              <div class="row d-flex flex-column flex-md-row">
                <div class="form-group col col-lg-6">
                  <label class="form-label">Адрес электронной почты</label>
                  <p>{{ item.email }}</p>
                </div>
                <div class="form-group col col-lg-6">
                  <label class="form-label">Роль</label>
                  <p>{{ item.roleTitles }}</p>
                </div>
              </div>
              <!-- <div class="row d-flex flex-column flex-md-row">
                <div class="form-group col col-lg-6">
                  {{ item.newPassword }}
                  <ComponentInput
                    v-model="item.newPassword"
                    label="Новый пароль"
                    :maxLength="30"
                    placeholder="Введите пароль"
                  />
                </div>
                <div class="form-group col col-lg-6">
                  {{ item.confirmPassword }}
                  <ComponentInput
                    label="Подтверждение нового пароля"
                    :maxLength="30"
                    v-model="item.confirmPassword"
                    placeholder="Введите пароль"
                  />
                  <div class="invalid-msg" v-if="v$.item.confirmPassword.$error">Пароли должны совпадать</div>
                </div>
              </div>
              <div class="row d-flex flex-column flex-md-row">
                <div @click="onDownloadRequirements" class="file-download">Требования к паролю</div>
              </div> -->
              <div class="card-row d-flex flex-column flex-md-row">
                <div class="form-group col col-lg-6">
                  <label class="form-label">Последний логин</label>
                  <p>{{ $momentFormat(item?.lastLoginDt, 'DD.MM.YYYY HH:mm') || '(нет)' }}</p>
                </div>
                <div class="form-group col col-lg-6">
                  <label class="form-label">Последняя активность</label>
                  <p>{{ $momentFormat(item?.lastActivityDt, 'DD.MM.YYYY HH:mm') || '(нет)' }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="card-row btns">
            <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="cancel" />
            <ButtonStock title="Сохранить изменения" v-on:click="save" />
          </div>
        </tab>
        <tab name="Настройка виджетов">
          <div class="table-container mb-16 js-table-arrow" v-if="widgets && widgets.length > 0">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>Наименование</th>
                  <th>Отображается</th>
                  <th>Статус</th>
                  <th>Порядок</th>
                </tr>
              </thead>
              <draggable v-model="widgets" tag="tbody" item-key="wigetTypeId" handle=".handle">
                <template #item="{ element }">
                  <tr>
                    <td>{{ element.name }}</td>
                    <td>{{ element.viewWithoutCountry ? 'всегда' : 'по стране' }}</td>
                    <td>
                      <div class="checkboxInput">
                        <input type="checkbox" v-model="element.isActive" :id="'chActive' + element.widgetTypeId" />
                        <label :for="'chActive' + element.widgetTypeId">Отображать на главной</label>
                      </div>
                    </td>
                    <td>
                      <div class="handle icon">
                        <i class="fa fa-align-justify" title="Перетащить"></i>
                      </div>
                      <!-- {{ element.orderNum }} -->
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
          <div v-else>
            <div class="tabs-inner_container card">
              <div class="main-info card-info">
                <p>Не найдено доступных виджетов.</p>
              </div>
            </div>
          </div>

          <div class="card-row btns" v-if="widgets && widgets.length > 0">
            <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="cancelWidget" />
            <ButtonStock title="Сохранить изменения" v-on:click="saveWidget" />
          </div>
        </tab>
        <tab name="Настройка уведомлений">
          <div class="mb-16 card__notification-options">
            <ComponentInput label="Уведомлять по электронной почте" v-model="item.notifyByEmail" type="checkbox" />
            <ComponentInput label="Уведомлять push-сообщениями" v-model="item.notifyByPush" type="checkbox" />
            <ComponentInput
              label="Использовать системные уведомления"
              v-model="item.notifyBySystemMessages"
              type="checkbox"
            />
          </div>
          <div class="card-row btns">
            <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="cancel" />
            <ButtonStock title="Сохранить изменения" v-on:click="save" />
          </div>
        </tab>
        <tab name="Общие настройки" v-if="$store.state.auth?.oidc?.profile?.roleIds.includes(1)">
          <div class="mb-16 card__notification-options">
            <ComponentInput
              label="Показывать кнопки для сохранения и загрузки фильтров"
              v-model="item.showFilters"
              type="checkbox"
            />
          </div>
          <div class="card-row btns">
            <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="cancel" />
            <ButtonStock title="Сохранить изменения" v-on:click="save" />
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import PictureUpload from '@/common/components/PictureUpload';
  import tab from '@/common/components/tabsDetailInner.vue';
  //import { sameAs } from '@vuelidate/validators'
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';

  // import Utils from '@/common/utils';
  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/user';

  export default {
    name: 'SettingsPage',
    components: {
      ComponentInput,
      PictureUpload,
      ButtonStock,
      tabs,
      tab,
      draggable,
    },
    data() {
      return {
        item: {},
        widgets: [],
      };
    },
    created() {
      this.loadUserData();
      this.loadWidgets();
      // var profile = this.$store.state.auth?.oidc?.profile;
      // if(profile == null)
      //   profile = JSON.parse(localStorage.getItem('userProfileData'));

      // this.item = profile;
      //   console.log(profile);
    },
    methods: {
      loadUserData() {
        API.getUser().then((response) => {
          this.item = response.data;
          this.item.newPassword = '';
          this.item.confirmPassword = '';
        });
      },
      loadWidgets() {
        API.loadWidgets().then((response) => {
          this.widgets = response.data;
        });
      },
      cancelWidget() {
        this.loadWidgets();
      },
      cancel() {
        this.loadUserData();
      },
      pictureSelected(data) {
        this.item.employee.picture = data;
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = {
          id: this.item.id,
          uuid: this.item.uuid,
          password: this.item.newPassword,
          notifyByEmail: this.item.notifyByEmail,
          notifyByPush: this.item.notifyByPush,
          notifyBySystemMessages: this.item.notifyBySystemMessages,
          showFilters: this.item.showFilters,
        };
        if (this.item.employee) {
          itemToSave.employee = {
            picture: this.item.employee.picture,
            name: this.item.employee.name,
            surname: this.item.employee.surname,
            patronymic: this.item.employee.patronymic,
          };
        }

        API.updateUser(itemToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data) {
              this.item = response.data;
              this.$store.commit('auth/oidc/setShowFilters', this.item.showFilters);
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      saveWidget() {
        var widgetsToSave = [];
        this.widgets.forEach((w, index) => {
          widgetsToSave.push({
            widgetTypeId: w.widgetTypeId,
            isActive: w.isActive,
            orderNum: index,
          });
        });
        //console.log(widgetsToSave);

        API.saveWidgets(widgetsToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data) {
              this.widgets = response.data;
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      // onDownloadRequirements() {
      //   return Utils.downloadFile('/api/staticfile/getpasswordrequirements', 'Trebovaniya_k_parolyu.docx', null);
      // },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          newPassword: {},
          confirmPassword: {
            /*sameAsPassword: sameAs('newPassword')*/
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .btns button {
    margin-right: 1rem;
  }
  .table-container table {
    min-width: auto;
    width: 100%;
  }
  .handle {
    font-size: 20px;
  }
  .file-download {
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .card__notification-options::v-deep .input-group-wrapper {
    margin-bottom: 10px;
  }
  .card__notification-options::v-deep .form-check-label {
    margin-left: 10px;
  }

  .card__notification-options::v-deep input[type='checkbox'] {
    position: relative;
    width: 20px;
    height: 20px;
    left: 0;
    top: 3px;
    margin: 0 auto;
  }
</style>
