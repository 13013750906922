<template>
  <div class="home container-page">
    <smart-widget-grid
      :resizable="false"
      :responsive="true"
      v-model:layout.sync="layout"
      :row-height="48"
      :margin="[20, 20]"
      :responsive-layouts="layouts"
    >
      <!-- Профили МПК -->
      <template #1>
        <WidgetStock title="Профили МПК:" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="widget-custom-in interface">
            <p class="title">Межправительственная коммисия по международному сотрудничеству</p>
            <ul>
              <li>Рабочая группа по инвестициям</li>
              <li>Рабочая группа по промышленности (Минпромторг)</li>
            </ul>
          </div>
        </WidgetStock>
      </template>

      <!-- Горячие показатели -->
      <template #2>
        <WidgetStock title="Горячие показатели" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="widget-custom-in hotIndocators">
            <div class="select">
              <SelectComponent placeholder="Период" :options="yearsHotIndicators" />
            </div>
            <ul class="hotIndocators-list">
              <li class="hotIndocators-item">
                <p class="title">Экспорт</p>
                <div class="data positive">
                  <IconComponent name="arrowIndicator" />
                  <p class="bigNumber">2,5843</p>
                  <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
                </div>
              </li>
              <li class="hotIndocators-item">
                <p class="title">Импорт</p>
                <div class="data negative">
                  <IconComponent name="arrowIndicator" />
                  <p class="bigNumber">1,4452</p>
                  <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
                </div>
              </li>
              <li class="hotIndocators-item">
                <p class="title">Товарооборот</p>
                <div class="data positive">
                  <IconComponent name="arrowIndicator" />
                  <p class="bigNumber">12,584</p>
                  <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
                </div>
              </li>
              <li class="hotIndocators-item">
                <p class="title">Сальдо</p>
                <div class="data negative">
                  <IconComponent name="arrowIndicator" />
                  <p class="bigNumber">0,4583</p>
                  <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
                </div>
              </li>
              <li class="hotIndocators-item">
                <p class="title">Инвестиции поступившие</p>
                <div class="data negative">
                  <IconComponent name="arrowIndicator" />
                  <p class="bigNumber">5,5483</p>
                  <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
                </div>
              </li>
              <li class="hotIndocators-item">
                <p class="title">Инвестиции накопленные</p>
                <div class="data positive">
                  <IconComponent name="arrowIndicator" />
                  <p class="bigNumber">1,8954</p>
                  <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
                </div>
              </li>
            </ul>
            <ButtonStock title="Страновая аналитика" />
          </div>
        </WidgetStock>
      </template>

      <!-- Новости -->
      <template #3>
        <WidgetStock title="Дайджест СМИ" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="widget-custom-in news-component">
            <newsInner :newses="arrayNews" />
          </div>
        </WidgetStock>
      </template>

      <!-- Поручения загранаппарату -->
      <template #7>
        <WidgetStock title="Поручения загранаппарату" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="table-component stock table-vidjet">
            <div class="table-component__header d-flex">
              <ButtonStock class="ml-auto" title="Новое поручение" />
            </div>
            <div class="table-vidjet">
              <table>
                <thead>
                  <colgroup width="45%"></colgroup>
                  <colgroup width="15%"></colgroup>
                  <colgroup width="10%"></colgroup>
                  <colgroup width="25%"></colgroup>
                  <tr>
                    <th>Содержание</th>
                    <th>Срок</th>
                    <th>Статус</th>
                    <th>Отчет</th>
                  </tr>
                </thead>
                <tbody class="mh315">
                  <tr v-for="order in ordersArray" :key="order.id">
                    <td width="45%" :title="order.about">
                      {{ order.about }}
                    </td>
                    <td width="15%" :title="order.time">
                      {{ order.time }}
                    </td>
                    <td width="10%">
                      <IconComponent v-if="order.status == 1" name="attentionSigh" />
                      <IconComponent v-else-if="order.status == 2" name="banSigh" />
                      <IconComponent v-else-if="order.status == 3" name="goodSigh" />
                      <IconComponent v-else-if="order.status == 4" name="refreshSigh" />
                    </td>
                    <td width="25%">
                      <router-link :to="order.report" v-if="order.report != null || order.report != undefined">
                        <ButtonStock btnType="violet" title="Просмотреть" />
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-component__footer">
              <ButtonStock title="Все поручения" />
            </div>
          </div>
        </WidgetStock>
      </template>

      <!-- проекты -->
      <template #8>
        <WidgetStock title="проекты" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="table-component myprojects stock">
            <div class="table-component__header">
              <SelectComponent class="mr-6" />
              <ButtonStock title="Выбрать" />
            </div>
            <div class="table-vidjet">
              <table>
                <thead>
                  <colgroup width="30%"></colgroup>
                  <colgroup width="20%"></colgroup>
                  <colgroup width="20%"></colgroup>
                  <colgroup width="20%"></colgroup>
                  <tr>
                    <th>Название</th>
                    <th>Отрасль</th>
                    <th>Инициатор</th>
                    <th>Статус ЖЦ</th>
                  </tr>
                </thead>
                <tbody class="mh280">
                  <tr v-for="project in projectsArray" :key="project.id">
                    <td width="30%" :title="project.about">
                      {{ project.about }}
                    </td>
                    <td width="20%" :title="project.industry">
                      {{ project.industry }}
                    </td>
                    <td width="20%" :title="project.executor">
                      {{ project.executor }}
                    </td>
                    <td width="10%">
                      <template v-if="project.status == 1">
                        <IconComponent name="goodSigh" />
                      </template>
                      <template v-else-if="project.status == 0">
                        <IconComponent name="grayGoodSigh" />
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-component__footer">
              <ButtonStock title="Все проекты" />
            </div>
          </div>
        </WidgetStock>
      </template>

      <!-- Уведомления -->
      <template #9>
        <WidgetStock title="Уведомления" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <tableNotif :optionsThead="optionsThead" :options="notifArray" />
        </WidgetStock>
      </template>

      <!-- Календарь -->
      <template #11>
        <WidgetStock title="Календарь" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <calendarInner :events="calendar" />
        </WidgetStock>
      </template>
    </smart-widget-grid>
  </div>
</template>

<script>
  // Additional
  import SelectComponent from '@/common/components/selectComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import calendarInner from '@/components/widgets/inners/calendarInner.vue';
  // Inners
  import newsInner from '@/components/widgets/inners/newsInner.vue';
  import tableNotif from '@/components/widgets/inners/tables/tableNotif.vue';
  import WidgetStock from '@/components/widgets/WidgetStock.vue';

  let testLayouts = {
    md: [
      // "i" = id элемента template
      // Профили МПК
      { x: 0, y: 0, w: 6, h: 3.5, i: '1' },
      // Горячие показатели
      { x: 0, y: 1, w: 6, h: 6, i: '2' },
      // News
      { x: 6, y: 0, w: 4, h: 9, i: '3' },
      // Поручения загранаппарату
      { x: 0, y: 3, w: 6, h: 9, i: '7' },
      // проекты
      { x: 6, y: 3, w: 4, h: 9, i: '8' },
      // Уведомления
      { x: 0, y: 4, w: 4, h: 9, i: '9' },
      // Календарь
      { x: 4, y: 4, w: 6, h: 9, i: '11' },
    ],
    xxs: [
      // Профили МПК
      { x: 0, y: 1, w: 4, h: 4.5, i: '1' },
      // Горячие показатели
      { x: 0, y: 2, w: 4, h: 8.5, i: '2' },
      // News
      { x: 0, y: 3, w: 4, h: 10.5, i: '3' },
      // Поручения загранаппарату
      { x: 0, y: 6, w: 6, h: 9.5, i: '7' },
      // проекты
      { x: 0, y: 7, w: 4, h: 9, i: '8' },
      // Уведомления
      { x: 0, y: 8, w: 4, h: 9, i: '9' },
      // Календарь
      { x: 0, y: 10, w: 6, h: 9.5, i: '11' },
    ],
  };
  let needResolution = '';

  export default {
    name: 'zagran',
    components: {
      WidgetStock,
      ButtonStock,
      newsInner,
      tableNotif,
      calendarInner,
      IconComponent,
      SelectComponent,
    },
    methods: {
      gearMethod() {
        console.log('gear');
      },
      closeMethod() {
        console.log('close');
      },
      whatResolutionsClient() {
        if (window.innerWidth <= 508) {
          needResolution = 'xxs';
        } else {
          needResolution = 'md';
        }
        this.layout = testLayouts[needResolution];
      },
    },
    data() {
      return {
        layouts: testLayouts,
        layout: testLayouts['md'],
        arrayNews: [
          {
            id: 1,
            img: '~@/assets/img/news-image.png',
            title: 'Максим Решетников: Мы приветствуем дальнейшее развитие и реинвестирование',
            date: '21/12/21',
            about:
              '21 декабря в Париже состоялось XXVI заседание Российско-Французского совета по экономическим,финансовым, промышленным и торговым вопросам (СЕФИК) 21 декабря в Париже состоялось XXVI заседание Российско-Французского совета по экономическим, финансовым, промышленным',
          },
          {
            id: 2,
            img: '~@/assets/images/news-image.png',
            title: 'Максим Решетников: Мы приветствуем',
            date: '22/12/21',
            about: '22 декабря в Париже состоялось заседание',
          },
          {
            id: 3,
            img: '~@/assets/images/news-image.png',
            title: 'Максим: Мы ...',
            date: '23/12/21',
            about: '23 декабря в Париже не состоялось заседание',
          },
        ],
        calendar: [
          { text: 'Все', id: '1' },
          { text: 'МПК по торгово-экономическому', id: '2' },
          { text: 'Рабочая группа по инвестициям', id: '3' },
          { text: 'Деловой совет', id: '4' },
          { text: 'Форум', id: '5' },
        ],
        notifArray: [
          {
            id: '1',
            name: 'Обновленная таможенная статистика',
            date: '21.01.22',
            status: 1,
          },
          { id: '2', name: 'Просрочено поручение', date: '22.01.22', status: 2 },
          {
            id: '3',
            name: 'Поступил ответ на запрос',
            date: '23.01.22',
            status: 3,
          },
          {
            id: '4',
            name: 'Обновленная таможенная статистика',
            date: '24.01.22',
            status: 4,
          },
          {
            id: '5',
            name: 'Обновленная таможенная статистика',
            date: '25.01.22',
            status: 1,
          },
          {
            id: '6',
            name: 'Обновленная таможенная статистика',
            date: '21.01.22',
            status: 1,
          },
          { id: '7', name: 'Просрочено поручение', date: '22.01.22', status: 2 },
          {
            id: '8',
            name: 'Поступил ответ на запрос',
            date: '23.01.22',
            status: 3,
          },
          {
            id: '9',
            name: 'Обновленная таможенная статистика',
            date: '24.01.22',
            status: 4,
          },
          {
            id: '10',
            name: 'Обновленная таможенная статистика',
            date: '25.01.22',
            status: 1,
          },
          {
            id: '11',
            name: 'Обновленная таможенная статистика',
            date: '21.01.22',
            status: 1,
          },
          { id: '12', name: 'Просрочено поручение', date: '22.01.22', status: 2 },
          {
            id: '13',
            name: 'Поступил ответ на запрос',
            date: '23.01.22',
            status: 3,
          },
          {
            id: '14',
            name: 'Обновленная таможенная статистика',
            date: '24.01.22',
            status: 4,
          },
          {
            id: '15',
            name: 'Обновленная таможенная статистика',
            date: '25.01.22',
            status: 1,
          },
          {
            id: '16',
            name: 'Обновленная таможенная статистика',
            date: '21.01.22',
            status: 1,
          },
          { id: '17', name: 'Просрочено поручение', date: '22.01.22', status: 2 },
          {
            id: '18',
            name: 'Поступил ответ на запрос',
            date: '23.01.22',
            status: 3,
          },
          {
            id: '19',
            name: 'Обновленная таможенная статистика',
            date: '24.01.22',
            status: 4,
          },
          {
            id: '20',
            name: 'Обновленная таможенная статистика',
            date: '25.01.22',
            status: 1,
          },
        ],
        optionsThead: [
          { title: 'Содержание', id: 1 },
          { title: 'Дата', id: 2 },
          { title: 'Статус', id: 3 },
        ],
        ordersArray: [
          {
            about: 'Подготовить предложения по...',
            time: '21.01.22',
            executor: 'Минэкономразвития',
            status: 1,
            report: '/#/report-1',
          },
          {
            about: 'Подготовить предложения по подготовке предл...',
            time: '21.01.22',
            executor: 'Минфин',
            status: 3,
            report: '/#/report-1',
          },
          {
            about: 'Провести переговоры по проведению перегово...',
            time: '21.01.22',
            executor: 'Минпромторг',
            status: 4,
            report: '/#/report-1',
          },
          {
            about: 'Подготовить предложения по подготовке предл...',
            time: '21.01.22',
            executor: 'Минобрнауки',
            status: 2,
            report: '/#/report-1',
          },
          {
            about: 'Подготовить предложения по подготовке предл...',
            time: '21.01.22',
            executor: 'Минэкономразвития',
            status: 1,
            report: '/#/report-1',
          },
          {
            about: 'Проработать вопрос по проработке вопросов',
            time: '21.01.22',
            executor: 'Росатом',
            status: 3,
            report: '/#/report-1',
          },
          {
            about: 'Подготовить предложения по...',
            time: '21.01.22',
            executor: 'Минэкономразвития',
            status: 4,
            report: '/#/report-1',
          },
        ],
        projectsArray: [
          {
            about: 'Строительство завода',
            industry: 'Машиностроение',
            executor: 'Минэкономразвития',
            status: 0,
          },
          {
            about: 'Поставка удобрений',
            industry: 'Игровая',
            executor: 'Минэкономразвития',
            status: 1,
          },
          {
            about: 'Поставка удобрений',
            industry: 'Мусорная',
            executor: 'Минэкономразвития',
            status: 0,
          },
          {
            about: 'Строительство завода',
            industry: 'Ядерная',
            executor: 'Минэкономразвития',
            status: 1,
          },
          {
            about: 'Строительство завода',
            industry: 'Шаумерчная',
            executor: 'Минэкономразвития',
            status: 0,
          },
          {
            about: 'Строительство завода',
            industry: 'Химическая',
            executor: 'Минэкономразвития',
            status: 1,
          },
          {
            about: 'Строительство завода',
            industry: 'Бургерная',
            executor: 'Минэкономразвития',
            status: 0,
          },
          {
            about: 'Строительство завода',
            industry: 'Пиццевая',
            executor: 'Минэкономразвития',
            status: 1,
          },
          {
            about: 'Строительство завода',
            industry: 'Картофельная',
            executor: 'Минэкономразвития',
            status: 0,
          },
        ],
        yearsHotIndicators: [
          { text: '2021', valve: 1 },
          { text: '2022', valve: 2 },
          { text: '2023', valve: 3 },
          { text: '2024', valve: 4 },
        ],
      };
    },
    created() {
      this.whatResolutionsClient();
    },
  };
</script>

<style lang="scss" scoped></style>
