import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    countries: [],
    groupsCountries: [],
    typeOfTradingOperation: [],
    transport: [],
    typeImport: [],
    processType: [],
    procedure: [],
    operations: [],
    nsiCountryList: [],
    nsiCountryListWithData: [],
    nsiMeasureOrganProtectedMarket: [],
    nsiIndustry: [],
    moveDirections: [],
    countryCoverages: [],
    measureTypes: [],
    ttrStatus: [],
    investigationType: [],
    regions: [],
    partnerCountry: [],
    regionsSearchId: [],
    partnerCountrySearchId: [],
    federalDistrictList: [],
  },
};

export const DIRECTIVES = 'directives';

export const DIRECTIVES_ACTIONS_NAME = {
  countriesGet: `${DIRECTIVES}/getCountries`,
  getGroupsCountries: `${DIRECTIVES}/getGroupsCountries`,
  getTypeOfTradingOperation: `${DIRECTIVES}/getTypeOfTradingOperation`,
  getTransport: `${DIRECTIVES}/getTransport`,
  getTypeImport: `${DIRECTIVES}/getTypeImport`,
  getProcessType: `${DIRECTIVES}/getProcessType`,
  getProcedure: `${DIRECTIVES}/getProcedure`,
  getOperations: `${DIRECTIVES}/getOperations`,
  getNsiCountry: `${DIRECTIVES}/getNsiCountry`,
  getNsiCountryWithData: `${DIRECTIVES}/getNsiCountryWithData`,
  getNSIMeasureOrganProtectedMarket: `${DIRECTIVES}/getNSIMeasureOrganProtectedMarket`,
  getNSIIndustry: `${DIRECTIVES}/getNSIIndustry`,
  getNSIMoveDirections: `${DIRECTIVES}/getNSIMoveDirections`,
  getNSICountryCoverages: `${DIRECTIVES}/getNSICountryCoverages`,
  getNSIMeasureTypes: `${DIRECTIVES}/getNSIMeasureTypes`,
  getNSITtrStatus: `${DIRECTIVES}/getNSITtrStatus`,
  getNSIInvestigationType: `${DIRECTIVES}/getNSIInvestigationType`,
  getRegions: `${DIRECTIVES}/getRegions`,
  getPartnerCountry: `${DIRECTIVES}/getPartnerCountry`,
  getRegionsSearchId: `${DIRECTIVES}/getRegionsSearchId`,
  getPartnerCountrySearchId: `${DIRECTIVES}/getPartnerCountrySearchId`,
  getFederalDistricts: `${DIRECTIVES}/getFederalDistricts`,
};

export const DIRECTIVES_MUTATIONS_NAME = {
  setCountries: 'setCountries',
  setGroupsCountries: 'setGroupsCountries',
  setTypeOfTradingOperation: 'setTypeOfTradingOperation',
  setTransport: 'setTransport',
  setTypeImport: 'setTypeImport',
  setProcessType: 'setProcessType',
  setProcedure: 'setProcedure',
  setOperations: 'setOperations',
  setNsiCountry: 'setNsiCountry',
  setNsiCountryWithData: 'setNsiCountryWithData',
  setMeasureOrganProtectedMarket: 'setMeasureOrganProtectedMarket',
  setIndustry: 'setIndustry',
  setMoveDirections: 'setMoveDirections',
  setCountryCoverages: 'setCountryCoverages',
  setMeasureTypes: 'setMeasureTypes',
  setTtrStatus: 'setTtrStatus',
  setInvestigationType: 'setInvestigationType',
  setRegions: 'setRegions',
  setPartnerCountry: 'setPartnerCountry',
  setRegionsSearchId: 'setRegionsSearchId',
  setPartnerCountrySearchId: 'setPartnerCountrySearchId',
  setFederalDistricts: 'setFederalDistricts',
};

export const DIRECTIVES_GETTERS_NAME = {
  getCountriesSelect: `${DIRECTIVES}/getCountriesSelect`,
  getCountriesMultiSelect: `${DIRECTIVES}/getCountriesMultiSelect`,
  getGroupsCountriesMultiSelect: `${DIRECTIVES}/getGroupsCountriesMultiSelect`,
  getTypeOfTradingOperationList: `${DIRECTIVES}/getTypeOfTradingOperationList`,
  getTypeOfTradingOperationMultiSelect: `${DIRECTIVES}/getTypeOfTradingOperationMultiSelect`,
  getTransportList: `${DIRECTIVES}/getTransportList`,
  getTypeImportSelect: `${DIRECTIVES}/getTypeImportSelect`,
  getTypeImportMultiSelect: `${DIRECTIVES}/getTypeImportMultiSelect`,
  getTypeProcess: `${DIRECTIVES}/getTypeProcess`,
  getProcedureList: `${DIRECTIVES}/getProcedureList`,
  getOperationsList: `${DIRECTIVES}/getOperationsList`,
  getNsiCountryList: `${DIRECTIVES}/getNsiCountryList`,
  getNsiCountryListWithData: `${DIRECTIVES}/getNsiCountryListWithData`,
  getCountryNoRussia: `${DIRECTIVES}/getCountryNoRussia`,
  getNSIRequest: `${DIRECTIVES}/getNSIRequest`,
  getNSIMeasureOrganProtectedMarketList: `${DIRECTIVES}/getNSIMeasureOrganProtectedMarketList`,
  getNSIIndustryList: `${DIRECTIVES}/getNSIIndustryList`,
  getNSIMoveDirectionsList: `${DIRECTIVES}/getNSIMoveDirectionsList`,
  getNSICountryCoveragesList: `${DIRECTIVES}/getNSICountryCoveragesList`,
  getNSIMeasureTypesList: `${DIRECTIVES}/getNSIMeasureTypesList`,
  getTtrStatusGroupOptions: `${DIRECTIVES}/getTtrStatusGroupOptions`,
  getNSIInvestigationTypeList: `${DIRECTIVES}/getNSIInvestigationTypeList`,
  getFederalDistrictList: `${DIRECTIVES}/getFederalDistrictList`,
  getRegionsList: `${DIRECTIVES}/getRegionsList`,
  getRegionsListWithId: `${DIRECTIVES}/getRegionsListWithId`,
  getPartnerCountryList: `${DIRECTIVES}/getPartnerCountryList`,
  getRegionsSearchIdList: `${DIRECTIVES}/getRegionsSearchIdList`,
  getPartnerCountrySearchIdList: `${DIRECTIVES}/getPartnerCountrySearchIdList`,
};
