<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Департаменты</p>

      <div class="mb-16">
        <ButtonStock title="Создать" @click="editCard(0)" />
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Краткое наименование</th>
              <th>Полное наименование</th>
              <th>Статус</th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th class="filter" colspan="2">
                <FilterInput v-model="filter.name" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
              </th>
              <th class="filter">
                <FilterActiveInput
                  v-model="filter.isActive"
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ item.shotName }}</td>
              <td>{{ item.name }}</td>
              <td>
                {{ item.isActive ? 'действ.' : 'не действ.' }}
              </td>
              <td>
                <IconComponent @click="editCard(item.id)" name="mode" />
              </td>
              <td>
                <IconComponent @click="deleteCard(item.id)" name="delete" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="5">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import FilterActiveInput from '../../../common/components/FilterActiveInput';
  import FilterInput from '../../../common/components/FilterInput';
  import API from '../api/department';

  export default {
    name: 'Departments',
    components: {
      FilterActiveInput,
      FilterInput,
      ButtonStock,
      IconComponent,
      DefaultFilter,
    },
    mixins: [filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          name: null,
          isActive: null,
        },
        page: {},
        isLoading: false,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage();
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);
        API.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      editCard(id) {
        this.$router.push({ name: 'DepartmentCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
</style>
