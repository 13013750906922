<template>
  <div class="International-trade internationalTradeEconRev mb-32" :class="{ notForTecRussiaTab: notForTecRussiaTab }">
    <div class="International-trade__switchblock d-flex justify-content-between align-items-baseline gap-4 mb-12 mr-42">
      <p class="economicsTypeTitle mb-0" id="tec-world">Торгово-экономическое сотрудничество с миром</p>

      <Select2 class="select2" :options="yearList" v-model="year" placeholder="Период" @select="loadData" />
      <p class="fs14 mr-6 gray">Источник: Международный торговый центр</p>

      <SwitchComponent @switchHandler="switchHand($event)" />
    </div>

    <ul class="indicators-new__list d-flex">
      <li
        class="indicators-new__item"
        @click="turnoverMoreInfo ? selectTab(1, isProductMode ? 'Товарооборот' : 'Оборот услуг') : null"
        :class="[{ active: tabId === 1 }, { 'more-info-hidden': !turnoverMoreInfo }]"
      >
        <div class="indicators-new__wrapper flex-column">
          <p class="indicators-new__title">
            {{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}
          </p>
          <div>
            <div class="d-flex align-items-center">
              <strong class="indicators-new__count d-flex align-items-center" v-if="currentTurnoverSummaryAvailable">
                {{
                  formatNumber(currentTurnoverSummary.totalValue / 1000, 0) == '0,00'
                    ? '-'
                    : formatNumber(currentTurnoverSummary.totalValue / 1000, 0)
                }}
                <span class="d-block ml-4 currency">млн $</span>
              </strong>

              <strong class="indicators-new__count" v-else-if="currentSummary">
                {{
                  formatNumber(currentSummary.totalValue / 1000) == '0,00'
                    ? '-'
                    : formatNumber(currentSummary.totalValue / 1000, 0)
                }}
                <span class="d-block ml-4 currency">млн $</span>
              </strong>
              <strong v-else>-</strong>
            </div>
            <div class="d-flex mt-2 fs12">
              <div>
                <i
                  v-if="currentTurnoverSummaryAvailable"
                  class="fas fa-solid mr-1"
                  :class="[
                    {
                      'text-red back-red': currentTurnoverSummary?.totalValuePercent < 0,
                    },
                    {
                      'text-red fa-arrow-circle-down': currentTurnoverSummary?.totalValuePercent < 0,
                    },
                    {
                      'text-green fa-arrow-circle-up': currentTurnoverSummary?.totalValuePercent > 0,
                    },
                    {
                      'text-green': currentTurnoverSummary?.totalValuePercent > 0,
                    },
                  ]"
                ></i>
                <i
                  v-else
                  class="fas fa-solid mr-1"
                  :class="[
                    { 'text-red circle': isProductMode },
                    { 'text-red fa-arrow-circle-down': isProductMode },
                    { 'text-green fa-arrow-circle-up': !isProductMode },
                    { 'text-green circle': !isProductMode },
                  ]"
                ></i>
              </div>
              <span
                v-if="currentTurnoverSummaryAvailable && currentTurnoverSummary?.totalValuePercent"
                :class="[
                  { 'text-red': currentTurnoverSummary?.totalValuePercent < 0 },
                  {
                    'text-green': currentTurnoverSummary?.totalValuePercent > 0,
                  },
                ]"
              >
                {{ formatNumber(currentTurnoverSummary?.totalValuePercent) }}%
                <span class="text-black" style="display: none">г/г</span>
              </span>
              <span
                v-else-if="currentTurnoverSummaryAvailable == false || currentTurnoverSummary?.totalValuePercent == 0"
                class="text-red"
              >
                -
              </span>
            </div>
          </div>
        </div>
      </li>
      <li
        class="indicators-new__item"
        @click="exportMoreInfo ? selectTab(2, 'Экспорт') : null"
        :class="[{ active: tabId === 2 }, { 'more-info-hidden': !exportMoreInfo }]"
      >
        <div class="indicators-new__wrapper flex-column">
          <p class="indicators-new__title">Экспорт</p>
          <div>
            <div class="d-flex align-items-center">
              <strong class="indicators-new__count d-flex align-items-center" v-if="currentTurnoverSummaryAvailable">
                {{
                  formatNumber(currentTurnoverSummary.exportValue / 1000, 0) == '0,00'
                    ? '-'
                    : formatNumber(currentTurnoverSummary.exportValue / 1000, 0)
                }}
                <span class="d-block ml-4 currency">млн $</span>
              </strong>

              <strong class="indicators-new__count" v-else-if="currentSummary">
                {{
                  formatNumber(currentSummary.exportValue / 1000) == '0,00'
                    ? '-'
                    : formatNumber(currentSummary.exportValue / 1000, 0)
                }}
                <span class="d-block ml-4 currency">млн $</span>
              </strong>
              <strong v-else>-</strong>
            </div>
            <div class="d-flex mt-2 fs12">
              <div>
                <i
                  v-if="currentTurnoverSummaryAvailable"
                  class="fas fa-solid mr-1"
                  :class="[
                    {
                      'text-red back-red': currentTurnoverSummary?.exportValuePercent < 0,
                    },
                    {
                      'text-red fa-arrow-circle-down': currentTurnoverSummary?.exportValuePercent < 0,
                    },
                    {
                      'text-green fa-arrow-circle-up': currentTurnoverSummary?.exportValuePercent > 0,
                    },
                    {
                      'text-green': currentTurnoverSummary?.exportValuePercent > 0,
                    },
                  ]"
                ></i>
                <i
                  v-else
                  class="fas fa-solid mr-1"
                  :class="[
                    { 'text-red circle': isProductMode },
                    { 'text-red fa-arrow-circle-down': isProductMode },
                    { 'text-green fa-arrow-circle-up': !isProductMode },
                    { 'text-green circle': !isProductMode },
                  ]"
                ></i>
              </div>
              <span
                v-if="currentTurnoverSummaryAvailable && currentTurnoverSummary?.exportValuePercent"
                :class="[
                  { 'text-red': currentTurnoverSummary?.exportValuePercent < 0 },
                  {
                    'text-green': currentTurnoverSummary?.exportValuePercent > 0,
                  },
                ]"
              >
                {{ formatNumber(currentTurnoverSummary?.exportValuePercent) }}%
                <span class="text-black" style="display: none">г/г</span>
              </span>
              <span
                v-else-if="currentTurnoverSummaryAvailable == false || currentTurnoverSummary?.exportValuePercent == 0"
                class="text-red"
              >
                -
              </span>
            </div>
          </div>
        </div>
      </li>
      <li
        class="indicators-new__item"
        @click="turnoverMoreInfo ? selectTab(3, 'Импорт') : null"
        :class="[{ active: tabId === 3 }, { 'more-info-hidden': !importMoreInfo }]"
      >
        <div class="indicators-new__wrapper flex-column">
          <p class="indicators-new__title">Импорт</p>
          <div>
            <div class="d-flex align-items-center">
              <strong class="indicators-new__count d-flex align-items-center" v-if="currentTurnoverSummaryAvailable">
                {{
                  formatNumber(currentTurnoverSummary.importValue / 1000, 0) == '0,00'
                    ? '-'
                    : formatNumber(currentTurnoverSummary.importValue / 1000, 0)
                }}
                <span class="d-block ml-4 currency">млн $</span>
              </strong>

              <strong class="indicators-new__count" v-else-if="currentSummary">
                {{
                  formatNumber(currentSummary.importValue / 1000) == '0,00'
                    ? '-'
                    : formatNumber(currentSummary.importValue / 1000, 0)
                }}
                <span class="d-block ml-4 currency">млн $</span>
              </strong>
              <strong v-else>-</strong>
            </div>
            <div class="d-flex mt-2 fs12">
              <div>
                <i
                  v-if="currentTurnoverSummaryAvailable"
                  class="fas fa-solid mr-1"
                  :class="[
                    {
                      'text-red back-red': currentTurnoverSummary?.importValuePercent < 0,
                    },
                    {
                      'text-red fa-arrow-circle-down': currentTurnoverSummary?.importValuePercent < 0,
                    },
                    {
                      'text-green fa-arrow-circle-up': currentTurnoverSummary?.importValuePercent > 0,
                    },
                    {
                      'text-green': currentTurnoverSummary?.importValuePercent > 0,
                    },
                  ]"
                ></i>
                <i
                  v-else
                  class="fas fa-solid mr-1"
                  :class="[
                    { 'text-red circle': isProductMode },
                    { 'text-red fa-arrow-circle-down': isProductMode },
                    { 'text-green fa-arrow-circle-up': !isProductMode },
                    { 'text-green circle': !isProductMode },
                  ]"
                ></i>
              </div>
              <span
                v-if="currentTurnoverSummaryAvailable && currentTurnoverSummary?.importValuePercent"
                :class="[
                  { 'text-red': currentTurnoverSummary?.importValuePercent < 0 },
                  {
                    'text-green': currentTurnoverSummary?.importValuePercent > 0,
                  },
                ]"
              >
                {{ formatNumber(currentTurnoverSummary?.importValuePercent) }}%
                <span class="text-black" style="display: none">г/г</span>
              </span>
              <span
                v-else-if="currentTurnoverSummaryAvailable == false || currentTurnoverSummary?.importValuePercent == 0"
                class="text-red"
              >
                -
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="tabId === 1 || tabId === 2 || tabId === 3">
      <h2 class="mt-5">
        <template v-if="isProductMode">Торговля товарами</template>
        <template v-else>Торговля услугами</template>
        , млн $ США
      </h2>
      <LineChartThree
        v-if="!isLoading && isProductMode"
        format-label=" млн $"
        :chart-data="totalTnvedValueByYears"
        :chart-data-second="exportTnvedValueByYears"
        :chart-data-three="importTnvedValueByYears"
        background-color-three="#2F84EB"
        point-color-three="#2F84EB"
        background-color="#01A39D"
        point-color="#01A39D"
        background-color-two="#787F8F"
        point-color-two="#787F8F"
        border-color="#01A39D"
        border-color-two="#787F8F"
        border-color-three="#2F84EB"
        :labels="chartYearList"
        :displayDataLabels="false"
      />
      <LineChartThree
        v-if="!isLoading && !isProductMode"
        format-label=" млн $"
        :chart-data="totalOkvedValueByYears"
        :chart-data-second="exportOkvedValueByYears"
        :chart-data-three="importOkvedValueByYears"
        background-color-three="#2F84EB"
        point-color-three="#2F84EB"
        background-color="#01A39D"
        point-color="#01A39D"
        background-color-two="#787F8F"
        point-color-two="#787F8F"
        border-color="#01A39D"
        border-color-two="#787F8F"
        border-color-three="#2F84EB"
        :labels="chartYearList"
        :displayDataLabels="false"
      />
      <div class="d-flex align-items-center mt-4">
        <div class="indicator-round d-flex align-items-center mr-4">
          <span class="rounded-circle"></span>
          <p v-if="isProductMode">Товарооборот</p>
          <p v-else>Оборот</p>
        </div>
        <div class="indicator-round d-flex align-items-center mr-4">
          <span class="rounded-circle"></span>
          <p>Экспорт</p>
        </div>
        <div class="indicator-round d-flex align-items-center">
          <span class="rounded-circle"></span>
          <p>Импорт</p>
        </div>
      </div>
    </div>

    <div class="supplyStructure" v-if="(tabId === 2 && isProductMode) || (tabId === 1 && isProductMode)">
      <div class="d-flex justify-content-between my-5">
        <SwitchComponent
          class="customSwitcherInter"
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tovarnayaStructureExport = !switchRows.tovarnayaStructureExport"
        />
        <h2>Товарная структура экспорта</h2>
      </div>
      <template v-if="switchRows.tovarnayaStructureExport">
        <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
        <template v-if="!isLoading && currentSummary">
          <ColumnChartDouble
            labelAlignY="end"
            labelAnchorY="end"
            :enableTooltip="false"
            indexAxis="y"
            barPercentage="1"
            :format-label="null"
            color-first="#0791BA"
            color-second="#D3DFE5"
            label-first="Текущий период, млн $"
            label-second="Прошедший период, млн $"
            :chart-data="currentSummary.topExportGroups.map((item) => (item.currentValue / 1000).toFixed(1))"
            :chart-data-two="currentSummary.topExportGroups.map((item) => (item.previousValue / 1000).toFixed(1))"
            :labels="currentSummary.topExportGroups.map((item) => makeChartLabel(item.title, 70))"
            height="100%"
          />
        </template>
      </template>
      <template v-else>
        <!-- <h2 class="my-5" v-if="currentSummary">Товарная структура экспорта (таблица)</h2> -->
        <div class="table-container mt-5 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th style="font-size: 14px" rowspan="2">Наименование</th>
                <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Экспорт</th>
              </tr>
              <tr>
                <th style="text-align: center">Текущий период, млн $</th>
                <th>Прошедший период, млн $</th>
              </tr>
            </thead>
            <tbody v-if="currentSummary">
              <tr style="background-color: #ade0ff">
                <td class="font-weight-bold">Всего</td>
                <td class="font-weight-bold">
                  {{ formatNumber(currentSummary.exportGroupsTotalCurrent / 1000) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatNumber(currentSummary.exportGroupsTotalPrevious / 1000) }}
                </td>
              </tr>
              <tr v-for="item in currentSummary.topExportGroups" :key="item.id">
                <td class="font-weight-bold">{{ item.title }}</td>
                <td class="font-weight-bold">
                  {{ formatNumber(item.currentValue / 1000) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatNumber(item.previousValue / 1000) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
    <div v-if="tabId === 4 && isProductMode">
      <div class="d-flex justify-content-between my-5">
        <SwitchComponent
          class="customSwitcherInter"
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tovarnayaStructureExportSecond = !switchRows.tovarnayaStructureExportSecond"
        />
        <h2>Товарная структура экспорта</h2>
      </div>
      <template v-if="!isLoading && switchRows.tovarnayaStructureExportSecond">
        <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
        <ColumnChartDouble
          labelAlignY="end"
          labelAnchorY="end"
          :enableTooltip="false"
          indexAxis="y"
          barPercentage="1"
          :format-label="null"
          color-first="#0791BA"
          color-second="#D3DFE5"
          label-first="Текущий период"
          label-second="Прошедший период"
          :chart-data="itemsDataTwo.map((item) => item.count)"
          :chart-data-two="itemsDataTwo.map((item) => item.countTwo)"
          :labels="itemsDataTwo.map((item) => item.name)"
          height="100%"
        />
      </template>
      <template v-else>
        <div class="table-container mt-5 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th style="font-size: 14px" rowspan="2">Наименование</th>
                <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Экспорт</th>
              </tr>
              <tr>
                <th style="text-align: center">Текущий период</th>
                <th>Прошедший период</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: #ade0ff">
                <td class="font-weight-bold">Всего</td>
                <td class="font-weight-bold">
                  {{ reduceData + ' млн $' }}
                </td>
                <td class="font-weight-bold">
                  {{ reduceDataSecond + ' млн $' }}
                </td>
              </tr>
              <tr v-for="item in itemsDataTwo" :key="item.id">
                <td class="font-weight-bold">{{ item.name }}</td>
                <td class="font-weight-bold">{{ item.count + ' млн $' }}</td>
                <td class="font-weight-bold">{{ item.countTwo + ' млн $' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
    <div v-if="(tabId === 3 && isProductMode) || (tabId === 1 && isProductMode)">
      <div class="d-flex justify-content-between my-5">
        <SwitchComponent
          class="customSwitcherInter"
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tovarnayaStructureImport = !switchRows.tovarnayaStructureImport"
        />
        <h2>Товарная структура импорта</h2>
      </div>
      <template v-if="switchRows.tovarnayaStructureImport">
        <!-- <h2 class="my-5">Товарная структура импорта</h2> -->
        <ColumnChartDouble
          labelAlignY="end"
          labelAnchorY="end"
          :enableTooltip="false"
          indexAxis="y"
          barPercentage="1"
          :format-label="null"
          color-first="#0791BA"
          color-second="#D3DFE5"
          label-first="Текущий период, млн $"
          label-second="Прошедший период, млн $"
          :chart-data="currentSummary.topImportGroups.map((item) => (item.currentValue / 1000).toFixed(1))"
          :chart-data-two="currentSummary.topImportGroups.map((item) => (item.previousValue / 1000).toFixed(1))"
          :labels="currentSummary.topImportGroups.map((item) => makeChartLabel(item.title, 70))"
        />
      </template>
      <template v-else>
        <h2 class="my-5" v-if="currentSummary">Товарная структура импорта (таблица)</h2>
        <div class="table-container mt-5 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th style="font-size: 14px" rowspan="2">Наименование</th>
                <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Импорт</th>
              </tr>
              <tr>
                <th style="text-align: center">Текущий период, млн $</th>
                <th>Прошедший период, млн $</th>
              </tr>
            </thead>
            <tbody v-if="currentSummary">
              <tr style="background-color: #ade0ff">
                <td class="font-weight-bold">Всего</td>
                <td class="font-weight-bold">
                  {{ formatNumber(currentSummary.importGroupsTotalCurrent / 1000) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatNumber(currentSummary.importGroupsTotalPrevious / 1000) }}
                </td>
              </tr>
              <tr v-for="item in currentSummary.topImportGroups" :key="item.id">
                <td class="font-weight-bold">{{ item.title }}</td>
                <td class="font-weight-bold">
                  {{ formatNumber(item.currentValue / 1000) }}
                </td>
                <td class="font-weight-bold">
                  {{ formatNumber(item.previousValue / 1000) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import SwitchComponent from '@/components/buttons/switchComponent';
  import ColumnChartDouble from '@/components/columnChartDouble';
  import LineChartThree from '@/components/lineChartThree';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryAnalyticsNew/api/api';

  export default {
    name: 'InternationalTradeEconRev',
    mixins: [text],
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    components: {
      SwitchComponent,
      LineChartThree,
      ColumnChartDouble,
    },
    data() {
      return {
        year: 2020,
        isProductMode: true,
        tabId: null,
        label: null,
        yearList: [
          { id: 2018, text: '2018 год' },
          { id: 2019, text: '2019 год' },
          { id: 2020, text: '2020 год' },
        ],
        itemsData: [
          {
            name: 'Топливо-энергетические товары',
            id: 1,
            count: 20,
            countTwo: 34,
          },
          {
            name: 'Оборудование и транспортные средства',
            id: 2,
            count: 10,
            countTwo: 34,
          },
          { name: 'Металлы, изделия из них', id: 3, count: 10, countTwo: 34 },
          {
            name: 'Продукция химической промышленности',
            id: 4,
            count: 60,
            countTwo: 34,
          },
          {
            name: 'Древесина и цел.-бумажн. изделия',
            id: 5,
            count: 30,
            countTwo: 34,
          },
          { name: 'Другие товары', id: 6, count: 20, countTwo: 34 },
        ],
        itemsDataTwo: [
          {
            name: 'Зерна, Овощи, Фрукты',
            id: 1,
            count: 20,
            countTwo: 34,
          },
          {
            name: 'Химикаты, удобрения',
            id: 2,
            count: 10,
            countTwo: 34,
          },
          {
            name: 'обработанный камень, чугун и сталь',
            id: 3,
            count: 10,
            countTwo: 34,
          },
          {
            name: 'Цветные и драгоценные металлы',
            id: 4,
            count: 60,
            countTwo: 34,
          },
          {
            name: 'Фармацевтические товары',
            id: 5,
            count: 30,
            countTwo: 34,
          },
          {
            name: 'Готовые продукты невысокой сложности',
            id: 6,
            count: 20,
            countTwo: 34,
          },
          {
            name: 'Бытовая химия',
            id: 7,
            count: 10,
            countTwo: 32,
          },
          {
            name: 'Одежда и обувь',
            id: 8,
            count: 10,
            countTwo: 32,
          },
        ],
        summary: null,
        importValues: null,
        exportValues: null,
        importIndicatorId: 4,
        exportIndicatorId: 8,
        isLoading: false,

        turnoverMoreInfo: true,
        exportMoreInfo: true,
        importMoreInfo: true,

        switchRows: {
          tovarnayaStructureExport: true,
          tovarnayaStructureExportSecond: true,
          tovarnayaStructureImport: true,
        },
      };
    },

    computed: {
      reduceData() {
        const initialValue = 0;
        const result = this.itemsData
          .map((item) => item.count)
          .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        return result;
      },
      reduceDataSecond() {
        const initialValue = 0;
        const result = this.itemsData
          .map((item) => item.countTwo)
          .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        return result;
      },
      currentSummary() {
        return this.isProductMode ? this.summary?.worldSummaryTnved : this.summary?.worldSummaryOkved;
      },
      currentSummaryOkved() {
        return this.summary?.turnoverWorldOkved;
      },
      currentSummaryTnved() {
        return this.summary?.turnoverWorldTnved;
      },
      currentTurnoverSummary() {
        return this.isProductMode ? this.currentSummaryTnved : this.currentSummaryOkved;
      },
      currentTurnoverSummaryAvailable() {
        return this.currentTurnoverSummary && this.currentTurnoverSummary?.totalValuesByYears?.length > 0;
      },
      totalOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.totalValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        // return this.currentSummaryOkved?.totalValuesByYears
        //   ?.filter((x) => Number(x.title) > 2016 && Number(x.title) <= this.year)
        //   .map((x) => (x.currentValue / 1000000).toFixed(2));
        else return [30, 4, 5, 3, 1, 10];
      },
      exportOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.exportValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        // return this.currentSummaryOkved?.exportValuesByYears
        //   ?.filter((x) => Number(x.title) > 2016 && Number(x.title) <= this.year)
        //   .map((x) => (x.currentValue / 1000).toFixed(2));
        else return [30, 4, 5, 3, 1, 10].reverse();
      },
      importOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.importValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        // return this.currentSummaryOkved?.importValuesByYears
        //   ?.filter((x) => Number(x.title) > 2016 && Number(x.title) <= this.year)
        //   .map((x) => (x.currentValue / 1000000).toFixed(2));
        else return [36, 40, 20, 31, 1, 10];
      },
      totalTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.totalValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(2) : 0;
          });
        // return this.currentSummaryTnved?.totalValuesByYears
        //   ?.filter((x) => Number(x.title) > 2016 && Number(x.title) <= this.year)
        //   .map((x) => (x.currentValue / 1000000).toFixed(2));
        else return [30, 4, 5, 3, 1, 10];
      },
      exportTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.exportValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        // return this.currentSummaryTnved?.exportValuesByYears
        //   ?.filter((x) => Number(x.title) > 2016 && Number(x.title) <= this.year)
        //   .map((x) => (x.currentValue / 1000000).toFixed(2));
        else return [30, 4, 5, 3, 1, 10].reverse();
      },
      importTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length) {
          // let values = this.currentSummaryTnved?.importValuesByYears
          //   ?.filter((x) => Number(x.title) > 2016 && Number(x.title) <= this.year);
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.importValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / 1000).toFixed(0) : 0;
          });
        } else return [36, 40, 20, 31, 1, 10];
      },
      chartYearList() {
        var list = this.getAvailableYears().map((x) => x.text);
        return list;
      },
    },
    created() {
      this.loadIndicatorVisibility();
      Api.getYearListTurnover(this.countryId).then((response) => {
        this.yearList = response.data.filter((x) => x.id > 2016);
        //this.year = this.yearList[this.yearList.length - 1].id;
        this.year = 2021; //Это временно!!!
        this.loadData();
      });
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.turnoverMoreInfo = response.data[10].moreInfoVisible;
          this.exportMoreInfo = response.data[11].moreInfoVisible;
          this.importMoreInfo = response.data[12].moreInfoVisible;
        });
      },
      loadData() {
        this.isLoading = true;
        Api.getForeignTradeData(this.year, this.countryId).then((response) => {
          // this.summary = null;
          // this.$nextTick();
          this.summary = response.data;
          this.isLoading = false;
        });
        Api.searchMacro({
          countryId: this.countryId,
          indicatorId: this.importIndicatorId,
        }).then((response) => {
          this.importValues = response.data.items;
        });
        Api.searchMacro({
          countryId: this.countryId,
          indicatorId: this.exportIndicatorId,
        }).then((response) => {
          this.exportValues = response.data.items;
        });
      },
      switchHand(e) {
        this.isProductMode = e;
        this.selectTab(1, this.isProductMode ? 'Товарооборот' : 'Оборот услуг');
        this.$forceUpdate();
      },
      selectTab(id, label) {
        if (id != this.tabId) {
          this.tabId = id;
          this.label = label;
        } else {
          // при повторном клике - сворачивание
          this.tabId = null;
          this.label = null;
        }
      },
      getAvailableYears() {
        return this.yearList.filter((x) => x.id > 2016 && x.id <= this.year);
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';

  .International-trade {
    &__switchblock {
      flex-wrap: wrap;
      align-items: baseline;
      @media screen and (max-width: 499px) {
        display: block !important;
      }
    }
  }

  .table-custom {
    border-collapse: separate;
    border-spacing: var(--border-width);

    th {
      padding: 13px 11px;
      background-color: #ffffff;
    }

    th,
    td {
      outline: var(--border);
      padding: 8px;
      text-align: center;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #000000;
    }

    th:first-child,
    td:first-child {
      padding: 8px 14px 8px 8px;
      text-align: left;
    }

    tr:nth-child(odd) {
      background-color: #e5f5ff;
    }

    th {
      border-bottom: 1px solid #01a39d !important;
    }
  }

  .table-custom-two {
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 111;
    }
  }

  .indicator-round {
    span {
      background-color: #018bbf;
      width: 14px;
      height: 14px;
    }

    p {
      margin-left: 13px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }

  .indicator-round:nth-child(2) {
    p {
      margin-left: 13px;
    }

    span {
      background-color: #787f8f;
    }
  }

  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }

  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }

  .indicators-two {
    display: flex;
    align-content: center;
    // justify-content: space-between;
    gap: 10px 25px;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      margin: 0 10px;

      p {
        margin-bottom: 20px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }

      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }

      strong {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
        white-space: nowrap;
        display: block;
      }
    }

    &__item {
      line-height: 0;
      text-align: center;
    }
  }

  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }

  .indicators {
    $offset: 15px;

    &__list {
      padding: 12px 4px;
      overflow: auto;
      gap: $offset;
    }

    &__itemCustom {
      cursor: pointer;
      max-width: 180px;
      width: 100%;

      &:hover,
      &.active {
        .indicators__wrapperCustom {
          background: #ffffff;
          box-shadow: 0px 0px 0px rgba(1, 120, 200, 0.5);
          border-radius: 5px;
        }
      }
    }

    &__wrapperCustom {
      transition: 0.2s ease-in-out;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 10px;
    }
  }

  .select2::v-deep {
    outline: none;
    background-color: #ffffff;
    // Контейнер ширина
    .select2-container {
      outline: none;
      width: 100% !important;
      max-width: initial !important;
      border-radius: 40px;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $color1;
        margin-top: 0 !important;
      }
    }

    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: auto !important;
      border: none;
      background-color: transparent !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      width: 10px;
      right: 14px;
      transform: translateY(-50%) !important;
    }

    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #989898;
    }

    /* Модификация стрелки */
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 0;
      border-left: 3px solid $color1 !important;
      border-bottom: 3px solid $color1 !important;
      transform: rotate(315deg);
      transition: 0.3s;
      mask-image: unset;
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      transform: rotate(135deg);
      margin-top: -3px !important;
    }
  }

  .select2-container--open .select2-dropdown--below {
    border: 1px solid #01a39d;
  }

  .select2-container--default .select2-results > .select2-results__options {
    li {
      border-top: 1px solid #01a39d !important;
    }

    li:hover {
      background-color: #01a39d;
    }
  }

  .more-info-hidden {
    padding-bottom: 26px;
    pointer-events: none;
  }

  .indicators__itemCustom {
    min-width: 150px;
  }
</style>
