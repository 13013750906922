<template>
  <div>
    <div class="tabs">
      <ul class="tabs_container">
        <li
          class="tabs_item"
          v-for="(tab, index) in tabs"
          :key="tab.id"
          @click.prevent="selectTab(tab, index)"
          :class="[{ selected: tab.isActive }, { disabled: tab.isDisabled }]"
        >
          <a :href="tab.href" @click.prevent="selectTab(tab, index)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    // TODO по возможности исправить
    // eslint-disable-next-line
    name: 'Tabs',
    emits: ['select'],
    props: ['modelValue'],
    data() {
      return {
        tabs: [],
        selectedTab: {},
        selectedTabIndex: 0,
      };
    },
    created() {},
    methods: {
      selectTab(selectedTab, index) {
        this.selectedTab = selectedTab;
        this.tabs.forEach((tab) => {
          tab.isActive = tab.name === selectedTab.name;
        });
        this.selectedTabIndex = index;
        this.$emit('select', index);
        this.$emit('update:modelValue', index);
      },
    },
    watch: {
      modelValue: {
        handler: function (val) {
          this.$forceUpdate();
          if (val !== this.selectedTabIndex) {
            for (let i = 0; i < this.tabs.length; i++) {
              this.tabs[i].isActive = i === val;
            }
            this.selectedTabIndex = val;
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tabs {
    $m: 32px;
    overflow-x: scroll;
    margin-bottom: $m;

    &_container {
      display: flex;
      width: fit-content;
      border-bottom: 2px solid #00c6a2;
      margin: $m 0px 5px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 12px 30px;
      transition: 0.3s;
      box-shadow: 0px 5px 10px rgba(0, 120, 199, 0.15);
      border-radius: 5px 5px 0px 0px;
      background: #e6f5ff;
      border-top: 0.5px solid #d3dfe6;
      border-left: 0.5px solid #d3dfe6;
      border-right: 0.5px solid #d3dfe6;
      margin-right: 16px;

      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $black-3;
      }

      &:hover {
        filter: brightness(105%);
      }

      &:last-of-type {
        margin-right: 0px;
      }

      &.selected {
        background: #0179c8;

        a {
          font-weight: 600;
          color: $white;
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.6;

        a {
          pointer-events: none;
        }
      }
    }
  }
</style>
