export const SortColumn = {
  CountryName: 0,
  AssignmentName: 1,
  Deadline: 2,
  UrgencyId: 3,
  EventName: 4,
  TypeName: 5,
  StatusName: 6,
  Report: 7,
  ReceiptDate: 8,
  CorrespondentName: 9,
  ResponsibleEmployees: 10
}
