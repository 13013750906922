<template>
  <Loader class="loader" v-if="loadingMore"></Loader>
  <div :class="`more-button fira-sans ${classButton}`">
    <button v-if="isVisibleButton || isForeverVisible" @click="showMore()">
      <slot />
    </button>
  </div>
</template>

<script>
  import Loader from '@/components/Loader';

  export default {
    components: { Loader },
    emits: ['onClick'],
    props: {
      loadingMore: Boolean,
      currentPage: Number,
      pageCount: Number,
      classButton: {
        type: String,
        default: '',
      },
      isShowMoreVisible: {
        type: Boolean,
        default: true,
      },
      isForeverVisible: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      showMore() {
        this.$emit('onClick');
      },
    },
    computed: {
      isVisibleButton() {
        return this.currentPage < this.pageCount && this.pageCount > 1 && !this.loadingMore && this.isShowMoreVisible;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loader {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .more-button {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    button {
      color: $green;
      background: none;
      border: none;
    }
  }
</style>
