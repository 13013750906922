<template>
  <ModalComponent
    title="Редактирование наименования"
    @close="updateShowing(false)"
    :model-value="isShowing"
    @update:modelValue="updateShowing"
    headType="redesigned"
    bodyType="redesigned"
  >
    <form class="custom-form">
      <div class="custom-form__block">
        <InputBlock title="Краткое наименование файла" required>
          <ComponentInput v-model="localeFile.fileName" mod="rectangular" required />
        </InputBlock>
        <div class="custom-form__button-container custom-form__button-container_mobile">
          <ButtonComponent @click="updateFile" :disabled="isLoading" mod="gradient-bg" type="submit"
            >Переименовать</ButtonComponent
          >
          <ButtonComponent @click="updateShowing(false)" :disabled="isLoading">Отменить</ButtonComponent>
        </div>
      </div>
    </form>
  </ModalComponent>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import InputBlock from '@/common/components/redesigned/InputBlock.vue';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../../../api/referenceInfo';

  export default {
    name: 'ReferenceInfoTableEditing',
    components: { InputBlock, ButtonComponent, ComponentInput, ModalComponent },
    emits: ['update:isShowing', 'update:file'],
    props: {
      file: {
        type: Object,
        default: null,
      },
      isShowing: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        localeFile: null,
        isLoading: false,
      };
    },
    methods: {
      updateShowing(isShowing) {
        this.$emit('update:isShowing', isShowing);
      },
      async updateFile() {
        try {
          this.isLoading = true;

          await API.changeName({
            id: this.localeFile.id,
            newName: this.localeFile.fileName,
          });

          this.$emit('update:file', this.localeFile);
        } catch (err) {
          console.error(err);
        } finally {
          this.isLoading = false;
        }
      },
    },
    watch: {
      file: {
        handler(newValue) {
          this.localeFile = JSON.parse(JSON.stringify(newValue));
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/custom-form.scss';
</style>
