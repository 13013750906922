<template>
  <div class="potential-table__tooltip">
    <div class="potential-table__tooltip-close"></div>
    <div class="potential-table__tooltip-title">
      <div class="potential-table__tooltip-code">
        {{ productData.code }}
        <br />
        {{ country?.name }}
        <br />
        <span>{{ productData.name }}</span>
      </div>

      <p class="potential-table__tooltip-hint">Источник: Данные Министерства экономического развития РФ</p>
    </div>
    <div class="potential-table__tooltip-content">
      <div class="potential-table__tooltip-block">
        <div>Экспортный потенциал</div>
        <div class="potential-table__tooltip-value">{{ exportValue }} млн</div>
      </div>
      <div class="potential-table__tooltip-block">
        <div>Средний зеркальный экспорт за 5 лет</div>
        <div class="potential-table__tooltip-value">{{ exportFact }} млн</div>
      </div>
      <div class="potential-table__tooltip-block">
        Нереализованный экспортный потенциал
        <div class="potential-table__tooltip-value">{{ value }} млн</div>
      </div>
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import API from '@/modules/countryAnalyticsNew/api/api';
  import API_Country from '@/modules/nsi/api/country';

  export default {
    name: 'ExportPotentialPopup',
    components: {},
    mixins: [text],
    props: {
      countryId: {
        type: Number,
        default: null,
      },
      productCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        country: null,
        exportPotentialData: {},
      };
    },
    created() {},
    mounted() {
      this.$watch(
        (vm) => [vm.productCode],
        () => {
          if (this.countryId) this.loadPotentialData();
        },
      );
    },
    beforeUnmount() {},
    methods: {
      loadCountry() {
        this.country = null;
        if (this.countryId)
          API_Country.findShort(this.countryId).then((response) => {
            this.country = response.data;
          });
      },
      loadPotentialData() {
        API.getSummaryForCountry(this.countryId, this.productCode).then((response) => {
          this.exportPotentialData = response.data;
        });
      },
    },
    watch: {
      countryId: function () {
        this.loadCountry();
        if (this.countryId) this.loadPotentialData();
      },
    },
    computed: {
      productData() {
        if (
          this.exportPotentialData &&
          this.exportPotentialData.itemsNsiTnved &&
          this.exportPotentialData.itemsNsiTnved.length
        )
          return this.exportPotentialData.itemsNsiTnved[0];
        else return {};
      },
      exportValue() {
        let result = this.exportPotentialData?.exportValue ? this.exportPotentialData.exportValue.toFixed(1) : 0;
        return result >= 0.1 ? result : '0.1<';
      },
      exportFact() {
        let result = this.exportPotentialData?.exportFact ? this.exportPotentialData.exportFact.toFixed(1) : 0;
        return result >= 0.1 ? result : '0.1<';
      },
      value() {
        let result = this.exportPotentialData?.value ? this.exportPotentialData.value.toFixed(1) : 0;
        return result >= 0.1 ? result : '0.1<';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/colors';
  .potential-table {
    &__tooltip {
      position: static;
      border: 0;
      display: block;
      width: auto;
      padding: 16px;
      &-title {
        padding-bottom: 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
      &-code {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $color9;
      }
      &-block {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed $color3;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        div {
          display: flex;
          align-items: center;
        }
        &:last-child {
          border-bottom: unset;
        }
      }
      &-box {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }
      &-value {
        color: $color9;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
        margin-left: 12px;
      }
    }
  }

  .imagePreviewWrapper {
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;
    margin: 5px 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
