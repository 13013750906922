import axios from 'axios';

import runtimeConfig from '@/common/runtime-config';

export default {
  loadSelectOptions(dictName, optionsArray, requestData, isClear) {
    this.searchNsi(dictName, requestData).then(function (response) {
      if (isClear) {
        while (optionsArray.length > 1) optionsArray.pop();
      }

      if (response.data && response.data.length) {
        response.data.forEach((i) => optionsArray.push(i));
      }
    });
  },
  loadMultiSelectOptions(dictName, optionsArray, requestData, isClear) {
    this.searchNsi(dictName, requestData).then(function (response) {
      if (isClear) {
        while (optionsArray.length > 1) optionsArray.pop();
      }

      if (response.data && response.data.length) {
        response.data.map((i) =>
          optionsArray.push({
            value: i.id,
            label: i.text,
          }),
        );
      }
    });
  },
  searchNsi(dictName, requestData) {
    return axios.get('/api/nsi/' + dictName, { params: requestData });
  },
  downloadFile(url, filename, params, nameFromParams) {
    const options = { responseType: 'blob' };
    if (params) {
      options.params = params;
    }
    return axios.get(url, options).then((response) => {
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement('a');
      const contentDisposition = response.headers['content-disposition'];
      filename = filename || 'download';
      if (contentDisposition && !nameFromParams) {
        const matches = contentDisposition.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?/);
        if (matches?.length === 2) {
          filename = matches[1];
        }
      }
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    });
  },
  getFileUrl(id) {
    const baseUrl = runtimeConfig.apiBaseUrl;
    return `${baseUrl}/api/file/download?id=${id}`;
  },
  getFileUrlGuid(guid) {
    const baseUrl = runtimeConfig.apiBaseUrl;
    return `${baseUrl}/api/file/downloadGuid?guid=${guid}`;
  },
  groupList(list, keyGetter, itemGetter) {
    let result = [];
    list.forEach((item) => {
      const key = keyGetter(item);
      if (key.id > 0) {
        let group = result.find((x) => x.id === key.id);
        if (!group) {
          group = { id: key.id, text: key.text, children: [] };
          result.push(group);
        }
        let child;
        if (typeof itemGetter === 'function') {
          child = itemGetter(item);
        } else {
          child = { id: item.id, text: item.name };
        }
        group.children.push(child);
      } else if (item.isLeaf) {
        result.push({ id: item.id, text: item.name });
      }
    });
    return result;
  },
  getPublicBaseUrl() {
    return runtimeConfig.publicBaseUrl;
  },
  async getAdminAccess() {
    return axios.get('/api/navigation/adminAccess');
  },
  async getRussianRegions() {
    const authUrl = runtimeConfig.apiAuthUrl;
    return axios.get(`${authUrl}/api/RegionOfRussia/Search`);
  },
  windowTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  getPreparedMobilePhone(phoneNumber) {
    if (!phoneNumber) return;
    return String(phoneNumber).replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
  },
};
export const findItemNested = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.id == itemId) return item;
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey);
  }, null);

export const isEscape = (e) => e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;
