<template>
  <div class="country-ranking">
    <div class="budget-title" id="budget">Бюджетная политика</div>
    <ul class="indicators__list align-center" v-if="!isLoading">
      <template v-for="item in indicatorsList" :key="item.id">
        <li class="indicators__item justify-content-between" v-if="item.count != '-'">
          <div class="d-flex flex-column">
            <div class="indicators__item-title">{{ item.title }}</div>
            <div v-if="item.source" class="analytics-source-text">Источник: {{ item.source }}</div>
            <div v-if="item.source" class="analytics-source-text">
              <!-- <template v-if="item.id == 5">
                По данным за 2 кв 2022
              </template>
              <template v-else>
                По данным за 2021 год
              </template> -->
              <template v-if="yearUpdate"> По данным за {{ yearUpdate }} год </template>
            </div>
          </div>
          <strong>{{ isNaN(item.count) ? item.count : formatNumber(Number(item.count)) }}</strong>
          <!--        <div v-if="item.awards.length" class="award d-flex flex-column">-->
          <!--          <span v-for="(award, idx) in item.awards" :key="idx"-->
          <!--            >{{ award.count }} место - {{ award.country }}</span-->
          <!--          >-->
          <!--        </div>-->
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import Api from '@/modules/countryAnalyticsNew/api/api';

  export default {
    name: 'Budget',
    mixins: [text],
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        indicatorsList: [
          {
            id: 1,
            title: 'Баланс бюджета, % ВВП',
            source: 'Международный валютный фонд',
            count: 'Загрузка...',
            awards: [],
          },
          {
            id: 2,
            title: 'Государственные доходы, % ВВП',
            source: 'Международный валютный фонд',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 3,
            title: 'Государственные расходы, % ВВП',
            source: 'Всемирный банк',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 4,
            title: 'Государственный долг, % ВВП',
            source: 'Международный валютный фонд',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 5,
            title: 'Общий кредит нефинансовому сектору, % ВВП',
            source: 'Банк международных расчетов',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 6,
            title: 'Золотовалютные резервы, млрд $ США',
            source: 'Всемирный банк',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 7,
            title: 'Золотовалютные резервы, объем в золоте, млрд $ США',
            source: 'gold.org',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
        ],
        yearUpdate: null,
        isLoading: true,
      };
    },
    created() {
      this.loadPage();
    },
    methods: {
      loadPage() {
        this.isLoading = true;
        Api.getBudgetPolicy(this.countryId)
          .then((response) => {
            this.updateIndicators(response.data);
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error?.response?.data);
            this.isLoading = false;
          });
      },
      updateIndicators(data) {
        console.log(data);
        this.yearUpdate = data.year;
        const noData = '-';
        let ind = this.indicatorsList.find((x) => x.id === 1);
        ind.count = data.budgetBalance !== null ? data.budgetBalance.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 2);
        ind.count = data.revenue !== null ? data.revenue.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 3);
        ind.count = data.expenses !== null ? data.expenses.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 4);
        ind.count = data.governmentDebt !== null ? data.governmentDebt.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 5);
        ind.count = data.nonFinancialCredit !== null ? data.nonFinancialCredit.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 6);
        ind.count = data.reserves !== null ? (data.reserves / 1000000000).toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 7);
        ind.count = data.reservesInGold !== null ? data.reservesInGold.toFixed(2) : noData;
      },
    },
    watch: {
      countryId: function () {
        this.loadPage();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/modules/countryAnalyticsNew/styles/index';
  .budget-title {
    margin-top: 60px;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }
</style>
