import { NAVIGATOR_STEP_CARD_ACTIONS_NAME, NAVIGATOR_STEP_CARD_MUTATIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getStepCard(context, params) {
  return await request.call(this, context, {
    url: links.navigator.stepCards.getStepCard + params.id,
    mutate: NAVIGATOR_STEP_CARD_MUTATIONS_NAME.setStepCard,
    name: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getStepCard,
  });
}

export async function createStepCard(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.stepCards.saveStepCard,
      name: NAVIGATOR_STEP_CARD_ACTIONS_NAME.createStepCard,
      method: 'post',
    },
    params,
  );
}

export async function updateStepCard(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.stepCards.saveStepCard,
      name: NAVIGATOR_STEP_CARD_ACTIONS_NAME.updateStepCard,
      method: 'put',
      pathParams: ['cardId'],
    },
    params,
  );
}

export async function getOrganizationList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.stepCards.getOrganizationList,
      mutate: NAVIGATOR_STEP_CARD_MUTATIONS_NAME.setOrganizationList,
      name: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getOrganizationList,
      requestAuth: true,
    },
    params,
  );
}

export async function getDocumentList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.stepCards.getDocumentList,
      mutate: NAVIGATOR_STEP_CARD_MUTATIONS_NAME.setDocumentList,
      name: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getDocumentList,
      pathParams: params.cardId,
    },
    params,
  );
}

export async function getLegalSourceList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.stepCards.getLegalSourceList,
      mutate: NAVIGATOR_STEP_CARD_MUTATIONS_NAME.setLegalSourceList,
      name: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getLegalSourceList,
    },
    params,
  );
}
