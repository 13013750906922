<template>
  <div class="container-page">
    <p class="title-inner">Журнал аудита</p>
    <tabs>
      <tab name="Учет операций в системе" :selected="true">
        <AuditLogActions />
      </tab>
      <tab name="Учет посещаемости страниц" :selected="false">
        <AuditLogVisits />
      </tab>
    </tabs>
  </div>
</template>

<script>
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';

  import AuditLogActions from '@/modules/admin/components/AuditLogActions';
  import AuditLogVisits from '@/modules/admin/components/AuditLogVisits';

  export default {
    name: 'AuditLog',
    components: { AuditLogActions, AuditLogVisits, tabs, tab },
    data() {
      return {};
    },
    created() {},
    methods: {},
  };
</script>
