export const routes = [
  {
    path: '/investmentagreement',
    name: 'InvestmentAgreementRegistry',
    component: () => import('../views/InvestmentAgreementRegistry'),
  },
  {
    path: '/investmentagreement/edit/:id',
    name: 'InvestmentAgreementCard',
    component: () => import('../views/InvestmentAgreementCard'),
  },
];
