export default {
  setScenarios(state, res) {
    const { data = {} } = res;
    state.tableData = data;

    state.isLoading = false;
  },
  addScenarios(state, res) {
    const { data = {} } = res;
    state.tableData.items = state.tableData.items.concat(data.items);
    state.tableData.pageNumber = data.pageNumber;
    state.isLoadingMore = false;
  },
};
