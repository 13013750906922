<template>
  <div class="reportForm">
    <div class="reportForm__reportTextBlock">
      <span class="reportForm__reportTextBlock__title">Отчёт</span>
      <ComponentInput
        v-model="card.text"
        type="textarea"
        placeholder="Вставьте или напишите текст отчёта"
        mod="rectangular no-resize"
      />
    </div>

    <div class="reportForm__reportFileUpload">
      <span class="reportForm__reportTextBlock__title">Приложить документы</span>
      <FileMultiUploadRedesigned :entity="card" @uploaded="fileUploaded" @deleteFile="removeFile" prop-name="files" />
    </div>

    <div class="reportForm__navigationBlock">
      <ButtonComponent mod="gradient-bg" @click="$emit('save', card)" class="reportForm__navigationBlock__save">
        Сохранить
      </ButtonComponent>
      <ButtonComponent @click="$emit('back')" class="reportForm__navigationBlock__back"> Отмена </ButtonComponent>
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import FileMultiUploadRedesigned from '@/common/components/redesigned/FileMultiUploadRedesigned.vue';

  export default {
    components: {
      ComponentInput,
      FileMultiUploadRedesigned,
      ButtonComponent,
    },

    props: {
      data: {
        default: () => {},
        type: Object,
      },
    },

    data() {
      return {
        card: {
          text: this.data?.text || '',
          files: this.data?.files || [],
        },
      };
    },

    methods: {
      fileUploaded(e) {
        this.card.files.push(e.data);
      },
      removeFile(e) {
        const file = this.card.files.find((x) => x.id === e.id);
        const index = this.card.files.indexOf(file);
        if (index > -1) {
          this.card.files.splice(index, 1);
        }
      },
    },
  };
</script>

<style lang="scss">
  .reportForm {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__reportTextBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0 0 24px 0;

      &__title {
        font: $fira-16;
      }
    }

    &__reportFileUpload {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      margin: 0 0 40px 0;

      &__title {
        font: $fira-16;
      }

      .fileUpload-container {
        padding: 0;
        max-width: 740px;
      }
    }

    &__navigationBlock {
      display: flex;
      gap: 8px;

      &__save {
        max-width: 103px;
      }

      &__back {
        max-width: 83px;
      }
    }
  }
</style>
