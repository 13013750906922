import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (route, request) {
    return axios.get('/api/nsi/' + route + '/search', { params: request });
  },
  find: function (route, id) {
    return axios.get('/api/nsi/' + route + '/find/?id=' + id);
  },
  update: function (route, data) {
    if (data.id > 0) return axios.put('/api/nsi/' + route + '/update/?id=' + data.id, data);
    else return axios.post('/api/nsi/' + route + '/create', data);
  },
  delete: function (route, id) {
    return axios.delete('/api/nsi/' + route + '/delete/?id=' + id);
  },
  industryList: function (route) {
    return axios.get('/api/nsi/' + route + '/industryList');
  },
  exportExcel: function (route) {
    return Utils.downloadFile('/api/nsi/' + route + '/exportExcel', 'NsiExport.xlsx', {});
  },
  mpkBarrierQuestionsStatuses: function() {
    return axios.get('/api/nsi/getmpkbarrierquestionstatuses');
  },
  importFile: function (route, file) {
    const data = new FormData();
    data.append('file', file);
    return axios.post('/api/nsi/' + route + '/import', data);
  },
};
