<template>
  <ReferenceInfoTable
    :files="files"
    :loading="isFetchingFiles"
    :country-id="countryId"
    :pagination="pagination"
    :is-delete-event-showing="isDeletePermission"
    @delete="deleteFile"
    @filter="filterHandler"
    @show-more="showMore"
  >
    <template #fileEvents="{ file, index }">
      <IconComponent
        v-permission="permissions.recovery"
        class="icon--full-svg"
        @click="restoreFile(file, index)"
        name="restore"
      />
    </template>
  </ReferenceInfoTable>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import API from '../../api/referenceInfo';
  import ReferenceInfoTable from './referenceInfoTable/ReferenceInfoTable.vue';

  export default {
    name: 'ReferenceInfoArchive',
    components: { IconComponent, ReferenceInfoTable },
    mixins: [security],
    props: {
      countryId: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        files: [],
        isFetchingFiles: false,
        filters: {
          orderByName: 0,
          orderByDat: 1,
          fileExtensions: [],
          from: null,
          to: null,
        },
        pagination: {
          limit: 10,
          offset: 0,
          total: 0,
        },
        permissions: {
          removing: Constants.Permissions.CountryAnalyticsReferenceInfoRecoveryArchive,
          recovery: Constants.Permissions.CountryAnalyticsReferenceInfoRemovingArchive,
        },
        isDeletePermission: false,
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.getFiles();
        this.checkRemovingPermission();
      },
      async checkRemovingPermission() {
        const response = await this.checkPermission(this.permissions.removing);
        this.isDeletePermission = response.data;
      },
      async getFiles(isReset = false) {
        try {
          if (isReset) this.resetPagination();
          this.isFetchingFiles = true;

          const response = await API.getArchiveFiles({
            countryId: this.countryId,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            ...this.filters,
            fileExtensions: JSON.stringify(this.filters.fileExtensions),
          });

          this.files = [...this.files, ...response.data.data];
          this.updatePagination({
            total: response.data.total,
            offset: this.pagination.offset + this.pagination.limit,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.isFetchingFiles = false;
        }
      },
      filterHandler(filters) {
        this.filters = filters;
        this.getFiles(true);
      },
      updatePagination({ total, offset }) {
        this.pagination.total = total;
        this.pagination.offset = offset;
      },
      resetPagination() {
        this.pagination.offset = 0;
        this.files = [];
      },
      showMore() {
        this.getFiles();
      },
      async restoreFile(file, fileIndex) {
        try {
          await API.restoreFromArchive(file.id);
          this.files = this.files.filter((file, index) => index !== fileIndex);
        } catch (err) {
          console.error(err);
        }
      },
      async deleteFile(file, fileIndex) {
        try {
          await API.removeFromArchive(file.id);
          this.files = this.files.filter((file, index) => index !== fileIndex);
          this.updatePagination({
            total: this.pagination.total - 1,
            offset: this.pagination.offset - 1,
          });
        } catch (err) {
          console.error(err);
        }
      },
    },
    watch: {
      countryId() {
        this.getFiles(true);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/table/table-sort.scss';
</style>
