export default {
  methods: {
    /**
     * Метод форматирует число и приводит к ценовому формату, например 200 000,00
     * @param {Number} number - число, которое необходимо отформатировать
     * @param {String} currency - тип форматирования, который необходим (по умолчанию ru-RU)
     * @param {Number} toFixedNumber - Количество цифр после запятой, которые необходимо отобразить (по умолчанию 2)
     * @returns {String} - возвращает отформатированное число
     */
    numberFormatter(number = 0, currency = 'ru-RU', toFixedNumber = 2) {
      return new Intl.NumberFormat(currency).format(number.toFixed(toFixedNumber));
    },
  },
};
