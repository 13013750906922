<template>
  <div class="toolbar">
    <button
      v-if="hasListener('edit')"
      @click.prevent.stop="$emit('edit')"
      class="toolbar__item"
      aria-label="Редактировать"
      title="Редактировать"
      type="button"
    >
      <IconComponent name="edit" />
    </button>

    <button
      v-if="hasListener('delete')"
      @click.prevent.stop="$emit('delete')"
      class="toolbar__item toolbar__item--danger"
      aria-label="Удалить"
      title="Удалить"
      type="button"
    >
      <IconComponent name="delete-not-fill" />
    </button>
  </div>
</template>

<script>
import IconComponent from '@/common/ui/IconComponent.vue';

export default {
  name: 'Toolbar',

  emits: ['edit', 'delete'],

  components: {
    IconComponent
  },

  methods: {
    hasListener(name) {
      const eventName = 'on' + name[0].toUpperCase() + name.slice(1);
      return !!this.$.vnode.props?.[eventName];
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/global/variables';

  .toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .toolbar__item {
    all: unset;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      inset: -4px;
    }

    &:hover {
      color: $blue-2;
    }
  }

  .toolbar__item--danger {
    &:hover {
      color: $red;
    }
  }
</style>
