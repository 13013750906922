<template>
  <div class="history-table">
    <div class="table-sort table-sort_dense table-sort_non-hover">
      <div class="table-sort__head">
        <div class="table-sort__row first-head">
          <div class="table-sort__th column-user" @click="changeSorting('userOrdering')">
            <p :class="getClassSorting(filters.userOrdering)">Пользователь</p>
          </div>
          <div class="table-sort__th column-date" @click="changeSorting('dateOrdering')">
            <p :class="getClassSorting(filters.dateOrdering)">Дата изменения</p>
          </div>
          <div class="table-sort__th column-event">
            <p>Действие</p>
          </div>
        </div>
      </div>
      <div v-if="history.length" class="table-sort__body">
        <template v-for="item in history" :key="item.id">
          <div class="table-sort__row hover">
            <div class="table-sort__td column-user">
              <p>{{ item?.user?.name }}</p>
              <a :href="`mailto: ${item?.user?.email}`" class="history-table__email">{{ item?.user?.email }}</a>
            </div>
            <div class="table-sort__td column-date">
              <p>{{ $momentFormat(item.modifyDate, 'DD.MM.YYYY  HH:mm:ss') }}</p>
            </div>
            <div class="table-sort__td column-event">
              <p>{{ item.message }}</p>
            </div>
          </div>
        </template>
      </div>
      <div v-else-if="!loading" class="table-sort__body">
        <div class="table-sort__row stub">
          <p class="table-sort__td stub">По вашему запросу файлы не найдены</p>
        </div>
      </div>
      <div v-if="loading" class="history-table__loader">
        <Loader />
      </div>
    </div>

    <div v-if="getIsPaginationShowing" class="history-table__navigation">
      <ShowMoreButton classButton="text-start" is-forever-visible @onClick="$emit('showMore')"
        >Показать еще +</ShowMoreButton
      >
    </div>
  </div>
</template>

<script>
  import ShowMoreButton from '@/common/components/redesigned/ShowMoreButton.vue';
  import Constants from '@/common/constants';

  import Loader from '@/components/Loader.vue';

  export default {
    name: 'ReferenceInfoHistoryTable',
    components: { ShowMoreButton, Loader },
    emits: ['filter', 'update:files', 'showMore'],
    props: {
      history: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      pagination: {
        type: Object,
        default: () => {
          return {
            limit: 0,
            offset: 0,
            total: 0,
          };
        },
      },
    },
    data() {
      return {
        filters: {
          userOrdering: 0,
          dateOrdering: 1,
          fileExtensions: [],
          from: null,
          to: null,
        },
      };
    },
    methods: {
      getClassSorting(order) {
        if (!order) return Constants.sort.default;
        return order === 1 ? Constants.sort.ascending : Constants.sort.descending;
      },
      changeSorting(key) {
        const order = this.filters[key];
        this.resetSorting();
        this.filters[key] = order === 0 ? 1 : order === 1 ? 2 : 0;
      },
      resetSorting() {
        this.filters.userOrdering = 0;
        this.filters.dateOrdering = 0;
      },
    },
    computed: {
      getIsPaginationShowing() {
        return this.pagination.offset < this.pagination.total;
      },
    },
    watch: {
      filters: {
        handler(newValue) {
          this.$emit('filter', newValue);
        },
        deep: true,
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/table/table-sort.scss';

  .history-table {
    &__navigation {
      display: flex;
      justify-content: center;
    }

    &__loader {
      display: flex;
      justify-content: center;
    }

    .table-sort {
      .column-user {
        min-width: 260px;
        max-width: 260px;
      }

      .column-event {
        min-width: 832px;
        max-width: 832px;
      }

      .column-date {
        min-width: 140px;
        max-width: 140px;
      }

      &__td.column-user {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
</style>
