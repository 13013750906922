<template>
  <ui
    v-if="childComponents?.length > 0"
    class="trade-indicators-list"
    :class="{ 'trade-indicators-list--with-scroll': withScroll }"
  >
    <li class="trade-indicators-list__item" v-for="(item, index) in childComponents" :key="index">
      <component :is="item" />
    </li>
  </ui>
</template>

<script>
  export default {
    name: 'TradeIndicatorsList',

    props: {
      // выравнивание элемента списка по горизонтали
      // используется в scoped CSS
      // eslint-disable-next-line
      justifyContent: {
        type: String,
        validator(value) {
          return [
            'start',
            'end',
            'center',
            'space-between',
            'space-around',
            'space-evenly'
          ].includes(value);
        },
        default: 'space-between'
      },

      // значение `flex-basis` элемента списка
      // используется в scoped CSS
      // eslint-disable-next-line
      itemBaseSize: {
        type: String,
        default: '180px'
      },

      withScroll: {
        type: Boolean,
        default: true,
      }
    },

    computed: {
      childComponents() {
        return this.$slots.default()
          .filter((child) => typeof child.type !== 'symbol');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .trade-indicators-list {
    margin: 0;
    padding: 0;
    list-style-type: '';
    display: flex;
    justify-content: v-bind(justifyContent);
    gap: 24px;
  }

  .trade-indicators-list--with-scroll {
    flex-wrap: nowrap;
    overflow: auto hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .trade-indicators-list__item {
    flex: 0 0 v-bind(itemBaseSize);
    display: grid;
  }
</style>
