<template>
  <div :class="{ active: isActive }" ref="menu" v-if="items.length">
    <BurgerMenu :is-active="isActive" class="burger" @toggle="toggleMenu" />
    <!-- попросили убрать -->
    <!-- <switch-component class="switch" /> -->
    <div class="menu" v-if="isActive">
      <ul class="menu__list">
        <menu-item-component v-for="item in items" :key="item.id" :item="item" @closeMenu="closeMenu" />
      </ul>
    </div>
  </div>
</template>

<script>
  // import SwitchComponent from "../../components/buttons/switchComponent.vue";
  import api from '@/common/api/menu';

  import BurgerMenu from '@/components/buttons/burgerMenu.vue';

  import refreshAuthorizationHeader from '../axios';
  import menuItemComponent from './menuItemComponent.vue';

  export default {
    name: 'menuComponent',
    components: {
      BurgerMenu,
      menuItemComponent,
      // SwitchComponent
    },
    data() {
      return {
        isActive: false,
        items: [],
      };
    },
    created() {
      refreshAuthorizationHeader();
      this.loadMenu();
    },
    methods: {
      loadMenu() {
        api.getMenuTree().then((res) => {
          this.items = res.data;
          this.setExpanded(this.items);
        });
      },
      setExpanded(items) {
        if (items)
          items.forEach((x) => {
            this.setExpanded(x.items);
            x.expanded = x.items.some((it) => it.url?.trim() === this.$route.path.trim() || it.expanded);
          });
      },
      getIsExpanded(item) {
        let isExpanded = item.items.some((it) => it.url?.trim() === this.$route.path.trim());
        if (!isExpanded && item.items.length > 0) isExpanded = item.items.some((it) => this.getIsExpanded(it));
        return isExpanded;
      },
      toggleMenu() {
        this.isActive ? this.closeMenu() : this.openMenu();
      },
      openMenu() {
        this.isActive = true;
        document.body.classList.add('active-left-menu');
        window.addEventListener('click', this.handlerClickOutside);
      },
      closeMenu() {
        this.isActive = false;
        document.body.classList.remove('active-left-menu');
        window.removeEventListener('click', this.handlerClickOutside);
      },
      handlerClickOutside(e) {
        if (!this.$refs.menu.contains(e.target)) {
          this.closeMenu();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .menu {
    padding: 8px 16px 24px;

    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
</style>
