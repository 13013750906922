<template>
  <ReferenceInfoHistoryTable
    :history="history"
    :loading="isFetching"
    :pagination="pagination"
    @filter="filterHandler"
    @show-more="showMore"
  ></ReferenceInfoHistoryTable>
</template>

<script>
  import API from '../../../api/referenceInfo';
  import ReferenceInfoHistoryTable from '../referenceInfoHistory/ReferenceInfoHistoryTable.vue';

  export default {
    name: 'ReferenceInfoHistory',
    components: { ReferenceInfoHistoryTable },
    props: {
      countryId: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        history: [],
        isFetching: false,
        filters: {
          userOrdering: 0,
          dateOrdering: 1,
        },
        pagination: {
          limit: 10,
          offset: 0,
          total: 0,
        },
      };
    },
    created() {
      this.fetchHistory();
    },
    methods: {
      async fetchHistory(isReset = false) {
        try {
          if (isReset) this.resetPagination();

          this.isFetching = true;

          const response = await API.getHistory({
            countryId: this.countryId,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            ...this.filters,
          });

          this.history = [...this.history, ...response.data.data];
          this.updatePagination({
            total: response.data.total,
            offset: this.pagination.offset + this.pagination.limit,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.isFetching = false;
        }
      },
      resetPagination() {
        this.pagination.offset = 0;
        this.history = [];
      },
      updatePagination({ total, offset }) {
        this.pagination.total = total;
        this.pagination.offset = offset;
      },
      showMore() {
        this.fetchHistory();
      },
      filterHandler(filters) {
        this.filters = filters;
        this.fetchHistory(true);
      },
    },
    watch: {
      countryId() {
        this.fetchHistory(true);
      },
    },
  };
</script>

<style scoped lang="scss"></style>
