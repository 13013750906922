<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка статуса проекта</p>
    </div>

    <div>
      <div class="row">
        <div class="col-12">
          <div class="form-group required">
            <ComponentInput
              v-model="item.name"
              label="Наименование"
              required
              maxLength="250"
              placeholder="Введите наименование"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-12">
          <div class="form-group">
            <ComponentInput
              label="Просмотр проекта - доступно ролям"
              :select2Settings="select2Settings"
              :select2Options="roles"
              v-model="item.viewRolesIds"
              type="select2"
              placeholder="Доступно всем"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-12">
          <div class="form-group">
            <ComponentInput
              label="Редактирование проекта - доступно ролям"
              :select2Settings="select2Settings"
              :select2Options="roles"
              v-model="item.editRolesIds"
              type="select2"
              placeholder="Доступно всем"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="item.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>

    <div>
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import Api_Roles from '@/modules/admin/api/roles';

  import API from '../api/nsi';

  export default {
    name: 'MpkProjectStatusCard',
    components: {
      ComponentInput,
      ButtonStock,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        roles: [],
        select2Settings: Constants.select2SettingsMultiple,
      };
    },
    created() {
      Api_Roles.search({ pageNumber: 1, pageSize: 0 }).then((response) => {
        this.roles = response.data.items.map((x) => ({ id: x.id, text: x.title }));
      });
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find('mpkprojectstatus', this.id).then((response) => {
            this.item = response.data;
            this.item.viewRolesIds = this.item.roleCanView ? this.item.roleCanView.split(',') : [];
            this.item.editRolesIds = this.item.roleCanEdit ? this.item.roleCanEdit.split(',') : [];
          });
        } else {
          this.item = {
            isActive: true,
            viewRolesIds: [],
            editRolesIds: [],
          };
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        this.item.roleCanView = this.item.viewRolesIds.join(',');
        this.item.roleCanEdit = this.item.editRolesIds.join(',');

        API.update('mpkprojectstatus', this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              this.$router.push({ name: 'MpkProjectStatuses' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'MpkProjectStatuses' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
