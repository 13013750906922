<template>
  <div class="field" :class="{ 'field--required': required }">
    <div class="field__label" v-if="$slots.label">
      <slot name="label"></slot>
    </div>
    <div class="field__label" v-else-if="label">
      {{ label }}
    </div>
    <slot></slot>

    <div class="field__error" v-if="$slots.error">
      <slot name="error"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Field',

    props: {
      label: {
        type: String,
      },

      required: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field {}

  .field__label {
    margin-bottom: 8px;
    font: $fira-16;
  }

  .field__error {
    margin-top: 12px;
    font: $fira-12;
    color: $red;
  }

  .field--required {
    .field__label::after {
      content: ' ' '*';
      color: $red;
    }
  }
</style>
