export default {
  methods: {
    /**
     * Функция преобразования арабской цифры в римскую
     * @param {Number} number - число от 0 до 10
     * @returns {String} строку в виде римской цифры
     */
    getRomanNumber(number = 0) {
      const romanNumerals = ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
      return romanNumerals[number];
    },
  },
};
