<template>
  <div class="container-page">
    <p class="title-inner">Новость</p>
    <tabs>
      <tab name="Основная информация" :selected="true">
        <div class="row">
          <div class="form-group col-6">
            <ComponentInput v-model="card.id" label="Номер публикации" :disabled="true" />
          </div>
        </div>
        <div class="row">
          <div class="form-group required col-6">
            <ComponentInput
              v-model="card.name"
              label="Заголовок"
              required
              :max-length="255"
              placeholder="Введите заголовок новости"
            />
          </div>
          <div class="form-group required col-6">
            <ComponentInput
              v-model="card.countryIds"
              type="select2"
              :select2-options="countries"
              :select2-settings="select2SettingsMulti"
              label="Страны"
              required
              multiple
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group required col-6">
            <ComponentInput
              v-model="card.newsTypeId"
              type="select2"
              :select2-options="newsTypes"
              :select2-settings="select2Settings"
              label="Тип новости"
              required
            />
          </div>
          <div class="form-group required col-6">
            <ComponentInput
              v-model="card.entityStatusId"
              type="select2"
              :select2-options="entityStatuses"
              :select2-settings="select2Settings"
              label="Статус новости"
              :disabled="card.newsTypeId == 3"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group required col-6">
            <ComponentInput
              v-model="card.previewText"
              type="textarea"
              label="Анонс"
              required
              placeholder="Введите текст для анонса"
            />
          </div>
          <div class="form-group col-6">
            <div class="form-label">Изображение для анонса</div>
            <PictureUpload :initialPhoto="card.previewPicture" v-on:pictureSelected="pictureSelected" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <div class="form-label">Дата и время публикации</div>
            <div class="date-container">
              <DateTimeComponent v-model="card.activeFrom" :clearable="false" />
            </div>
          </div>
          <div class="form-group col-6">
            <DateComponent v-model="card.activeTo" label="Дата снятия с публикации" />
          </div>
        </div>
        <div class="form-group">
          <ComponentInput
            v-model="card.links"
            type="textarea"
            label="Ссылки на внешние источники данных"
            placeholder="Введите ссылки на внешние источники данных"
          />
        </div>
        <template v-if="Number(card.newsTypeId) === newsType.review">
          <div class="row">
            <div class="form-group col-6">
              <ComponentInput
                v-model="card.actNumber"
                label="Номер нормативно-правового акта"
                :max-length="100"
                placeholder="Введите номер нормативно-правового акта"
              />
            </div>
            <div class="form-group col-6">
              <DateComponent v-model="card.actDate" label="Дата нормативно-правового акта" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-3">
              <ComponentInput
                v-model="card.tradingOperationIds"
                type="select2"
                :select2-options="tradingOperations"
                :select2-settings="select2SettingsMulti"
                multiple
                label="Торговая операция"
              />
            </div>
            <div class="form-group col-4">
              <ComponentInput
                v-model="card.scenarioId"
                type="select2"
                :select2-options="scenarios"
                :select2-settings="select2Settings"
                label="Сценарий"
              />
            </div>
            <div class="form-group col-5" v-if="card.scenarioId && procedures.length">
              <ComponentInput
                v-model="card.procedureIds"
                type="select2"
                :select2-options="procedures"
                :select2-settings="select2SettingsMulti"
                multiple
                label="Процедуры"
              />
            </div>
          </div>
        </template>
      </tab>
      <tab name="Текст">
        <div class="form-group required">
          <!-- <editor
              api-key="no-api-key"
              v-model="card.body"
              :init="tinyOptions"
              :value="card.body"
              output-format="html"
          /> -->
          <TinyMceEditor v-model="card.body" />
        </div>
      </tab>
      <tab name="Фотогалерея">
        <FileMultiUpload
          :entity="card"
          prop-name="photos"
          :user-data="fileType.photo"
          accept="image/*"
          :key="photos"
          :show-preview="true"
          @uploaded="fileUploaded"
          @deleteFile="removeFile"
        />
      </tab>
      <tab name="Видео">
        <FileMultiUpload
          :entity="card"
          prop-name="videos"
          :user-data="fileType.video"
          accept="video/*"
          :key="videos"
          @uploaded="fileUploaded"
          @deleteFile="removeFile"
        />
      </tab>
      <tab name="Файлы">
        <FileMultiUpload
          :entity="card"
          prop-name="otherFiles"
          :user-data="fileType.other"
          :key="other"
          @uploaded="fileUploaded"
          @deleteFile="removeFile"
        />
      </tab>
    </tabs>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
      <ButtonStock v-if="isDraft" class="mr-8 btn-approval" title="Согласование" v-on:click="sendApprove" />
      <ButtonStock
        v-if="canApprove && onApproval"
        class="mr-8 btn-approval"
        title="Согласовать"
        v-on:click="approveNews"
      />
      <ButtonStock
        v-if="canApprove && onApproval"
        class="mr-8 btn-approval"
        title="Отклонить"
        v-on:click="refuseNews"
      />
      <ModalComponent title="Отклонить" @close="dialogCancel = false" v-model="dialogCancel">
        <div class="form__body cancel">
          <div class="form__body-inputBlock mb-16">
            <p>Причина</p>
            <textarea placeholder="Напишите причину отклонения" v-model="reason"></textarea>
            <div class="invalid-msg" v-if="v$.reason.$error">Поле обязательно для заполнения</div>
          </div>
          <div>
            <ButtonStock class="mr-8 mb-6" title="Подтвердить" @click="refuseReportSend" />
            <ButtonStock @click="dialogCancel = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import TinyMceEditor from '@/components/tinymceeditor';

  import ComponentInput from '../../../common/components/ComponentInput';
  import DateComponent from '../../../common/components/dateComponent';
  //import Editor from '@tinymce/tinymce-vue';
  import DateTimeComponent from '../../../common/components/dateTimeComponent';
  import FileMultiUpload from '../../../common/components/FileMultiUpload';
  import PictureUpload from '../../../common/components/PictureUpload';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import ScenarioApi from '../../navigator/api/scenario';
  import NsiApi from '../../nsi/api/nsi';
  import Api from '../api/news';

  export default {
    name: 'NewsCard',
    components: {
      DateTimeComponent,
      ModalComponent,
      PictureUpload,
      FileMultiUpload,
      DateComponent,
      ComponentInput,
      ButtonStock,
      tabs,
      tab,
      TinyMceEditor,
    },
    mixins: [security],
    data() {
      return {
        // typeId: this.$route.params.typeId,
        id: this.$route.params.id,
        card: {
          entityStatusId: Constants.entityStatus.draft,
          activeFrom: new Date(),
          files: [],
        },
        countries: [],
        newsTypes: [],
        entityStatuses: [],
        tradingOperations: [],
        procedures: [],
        scenarios: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMulti: Constants.select2SettingsMultiple,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
        newsType: Constants.newsType,
        fileType: Constants.fileType,
        photos: 1,
        videos: 2,
        other: 3,
        // tinyOptions: {
        //   height: 500,
        //   menubar: false,
        //   plugins: [
        //     'advlist autolink lists link charmap preview anchor',
        //     'searchreplace visualblocks code',
        //     'media table paste code wordcount',
        //   ],
        //   toolbar:
        //     'undo redo | formatselect | bold italic backcolor forecolor | \
        //     alignleft aligncenter alignright alignjustify | \
        //     bullist numlist outdent indent | removeformat | help',
        //   language: 'ru',
        // },
        isLoading: false,
        dialogCancel: false,
        canApprove: false,
        reason: '',
        isModified: false,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.NewsPublish).then((response) => {
        this.canApprove = response.data;
      });
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          this.isLoading = true;
          Api.find(this.id)
            .then((response) => {
              this.card = response.data;
              if (this.card.allCountries) this.card.countryIds = ['0'];
            })
            .then(() => {
              this.isLoading = false;
            });
        }
      },
      loadDictionaries() {
        Utils.loadSelectOptions('entitystatuslist', this.entityStatuses);
        NsiApi.search('nsinewstype', this.defaultRequest)
          .then((response) => {
            this.newsTypes = response.data.items.map((x) => ({ text: x.name, id: x.id }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsitradingoperation', this.defaultRequest)
          .then((response) => {
            this.tradingOperations = response.data.items.map((x) => ({ text: x.name, id: x.id }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        ScenarioApi.search(this.defaultRequest)
          .then((response) => {
            this.scenarios = response.data.items.map((x) => ({ text: x.name, id: x.id }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        this.countries = [{ text: 'Все страны', id: 0, disabled: true }];
        Utils.loadSelectOptions('countryList', this.countries, true);
      },
      save() {
        this.v$.$touch();
        if (this.v$.card.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return;
        }

        if (this.card.activeTo < this.card.activeFrom) {
          Constants.alert.fire('Ошибка', 'Дата снятия с публикации не может быть меньше даты опубликования.', 'error');
          return;
        }

        if (!this.card.body?.trim()) {
          Constants.alert.fire('Ошибка', 'Необходимо заполнить текст новости на вкладке "Текст".', 'error');
          return;
        }

        Api.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.loadCard();
              this.isModified = false;
            } else {
              this.$router.push({ name: 'News' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      pictureSelected(data) {
        this.card.previewPicture = data;
      },
      fileUploaded(e) {
        e.data.fileType = e.userData;
        this.card.files.push(e.data);
      },
      removeFile(e) {
        const file = this.card.files.find((x) => x.id === e.id);
        const index = this.card.files.indexOf(file);
        if (index > -1) {
          this.card.files.splice(index, 1);
        }
      },
      close() {
        this.$router.push({ name: 'News' });
      },
      sendApprove() {
        Api.sendApproval(this.card.id)
          .then(() => {
            Constants.alert.fire('Отправка', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Отправка на согласование', Constants.commonError, 'error');
          });
      },
      approveNews() {
        Api.approveNews(this.card.id)
          .then(() => {
            Constants.alert.fire('Согласование', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Согласование', Constants.commonError, 'error');
          });
      },
      refuseReportSend() {
        this.v$.$touch();
        if (this.v$.reason.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var data = {
          newsId: this.card.id,
          reason: this.reason,
        };

        Api.refuseNews(data)
          .then((response) => {
            console.log(response);
            Constants.alert.fire('Отклонение', Constants.commonSuccess, 'success');
            this.dialogCancel = false;
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Отклонение', Constants.commonError, 'error');
          });
      },
      refuseNews() {
        this.dialogCancel = true;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
        reason: { required },
      };
    },
    computed: {
      fileList() {
        return this.card.files.slice();
      },
      isDraft: function () {
        return this.card.id && this.card.newsTypeId == 3 && this.card.entityStatusId == 1 && !this.isModified;
      },
      onApproval: function () {
        return this.card.id && this.card.newsTypeId == 3 && this.card.entityStatusId == 4 && !this.isModified;
      },
    },
    watch: {
      card: {
        handler: function () {
          if (!this.isLoading && !this.isModified) {
            this.isModified = true;
          }
        },
        deep: true,
      },
      'card.newsTypeId': {
        handler: function () {
          if (!this.isLoading) {
            this.card.entityStatusId = Constants.entityStatus.draft;
          }
        },
      },
      fileList: {
        handler: function () {
          this.card.photos = this.card.files.filter((f) => f.fileType === Constants.fileType.photo);
          this.card.videos = this.card.files.filter((f) => f.fileType === Constants.fileType.video);
          this.card.otherFiles = this.card.files.filter((f) => f.fileType === Constants.fileType.other);
          const now = new Date().getTime();
          this.photos += now;
          this.videos += now;
          this.other += now;
        },
      },
      'card.scenarioId': {
        handler: function (value) {
          this.procedures = [];
          if (!value) return;
          const request = Object.assign({ scenarioId: value, isTemplate: false }, this.defaultRequest);
          ScenarioApi.getElemList('procedure', request)
            .then((response) => {
              this.procedures = response.data.items.map((x) => ({ text: x.name, id: x.id }));
            })
            .catch((error) => {
              console.error(error?.response?.data);
            });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .date {
    &-container {
      display: flex;
      align-items: center;
    }
  }

  .btn-approval {
    margin-left: 5px;
  }
</style>
