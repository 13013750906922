<template>
  <div class="tecRussia-container mt-4">
    <InternationalTradeRussiaEconRev :notForTecRussiaTab="true" :country-id="countryId" @yearChanged="onYearChanged" />

    <!-- <div v-if="visibleIndicatorList && visibleIndicatorList.length > 0">
      <p class="mt-2 text-align-right">Источник: Банк России</p>
    </div> -->

    <!-- инвестиции -->
    <div class="economicsTypeBlock-new mb-59">
      <p class="economicsTypeBlock-new-title">Инвестиции</p>
      <ul class="indicators__list align-center mt-8">
        <li v-for="item in visibleIndicatorList" :key="item.id" class="indicators__itemCustom">
          <p class="text-align-left fs14 fw600 mb-6">{{ item.title }}</p>
          <template v-if="item.value">
            <div class="d-flex align-items-center">
              <strong class="fs22 fw700 mr-8">{{ item.value == '0,00' ? '-' : item.value }} </strong>
              <div>
                <div class="fs12">млн $ США</div>
                <div v-if="item.period" class="analytics-source-text fs10">Период: {{ item.period }}</div>
              </div>
            </div>
            <div class="analytics-source-text" v-if="item.place">
              <template v-if="item.value != '0,00'"> {{ item.place }} место </template>
            </div>
            <div
              class="analytics-source-text"
              v-if="item.percent"
              :class="[{ 'text-green': item.percent >= 0 }, { 'text-red': item.percent < 0 }]"
            >
              {{ item.percent.toFixed(2) }}%
            </div>
          </template>
          <template v-else-if="item.place">
            <strong>{{ item.place }} место</strong>
          </template>
        </li>
      </ul>
    </div>
    <div class="globalTrade-container">
      <InternationalTradeEconRev :country-id="countryId" :notForTecRussiaTab="true" />
      <InvestmentsEconRev :country-id="countryId" :notForTecRussiaTab="true" />
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import Api from '@/modules/countryAnalyticsNew/api/economicsReview.js';
  import InternationalTradeEconRev from '@/modules/countryAnalyticsNew/components/InternationalTrade/InternationalTradeEconRev.vue';
  import InternationalTradeRussiaEconRev from '@/modules/countryAnalyticsNew/components/InternationalTrade/InternationalTradeRussiaEconRev.vue';
  import InvestmentsEconRev from '@/modules/countryAnalyticsNew/components/Investments/investmentsEconRev';

  export default {
    name: 'TecRussia',
    mixins: [text],
    components: {
      InternationalTradeRussiaEconRev,
      InternationalTradeEconRev,
      InvestmentsEconRev,
    },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        year: new Date().getFullYear() - 1,
        indicatorsList: [],
        loading: false,
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        Api.getSummaryRussia(this.year, this.countryId).then((response) => {
          this.indicatorsList = [];
          console.log(response.data);
          this.updateIndicators(response.data);
          console.log(this.indicatorsList);
          this.loading = false;
        });
      },
      updateIndicators(summary) {
        this.indicatorsList = [
          {
            id: 8,
            title: 'Прямые поступившие в Россию',
            period: this.year + ' год',
            value: summary?.inFlows ? this.formatNumber(summary.inFlows, 0) : '',
          },
          {
            id: 9,
            title: 'Прямые накопленные в Россию',
            period: this.year + ' год',
            value: summary?.inFlowsCumulative ? this.formatNumber(summary.inFlowsCumulative, 0) : '',
          },
          {
            id: 10,
            title: 'Прямые поступившие из России',
            period: this.year + ' год',
            value: summary?.outFlows ? this.formatNumber(summary.outFlows, 0) : '',
          },
          {
            id: 11,
            title: 'Прямые накопленные из России',
            period: this.year + ' год',
            value: summary?.outFlowsCumulative ? this.formatNumber(summary.outFlowsCumulative, 0) : '',
          },
        ];
      },
      onYearChanged(year) {
        this.year = year;
        this.loadData();
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    computed: {
      visibleIndicatorList() {
        return this.indicatorsList.filter((x) => x.value || x.place || x.percent);
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators {
    &__list {
      display: flex;
      flex-wrap: wrap;

      gap: 36px 120px;
      @media (max-width: 1150px) {
        gap: 36px;
        justify-content: center !important;
      }
      @media (max-width: 892px) {
        justify-content: space-between !important;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 180px;
      max-width: 180px;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      border: 1px solid #06a0d8a9;
      p {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }
      strong {
        margin-top: 20px;
        margin-bottom: 9px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
      span {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 113.9%;
    color: #000000;
  }

  .indicators__item .analytics-source-text {
    font-size: 10px;
    text-align: center;
  }

  .indicator-round {
    span {
      background-color: #01a39d;
      width: 14px;
      height: 14px;
    }
    p {
      margin-left: 13px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  .indicator-round:nth-child(2) {
    span {
      background-color: #787f8f;
    }
  }
  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }
  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }
</style>

<style lang="scss">
  .tecRussia-container {
    .notForTecRussiaTab {
      h2,
      .indicators__item .btn-default {
        display: none !important;
      }
      .indicators__wrapper {
        margin-bottom: 17px;
      }
    }
  }
  .globalTrade-container {
    .investments {
      .indicators__list {
        margin-top: 8px !important;
        gap: 32px 100px;
        justify-content: space-between;
      }
      h3 {
        display: none !important;
      }
    }
    .notForTecRussiaTab .indicators-two {
      display: none !important;
    }
  }
  .indicators__itemCustom {
    min-width: 150px;
  }
</style>
