<template>
  <div class="integration-table table-container mt-5 js-table-arrow">
    <table class="stockTable">
      <thead>
        <tr>
          <th class="fs-14" rowspan="2">Наименование</th>
          <th colspan="2" class="integration-table__name fs-14">
            {{ name }}
          </th>
        </tr>
        <tr>
          <th class="integration-table__period">
            {{ dividerLabel != '' ? 'Текущий период,' : 'Текущий период' }}
            {{ dividerLabel }}
          </th>
          <th>
            {{ dividerLabel != '' ? 'Прошедший период,' : 'Прошедший период' }}
            {{ dividerLabel }}
          </th>
        </tr>
      </thead>
      <tbody v-if="currentSummary">
        <tr class="integration-table__summ">
          <td class="font-weight-bold">Всего</td>
          <td class="font-weight-bold">
            {{ formatNumber(currentSummary[exportGroups] / 1000, 1) }}
          </td>
          <td class="font-weight-bold">
            {{ formatNumber(currentSummary[exportGroupsPrevious] / 1000, 1) }}
          </td>
        </tr>
        <tr v-for="item in currentSummary[topExportGroups]" :key="item.id">
          <td class="font-weight-bold">{{ item.title }}</td>
          <td class="font-weight-bold">
            {{ formatNumber(item.currentValue / 1000, 1) }}
          </td>
          <td class="font-weight-bold">
            {{ formatNumber(item.previousValue / 1000, 1) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  export default {
    name: 'InternationalTradeExportTable',
    mixins: [text],
    props: {
      name: {
        type: String,
        default: '',
      },
      dividerLabel: {
        type: String,
        default: 'млн $',
      },
      currentSummary: {
        type: Object,
        default: () => {},
      },
      exportGroups: {
        type: String,
        default: '',
      },
      exportGroupsPrevious: {
        type: String,
        default: '',
      },
      topExportGroups: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .integration-table {
    &__name {
      text-align: center;

      border-bottom: none !important;
    }
    &__period {
      text-align: center;
    }
    &__summ {
      background-color: #ade0ff;
    }
  }
  .fs-14 {
    font-size: 14px !important;
  }
</style>
