<template>
  <div class="reference-info-list">
    <div class="reference-info-list__body">
      <div v-permission="permissions.addition" class="reference-info-list__buttons">
        <ButtonComponent @click="isAdditionFileMode = true" mod="gradient-bg">Добавить файл</ButtonComponent>
        <ButtonComponent @click="isAdditionFileWithCountryMode = true" mod="gradient-bg"
          >Загрузить по другой стране</ButtonComponent
        >
      </div>

      <CheckboxInput
        v-permission="permissions.hiding"
        v-model="isShowingHidden"
        text="Показать скрытые"
        @update:model-value="getFiles(true)"
      ></CheckboxInput>

      <ReferenceInfoTable
        :files="files"
        :is-hidden-files-showing="isShowingHidden"
        :loading="isFetchingFiles"
        :country-id="countryId"
        :pagination="pagination"
        :is-delete-event-showing="isDeletePermission"
        @filter="filterHandler"
        @show-more="showMore"
        @delete="deleteFile"
      >
        <template #fileEvents="{ file, index, hidden }">
          <IconComponent
            v-permission="permissions.hiding"
            class="icon--full-svg"
            @click="toggleShowingFile(file, index, hidden)"
            :name="hidden ? 'eyeCrossedOut' : 'eye'"
          />
          <IconComponent
            v-permission="permissions.editing"
            class="icon--full-svg"
            @click="setEditingMode(index)"
            name="edit"
          />
        </template>
      </ReferenceInfoTable>
    </div>
  </div>

  <ReferenceInfoAddition
    v-model:is-showing="isAdditionFileMode"
    :country-id="countryId"
    @add="addFile"
  ></ReferenceInfoAddition>

  <ReferenceInfoAddition
    v-model:is-showing="isAdditionFileWithCountryMode"
    @add="addFileWithCountry"
  ></ReferenceInfoAddition>

  <ReferenceInfoTableEditing
    :file="getEditingFile"
    v-model:is-showing="isEditingMode"
    @update:file="editFile"
  ></ReferenceInfoTableEditing>
</template>

<script>
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import CheckboxInput from '@/common/components/redesigned/CheckboxInput.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import API from '../../api/referenceInfo';
  import { REFERENCE_FILE_STATUS } from '../../utils/constants';
  import ReferenceInfoAddition from './ReferenceInfoAddition.vue';
  import ReferenceInfoTable from './referenceInfoTable/ReferenceInfoTable.vue';
  import ReferenceInfoTableEditing from './referenceInfoTable/ReferenceInfoTableEditing.vue';

  export default {
    name: 'ReferenceInfoList',
    components: {
      ReferenceInfoTableEditing,
      IconComponent,
      CheckboxInput,
      ButtonComponent,
      ReferenceInfoAddition,
      ReferenceInfoTable,
    },
    mixins: [security],
    props: {
      countryId: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        files: [],
        isAdditionFileMode: false,
        isAdditionFileWithCountryMode: false,
        isFetchingFiles: false,
        isEditingMode: false,
        filters: {
          orderByName: 0,
          orderByDat: 1,
          fileExtensions: [],
          from: null,
          to: null,
        },
        pagination: {
          limit: 10,
          offset: 0,
          total: 0,
        },
        isShowingHidden: true,
        editingFileIndex: 0,
        permissions: {
          editing: Constants.Permissions.CountryAnalyticsReferenceInfoChangingName,
          removing: Constants.Permissions.CountryAnalyticsReferenceInfoMovingToArchive,
          hiding: Constants.Permissions.CountryAnalyticsReferenceInfoChangingVisibility,
          addition: Constants.Permissions.CountryAnalyticsReferenceInfoAdditionFile,
        },
        isDeletePermission: false,
      };
    },
    computed: {
      getEditingFile() {
        return this.files.length ? this.files[this.editingFileIndex] : null;
      },
    },
    async created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.getFiles();
        this.checkRemovingPermission();
      },
      async checkRemovingPermission() {
        const response = await this.checkPermission(this.permissions.removing);
        this.isDeletePermission = response.data;
      },
      async getFiles(isReset = false) {
        try {
          if (isReset) this.resetPagination();
          this.isFetchingFiles = true;

          const response = await API.getFiles({
            countryId: this.countryId,
            isShowingHidden: this.isShowingHidden,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            ...this.filters,
            fileExtensions: JSON.stringify(this.filters.fileExtensions),
          });

          this.files = [...this.files, ...response.data.data];
          this.updatePagination({
            total: response.data.total,
            offset: this.pagination.offset + this.pagination.limit,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.isFetchingFiles = false;
        }
      },
      filterHandler(filters) {
        this.filters = filters;
        this.getFiles(true);
      },
      updatePagination({ total, offset }) {
        this.pagination.total = total;
        this.pagination.offset = offset;
      },
      resetPagination() {
        this.pagination.offset = 0;
        this.files = [];
      },
      showMore() {
        this.getFiles();
      },
      addFile(file) {
        this.files = [file, ...this.files];
        this.isAdditionFileMode = false;
      },
      addFileWithCountry(file) {
        if (file.countryId === parseInt(this.countryId)) this.files = [file, ...this.files];
        this.isAdditionFileWithCountryMode = false;
      },
      setEditingMode(fileIndex) {
        this.isEditingMode = true;
        this.editingFileIndex = fileIndex;
      },
      async toggleShowingFile(file, index, isHiddenStatus) {
        try {
          const status = isHiddenStatus ? REFERENCE_FILE_STATUS.VISIBLE : REFERENCE_FILE_STATUS.HIDDEN;
          await API.changeVisibility({
            id: file.id,
            status,
          });
          file.status = status;
          this.updateFile(file, index);
        } catch (err) {
          console.error(err);
        }
      },
      editFile(file) {
        this.updateFile(file, this.editingFileIndex);
        this.isEditingMode = false;
      },
      updateFile(file, index) {
        this.files = [...this.files.slice(0, index), file, ...this.files.slice(index + 1)];
      },
      async deleteFile(file, fileIndex) {
        try {
          await API.moveToArchive(file.id);
          this.files = this.files.filter((file, index) => index !== fileIndex);
          this.updatePagination({
            total: this.pagination.total - 1,
            offset: this.pagination.offset - 1,
          });
        } catch (err) {
          console.error(err);
        }
      },
    },
    watch: {
      countryId() {
        this.getFiles(true);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/table/table-sort.scss';

  .reference-info-list {
    &__body {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media (max-width: $mobile-2) {
        gap: 24px;
      }
    }

    &__buttons {
      display: flex;
      gap: 8px;

      @media (max-width: $mobile-2) {
        flex-direction: column;
      }
    }
  }
</style>
