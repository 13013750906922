import baseVES from './base';
import economicRelations from './economicRelations';
import eventsVES from './events';
import implementation from './implementation';

export default {
  baseVES,
  economicRelations,
  eventsVES,
  implementation,
};
