<template>
  <li v-if="menuItem" class="menu__item">
    <div v-if="menuItem.items && menuItem.items.length > 0">
      <div class="menu__root-item" @click="menuItem.expanded = !menuItem.expanded">
        <span class="collapse-icon" :class="{ 'collapse-icon_expanded': menuItem.expanded }"></span>
        <span class="menu__root-item-name">{{ menuItem.name }}</span>
      </div>
      <div class="menu__sub-items" v-show="menuItem.expanded">
        <ul>
          <menu-item-component
            v-for="subItem in menuItem.items"
            :key="subItem.id"
            :item="subItem"
            @closeMenu="handlerClickMenuLink"
          />
        </ul>
        <!-- <router-link
        :class="{'active-link': subItem.url?.trim() == $route.path?.trim()}"
        v-for="subItem in item.items" :key="subItem.id" :to="subItem.url.trim()">{{subItem.name}}</router-link> -->
      </div>
    </div>
    <router-link
      v-else
      class="menu__root-item-link"
      :class="{ 'active-link': menuItem.url?.trim() === $route.path?.trim() }"
      :to="menuItem.url.trim()"
      @click="handlerClickMenuLink()"
    >
      {{ menuItem.name }}
    </router-link>
  </li>
</template>

<script>
  export default {
    name: 'menuItemComponent',
    components: {},
    props: {
      item: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        menuItem: this.item,
      };
    },
    methods: {
      handlerClickMenuLink() {
        this.$emit('close-menu');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/colors.scss';

  .menu {
    &__item {
      cursor: pointer;

      .menu__sub-items {
        padding-left: 16px;
        padding-top: 8px;

        & ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
      }

      .collapse-icon {
        position: relative;
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 12L10 15.4641L10 8.5359L16 12Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        transform-origin: center;
        transition: transform ease 0.3s;

        &_expanded {
          transform: rotate(90deg);
        }
      }

      .menu__root-item {
        display: flex;

        &-link {
          margin-left: 16px;
          display: inline-block;
          line-height: 1.25;
        }
      }

      .active-link {
        color: #acdae8;
      }
    }

    &__btns {
      position: absolute;
      margin-top: 45px;
      margin-left: 25px;
    }

    & * {
      font-family: 'Fira Sans', sans-serif;
      color: $white;
    }
  }
</style>
