<template>
  <div class="indicator">
    <p>{{ name }}</p>

    <div class="indicator__wrapper">
      <i v-if="currentTurnoverSummaryAvailable" class="fas fa-solid mr-1" :class="iconClass"></i>
      <i v-else class="fas fa-solid mr-1" :class="productModeIconClass"></i>
      <strong v-if="currentTurnoverSummaryAvailable">
        {{ resolvedIndicatorValue }}
      </strong>
      <strong v-else-if="currentSummary">
        {{ resolvedCurrentSummaryIndicatorValue }}
      </strong>
      <strong v-else>-</strong>
      <div>
        <span v-if="currentTurnoverSummaryAvailable && currentTurnoverSummary?.[previousPercent]" :class="percentClass">
          {{ formatNumber(currentTurnoverSummary?.[previousPercent]) }}%
          <span class="text-black" style="display: none">г/г</span>
        </span>
        <span
          v-else-if="currentTurnoverSummaryAvailable == false || currentTurnoverSummary?.[previousPercent] == 0"
          class="text-red"
        >
          -
        </span>
        <span class="d-block">{{ dividerLabel }} $</span>
      </div>
    </div>
    <button class="btn-default indicator__more-btn" v-if="turnoverMoreInfo">
      <IconComponent name="chartIcon" />Больше информации
    </button>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent';

  import { DIVIDER_LABEL } from '../../../utils/constants';

  export default {
    name: 'InternationalKeyWorldTradeIndicator',
    mixins: [text],
    components: { IconComponent },
    props: {
      name: {
        type: String,
        default: '',
      },
      currentSummary: {
        type: Object,
        default: () => {},
      },
      currentTurnoverSummary: {
        type: Object,
        default: () => {},
      },
      indicatorValue: {
        type: String,
        default: '',
      },
      previousPercent: {
        type: String,
        default: '',
      },
      turnoverMoreInfo: {
        type: Boolean,
        default: true,
      },
      isProductMode: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        dividerLabel: DIVIDER_LABEL,
      };
    },
    computed: {
      resolvedIndicatorValue() {
        return this.formatNumber(this.currentTurnoverSummary[this.indicatorValue] / 1000000, 0) == '0,00'
          ? '-'
          : this.formatNumber(this.currentTurnoverSummary[this.indicatorValue] / 1000000, 0);
      },

      currentTurnoverSummaryAvailable() {
        return this.currentTurnoverSummary && this.currentTurnoverSummary?.totalValuesByYears?.length > 0;
      },
      resolvedCurrentSummaryIndicatorValue() {
        return this.formatNumber(this.currentSummary[this.indicatorValue] / 1000000, 0) == '0,00'
          ? '-'
          : this.formatNumber(this.currentSummary[this.indicatorValue] / 1000000, 0);
      },
      iconClass() {
        return [
          {
            'text-red fa-arrow-down': this.currentTurnoverSummary?.[this.previousPercent] < 0,
          },

          {
            'fa-arrow-up text-green': this.currentTurnoverSummary?.[this.previousPercent] > 0,
          },
        ];
      },
      productModeIconClass() {
        return [
          { 'text-red back-red fa-arrow-down': this.isProductMode },
          { 'fa-arrow-up text-green back-green': !this.isProductMode },
        ];
      },
      percentClass() {
        return [
          { 'text-red': this.currentTurnoverSummary?.[this.previousPercent] < 0 },
          {
            'text-green': this.currentTurnoverSummary?.[this.previousPercent] > 0,
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .indicator {
    &__wrapper {
      transition: 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      strong {
        display: block;
        margin-right: 10px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        white-space: nowrap;
      }
      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #787f8f;
      }
    }
    p {
      font: $fira-12;
      font-style: normal;
      color: #333333;
      width: 160px;
    }
    &__more-btn {
      .icon {
        margin-right: 8px;
      }
      max-width: 100px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 113.9%;
      text-align: center;
      color: #787f8f;
      display: flex;
      align-items: center;
      margin: -8px auto auto auto;
      padding: 8px 16px;
      border: none;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
    }
  }

  .indicators__item {
    .indicator__wrapper {
      padding: 20px 10px;
    }
    &:hover,
    &.active {
      .indicator {
        .indicator__wrapper {
          background: #ffffff;
          box-shadow: 0px 0px 20px rgba(30, 152, 233, 0.936);
          border-radius: 5px;
        }
      }
    }
  }
</style>
