import { COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME, COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getCurrentBalance(context) {
  if (!context.state?.currentBalance?.length) {
    return await request.call(this, context, {
      url: links.countryAnalytics.payments.currentBalance,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.setCurrentBalanceList,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getCurrentBalance,
    });
  }
}

export async function getFinancialBalance(context) {
  if (!context.state?.financialBalance?.length) {
    return await request.call(this, context, {
      url: links.countryAnalytics.payments.financialBalance,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.setFinancialBalanceList,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.setFinancialBalanceList,
    });
  }
}

export async function getInfoCurrentBalance(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.payments.infoCurrentBalance,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.updateInfoCurrentBalance,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoCurrentBalance,
    },
    params,
  );
}

export async function getInfoFinancialBalance(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.payments.infoFinancialBalance,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.updateInfoFinancialBalance,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoFinancialBalance,
    },
    params,
  );
}

export async function getInfoReserveAssetBalance(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.payments.infoReserveAssetBalance,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.updateInfoReserveAssetBalance,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoReserveAssetBalance,
    },
    params,
  );
}

export async function getInfoPureErrorsAndOmissions(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.payments.infoPureErrorsAndOmissions,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.updateInfoPureErrorsAndOmissions,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoPureErrorsAndOmissions,
    },
    params,
  );
}

export async function fetchYearList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.payments.yearList,
      mutate: COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.setYearList,
      name: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME,
    },
    params,
  );
}
