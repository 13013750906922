import { authAxios } from '@/common/axios';

export default {
  search: function (request) {
    return authAxios.get('/api/country/search', { params: request });
  },
  find: function (id) {
    return authAxios.get(`/api/country/${id}`);
  },
  update: function (data) {
    return authAxios.put(`/api/country/${data.id}`, data);
  },
  getList: function () {
    return authAxios.get('/api/country/getlist');
  },
  getMembership: function (id) {
    return authAxios.get(`/api/country/membership/${id}`);
  },
  findShort: function (id) {
    return authAxios.get(`/api/country/findShort/${id}`);
  },
};
