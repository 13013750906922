<template>
  <div class="container-page">
    <div class="activity-page edit">
      <tabs>
        <tab name="Общая информация" :selected="true">
          <div class="tabs-inner_container">
            <div class="main-info card">
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Название</p>
                  <ComponentInput placeholder="Введите название" v-model="item.name" required v-if="isCreatorOpen" />
                  <div v-else>{{ item.name }}</div>
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Тип задачи</p>
                  <ComponentInput
                    placeholder="Укажите тип"
                    :select2Settings="select2Settings"
                    :select2Options="taskTypeList"
                    v-model="item.taskTypeId"
                    type="select2"
                    required
                    v-if="isCreatorOpen"
                  />
                  <div v-else>{{ item.taskType?.name }}</div>
                </div>
              </div>

              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Исполнитель</p>
                  <ComponentInput
                    placeholder="Укажите исполнителя"
                    :select2Settings="select2Settings"
                    :select2Options="employeeList"
                    v-model="item.executorId"
                    type="select2"
                    required
                    v-if="isCreatorOpen"
                  />
                  <div v-else>{{ item.executorFio }}</div>
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Срочность</p>
                  <ComponentInput
                    placeholder="Укажите срочность"
                    :select2Settings="select2Settings"
                    :select2Options="taskUrgencyList"
                    v-model="item.taskUrgencyId"
                    type="select2"
                    required
                    v-if="isCreatorOpen"
                  />
                  <div v-else>{{ item.taskUrgency?.name }}</div>
                </div>
              </div>

              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Cоисполнитель</p>
                  <ComponentInput
                    placeholder="Укажите соисполнителей"
                    :select2Settings="{ multiple: true }"
                    :select2Options="employeeList"
                    v-model="item.issueExecutorsIds"
                    type="select2"
                    :multiple="true"
                    v-if="isCreatorOpen"
                  />
                  <div v-else>{{ item.executorDopFio }}</div>
                </div>
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Контрольный срок</p>
                  <dateTimeComponent v-model="item.deadline" v-if="isCreatorOpen" />
                  <div v-else>{{ $momentFormat(item.deadline, 'DD MMMM YYYY HH:mm') }}</div>
                </div>
              </div>

              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Описание задачи</p>
                  <ComponentInput
                    placeholder="Введите описание"
                    v-model="item.description"
                    type="textarea"
                    required
                    v-if="isCreatorOpen"
                  />
                  <div v-html="formatText(item.description)" v-else></div>
                </div>
                <div class="card-row_col">
                  <p class="title-row">Инициатор</p>
                  <div>
                    {{ item.managerFio }}
                    <p class="date">{{ $momentFormat(item.created, 'DD MMMM YYYY') }}</p>
                  </div>
                </div>
              </div>

              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Статус задачи</p>
                  <ComponentInput
                    placeholder="Укажите статус"
                    :select2Settings="select2Settings"
                    :select2Options="taskStatusList"
                    v-model="item.taskStatusId"
                    type="select2"
                    required
                    v-if="isCreatorOpen"
                  />
                  <div v-else>{{ item.taskStatus?.name }}</div>
                </div>
                <div class="card-row_col">
                  <template v-if="isExecutorOpen && !(isCreatorOpen && item.taskStatusId == 4)">
                    <p class="title-row">Перевод статуса</p>
                    <div v-if="item.taskStatusId < 5">
                      <ButtonStock
                        class="mr-4"
                        v-if="item.taskStatusId != 3"
                        title="В работе"
                        v-on:click="changeStatus(3, 'В работе')"
                      />
                      <ButtonStock
                        v-if="item.taskStatusId != 4"
                        title="Согласование"
                        v-on:click="changeStatus(4, 'Согласование')"
                      />
                    </div>
                  </template>
                  <template v-if="isCreatorOpen && item.taskStatusId == 4">
                    <p class="title-row">Перевод статуса</p>
                    <div>
                      <ButtonStock class="mr-4" title="Согласовать" v-on:click="changeStatus(5, 'Готова')" />
                      <ButtonStock class="mr-4" title="Отклонить" v-on:click="changeStatus(3, 'В работе')" />
                    </div>
                  </template>
                </div>
              </div>

              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <div class="card-row_col"></div>
                  <p class="title-row">Отчет об исполнении</p>
                  <div>
                    {{ item.comment }}
                    <div class="reportFiles">
                      <ButtonStock
                        :title="file.originalName"
                        class="reportFiles__file"
                        btnType="fileIcon"
                        iconName="fileText"
                        mode="icon"
                        v-for="file in item.reportFiles"
                        :key="file"
                        @click="downloadFile(file)"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-row_col">
                  <p class="title-row">Заполнение отчета</p>
                  <div class="checkboxInput">
                    <input type="checkbox" v-model="item.needReport" id="chbx_needReport" :disabled="!isCreatorOpen" />
                    <label for="chbx_needReport" class="form-check-label form-label">Обязателен отчет</label>
                  </div>
                </div>
              </div>
              <ButtonStock :title="getNameReportPopup" class="card-reportButton" @click="showReport()" />
            </div>
          </div>
        </tab>
        <tab name="Документы">
          <div class="tabs-inner_container">
            <div class="main-info card">
              <!-- row -->
              <div class="card-row">
                <!-- col -->
                <div class="card-row_col">
                  <p class="title-row">Документы</p>
                  <FileMultiUpload
                    :entity="item"
                    @deleteFile="removeFile"
                    prop-name="files"
                    @uploaded="fileUploaded"
                    :readonly="isReadonly"
                  />
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
      <div class="card-info__row btns">
        <ButtonStock title="Сохранить" v-on:click="save" />
        <ButtonStock btnType="secondary" title="Отмена" v-on:click="close" />
      </div>
    </div>
  </div>

  <ModalComponent
    :title="getNameReportPopup"
    v-model="reportShow"
    :themeLigth="true"
    :width="848"
    content-class="modal--new"
    @close="closeReport()"
  >
    <ReportForm :data="getReport" @back="closeReport()" @save="saveReport" />
  </ModalComponent>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateTimeComponent from '@/common/components/dateTimeComponent.vue';
  import FileMultiUpload from '@/common/components/FileMultiUpload';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  // Additional
  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/issue';
  import ReportForm from '../components/ReportForm.vue';

  export default {
    name: 'IssueCard',
    components: {
      ModalComponent,
      ButtonStock,
      dateTimeComponent,
      ComponentInput,
      FileMultiUpload,
      tabs,
      tab,
      ReportForm,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        isReadonly: this.$route.params.action === 'view',
        isCreatorOpen: false,
        isExecutorOpen: false,

        item: {},
        select2Settings: Constants.select2Settings,
        taskTypeList: [],
        taskUrgencyList: [],
        employeeList: [],
        taskStatusList: [],
        reportShow: false,
      };
    },
    created() {
      this.checkPermissions([Constants.Permissions.MpkTaskEdit, Constants.Permissions.MpkTaskAccess]);
      this.loadCard();

      Utils.loadSelectOptions('taskTypeList', this.taskTypeList);
      Utils.loadSelectOptions('taskUrgencyList', this.taskUrgencyList);
      //Utils.loadSelectOptions("taskStatusList", this.taskStatusList);
      Utils.searchNsi('taskStatusList', {}).then((response) => {
        this.taskStatusList = response.data.slice(1);
      });
      this.loadEmployee();
    },
    computed: {
      getReport() {
        return {
          text: this.item?.comment,
          files: this.item?.reportFiles,
        };
      },
      getNameReportPopup() {
        return this.item?.comment ? 'Редактировать отчёт' : 'Внести отчёт';
      },
    },
    methods: {
      loadEmployee() {
        API.getEmployeeList().then((response) => {
          if (response.data) {
            response.data.forEach((el) => {
              this.employeeList.push(el);
            });
          }
        });
      },
      changeStatus(statusId, statusName) {
        if (statusId >= 3 && this.item.needReport && !this.item.comment) {
          Constants.alert.fire('Ошибка', 'Отчет не заполнен', 'error');
          return;
        }
        this.item.taskStatusId = statusId;
        this.item.taskStatus.name = statusName;
      },
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
            let profile = this.$store.state.auth?.oidc?.profile;
            if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
            const currEmployeeId = profile?.employee?.id;
            this.isCreatorOpen = this.item.createdById == profile?.id;
            this.isExecutorOpen =
              this.item.executorId == currEmployeeId || this.item.issueExecutorsIds.includes(currEmployeeId);
          });
        } else {
          this.close();
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        if (this.item.taskStatusId >= 3 && this.item.needReport && !this.item.comment) {
          Constants.alert.fire('Ошибка', 'Отчет не заполнен', 'error');
          return;
        }

        const itemToSave = Object.assign({}, this.item);
        itemToSave.taskStatus = null;
        itemToSave.taskUrgency = null;
        itemToSave.taskType = null;
        itemToSave.issueDocumentsIds = itemToSave.files.map((x) => x.id);
        itemToSave.files = null;

        API.update(itemToSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'Issues' });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Issues' });
      },
      fileUploaded(e) {
        this.item.files.push(e.data);
      },
      removeFile(e) {
        const file = this.item.files.find((x) => x.id == e.id);
        const index = this.item.files.indexOf(file);
        if (index !== -1) {
          this.item.files.splice(index, 1);
        }
      },

      downloadFile(file) {
        Utils.downloadFile(`/api/file/download?id=${file.id}`, file.originalName);
      },

      saveReport(data) {
        let saveData = data;
        saveData.id = this.item.id;
        saveData.ids = saveData.files.map((x) => x.id);
        API.saveReport(saveData).then(() => {
          Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          this.closeReport();
        });
      },

      showReport() {
        this.reportShow = true;
      },

      closeReport() {
        this.reportShow = false;
      },

      formatText(text) {
        if (text) {
          return text.replace(/\n/g, '<br>');
        }
        return '';
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .form {
    &-label {
      font-weight: 400 !important;
      font-size: 14px;
      color: #231f20;
      line-height: 160%;
    }

    &-group-checkboxes {
      display: flex;
      flex-wrap: wrap;

      .checkboxInput {
        &:not(:last-of-type) {
          margin-right: 34px;
          margin-bottom: 34px;
        }
      }

      .form-check-input {
        left: 30px;
        top: 10px;
      }
    }
  }

  .date {
    &-container {
      display: flex;
      align-items: center;
    }
  }

  .card {
    padding: 32px;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);

    &-row {
      display: flex;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #d3dfe6;

      &:not(&.btns) {
        justify-content: space-between;
      }

      textarea {
        width: 100%;
        min-height: 82px;
      }

      p {
        font-family: 'Fira Sans';
        font-size: 14px;
        line-height: 150%;
        color: #231f20;
      }

      .title-row {
        font-weight: 500;
        margin-bottom: 8px;
      }

      &:last-of-type {
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: none;
      }

      &_col {
        width: 60%;

        &:last-of-type {
          width: 35%;
        }
      }

      &.btns {
        button {
          margin-right: 16px;
        }

        &:last-of-type {
          justify-content: flex-end;
        }
      }
    }

    .reportFiles {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 16px 0 0 0;

      &__file {
        display: flex;
        color: $blue-2;
        font: $fira-16;
        line-height: normal;
        align-items: flex-start;

        &__icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    &-reportButton {
      align-self: flex-start;
      margin: 20px 0 0 0;
    }
  }

  @media (max-width: 767px) {
    .card {
      padding: 32px 12px;
    }

    .card-row {
      flex-wrap: wrap;
    }

    .card-row_col {
      margin-bottom: 16px;
    }

    .card-row_col:last-of-type {
      margin-bottom: 0px;
    }

    .card-row_col,
    .card-row_col:last-of-type {
      width: 100%;
    }
  }
</style>
