<template>
  <div>
    <component :is="layoutComponentName">
      <router-view />
    </component>
  </div>
</template>

<script>
  import DashboardLayout from '@/common/ui/layout/DashboardLayout';

  import auditPage from '@/modules/admin/api/auditPage';

  export default {
    name: 'App',
    components: {
      DashboardLayout,
    },
    computed: {
      layoutComponentName() {
        return 'DashboardLayout';
      },
    },
    data() {
      return {};
    },
    created() {
      this.$store.dispatch('auth/oidc/loadUser');
    },
    methods: {},
    watch: {
      $route: function (to) {
        if (to.matched && to.matched.length > 0) {
          let request = {
            url: window.location.href,
            isInternal: true,
            routeName: to.name,
          };
          auditPage.visitPage(request);
        }
      },
    },
  };
</script>
