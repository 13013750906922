import axios from 'axios';

export default {
  async search() {
    return await axios.get('api/settings/search');
  },
  async saveSettings(items) {
    return await axios.put('api/settings/savesettings', items);
  },
};
