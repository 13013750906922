<template>
  <span>{{ value ? 'действ.' : 'не действ.' }}</span>
</template>

<script>
  export default {
    name: 'ActiveComponent',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>

<style scoped></style>
