import axios from 'axios';

export const measureNotificationAPI = {
  getNotifications: () => axios.get('/api/notificationrestrictionmeasure/notifications'),
  setViewNotification: (id) => axios.put(`/api/notificationrestrictionmeasure/${id}`),
}

export const assignmentNotificationAPI = {
  getNotifications: () => axios.get('/api/eapnotification/notifications'),
  setViewNotification: (id) => axios.put(`/api/eapnotification/${id}`),
  getHaveUnreadedNotifications: () => axios.get('/api/eapnotification/haveunreadednotifications'),
};

export default measureNotificationAPI
