import Okved from '@/modules/nsi/views/Okved';
import OkvedCard from '@/modules/nsi/views/OkvedCard';

import Countries from '../views/Countries';
import CountriesGroups from '../views/CountriesGroups';
import CountryCard from '../views/CountryCard';
import CountryGroupCard from '../views/CountryGroupCard';
import DictionaryItemCard from '../views/DictionaryItemCard';
import DictionaryItems from '../views/DictionaryItems';
import DictionaryWrapper from '../views/dictionaryWrapper';
import DocumentTagCard from '../views/DocumentTagCard';
import DocumentTags from '../views/DocumentTags';
import DocumentTypeCard from '../views/DocumentTypeCard';
import DocumentTypes from '../views/DocumentTypes';
import FreeTradeAgreementTypeCard from '../views/FreeTradeAgreementTypeCard';
import FreeTradeAgreementTypes from '../views/FreeTradeAgreementTypes';
import Industries from '../views/Industries';
import IndustryCard from '../views/IndustryCard';
import InvestigationTypeCard from '../views/InvestigationTypeCard';
import InvestigationTypes from '../views/InvestigationTypes';
import MassMediaCategory from '../views/MassMediaCategory';
import MassMediaCategoryCard from '../views/MassMediaCategoryCard';
import MassMediaSmtk from '../views/MassMediaSmtk';
import MassMediaSmtkCard from '../views/MassMediaSmtkCard';
import MeasureKindCard from '../views/MeasureKindCard';
import MeasureOrganProtectedMarketCard from '../views/MeasureOrganProtectedMarketCard';
import MeasuresKinds from '../views/MeasuresKinds';
import MeasuriesOrgansProtectedMarket from '../views/MeasuriesOrgansProtectedMarket';
import MpkEventTypeCard from '../views/MpkEventTypeCard';
import MpkEventTypes from '../views/MpkEventTypes';
import MpkProjectStatusCard from '../views/MpkProjectStatusCard';
import MpkProjectStatuses from '../views/MpkProjectStatuses';
import NonTariffMeasure from '../views/NonTariffMeasure';
import NonTariffMeasureCard from '../views/NonTariffMeasureCard';
import NsiCountryCoverage from '../views/NsiCountryCoverage';
import NsiCountryCoverageCard from '../views/NsiCountryCoverageCard';
import NsiExportPotentialServiceTypeCard from '../views/NsiExportPotentialServiceTypeCard';
import NsiExportPotentialServiceTypes from '../views/NsiExportPotentialServiceTypes';
import NsiList from '../views/NsiList';
import NsiMpkStatusCard from '../views/NsiMpkStatusCard';
import NsiMpkStatuses from '../views/NsiMpkStatuses';
import OrganizationRoleCard from '../views/OrganizationRoleCard';
import OrganizationRoles from '../views/OrganizationRoles';
import ProjectPurposeCard from '../views/ProjectPurposeCard';
import ProjectPurposes from '../views/ProjectPurposes';
import TaskTypeCard from '../views/TaskTypeCard';
import TaskTypes from '../views/TaskTypes';
import Tnved from '../views/Tnved';
import TnvedCard from '../views/TnvedCard';
import TnvedIndustry from '../views/TnvedIndustry';
import TnvedIndustryCard from '../views/TnvedIndustryCard';
import TnvedNonEnergy from '../views/TnvedNonEnergy';
import TransportCard from '../views/TransportCard';
import Transports from '../views/Transports';

export const routes = [
  {
    path: '/Nsi/MpkProjectStatus',
    name: 'MpkProjectStatuses',
    component: MpkProjectStatuses,
  },
  {
    path: '/Nsi/MpkProjectStatus/:action/:id',
    name: 'MpkProjectStatusCard',
    component: MpkProjectStatusCard,
  },
  {
    path: '/Nsi/NonTariffMeasure',
    name: 'NonTariffMeasure',
    component: NonTariffMeasure,
  },
  {
    path: '/Nsi/NonTariffMeasure/:action/:id',
    name: 'NonTariffMeasureCard',
    component: NonTariffMeasureCard,
  },
  {
    path: '/Nsi/NsiMpkStatus',
    name: 'NsiMpkStatuses',
    component: NsiMpkStatuses,
  },
  {
    path: '/Nsi/NsiMpkStatus/:action/:id',
    name: 'NsiMpkStatusCard',
    component: NsiMpkStatusCard,
  },
  {
    path: '/Nsi/NsiCountryCoverage',
    name: 'NsiCountryCoverage',
    component: NsiCountryCoverage,
  },
  {
    path: '/Nsi/NsiCountryCoverage/:action/:id',
    name: 'NsiCountryCoverageCard',
    component: NsiCountryCoverageCard,
  },
  {
    path: '/Nsi/Countries',
    name: 'Countries',
    component: Countries,
  },
  {
    path: '/Nsi/Countries/:action/:id',
    name: 'CountryCard',
    component: CountryCard,
  },
  {
    path: '/Nsi/Industry',
    name: 'Industries',
    component: Industries,
  },
  {
    path: '/Nsi/Industry/:action/:id',
    name: 'IndustryCard',
    component: IndustryCard,
  },
  {
    path: '/Nsi/MpkEventType',
    name: 'MpkEventTypes',
    component: MpkEventTypes,
  },
  {
    path: '/Nsi/MpkEventType/:action/:id',
    name: 'MpkEventTypeCard',
    component: MpkEventTypeCard,
  },
  {
    path: '/Nsi/ProjectPurpose',
    name: 'ProjectPurposes',
    component: ProjectPurposes,
  },
  {
    path: '/Nsi/ProjectPurpose/:action/:id',
    name: 'ProjectPurposeCard',
    component: ProjectPurposeCard,
  },
  {
    path: '/nsi/NsiList',
    name: 'NsiList',
    component: NsiList,
  },
  {
    path: '/nsi/tnved',
    name: 'Tnved',
    component: Tnved,
  },
  {
    path: '/nsi/tnved/:action/:id',
    name: 'TnvedCard',
    component: TnvedCard,
  },
  {
    path: '/nsi/tnvedNonEnergy',
    name: 'TnvedNonEnergy',
    component: TnvedNonEnergy,
  },
  {
    path: '/nsi/okved',
    name: 'Okved',
    component: Okved,
  },
  {
    path: '/nsi/okved/:action/:id',
    name: 'OkvedCard',
    component: OkvedCard,
  },
  {
    path: '/nsi/transport',
    name: 'Transports',
    component: Transports,
  },
  {
    path: '/nsi/transport/:action/:id',
    name: 'TransportCard',
    component: TransportCard,
  },
  {
    path: '/nsi/countriesGroups',
    name: 'countriesGroups',
    component: CountriesGroups,
  },
  {
    path: '/nsi/countriesGroups/:action/:id',
    name: 'CountryGroupCard',
    component: CountryGroupCard,
  },
  {
    path: '/nsi/MeasureKind',
    name: 'MeasuresKinds',
    component: MeasuresKinds,
  },
  {
    path: '/nsi/MeasureKind/:action/:id',
    name: 'MeasureKindCard',
    component: MeasureKindCard,
  },
  {
    path: '/nsi/MeasuriesOrgansProtectedMarket',
    name: 'MeasuriesOrgansProtectedMarket',
    component: MeasuriesOrgansProtectedMarket,
  },
  {
    path: '/nsi/MeasureOrganProtectedMarket/:action/:id',
    name: 'MeasureOrganProtectedMarketCard',
    component: MeasureOrganProtectedMarketCard,
  },
  {
    path: '/nsi/TaskTypes',
    name: 'TaskTypes',
    component: TaskTypes,
  },
  {
    path: '/nsi/TaskTypes/:action/:id',
    name: 'TaskTypeCard',
    component: TaskTypeCard,
  },
  {
    path: '/nsi/DocumentTags',
    name: 'DocumentTags',
    component: DocumentTags,
  },
  {
    path: '/nsi/DocumentTags/:action/:id',
    name: 'DocumentTagCard',
    component: DocumentTagCard,
  },
  {
    path: '/nsi/DocumentTypes',
    name: 'DocumentTypes',
    component: DocumentTypes,
  },
  {
    path: '/nsi/DocumentTypes/:action/:id',
    name: 'DocumentTypeCard',
    component: DocumentTypeCard,
  },
  {
    path: '/nsi/investigationtypes',
    name: 'InvestigationTypes',
    component: InvestigationTypes,
  },
  {
    path: '/nsi/investigationtypes/:action/:id',
    name: 'InvestigationTypeCard',
    component: InvestigationTypeCard,
  },
  {
    path: '/nsi/OrganizationRoles',
    name: 'OrganizationRoles',
    component: OrganizationRoles,
  },
  {
    path: '/nsi/OrganizationRoles/:action/:id',
    name: 'OrganizationRoleCard',
    component: OrganizationRoleCard,
  },
  {
    path: '/nsi/MassMediaCategory',
    name: 'MassMediaCategory',
    component: MassMediaCategory,
  },
  {
    path: '/nsi/MassMediaCategory/:action/:id',
    name: 'MassMediaCategoryCard',
    component: MassMediaCategoryCard,
  },
  {
    path: '/nsi/MassMediaSmtk',
    name: 'MassMediaSmtk',
    component: MassMediaSmtk,
  },
  {
    path: '/nsi/MassMediaSmtk/:action/:id',
    name: 'MassMediaSmtkCard',
    component: MassMediaSmtkCard,
  },
  {
    path: '/nsi/dictionary/:name',
    name: 'dictionaryWrapper',
    component: DictionaryWrapper,
    children: [
      {
        path: '/nsi/dictionary/:name',
        name: 'dictionaryItems',
        component: DictionaryItems,
      },
      {
        path: '/nsi/dictionary/:name/:action/:id',
        name: 'dictionaryItemCard',
        component: DictionaryItemCard,
      },
    ],
  },
  {
    path: '/nsi/dictionary/tnvedIndustry',
    name: 'TnvedIndustry',
    component: TnvedIndustry,
  },
  {
    path: '/nsi/dictionary/tnvedIndustry/:action/:id',
    name: 'TnvedIndustryCard',
    component: TnvedIndustryCard,
  },
  {
    path: '/nsi/dictionary/freeTradeAgreementType',
    name: 'FreeTradeAgreementTypes',
    component: FreeTradeAgreementTypes,
  },
  {
    path: '/nsi/dictionary/freeTradeAgreementType/:action/:id',
    name: 'FreeTradeAgreementTypeCard',
    component: FreeTradeAgreementTypeCard,
  },
  {
    path: '/nsi/nsiExportPotentialServiceType',
    name: 'NsiExportPotentialServiceTypes',
    component: NsiExportPotentialServiceTypes,
  },
  {
    path: '/nsi/nsiExportPotentialServiceType/:action/:id',
    name: 'NsiExportPotentialServiceTypeCard',
    component: NsiExportPotentialServiceTypeCard,
  },
];
