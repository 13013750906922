export function setEvents(state, res) {
  const { data = {} } = res;
  if (res.more) {
    state.listOfEvents.pageNumber = res.data.pageNumber;
    state.listOfEvents.items = state.listOfEvents.items.concat(res.data.items);
  } else {
    state.listOfEvents = data;
  }
}

export function setDataCardEvents(state, res) {
  const { data = {} } = res;
  state.dataCardEvent = data;
}
