import Utils from '@/common/utils';

import Callback from '../views/Callback.vue';
import ErrorPage from '../views/ErrorPage.vue';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';

import store from '@/store/index';

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: '/auth/callback',
    name: 'Callback',
    component: Callback,
    meta: { isPublic: true },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { isPublic: true },
  },
  {
    path: '/errorPage',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: { isPublic: true },
  },
];

export const registerGuards = (router) => {
  router.beforeEach(async (to /*, from*/) => {
    if (store.getters['hasAdminAccess'] == null) {
      let access = await Utils.getAdminAccess();
      store.dispatch('setAdminAccess', access.data);
    }

    if (to.meta.isPublic !== true && !store.getters['auth/oidc/isAuthenticated']) {
      return { path: '/login', query: { returnUrl: to.fullPath } };
    }
    console.log(store.getters['hasAdminAccess']);
    if (
      store.getters['auth/oidc/isAuthenticated'] &&
      store.getters['hasAdminAccess'] === false &&
      to.meta.isAdmin == true
    ) {
      return { path: '/' };
    }
    // console.log('auth guard', to, from)
  });
};
