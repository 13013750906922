<template>
  <div id="container">
    <div id="mapContainer"></div>
  </div>
</template>

<script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  import worldMap from '@/common/custom.geo.json';
  export default {
    name: 'InternalMarketMap',
    data() {
      return {
        center: [40, 0],
        world: worldMap,
        map: null,
      };
    },
    methods: {
      styleMap(feature) {
        let code = feature.properties.iso_n3;
        let color = '#E3E3E3';
        const country = (feature.properties.countryStatus = Math.floor(Math.random() * 12));
        switch (country) {
          case 0:
            color = '#E3E3E3';
            break;
          case 1:
            color = '#99CEFF';
            break;
          case 2:
            color = '#74BCFF';
            break;
          case 3:
            color = '#309CFF';
            break;
          case 4:
            color = '#0081F9';
            break;
          case 5:
            color = '#0264BE';
            break;
          case 6:
            color = '#0061BB';
            break;
          case 7:
            color = '#0061BB';
            break;
          case 8:
            color = '#0061BB';
            break;
          case 9:
            color = '#0061BB';
            break;
          case 10:
            color = '#0061BB';
            break;

          default:
            color = '#E3E3E3';
        }
        if (country > 10) {
          color = '#00386C';
        }
        if (code == 304) {
          return {
            color: '#60a7ee',
            fillColor: 'white',
            weight: 1,
            opacity: 0.5,
          };
        }
        return { color: '#60a7ee', fillColor: color, weight: 1, opacity: 0.7 };
      },
      onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.name) {
          layer.bindPopup(
            `<div class="popup">
                  <div class="popup__head d-flex align-items-center justify-content-between">
                    <p class="font-weight-bold">${feature?.properties?.name}</p>
                    <span class="font-weight-normal">ЕАЭС</span>
                  </div>
                  <div class="popup__body">
                  <div class="border-bottom border-1">
                  <p class="font-weight-bold">ВСЕГО ПРЕПЯТСТВИЙ - 39</p>
</div>
                  <p>Барьеры - 3</p>
                  <p>Ограничения - 36</p>
                  </div>
            </div>`,
          );
          layer.on('mouseup', () => {
            layer.openPopup();
          });
        }
      },
      setupLeafletMap: function () {
        let mapContainer = window.document.getElementById('mapContainer');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.map) {
          this.map.remove();
          this.map = null;
        }
        this.map = L.map('mapContainer').setView(this.center, 1.4);
        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.map);
        L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
          onEachFeature: this.onEachFeature,
          style: this.styleMap,
        }).addTo(this.map);
      },
    },
    mounted() {
      this.setupLeafletMap();
    },
  };
</script>

<style scoped lang="scss">
  #mapContainer {
    background-color: #ffffff;
    width: 100%;
    height: 500px;
  }
</style>

<style lang="scss">
  .leaflet-container a.leaflet-popup-close-button {
    right: 8px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 24px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    .leaflet-popup-tip-container {
      display: none;
    }
    padding: 0;
    .popup {
      &__head {
        background: #1d87ee;
        p,
        span {
          margin: 10px 32px 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #ffffff;
        }
      }
      &__body {
        p {
          margin: 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          color: #015caf;
        }
      }
    }
    .leaflet-popup-content {
      margin: 0;
    }
  }
</style>
