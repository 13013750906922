<template>
  <div class="international-trade mt-32" :class="{ notForTecRussiaTab: notForTecRussiaTab }">
    <div class="international-trade__switchblock d-flex justify-content-between">
      <RedesignedSwitchComponent @switchHandler="switchHand($event)" />

      <div class="d-flex align-items-center">
        <p class="mr-6">Источник: Международный торговый центр</p>

        <Multiselect
          class="select-country-analytic"
          label="text"
          :searchable="true"
          v-model="year"
          valueProp="id"
          track-by="text"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="yearList"
          :canClear="false"
          @select="loadData"
        />
      </div>
    </div>
    <h2 class="mt-5">Ключевые показатели торговли с миром</h2>
    <ul class="indicators__list d-flex justify-content-between align-items-end mt-32">
      <li
        class="indicators__item"
        @click="
          resolveSelectTab({
            isCondition: turnoverMoreInfo,
            id: 1,
            label: isProductMode ? 'Товарооборот' : 'Оборот услуг',
          })
        "
        :class="[{ active: tabId === 1 }, { 'more-info-hidden': !turnoverMoreInfo }]"
      >
        <InternationalKeyWorldTradeIndicator
          name="Товарооборот"
          :currentSummary="currentSummary"
          :currentTurnoverSummary="currentTurnoverSummary"
          :indicatorValue="indicatorValue.TOTAL_VALUE"
          previousPercent="totalValuePercent"
          :isProductMode="isProductMode"
          :turnoverMoreInfo="turnoverMoreInfo"
        />
      </li>

      <li
        class="indicators__item"
        @click="
          resolveSelectTab({
            isCondition: exportMoreInfo,
            id: 2,
            label: 'Экспорт',
          })
        "
        :class="[{ active: tabId === 2 }, { 'more-info-hidden': !exportMoreInfo }]"
      >
        <InternationalKeyWorldTradeIndicator
          name="Экспорт"
          :currentSummary="currentSummary"
          :currentTurnoverSummary="currentTurnoverSummary"
          :indicatorValue="indicatorValue.EXPORT_VALUE"
          previousPercent="exportValuePercent"
          :isProductMode="isProductMode"
          :turnoverMoreInfo="turnoverMoreInfo"
        />
      </li>

      <li
        class="indicators__item"
        @click="
          resolveSelectTab({
            isCondition: importMoreInfo,
            id: 3,
            label: 'Импорт',
          })
        "
        :class="[{ active: tabId === 3 }, { 'more-info-hidden': !importMoreInfo }]"
      >
        <InternationalKeyWorldTradeIndicator
          name="Импорт"
          :currentSummary="currentSummary"
          :currentTurnoverSummary="currentTurnoverSummary"
          :indicatorValue="indicatorValue.IMPORT_VALUE"
          previousPercent="importValuePercent"
          :isProductMode="isProductMode"
          :turnoverMoreInfo="turnoverMoreInfo"
        />
      </li>
    </ul>

    <ul v-if="isProductMode" class="indicators-two mt-5">
      <li class="indicators-two__item">
        <p>
          Экспортная квота (доля экспорта в ВВП на душу населения)
          <br />
          Источник:
          <br />
          Всемирный банк
        </p>
        <span v-if="exportQuota && exportQuota.length">Период: {{ exportQuota[exportQuota.length - 1]?.year }}</span>
        <strong v-if="exportQuota && exportQuota.length"
          >{{ exportQuota && formatNumber(exportQuota[exportQuota.length - 1]?.value) }}%</strong
        >
        <strong v-else>-</strong>
      </li>
      <li class="indicators-two__item">
        <p>
          Импортная квота (доля импорта в ВВП на душу населения)
          <br />
          Источник:
          <br />
          Всемирный банк
        </p>
        <span v-if="importQuota && importQuota.length">Период: {{ importQuota[importQuota.length - 1]?.year }}</span>
        <strong v-if="importQuota && importQuota.length"
          >{{ importQuota && formatNumber(importQuota[importQuota.length - 1]?.value) }}%</strong
        >
        <strong v-else>-</strong>
      </li>
      <li class="indicators-two__item">
        <p>
          Средний уровень тарифной защиты
          <br />
          Источник:
          <br />
          Всемирный банк
        </p>
        <span v-if="summary && summary.year">Период: {{ summary.year }}</span>
        <strong v-if="summary && summary.avgTariffProtectionLevel !== null"
          >{{ formatNumber(summary.avgTariffProtectionLevel) }}%</strong
        >
        <strong v-else>&mdash;</strong>
      </li>
      <li class="indicators-two__item">
        <p>
          Торговый баланс
          <br />
          Источник:
          <br />
          Всемирный банк
        </p>
        <span v-if="summary && summary.year">Период: {{ summary.year }}</span>
        <div v-if="summary && summary.externalBalance !== null" class="indicators-two__block">
          <span>млн $ США</span>
          <strong class="mr-2">{{ formatNumber(summary.externalBalance / 1000000) }}</strong>
        </div>
        <strong v-else>&mdash;</strong>
      </li>
    </ul>

    <div v-if="notForTecRussiaTab != true">
      <div v-if="tabId === 1 || tabId === 2 || tabId === 3">
        <h2 class="mt-5">
          <template v-if="isProductMode">Торговля товарами</template>
          <template v-else>Торговля услугами</template>, млрд $ США
        </h2>

        <LineChartThree
          v-if="!isLoading && isProductMode"
          format-label=" млрд $"
          :chart-data="totalTnvedValueByYears"
          :chart-data-second="exportTnvedValueByYears"
          :chart-data-three="importTnvedValueByYears"
          background-color-three="#2F84EB"
          point-color-three="#2F84EB"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          border-color-three="#2F84EB"
          :labels="chartYearList"
          :displayDataLabels="false"
        />
        <LineChartThree
          v-if="!isLoading && !isProductMode"
          format-label=" млрд $"
          :chart-data="totalOkvedValueByYears"
          :chart-data-second="exportOkvedValueByYears"
          :chart-data-three="importOkvedValueByYears"
          background-color-three="#2F84EB"
          point-color-three="#2F84EB"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          border-color-three="#2F84EB"
          :labels="chartYearList"
          :displayDataLabels="false"
        />
        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p v-if="isProductMode">Товарооборот</p>
            <p v-else>Оборот</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Экспорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center">
            <span class="rounded-circle"></span>
            <p>Импорт</p>
          </div>
        </div>
      </div>

      <div class="supplyStructure" v-if="(tabId === 2 && isProductMode) || (tabId === 1 && isProductMode)">
        <div class="d-flex justify-content-between my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureExport = !switchRows.tovarnayaStructureExport"
          />
          <h2>Товарная структура экспорта</h2>
        </div>
        <template v-if="switchRows.tovarnayaStructureExport">
          <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
          <template v-if="!isLoading && currentSummary">
            <ColumnChartDouble
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#0791BA"
              color-second="#D3DFE5"
              label-first="Текущий период, млрд $"
              label-second="Прошедший период, млрд $"
              :chart-data="currentSummary.topExportGroups.map((item) => (item.currentValue / 1000000).toFixed(1))"
              :chart-data-two="currentSummary.topExportGroups.map((item) => (item.previousValue / 1000000).toFixed(1))"
              :labels="currentSummary.topExportGroups.map((item) => makeChartLabel(item.title, 90))"
              height="100%"
            />
          </template>
        </template>
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">Товарная структура экспорта (таблица)</h2> -->
          <InternationalTradeExportTable
            name="Экспорт"
            :currentSummary="currentSummary"
            dividerLabel="млн $"
            exportGroups="exportGroupsTotalCurrent"
            exportGroupsPrevious="exportGroupsTotalPrevious"
            topExportGroups="topExportGroups"
          />
        </template>
      </div>
      <div v-if="tabId === 4 && isProductMode">
        <div class="d-flex justify-content-between my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureExportSecond = !switchRows.tovarnayaStructureExportSecond"
          />
          <h2>Товарная структура экспорта</h2>
        </div>
        <template v-if="!isLoading && switchRows.tovarnayaStructureExportSecond">
          <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
          <ColumnChartDouble
            indexAxis="y"
            barPercentage="1"
            :format-label="null"
            color-first="#0791BA"
            color-second="#D3DFE5"
            label-first="Текущий период"
            label-second="Прошедший период"
            :chart-data="itemsDataTwo.map((item) => item.count)"
            :chart-data-two="itemsDataTwo.map((item) => item.countTwo)"
            :labels="itemsDataTwo.map((item) => item.name)"
            height="100%"
          />
        </template>
        <template v-else>
          <div class="table-container mt-5 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th style="font-size: 14px" rowspan="2">Наименование</th>
                  <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                    Экспорт
                  </th>
                </tr>
                <tr>
                  <th style="text-align: center">Текущий период</th>
                  <th>Прошедший период</th>
                </tr>
              </thead>
              <tbody>
                <tr style="background-color: #ade0ff">
                  <td class="font-weight-bold">Всего</td>
                  <td class="font-weight-bold">
                    {{ reduceData + ' млн $' }}
                  </td>
                  <td class="font-weight-bold">
                    {{ reduceDataSecond + ' млн $' }}
                  </td>
                </tr>
                <tr v-for="item in itemsDataTwo" :key="item.id">
                  <td class="font-weight-bold">{{ item.name }}</td>
                  <td class="font-weight-bold">{{ item.count + ' млн $' }}</td>
                  <td class="font-weight-bold">
                    {{ item.countTwo + ' млн $' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div v-if="(tabId === 3 && isProductMode) || (tabId === 1 && isProductMode)">
        <div class="d-flex justify-content-between my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureImport = !switchRows.tovarnayaStructureImport"
          />
          <h2>Товарная структура импорта</h2>
        </div>
        <template v-if="switchRows.tovarnayaStructureImport">
          <!-- <h2 class="my-5">Товарная структура импорта</h2> -->
          <ColumnChartDouble
            indexAxis="y"
            barPercentage="1"
            :format-label="null"
            color-first="#0791BA"
            color-second="#D3DFE5"
            label-first="Текущий период, млрд $"
            label-second="Прошедший период, млрд $"
            :chart-data="currentSummary.topImportGroups.map((item) => (item.currentValue / 1000000).toFixed(1))"
            :chart-data-two="currentSummary.topImportGroups.map((item) => (item.previousValue / million).toFixed(1))"
            :labels="currentSummary.topImportGroups.map((item) => makeChartLabel(item.title, 90))"
          />
        </template>
        <template v-else>
          <h2 class="my-5" v-if="currentSummary">Товарная структура импорта (таблица)</h2>
          <InternationalTradeExportTable
            name="Импорт"
            :currentSummary="currentSummary"
            dividerLabel="млн $"
            exportGroups="importGroupsTotalCurrent"
            exportGroupsPrevious="importGroupsTotalPrevious"
            topExportGroups="topImportGroups"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import ColumnChartDouble from '@/components/columnChartDouble';
  import LineChartThree from '@/components/lineChartThree';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryАnalytics/api';

  import { DataItems, InternationalYears, SecondDataItems } from '../../utils/constants';
  import InternationalKeyWorldTradeIndicator from './indicators/InternationalKeyWorldTradeIndicator.vue';
  import InternationalTradeExportTable from './InternationalTradeExportTable.vue';
  import InternationalTradeMixin from './mixin/InternationalTradeMixin';

  export default {
    name: 'InternationalTrade',
    mixins: [text, InternationalTradeMixin],
    props: {
      countryId: {
        type: String,
        default: '',
      },
      notForTecRussiaTab: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      LineChartThree,
      ColumnChartDouble,
      InternationalTradeExportTable,
      RedesignedSwitchComponent,
      InternationalKeyWorldTradeIndicator,
      Multiselect,
    },
    data() {
      return {
        year: 2020,
        isProductMode: true,
        tabId: null,
        label: null,
        yearList: InternationalYears,
        itemsData: DataItems,
        itemsDataTwo: SecondDataItems,
        million: Constants.MILLION,
        summary: null,
        importValues: null,
        exportValues: null,
        importIndicatorId: 4,
        exportIndicatorId: 8,
        isLoading: false,
        indicatorValue: Constants.indicator.value,

        turnoverMoreInfo: true,
        exportMoreInfo: true,
        importMoreInfo: true,

        switchRows: {
          tovarnayaStructureExport: true,
          tovarnayaStructureExportSecond: true,
          tovarnayaStructureImport: true,
        },
      };
    },

    computed: {
      reduceData() {
        const initialValue = 0;
        const result = this.itemsData
          .map((item) => item.count)
          .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        return result;
      },
      reduceDataSecond() {
        const initialValue = 0;
        const result = this.itemsData
          .map((item) => item.countTwo)
          .reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        return result;
      },
      currentSummary() {
        return this.isProductMode ? this.summary?.worldSummaryTnved : this.summary?.worldSummaryOkved;
      },
      importQuota() {
        return this.importValues && this.importValues?.filter((item) => item.year <= this.year).sort(this.sortByYear);
      },
      exportQuota() {
        return this.exportValues && this.exportValues?.filter((item) => item.year <= this.year).sort(this.sortByYear);
      },
      currentSummaryOkved() {
        return this.summary?.turnoverWorldOkved;
      },
      currentSummaryTnved() {
        return this.summary?.turnoverWorldTnved;
      },
      currentTurnoverSummary() {
        return this.isProductMode ? this.currentSummaryTnved : this.currentSummaryOkved;
      },
      totalOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.totalValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / this.million).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10];
      },
      exportOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.exportValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / this.million).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10].reverse();
      },
      importOkvedValueByYears() {
        if (this.currentSummaryOkved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryOkved?.importValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / this.million).toFixed(0) : 0;
          });
        else return [36, 40, 20, 31, 1, 10];
      },
      totalTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.totalValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / this.million).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10];
      },
      exportTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length)
          return this.getAvailableYears().map((x) => {
            let value = this.currentSummaryTnved?.exportValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / this.million).toFixed(0) : 0;
          });
        else return [30, 4, 5, 3, 1, 10].reverse();
      },
      importTnvedValueByYears() {
        if (this.currentSummaryTnved?.totalValuesByYears.length) {
          return this.getAvailableYears().map((x) => {
            const value = this.currentSummaryTnved?.importValuesByYears?.find((v) => v.title == x.id);
            return value ? (value.currentValue / this.million).toFixed(0) : 0;
          });
        } else return [36, 40, 20, 31, 1, 10];
      },
      chartYearList() {
        return this.getAvailableYears().map((x) => x.text);
      },
    },
    created() {
      this.loadIndicatorVisibility();
      Api.getYearListTurnover(this.countryId).then((response) => {
        this.yearList = response.data.filter((x) => x.id > 2016);
        this.year = this.yearList[this.yearList.length - 1].id;
        this.loadData();
      });
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.turnoverMoreInfo = response.data[10].moreInfoVisible;
          this.exportMoreInfo = response.data[11].moreInfoVisible;
          this.importMoreInfo = response.data[12].moreInfoVisible;
        });
      },
      loadData() {
        this.isLoading = true;
        Api.getForeignTradeData(this.year, this.countryId).then((response) => {
          this.summary = response.data;
          this.isLoading = false;
        });
        Api.searchMacro({
          countryId: this.countryId,
          indicatorId: this.importIndicatorId,
          valueFrom: 0.005,
        }).then((response) => {
          this.importValues = response.data.items;
        });
        Api.searchMacro({
          countryId: this.countryId,
          indicatorId: this.exportIndicatorId,
          valueFrom: 0.005,
        }).then((response) => {
          this.exportValues = response.data.items;
        });
      },
      switchHand(e) {
        this.isProductMode = e;
        this.selectTab(1, this.isProductMode ? 'Товарооборот' : 'Оборот услуг');
        this.$forceUpdate();
      },
      selectTab(id, label) {
        this.tabId = id;
        this.label = label;
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  @import './styles/InternationalTrade.scss';
  @import '../../styles/countryAnalyticsMultiselect.scss';
</style>
