export const routes = [
  {
    path: '/mpkProjects/list',
    name: 'Projects',
    component: () => import('../views/Projects'),
    props: true,
  },
  {
    path: '/mpkProjects/map',
    name: 'ProjectsMap',
    component: () => import('../views/ProjectsMap'),
  },
  {
    path: '/mpkProjects/statistics',
    name: 'ProjectsStatistics',
    component: () => import('../views/ProjectsStatistics'),
  },
  {
    path: '/mpkProjects/edit/:id',
    name: 'EditProjectCard',
    component: () => import('../views/EditProjectCard'),
  },
  {
    path: '/mpkProjects/view/:id',
    name: 'ProjectCard',
    component: () => import('../views/ProjectCard'),
  },
];
