<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка организации</p>
    </div>
    <tabs>
      <tab name="Основная информация" :selected="true">
        <div>
          <div class="col col-lg-4 pb-0">
            <PictureUpload v-on:pictureSelected="pictureSelected" :initialPhoto="item.picture" :readOnly="!canEdit" />
          </div>
          <div class="row">
            <div class="form-group required">
              <ComponentInput
                v-model="item.name"
                label="Название"
                required
                maxLength="250"
                placeholder="Введите название"
                :disabled="!canEdit"
              />
            </div>
            <div class="form-group required">
              <ComponentInput
                v-model="item.nameFull"
                label="Полное название"
                required
                placeholder="Введите полное название"
                :disabled="!canEdit"
              />
            </div>
            <div class="form-group">
              <ComponentInput
                v-model="item.address"
                label="Адрес"
                placeholder="Введите адрес организации"
                :disabled="!canEdit"
              />
            </div>
            <div class="row">
              <div class="form-group col-6">
                <ComponentInput
                  v-model="item.phone"
                  label="Телефон"
                  placeholder="Введите телефон организации"
                  :disabled="!canEdit"
                />
              </div>
              <div class="form-group col-6">
                <ComponentInput
                  v-model="item.email"
                  type="email"
                  label="E-mail"
                  placeholder="Введите адрес электронной почты организации"
                  :disabled="!canEdit"
                />
              </div>
            </div>
            <div class="form-group">
              <ComponentInput
                v-model="item.www"
                type="url"
                label="URL адрес"
                placeholder="Введите ссылку на сайт организации"
                :disabled="!canEdit"
              />
            </div>
            <div class="form-group">
              <ComponentInput
                v-model="item.schedule"
                type="textarea"
                label="Режим работы"
                placeholder="Введите режим работы организации"
                :disabled="!canEdit"
              />
            </div>
            <div class="form-group required">
              <ComponentInput
                label="Тип организации"
                :select2Options="orgTypes"
                v-model="item.organizationTypeId"
                type="select2"
                required
                :select2Settings="select2Settings"
                :disabled="!canEdit"
              />
            </div>
          </div>
          <div class="row mt-10">
            <div class="checkboxInput">
              <input type="checkbox" v-model="item.isActive" id="chActive" :disabled="!canEdit" />
              <label for="chActive">Запись активна</label>
            </div>
          </div>
        </div>
      </tab>
      <tab name="Дополнительно">
        <div class="row">
          <div class="form-group col-6">
            <ComponentInput
              v-model="item.inn"
              :fix-length="10"
              label="ИНН"
              placeholder="Введите ИНН организации"
              :disabled="!canEdit"
            />
          </div>
          <div class="form-group col-6">
            <ComponentInput
              v-model="item.ogrn"
              :fix-length="13"
              label="ОГРН"
              placeholder="Введите ОГРН организации"
              :disabled="!canEdit"
            />
          </div>
        </div>
        <div class="form-group">
          <ComponentInput
            v-model="item.director"
            label="Директор"
            maxLength="255"
            placeholder="Введите ФИО директора организации"
            :disabled="!canEdit"
          />
        </div>
        <div class="form-group">
          <DateComponent
            v-model="item.registrationDate"
            label="Дата регистрации юридического лица"
            :disabled="!canEdit"
          />
        </div>
        <div class="form-group">
          <ComponentInput
            v-model="item.registrationAddress"
            label="Место регистрации юридического лица"
            maxLength="255"
            placeholder="Введите место регистрации юридического лица"
            :disabled="!canEdit"
          />
        </div>
        <div class="form-group">
          <ComponentInput
            v-model="item.authorizedFund"
            label="Уставный фонд, руб."
            type="number"
            :min="0"
            placeholder="Введите размер уставного фонда юридического лица"
            :disabled="!canEdit"
          />
        </div>
        <div class="form-group disabled-select">
          <ComponentInput
            label="Услуги (ОКВЭД)"
            :select2Settings="select2SettingsMulti"
            :select2Options="okved"
            v-model="item.okvedIds"
            multiple
            type="select2"
            :disabled="!canEdit"
          />
          <i
            class="fas fa-search catalog-icon"
            title="Найти в каталоге"
            @click="openCatalog('nsiokved', 'Выбор услуги')"
            v-if="canEdit"
          ></i>
        </div>
      </tab>
    </tabs>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" v-if="canEdit" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="catalog.open" :width="950" :title="catalog.title" @close="closeCatalog">
      <CatalogSelector
        v-bind:selected-ids="catalog.selectedIds"
        :route="catalog.route"
        :multiple="true"
        @select="updateCatalogField"
        @close="closeCatalog"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import DateComponent from '../../../common/components/dateComponent';
  import PictureUpload from '../../../common/components/PictureUpload';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import CatalogApi from '../../nsi/api/catalog';
  import CatalogSelector from '../../nsi/components/CatalogSelector';
  import API from '../api/organization';

  export default {
    name: 'OrganizationCard',
    components: {
      CatalogSelector,
      ModalComponent,
      DateComponent,
      PictureUpload,
      ComponentInput,
      ButtonStock,
      tabs,
      tab,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        /**@public
         * Используется в миксине security
         */
        isReadonly: this.$route.params.action === 'view',
        item: {},
        orgTypes: [],
        okved: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMulti: Constants.select2SettingsMultiple,
        catalog: {
          route: 'nsiokved',
          title: '',
          selectedIds: [],
          open: false,
        },
        canEdit: false,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.OrgEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        const typeId =
          this.$route.path.indexOf('admin') > -1
            ? Constants.organizationType.foiv
            : Constants.organizationType.navigatorParticipant;
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
          });
        } else {
          this.item = {
            isActive: true,
            organizationTypeId: typeId,
            externalSystemId: 1,
          };
        }
      },
      loadDictionaries() {
        API.getOrgTypes().then((response) => {
          if (response.data) {
            let orgTypes = response.data;
            if (this.isAdminSection()) {
              orgTypes = orgTypes.filter((x) => x.id !== Constants.organizationType.navigatorParticipant);
            } else {
              orgTypes = orgTypes.filter((x) => x.id === Constants.organizationType.navigatorParticipant);
            }
            this.orgTypes = orgTypes.map((x) => {
              return { text: x.name, id: x.id };
            });
          }
        });
      },
      pictureSelected(data) {
        this.item.picture = data;
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.update(this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.item.id) {
              this.loadCard();
            } else {
              this.close();
            }
          })
          .catch((error) => {
            console.error(error?.response?.data);
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({
          name: this.isAdminSection() ? 'Organizations' : 'NavOrgs',
        });
      },
      isAdminSection() {
        return this.$route.path.indexOf('admin') > -1;
      },
      openCatalog(route, title) {
        this.catalog.route = route;
        this.catalog.title = title;
        this.catalog.selectedIds = this.item.okvedIds ?? [];
        this.catalog.open = true;
      },
      updateCatalogField(selection) {
        this.closeCatalog();
        this.item.okvedIds = selection;
      },
      closeCatalog() {
        this.catalog.open = false;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
    watch: {
      'item.okvedIds': {
        handler: function (value) {
          if (!value?.length) return;
          CatalogApi.getListByIds('nsiokved', value).then((response) => {
            const list = response.data.map((x) => ({ id: x.id, text: x.name }));
            list.forEach((item) => {
              if (!this.okved.find((x) => x.id === item.id)) {
                this.okved.push(item);
              }
            });
          });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
