export function getTotalGoods(state) {
  return state.tableInfo?.itemsTotal || 0;
}

export function getLoadPage(state) {
  return state.loadPage;
}

export function getLoadMore(state) {
  return state.loadMore;
}

export function getTableInfo(state) {
  return state.tableInfo;
}
