<template>
  <div class="overflow-auto">
    <div style="min-width: 600px" v-if="autoHeight && autoHeight">
      <!-- ref может использоваться в родителе -->
      <BarChart
        :height="autoHeight && autoHeight"
        :chart-data="data"
        :options="options"
        css-classes="chart-container"
        ref="Ref"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref } from 'vue';
  import { BarChart } from 'vue-chart-3';
  import { Chart, registerables, Tooltip } from 'chart.js';
  Chart.register(...registerables, Tooltip);
  export default defineComponent({
    name: 'column-chart-horizontal',
    components: {
      BarChart,
    },
    emits: ['clickedBar'],
    props: {
      height: {
        type: Number,
        default: 40,
      },
      chartData: {
        type: Array,
      },
      barPercentage: {
        type: Number,
        default: 0.6,
      },
      labels: {
        type: Array,
      },
      indexAxis: {
        type: String,
        default: 'x',
      },
      colors: {
        type: [Array, String],
        default: '#0093BA',
      },
      formatLabel: {
        type: String,
        default: '',
      },
      labelColor: {
        type: String,
        default: '#000',
      },
      dataLabelAlign: {
        type: String,
      },
      dataLabelAnchor: {
        type: String,
      },
      afterFitY: {
        type: Function,
        default: () => {},
      },
      dataLabelOffset: {
        type: Number,
        default: null,
      },
      paddingLeft: {
        type: Number,
        default: null,
      },
      paddingRight: {
        type: Number,
        default: null,
      },
    },
    // Rerender bug fix
    watch: {
      chartData: function (newValue) {
        this.autoHeight = false;
        this.$nextTick().then(() => {
          this.setAutoHeight();
          this.dataValues = newValue;
        });
      },
    },
    data() {
      return {
        dataValues: this.chartData,
        autoHeight: null,
      };
    },
    created() {
      this.setAutoHeight();
      // this.autoHeight = this.labels && this.labels.length * this.height;
    },
    computed: {
      data() {
        return {
          labels: this.labels,
          datasets: [
            {
              label: '',
              data: this.dataValues,
              backgroundColor: this.colors,
              barPercentage: this.barPercentage,
              maxBarThickness: 20,
            },
          ],
        };
      },
    },
    setup(props, context) {
      const options = ref({
        indexAxis: props.indexAxis,
        locale: 'ru-RU',
        layout: {
          padding: {
            left: props.paddingLeft ?? 0,
            right: props.paddingRight ?? 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            clamp: true,
            align: props.dataLabelAlign ? props.dataLabelAlign : 'center',
            offset: props.dataLabelOffset ?? -10,
            anchor: props.dataLabelAnchor ? props.dataLabelAnchor : 'center',
            color: props.labelColor,
            textAlign: 'center',
            font: {
              fontSize: '14px',
            },
            formatter: function (value) {
              return value.toString().replace('.', ',') + ` ${props.formatLabel}`;
            },
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.formattedValue + ` ${props.formatLabel}`;
              },
            },
          },
          title: {
            text: 'Bar',
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            afterFit: props.afterFitY,
          },
        },
        onClick: function (evt, activeElements) {
          console.log(evt, '-- ev');
          console.log(activeElements, '-- activeElements');
          this.update();
          context.emit('clickedBar', activeElements && activeElements.length > 0 ? activeElements[0].index : null);
        },
      });

      return {
        //data,
        options,
      };
    },
    methods: {
      setAutoHeight() {
        this.autoHeight = this.labels?.length === 1 ? 80 : this.labels && this.labels.length * this.height;
      },
    },
  });
</script>
