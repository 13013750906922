<template>
  <div class="container-page">
    <div class="address-book">
      <!-- title -->
      <p class="title-inner">Адресная книга</p>
      <!-- search -->
      <div class="address-book__search">
        <form action="">
          <div class="address-book__search-container">
            <div class="address-book__search-inputBlock mb-4">
              <p class="mb-2">Поиск по ФИО</p>
              <input
                class="col-6"
                placeholder="ФИО"
                type="text"
                v-model="searchName"
                v-on:keyup.enter.prevent="search(true)"
              />
              <IconComponent name="search2" @click="search(true)" />
            </div>
          </div>
        </form>
      </div>
      <!-- table -->
      <div v-if="page.items">
        <div class="table-container js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>ФИО</th>
                <th>Адрес электронной почты</th>
                <th>Организация</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.items" :key="item.id" @dblclick="openChat(item)">
                <td>{{ item.fullName }}</td>
                <td>{{ item.user?.email }}</td>
                <td>{{ item.organization?.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ChatApi from '@/modules/messenger/api/index';

  import API from '../api/users';

  export default {
    name: 'AddressBook',
    components: {
      IconComponent,
    },
    mixins: [security],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          userTypeId: 1,
        },
        searchName: '',
        filter: {
          fullName: '',
        },
        chatTemplate: {
          chatTypeId: 1,
          entityStatusId: 1,
        },
      };
    },
    mounted() {
      this.checkPermission(Constants.Permissions.AdminAccess).then((response) => {
        if (response.data) this.loadPage();
        else this.notAccess();
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        const request = Object.assign(this.filter, this.request);
        API.search(request).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      search(isNameChange) {
        if (isNameChange) this.filter.fullName = this.searchName;
        this.request.pageNumber = 1;
        this.loadPage(false);
      },
      openChat(item) {
        ChatApi.findUserChatByEmployeeId(item.id)
          .then((response) => {
            let chat = response.data;
            this.$router.push({ name: 'Messenger', params: { initialDialogId: chat.id } });
          })
          .catch((error) => {
            if (error.response.status == 404) {
              let chat = Object.assign({}, this.chatTemplate);
              chat.title = item.fullName;
              chat.members = [item];
              ChatApi.createChat(chat).then((response) => {
                this.$router.push({ name: 'Messenger', params: { initialDialogId: response.data.id } });
              });
            }
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .address-book {
    &__search {
      &-inputBlock {
        position: relative;
        .icon {
          position: absolute;
          bottom: 12px;
          right: calc(50% + 12px);
        }
      }
    }
  }
</style>
