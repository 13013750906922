<template>
  <!-- Тестовая страница для регистра мпк -->
  <div class="container-page">
    <div>
      <p class="title-inner">Группы стран мира</p>

      <div class="mb-16">
        <ButtonStock title="Создать" @click="editCard(0)" />
      </div>

      <div v-if="page.items">
        <div class="table-container mb-16 js-table-arrow" v-if="page.items.length">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Название</th>
                <th>Статус</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
                <td>
                  <div
                    v-if="item.picture"
                    class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${item.picture})` }"
                  ></div>
                  {{ item.name }}
                </td>
                <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
                <td>
                  <IconComponent @click="editCard(item.id)" name="mode" />
                </td>
                <td>
                  <IconComponent @click="deleteCard(item.id)" name="delete" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '@/modules/nsi/api/countryGroup';

  export default {
    name: 'CountriesGroups',
    components: {
      ButtonStock,
      IconComponent,
    },
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},
      };
    },
    created() {
      this.loadPage(false);
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        console.log(addToExist);
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        API.search(this.request).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      editCard(id) {
        this.$router.push({ name: 'CountryGroupCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
