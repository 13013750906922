<template>
  <div class="label-field" v-if="titleOptions">{{ titleOptions }}</div>
  <Multiselect
    v-model="value"
    :value="modelValue"
    :class="getMultiselectClass"
    mode="multiple"
    :track-by="trackBy"
    inputType="text"
    :placeholder="placeholder"
    :label="labelOptions"
    :hideSelected="false"
    :valueProp="valueProp"
    :close-on-select="false"
    :searchable="true"
    :options="options"
    noResultsText="Ничего не найдено."
    noOptionsText="Список пуст."
    :disabled="disabled"
    :max="max"
    @close="closeDropDown"
    ref="select"
  >
    <template v-slot:placeholder>
      <div class="multiselect-placeholder">{{ placeholder }}</div>
    </template>
    <template v-slot:multiplelabel="{ values }">
      <div v-if="values && values.length < 2" class="multiselect-multiple-label">
        {{ values[0].text }}
      </div>
      <div v-else-if="values && values.length >= 2" class="multiselect-multiple-label">
        <span class="multiselect-multiple-label__selected-text">Выбрано</span>
        <span class="multiselect-multiple-label__selected-number">{{ values.length }}</span>
      </div>
    </template>
    <template v-slot:option="{ option }">
      <div class="input-group-wrapper checkbox-green">
        <input type="checkbox" class="form-check-input" :checked="getChecked(option)" :disabled="option.disabled" />
        <label class="form-check-label form-label">{{ option.text }}</label>
      </div>
    </template>
  </Multiselect>
  <div class="invalid-msg" v-if="v$.value.$error">Поле обязательно для заполнения</div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';
  import { useVuelidate } from '@vuelidate/core';
  import { helpers } from '@vuelidate/validators';

  const requiredValidator = (requiredInternal, scope, type, multiple) =>
    helpers.withParams({ type: 'requiredValidator' }, (value) => {
      if (!requiredInternal) return true;
      let res = true;
      if (type === 'snils' || type === 'phone' || type === 'passportDep')
        res =
          (value ? value.replaceAll('-', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '') : '') !== '';
      else if (type === 'checkbox') res = !!value;
      else if (type === 'multiple') {
        if (multiple) res = value.length > 0;
        else res = value && (value > 0 || value == 'false' || value == 'true');
      } else {
        if (typeof value === 'string') {
          res = value && value.trim().length > 0;
        } else if (type === 'number') {
          res = !isNaN(value);
        } else {
          res = value;
        }
      }

      res = !!res;

      return {
        $valid: res,
        extraParams: { scope: scope },
      };
    });

  export default {
    components: { Multiselect },
    emits: ['update:modelValue'],
    props: {
      options: {
        type: Array,
        required: true,
      },
      modelValue: [String, Number, Object, Boolean],
      placeholder: {
        type: [String, Number],
      },
      // Поле, которое будет добовляться в v-model. Поле объекта опций
      trackBy: {
        type: String,
        required: true,
      },
      // Поле, которое будет добовляться в v-model. Поле объекта опций
      valueProp: {
        type: String,
        required: true,
      },
      // Поле, которое будет отображаться в списке селектов. Поле объекта опций
      labelOptions: {
        type: String,
        required: true,
      },
      titleOptions: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
      mod: {
        type: String,
        validator(value) {
          return ['filter-small', 'base'].includes(value);
        },
      },
      error: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      // макс. число элементов, которые можно выбрать в режиме `multiple` или `tags`
      max: {
        type: Number
      }
    },
    data() {
      return {
        requiredInternal: this.required,
      };
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
      value: {
        get() {
          return this.modelValue ? this.modelValue : [];
        },
        set(value) {
          this.$emit('update:modelValue', value.map(String));
        },
      },
      getMultiselectClass() {
        return [
          'input-group-wrapper',
          'multiselect-checkbox',
          { error: this.v$.value.$error || this.error },
          this.mod ? `multiselect-checkbox--${this.mod}` : '',
        ];
      },
    },
    watch: {
      required: function (newValue) {
        this.requiredInternal = newValue;
      },
    },
    methods: {
      getChecked(option) {
        return this.value.map(String).some((item) => item === option.id.toString());
      },

      closeDropDown() {
        if (this.$refs.select) {
          this.$refs.select.blur();
        }
      },
    },
    validations() {
      return {
        value: {
          requiredValidator: requiredValidator(this.requiredInternal, '', 'multiple', true),
        },
      };
    },
  };
</script>
