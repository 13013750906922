<template>
  <section class="restrictionStatisticTab">
    <div class="restrictionStatisticTab__cards">
      <RestrictionStatisticCard
        :title="`Действующие барьеры на ${date} года`"
        :quantity="activeBarrierData.barrierQuantity"
        :dynamic="activeBarrierData.dynamic"
        :damageSum="getFormatterActiveBarrierDamageSum"
        positiveArrow="down"
        @onClick="redirectToActiveBarrier"
      />
      <RestrictionStatisticCard
        :title="`Выявленные барьеры за ${yearDate} год`"
        :quantity="identifiedBarrierData.barrierQuantity"
        :dynamic="identifiedBarrierData.dynamic"
        :damageSum="getFormatterIdentifiedBarrierDataDamageSum"
        positiveArrow="down"
        @onClick="redirectToIdentifiedBarrier"
      />
      <RestrictionStatisticCard
        :title="`Устраненные барьеры за ${yearDate} год`"
        :quantity="removedBarrierData.barrierQuantity"
        :dynamic="removedBarrierData.dynamic"
        :damageSum="getFormatterRemovedBarrierDataDamageSum"
        positiveArrow="up"
        @onClick="redirectToRemovedBarrier"
      />
    </div>
    <StatisticContainer title="Статистика по барьерам">
      <StatisticByBarriers />
    </StatisticContainer>

    <StatisticContainer title="Статистика по мерам">
      <StatisticByMeasures />
    </StatisticContainer>
  </section>
</template>

<script>
  import moment from 'moment';

  import constants from '@/common/constants';
  import numberFormatter from '@/common/mixins/numberFormatter';

  import analyticApi from '../../../api/analytics';
  import RestrictionStatisticCard from './RestrictionStatisticCard.vue';
  import StatisticByBarriers from './StatisticByBarriers.vue';
  import StatisticByMeasures from './StatisticByMeasures.vue';
  import StatisticContainer from './StatisticContainer.vue';

  export default {
    components: {
      RestrictionStatisticCard,
      StatisticByBarriers,
      StatisticContainer,
      StatisticByMeasures,
    },
    mixins: [numberFormatter],
    data() {
      return {
        activeBarrierData: {},
        identifiedBarrierData: {},
        removedBarrierData: {},
        date: this.$momentFormat(moment(), constants.DATE_FORMATS.DD_MMMM_YYYY),
        fullDateNow: this.$momentFormat(moment(), constants.DATE_FORMATS['YYYY-MM-DD']),
        startOfCurrentYear: this.$momentFormat(moment().startOf('year'), constants.DATE_FORMATS['YYYY-MM-DD']),
        yearDate: this.$momentFormat(moment(), constants.DATE_FORMATS.YYYY),
      };
    },
    created() {
      this.fetchActiveBarrier();
      this.fetchIdentifiedBarrier();
      this.fetchRemovedBarrier();
    },
    methods: {
      async fetchActiveBarrier() {
        try {
          const activeBarrierResolve = await analyticApi.getActiveBarrier();
          if (!activeBarrierResolve.data) {
            throw new Error('Ошибка');
          }
          this.activeBarrierData = activeBarrierResolve.data;
        } catch (e) {
          console.error(e);
        }
      },
      async fetchIdentifiedBarrier() {
        try {
          const identifiedBarrierResolve = await analyticApi.getIdentifiedBarrier();
          if (!identifiedBarrierResolve.data) {
            throw new Error('Ошибка');
          }
          this.identifiedBarrierData = identifiedBarrierResolve.data;
        } catch (e) {
          console.error(e);
        }
      },
      async fetchRemovedBarrier() {
        try {
          const removedBarrierResolve = await analyticApi.getRemovedBarrier();
          if (!removedBarrierResolve.data) {
            throw new Error('Ошибка');
          }
          this.removedBarrierData = removedBarrierResolve.data;
        } catch (e) {
          console.error(e);
        }
      },
      redirectToActiveBarrier() {
        this.$router.push({
          name: 'RestrictionMeasuresPage',
          query: {
            tradeItemTypeId: 1,
            isBarrierActive: true,
            isActive: true,
            // barrierIdentificationTo: this.fullDateNow,
          },
        });
      },
      redirectToIdentifiedBarrier() {
        this.$router.push({
          name: 'RestrictionMeasuresPage',
          query: {
            tradeItemTypeId: 1,
            isBarrierActive: true,
            isActive: true,
            barrierIdentificationTo: this.fullDateNow,
            barrierIdentificationFrom: this.startOfCurrentYear,
          },
        });
      },
      redirectToRemovedBarrier() {
        this.$router.push({
          name: 'RestrictionMeasuresPage',
          query: {
            tradeItemTypeId: 1,
            isBarrierActive: false,
            isActive: true,
            barrierRemovalTo: this.fullDateNow,
            barrierRemovalFrom: this.startOfCurrentYear,
          },
        });
      },
    },
    computed: {
      getFormatterActiveBarrierDamageSum() {
        return this.numberFormatter(this.activeBarrierData.damageSum);
      },
      getFormatterIdentifiedBarrierDataDamageSum() {
        return this.numberFormatter(this.identifiedBarrierData.damageSum);
      },
      getFormatterRemovedBarrierDataDamageSum() {
        return this.numberFormatter(this.removedBarrierData.damageSum);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .restrictionStatisticTab {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: 40px 0;

    &__cards {
      display: flex;
      gap: 16px;

      @media (max-width: $laptop) {
        flex-direction: column;
      }
    }
  }
</style>
