<template>
  <div class="form__body">
    <div class="form__body-btns header">
      <ButtonStock btnType="white" :class="{ selected: tab == 1 }" title="Общая информация" @click="tab = 1" />
      <ButtonStock btnType="white" :class="{ selected: tab == 2 }" title="Документы" @click="tab = 2" />
    </div>

    <div :style="tab == 1 ? '' : 'display:none;'">
      <div class="form__body-content">
        <div class="form__body-inputBlock required">
          <p class="form-label">Название</p>
          <ComponentInput placeholder="Введите название" v-model="item.name" required />
        </div>

        <div class="form__body-inputBlock required">
          <p class="form-label">Тип задачи</p>
          <ComponentInput
            placeholder="Укажите тип"
            :select2Settings="select2Settings"
            :select2Options="taskTypeList"
            v-model="item.taskTypeId"
            type="select2"
            required
          />
        </div>

        <div class="form__body-inputBlock required">
          <p class="form-label">Срочность</p>
          <ComponentInput
            placeholder="Укажите срочность"
            :select2Settings="select2Settings"
            :select2Options="taskUrgencyList"
            v-model="item.taskUrgencyId"
            type="select2"
            required
          />
        </div>

        <div class="form__body-inputBlock required">
          <p class="form-label">Исполнитель</p>
          <ComponentInput
            placeholder="Укажите исполнителя"
            :select2Settings="select2Settings"
            :select2Options="employeeList"
            v-model="item.executorId"
            type="select2"
            required
            :disabled="!canChangeEmployee"
          />
        </div>

        <div class="form__body-inputBlock">
          <p>Cоисполнитель</p>
          <ComponentInput
            placeholder="Укажите соисполнителей"
            :select2Settings="{ multiple: true }"
            :select2Options="employeeList"
            v-model="item.issueExecutorsIds"
            type="select2"
            :multiple="true"
            :disabled="!canChangeEmployee"
          />
        </div>

        <div class="form__body-inputBlock deadline taskModal">
          <p>Контрольный срок</p>
          <dateTimeComponent v-model="item.deadline" />
        </div>

        <div class="form__body-inputBlock required">
          <p class="form-label">Описание задачи</p>
          <ComponentInput placeholder="Введите описание" v-model="item.description" type="textarea" required />
        </div>

        <div class="form__body-inputBlock">
          <div class="checkboxInput">
            <input class="w-auto" type="checkbox" v-model="item.needReport" id="chbx_needReport" />
            <label for="chbx_needReport" class="form-check-label form-label">Обязателен отчет</label>
          </div>
        </div>

        <div class="form__body-inputBlock" v-if="isEap && canChangeEmployee">
          <div class="checkboxInput">
            <input class="w-auto" type="checkbox" v-model="needCurator" id="chbx_needCurator" :disabled="isFoiv" />
            <label for="chbx_needCurator" class="form-check-label form-label"
              >Необходимо согласование с куратором</label
            >
          </div>
        </div>

        <div class="form__body-inputBlock required" v-if="isEap && canChangeEmployee && needCurator">
          <p class="form-label">Куратор</p>
          <ComponentInput
            placeholder="Выберите куратора"
            v-model="item.curatorId"
            :select2Settings="select2Settings"
            :select2Options="curatorList"
            type="select2"
            :required="needCurator"
          />
        </div>
      </div>
    </div>
    <div :style="tab == 2 ? '' : 'display:none;'">
      <div class="form__body-content">
        <div class="form__body-inputBlock">
          <p>Документы</p>
          <FileMultiUpload :entity="item" @deleteFile="removeFile" prop-name="files" @uploaded="fileUploaded" />
        </div>
        <!-- <div class="form__body-inputBlock">
                        <p>Комментарий</p>
                        <component-input
                            placeholder="Введите комментарий"
                            v-model="item.comment"
                            type="textarea"
                        />
                    </div> -->
      </div>
    </div>

    <!-- btns footer -->
    <div class="form__body-btns footer">
      <ButtonStock title="Сохранить" v-on:click="save" />
      <ButtonStock btnType="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateTimeComponent from '@/common/components/dateTimeComponent.vue';
  import FileMultiUpload from '@/common/components/FileMultiUpload';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  // Additional
  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_EAP from '../api/eap';
  import API from '../api/issue';

  export default {
    name: 'IssueCardAdd',
    components: {
      ButtonStock,
      dateTimeComponent,
      ComponentInput,
      FileMultiUpload,
    },
    props: {
      nn: Number,
      isEap: Boolean,
    },
    watch: {
      nn: function () {
        this.init();
      },
    },
    data() {
      return {
        isFoiv: false,
        needCurator: false,
        tab: 1,
        item: {},

        select2Settings: Constants.select2Settings,
        taskTypeList: [],
        taskUrgencyList: [],
        employeeList: [],
        curatorList: [],

        canChangeEmployee: false,
      };
    },
    created() {
      Utils.loadSelectOptions('taskTypeList', this.taskTypeList);
      Utils.loadSelectOptions('taskUrgencyList', this.taskUrgencyList);
      this.loadEmployee();

      this.init();
    },
    methods: {
      init() {
        if (this.v$ && this.v$.$error) {
          this.v$.$reset();
        }

        this.tab = 1;
        this.needCurator = this.isFoiv;
        this.item = {
          name: '',
          taskTypeId: 0,
          taskUrgencyId: 0,
          executorId: 0,
          curatorId: null,
          description: '',
          issueExecutorsIds: [],
          deadline: null,
          issueDocumentsIds: [],
          files: [],
          needReport: false,
        };
      },
      loadEmployee() {
        if (this.isEap) {
          this.employeeList = [];
          API_EAP.getEmployeeList().then((response) => {
            if (response.data) {
              response.data.forEach((el) => {
                this.employeeList.push(el);
              });

              let profile = this.$store.state.auth?.oidc?.profile;
              if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
              this.isFoiv = profile?.roleIds.some((x) => x == 8 || x == 7);
              this.needCurator = this.isFoiv;

              const curr = this.employeeList.find((x) => x.id == profile?.employee?.id);
              if (curr != null) {
                this.canChangeEmployee = false;
                this.item.executorId = profile?.employee?.id;
              } else {
                this.canChangeEmployee = true;
                this.curatorList = [];
                API_EAP.getCuratorList().then((res) => {
                  if (res.data) {
                    res.data.forEach((el) => {
                      this.curatorList.push(el);
                    });
                  }
                });
              }
            }
          });
        } else {
          this.canChangeEmployee = true;
          API.getEmployeeList().then((response) => {
            if (response.data) {
              response.data.forEach((el) => {
                this.employeeList.push(el);
              });
            }
          });
        }
      },
      close() {
        this.$emit('close');
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        const itemToSave = Object.assign({}, this.item);
        itemToSave.issueDocumentsIds = itemToSave.files.map((x) => x.id);
        itemToSave.files = null;
        itemToSave.curatorId = this.needCurator ? this.item.curatorId : null;

        if (this.isEap) {
          API_EAP.update(itemToSave)
            .then((response) => {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              if (response && response.data.id) {
                this.$emit('save', true);
              }
            })
            .catch(() => {
              Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            });
        } else {
          API.update(itemToSave)
            .then((response) => {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              if (response && response.data.id) {
                this.$emit('save', true);
              }
            })
            .catch((error) => {
              console.error(error.response);
              Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            });
        }
      },

      fileUploaded(e) {
        this.item.files.push(e.data);
      },
      removeFile(e) {
        const file = this.item.files.find((x) => x.id == e.id);
        const index = this.item.files.indexOf(file);
        if (index !== -1) {
          this.item.files.splice(index, 1);
        }
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .form__body {
    &-inputBlock {
      padding-bottom: 16px !important;

      &:nth-last-child(2) {
        margin-bottom: 16px !important;
      }

      textarea {
        resize: none;
        height: 200px;
      }

      &.deadline {
        display: flex;
        flex-wrap: wrap;

        p {
          width: 100%;
        }

        input,
        .date {
          max-width: 100px;
          width: 100%;
          margin-right: 8px;
        }
      }

      .added {
        display: flex;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }

        &-select,
        &-options {
          width: 50%;
        }

        &-select {
          margin-right: 8px;
        }

        &-options {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .icon {
            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-end;

      &.footer {
        margin-top: 16px;
      }

      button {
        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }

  .form__body-inputBlock.deadline.taskModal::v-deep {
    .v3dp__popout {
      left: 0;
    }
  }

  .form__body-inputBlock.required .form-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }

  @media (max-width: 767px) {
    .form__body-btns.header {
      flex-wrap: wrap;

      button {
        margin-bottom: 6px;

        &:not(:last-of-type) {
          margin-right: 0px;
        }
      }
    }
  }
</style>

<style lang="scss">
  @media (max-width: 767px) {
    .form__body-inputBlock.deadline.taskModal {
      .v3dp__datepicker {
        width: 100%;
      }

      .v3dp__popout {
        left: 0;
        width: 100% !important;
      }
    }
  }
</style>
