export function updateCountriesAgreements(state, res) {
  const { data = {} } = res;
  if (data?.items) {
    state.countriesAgreements = data.items;
  }
}

export function updateCountriesGroupAgreements(state, res) {
  const { data = {} } = res;
  if (data?.items) {
    state.countriesGroupAgreements = data.items;
  }
}

export function setAgreementsPage(state, res) {
  const { data = {} } = res;
  state.agreementsPage = data;
}

export function joinAgreementsPage(state, res) {
  const { data = {} } = res;
  const { items = [], firstItemNumber, pageNumber } = data;
  state.agreementsPage.items = state.agreementsPage.items.concat(items);
  state.agreementsPage.firstItemNumber = firstItemNumber;
  state.agreementsPage.pageNumber = pageNumber;
}
