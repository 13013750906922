<template>
  <section>
    <ModalComponent :title="currentNotification?.description?.title" @close="onRead" v-model="dialogState">
      <div class="notification-container">
        <div class="notification__information-container">
          <p class="notification__text">{{ currentNotification?.description?.message }}</p>
          <a class="notification__url" :href="currentUrl">{{ currentUrl }}</a>
          <p class="notification__remaining">Осталось {{ notificationList.length + 1 }}</p>
        </div>
        <ButtonStock title="Прочитано" class="button-read" @click="onRead" />
      </div>
    </ModalComponent>
  </section>
</template>

<script>
  import Api from '@/common/api/notification';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  export default {
    name: 'NotificationComponent',
    components: {
      ModalComponent,
      ButtonStock,
    },
    data() {
      return {
        dialogState: false,
        notificationList: [],
        currentNotification: null,
        isLoaded: false,
      };
    },
    mounted() {},
    created() {},
    computed: {
      currentUrl: function () {
        if (this.currentNotification) {
          const route = this.$router.resolve({ name: 'home' });
          const absoluteURL = new URL(route.href, window.location.href).href;
          const entityId = this.currentNotification.entityId == 0 ? '' : this.currentNotification.entityId;
          return absoluteURL + this.currentNotification.description.catalog.baseUrl + entityId;
        } else return '';
      },
    },
    watch: {
      $route(newRoute) {
        if (newRoute?.name !== 'Logout' && newRoute?.name !== 'Login' && newRoute.name !== 'Callback') {
          if (!this.isLoaded) this.loadPostponed();
          else this.loadNotifications();
        }
      },
    },
    methods: {
      loadPostponed() {
        setTimeout(() => {
          this.loadNotifications();
          this.isLoaded = true;
        }, 1000);
      },
      loadNotifications() {
        Api.searchUnread({ searchNew: true }).then((response) => {
          this.$nextTick(() => {
            this.notificationList = response.data;
            if (this.notificationList.length > 0) {
              this.currentNotification = this.notificationList.pop();
              this.dialogState = true;
            }
          });
        });
      },
      onRead() {
        Api.markAsRead(this.currentNotification.id).then(() => {
          if (this.notificationList.length > 0) {
            this.currentNotification = this.notificationList.pop();
          } else {
            this.currentNotification = null;
            this.dialogState = false;
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .notification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .notification__information-container {
    margin-bottom: 20px;
    max-width: 90%;
  }
  .notification__url {
    word-break: break-all;
  }
  .notification__remaining {
    margin-top: 20px;
    width: 100%;
    color: #aaa;
  }
</style>
