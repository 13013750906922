<template>
  <div class="library__container-table-block table-container js-table-arrow">
    <table class="stockTable">
      <thead>
        <tr>
          <th>Документ</th>
          <th>Категория</th>
          <th>Тип</th>
          <th>Дата<br />создания</th>
          <th>Расширение</th>
          <th>Размер, Мб</th>
          <th>Пользователь</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items.documents" :key="item.key">
          <td>{{ item.name }}</td>
          <td>{{ item.mpkEventName }}</td>
          <td>{{ item.documentType ? item.documentType.name : '' }}</td>
          <td>{{ $momentFormat(item.created, 'DD.MM.YYYY HH:mm') }}</td>
          <td class="text-center">
            <!-- {{ item.file ? item.file.extension : '' }} -->
            <template v-if="item.file">
              <div class="document-container">
                <span class="document-name">{{ item.file.extension }}</span>
                <IconComponent class="document-icon" name="fileDoc" />
              </div>
            </template>
            <template v-else> - </template>
          </td>
          <td>{{ item.file ? item.file.fileSizeMb : '' }}</td>
          <td>{{ item.createdBy }}</td>
          <td class="edit">
            <IconComponent @click="downloadDoc(item)" name="download_transparent" title="Скачать документ" />
            <IconComponent @click="copyLink(item)" name="copy" title="Скопировать ссылку на документ" />
            <IconComponent @click="editDoc(item)" name="mode" title="Редактировать документ" />
            <IconComponent @click="deleteDoc(item)" name="delete" title="Удалить документ" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'LibraryTableComponent',
    components: { IconComponent },
    props: {
      items: {
        type: Object,
        default: null,
      },
    },
    watch: {
      items: {
        handler: function (val) {
          console.log('new items: ' + val);
        },
      },
    },
    emits: ['downloadDoc', 'copyLink', 'deleteDoc', 'editDoc'],
    methods: {
      downloadDoc(item) {
        this.$emit('downloadDoc', item);
      },
      copyLink(item) {
        this.$emit('copyLink', item);
      },
      deleteDoc(item) {
        this.$emit('deleteDoc', item);
      },
      editDoc(item) {
        this.$emit('editDoc', item);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../style/library';
</style>
