import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/eapassignment/list', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/eapassignment/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/eapassignment/update?id=${data.id}`, data);
    else return axios.post(`/api/eapassignment/create`, data);
  },
  delete: function (id) {
    return axios.delete('/api/eapassignment/delete?id=' + id);
  },
  getEventForList: function (request) {
    return axios.get('/api/eapassignment/getAllEvents', { params: request });
  },
  getTotal: function (request) {
    return axios.get('/api/eapassignment/getTotalByCountries', { params: request });
  },
  refuseReport: function (data) {
    return axios.put(`/api/eapassignment/refuseReport?id=${data.assignmentId}`, data);
  },
  approveReport: function (data) {
    return axios.put(`/api/eapassignment/approveReport?id=${data.id}`, data);
  },
  updateReport: function (data) {
    return axios.put(`/api/eapassignment/updateReport?id=${data.id}`, data);
  },
  setPostponed: function (id, state) {
    return axios.put(`/api/eapassignment/setPostponed?id=${id}&state=${state}`);
  },
  export: function (request) {
    return Utils.downloadFile('/api/eapassignment/export', '', request);
  },
  getResponsibleCandidates: function(params) {
    return axios.get('/api/eapassignment/getresponsiblecandidates', {
      params
    });
  },
  getCorrespondentsCandidates: function(params) {
    return axios.get('/api/eapassignment/getcorrespondentscandidates', {
      params
    });
  }
};
