import axios from 'axios';

export default {
  searchUnread: function (request) {
    return axios.get(`/api/notification/searchUnread`, { params: request });
  },
  markAsRead: function (id) {
    return axios.put(`/api/notification/markAsRead?notificationId=${id}`);
  },
  getDescriptions: function (request) {
    return axios.get(`/api/notification/getDescriptions`, { params: request });
  },
  updateDescription: function (item) {
    return axios.put(`/api/notification/updateDescription`, item);
  },
};
