<template>
  <div class="apexchart-pie" :class="classMod">
    <apexchart
      class="apexchart-pie__diagram"
      ref="apexchart"
      :type="diagramType"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>
    <div class="apexchart-legend">
      <div class="apexchart-legend__item" v-for="(item, index) in Object.keys(seriesProp)" :key="item">
        <div class="apexchart-legend__marker" :style="{ backgroundColor: chartColors[index] }"></div>
        <p
          class="apexchart-legend__text"
          @mouseover="mouseoverLegendItem(item)"
          @mouseleave="mouseleaveLegendItem(item)"
        >
          {{ item }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import constants from '@/components/ChartComponents/chartConstants.js';

  export default {
    props: {
      seriesProp: {
        type: Object,
        default: () => ({
          value1: 2,
          value2: 4,
        }),
      },
      diagramType: {
        type: String,
        validator: (value) => ['pie', 'donut'].includes(value),
        default: 'pie',
      },
      classMod: {
        type: String,
        default: '',
        validator: (value) => ['adaptive', ''].includes(value),
      },
    },
    watch: {
      seriesProp: function () {
        this.createChart();
      },
    },
    data() {
      return {
        // data for templates
        labelsArray: [],
        countArray: [],
        chartOptions: {},
        chartColors: constants.colors,
      };
    },
    methods: {
      mouseoverLegendItem(name) {
        this.$refs.apexchart.toggleSeries(name);
      },
      mouseleaveLegendItem(name) {
        if (!this.$refs.apexchart) return;
        this.$refs.apexchart.toggleSeries(name);
      },

      // templates
      createChart() {
        this.labelsArray = [];
        this.countArray = [];
        // Массив для категорий
        Object.entries(this.seriesProp).forEach(([key, value]) => {
          this.labelsArray.push(key);
          this.countArray.push(value);
        });

        this.chartOptions = {
          chart: {
            width: 330,
          },
          dataLabels: {
            enabled: true,
            formatter: function (value, { seriesIndex, w }) {
              return w.globals.series[seriesIndex];
            },
            style: {
              fontSize: '15px',
              lineHeight: '16px',
              fontFamily: 'Fira Sans',
            },
          },
          colors: this.chartColors,
          labels: this.labelsArray,
          legend: {
            show: false,
            position: 'bottom',
            showForSingleSeries: true,
            fontSize: '15px',
            lineHeight: '16px',
            fontFamily: 'Fira Sans',
          },
          noData: {
            text: 'Данные отсутствуют',
          },
        };
        this.series = this.countArray;
      },
    },
    created() {
      this.createChart();
    },
  };
</script>

<style lang="scss" scoped>
  .apexchart-legend {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 12px;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    &__item {
      display: flex;
      align-items: center;
      min-width: 120px;
      max-width: 120px;

      gap: 8px;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: -0.3px;
      font-family: 'Fira Sans';
      cursor: pointer;

      @media (max-width: $mobile) {
        max-width: 100%;
      }
    }

    &__marker {
      min-width: 12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #000;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .apexchart-pie {
    &.adaptive {
      display: flex;
      gap: 48px;

      @media (max-width: $laptop) {
        flex-direction: column;
        gap: 40px;
      }
      @media (max-width: $mobile) {
        gap: 24px;
      }

      .apexchart-pie {
        &__diagram {
          min-width: 345px;
          max-width: 345px;
          width: 345px;

          @media (max-width: $mobile) {
            max-width: 100%;
            min-width: 100%;
          }
        }
      }

      .apexchart-legend {
        margin-top: 0;
        gap: 12px;

        &__item {
          min-width: 250px;
          max-width: 250px;

          @media (max-width: $mobile) {
            max-width: 100%;
          }
        }
      }
    }
  }
</style>
