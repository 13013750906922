<template>
  <div class="work-screen container-page p-0">
    <div class="d-flex justify-content-between">
      <div class="select d-flex align-items-center">
        <span>Страна:</span>
        <ComponentInput
          class="select2"
          :select2Options="countries"
          placeholder="Выберите страну..."
          type="select2"
          v-model="country"
          :select2Settings="select2Settings"
        />
      </div>
      <ButtonStock style="margin-right: 20px" title="Материалы к МПК" />
    </div>
    <!--
      grid обертка нужна для того чтобы создать сетку на которой виджеты
     -можно распологать
     -перемещать
    -->
    <smart-widget-grid :resizable="false" :layout="layout" :responsive="true" :row-height="140" :margin="[20, 36]">
      <!-- Template нужен для того чтобы сетка смогла увидеть виджет -->
      <template #0>
        <WidgetStock title="Горячие показатели" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div>
            <ul class="indicators__list">
              <IndicatorItem
                v-for="item in indicatorList"
                :key="item.id"
                @click="activeTab = item.id"
                :active="item.id === activeTab"
                :percent="item.percent"
                :count="item.count"
                :title="item.title"
              />
            </ul>
            <div class="d-flex w-100 justify-content-end indicators__footer">
              <Select2
                class="select2"
                v-model="selected"
                :settings="{ minimumResultsForSearch: 20 }"
                :options="options"
              />
              <ButtonStock class="mx-2" title="Страновая аналитика" />
            </div>
          </div>
        </WidgetStock>
      </template>

      <template #1>
        <div class="chart__inner" v-if="activeTab == 1 || activeTab == 2 || activeTab == 3 || activeTab == 5">
          <LineChartFour
            format-label=" млдр $"
            :chart-data="[30, 4, 5, 3, 1, 10]"
            :chart-data-second="[30, 4, 5, 3, 1, 10].reverse()"
            :chart-data-three="[36, 40, 20, 31, 1, 10]"
            :chart-data-four="[36, 40, 20, 31, 1, 10].reverse()"
            background-color-three="#2F84EB"
            background-color-four="#31BC00"
            point-color-three="#2F84EB"
            point-color-four="#31BC00"
            background-color="#01A39D"
            point-color="#01A39D"
            background-color-two="#787F8F"
            point-color-two="#787F8F"
            border-color="#01A39D"
            border-color-two="#787F8F"
            border-color-three="#2F84EB"
            border-color-four="#31BC00"
            :labels="['2017', '2018', '2019', '2020']"
            :displayDataLabels="false"
          />
          <div class="d-flex align-items-center mt-4">
            <div class="indicator-round d-flex align-items-center mr-4">
              <span class="rounded-circle"></span>
              <p>Товарооборот</p>
            </div>
            <div class="indicator-round d-flex align-items-center mr-4">
              <span class="rounded-circle"></span>
              <p>Экспорт</p>
            </div>
            <div class="indicator-round d-flex align-items-center mr-4">
              <span class="rounded-circle"></span>
              <p>Импорт</p>
            </div>
            <div class="indicator-round d-flex align-items-center">
              <span class="rounded-circle"></span>
              <p>Сальдо</p>
            </div>
          </div>
        </div>
        <div class="chart__inner" v-if="activeTab == 4">
          <LineChartDouble
            format-label=" млд $"
            :chart-data="[30, 4, 5, 3, 1, 10]"
            :chart-data-second="[30, 4, 5, 3, 1, 10].reverse()"
            background-color="#01A39D"
            point-color="#01A39D"
            background-color-two="#787F8F"
            point-color-two="#787F8F"
            border-color="#01A39D"
            border-color-two="#787F8F"
            :labels="['2017', '2018', '2019', '2020']"
            :displayDataLabels="false"
          />
          <div class="d-flex align-items-center mt-4">
            <div class="indicator-round d-flex align-items-center mr-4">
              <span class="rounded-circle"></span>
              <p>Абсалютное значение</p>
            </div>
            <div class="indicator-round d-flex align-items-center mr-4">
              <span class="rounded-circle"></span>
              <p>Прошедшее значение</p>
            </div>
          </div>
        </div>
      </template>
    </smart-widget-grid>
    <smart-widget-grid :resizable="false" :layout="layoutTwo" :responsive="true" :margin="[20, 36]">
      <!-- Template нужен для того чтобы сетка смогла увидеть виджет -->

      <template #0>
        <WidgetStock title="профили МПК: италия" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="text-inner">
            <strong>Межправительственная коммисия по международному сотрудничеству</strong>
            <span>Рабочая группа по инвестициям</span>
            <span>Рабочая группа по промышленности (Минпромторг)</span>
          </div>
        </WidgetStock>
      </template>
      <template #1>
        <WidgetStock title="Мои мероприятия" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <table class="table-inner">
            <colgroup width="50%"></colgroup>
            <colgroup width="25%"></colgroup>
            <colgroup width="25%"></colgroup>
            <thead>
              <th>Название</th>
              <th>Дата проведения</th>
              <th>Статус</th>
            </thead>
            <tbody>
              <tr>
                <td>Встреча с Президентом</td>
                <td>15.05.22</td>
                <td>Направление Запросов</td>
              </tr>
              <tr class="active">
                <td>Проведение Росчасти</td>
                <td>20.04.22</td>
                <td>Подготовка протокола</td>
              </tr>
              <tr>
                <td>Заседание МПК</td>
                <td></td>
                <td>Согласование даты</td>
              </tr>
            </tbody>
          </table>
        </WidgetStock>
      </template>
      <template #2>
        <WidgetStock title="проекты" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="project__wrapper">
            <div class="projects__inner">
              <div class="projects__head mb-4">
                <Select2
                  :settings="{ minimumResultsForSearch: 20 }"
                  class="select2 m-0 w-100"
                  v-model="projectSelect"
                  :options="projectSelectOpt"
                />
                <ButtonStock class="ml-2" title="Выбрать" />
              </div>
              <TableProject :optionsThead="optionsThead" :options="notifArray" />
              <ButtonStock class="w-100 mt-2" title="Все проекты" />
            </div>
            <div class="chart__wrapper">
              <p class="mb-5">Всего проектов – 100, в процессе реализации – 50, реализовано – 30, заморожено – 20</p>
              <ColumnChartHorizontal
                :height="500"
                index-axis="y"
                :bar-percentage="1"
                :colors="[
                  '#86979f',
                  '#00B2FF',
                  '#fa9872',
                  '#00a671',
                  '#00a3d4',
                  '#fac9fc',
                  '#a4e0ae',
                  '#dbe7b4',
                  '#86979f',
                  '#00B2FF',
                  '#fa9872',
                  '#00a671',
                  '#00a3d4',
                  '#fac9fc',
                  '#a4e0ae',
                  '#dbe7b4',
                ]"
                :chart-data="[30, 54, 21, 20, 69, 30, 54, 21, 20, 69, 30, 54, 21, 20, 69, 30, 54, 21, 20, 69]"
                :labels="[
                  'Металлургия',
                  'Энергетика',
                  'Промышленность',
                  'Фармацевтика',
                  'Другие отрасли',
                  'Металлургия',
                  'Энергетика',
                  'Промышленность',
                  'Фармацевтика',
                  'Другие отрасли',
                  'Металлургия',
                  'Энергетика',
                  'Промышленность',
                  'Фармацевтика',
                  'Другие отрасли',
                  'Металлургия',
                  'Энергетика',
                  'Промышленность',
                  'Фармацевтика',
                  'Другие отрасли',
                ]"
              />
            </div>
          </div>
        </WidgetStock>
      </template>
      <template #3>
        <WidgetStock title="мониторинг исполнения поручений МПК" :onGearFunc="gearMethod" :onCloseFunc="closeMethod">
          <div class="p-3">
            <div class="monitoring-content__chart">
              <ChartBarMonitoring :seriesProp="dataMonitoringByFoiv" :nn="nnByFoiv" />
            </div>
            <ButtonStock class="chart-stacked__btn" title="Подробная информация" />
          </div>
        </WidgetStock>
      </template>
    </smart-widget-grid>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ChartBarMonitoring from '@/components/ChartComponents/ChartBarMonitoring';
  import ColumnChartHorizontal from '@/components/column-chart-horizontal';
  import LineChartDouble from '@/components/lineChartDouble';
  import LineChartFour from '@/components/lineChartFour';

  import ButtonStock from '../../components/buttons/ButtonStock';
  import WidgetStock from '../../components/widgets/WidgetStock';
  import IndicatorItem from './components/indicatorItem';
  import TableProject from './components/tableProject';

  export default {
    name: 'workScreenPage',
    components: {
      ChartBarMonitoring,
      ColumnChartHorizontal,
      LineChartDouble,
      LineChartFour,
      ComponentInput,
      TableProject,
      ButtonStock,
      IndicatorItem,
      WidgetStock,
    },
    methods: {
      // Для каждого виджета свой метод гир(опции)
      gearMethod() {
        console.log('Gear');
      },
      // Закрытие(коллапс) виджета
      closeMethod() {
        console.log('Close');
      },
    },
    data() {
      return {
        dataMonitoringByFoiv: [
          { data: [1, 2, 3, 4], name: 'name' },
          { data: [1, 2, 3, 4].reverse(), name: 'name' },
          { data: [1, 2, 3, 4], name: 'name' },
          { data: [1, 2, 3, 4].reverse(), name: 'name' },
        ],
        nnByFoiv: 1,
        activeTab: 1,
        layout: [
          // i = id элемента template
          { x: 0, y: 0, w: 12, h: 2.5, i: '0' },
          { x: 0, y: 0, w: 12, h: 3, i: '1' },
        ],
        layoutTwo: [
          // i = id элемента template
          { x: 0, y: 0, w: 4, h: 3, i: '0' },
          { x: 4, y: 0, w: 6, h: 3, i: '1' },
          { x: 0, y: 0, w: 10, h: 9, i: '2' },
          { x: 0, y: 0, w: 6, h: 7, i: '3' },
        ],
        country: this.$route.query && this.$route.query.countryId > 0 ? this.$route.query.countryId : null,
        countries: [],
        currentCountry: null,
        select2Settings: Constants.select2Settings,
        indicatorList: [
          {
            id: 1,
            title: 'Товарооборот',
            count: '20,3',
            percent: '19,7',
          },
          {
            id: 2,
            title: 'Импорт',
            count: '10,2',
            percent: '-6,5',
          },
          {
            id: 3,
            title: 'Экспорт',
            count: '10,1',
            percent: '-29,8',
          },
          {
            id: 4,
            title: 'Несырьевой\n' + 'неэнергетический\n' + 'экспорт',
            count: '2,0',
            percent: '-20,2',
          },
          {
            id: 5,
            title: 'Сальдо',
            count: '2,0',
            percent: '-20,2',
          },

          // {
          //   id: 5,
          //   title: "Накопленные ПИ\n" + "из страны",
          //   count: "20,3",
          //   percent: "19,7",
          // },
          // {
          //   id: 6,
          //   title: "Накопленные ПИ\n" + "в страну",
          //   count: "20,3",
          //   percent: "19,7",
          // },
        ],
        options: [{ text: 'Январь-ноябрь', id: '1' }],
        selected: 1,
        notifArray: [
          {
            id: 1,
            name: 'Поставка удобрений',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Промышлен...',
            status: 1,
          },
          {
            id: 2,
            name: 'Строительство АЭС',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Энергетика...',
            status: 2,
          },
          {
            id: 3,
            name: 'Поставка удобрений',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Промышлен...',
            status: 3,
          },
          {
            id: 4,
            name: 'Строительство порта',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Энергетика...',
            status: 4,
          },
          {
            id: 5,
            name: 'Поставка удобрений',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Промышлен...',
            status: 1,
          },
          {
            id: 6,
            name: 'Строительство АЭС',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Энергетика...',
            status: 2,
          },
          {
            id: 7,
            name: 'Поставка удобрений',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Промышлен...',
            status: 3,
          },
          {
            id: 8,
            name: 'Строительство порта',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Энергетика...',
            status: 4,
          },
          {
            id: 5,
            name: 'Поставка удобрений',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Промышлен...',
            status: 1,
          },
          {
            id: 6,
            name: 'Строительство АЭС',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Энергетика...',
            status: 2,
          },
          {
            id: 7,
            name: 'Поставка удобрений',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Промышлен...',
            status: 3,
          },
          {
            id: 8,
            name: 'Строительство порта',
            init: 'ООО «АЛЬЯНС»',
            industry: 'Энергетика...',
            status: 4,
          },
        ],
        optionsThead: [
          { title: 'Название', id: 1 },
          { title: 'Инициатор', id: 2 },
          { title: 'Отрасль', id: 3 },
          { title: 'Статус', id: 4 },
        ],
        projectSelectOpt: [
          { text: 'Все', id: 1 },
          { text: 'Промышлен', id: 2 },
          { text: 'Энергетика', id: 3 },
        ],
        projectSelect: 1,
      };
    },
    created() {
      Utils.searchNsi('countryListWithData', {}).then((response) => {
        this.countries = response.data.map((c) => ({
          id: c.id,
          text: c.name,
          fullName: c.nameFull || c.name,
          code: c.code,
          groupId: c.countryGroupId,
        }));
        this.currentCountry = this.countries[0];
      });
    },
    watch: {
      currentCountry: function () {
        // let tab = this.tabData.find((t) => t.id === 2);
        //tab.hidden = this.currentCountry?.groupId !== Constants.countryGroupEAEC;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../assets/theme/default/colors';

  .indicators {
    &__footer {
      flex-wrap: wrap;
    }
    &__list {
      gap: 16px;
      overflow: auto;
      padding: 24px 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .chart__inner {
    background: #ffffff;
    padding: 42px;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
  }
  .chart__wrapper {
    padding: 18px;
    width: 100%;
  }
  .chart-stacked__wrapper {
    padding: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 768px) {
      min-width: 768px;
      align-items: flex-start;
    }
    p {
      margin-right: 130px;
      margin-bottom: 0;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #343c44;
    }
  }

  .chart__header {
    display: flex;
    justify-content: center;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-size: 25px;
    line-height: 29px;
    color: $dark;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      span {
        margin-left: 0 !important;
      }
    }
    strong {
      font-weight: bold;
    }
    span {
      margin-left: 32px;
      font-size: 14px;
      strong {
        font-size: 25px;
        font-weight: bold;
      }
    }
    span:nth-child(3) {
      padding: 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 113.9%;
      color: #ffffff;
      background: #ff5a5a;
      box-shadow: 0px 7px 17px rgba(84, 222, 0, 0.11);
      border-radius: 5px;
    }
  }
  .select2::v-deep {
    outline: none;
    max-width: 161px;
    width: 100%;
    @media (max-width: 576px) {
      max-width: 100%;
      margin: -12px 8px 12px 8px;
    }
    // Контейнер ширина
    .select2-container {
      outline: none;
      min-width: 161px;
      width: 100% !important;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 40px;
        color: #343c44;
      }
    }

    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: calc(2.25rem + 6px) !important;
      border-radius: 2px !important;
      border: 1px solid #d6e4ec;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    // Стрелочка
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 1px;
      border-left: 3px solid #343c44;
      border-bottom: 3px solid #343c44;
      transform: rotate(315deg);
      margin-left: -10px;
      margin-top: -6px;
    }

    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #343c44;
    }
  }
  .text-inner {
    min-width: 420px;
    padding: 28px 16px 14px 16px;
    strong {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
    span {
      padding-left: 16px;
      display: block;
      font-weight: normal;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .table-inner {
    min-width: 640px;

    width: 100%;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    padding: 16px;
    .active {
      background-color: #ededed;
    }
    th {
      padding-bottom: 16px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 12px;
    }
  }
  .projects__inner {
    padding: 18px;
    max-width: 45%;
  }
  .projects__head {
    display: flex;
    align-items: center;
  }
  .project__wrapper {
    min-width: 1024px;
    display: flex;
    justify-content: space-between;
  }
  .select {
    margin-left: 20px;
    width: auto;
    max-width: 320px;
    min-width: 100px;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-right: 16px;
      color: $color8;
    }
  }
  .indicator-round {
    span {
      background-color: #018bbf;
      width: 14px;
      height: 14px;
    }
    p {
      margin-left: 13px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  .indicator-round:nth-child(2) {
    p {
      margin-left: 13px;
    }
    span {
      background-color: #787f8f;
    }
  }
  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }
  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }
</style>
