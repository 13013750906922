import { authAxios } from '@/common/axios';

/**
 * Метод проверяет доступ к переданному скоупу
 * @param {Number} permId - id скоупа
 * @returns {Promise<{data: boolean}>} возвращает информацию о доступе
 */

export function checkPermission(permId) {
  return authAxios.get(`/api/permission/check?permId=${permId}`);
}
