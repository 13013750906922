import { getDefaultState } from './utils';

export function setInitiative(state, initiative) {
  state.initiative = initiative;
}

export function setProposedMeasureJustificationDto(state, proposedMeasureJustificationDto) {
  state.initiative.proposedMeasureJustificationDto = proposedMeasureJustificationDto;
}

export function setMainInfo(state, mainInfo) {
  Object.assign(state.initiative, mainInfo);
}

export function setEmployee(state, employee) {
  Object.assign(state.initiative.employee, employee);
}

export function setOrganization(state, organization) {
  Object.assign(state.initiative.employee.organization, organization);
}

export function setRates(state, rates) {
  state.initiative.rates = rates;
}

export function setRequestFiles(state, requestFiles) {
  state.initiative.requestFiles = requestFiles;
}

export function setDataFiles(state, dataFiles) {
  state.initiative.dataFiles = dataFiles;
}

export function setComments(state, comments) {
  state.initiative.comments = comments;
}

export function setTimeline(state, timeline) {
  state.initiative.timeline = timeline;
}

export function resetState(state) {
  Object.assign(state, getDefaultState());
}

export function updateListTableHeaderInitiative(state, array) {
  state.tableHeaderInitiative = array;
}
