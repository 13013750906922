<template>
  <header class="header">
    <nav class="navbar">
      <router-link class="logo-link" to="/">
        <div class="logo-link__block">
          <img
            src="@/assets/images/logo.png"
            class="logo"
            alt="МИНИСТЕРСТВО ЭКОНОМИЧЕСКОГО РАЗВИТИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ"
          />
        </div>
      </router-link>
      <div class="title-block">
        <h1 class="title">МИНИСТЕРСТВО ЭКОНОМИЧЕСКОГО РАЗВИТИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</h1>
        <p class="sub-title">Единый портал внешнеэкономической информации</p>
      </div>
      <div class="mail ml-auto" v-if="userLoaded"></div>
      <!-- Right navbar links -->
      <div class="navbar__right-col" v-if="userLoaded && $store.state.auth?.oidc?.profile">
        <div class="user d-flex">
          <div class="avatar">
            <img
              v-if="$store.state.auth?.oidc?.profile.employee?.picture"
              :src="$store.state.auth?.oidc?.profile.employee?.picture"
              alt="avatar"
            />
            <img v-else src="@/assets/images/avatar.png" alt="avatar" />
          </div>
          <div class="user__text">
            <router-link to="/settings">
              <div class="user__name">
                <span v-if="$store.state.auth?.oidc?.profile.employee">{{ userName }}</span>
                <span v-else>{{ $store.state.auth?.oidc?.profile.email }}</span>
              </div>
            </router-link>
            <div class="user__position">
              <p>{{ $store.state.auth?.oidc?.profile.roleTitles }}</p>
            </div>
          </div>
        </div>
        <div class="icons">
          <div class="messenger icons__item">
            <router-link to="/messenger" title="Сообщения">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.6654 8.99984C18.6654 8.2665 18.0654 7.6665 17.332 7.6665H6.66536C5.93203 7.6665 5.33203 8.2665 5.33203 8.99984M18.6654 8.99984V16.9998C18.6654 17.7332 18.0654 18.3332 17.332 18.3332H6.66536C5.93203 18.3332 5.33203 17.7332 5.33203 16.9998V8.99984M18.6654 8.99984L11.9987 13.6665L5.33203 8.99984"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
            <div class="indicator" v-if="unreadMessageCount > 0"></div>
          </div>
          <div class="notification icons__item">
            <router-link to="/notifications" title="Уведомления">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.1533 19.6667C13.0361 19.8687 12.8679 20.0364 12.6655 20.153C12.4631 20.2696 12.2336 20.331 12 20.331C11.7664 20.331 11.5369 20.2696 11.3345 20.153C11.1321 20.0364 10.9639 19.8687 10.8467 19.6667M16 11C16 9.93913 15.5786 8.92172 14.8284 8.17157C14.0783 7.42143 13.0609 7 12 7C10.9391 7 9.92172 7.42143 9.17157 8.17157C8.42143 8.92172 8 9.93913 8 11C8 15.6667 6 17 6 17H18C18 17 16 15.6667 16 11Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
            <div class="indicator" v-show="hasNotViewNotification"></div>
          </div>
        </div>
        <div class="logout" v-if="userLoaded">
          <router-link to="/logout" title="Выйти">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.3333 18.75H15.0833V17.25H14.3333V18.75ZM6 16.6667H5.25H6ZM7.33333 6V5.25V6ZM14.3333 6.75H15.0833V5.25H14.3333V6.75ZM14.1363 14.803L13.606 15.3333L14.6666 16.394L15.197 15.8637L14.1363 14.803ZM18 12L18.5303 12.5303L19.0606 12L18.5303 11.4697L18 12ZM15.197 8.13634L14.6666 7.60601L13.606 8.66667L14.1363 9.197L15.197 8.13634ZM10 11.25H9.25V12.75H10V11.25ZM14.3333 17.25H7.33333V18.75H14.3333V17.25ZM7.33333 17.25C7.17862 17.25 7.03025 17.1885 6.92085 17.0791L5.86019 18.1398C6.2509 18.5305 6.7808 18.75 7.33333 18.75V17.25ZM6.92085 17.0791C6.81146 16.9697 6.75 16.8214 6.75 16.6667H5.25C5.25 17.2192 5.46949 17.7491 5.86019 18.1398L6.92085 17.0791ZM6.75 16.6667V7.33333H5.25V16.6667H6.75ZM6.75 7.33333C6.75 7.17862 6.81146 7.03025 6.92085 6.92085L5.86019 5.86019C5.46949 6.2509 5.25 6.7808 5.25 7.33333H6.75ZM6.92085 6.92085C7.03025 6.81146 7.17862 6.75 7.33333 6.75V5.25C6.7808 5.25 6.2509 5.46949 5.86019 5.86019L6.92085 6.92085ZM7.33333 6.75H14.3333V5.25H7.33333V6.75ZM15.197 15.8637L18.5303 12.5303L17.4696 11.4697L14.1363 14.803L15.197 15.8637ZM18.5303 11.4697L15.197 8.13634L14.1363 9.197L17.4696 12.5303L18.5303 11.4697ZM18 11.25L10 11.25V12.75L18 12.75V11.25Z"
              />
            </svg>
          </router-link>
        </div>
      </div>
      <div class="login ml-auto" v-else>
        <router-link to="/login">Войти</router-link>
      </div>
    </nav>
  </header>
</template>

<script>
  import Constants from '@/common/constants';
  import connection from '@/common/message-hub.js';

  import ChatApi from '@/modules/messenger/api/index';
  import * as notificationsApi from '@/modules/notifications/api/notificationsApi.js';

  const MAX_RETRIES = 5;
  const RETRY_DELAY = 1000;
  const POLLING_INTERVAL = 10000;

  export default {
    name: 'HeaderComponent',
    data() {
      return {
        unreadMessageCount: 0,
        notifications: [],
        isPageVisible: true,
        retryCount: 0,
      };
    },
    computed: {
      unreadCount() {
        return this.$store.state.messenger?.unreadMessages > 0;
      },
      userLoaded() {
        return this.$store.state.auth?.oidc?.user;
      },
      userName() {
        let userName = '';
        if (this.$store.state.auth?.oidc?.profile?.employee?.surname) {
          userName = this.$store.state.auth?.oidc?.profile?.employee?.surname;
        }
        if (this.$store.state.auth?.oidc?.profile?.employee?.name) {
          userName += ` ${this.$store.state.auth?.oidc?.profile?.employee?.name[0]}.`;
        }
        if (this.$store.state.auth?.oidc?.profile?.employee?.patronymic) {
          userName += ` ${this.$store.state.auth?.oidc?.profile?.employee?.patronymic[0]}.`;
        }
        return userName;
      },
      hasNotViewNotification() {
        return this.notifications.find((elem) => elem.isViewed === false);
      },
      notificationAdapter() {
        const role = this.$store.state?.auth?.oidc?.user?.profile?.role;

        if (role === Constants.userRoleNames.foreignEmployeeRole) {
          return {
            notificationsApi: notificationsApi.assignmentNotificationAPI
          }
        }

        return {
          notificationsApi: notificationsApi.measureNotificationAPI
        }
      }
    },
    watch: {
      unreadCount() {
        if (this.unreadCount === true) {
          this.updateUnreadMessageCount();
          this.$store.commit('messenger/updateUnread', 0);
        }
      },
    },
    mounted() {
      connection.on('NewMessage', (title, body) => {
        this.updateUnreadMessageCount();
        if ('Notification' in window) {
          const notificationTitle = title;
          const notificationOptions = {
            body: body,
            icon: '/Emblem.png',
          };

          if (Notification && Notification.permission == 'granted' && !document.hasFocus()) {
            new Notification(notificationTitle, notificationOptions);
          }
        } else {
          // API not supported
        }
      });
      connection.on('ImportFinishedMessage', (title, body) => {
        if ('Notification' in window) {
          const notificationTitle = title;
          const notificationOptions = {
            body: body,
            icon: '/Emblem.png',
          };

          if (Notification && Notification.permission == 'granted') {
            new Notification(notificationTitle, notificationOptions);
          }
        } else {
          // API not supported
        }
      });
      this.fetchNotifications();
      document.addEventListener('visibilitychange', this.toggleVisiblePage);
    },
    beforeUnmount() {
      document.removeEventListener('visibilitychange', this.toggleVisiblePage);
    },
    methods: {
      updateUnreadMessageCount() {
        ChatApi.getUnreadMessageCount(null).then((response) => {
          this.unreadMessageCount = response.data;
        });
      },
      toggleVisiblePage() {
        this.isPageVisible = !document.hidden;
        if (!document.hidden) {
          this.fetchNotifications();
        }
      },
      fetchNotifications() {
        if (!this.isPageVisible) return false;

        this.notificationAdapter.notificationsApi
          .getNotifications()
          .then((response) => {
            this.notifications = response.data;
            setTimeout(this.fetchNotifications, POLLING_INTERVAL);
            this.retryCount = 0;
          })
          .catch((error) => {
            console.error('Произошла ошибка:', error);
            if (this.retryCount > MAX_RETRIES) {
              return;
            }
            this.retryCount++;
            setTimeout(() => {
              this.fetchNotifications();
            }, Math.pow(2, this.retryCount - 1) * RETRY_DELAY);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/colors.scss';

  header {
    width: 100%;
    max-width: 1440px;
    padding: 16px 80px;
    margin: 0 auto 24px;
    position: relative;
    z-index: 1;

    @media (max-width: 1080px) {
      padding: 16px 32px 16px 80px;
      margin-bottom: 16px;
    }

    @media (max-width: 767px) {
      padding: 12px 14px 12px 60px;
      margin-bottom: 12px;
    }

    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.33;
      color: $black;
      margin-bottom: 4px;

      @media (max-width: 1080px) {
        font-size: 16px;
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        font-size: 12px;
      }

      @media (max-width: 340px) {
        letter-spacing: -0.03em;
      }
    }

    .sub-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.42;
      letter-spacing: 0.03em;
      color: $black;
      margin: 0;

      @media (max-width: 1080px) {
        font-size: 12px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .user {
      flex-shrink: 0;
      align-items: center;

      &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.33;
        color: $color1;
        margin-left: 12px;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 1080px) {
          display: none;
        }
      }

      &__name {
        font-size: 16px;
        line-height: 1.25;
        white-space: nowrap;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a {
        color: currentColor;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .avatar {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .navbar {
      flex-wrap: nowrap;
      align-items: center;
      padding: 0;

      &__right-col {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: auto;
        padding-left: 24px;

        @media (max-width: 640px) {
          display: none;
        }
      }
    }

    .logo {
      width: 64px;
      height: 64px;

      @media (max-width: 1080px) {
        width: 60px;
        height: 60px;
      }

      @media (max-width: 767px) {
        width: 48px;
        height: 48px;
      }

      &-link {
        flex: 0 0 64px;
        margin-right: 16px;

        @media (max-width: 1080px) {
          flex-basis: 60px;
        }

        @media (max-width: 767px) {
          flex-basis: 48px;
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 8px;

      &__item {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        position: relative;

        &:hover {
          & svg {
            stroke: $color-hover-icon;
          }
        }

        & svg {
          transition: stroke ease 0.3s;
          stroke: $color1;
        }
      }
    }

    .logout {
      & svg {
        width: 24px;
        height: 24px;
        fill: $color1;
        transition: fill ease 0.3s;
      }

      &:hover {
        & svg {
          fill: $color-hover-icon;
        }
      }
    }

    .indicator {
      position: absolute;
      width: 6px;
      height: 6px;
      top: 3px;
      right: 3px;
      border-radius: 50%;
      background-color: $color-indicator;
    }

    .notification {
      & .indicator {
        right: 5px;
      }
    }
  }
</style>
