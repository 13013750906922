<template>
  <div class="page-selector-container d-flex align-items-center justify-content-between">
    <div class="d-flex flex-wrap">
      <div class="country-analytics-new__selector country-analytics-new__page-selector mr-3">
        <ComponentInput
          class="select2"
          :select2Options="pages"
          v-model="selectedPageId"
          placeholder="Выберите страницу..."
          type="select2"
          :select2Settings="select2SettingsNoClear"
          @select2OnSelect="onPageSelect"
        />
      </div>
      <div
        class="country-analytics-new__selector country-analytics-new__country-selector"
        :class="{ 'country-required': selectedPageId != 1 && !selectedCountryId }"
      >
        <ComponentInput
          class="select2"
          :select2Options="countries"
          v-model="selectedCountryId"
          placeholder="Выберите страну..."
          type="select2"
          :select2Settings="select2Settings"
          @select2OnSelect="onCountrySelect"
          @select2OnClear="onCountryClear"
        />
      </div>
    </div>
    <div class="d-flex">
      <ButtonStock
        v-if="selectedPageId != 1 && selectedCountryId"
        class="d-block btn-small"
        btn-type="blueGreen"
        title="Презентация"
        v-on:click="onOpenPresentationDialog"
      />
      <ButtonStock
        v-if="selectedPageId == 5 && selectedCountryId"
        class="d-block btn-small ml-2"
        title="Справка по стране"
        v-on:click="onOpenPoliticalSituationDialog"
      />
    </div>
  </div>

  <ModalComponent title="Скачать презентацию" @close="presentation.dialog = false" v-model="presentation.dialog">
    <div class="form__body">
      <div class="form__body-inputBlock mb-3 pb-3">
        <p>Страна</p>
        <ComponentInput
          placeholder="Укажите страну"
          :select2Settings="select2Settings"
          :select2Options="countries"
          v-model="presentation.countryId"
          type="select2"
          required
        />
      </div>
      <div class="form__body-inputBlock mb-3 pb-3">
        <p>Год</p>
        <ComponentInput placeholder="Укажите год" type="number" v-model="presentation.year" required />
      </div>
      <div class="form__body-inputBlock">
        <ButtonStock title="Скачать" @click="createPresentation" />
      </div>
    </div>
  </ModalComponent>
  <ModalComponent
    title="Политическая справка"
    @close="politicalSituation.dialog = false"
    v-model="politicalSituation.dialog"
  >
    <h5>{{ politicalSituation.country?.nameFull }}</h5>
    <div
      v-if="politicalSituation.country"
      class="html-text"
      v-html="politicalSituation.country?.politicalSituation"
    ></div>
  </ModalComponent>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import Api from '@/modules/countryAnalyticsNew/api/api';
  import Api_Country from '@/modules/nsi/api/country';

  export default {
    name: 'PageSelector',
    components: { ComponentInput, ButtonStock, ModalComponent },
    emits: ['pageChange'],
    props: {
      pageData: {
        type: Object,
        default: null,
      },
      countries: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectedCountryId: this.pageData?.countryId,
        pages: [
          { id: 1, text: 'Аналитические данные по странам', needsCountry: false },
          { id: 2, text: 'Обзор экономики', needsCountry: true },
          { id: 3, text: 'Сотрудничество и совместные проекты', needsCountry: true },
          { id: 4, text: 'Рейтинги и финансовая политика', needsCountry: true },
          { id: 5, text: 'Условия доступа на рынки', needsCountry: true },
        ],
        selectedPageId: this.pageData?.typeId ?? 1,
        select2Settings: Constants.select2Settings,
        select2SettingsNoClear: Object.assign({ minimumResultsForSearch: -1 }, Constants.select2SettingsNoClear),
        presentation: {
          countryId: null,
          year: null,
          dialog: false,
        },
        politicalSituation: {
          country: null,
          dialog: false,
        },
      };
    },
    created() {},
    mounted() {
      let countryId = this.pageData.countryId;
      if (countryId) this.selectedCountryId = Number(countryId);
      let pageId = this.pageData.typeId;
      if (pageId && pageId != 1 && countryId) {
        this.selectedPageId = Number(pageId);
      }
    },
    methods: {
      sendPageChangeEvent() {
        this.$emit('pageChange', { page: this.selectedPageId, country: this.selectedCountryId });
      },
      onPageSelect() {
        if (this.selectedPageId != 1 && !this.selectedCountryId) return;
        if (this.selectedPageId == 1) this.selectedCountryId = null;

        this.sendPageChangeEvent();
      },
      onCountryClear() {},
      onCountrySelect() {
        if (this.selectedPageId == 1 && this.selectedCountryId) this.selectedPageId = 2;
        else if (!this.selectedCountryId) {
          this.selectedPageId = 1;
          this.$nextTick(() => {
            $('.select2-hidden-accessible').select2('close');
          });
        }

        this.sendPageChangeEvent();
      },
      onOpenPresentationDialog() {
        this.presentation.dialog = false;
        this.$nextTick(() => {
          this.presentation.countryId = this.selectedCountryId;
          this.presentation.year = null;
          this.presentation.dialog = true;
        });
      },
      createPresentation() {
        if (this.presentation.countryId > 0 && this.presentation.year) {
          Api.createPresentation({
            countryId: this.presentation.countryId,
            year: this.presentation.year,
          }).finally(() => {
            this.presentation.dialog = false;
          });
        } else {
          Constants.alert.fire('Презентация', 'Заполните поля', 'error');
        }
      },
      onOpenPoliticalSituationDialog() {
        this.politicalSituation.dialog = false;
        this.$nextTick(() => {
          if (this.politicalSituation.country && this.politicalSituation.country.id == this.selectedCountryId)
            this.politicalSituation.dialog = true;
          else {
            this.politicalSituation.country = null;

            Api_Country.find(this.selectedCountryId).then((response) => {
              this.politicalSituation.country = response.data;
              this.politicalSituation.dialog = true;
            });
          }
        });
      },
    },
    watch: {
      pageData: {
        handler: function () {
          this.selectedPageId = this.pageData.typeId;
          this.selectedCountryId = this.pageData.countryId;
        },
        deep: true,
      },
    },
    computed: {},
  };
</script>

<style lang="scss">
  @import '../styles/index';
  .country-analytics-new__page-selector {
    .select2 .select2-container .select2-selection__rendered {
      font-weight: 500;
      font-size: 28px;
      line-height: 120%;
      padding-top: 2px;
    }

    .select2 .select2-container--default .select2-selection {
      padding-left: 0;
    }
  }

  .country-analytics-new__country-selector {
    .select2 .select2-container .select2-selection__rendered {
      font-weight: 500;
      font-size: 20px;
      height: 100%;
      overflow: visible;
    }

    .select2 .select2-container--default .select2-selection {
      min-height: 36px !important;
    }
  }

  .country-required {
    box-shadow: 0px 0px 0px 1px red;
  }

  .page-selector-container {
    position: relative;
  }
</style>
