<template>
  <div class="assignment-status" :class="getStatusClass(assignment)">
    <IconComponent class="assignment-status__icon" :name="getStatusClassIcon(assignment)" />
    <span class="assignment-status__text">
      {{ assignment.statusName }}
    </span>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent';

  export default {
    name: 'AssignmentStatus',

    components: {
      IconComponent
    },

    props: {
      assignment: {
        type: Object,
        required: true
      }
    },

    methods: {
      getStatusClassIcon(assignment) {
        //cir-done //cir-late //cir-close //cir-inWork
        if (assignment.postponed) return 'cir-close';
        if (assignment.statusId == Constants.eapAssignemntStatusDoneId) return 'cir-done';
        return assignment.overdue ? 'cir-late' : 'cir-inWork';
      },

      getStatusClass(assignment) {
        //status-done //status-late //status-close //cir-inWork
        if (assignment.postponed) return 'status-close';
        if (assignment.statusId == Constants.eapAssignemntStatusDoneId) return 'status-done';
        return assignment.overdue ? 'status-late' : 'status-inWork';
      },
    }
  }
</script>

<style lang="scss" scoped>
  .assignment-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;

    &.status {
      &-done {
        color: #24c20f;
      }
      &-close {
        color: #f04f4f;
      }
      &-late {
        color: #f04f4f;
      }
      &-inWork {
        color: #ffa800;
      }
    }
  }

  .assignment-status__icon {}

  .assignment-status__text {}
</style>
