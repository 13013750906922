import axios from 'axios';

import { authAxios } from '@/common/axios';
import Utils from '@/common/utils';

export default {
  searchMacro: function (request) {
    return axios.get('api/macroeconomicindicatorcountryvalue/search', {
      params: request,
    });
  },
  searchBankRates: function (countryId) {
    return axios.get(`api/bankrate/summary/${countryId}`, {});
  },

  getGreenEconomyByFuelSummry: function (countryId) {
    return axios.get(`api/greeneconomybyfuel/summary/${countryId}`, {});
  },
  getGreenEconomyCo2Summry: function (countryId) {
    return axios.get(`api/greeneconomyco2/summary/${countryId}`, {});
  },
  getInvestementYearList: function (countryId) {
    return axios.get(`api/investment/yearList`, {
      params: { countryId: countryId },
    });
  },
  getInvestmentSummary: function (countryId, year) {
    return axios.get(`api/investment/summary`, {
      params: { id: countryId, year: year },
    });
  },
  getCountryRatings: function (countryId) {
    return axios.get(`api/countryrating/summary/${countryId}`, {});
  },
  getBudgetPolicy: function (countryId) {
    return axios.get(`/api/budgetpolicy/summary/${countryId}`, {});
  },
  getForeignTradeData: function (year, countryId) {
    return axios.get(`api/foreigntrade/summary/${year}/${countryId}`, {});
  },
  getForeignTradeForOne: function (isRussia, isProductMode, year, countryId) {
    return axios.get(
      `api/foreigntrade/summaryForOne/${year}/${countryId}?isRussia=${isRussia}&isProductMode=${isProductMode}`,
      {},
    );
  },
  getYearList: function (isRussia, isProductMode) {
    return axios.get(`api/foreigntrade/yearList`, {
      params: { isRussia: isRussia, isProductMode: isProductMode },
    });
  },
  getTecWorldSummary: function (year, countryId) {
    return axios.get(`api/tecworld/summary/${year}/${countryId}`, {});
  },
  getTradeEconomicRelRussia: function (year, countryId) {
    return axios.get(`api/tradeeconomicrelrussia/summary/${year}/${countryId}`, {});
  },
  getExportProductLast: function (countryId) {
    return axios.get(`api/exportProduct/summaryLast/${countryId}`, {});
  },
  getExportProductAll: function (year) {
    return axios.get(`api/exportProduct/summaryForAll?year=${year}`, {});
  },
  getFinancialSector: function (countryId) {
    return axios.get(`api/financialsector/getbycountryid/${countryId}`, {});
  },
  createPresentation: function (request) {
    return Utils.downloadFile('/api/countryReport/createReport', 'CountryReport.pptx', request);
  },

  getLifeIndexSummary: function (countryId) {
    return axios.get(`api/lifeindex/summary/${countryId}`, {});
  },

  getInvestementRussia: function (countryId) {
    return axios.get(`api/InvestmentRussia/summary/${countryId}`, {});
  },

  getRealIncome: function (countryId) {
    return axios.get(`api/realdisposablepersonalincome/findByCountry/${countryId}`, {});
  },

  getYearListTurnover: function (countryId) {
    return axios.get(`api/foreignTradeTurnoverWorld/yearList?countryId=${countryId}`, {});
  },

  searchInflation: function (request) {
    return axios.get(`api/inflation/search`, { params: request });
  },

  getUnfriendlyCountryInfo: function (countryId) {
    return axios.get(`api/unfriendlyсountries/unfriendlycountryinfo/${countryId}`, {});
  },

  getCountryCreditRating: function (countryId) {
    return axios.get(`/api/countrycreditrating/getforcountry/${countryId}`);
  },

  getTopThreeIndustries: function (countryId) {
    return axios.get(`/api/investmentrussia/gettopthreeindustryincomingvalues/${countryId}`);
  },

  findCountry(id) {
    return authAxios.get(`/api/country/${id}`);
  },

  getInvestmentRussia: function (countryId) {
    return axios.get(`/api/investmentrussia/getinvestmenthistogram/${countryId}`);
  },

  getTopFiveInvestors: function (countryId) {
    return axios.get('/api/investment/topdirectinwardinvestors/', { params: { countryId } });
  },

  getFavorTrade: function (countryId, isExport) {
    return axios.get(`/api/favortrade/getfavortradehistogram`, {
      params: {
        countryId: countryId,
        isExport: isExport,
      },
    });
  },
};
