import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    loadPage: false,
    passportKeys: [],
    passportPartnerCountries: [],
    passportTradeTurnovers: [],
    passportMainInformation: [],
    passportPartnersCount: 0,
    passportCurrentEvents: [],
    passportPreviousEvents: [],
    passportCurrentEventsCount: 0,
    passportPreviousEventsCount: 0,
    passportListOfAgreements: [],
    topRegionsAgreements: {},
    topEffectiveRegions: [],
    topRegionsByTurnover: [],
    responsibleAuthority: null,
  },
};
