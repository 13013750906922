export default {
  whiteColor: '#fff',
  colors: [
    '#08B9FF',
    '#76F748',
    '#FF644E',
    '#006199',
    '#37881A',
    '#ff6111',
    '#48D913',
    '#FF8371',
    '#19F5F1',
    '#FFD932',
    '#FFA0CD',
    '#3AA99C',
    '#BA990C',
    '#BD0D8C',
    '#43EDDA',
    '#FFE15B',
    '#FF68B4',
    '#bfff00',
    '#f600ff',
    '#ff4400',
    '#ffa600',
    '#ff2f00',
    '#00ffe1',
    '#bfff00',
    '#ff0095',
    '#6aff00',
    '#00ffa2',
    '#00ccff',
    '#00ff80',
    '#ffff00',
    '#ffc300',
    '#ff000d',
    '#b700ff',
    '#5900ff',
    '#31900e',
    '#4200bd',
    '#00d0ff',
    '#d60036',
    '#7400b8',
    '#c2003a',
    '#d10018',
    '#c2000a',
    '#ad6b00',
    '#c20024',
    '#1495eb',
    '#ff57eb',
    '#007bff',
    '#9e6700',
    '#b32a00',
    '#ffb700',
    '#098a00',
    '#ff0088',
    '#ff000d',
    '#00ff51',
    '#26bd00',
    '#6200ff',
    '#ff0015',
    '#99006b',
    '#ffe100',
    '#00004D',
    '#4D0000',
    '#FF99FF',
    '#0040ff',
    '#A6FF33',
    '#99F2FF',
    '#000033',
    '#ff6200',
    '#115c00',
    '#1e00ff',
    'ffd900',
    '#518f00',
    '#cb47ff',
    '#ff0059',
  ],
  defaultXData: [
    '1 квартал 2020',
    '2 квартал 2020',
    '3 квартал 2020',
    '4 квартал 2020',
    '1 квартал 2021',
    '2 квартал 2021',
    '3 квартал 2021',
    '4 квартал 2021',
    '1 квартал 2022',
    '2 квартал 2022',
    '3 квартал 2022',
    '4 квартал 2022',
  ],
  defaultYData: [30, 40, 45, 50, 30, 40, 45, 50, 30, 40, 45, 50],
};
