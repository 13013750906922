<template>
  <template v-if="!async">
    <Multiselect
      :close-on-select="true"
      :clear-on-select="false"
      :searchable="true"
      :create-option="false"
      :multiple-label="multiLabel"
      :options="computedOptions"
      :mode="mode"
      :disabled="disabled || loadingList"
      :class="getClassList"
      :placeholder="placeholder"
      :canDeselect="false"
      :groups="groups"
      :loading="loadingList"
      :canClear="canClear"
      noResultsText="Ничего не найдено."
      noOptionsText="Список пуст."
      @search-change="asyncFind"
      @select="onSelect"
      ref="select"
      @close="closeDropDown"
      autocomplete="off"
    />
  </template>
  <template v-else>
    <Multiselect
      :id="selectId"
      :options="computedOptions"
      :close-on-select="true"
      :clear-on-select="false"
      :canDeselect="false"
      :searchable="true"
      :internal-search="false"
      :loading="isLoadingInternal"
      track-by="value"
      :hide-selected="false"
      :show-no-results="false"
      :preserve-search="true"
      @search-change="asyncFind"
      :disabled="disabled"
      :class="getClassList"
      :placeholder="placeholder"
      :groups="groups"
      :canClear="canClear"
      noResultsText="Ничего не найдено."
      noOptionsText="Список пуст."
      ref="select"
      @close="closeDropDown"
    />
  </template>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  export default {
    components: {
      Multiselect,
    },
    name: 'SelectMultiComponent',
    emits: ['search'],
    props: {
      selectId: String,
      async: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        default: () => [],
      },
      mode: {
        required: false,
        default: 'single',
      },
      classSelect: {
        type: [String, Array],
        default: '',
      },
      modifier: {
        type: String,
        default: '',
        validator(value) {
          return ['rectangular', 'select-country', 'filter-small', 'blue'].includes(value);
        },
      },
      placeholder: {
        type: String,
        default: '',
      },
      clearClass: {
        type: Boolean,
        default: false,
      },
      groups: {
        type: Boolean,
        default: false,
      },
      loadingList: {
        type: Boolean,
        default: false,
      },
      canClear: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isLoadingInternal: false,
        timerId: null,
      };
    },
    watch: {
      isLoading: function () {
        this.isLoadingInternal = !!this.isLoading;
        if (!this.isLoadingInternal) {
          // workaround of strange behaviour: showing "No results found" even if results are fetched.
          let el = document.querySelector(`#${this.selectId} .multiselect-caret`);
          el.click();
          setTimeout(() => {
            el = document.querySelector(`#${this.selectId}`);
            el.focus();
          }, 100);
        }
      },
    },
    computed: {
      getClassList() {
        return [
          this.classSelect,
          this.modifier ? `multiselect--${this.modifier}` : '',
          this.clearClass ? 'visible-clear' : '',
        ];
      },
      computedOptions() {
        return [...this.options];
      },
    },
    methods: {
      asyncFind(query) {
        if (!query) return;
        this.$emit('change-search', query);
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        this.timerId = setTimeout(() => {
          this.$emit('search', query);
        }, 1000);
      },
      multiLabel(value) {
        let label = '';
        value.forEach((item, index) => {
          if (index) {
            label += `, ${item.label}`;
          } else {
            label = item.label;
          }
        });
        return label;
      },
      onSelect(id, obj) {
        this.$emit('select', id, obj);
      },

      closeDropDown() {
        if (this.$refs.select) {
          this.$refs.select.blur();
        }
      },
    },
  };
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
  :root {
    // Modal
    --g-dialog-overlay-bg: linear-gradient(270deg, rgba(0, 178, 170, 0.5) 0%, rgba(0, 120, 200, 0.5) 100%);
    // Multiselect
    --ms-tag-bg: #d3dfe6;
    --ms-tag-font-weight: 400;
    --ms-tag-color: #231f20;
    --ms-tag-remove-radius: 50%;
    --ms-option-font-size: 14px;
    --ms-bg: #fbfbfb;
    --ms-caret-color: transparent;
  }
  .multiselect {
    &.visible-clear {
      .multiselect-clear {
        display: flex;
        padding: 0;
        margin-right: 20px;
      }

      .multiselect-search,
      .multiselect-single-label {
        padding-right: 50px;
      }

      &.multiselect--filter-small {
        .multiselect-search,
        .multiselect-single-label {
          padding-right: 35px;
        }
      }
    }

    &--rectangular,
    &--select-country,
    &--filter-small {
      min-height: auto;
      border: none !important;
      border-radius: 0 !important;

      .multiselect {
        &-wrapper {
          border: 1px solid $grey;
          min-height: 40px;
        }
        &-single-label {
          padding-right: 35px;
          width: 100%;

          &-text {
            font: $fira-14-20;
          }
        }
        &-placeholder {
          font: $fira-14-20;
        }
        &-dropdown {
          border-top: none;
          transform: translateY(calc(100% - 1px));
          width: 100%;
          left: 0;
          right: 0;
        }
        &-search {
          border: none !important;
          padding-right: 35px;
          font: $fira-14-20;
        }
        &-clear {
          display: none;
        }
        &-caret {
          margin-right: 20px;
        }
      }

      &.error {
        .multiselect-wrapper {
          border-color: $red;
        }
      }

      &.is-active {
        .multiselect-wrapper {
          border-color: $grey;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &--rectangular {
      .multiselect {
        &-wrapper {
          border-radius: 4px;
        }
        &-placeholder {
          padding: 0 35px 0 14px;
        }
      }

      @media (min-width: $tablet) {
        .exportPdf & {
          .multiselect-single-label-text {
            margin-top: -15px;
          }

          .multiselect-placeholder {
            margin-top: -10px;
          }
        }
      }
    }

    &--filter {
      font: $fira-16;

      .multiselect-single-label-text,
      .multiselect-search {
        font: $fira-16;
      }
    }

    &--select-country {
      border-radius: 12px !important;

      .multiselect {
        &-dropdown {
          background-color: $light-grey;
        }
        &-wrapper {
          border-radius: 12px;
        }
        &-search {
          padding: 8px 12px;
        }
        &-placeholder {
          padding: 0 12px;
        }
        &-caret {
          display: none;
        }
        &-option {
          padding: 8px 12px;
        }
        &-dropdown {
          background-color: $light-grey;
        }
      }
    }

    &--empty-borders {
      border: none;
      background: none;
      border-bottom: 3px dashed $light-grey-5;
      border-radius: 0;

      &.is-active {
        border: none;
        border-bottom: 3px dashed $light-grey-5;
      }

      .multiselect-search {
        border-radius: 0;
        border: none !important;
        background: none;
        padding: 0 58px 0 0;
        font: $fira-24-M;
      }
      .multiselect-single-label {
        padding: 0 58px 0 0;
        font: $fira-24-M;
      }
      .multiselect-single-label-text {
        font: $fira-24-M;
      }
      .multiselect-clear {
        padding: 5px;
        margin: 0px 20px 0 0;
      }
    }

    &--green {
      background: none;
      border: none !important;
      border-radius: 0 !important;

      .multiselect-single-label-text,
      .multiselect-search {
        color: $green;
        font: 500 15px / 24px 'Fira Sans';
      }

      .multiselect-search {
        border: none;
        border-radius: 0;
        background: none;
      }

      .multiselect-wrapper {
        height: 46px;
        border: 1px solid $green;
      }

      &.is-active {
        .multiselect-wrapper {
          border-color: $green;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .multiselect-dropdown {
        border-top: none;
        transform: translateY(calc(100% - 1px));
        width: 100%;
        left: 0;
        right: 0;
      }

      @media (max-width: $mobile) {
        .multiselect-single-label-text,
        .multiselect-search {
          font-size: 13px;
        }

        .multiselect-single-label {
          padding: 0 10px;
        }

        .multiselect-search {
          padding: 10px 10px;
        }

        .multiselect-wrapper {
          height: 40px;
        }
      }
    }

    &--hidden-clear {
      .multiselect-clear {
        display: none;
      }

      .multiselect-single-label {
        padding-right: 30px;
      }
    }

    &--filter-small {
      .multiselect-wrapper {
        min-height: 32px;
      }

      .multiselect-wrapper input {
        padding: 8px;
        line-height: inherit;
      }

      .multiselect-single-label {
        padding-left: 8px;
      }

      .multiselect-single-label-text {
        font: $fira-12;
      }

      .multiselect-clear {
        margin-right: 7px !important;
      }

      .multiselect-clear-icon {
        width: 12px;
        height: 12px;
      }

      .multiselect-placeholder {
        padding: 0 8px;
        font: $fira-12;
      }
    }

    &--blue {
      background: none;
      border: none !important;
      border-radius: 0 !important;
      min-height: auto;
      font: $fira-20-M;

      .multiselect-wrapper {
        min-height: 24px;
        position: relative;
        color: $blue;
      }

      .multiselect-search {
        padding: 0;
        border: none !important;
        background: transparent;
        padding-right: 24px;
      }

      .multiselect-single-label {
        padding: 0;
      }

      .multiselect-clear {
        display: none;
      }

      .multiselect-multiple-label,
      .multiselect-placeholder,
      .multiselect-single-label {
        font: $fira-20-M;
        padding-right: 24px;
      }

      .multiselect-single-label-text {
        font: inherit;
      }

      .multiselect-caret {
        border-color: $blue;
        position: absolute;
        right: 7px;
        top: 4px;
        margin: 0;
      }

      @media (min-width: $tablet) {
        .exportPdf & {
          .multiselect-caret {
            margin-top: 13px;
          }
        }
      }
    }

    @media (min-width: $tablet) {
      .exportPdf & {
        .multiselect-wrapper,
        .multiselect-single-label-text {
          overflow: visible;
        }
      }
    }
  }
  .multiselect-clear {
    padding: 0 22px 0 0;
  }
  .multiselect-tag-remove {
    background-color: #787f8f;
    padding: 2px;
    margin: 0 6px 0 10px;
  }
  .multiselect-tag-remove-icon {
    background-color: #d3dfe5;
  }
  .multiselect-tags-search {
    border: none !important;
  }
  .multiselect-placeholder {
    font-size: 14px;
    color: #989898;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    .multiselect--filter & {
      font: $fira-16;
      padding-left: 12px;
    }
  }
  .multiselect.is-active {
    box-shadow: none;
  }
  .multiselect-caret {
    outline: none;
    border-color: unset;
    border-style: unset;
    border-width: unset;
    width: 10px;
    height: 10px;
    border-radius: 1px;
    border-left: 3px solid #343c44;
    border-bottom: 3px solid #343c44;
    transform: rotate(315deg);
    margin-left: -10px;
    margin-top: -6px;
    transition: 0.3s;
    mask-image: unset;
    &.is-open {
      transform: rotate(135deg);
      margin-top: 2px;
    }

    .multiselect--filter-small & {
      border-color: #989898;
      width: 6px;
      height: 6px;
      margin: -4px 10px 0 0;
      cursor: pointer;
    }
  }
  .multiselect-wrapper {
    border-radius: 4px;
    overflow: hidden;

    input {
      .multiselect--filter & {
        padding: 8px 12px;
      }
    }
  }
  .is-visible {
    .multiselect {
      flex-direction: column;
      .multiselect-dropdown {
        position: initial;
        display: block !important;
        transform: none;
        max-height: unset;
        width: 100%;
        border-bottom: none;
        border-left: none;
        border-right: none;
        min-height: 403px;
      }
      .multiselect-search {
        display: block !important;
        position: initial;
        margin-bottom: 1px;
        border: 1px solid transparent;
        &:focus {
          border: 1px solid #01a39d;
        }
      }
      .multiselect-clear {
        position: absolute;
        top: 12px;
        right: 6px;
      }
      .multiselect-caret {
        display: none;
      }
      .multiselect-placeholder,
      .multiselect-multiple-label {
        height: auto;
        left: 6px;
        top: 12px;
      }
    }
  }
  .custom-inMultiselect {
    .multiselect-group-label {
      background: #ffffff;
      border-top: 2px solid #d3dfe5;
      border-bottom: 2px solid #d3dfe5;
      padding: 4px 8px 4px 42px;
      &.is-selected {
        background: var(--ms-group-label-bg-selected, #059669);
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }
    }
    .multiselect-checkBox {
      min-height: 18px;
      min-width: 18px;
      border: 2px solid #00c6a2;
      border-radius: 2px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &.gradient {
        .multiselect-checkBox__arrow {
          border-left: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
        }
      }
      &__arrow {
        display: none;
        height: 4px;
        width: 8px;
        border-left: 2px solid #00c6a2;
        border-bottom: 2px solid #00c6a2;
        transform: rotate(-50deg);
      }
    }
    .multiselect-text {
      width: 90%;
    }
    .multiselect-option.is-selected {
      background: none;
      color: #1f2429;
      .multiselect-checkBox {
        &.gradient {
          border: none;
          background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
        }
        &__arrow {
          display: block;
        }
      }
    }
    .multiselect-img {
      height: 61px;
      width: 61px;
      border-radius: 50%;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .multiselect-option {
    .multiselect--rectangular & {
      padding: 10px 14px;
      color: $base;

      span {
        font-family: 'Fira Sans';
      }

      &:hover {
        background: $green-1 !important;
        color: #fff;
      }
    }
    &.is-pointed {
      .multiselect--rectangular & {
        background: transparent !important;
      }

      &:hover {
        background: $green-1 !important;
        color: #fff;
      }
    }

    &.is-selected {
      .multiselect--rectangular & {
        background: $green-1 !important;
        color: #fff;
      }
    }

    .multiselect--filter-small & {
      padding: 8px;
      font: $fira-14;
    }
  }

  .multiselect-checkbox {
    min-height: auto;
    border: none !important;
    border-radius: 0 !important;

    .multiselect-wrapper {
      min-height: 40px;
      border-radius: 4px;
      border: 1px solid $grey;
    }

    .multiselect-dropdown {
      border-top: none;
      transform: translateY(calc(100% - 1px));
      width: 100%;
      left: 0;
      right: 0;
    }

    .multiselect-search {
      font: $fira-14-20;
      padding: 10px 50px 10px 14px;
      border: none !important;
    }

    .multiselect-caret,
    .multiselect-clear {
      margin-right: 20px;
    }

    .multiselect-placeholder {
      padding-left: var(--ms-px, 0.875rem);
      padding-right: 50px;
      font: $fira-14-20;
    }

    .multiselect-multiple-label,
    .multiselect-single-label {
      font: $fira-14-20;
      display: flex;
      gap: 4px;
      color: $base;
      padding: 10px 50px 10px 14px;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__selected-number {
        border-radius: 2px;
        background: $blue;
        color: #fff;
        padding: 0px 8px;
      }
    }

    .option-container {
      display: flex;
      width: 100%;
    }

    .multiselect-option.is-pointed:hover,
    .multiselect-option.is-selected {
      background-color: $grey-disabled !important;
      color: $blue-dark;
    }

    &.error {
      .multiselect-wrapper {
        border-color: $red;
      }
    }

    &.is-active {
      .multiselect-wrapper {
        border-color: $grey;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .input-group-wrapper {
      font: $fira-14-20;
    }

    //checkbox
    .input-group-wrapper.checkbox-green {
      .form-check-input {
        display: none;
        opacity: 0;
        margin: 0;
        width: 20px;
        height: 20px;
        z-index: 10;
        cursor: pointer;
      }

      .form-check-label {
        position: relative;
        font-family: 'Fira Sans';
        font: $fira-14-20;
        padding-left: 32px;
        cursor: pointer;

        &::before,
        &::after {
          content: '';
          position: absolute;
          border-radius: 4px;
          border: 1px solid $green;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          z-index: 0;
        }

        &::after {
          opacity: 0;
          border: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3crect width='20' height='20' rx='4' fill='url(%23paint0_linear_3530_38638)'/%3e%3cpath d='M15 5.8335L8.125 13.3335L5 9.92441' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_3530_38638' x1='20' y1='10' x2='0' y2='10' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2301A39D'/%3e%3cstop offset='1' stop-color='%230078C8'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
          transition: 0.5s ease;
          z-index: 1;
        }
      }

      input:checked ~ .form-check-label {
        &::after {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    &--single {
      .multiselect-option {
        .form-label {
          color: inherit;
          margin-bottom: 0;
        }
      }

      .multiselect-option.is-pointed:hover,
      .multiselect-option.is-selected {
        background-color: $green-1 !important;
        color: #fff;
      }

      .multiselect-option.is-pointed {
        background: transparent;
      }
    }

    &--filter-small {
      .input-group-wrapper.checkbox-green .form-check-label {
        font: $fira-12;
      }

      .multiselect-search {
        padding: 8px;
        line-height: inherit;
      }

      .multiselect-wrapper {
        min-height: 32px;
      }

      .multiselect-placeholder {
        font: $fira-12;
        padding-left: 8px;
      }

      .multiselect-multiple-label {
        font: $fira-12;
        padding-left: 8px;
      }

      .multiselect-caret {
        border-color: $dark-grey-1;
        width: 6px;
        height: 6px;
        margin: -5px 10px 0 0;
        cursor: pointer;

        &.is-open {
          margin-top: 0px;
        }
      }

      .multiselect-clear {
        margin: 0 8px 0 0;
        padding: 0;
      }

      .multiselect-clear-icon {
        width: 8px;
        height: 12px;
      }

      .input-group-wrapper.checkbox-green .form-check-label {
        font-size: 14px;
        padding-left: 23px;

        &::before,
        &::after {
          width: 18px;
          height: 18px;
        }
      }
    }

    &--base {
      .multiselect-wrapper {
        min-height: 40px;
      }

      .multiselect-search {
        padding: 8px 16px;
      }
    }
  }
</style>
