<template>
  <div class="apexchart-monitoring" :class="{ pb64: template === 'default' }">
    <div class="apexchart-content__wrapper">
      <apexchart ref="apexchart" class="apexchart-content" type="bar" :options="chartOptions" :series="series">
      </apexchart>
    </div>
    <div v-if="template === 'default'" class="apexchart-legend">
      <div
        v-for="(item, index) in legendDataVisible"
        :key="index"
        class="apexchart-legend__item"
        :class="{ pale: hiddenLegendItems.includes(item.label) }"
        @click="clickLegendItem(item.label)"
        @mouseover="mouseoverLegendItem(item.label)"
        @mouseleave="mouseleaveLegendItem"
      >
        <div class="apexchart-legend__item-color" :style="[`background-color: rgba(${item.color})`]"></div>
        <div class="apexchart-legend__item-label">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartBarMonitoring',
    props: {
      seriesProp: {
        type: Array,
      },
      template: {
        default: 'default',
        type: String,
      },
      nn: Number,
    },
    watch: {
      nn: function (/*newValue*/) {
        console.log(' ------------ props');
        this.loadChart();
        this.isLoaded = true;
      },
      seriesProp: function () {
        this.loadChart();
        this.isLoaded = true;
      },
    },
    data() {
      return {
        isLoaded: false,
        legendData: [
          { label: 'Выполнено', color: '120, 210, 140, 0.9' },
          { label: 'В стадии выполнения', color: '100, 173, 239, 0.9' },
          { label: 'Не выполнено', color: '240, 79, 79, 0.9' },
        ],
        hiddenLegendItems: [],
        focusedLegendItems: [],
        // data for templates
        xAxisCategories: [],
        oneArray: [],
        twoArray: [],
        threeArray: [],
        // stock options
        series: [
          {
            name: 'series-1',
            data: [30, 40, 45, 50, 49, 60, 70, 81],
          },
        ],
        chartOptions: {
          grid: {
            show: false,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#78D28C', '#003990', '#F04F4F', '#64ADEF', '#008C6E', '#007EC5'],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '60%',
              barWidth: '100%',
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff'],
          },
          yaxis: {
            labels: {
              maxWidth: 400,
              offsetX: 0,
              style: {
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Ubuntu, sans-serif',
                colors: '#333',
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              },
            },
          },
          noData: {
            text: 'Загрузка...',
          },
        },
      };
    },
    methods: {
      xAxisCategoriesHandlePush(item) {
        if (item.length < 25) {
          this.xAxisCategories.push(item);
          return;
        }
        const itemArray = item.replace('\n', '').trim().split(` `);
        const itemLength = itemArray.length;
        if (itemLength > 3) {
          const separatorIndex = Math.round(itemLength / 2);
          const firstLine = itemArray.slice(0, separatorIndex).join(` `);
          const secondLine = itemArray.slice(separatorIndex, itemLength).join(` `);
          this.xAxisCategories.push([firstLine, secondLine]);
          return;
        }
        const isFirstWordLonder = itemArray[0].length > itemArray[itemLength - 1].length;
        const firstLine = isFirstWordLonder ? itemArray[0] : itemArray.slice(0, itemLength - 1).join(` `);
        const secondLine = isFirstWordLonder ? itemArray.slice(1, itemLength).join(` `) : itemArray[itemLength - 1];
        this.xAxisCategories.push([firstLine, secondLine]);
      },
      clickLegendItem(name) {
        if (!this.$refs.apexchart) return;
        console.log('clickLegendItem', this.$refs.apexchart);
        this.$refs.apexchart.toggleSeries(name);
        if (this.hiddenLegendItems.includes(name)) {
          this.hiddenLegendItems = this.hiddenLegendItems.filter((item) => item !== name);
          return;
        }
        this.hiddenLegendItems.push(name);
      },
      mouseoverLegendItem(name) {
        if (!this.$refs.apexchart || this.hiddenLegendItems.includes(name)) return;
        this.focusedLegendItems.push(name);
        this.$refs.apexchart.updateOptions(this.barOfComplatingOptions);
      },
      mouseleaveLegendItem() {
        if (!this.$refs.apexchart) return;
        this.focusedLegendItems = [];
        this.$refs.apexchart.updateOptions(this.barOfComplatingOptions);
      },
      // templates
      // ex. monitoring-work-mpk.vue
      doneChart() {
        this.xAxisCategories = [];
        this.oneArray = [];
        this.twoArray = [];
        this.threeArray = [];

        // Массив для категорий
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].name;
          this.xAxisCategoriesHandlePush(item);
        }
        //
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[0];
          this.oneArray.push(item);
        }
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[1];
          this.twoArray.push(item);
        }
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[2];
          this.threeArray.push(item);
        }
        this.chartOptions = this.barOfComplatingOptions;
        this.series = [
          {
            name: this.legendData[0].label,
            data: this.oneArray,
          },
          {
            name: this.legendData[1].label,
            data: this.twoArray,
          },
          {
            name: this.legendData[2].label,
            data: this.threeArray,
          },
        ];
      },
      // ex. analytics-projects.vue
      analyticsChart() {
        // Массив для категорий
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].name;
          this.xAxisCategories.push(item);
        }
        //
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].data[0];
          this.oneArray.push(item);
        }
        (this.chartOptions = {
          chart: {
            type: 'bar',
            height: 55,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
              barHeight: '90%',
              barWidth: '100%',
              dataLabels: {
                position: 'bottom',
              },
            },
          },
          colors: ['#01A39D', '#01CCC5', '#26CA98', '#018BBF', '#1D87EE', '#2b908f', '#69d2e7'],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['black'],
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: '400',
            },
            formatter: function (val, opt) {
              return val + '  ' + opt.w.globals.labels[opt.dataPointIndex];
            },
            offsetX: 0,
          },
          xaxis: {
            categories: this.xAxisCategories,
            toolbar: {
              show: false,
            },
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
          },
          yaxis: {
            toolbar: {
              show: false,
            },
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          grid: {
            show: false,
          },
        }),
          (this.series = [
            {
              data: this.oneArray,
            },
          ]);
      },
      loadChart() {
        // выбор шаблона
        // ex. monitoring-work-mpk.vue
        if (this.template == 'default') {
          this.doneChart();
        }
        // ex. analytics-projects.vue
        else if (this.template == 'analytics') {
          this.analyticsChart();
        }
      },
    },
    created() {
      this.loadChart();
    },
    computed: {
      legendDataVisible() {
        return [
          ...[this.oneArray.some((item) => item !== 0) ? this.legendData[0] : null],
          ...[this.twoArray.some((item) => item !== 0) ? this.legendData[1] : null],
          ...[this.threeArray.some((item) => item !== 0) ? this.legendData[2] : null],
        ].filter((el) => !!el);
      },
      barOfComplatingColors() {
        return this.legendData.map((item) => {
          if (!this.focusedLegendItems.length) return `rgba(${item.color})`;
          if (this.focusedLegendItems.includes(item.label)) return `rgba(${item.color})`;
          return `rgba(${item.color.replace('0.9', '0.2')})`;
        });
      },
      barOfComplatingOptions() {
        console.log(this.seriesProp);
        return {
          chart: {
            type: 'bar',
            height: Math.max(100, this.seriesProp.length * 30 + 25),
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '60%',
              barWidth: '100%',
            },
          },
          colors: this.barOfComplatingColors,
          xaxis: {
            categories: this.xAxisCategories,
            labels: {
              show: false,
              style: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label',
              },
            },
          },
          yaxis: {
            labels: {
              show: this.seriesProp.length > 0,
            },
          },
          legend: {
            show: false,
          },
          noData: {
            text: this.isLoaded ? 'Ничего не найдено' : 'Загрузка...',
          },
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .pb64 {
    padding-bottom: 64px;
    @media (max-width: 900px) {
      padding-bottom: 0;
    }
  }
  .apexchart-monitoring {
    position: relative;
  }
  .apexchart-content {
    min-width: 500px;
    &__wrapper {
      max-height: 500px;
      overflow: auto;
    }
  }
  .apexchart-legend {
    inset: auto 0px 1px 125px;
    position: absolute;
    display: flex;
    @media (max-width: 900px) {
      position: static;
      flex-wrap: wrap;
      inset: unset;
    }

    &__item {
      cursor: pointer;
      display: flex;
      margin: 20px 32px;
      align-items: center;
      &-color {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        margin-bottom: 2px;
        margin-right: 3px;
      }
      &-label {
        color: rgb(55, 61, 63);
        font-size: 14px;
        font-weight: 400;
        font-family: Helvetica, Arial, sans-serif;
      }
      &.pale {
        opacity: 0.5;
      }
    }
  }
</style>
