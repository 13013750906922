<template>
  <div>
    <h2 class="my-4">Тепловая карта соглашений</h2>
    <div class="d-flex align-items-center mb-4">
      <div class="select2 mr-4">
        <ComponentInput
          placeholder="Страна"
          :select2Settings="select2Settings"
          :select2Options="countries"
          @select2OnSelect="onCountrySelect"
          v-model="selectedCountryId"
          type="select2"
        />
      </div>
      <div class="select2">
        <ComponentInput
          placeholder="Тип соглашения"
          :select2Settings="select2Settings"
          :select2Options="agreementTypes"
          v-model="selectedTypeId"
          type="select2"
        />
      </div>
    </div>
    <div v-if="loaded" class="d-flex align-items-center justify-content-center">
      <Loader />
    </div>
    <div>
      <div id="mapContainerTrade"></div>
    </div>
    <ModalComponent @close="dialogWindow = false" v-model="dialogWindow" :width="800">
      <div class="trade-investment__modal-btns">
        <ButtonStock
          class="trade-investment__modal-btns_btn"
          :title="'Соглашения ' + selectedCountry.text + ' с третьими странами'"
          @click="dialogWindow = false"
        />

        <!--        <router-link to="/market-access/restriction">-->
        <!--          <button-stock title="Барьеры и ограничения" />-->
        <!--        </router-link>-->

        <!--        <router-link to="/">-->
        <!--          <button-stock title="Новости по стране" />-->
        <!--        </router-link>-->

        <!--        <router-link to="/">-->
        <!--          <button-stock title="Торгово-экономическое сотрудничество" />-->
        <!--        </router-link>-->
      </div>

      <div class="trade-investment__modal-table">
        <table v-if="selectedAgreement">
          <tbody>
            <tr>
              <td>Название соглашения</td>
              <td>{{ selectedAgreement.name }}</td>
            </tr>
            <tr>
              <td>Текст соглашения и тарифные обязательства</td>
              <td>
                <ul class="trade-investment__modal-files" v-if="selectedAgreement.mainFiles">
                  <li v-for="file in selectedAgreement.mainFiles" :key="file.id">
                    <p class="file-name">{{ file.originalName }}</p>
                    <IconComponent name="download" @click="downloadFile(file)" />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Охват</td>
              <td>{{ selectedAgreement.tradeItemTypesStr }}</td>
            </tr>
            <tr>
              <td>Дата подписания</td>
              <td>{{ selectedAgreement.signDt }}</td>
            </tr>
            <tr>
              <td>Дата вступления в силу</td>
              <td>{{ selectedAgreement.startDt }}</td>
            </tr>
            <tr>
              <td>Стороны соглашения</td>
              <td>{{ selectedAgreement.sidesStr }}</td>
            </tr>
            <tr>
              <td>Первоначальные подписавшиеся</td>
              <td>{{ selectedAgreement.initialSignersStr }}</td>
            </tr>
            <tr>
              <td>Ответственное ведомство</td>
              <td>
                <div v-if="selectedAgreement.blocks">
                  <div v-for="info in selectedAgreement.blocks" :key="info.id">
                    <a :href="info.url" target="_blank">
                      {{ info.name }}
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Членство страны в региональных блоках, международных организациях/объединениях</td>
              <td>{{ selectedAgreement.responsibleDepartment }}</td>
            </tr>
            <tr>
              <td>Источники</td>
              <td>
                <div v-if="selectedAgreement.additionalInfo">
                  <div v-for="info in selectedAgreement.additionalInfo" :key="info.id">
                    <a :href="info.url" target="_blank">
                      {{ info.name }}
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Дополнительные сведения</td>
              <td>
                <div v-if="selectedAgreement.agreementAdditionalInfo">
                  <div v-for="info in selectedAgreement.agreementAdditionalInfo" :key="info.id">
                    <a :href="info.url" target="_blank">
                      {{ info.name }}
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Дополнительная информация</td>
              <td>
                <ul class="trade-investment__modal-files" v-if="selectedAgreement.additionalDocuments">
                  <li v-for="document in selectedAgreement.additionalDocuments" :key="document.id">
                    <p class="file-name">{{ document.name }}</p>
                    <IconComponent name="download" @click="downloadFile(document.file)" />
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import Loader from '@/components/Loader';
  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '@/modules/nsi/api/country';
  import countryApi from '@/modules/nsi/api/country';

  import worldMap from '../../../common/custom.geo.json';
  import API_NSI from '../../nsi/api/nsi';
  import API from '../tradeInvestmentMap/api';

  export default {
    name: 'tradeInvestmentMap',
    components: { Loader, IconComponent, ButtonStock, ModalComponent, ComponentInput },

    data() {
      return {
        agreementTypes: [],
        selectedCountry: null,
        agreements: [],
        selectedCountryId: null,
        selectedTypeId: null,
        select2Settings: Constants.select2Settings,
        countries: [],
        center: [40, 0],
        world: worldMap,
        selectedAgreementId: null,
        selectedAgreement: null,
        dialogWindow: false,
        agreementsInner: this.agreements,
        mapDiv: null,
        selectedCountryInner: this.selectedCountry,
        countriesInner: this.countries,
        loaded: false,
        geoJsonLayer: null,
        countryNames: [],
      };
    },
    methods: {
      openModal(data) {
        this.dialogWindow = data.dialogWindow;
        this.selectedAgreementId = data.id;
        if (
          this.selectedAgreementId > 0 &&
          (!this.selectedAgreement || this.selectedAgreementId != this.selectedAgreement.id)
        ) {
          this.selectedAgreement = {};
          API.getAgreement(this.selectedAgreementId).then((res) => {
            this.selectedAgreement = res.data;
          });
        }
      },
      onCountrySelect() {
        this.loadData();
      },
      loadAgreementTypes() {
        let request = { pageNumber: 1, pageSize: 0 };
        API_NSI.search('NsiFreeTradeAgreementType', request).then((response) => {
          if (response.data) {
            this.agreementTypes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      loadData() {
        this.loaded = true;
        if (!this.selectedCountryId) {
          this.loaded = false;
          this.agreements = {};
          this.restyleGeoJsonLayer();
          return;
        }

        API.getAgreements(this.selectedCountryId, this.selectedTypeId).then((response) => {
          if (response.data) {
            this.agreements = response.data;
            this.selectedCountry = Object.assign(
              {},
              this.countries.find((x) => x.id == this.selectedCountryId),
            );
          }
          this.restyleGeoJsonLayer();
          this.loaded = false;
        });
      },
      styleMap(feature) {
        if (!(this.countries && this.countries.length && this.selectedCountryInner))
          return { color: '#60a7ee', fillColor: '#E3E3E3', weight: 1, opacity: 0.7 };

        let color = '#E3E3E3';
        let code = parseInt(feature.properties.iso_n3);
        if (this.selectedCountryInner && code == this.selectedCountryInner.code) {
          color = '#0085FF';
        }
        if (this.agreementsInner && this.agreementsInner[code] && this.agreementsInner[code].length > 0) {
          color = '#005AAD';
          let country = this.countriesInner.find((c) => c.code == code);
          if (country) {
            let agreementsList = ''; //`<ul>${feature.properties.name}</ul>`;
            this.agreementsInner[code].forEach((p) => {
              let str = `<li style="list-style-type: none;"><div style="display:flex;"><p>${p.name}</p><div>
                           <button class="btn-primary firesBtnModal" data-country="${feature.properties.iso_n3}" data-id="${p.id}">
              Просмотр
             </button></div>
              </li>`;
              agreementsList = agreementsList.concat(str);
            });
            feature.properties.agreementsList = agreementsList;
            feature.properties.rusName = country.text;
          }
          feature.properties.code = code;
        }
        if (code == 304) {
          return {
            color: '#60a7ee',
            fillColor: 'white',
            weight: 1,
            opacity: 0.5,
          };
        }
        return { color: '#60a7ee', fillColor: color, weight: 1, opacity: 0.7 };
      },

      onEachFeature(feature, layer) {
        layer.off('mouseup');
        layer.on('mouseup', () => {
          layer.unbindPopup();
          if (feature?.properties?.rusName) {
            layer.bindPopup(
              //feature.properties.rusName,
              `<div class="popup">
                      <div class="popup__head d-flex align-items-center justify-content-between">
                        <p class="font-weight-bold">${feature?.properties?.rusName}</p>
                      </div>
                      <div class="popup__body p-3">

                      ${feature.properties.agreementsList}

                </div>`,
              feature.properties.agreementsList,
              '',
            );
          } else {
            let countryName = this.countryNames.find((x) => x.code == feature.properties.iso_n3)?.name ?? 'Нет данных';
            layer.bindPopup(
              //feature.properties.rusName,
              `<div class="popup">
                      <div class="popup__head d-flex align-items-center justify-content-between">
                        <p class="font-weight-bold">${countryName}</p>
                      </div>
                      <div class="popup__body p-3">

                      Нет данных.

                </div>`,
              feature.properties.agreementsList,
              '',
            );
          }
          layer.openPopup();
        });
      },

      setupLeafletMap: function () {
        this.loaded = true;
        let mapContainer = window.document.getElementById('mapContainerTrade');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.mapDiv) {
          this.mapDiv.remove();
          this.mapDiv = null;
        }
        this.mapDiv = L.map('mapContainerTrade').setView(this.center, 1.4);
        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.mapDiv);
        window.addEventListener('click', (e) => {
          if (e.target.classList.contains('firesBtnModal')) {
            let firedCountry = e.target.getAttribute('data-country');
            let firedId = e.target.getAttribute('data-id');
            this.dialogWindow = true;
            this.openModal({
              dialogWindow: this.dialogWindow,
              id: firedId,
              countryCode: firedCountry,
            });
          }
        });
        this.geoJsonLayer = L.geoJSON(this.world, {
          weight: 0.2,
          fillOpacity: 0.9,
        }).addTo(this.mapDiv);
        this.loaded = false;
      },
      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },
      restyleLayer(layer) {
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },
      downloadFile(file) {
        return Utils.downloadFile(`/api/file/download?id=${file.id}`, file.originalName);
      },
    },
    watch: {
      agreements: function (newValue) {
        this.agreementsInner = newValue;
        if (this.countries && this.countries.length) this.restyleGeoJsonLayer();
      },
      selectedCountry: function (newValue) {
        this.selectedCountryInner = newValue;
        if (this.selectedCountry && this.countries && this.countries.length) this.restyleGeoJsonLayer();
      },
      countries: function (newValue) {
        this.countriesInner = newValue;
        if (this.countries && this.countries.length) this.restyleGeoJsonLayer();
      },
      countryNames: function () {
        this.restyleGeoJsonLayer();
      },
      selectedTypeId: function () {
        if (this.selectedCountry) this.loadData();
      },
    },
    created() {
      countryApi.search().then((ret) => {
        this.countryNames = [
          ...ret.data.items.map((item) => {
            return { name: item.name, nameFull: item.nameFull, id: item.id, code: item.code };
          }),
        ];
      });
    },
    mounted() {
      Api.search()
        .then((res) => {
          this.countries = res.data.items.map((item) => {
            return {
              id: item.id,
              code: item.code,
              text: item.name,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        });
      this.setupLeafletMap();
      this.loadAgreementTypes();
      this.loadData();
    },
  };
</script>

<style scoped lang="scss">
  .select2 {
    max-width: 320px;
    width: 100%;
  }

  h2 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }

  #mapContainerTrade {
    background-color: #ffffff;
    width: 100%;
    height: 500px;
  }

  .filter {
    display: flex;
    margin-bottom: 22px;

    &-block {
      max-width: 220px;
      width: 100%;
      margin-bottom: 8px;

      &:not(:last-of-type) {
        margin-right: 24px;
      }

      .multiselect {
        width: 100%;
      }

      &.document {
        max-width: 440px;
      }
    }

    &_link {
      margin: 0 0 8px 24px;
      display: block;
      width: auto;

      button {
        height: 40px;
      }
    }
  }

  .trade-investment {
    &__map {
      position: relative;
      z-index: 1;
    }

    &__modal {
      &-table {
        margin-top: 20px;
        overflow-x: auto;

        table {
          border: 1px solid #f3f3f3;
          border-radius: 3px;
          width: 740px;
          margin-bottom: 16px;

          tr {
            &:nth-of-type(odd) {
              background-color: #f3f3f3;
            }

            td {
              padding: 12px;
              font-weight: 400;
              font-size: 14px;
              line-height: 160%;
              color: #343c44;
            }
          }
        }
      }

      &-btns {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        a,
        button {
          //max-width: 250px;
          margin-right: 6px;
          margin-bottom: 8px;
          width: auto;
        }
      }

      &-files {
        li {
          display: flex;
          font-size: 14px;
          margin-bottom: 14px;

          .file-name {
            color: darkgray;
            margin-right: 16px;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .leaflet-container a.leaflet-popup-close-button {
    right: 8px;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    .leaflet-popup-content {
      max-height: 320px;
      overflow: auto;
      max-width: 100% !important;
      width: auto !important;
    }

    .leaflet-popup-tip-container {
      display: none;
    }

    .popup {
      &__head {
        background: #1d87ee;

        p,
        span {
          margin: 10px 32px 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #ffffff;
        }
      }

      &__body {
        display: flex;
        flex-direction: column;
        padding: 12px;

        li {
          padding: 12px;

          button {
            margin-left: 12px;
          }
        }

        li div {
          p {
            min-width: 240px;
            margin: 12px;
          }
        }

        p {
          margin: 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          color: #015caf;
        }
      }
    }

    .leaflet-popup-content {
      margin: 0;
    }
  }
</style>
