import { COUNTRY_ANALYTICS_ACTIONS_NAME, COUNTRY_ANALYTICS_MUTATIONS_NAME } from './directoryNames';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getLifeIndexSummary(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getLifeIndexSummary,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setLifeIndexSummary,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getLifeIndexSummary,
      pathParams: ['id'],
    },
    params,
  );
}

export async function getInvestmentInStock(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getInvestmentInStock,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setInvestmentInStock,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentInStock,
    },
    params,
  );
}

export async function getInvestmentOutStock(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getInvestmentOutStock,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setInvestmentOutStock,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentOutStock,
    },
    params,
  );
}

export async function getInvestmentInFlows(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getInvestmentInFlows,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setInvestmentInFlows,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentInFlows,
    },
    params,
  );
}

export async function getInvestmentOutFlows(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getInvestmentOutFlows,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setInvestmentOutFlows,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentOutFlows,
    },
    params,
  );
}

export async function getForeignTradeTurnoverWordBalance(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getForeignTradeTurnoverWordBalance,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setForeignTradeTurnoverWordBalance,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getBalanceForeignTradeTurnoverWord,
    },
    params,
  );
}

export async function getForeignTradeTurnoverWordGdpValues(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getForeignTradeTurnoverWordGdpValues,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setForeignTradeTurnoverWordGdpValues,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getGdpValuesForeignTradeTurnoverWord,
    },
    params,
  );
}

export async function getUnfriendlyCountryInfo(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getUnfriendlyCountryInfo,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setUnfriendlyCountryInfo,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getUnfriendlyCountryInfo,
      pathParams: ['id'],
    },
    params,
  );
}

export async function getTopImportIndustries(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getTopImportIndustries,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setTopImportIndustries,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopImportIndustries,
    },
    params,
  );
}

export async function getTopExportIndustries(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getTopExportIndustries,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setTopExportIndustries,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopExportIndustries,
    },
    params,
  );
}

export async function getTopImportCountries(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getTopImportCountries,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setTopImportCountries,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopImportCountries,
    },
    params,
  );
}

export async function getTopExportCountries(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getTopExportCountries,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setTopExportCountries,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopExportCountries,
    },
    params,
  );
}

export async function getIndicatorBudgetDeficit(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getIndicatorBudgetDeficit,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setIndicatorBudgetDeficit,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getIndicatorBudgetDeficit,
    },
    params,
  );
}
export async function getIndicatorStockMarketCapitalization(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getIndicatorStockMarketCapitalization,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setIndicatorStockMarketCapitalization,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getIndicatorStockMarketCapitalization,
    },
    params,
  );
}
export async function getIndicatorFinancialAccount(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getIndicatorFinancialAccount,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setIndicatorFinancialAccount,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getIndicatorFinancialAccount,
    },
    params,
  );
}
export async function getIndicatorGovernmentBondYields(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getIndicatorGovernmentBondYields,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setIndicatorGovernmentBondYields,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getIndicatorGovernmentBondYields,
    },
    params,
  );
}
export async function getYearListTurnover(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getYearListTurnover,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setYearListTurnover,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getYearListTurnover,
    },
    params,
  );
}
export async function getForeignTrade(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getForeignTradeData,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setForeignTradeData,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getForeignTrade,
      pathParams: ['year', 'countryId'],
    },
    params,
  );
}
export async function getMacroEconomicIndicatorValue(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getMacroEconomicIndicatorValue,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setMacroEconomicIndicatorValue,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getMacroEconomicIndicatorValue,
    },
    params,
  );
}
export async function getEconomicReviewSummary(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getEconomicReviewSummary,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setEconomicReviewSummary,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getEconomicReviewSummary,
    },
    params,
  );
}
export async function getIndicatorCountryRating(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getIndicatorCountryRating,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setIndicatorCountryRating,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getIndicatorCountryRating,
    },
    params,
  );
}

export async function getExportProductLast(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getExportProductLast,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setExportProductLast,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getLastExportProduct,
      pathParams: ['id'],
    },
    params,
  );
}

export async function getFerRegionsTradeValue(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getFerRegionsTradeValue,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setFerRegionsTradeValue,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getFerRegionsTradeValue,
    },
    params,
  );
}

export async function getFerRegionsTradeCountryCount(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getFerRegionsTradeCountryCount,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setFerRegionsTradeCountryCount,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getFerRegionsTradeCountryCount,
    },
    params,
  );
}

export async function getQuartalList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getQuartalList,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setQuartalList,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getQuartalList,
    },
    params,
  );
}

export async function getQuartalSummary(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.countryAnalytics.analytics.getQuartalSummary,
      mutate: COUNTRY_ANALYTICS_MUTATIONS_NAME.setQuartalSummary,
      name: COUNTRY_ANALYTICS_ACTIONS_NAME.getQuartalSummary,
    },
    params,
  );
}
