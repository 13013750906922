import { mapActions, mapGetters } from 'vuex';

import Api from '../../../api';
import {
  COUNTRY_ANALYTICS_ACTIONS_NAME,
  COUNTRY_ANALYTICS_GETTERS_NAME,
} from '../../../store/analytics/directoryNames';
import { QuartalsObject } from '../../../utils/constants';

export default {
  data() {
    return {
      year: new Date().getFullYear() - 1,
      yearList: [],
      graphByYear: null, //Торговля товарами/услугами, млн $ США (график по годам)
      summary: null,

      quartal: null,
      series: [],
      categories: [],
      graphKey: true,
    };
  },
  computed: {
    ...mapGetters({
      quartals: COUNTRY_ANALYTICS_GETTERS_NAME.getQuartals,
      quartalSummary: COUNTRY_ANALYTICS_GETTERS_NAME.getSummaryQuartal,
    }),
  },
  methods: {
    ...mapActions({
      getQuartalList: COUNTRY_ANALYTICS_ACTIONS_NAME.getQuartalList,
      getQuartalSummary: COUNTRY_ANALYTICS_ACTIONS_NAME.getQuartalSummary,
    }),
    loadYearList(thenLoadData, formatData) {
      this.summary = null;
      this.graphByYear = null;
      Api.getYearList(true, this.isProductMode).then((response) => {
        if (formatData) {
          this.yearList = response.data.map((i) => ({ value: +i.id, label: i.text }));
          this.year = this.yearList.slice(-1)[0]?.value || '';
        } else {
          this.yearList = response.data;
          this.year = this.yearList.slice(-1)[0].id;
        }
        if (thenLoadData) this.loadData();
      });
    },
    resolveSelectTab({ isCondition, id, label }) {
      if (isCondition) {
        this.selectTab(id, label);
      }
    },
    sortByYear(x, y) {
      if (x.year < y.year) {
        return -1;
      }
      if (x.year > y.year) {
        return 1;
      }
      return 0;
    },
    getAvailableYears() {
      return this.yearList.filter((x) => x.id > 2016 && x.id <= this.year);
    },
    getValuesByYears(value, divider = 1000000) {
      return this.summary.summary.valuesByYears.map((x) => (x[value] / divider).toFixed(0));
    },

    async loadQuartals() {
      this.quartal = null;
      await this.getQuartalList({ countryId: this.countryId, year: this.year });
    },
    async loadDataQuartal() {
      const quartalSummary = await this.getQuartalSummary({
        countryId: this.countryId,
        year: this.year,
        quartal: this.quartal,
      });
      if (quartalSummary.success) {
        this.buildDataQuartalSummary();
      }
    },

    buildDataQuartalSummary() {
      this.summary.summary = Object.assign(this.summary.summary, this.quartalSummary);

      this.summary.summary.exportValuePreviousPercent = this.quartalSummary?.exportValuePrevPercent;
      this.summary.summary.importValuePreviousPercent = this.quartalSummary?.importValuePrevPercent;
      this.summary.summary.totalValuePreviousPercent = this.quartalSummary?.totalValuePrevPercent;
      this.summary.summary.balance = this.quartalSummary?.balanceValue;
      this.summary.summary.balancePreviousPercent = this.quartalSummary?.balanceValuePrevPeriod;

      this.categories = this.quartalSummary.valuesByQuartals.map(
        ({ quartal, year }) => `${this.resolveQuartalLabel(quartal)} ${year}`,
      );

      this.series = [
        {
          name: 'Оборот услуг',
          data: this.quartalSummary.valuesByQuartals.map(({ totalValue }) => totalValue.toFixed(1)),
        },
        {
          name: 'Экспорт',
          data: this.quartalSummary.valuesByQuartals.map(({ exportValue }) => exportValue.toFixed(1)),
        },
        {
          name: 'Импорт',
          data: this.quartalSummary.valuesByQuartals.map(({ importValue }) => importValue.toFixed(1)),
        },
        {
          name: 'Сальдо',
          data: this.quartalSummary.valuesByQuartals.map(({ balance }) => balance.toFixed(1)),
        },
      ];
      this.graphKey = !this.graphKey;
    },
    resolveQuartalLabel(quartal) {
      return QuartalsObject[Number(quartal)]?.value;
    },
    clearGraph() {
      this.categories = [];
      this.series = [];
    },
  },
};
