import axios from 'axios';

export default {
  loadHistory: function (id) {
    return axios.get('/api/nsi/nsiinvestigationtype/historyItems', { params: { id: id } });
  },
  loadRelated: function (request) {
    return axios.get('/api/nsi/nsiinvestigationtype/searchRelatedItems', { params: request });
  },
};
