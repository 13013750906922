import AddressBook from '../views/AddressBook';
import AuditLog from '../views/AuditLog';
import DepartmentCard from '../views/DepartmentCard';
import Departments from '../views/Departments';
import IndicatorVisibility from '../views/IndicatorVisibility';
import ListOfUsersOnNews from '../views/ListOfUsersOnNews';
import NotificationSettings from '../views/NotificationSettings';
import OpenClientMenu from '../views/OpenClientMenu';
import OrganizationCard from '../views/OrganizationCard';
import Organizations from '../views/Organizations';
import Permissions from '../views/Permissions';
import RoleCard from '../views/RoleCard';
import RoleGroupCard from '../views/RoleGroupCard';
import Roles from '../views/Roles';
import SystemSettings from '../views/Settings';
import UserCard from '../views/UserCard';
import UserNotifications from '../views/UserNotifications';
import Users from '../views/Users';

export const routes = [
  {
    path: '/admin/organizations',
    name: 'Organizations',
    component: Organizations,
  },
  {
    path: '/admin/organizations/:action/:id',
    name: 'OrganizationCard',
    component: OrganizationCard,
  },
  {
    path: '/admin/departments',
    name: 'Departments',
    component: Departments,
  },
  {
    path: '/admin/departments/:action/:id',
    name: 'DepartmentCard',
    component: DepartmentCard,
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: Users,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/UsersOnNews',
    name: 'usersOnNews',
    component: ListOfUsersOnNews,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/users/:action/:id',
    name: 'UserCard',
    component: UserCard,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/permissions',
    name: 'Permissions',
    component: Permissions,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/audit',
    name: 'AuditLog',
    component: AuditLog,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/openClientMenu',
    name: 'OpenClientMenu',
    component: OpenClientMenu,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/Roles',
    name: 'Roles',
    component: Roles,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/roles/:action/:id',
    name: 'RoleCard',
    component: RoleCard,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/rolegroups/:action/:id',
    name: 'RoleGroupCard',
    component: RoleGroupCard,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/IndicatorVisibility',
    name: 'IndicatorVisibility',
    component: IndicatorVisibility,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/settings',
    name: 'SystemSettings',
    component: SystemSettings,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/userNotifications',
    name: 'UserNotification',
    component: UserNotifications,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/notificationSettings',
    name: 'NotificationSettings',
    component: NotificationSettings,
    meta: { isAdmin: true },
  },
  {
    path: '/admin/addressBook',
    name: 'AddressBook',
    component: AddressBook,
    meta: { isAdmin: true },
  },
];
