import OrgApi from '@/modules/admin/api/organization';

import Api from '../../api/reqsinitiativesttr';
import { INITIATIVE_REGISTRY_ACTIONS_NAME } from './name';

import links from '@/utils/links';
import request from '@/utils/request';

export async function setUser({ rootState, commit }) {
  let profile = rootState.auth?.oidc?.profile;

  if (profile == null) {
    profile = JSON.parse(localStorage.getItem('userProfileData'));
  }

  commit(`setEmployee`, profile?.employee ?? {});

  let orgId = profile?.employee?.organizationId;
  if (orgId) {
    const response = await OrgApi.find(orgId);
    commit('setOrganization', response.data);
  }
}

export function createInitiative({ state }) {
  return Api.create(state.initiative);
}

export async function changeParametersMulti(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.measure.initiativeRegistry.changeParametersMulti,
      name: INITIATIVE_REGISTRY_ACTIONS_NAME.changeParametersMulti,
      method: 'patch',
    },
    params,
  );
}

export async function updateInitiative({ state }) {
  const response = await Api.update(state.initiative);
  state.initiative = response.data;
  return response;
}

export async function findInitiative({ commit }, id) {
  const response = await Api.find(id);
  const newInitiative = response.data;

  commit('setInitiative', newInitiative);
}
