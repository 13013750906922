import moment from 'moment';

import constants from '@/common/constants';

const getMonthPeriodWithYear = (year, monthIndex) =>
  monthIndex === 0
    ? `${year} (${constants.monthListSimple[monthIndex]})`
    : `${year} (${constants.monthListSimple[0]} - ${constants.monthListSimple[monthIndex]})`;

export function getYearOptions(state) {
  state.loadYearOptions = true;
  const options = [];
  if (state?.year) {
    let i = +state.year;
    const currentYear = new Date().getFullYear();
    for (i; i < currentYear + 1; i++) {
      options.push({
        value: +i,
        label: i,
      });
    }
  }
  state.loadYearOptions = false;
  return options;
}

export function getYearOptionsLoad(state) {
  return state.loadYearOptions;
}

export function getYearAndMonthOptions(state) {
  state.loadYearMonthOptions = true;
  const options = [];
  if (state?.year) {
    let iYear = +state.year;
    const allMonth = 12;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    for (iYear; iYear < currentYear + 1; iYear++) {
      if (iYear !== currentYear) {
        for (let iMonth = 0; iMonth < allMonth; iMonth++) {
          options.push({
            id: moment(`${iYear}.${iMonth + 1}.01`).format('YYYY.MM.DD'),
            name: getMonthPeriodWithYear(iYear, iMonth),
          });
        }
      } else {
        for (let iMonth = 0; iMonth < currentMonth + 1; iMonth++) {
          options.push({
            id: moment(`${iYear}.${iMonth + 1}.01`).format('YYYY.MM.DD'),
            name: getMonthPeriodWithYear(iYear, iMonth),
          });
        }
      }
    }
  }
  state.loadYearMonthOptions = false;
  return options;
}

export function getYearAndMonthOptionsLoad(state) {
  return state.loadYearMonthOptions;
}
