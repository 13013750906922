export const INVESTMENT_AGREEMENT = 'investmentAgreement';

export const INVESTMENT_AGREEMENT_ACTIONS_NAME = {
  getCountriesAgreements: `${INVESTMENT_AGREEMENT}/getCountriesAgreements`,
  getCountriesGroupAgreements: `${INVESTMENT_AGREEMENT}/getCountriesGroupAgreements`,
  getListAgreements: `${INVESTMENT_AGREEMENT}/getListAgreements`,
  findCardAgreements: `${INVESTMENT_AGREEMENT}/findCardAgreements`,
  createAgreements: `${INVESTMENT_AGREEMENT}/createAgreements`,
  updateAgreements: `${INVESTMENT_AGREEMENT}/updateAgreements`,
  deleteAgreements: `${INVESTMENT_AGREEMENT}/deleteAgreements`,
};

export const INVESTMENT_AGREEMENT_MUTATIONS_NAME = {
  updateCountriesAgreements: 'updateCountriesAgreements',
  updateCountriesGroupAgreements: 'updateCountriesGroupAgreements',
  setAgreementsPage: 'setAgreementsPage',
  joinAgreementsPage: 'joinAgreementsPage',
};

export const INVESTMENT_AGREEMENT_GETTERS_NAME = {
  getListCountriesAgreements: `${INVESTMENT_AGREEMENT}/getListCountriesAgreements`,
  getListCountriesGroupAgreements: `${INVESTMENT_AGREEMENT}/getListCountriesGroupAgreements`,
  getAgreementsPage: `${INVESTMENT_AGREEMENT}/getAgreementsPage`,
  getListCountriesAgreementsSelect: `${INVESTMENT_AGREEMENT}/getListCountriesAgreementsSelect`,
  getListCountriesGroupAgreementsSelect: `${INVESTMENT_AGREEMENT}/getListCountriesGroupAgreementsSelect`,
};
