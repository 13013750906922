<template>
  <div class="popup">
    <div class="popup__head d-flex align-items-center">
      <div
        v-if="country?.picture"
        class="imagePreviewWrapper"
        :style="{ 'background-image': `url(${country?.picture})` }"
      ></div>
      <p class="font-weight-bold">{{ country?.nameFull }}</p>
    </div>
    <div class="popup__body p-3">
      <strong class="total-projects">Всего проектов: {{ projectItems.length }}</strong>
      <p class="popup__body-country" @click="openJointProjects">{{ country?.name }}</p>
      <ul class="popup__body_list" v-if="projects?.items">
        <li class="popup__body_list-item" v-for="p in projectItems" :key="p.id" @click="openProjectCard(p.id)">
          {{ p.name }}
        </li>
      </ul>
      <ButtonStock class="btn-small mt-2" title="Все проекты" v-on:click="openJointProjects" />
    </div>
  </div>
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock';

  import API_Project from '@/modules/mpkProject/api/index';
  import API_Country from '@/modules/nsi/api/country';

  export default {
    name: 'ProjectPopup',
    components: { ButtonStock },
    emits: ['allProjectsClick'],
    props: {
      countryId: {
        type: Number,
        default: null,
      },
      industryId: {
        type: Number,
        default: null,
      },
      purposeId: {
        type: Number,
        default: null,
      },
      statusId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        country: null,
        projects: {},
      };
    },
    created() {},
    mounted() {
      this.$watch(
        (vm) => [vm.industryId, vm.purposeId, vm.statusId],
        () => {
          if (this.countryId) this.loadProjects();
        },
      );
    },
    beforeUnmount() {},
    methods: {
      loadCountry() {
        this.country = null;
        if (this.countryId)
          API_Country.findShort(this.countryId).then((response) => {
            this.country = response.data;
          });
      },
      loadProjects() {
        let request = { pageNumber: 1, pageSize: 0, countryIds: [this.countryId] };
        if (this.industryId) request.industryIds = [this.industryId];
        if (this.purposeId) request.purposeIds = [this.purposeId];
        if (this.statusId) request.statusIds = [this.statusId];

        API_Project.projectsByCountry(request).then((response) => {
          this.projects = response.data;
        });
      },
      openJointProjects() {
        this.$emit('allProjectClick', this.countryId);
      },
      openProjectCard(id) {
        this.$router.push({ name: 'ProjectCard', params: { id: id } });
      },
    },
    watch: {
      countryId: function () {
        this.loadCountry();
        if (this.countryId) this.loadProjects();
      },
    },
    computed: {
      projectItems() {
        if (this.projects && this.projects.items && Object.keys(this.projects.items).length > 0)
          return this.projects.items[Object.keys(this.projects.items)[0]];
        else return [];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup {
    &__head {
      background: #1d87ee;
      p,
      span {
        margin: 10px 32px 10px 24px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        color: #ffffff;
      }
    }
    &__body {
      strong {
        color: #0078a8;
        margin: 0 0 8px 0;
        display: block;
      }
      &_list {
        max-height: 130px;
        overflow-y: auto;
        &-item {
          list-style-type: none;
          padding: 6px 0 !important;
          margin: 4px 0;
          color: #015caf;
          cursor: pointer;
        }
      }
      &-country {
        margin: 5px 0 !important;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: #015caf;
        cursor: pointer;
      }
    }
  }
  .imagePreviewWrapper {
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;
    margin: 5px 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
