<template>
  <div class="container-page">
    <div class="mb-16 d-flex flex-row justify-content-between">
      <div class="mb-16 d-flex flex-row setting_container">
        <ButtonStock
          title="Очистить"
          @click="clear()"
          v-if="$store.state.auth?.oidc?.profile && $store.state.auth?.oidc?.profile.id == 1"
        />
        <ButtonStock class="ml-2" title="Экспорт" @click="exportExcel" :disabled="exportInProgress" />
        <ButtonStock class="ml-2" title="Импорт" @click="importExcel" :disabled="exportInProgress" />
        <ButtonStock class="ml-2" title="Печать" @click="printData" :disabled="printingInProgress" />
      </div>
    </div>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th @click="changeSorting(0)" :class="getClassSorting(0) + ' ' + 'cursor-pointer'">ID</th>
            <th @click="changeSorting(1)" :class="getClassSorting(1) + ' ' + 'cursor-pointer'">Критичность</th>
            <th @click="changeSorting(2)" :class="getClassSorting(2) + ' ' + 'cursor-pointer'">Модуль</th>
            <th @click="changeSorting(3)" :class="getClassSorting(3) + ' ' + 'cursor-pointer'">Действие</th>
            <th @click="changeSorting(4)" :class="getClassSorting(4) + ' ' + 'cursor-pointer'">Пользователь</th>
            <th @click="changeSorting(5)" :class="getClassSorting(5) + ' ' + 'cursor-pointer'">Дата и время</th>
            <th @click="changeSorting(6)" :class="getClassSorting(6) + ' ' + 'cursor-pointer'">Сущность</th>
          </tr>
          <tr>
            <th></th>
            <th>
              <FilterInput
                type="select2"
                :select2-options="severities"
                :select2-settings="select2Settings"
                v-model="request.severityIds"
                multiple
                :placeholder="placeholderAll"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="modules"
                :select2-settings="select2Settings"
                v-model="request.moduleIds"
                multiple
                :placeholder="placeholderAll"
                v-on:select2OnSelect="loadPermissions"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="permissions"
                :select2-settings="select2Settings"
                v-model="request.permissionIds"
                multiple
                :placeholder="placeholderAll"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="users"
                :select2-settings="select2Settings"
                v-model="request.userIds"
                multiple
                :placeholder="placeholderAll"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <div class="range">
                <FilterInput
                  type="date"
                  v-model="request.dateFrom"
                  class="mr-2"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput type="time" v-model="request.timeFrom" v-on:valueChangedDebounced="changeFilter" />
              </div>
              <div class="range">
                <FilterInput
                  type="date"
                  class="mr-2"
                  v-model="request.dateTo"
                  v-on:valueChangedDebounced="changeFilter"
                />
                <FilterInput type="time" v-model="request.timeTo" v-on:valueChangedDebounced="changeFilter" />
              </div>
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="entityTypes"
                :select2-settings="select2Settings"
                v-model="request.entityTypeIds"
                multiple
                :placeholder="placeholderAll"
                v-on:select2OnSelect="loadPermissions"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="page?.items?.length && !isLoading">
          <tr
            v-for="item in page.items"
            :key="item.id"
            :class="`log-severity-${item.severityId}`"
            @dblclick="viewDetails(item.id)"
          >
            <td>{{ item.id }}</td>
            <td>
              <a href="javascript:" @click="viewDetails(item.id)">{{ item.severityName }}</a>
            </td>
            <td>{{ item.moduleName }}</td>
            <td>{{ item.permissionName }}</td>
            <td>{{ item.userName }}</td>
            <td>{{ item.formattedDate }}</td>
            <!-- <td>{{$momentFormat(item.formattedDate, "DD.MM.yyyy HH:mm:ss")}}</td> -->
            <td>{{ item.entityTypeName }}</td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" colspan="8">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="8">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        :showCountPerPage="true"
        :currentCountPerPage="request.pageSize"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
        @recordsPerPageChanged="onRecordsPerPageChange($event)"
      />
    </div>

    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import { forEach } from 'lodash';

  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ImportModal from '@/components/modals/ImportModal';
  import ModalComponent from '@/components/modals/ModalComponent';

  import FilterInput from '../../../common/components/FilterInput';
  import Api from '../api/eventlog';
  import UserApi from '../api/users';

  export default {
    name: 'AuditLogActions',
    components: { FilterInput, ButtonStock, ModalComponent, ImportModal },
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          severityIds: [],
          moduleIds: [],
          dateFrom: null,
          dateTo: null,
          userIds: [],
          permissionIds: [],
          entityTypeIds: [],
          includeDescription: false,
        },
        page: {},
        isLoading: false,
        modules: [],
        severities: [],
        users: [],
        permissions: [],
        entityTypes: [],
        select2Settings: Constants.select2SettingsMultiple,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
        placeholderAll: '(Все)',
        exportInProgress: false,
        printingInProgress: false,
        importModal: false,
      };
    },
    created() {
      this.loadPage();
      this.loadDictionaries();
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;

        const params = Object.assign({}, this.request);
        if (params.dateFrom != null && this.request.timeFrom && this.request.timeFrom.length == 5)
          params.dateFrom += ' ' + this.request.timeFrom + ':00';
        if (params.dateTo != null && this.request.timeTo && this.request.timeTo.length == 5)
          params.dateTo += ' ' + this.request.timeTo + ':01';

        Api.search(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      loadDictionaries() {
        Api.getSeverities().then((response) => {
          this.severities = response.data.map((x) => ({ id: x.id, text: x.title }));
        });
        Api.getModules().then((response) => {
          this.modules = response.data.map((x) => ({ id: x.id, text: x.title }));
        });
        Api.getEntityTypes().then((response) => {
          this.entityTypes = response.data.map((x) => ({ id: x.id, text: x.title }));
        });
        UserApi.search(this.defaultRequest)
          .then((response) => {
            this.users = response.data.items.map((x) => {
              return { text: x.fullName, id: x.userId };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      viewDetails(id) {
        Api.find(id)
          .then((response) => {
            let items = [];
            items.push(`<tr><td>Идентификатор</td><td>${response.data.id}</td></tr>`);
            items.push(`<tr><td>Критичность</td><td>${response.data.severityName}</td></tr>`);
            items.push(`<tr><td>Модуль</td><td>${response.data.moduleName}</td></tr>`);
            items.push(`<tr><td>Действие</td><td>${response.data.permissionName}</td></tr>`);
            items.push(`<tr><td>Пользователь</td><td>${response.data.userName}</td></tr>`);
            items.push(`<tr><td>Дата и время</td><td>${response.data.formattedDate}</td></tr>`);
            if (response.data.entityTypeName) {
              items.push(`<tr><td>Сущность</td><td>${response.data.entityTypeName}</td></tr>`);
            }
            if (response.data.itemId) {
              items.push(`<tr><td>Идентификатор сущности</td><td>${response.data.itemId}</td></tr>`);
            }
            if (response.data.description) {
              items.push(`<tr><td>Описание</td><td>${response.data.description}</td></tr>`);
            }
            if (response.data.additionalInfo) {
              items.push(`<tr><td>Дополнительная информация:</td></tr>`);
              items.push(
                `<tr><td colspan="2" class="table__additional-info">${response.data.additionalInfo}</td></tr>`,
              );
            }
            const content = `<table class="log-details-table"><tbody>${items.join('\n')}</tbody></table>`;
            Constants.alert.fire({
              title: 'Подробности события',
              html: content,
              width: '760px',
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.loadPage(false);
      },
      loadPermissions() {
        const request = Object.assign(
          {
            moduleIds: this.request.moduleIds,
            entityTypeIds: this.request.entityTypeIds,
          },
          this.defaultRequest,
        );
        Api.getPermissions(request).then((response) => {
          this.permissions = response.data.items.map((x) => ({ id: x.id, text: x.name }));
        });
      },
      onPageChange(pageNumber, append) {
        this.request.pageNumber = pageNumber;
        this.loadPage(append);
      },
      onRecordsPerPageChange(recordCount) {
        this.request.pageNumber = 1;
        this.request.pageSize = recordCount;
        this.loadPage();
      },
      clear() {
        var options = {
          title: 'Удаление',
          text: 'Вы действительно хотите удалить записи аудита?',
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          showCancelButton: true,
          icon: 'warning',
        };
        Constants.alert.fire(options).then((res) => {
          if (res.isConfirmed) {
            Api.deleteAll(this.request)
              .then((response) => {
                console.log(response);
                //Constants.alert.fire('Удаление', "Запись удалена", 'success')
                this.request.pageNumber = 1;
                this.loadPage(false);
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Записи не удалены',
                  'error',
                );
              });
          }
        });
      },
      exportExcel() {
        this.exportInProgress = true;
        Api.exportExcel(this.request).then(() => {
          this.exportInProgress = false;
        });
      },
      printData() {
        this.printingInProgress = true;
        const request = Object.assign({}, this.request);
        request.pageNumber = 1;
        request.pageSize = 0;
        Api.search(request)
          .then((response) => {
            this.printDiv(response.data.items);
            this.printingInProgress = false;
          })
          .catch((error) => {
            this.printingInProgress = false;
            console.error(error?.response?.data);
          });
      },
      printDiv(items) {
        let frame1 = document.createElement('iframe');
        frame1.name = 'frame1';
        frame1.style.position = 'absolute';
        frame1.style.top = '-1000000px';
        document.body.appendChild(frame1);
        let frameDoc = frame1.contentWindow
          ? frame1.contentWindow
          : frame1.contentDocument.document
            ? frame1.contentDocument.document
            : frame1.contentDocument;
        frameDoc.document.open();
        frameDoc.document.write('<html lang="ru"><head><title>Журнал учета операций в системе</title>');
        frameDoc.document.write('<style lang="css">');
        frameDoc.document.write('p {');
        frameDoc.document.write('font-weight: 600;');
        frameDoc.document.write('font-size: 22px');
        frameDoc.document.write('}');
        frameDoc.document.write('.print__table, th, td {');
        frameDoc.document.write('border: 1px solid black;');
        frameDoc.document.write('border-collapse: collapse;');
        frameDoc.document.write('}');
        frameDoc.document.write('</style>');
        frameDoc.document.write('</head><body>');
        frameDoc.document.write('<p>Журнал учета операций в системе</p>');
        frameDoc.document.write('<table class="print__table"><thead></thead><tbody>');
        frameDoc.document.write(
          '<tr><th>ID</th><th>Критичность</th><th>Модуль</th><th>Действие</th><th>Пользователь</th><th>Дата и время</th><th>Сущность</th></tr>',
        );
        frameDoc.document.write('</thead><tbody>');
        forEach(items, (item) => {
          frameDoc.document.write('<tr>');
          frameDoc.document.write('<td>' + item.id + '</td>');
          frameDoc.document.write('<td>' + item.severityName + '</td>');
          frameDoc.document.write('<td>' + item.moduleName + '</td>');
          frameDoc.document.write('<td>' + item.permissionName + '</td>');
          frameDoc.document.write('<td>' + item.userName + '</td>');
          frameDoc.document.write('<td>' + item.formattedDate + '</td>');
          let entityName = item.entityTypeName ?? '';
          frameDoc.document.write('<td>' + entityName + '</td>');
          frameDoc.document.write('</tr>');
        });
        frameDoc.document.write('</tbody></table>');
        frameDoc.document.write('</body></html>');
        frameDoc.document.close();
        setTimeout(function () {
          window.frames['frame1'].focus();
          window.frames['frame1'].print();
          document.body.removeChild(frame1);
        }, 500);
        return false;
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.uploadFile(file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch(() => {
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      closeModal() {
        this.importModal = false;
      },
      getClassSorting(id) {
        if (this.request.sortColumn == id) {
          if (this.request.sortOrder == 0) return 'table-header_ascending';
          else if (this.request.sortOrder == 1) return 'table-header_descending';
        }
        return '';
      },
      changeSorting(id) {
        if (this.request.sortColumn == id)
          switch (this.request.sortOrder) {
            case 0:
              this.request.sortOrder = 1;
              break;
            case 1:
              this.request.sortOrder = null;
              break;
            default:
              this.request.sortOrder = 0;
              break;
          }
        else this.request.sortOrder = 0;
        this.request.sortColumn = id;
        this.loadPage(false);
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default/colors';
  tr {
    &.log-severity-3 {
      td {
        background-color: #ffeeee !important;
        a {
          color: $colorRed !important;
        }
      }
    }
  }
  .log-details-table {
    width: 100%;
    tr {
      td:first-child {
        font-weight: bold;
      }
    }
    td {
      text-align: left;
      // white-space: pre;
      padding: 4px;
    }
    .table__additional-info {
      font-weight: normal !important;
      white-space: pre-wrap;
    }
  }
  .filter {
    .range {
      display: flex;
      .input-group-wrapper {
        input {
          padding: 4px 8px;
          min-width: 100px;
          max-width: 100px;
        }
      }
    }
  }
</style>
