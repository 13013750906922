<template>
  <div class="container-page">
    <div class="library">
      <p class="library__title title-inner">Библиотека документов</p>
      <div class="library__container">
        <!-- дерево с поиском -->
        <div class="library__container-tree" v-show="!dialogAddEdit">
          <div class="tree-search">
            <div class="tree-search__block">
              <input placeholder="Поиск каталога" v-model="searchTextTree" type="text" />
              <IconComponent name="search_currentCol" />
            </div>
            <div class="tree-search__block">
              <input placeholder="Поиск документа по имени" v-model="searchDocumentText" type="text" />
              <IconComponent name="search_currentCol" />
            </div>
            <div class="tree-search__block mb-4">
              <ComponentInput
                type="select2"
                :select2Settings="select2Settings"
                placeholder="Поиск документа по типу"
                v-model="searchDocumentType"
                :select2Options="docTypes"
              />
            </div>
            <p class="tree-search__title">Корень</p>
            <!-- <p>{{this.firedItem}}</p> -->
            <TreeSelectSearch
              :expandAllRowsOnSearch="true"
              :search-text="searchTextTree"
              v-model:nodes="libraryTree"
              @getId="getIdTreeItem($event)"
              :catchFiredItem="catchFiredItem"
              :nodeActiveId="selectedIdTreeItem"
            />
          </div>
        </div>
        <!-- таблица -->
        <div class="library__container-table">
          <div class="library__container-table-btns header">
            <ButtonStock title="Создать папку" @click="editFolder(0)" />
            <ButtonStock @click="editFolder(selectedItem.id)" title="Изменить папку" v-if="selectedItem" />
            <ButtonStock @click="deleteFolder" title="Удалить папку" v-if="selectedItem" />
          </div>

          <div
            class="library__container-table-btns footer mt-0 mb-3"
            v-if="(selectedItem && !selectedItem.isCatalog) || showButtons"
          >
            <ButtonStock
              mode="icon"
              @click="editDoc(0)"
              iconName="paper-plus"
              btnType="whiteGreenBordered"
              title="Добавить документ"
            />
            <ButtonStock
              mode="icon"
              iconName="paper-download"
              title="Скачать файлы"
              btnType="whiteGreenBordered"
              @click="downloadFolder"
            />
          </div>

          <TableComponent
            v-if="!searchItem && selectedItem && !selectedItem.isCatalog"
            :items="selectedItem"
            @deleteDoc="deleteDoc"
            @downloadDoc="downloadDoc"
            @copyLink="copyLink"
            @editDoc="editDoc"
          />
          <TableComponent
            v-if="searchItem"
            :items="searchItem"
            @deleteDoc="deleteDoc"
            @downloadDoc="downloadDoc"
            @copyLink="copyLink"
            @editDoc="editDoc"
          />
        </div>
      </div>
    </div>

    <!-- create file -->
    <ModalComponent
      headType="blue"
      :title="editFolderModel.title"
      @close="editFolderShowModal = false"
      v-model="editFolderShowModal"
    >
      <div class="form__body form__body-tab-container">
        <tabs v-model="selectedFolderTabIndex">
          <tab name="Общая информация" :selected="true">
            <div class="form__body-inputs-row">
              <label for="fName1">Название</label>
              <div class="input-block">
                <ComponentInput placeholder="Введите название" id="fName1" v-model="editFolderModel.name" />
              </div>
            </div>
            <div class="form__body-inputs-row">
              <label>Страны</label>
              <ComponentInput
                :multiselect="true"
                type="select2"
                :select2Settings="select2SettingsMultiple"
                placeholder="Выберите"
                v-model="editFolderModel.countryIds"
                :select2Options="countries"
              />
            </div>
            <div class="form__body-inputs-row">
              <div class="d-flex align-items-baseline justify-content-between">
                <label class="mr-4">Каталог</label>
                <input type="checkbox" v-model="editFolderModel.isCatalog" />
              </div>
            </div>

            <div class="d-flex mt-4 justify-content-end">
              <ButtonStock @click="saveFolder" title="Сохранить" />
              <ButtonStock class="ml-4" title="Отмена" @click="editFolderShowModal = false" />
            </div>
          </tab>
          <tab name="История" :selected="false" :disabled="editFolderModel.id == 0">
            <div class="table-container mb-16 js-table-arrow">
              <table class="stockTable">
                <thead>
                  <tr>
                    <th>Тип операции</th>
                    <th>Дата</th>
                    <th>Пользователь</th>
                  </tr>
                </thead>
                <tbody v-if="historyItems?.length > 0 && !historyLoading">
                  <tr v-for="item in historyItems" :key="item.id">
                    <td>{{ item.operationType?.name }}</td>
                    <td>{{ $momentFormat(item.date, 'DD MMMM YYYY HH:mm') }}</td>
                    <td>{{ item.user?.fullName }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="historyLoading">
                  <tr>
                    <td class="text-center" colspan="3">Загрузка...</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="3">Нет данных.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- btns footer -->
            <div class="form__body-btns footer">
              <ButtonStock btnType="whiteGreenBordered" title="Закрыть" @click="editFolderShowModal = false" />
            </div>
          </tab>
        </tabs>
      </div>
    </ModalComponent>
    <!--    edit file-->

    <!-- Edit modal -->
    <ModalComponent
      headType="blue"
      :title="editDocModel.id > 0 ? 'Редактирование документа' : 'Добавление нового документа'"
      @close="dialogAddEdit = false"
      v-model="dialogAddEdit"
    >
      <div class="form__body form__body-tab-container">
        <tabs v-model="selectedDocTabIndex">
          <tab name="Общая информация" :selected="true">
            <div class="form__body-create">
              <div class="form__body-inputs">
                <!-- input -->
                <div class="form__body-inputs-row">
                  <label for="file1">Файл</label>
                  <div class="input-block file-upload-container">
                    <FileUpload
                      width="100%"
                      :entity="editDocModel"
                      @deleteFile="removeDocFile()"
                      required
                      prop-name="file"
                      @uploaded="fileDocUploaded"
                    />
                  </div>
                </div>
                <div class="form__body-inputs-row">
                  <label for="fName1">Название</label>
                  <div class="input-block">
                    <ComponentInput placeholder="Введите название" id="fName1" v-model="editDocModel.name" />
                  </div>
                </div>
                <div class="form__body-inputs-row">
                  <label>Тип документа</label>
                  <ComponentInput
                    type="select2"
                    :select2Settings="select2Settings"
                    placeholder="Выберите"
                    v-model="editDocModel.documentTypeId"
                    :select2Options="docTypes"
                  />
                </div>
                <div class="form__body-inputs-row form__body-inputs-row_long-input">
                  <label>Категория</label>
                  <ComponentInput
                    type="select2"
                    :select2Settings="select2Settings"
                    placeholder="Выберите"
                    v-model="editDocModel.mpkEventId"
                    :select2Options="events"
                  />
                </div>
                <!-- input -->
                <div class="form__body-inputs-row">
                  <label for="name">Теги</label>
                  <div class="input-block">
                    <ComponentInput
                      placeholder="Выберите теги..."
                      :select2Options="tags"
                      v-model="editDocModel.tagIds"
                      type="select2"
                      :select2Settings="select2SettingsMultiple"
                      :multiple="true"
                    />
                  </div>
                </div>
                <!-- input -->
                <div class="form__body-inputs-row">
                  <label for="docDescription">Описание</label>
                  <div class="input-block">
                    <ComponentInput
                      placeholder="Введите название"
                      id="docDescription"
                      v-model="editDocModel.description"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- btns footer -->
            <div class="form__body-btns footer">
              <!-- 3x btns -->
              <ButtonStock
                btnType="whiteGreenBordered"
                title="Применить"
                @click="saveDoc(false)"
                v-if="editDocModel.id == 0"
              />
              <ButtonStock btnType="whiteGreenBordered" title="Сохранить" @click="saveDoc(true)" />
              <ButtonStock btnType="whiteGreenBordered" title="Отмена" @click="dialogAddEdit = false" />
            </div>
          </tab>
          <tab name="История" :selected="false" :disabled="editDocModel.id == 0">
            <div class="table-container mb-16 js-table-arrow">
              <table class="stockTable">
                <thead>
                  <tr>
                    <th>Тип операции</th>
                    <th>Дата</th>
                    <th>Пользователь</th>
                  </tr>
                </thead>
                <tbody v-if="historyItems?.length > 0 && !historyLoading">
                  <tr v-for="item in historyItems" :key="item.id">
                    <td>{{ item.operationType?.name }}</td>
                    <td>{{ $momentFormat(item.date, 'DD MMMM YYYY HH:mm') }}</td>
                    <td>{{ item.user?.fullName }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="historyLoading">
                  <tr>
                    <td class="text-center" colspan="3">Загрузка...</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="3">Нет данных.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- btns footer -->
            <div class="form__body-btns footer">
              <ButtonStock btnType="whiteGreenBordered" title="Закрыть" @click="dialogAddEdit = false" />
            </div>
          </tab>
        </tabs>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
  // Additional
  import ComponentInput from '@/common/components/ComponentInput';
  import FileUpload from '@/common/components/FileUpload';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import TreeSelectSearch from '@/common/components/TreeSelectSearch.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import { findItemNested } from '@/common/utils';
  import UTILS from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API_EVENT from '@/modules/mpk/api/event';
  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/index';
  import TableComponent from '../components/LibraryTableComponent.vue';

  export default {
    name: 'Library',
    components: {
      ComponentInput,
      ButtonStock,
      ModalComponent,
      IconComponent,
      TreeSelectSearch,
      FileUpload,
      TableComponent,
      tabs,
      tab,
    },
    data() {
      return {
        docTypes: [],
        countries: [],
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        select2Settings: Constants.select2Settings,
        dialogAddEdit: false,
        selectedIdTreeItem: null,
        editFolderModel: {
          id: 0,
          title: 'Создать папку',
          name: 'Новая папка',
          isCatalog: false,
          countryIds: [],
        },
        editDocModel: {
          id: 0,
          libraryFolderId: null,
          title: 'Создать документ',
          name: 'Новый документ',
          documentTypeId: null,
          description: '',
          fileId: null,
          file: {},
          tagIds: [],
          mpkEventId: null,
        },
        editFolderShowModal: false,
        tags: [],
        libraryTree: [],
        searchTextTree: '',
        firedItem: null,
        events: [],
        searchDocumentText: '',
        isTextSearch: false,
        searchDocumentType: null,
        searchLock: false,
        historyLoading: false,
        historyItems: [],
        selectedDocTabIndex: 0,
        selectedFolderTabIndex: 0,
      };
    },
    created() {
      this.loadPage();
      this.loadDictionaries();
    },
    computed: {
      selectedItem() {
        return findItemNested(this.libraryTree, this.selectedIdTreeItem, 'nodes');
      },
      searchItem() {
        if (this.searchDocumentText || this.searchDocumentType) {
          return { documents: this.findDocuments(this.libraryTree, this.searchDocumentText, this.searchDocumentType) };
        } else return null;
      },
      showButtons() {
        let result = this.searchItem;
        if (result) {
          return this.isSingleFolder(result.documents);
        } else return false;
      },
    },
    methods: {
      isSingleFolder(arr) {
        var first = arr[0];
        return (
          arr.length > 0 &&
          arr.every(function (element) {
            return element.libraryFolderId === first.libraryFolderId;
          })
        );
      },
      findDocuments(arr, textToSearch, typeToSearch) {
        this.isTextSearch = true;
        this.showButtons = false;
        let result = [];
        arr.forEach((x) => {
          x.documents.forEach((d) => {
            if (
              (textToSearch && d.name && d.name.toLowerCase().includes(textToSearch.toLowerCase())) ||
              (typeToSearch && d.documentTypeId == typeToSearch)
            )
              result.push(d);
          });
          result = result.concat(this.findDocuments(x.nodes, textToSearch, typeToSearch));
        });
        return result;
      },
      loadPage() {
        API.getLibrary().then((res) => {
          this.libraryTree = res.data;
        });
      },
      loadDictionaries() {
        var request = { pageNumber: 1, pageSize: 0 };
        UTILS.loadSelectOptions('countryList', this.countries, true);
        API_NSI.search('NsiDocumentType', request).then((response) => {
          if (response.data) {
            this.docTypes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_NSI.search('NsiDocumentTag', request).then((response) => {
          if (response.data) {
            this.tags = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_EVENT.search(request).then((response) => {
          if (response.data) {
            this.events = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      getIdTreeItem(id) {
        this.searchDocumentText = '';
        this.searchDocumentType = null;
        this.$nextTick(() => {
          this.selectedIdTreeItem = id;
        });
      },
      editFolder(id) {
        this.selectedFolderTabIndex = 0;
        this.$nextTick(() => {
          this.editFolderModel.id = id;
          if (id > 0) {
            this.getHistory(id, true);
            this.editFolderModel.name = this.selectedItem.label;
            this.editFolderModel.isCatalog = this.selectedItem.isCatalog;
            this.editFolderModel.countryIds = this.selectedItem.countryIds;
            this.editFolderModel.title = 'Редактирование папки';
            this.editFolderModel.parentFolderId = this.selectedItem.parentFolderId;
          } else {
            this.editFolderModel.name = 'Новая папка';
            this.editFolderModel.isCatalog = false;
            this.editFolderModel.countryIds = [];
            this.editFolderModel.title = 'Создание папки';
            this.editFolderModel.parentFolderId = this.selectedItem ? this.selectedItem.parentFolderId : null;
          }
          this.editFolderShowModal = true;
        });
      },
      downloadFolder() {
        if (this.selectedItem) {
          API.downloadZippedFiles(this.selectedItem.id).then((response) => {
            const blob = new Blob([response.data], { type: response.data.type });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.selectedItem.name}.zip`;
            link.click();
            URL.revokeObjectURL(link.href);
          });
        }
      },
      deleteFolder() {
        if (this.selectedItem) {
          var options = Object.assign({}, Constants.confirmOptions.delete);
          if (
            (this.selectedItem.nodes && this.selectedItem.nodes.length > 0) ||
            (this.selectedItem.documents && this.selectedItem.documents.length > 0)
          ) {
            options.text = 'Папка непуста. Удалить папку со всем ее содержимым - документами и подпапками?';
          }
          Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
            if (res.isConfirmed) {
              API.deleteFolder(this.selectedItem.id)
                .then(() => {
                  Constants.alert.fire(
                    'Удаление папки',
                    `Папка "${this.selectedItem.name}" успешно удалена`,
                    'success',
                  );
                  this.selectedItem = null;
                  this.loadPage();
                })
                .catch(() => {
                  Constants.alert.fire('Удаление папки', Constants.commonError, 'error');
                });
            }
          });
        }
      },
      downloadDoc(item) {
        var url = UTILS.getFileUrl(item.fileId);
        this.downloadFile(url, item.file.originalName).then(() => {
          API.logFileDownload(item.id);
        });
      },
      async downloadFile(url, originalName) {
        return UTILS.downloadFile(url, originalName);
      },
      copyLink(item) {
        var url = UTILS.getFileUrlGuid(item.file.guid);
        try {
          navigator.clipboard.writeText(url).then(() => {
            Constants.alert.fire(
              'Копирование ссылки на файл',
              `Ссылка на файл "${item.file.originalName}" скопирована в буфер обмена`,
              'success',
            );
          });
        } catch ($e) {
          Constants.alert.fire('Копирование ссылки на файл', 'Не удалось скопировать ссылку на файл', 'error');
        }
      },
      deleteDoc(doc) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.deleteDocument(doc.id)
              .then(() => {
                Constants.alert.fire('Удаление документа', `Документ "${doc.name}" успешно удален`, 'success');
                this.loadPage();
              })
              .catch(() => {
                Constants.alert.fire('Удаление документа', Constants.commonError, 'error');
              });
          }
        });
      },
      saveFolder() {
        let parent_id = this.selectedItem ? this.selectedItem.id : null;
        let newFolder = {
          id: this.editFolderModel.id,
          label: this.editFolderModel.name,
          isCatalog: this.editFolderModel.isCatalog,
          countryIds: this.editFolderModel.countryIds,
          nodes: [],
          parentFolderId: this.editFolderModel.id == parent_id ? this.editFolderModel.parentFolderId : parent_id,
        };
        API.updateFolder(newFolder)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
              this.editFolderShowModal = false;
              this.loadPage();
            } else {
              Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      saveDoc(closeDialog) {
        let newDoc = {
          id: this.editDocModel.id,
          name: this.editDocModel.name,
          description: this.editDocModel.description,
          documentTypeId: this.editDocModel.documentTypeId,
          libraryFolderId: this.editDocModel.libraryFolderId,
          file: this.editDocModel.file,
          fileId: this.editDocModel.fileId,
          tagIds: this.editDocModel.tagIds,
          mpkEventId: this.editDocModel.mpkEventId,
        };
        API.updateDocument(newDoc)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              Constants.alert.fire('Сохранение', `Документ "${this.editDocModel.name}" успешно сохранен`, 'success');
              if (closeDialog) {
                this.dialogAddEdit = false;
                this.loadPage();
              } else {
                if (this.editDocModel.id == 0) {
                  this.editDocModel = {
                    id: 0,
                    libraryFolderId: this.selectedItem.id,
                  };
                }
              }
            } else {
              Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      catchFiredItem(data) {
        this.firedItem = data.firedItem;
      },
      removeDocFile() {
        this.editDocModel.file = {};
        this.editDocModel.fileId = null;
      },
      fileDocUploaded(e) {
        this.editDocModel.file = e.data;
        this.editDocModel.fileId = e.data ? e.data.id : null;
      },
      editDoc(data) {
        this.selectedDocTabIndex = 0;
        this.$nextTick(() => {
          if (data) {
            this.getHistory(data.id, false);
            this.editDocModel = data;
          } else {
            this.editDocModel = {};
            this.editDocModel.id = 0;
            this.editDocModel.libraryFolderId = this.selectedItem.id;
            this.editDocModel.mpkEventId = null;
          }
          this.dialogAddEdit = true;
        });
      },
      getHistory(id, isFolder) {
        this.historyLoading = true;
        this.historyItems = [];

        let handler = (response) => {
          this.historyItems = response.data;
          this.historyLoading = false;
        };
        let errorHandler = (error) => {
          console.error(error);
          this.historyLoading = false;
        };

        if (isFolder) API.getFolderHistory(id).then(handler).catch(errorHandler);
        else API.getDocumentHistory(id).then(handler).catch(errorHandler);
      },
    },
    watch: {
      searchDocumentText: {
        handler: function (val) {
          if (!this.searchLock) {
            this.searchLock = true;
            this.searchTextTree = '';
            if (val) {
              this.searchDocumentType = null;
              if (this.showButtons) {
                this.selectedIdTreeItem = this.searchItem.documents[0].libraryFolderId;
                this.searchTextTree = findItemNested(this.libraryTree, this.selectedIdTreeItem, 'nodes')?.label;
              } else this.selectedIdTreeItem = null;
            } else {
              this.selectedIdTreeItem = null;
              this.searchItem = null;
            }
            this.$nextTick(() => {
              this.searchLock = false;
            });
          }
        },
      },
      searchDocumentType: {
        handler: function (val) {
          if (!this.searchLock) {
            this.searchLock = true;
            this.searchTextTree = '';
            if (val) {
              this.searchDocumentText = null;
              if (this.showButtons) {
                this.selectedIdTreeItem = this.searchItem.documents[0].libraryFolderId;
                this.searchTextTree = findItemNested(this.libraryTree, this.selectedIdTreeItem, 'nodes')?.label;
              } else this.selectedIdTreeItem = null;
            } else {
              this.selectedIdTreeItem = null;
              this.searchItem = null;
            }
            this.$nextTick(() => {
              this.searchLock = false;
            });
          }
        },
      },
      showButtons: {
        handler: function (val) {
          if (val && this.searchItem && this.searchItem.documents.length > 0) {
            this.isTextSearch = true;
            this.selectedIdTreeItem = this.searchItem.documents[0].libraryFolderId;
          }
        },
      },
      selectedIdTreeItem: {
        handler: function (val) {
          if (val && !this.isTextSearch) {
            this.searchDocumentText = '';
            this.searchDocumentType = null;
          }
          if (this.isTextSearch) this.isTextSearch = false;
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../style/library';

  .form__body {
    &-tree {
      margin-bottom: 20px;
      .card-info {
        padding: 16px;
        height: 346px;
        width: 100%;
      }
    }
    &-btns {
      display: flex;
      flex-wrap: wrap;
      &.header {
        margin-bottom: 20px;
      }
      &.footer,
      &.header {
        justify-content: flex-end;
        button {
          &:not(:last-of-type) {
            margin-right: 30px;
          }
        }
      }
    }
    &-inputs {
      &-row::v-deep {
        display: flex;
        margin-bottom: 12px;
        &:last-of-type {
          margin-bottom: 12px;
        }
        label {
          min-width: 135px;
          max-width: 135px;
          width: 100%;
        }
        .input-block {
          max-width: 550px;
          width: 100%;
          input,
          textarea {
            width: 100%;
          }
          textarea {
            height: 165px;
          }
        }
        .file-upload-container {
          max-width: calc(100% - 135px);
          .inner-file {
            padding-right: 14px;
          }
          .label-input {
            display: inline-block;
            max-width: 100%;
          }
        }
      }
    }
  }
  .form__body-inputs-row_long-input::v-deep {
    .input-group-wrapper {
      max-width: calc(100% - 135px) !important;
    }
  }
  .form__body-tab-container::v-deep {
    .tabs_container {
      margin: 0px 0px 32px;
    }
  }

  .table-container {
    height: 30vh;
  }

  // Media
  @media (max-width: 1024px) {
    .library__container {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 767px) {
    .library__container-table-btns,
    .form__body-btns {
      flex-wrap: wrap;
      button {
        margin-bottom: 16px;
      }
    }
    .form__body-inputs-row {
      flex-wrap: wrap;
      .input-block {
        max-width: unset;
      }
    }
  }
  @media (max-width: 320px) {
    .library__container-table-btns,
    .form__body-btns {
      &.header {
        button:not(:last-of-type) {
          margin-right: 0px;
        }
      }
    }
  }
</style>
