<template>
  <smart-widget simple :padding="[0, 0]">
    <div class="widget-header">
      <!-- Кнопка опции виджета -->
      <button class="btn-settings" v-on:click="onGearFunc" v-if="showGear">
        <IconComponent name="settings" />
      </button>
      <!-- Название виджета -->
      <p class="widget-header__title">
        {{ title }}
      </p>
      <!-- Кнопка закрыть(свернуть) -->
      <button class="btn-closeCollapse" v-on:click="onCloseFunc" v-if="showClose">
        <IconComponent name="closeCross" />
      </button>
    </div>
    <!-- Внутренности виджета любые -->
    <div class="widget-inner">
      <slot />
    </div>
  </smart-widget>
</template>

<script>
  import IconComponent from '../../common/ui/IconComponent.vue';
  export default {
    name: 'WidgetStock',
    components: { IconComponent },
    props: {
      title: {
        default: ' ',
        type: String,
      },
      showGear: {
        type: Boolean,
        default: true,
      },
      showClose: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['onGear', 'onClose'],
    data() {
      return {};
    },
    methods: {
      onCloseFunc() {
        this.$emit('onClose');
      },
      onGearFunc() {
        this.$emit('onGear');
      },
    },
  };
</script>

<style lang="scss">
  // widgets style
  .widget-inner {
    overflow: auto;
  }
  .smartwidget {
    border: none;
    cursor: default;
    // filter: drop-shadow(0px 2px 4px rgba(117, 131, 142, 0.04)) drop-shadow(0px 8px 16px rgba(52, 60, 68, 0.1));
    &.widget-transparent {
      background: transparent !important;
      box-shadow: none !important;
      border: none !important;
      .widget-header {
        display: none;
      }
    }
  }
  .widget {
    &-header {
      display: flex;
      background-color: #f7f7f7;
      padding: 32px 14px;
      cursor: default;
      // filter: drop-shadow(0px 2px 4px rgba(117, 131, 142, 0.04)) drop-shadow(0px 8px 16px rgba(52, 60, 68, 0.1));
      &.widget-transparent {
        background: transparent;
        box-shadow: none !important;
        border: none;

        .widget-header {
          display: none;
        }
      }
    }
  }
  .widget {
    &-box {
      box-shadow: 0 0 10px #e9e9e9;
    }
    &-header {
      display: flex;
      background-color: #f7f7f7;
      padding: 32px 14px;
      cursor: default;
      &__title {
        font-weight: 300;
        font-size: 18px;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        color: #343c44;
        margin-bottom: 0px;
      }
      .btn-settings {
        margin-right: 12px;
      }
      .btn-closeCollapse {
        margin-left: auto;
      }
    }
  }

  // Для виджета выбора стран
  // .widget-custom-in обертка для виджетов с паддингами(не таблицы)
  .widget-custom-in {
    display: flex;
    width: 100%;
    padding: 24px 22px;
    &.contentBetween {
      justify-content: space-between;
    }
    &.news-component {
      padding: 12px 14px;
      flex-direction: column;
    }
  }
</style>
