<template>
  <div class="w-100">
    <h4>{{ title }}</h4>
    <template v-if="content != null && content.length > 0">
      <p v-for="(sphere, index) in content" :key="index" class="top-item">
        <span class="top-item__percent">{{ sphere?.value }}%</span>
        {{ sphere?.key }}
      </p>
    </template>
    <p class="mt-24 mb-24" v-else>{{ emptyMessage }}</p>
  </div>
</template>

<script>
  import { DATA_EMPTY_MESSAGE } from '../../utils/constants';
  export default {
    name: 'TopCountryStatistic',
    props: {
      title: {
        type: String,
        default: '',
      },
      content: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      emptyMessage: DATA_EMPTY_MESSAGE,
    }),
  };
</script>

<style lang="scss" scoped>
  h4 {
    font: $fira-16-M;
    color: $base;
    margin-bottom: 10px;
  }
  .top-item {
    display: inline-block;
    padding: 10px;
    border: 1px solid #d6e4ec;
    border-radius: 4px;
    font: $fira-12;
    margin: 0 10px 10px 0;
    &__percent {
      font-weight: 700;
      color: #01a39d;
    }
  }
</style>
