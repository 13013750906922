import { NAVIGATOR_SCENARIOS_ACTIONS_NAME, NAVIGATOR_SCENARIOS_MUTATIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export default {
  async fetchScenarios(context, params) {
    context.state.isLoading = true;
    return await request.call(
      this,
      context,
      {
        url: links.navigator.scenarios.search,
        mutate: NAVIGATOR_SCENARIOS_MUTATIONS_NAME.setScenarios,
        name: NAVIGATOR_SCENARIOS_ACTIONS_NAME.fetchScenarios,
      },
      params,
    );
  },
  async fetchMoreScenarios(context, params) {
    context.state.isLoadingMore = true;

    return await request.call(
      this,
      context,
      {
        url: links.navigator.scenarios.search,
        mutate: NAVIGATOR_SCENARIOS_MUTATIONS_NAME.addScenarios,
        name: NAVIGATOR_SCENARIOS_ACTIONS_NAME.fetchMoreScenarios,
      },
      params,
    );
  },
};
