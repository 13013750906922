<template>
  <ShowMoreButton
    :currentPage="currentPage"
    :pageCount="pageCount"
    :loadingMore="loadingMore"
    :isShowMoreVisible="isShowMoreVisible"
    @onClick="showMore"
    >Показать еще
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M8.5 3.33334V12.6667"
        stroke="#01A39D"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M3.83333 8H13.1667"
        stroke="#01A39D"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  </ShowMoreButton>

  <nav class="paginationRedesigned__container">
    <div class="paginationRedesigned__backBlock" :class="{ disabled: currentPage == 1 }">
      <p @click="goToPrev(1)" class="fira-sans">&lt;&lt;</p>
      <p @click="goToPrev()" class="fira-sans">&lt;</p>
    </div>
    <ul class="paginationRedesigned__listPages">
      <li :class="{ chosen: button.number === currentPage }" :key="button.number" v-for="button in buttons">
        <p v-if="button.number" class="fira-sans" type="button" @click="goToPage(button.number)">
          {{ button.number }}
        </p>
        <p v-if="button.isSpacer">...</p>
      </li>
    </ul>
    <div class="paginationRedesigned__nextBlock" :class="{ disabled: currentPage == pageCount }">
      <p @click="goToNext()" class="fira-sans">></p>
      <p @click="goToNext(pageCount)" class="fira-sans">>></p>
    </div>
  </nav>
</template>

<script>
  import ShowMoreButton from './ShowMoreButton.vue';

  function makeButtons(currentPage, pageCount) {
    let maxButtonCount = 10;
    if (window.innerWidth < 767) {
      maxButtonCount = 6;
    }
    const buttons = [];
    const middle = Math.ceil(maxButtonCount / 2);
    if (pageCount <= maxButtonCount) {
      // 1 2 3 4 5 6 7
      for (let i = 1; i <= pageCount; i++) {
        buttons.push({ number: i });
      }
    } else if (currentPage <= middle) {
      // 1 2 3 4 5 ... 8
      const to = maxButtonCount - 2;
      for (let i = 1; i <= to; i++) {
        buttons.push({ number: i });
      }
      buttons.push({ isSpacer: true });
      buttons.push({ number: pageCount });
    } else if (currentPage >= pageCount - (maxButtonCount - middle)) {
      // 1 ... 4 5 6 7 8
      buttons.push({ number: 1 });
      buttons.push({ isSpacer: true });
      const from = pageCount - maxButtonCount + 3;
      for (let i = from; i <= pageCount; i++) {
        buttons.push({ number: i });
      }
    } else {
      // 1 ... 4 5 6 ... 9
      buttons.push({ number: 1 });
      buttons.push({ isSpacer: true });
      const from = currentPage - middle + 3;
      const to = from + (maxButtonCount - 4);
      for (let i = from; i <= to; i++) {
        buttons.push({ number: i });
      }
      buttons.push({ isSpacer: true });
      buttons.push({ number: pageCount });
    }
    return buttons;
  }

  export default {
    name: 'PaginationRedesigned',
    components: { ShowMoreButton },
    emits: ['more', 'change'],
    props: {
      currentPage: Number,
      pageCount: Number,
      loadingMore: {
        type: Boolean,
        default: false,
      },
      isShowMoreVisible: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        buttons: [],
      };
    },
    methods: {
      goToPage(pageNum) {
        if (pageNum !== this.currentPage) {
          this.$emit('change', pageNum);
        }
      },
      showMore() {
        this.$emit('more', this.currentPage + 1);
      },
      goToPrev(value) {
        if (this.currentPage > 1) {
          value ? this.$emit('change', value) : this.$emit('change', this.currentPage - 1);
        }
      },
      goToNext(value) {
        if (this.currentPage < this.pageCount) {
          value ? this.$emit('change', value) : this.$emit('change', this.currentPage + 1);
        }
      },
    },
    watch: {
      currentPage: function (newValue /*, oldValue*/) {
        this.buttons = makeButtons(newValue, this.pageCount);
      },
      pageCount: function (newValue /*, oldValue*/) {
        this.buttons = makeButtons(this.currentPage, newValue);
      },
    },
    created() {
      this.buttons = makeButtons(this.currentPage, this.pageCount);
    },
  };
</script>

<style lang="scss" scoped>
  .page-link {
    color: $gray-m-light !important;
  }
  // Пагинация
  .paginationRedesigned {
    &__container,
    &__listPages,
    &__backBlock,
    &__nextBlock {
      display: flex;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        min-width: 24px;
        font-size: 12px;
        line-height: 24px;
        color: $blue;
        margin-bottom: 0px;
        cursor: pointer;
        padding: 0 2px;
      }
    }
    &__container {
      margin-top: 40px;
      justify-content: center;
    }
    &__listPages,
    &__backBlock,
    &__nextBlock {
      p {
        background: none;

        border-radius: 2px;
        border: 1px solid $blue;
      }
    }
    &__backBlock,
    &__nextBlock {
      p {
        margin-right: 3px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
    &__listPages {
      padding: 0px 16px;
      li {
        margin-right: 3px;
        p {
          border-radius: 2px;
        }
        &.chosen {
          p {
            color: $white;
            background: $blue;
          }
        }
      }
    }
    &__backBlock.disabled,
    &__nextBlock.disabled {
      p {
        border: 1px solid $light-grey-5;
        color: $light-grey-5;
        cursor: default;
      }
    }
  }
  @media (max-width: $laptop) {
    .paginationRedesigned__container p,
    .paginationRedesigned__listPages p,
    .paginationRedesigned__backBlock p,
    .paginationRedesigned__nextBlock p {
      max-height: 24px;
      max-width: 24px;
      min-height: 24px;
      min-width: 24px;
    }
    .paginationRedesigned__listPages {
      padding: 0px 8px;
    }
  }
</style>
