<template>
  <div class="container-page">
    <div class="monitoring-container">
      <!-- title -->
      <p class="title-inner">Оценка результативности исполнения по стране</p>
      <!-- filter analysis -->
      <div class="monitoring-content__header">
        <div class="filter greenBlock">
          <div class="filter__block">
            <p class="label">Страна</p>
            <div class="input-container">
              <Multiselect
                placeholder="Страна"
                mode="multiple"
                :hideSelected="false"
                :close-on-select="true"
                :multiple-label="(values) => `${values.length} выбрано`"
                :options="countryList"
                v-model="filter.countryIds"
              />
            </div>
          </div>

          <div class="filter__block">
            <p class="label">Выберите период</p>
            <div class="input-container flex">
              <FilterInput type="date" v-model="filter.createdFrom" v-on:valueChangedDebounced="changeFilter" />
              <FilterInput type="date" v-model="filter.createdTo" v-on:valueChangedDebounced="changeFilter" />
            </div>
          </div>

          <div class="filter__block">
            <p class="label">Мероприятия МПК</p>
            <div class="input-container">
              <Multiselect
                placeholder="Мероприятие"
                mode="multiple"
                :hideSelected="false"
                :close-on-select="true"
                :multiple-label="(values) => `${values.length} выбрано`"
                :options="eventList"
                v-model="filter.eventIds"
              />
            </div>
          </div>
        </div>
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div class="widget-box">
        <div class="widget-header">
          <!-- Название виджета -->
          <p class="widget-header__title">Оценка результативности исполнения по стране</p>
        </div>
        <!-- Внутренности виджета любые -->
        <div class="widget-inner">
          <div class="monitoring-content__header">
            <div class="analysis">
              <p>Всего поручений: {{ totalData.totalCount }}</p>
              <p>Выполнено: {{ totalData.done }}</p>
              <p>В процессе: {{ totalData.inWork }}</p>
              <p>Просрочено: {{ totalData.overdue }}</p>
            </div>
          </div>

          <!-- charts -->
          <div class="monitoring-content__chart">
            <!-- charts -->
            <ChartBarMonitoring :seriesProp="dataMonitoring" :nn="nn" />
          </div>

          <!-- btns & indicators -->
          <div class="monitoring-content__footer">
            <div class="indicators">
              <p class="green">
                <span class="indicators-icon"></span>
                <span>Выполнено</span>
              </p>
              <p class="blue">
                <span class="indicators-icon"></span>
                <span>В работе</span>
              </p>
              <p class="red">
                <span class="indicators-icon"></span>
                <span>Просрочено</span>
              </p>
            </div>
            <ButtonStock title="Подробная информация" @click="goto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  // Additional
  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ChartBarMonitoring from '@/components/ChartComponents/ChartBarMonitoring';

  import API from '../api/assignment';

  export default {
    name: 'EapAssignmentMonitoring',
    components: {
      ButtonStock,
      ChartBarMonitoring,
      FilterInput,
      Multiselect,
      DefaultFilter,
    },
    watch: {
      'filter.countryIds': function (/*newValue*/) {
        this.changeFilter();
      },
      'filter.eventIds': function (/*newValue*/) {
        this.changeFilter();
      },
    },
    mixins: [security, filtermanager],
    data() {
      return {
        filter: {
          countryIds: [],
          eventIds: [],
          createdFrom: null,
          createdTo: null,
        },
        nn: 0,

        countryList: [],
        eventList: [],
        totalData: {
          totalCount: 0,
        },
        dataMonitoring: [],
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.EapAssignmentAccess);

      Utils.searchNsi('countryList', {}).then((response) => {
        if (response.data && response.data.length) {
          response.data.forEach((i) => this.countryList.push({ value: i.id, label: i.text }));
        }
      });

      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadEvents();
        this.loadPage();
      });
    },
    methods: {
      changeFilter() {
        this.saveFilter(this.filter);
        this.loadPage();
      },
      loadEvents() {
        API.getEventForList().then((response) => {
          this.eventList = [];
          if (response.data && response.data.length) {
            response.data
              .filter((x) => x.eventType == 0)
              .forEach((i) => this.eventList.push({ value: i.eventId, label: i.eventName }));
          }
        });
      },
      loadPage() {
        var params1 = Object.assign({}, this.filter);
        API.getTotal(params1).then((response) => {
          this.dataMonitoring = [];
          this.totalData = {};
          if (response.data && response.data.length > 0) {
            this.totalData = response.data[0];
            response.data.slice(1).forEach((el) => {
              this.dataMonitoring.push({
                name: el.title,
                data: [el.done, el.inWork, el.overdue],
              });
            });
            this.nn++;
          }
        });
      },
      goto() {
        this.$router.push({ name: 'EapAssignments' });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss">
  .monitoring {
    &-content {
      &__header {
        .filter {
          &.greenBlock {
            .filter__block {
              .input-container {
                .multiselect {
                  background: #01a39d;
                  border: none;
                  input {
                    background-color: #01a39d;
                  }
                }
                .select2 .select2-container--default .select2-selection--single {
                  background-color: #01a39d;
                  border: none;
                }
                .select2 .select2-container--default .select2-selection--single .select2-selection__placeholder,
                .multiselect-placeholder,
                .select2 .select2-container .select2-selection__rendered,
                .multiselect-multiple-label,
                .multiselect-placeholder,
                .multiselect-single-label {
                  font-weight: 400;
                  color: white;
                }
                .select2 .select2-container .select2-selection__rendered {
                  line-height: 44px;
                }
                .select2 .select2-container--default .select2-selection--single .select2-selection__arrow b,
                .multiselect-caret {
                  border-color: white !important;
                }
                .multiselect-clear-icon {
                  background-color: white;
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .monitoring {
    &-container {
      &:not(:last-of-type) {
        margin-bottom: 130px;
      }
      .title-inner {
        font-weight: 500;
      }
    }
    &-content {
      &__header {
        .analysis {
          margin-bottom: 0px;
          padding: 12px 18px;
        }
      }
      &__footer {
        button {
          bottom: 16px;
          right: 12px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .monitoring-content__footer button {
      position: initial;
      margin-bottom: 16px;
    }
  }
</style>
