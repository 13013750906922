<template>
  <div class="calendar-component">
    <div class="calendar-component__header">
      <selectComponent placeholder="Выберите событие" :options="events" />
      <ButtonStock title="Перейти" />
    </div>
    <div class="calendar-component__body">
      <!-- calendar head -->
      <div v-show="changeView" class="calendar-head">
        <!-- Селектор перелистывает недели месяца и показывает неободимую таблицу заполненную -->
        <!-- Скрывается при переключении вида -->
        <div v-show="changeView" class="calendar-selector">
          <div class="yearsMonth">
            <span @click="updateMonth('prev')" class="arrow prev"></span>
            <span> {{ todayMonth }} {{ todayYear }} </span>
            <span @click="updateMonth('next')" class="arrow next"></span>
          </div>
          <div class="weeks">
            <span @click="updateCounterWeeks('prev')" class="arrow prev"></span>
            <span>{{ counterWeeks }}-я неделя</span>
            <span @click="updateCounterWeeks('next')" class="arrow next"></span>
          </div>
        </div>
        <!-- Иконки сменяются при смене вида -->
        <div class="calendar-icons">
          <div v-show="changeView">
            <IconComponent @click="changeView = !changeView" name="event-note" />
          </div>
        </div>
      </div>

      <!-- Calendar second view -->
      <div ref="outsideCalendar" v-show="!changeView" class="calendar-plug">
        <div v-show="!changeView">
          <IconComponent @click="changeView = !changeView" name="tableOne" />
        </div>
        <v-calendar class="v-calendar__activity" :attributes="attributes">
          <template #day-popover="{ dayTitle, attributes }">
            <IconComponent @click="doClickOutsideCalendar" name="clear_cross" />
            <div class="day-popover__item" v-for="{ key, customData } in attributes" :key="key">
              <div class="day-popover__item-body">
                <!-- Тип мероприятия -->
                <p class="type">{{ customData.type }}</p>
                <!-- Дата мероприятия -->
                <p class="date">{{ dayTitle }}</p>
                <!-- Проверка наличия времени -->
                <p class="time-container">
                  <span class="time-block">
                    <span v-if="customData.time != ''" class="time">{{ customData.time }}</span>
                    <span>
                      <span>Регистрация</span>
                      <span v-if="customData.registration == 'yes'"> открыта</span>
                      <span v-if="customData.registration == 'no'"> закрыта</span>
                    </span>
                  </span>
                </p>
                <!-- Текст мероприятия -->
                <p class="about">{{ customData.description }}</p>
              </div>
            </div>
          </template>
        </v-calendar>
      </div>

      <!-- Calenar first view -->
      <div v-show="changeView" class="calendar-table table-component">
        <div class="table-vidjet">
          <table>
            <thead>
              <tr>
                <th>Содержание</th>
                <th>Дата</th>
                <th>Место</th>
                <th>Место</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tableArray" :key="item.key">
                <td>{{ item.title }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.countryIn }}</td>
                <td>{{ item.countryOut }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="changeView" class="calendar-component__footer">
      <ButtonStock title="Все мероприятия" />
    </div>
  </div>
</template>

<script>
  import selectComponent from '@/common/components/selectComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    components: {
      ButtonStock,
      selectComponent,
      IconComponent,
    },
    props: {
      events: {
        Type: Object,
      },
    },
    data() {
      const todos = [
        {
          type: 'Заседание МПК',
          description: 'Задуматься о жизни раз',
          isComplete: false,
          registration: 'no',
          time: '15:00',
          dates: [new Date(2022, 3, 14)],
        },
        {
          type: 'Засесть',
          description: 'Задуматься о жизни два',
          isComplete: false,
          registration: 'yes',
          time: '',
          dates: [new Date(2022, 3, 12)],
        },
        {
          type: 'Заседание МПК',
          description: 'Задуматься о жизни три',
          isComplete: false,
          registration: 'yes',
          time: '12:00',
          dates: [new Date(2022, 3, 12)],
        },
        {
          type: 'Двусторонняя международная встреча',
          description: 'Задуматься о жизни четыре',
          isComplete: false,
          registration: 'no',
          time: '13:30',
          dates: [new Date(2022, 3, 14)],
        },
      ];
      return {
        changeView: true,
        // То что отрисованно будет
        tableArray: [],
        // Данные с бека
        getEventsData: [
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-01',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-02',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Великобритания',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-03',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Франция',
          },
          { title: 'Деловой совет', date: '2022-04-04', countryIn: 'Москва', time: '14:00', countryOut: 'Черногория' },
          { title: 'Форум', date: '2022-04-05', countryIn: 'Москва', time: '14:00', countryOut: 'Великобритания' },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-06',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Франция',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-07',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-08',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-09',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-10',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Великобритания',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-11',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Франция',
          },
          { title: 'Деловой совет', date: '2022-04-12', countryIn: 'Москва', time: '14:00', countryOut: 'Черногория' },
          { title: 'Форум', date: '2022-04-13', countryIn: 'Москва', time: '14:00', countryOut: 'Великобритания' },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-14',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Франция',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-15',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-16',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-17',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-18',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Великобритания',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-19',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Франция',
          },
          { title: 'Деловой совет', date: '2022-04-22', countryIn: 'Москва', time: '14:00', countryOut: 'Черногория' },
          { title: 'Форум', date: '2022-04-23', countryIn: 'Москва', time: '14:00', countryOut: 'Великобритания' },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-24',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Франция',
          },
          {
            title: 'Рабочая группа по инвестициям',
            date: '2022-04-28',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
          {
            title: 'МПК по торгово-экономическому',
            date: '2022-04-30',
            time: '14:00',
            countryIn: 'Москва',
            countryOut: 'Черногория',
          },
        ],
        todos,
        counterWeeks: 1,
        counterMonth: 0,
        counterYear: 0,
        todayYear: null,
        todayMonth: null,
        month: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
      };
    },
    methods: {
      doClickOutsideCalendar() {
        this.$refs.outsideCalendar.click();
      },

      updateCalendarTable(week) {
        let totalDaysInWeek = 7;
        // eslint-disable-next-line no-unused-vars
        let rangeFrom = 1;
        // eslint-disable-next-line no-unused-vars
        let rangeTo = 0;
        let year = this.todayYear;
        // let month = this.counterMonth;
        let month = this.counterMonth + 1;
        switch (week) {
          case 1:
            rangeTo = totalDaysInWeek * 1;
            // eslint-disable-next-line no-case-declarations
            let filteredArray = this.getEventsData.filter((item) => {
              let dateFrom = new Date(`${year}-${month}-${rangeFrom}`);
              let dateTo = new Date(`${year}-${month}-${rangeTo} 23:59`);
              let currentDate = new Date(item.date);
              return currentDate >= dateFrom && currentDate <= dateTo;
            });
            console.log(filteredArray);
            this.tableArray = filteredArray;
            break;

          case 2:
            rangeFrom = totalDaysInWeek * 1 + 1;
            rangeTo = totalDaysInWeek * 2;
            filteredArray = this.getEventsData.filter((item) => {
              let dateFrom = new Date(`${year}-${month}-${rangeFrom}`);
              let dateTo = new Date(`${year}-${month}-${rangeTo} 23:59`);
              let currentDate = new Date(item.date);
              return currentDate >= dateFrom && currentDate <= dateTo;
            });
            console.log(filteredArray);
            this.tableArray = filteredArray;
            break;

          case 3:
            rangeFrom = totalDaysInWeek * 2 + 1;
            rangeTo = totalDaysInWeek * 3;
            filteredArray = this.getEventsData.filter((item) => {
              let dateFrom = new Date(`${year}-${month}-${rangeFrom}`);
              let dateTo = new Date(`${year}-${month}-${rangeTo} 23:59`);
              let currentDate = new Date(item.date);
              return currentDate >= dateFrom && currentDate <= dateTo;
            });
            console.log(filteredArray);
            this.tableArray = filteredArray;
            break;

          case 4:
            rangeFrom = totalDaysInWeek * 3 + 1;
            rangeTo = 31;
            filteredArray = this.getEventsData.filter((item) => {
              let dateFrom = new Date(`${year}-${month}-${rangeFrom}`);
              let dateTo = new Date(`${year}-${month}-${rangeTo} 23:59`);
              let currentDate = new Date(item.date);
              return currentDate >= dateFrom && currentDate <= dateTo;
            });
            console.log(filteredArray);
            this.tableArray = filteredArray;
            break;
        }
      },

      updateCounterWeeks(move) {
        let totalWeeks = 4,
          minWeeks = 1;

        switch (move) {
          case 'prev':
            if (this.counterWeeks != minWeeks) {
              this.counterWeeks--;
              this.updateCalendarTable(this.counterWeeks);
            }
            break;

          case 'next':
            if (this.counterWeeks != totalWeeks) {
              this.counterWeeks++;
              this.updateCalendarTable(this.counterWeeks);
            }
            break;
        }
      },

      updateMonth(move) {
        switch (move) {
          case 'prev':
            if (this.counterMonth < 0 || this.counterMonth == 0) {
              this.counterYear--;
              this.todayYear = this.counterYear;
              this.counterMonth = 11;
              this.todayMonth = this.month[this.counterMonth];
              break;
            }
            this.counterMonth--;
            this.counterWeeks = 1;
            this.updateCalendarTable(this.counterWeeks);
            this.todayMonth = this.month[this.counterMonth];
            break;

          case 'next':
            if (this.counterMonth > 11 || this.counterMonth == 11) {
              this.counterYear++;
              this.todayYear = this.counterYear;
              this.counterMonth = 0;
              this.todayMonth = this.month[this.counterMonth];
              break;
            }
            this.counterMonth++;
            this.counterWeeks = 1;
            this.updateCalendarTable(this.counterWeeks);
            this.todayMonth = this.month[this.counterMonth];
            break;
        }
      },

      getMonthAndYear() {
        let date = new Date();
        this.counterMonth = date.getMonth();
        this.counterYear = date.getFullYear();
        this.todayMonth = this.month[date.getMonth()];
        this.todayYear = date.getFullYear();
      },
    },

    computed: {
      attributes() {
        return [
          // Attributes for todos
          ...this.todos.map((todo) => ({
            dates: todo.dates,
            dot: {
              color: todo.color,
              class: todo.isComplete ? 'opacity-75' : '',
            },
            popover: {
              label: todo.description,
              visibility: 'click',
              isInteractive: true,
            },
            customData: todo,
          })),
        ];
      },
    },

    created() {
      this.getMonthAndYear();
      this.updateCalendarTable(this.counterWeeks);
    },
  };
</script>

<style lang="scss" scoped>
  .calendar {
    &-component {
      &__header,
      &__footer {
        padding: 16px 20px;
      }
      &__header {
        display: flex;
        .select2::v-deep {
          // Контейнер ширина
          .select2-container {
            width: 100% !important;
            max-width: 498px !important;
          }
        }
      }
      &__footer {
        .btn-primary {
          width: 100%;
        }
      }
    }
    &-table {
      table {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #343c44;
        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
        thead {
          th {
            padding: 10px;
            border-bottom: 1px solid #dedede;
            &:nth-of-type(1) {
              width: 20%;
            }
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              width: 10%;
            }
          }
        }
        tbody {
          display: block;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 255px;
          tr {
            td {
              padding: 10px 0px 10px 10px;
              &:nth-of-type(1) {
                width: 20%;
              }
              &:nth-of-type(2),
              &:nth-of-type(3),
              &:nth-of-type(4) {
                width: 10%;
              }
            }
            &:nth-of-type(even) {
              background: #ededed;
            }
          }
        }
      }
    }
    &-head,
    &-selector,
    &-icons {
      display: flex;
    }
    &-head {
      padding: 12px 14px;
      background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      span {
        font-weight: 700;
        letter-spacing: 0.25px;
      }
    }
    &-selector {
      margin-left: auto;
      .yearsMonth,
      .weeks {
        display: flex;
        align-items: center;
      }
      .arrow {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 1px;
        border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
        cursor: pointer;
        &.prev {
          transform: rotate(45deg);
          margin-right: 20px;
        }
        &.next {
          transform: rotate(225deg);
          margin-left: 20px;
        }
      }
      .yearsMonth {
        margin-right: 22px;
      }
    }
    &-icons {
      margin-left: auto;
    }
    &-plug {
      .v-calendar__activity {
        width: 100%;
      }
    }
  }
  @media (max-width: 320px) {
    .calendar-component__header {
      flex-wrap: wrap;
      .btn-primary {
        width: 100%;
        margin-top: 2px;
      }
    }
    .calendar-selector[data-v-4418a876] {
      flex-wrap: wrap;
    }
    .calendar-table table tbody {
      max-height: 225px;
    }
  }
</style>

<style lang="scss">
  .calendar {
    &-plug {
      position: relative;
      .icon {
        position: absolute;
        top: 12px;
        right: 14px;
        z-index: 1;
      }
      .v-calendar__activity {
        border: none;
        .vc-weeks {
          padding: 18px 8px 0px 8px;
        }
        .vc-header {
          padding: 14px 16px;
        }
        .vc-day,
        .vc-weekday {
          margin-bottom: 9.5px;
        }
        .vc-arrows-container {
          width: 30%;
          top: 0px;
          left: 225px;
        }
        .vc-day-popover-container {
          max-height: 160px;
          overflow-x: auto;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .calendar-plug .v-calendar__activity .vc-arrows-container {
      width: 100%;
      top: 0px;
      left: 0px;
      justify-content: space-around;
    }
  }
</style>
