import axios from 'axios';

export default {
  methods: {
    saveFilter(filter, additionalKey) {
      localStorage.setItem(this.getKey(additionalKey), JSON.stringify(filter));
    },
    async loadFilter(additionalKey) {
      const filter = localStorage.getItem(this.getKey(additionalKey));
      if (filter && filter != undefined) return JSON.parse(filter);
      else {
        let roleId = this.$store.state.auth?.oidc?.profile?.roleIds[0];
        if (roleId) {
          let value = await this.getFilterValue(roleId, this.$route.path, true).then((response) => {
            let filterItem = response.data;
            if (filterItem) return JSON.parse(filterItem.value);
          });
          return value;
        }
      }
    },
    removeFilter(additionalKey) {
      localStorage.removeItem(this.getKey(additionalKey));
    },
    getKey(additionalKey) {
      const filterKeySuffix = '_filter';
      return this.$options.name !== 'DictionaryItems'
        ? this.$options.name + filterKeySuffix + (additionalKey ?? '')
        : this.$options.name + `_${this.name}` + filterKeySuffix + (additionalKey ?? '');
    },
    getFilterValue(roleId, route, searchGroup) {
      return axios.get(`/api/filter/filterValue?route=${route}&roleId=${roleId}&searchGroup=${searchGroup}`);
    },
    updateFilterValue(item) {
      return axios.put(`/api/filter/updateFilterValue`, item);
    },
    isFilterNotEmpty(filter, skipKeys) {
      let isEmpty = true;
      for (var key in filter) {
        if (skipKeys && skipKeys.includes(key)) continue;

        if ((Array.isArray(filter[key]) || typeof filter[key] === 'string') && filter[key].length > 0) {
          isEmpty = false;
          break;
        }
        if (!isNaN(filter[key]) && filter[key] > 0) {
          isEmpty = false;
          break;
        }
      }
      return !isEmpty;
    },
  },
};
