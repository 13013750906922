import EapAssignmentCard from '../views/EapAssignmentCard';
import EapAssignmentMonitoring from '../views/EapAssignmentMonitoring';
import EapAssignments from '../views/EapAssignments';

export const routes = [
  {
    path: '/zamer/orders/',
    name: 'EapAssignments',
    component: EapAssignments,
  },
  {
    path: '/zamer/orders/:action/:id',
    name: 'EapAssignmentCard',
    component: EapAssignmentCard,
  },
  {
    path: '/zamer/ordersMonitoring/',
    name: 'EapAssignmentMonitoring',
    component: EapAssignmentMonitoring,
  },
];
