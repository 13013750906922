<template>
  <div class="container-page">
    <div>
      <p class="title-inner">
        {{ pageTitle }}
      </p>

      <div class="mb-16 d-flex flex-row justify-content-between">
        <ButtonStock title="Создать" @click="editCard(0)" v-if="canEdit" />
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div v-if="page.items">
        <div class="table-container mb-16 js-table-arrow" v-if="page.items">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Статус</th>
                <th v-if="canEdit"></th>
                <th v-if="canEdit"></th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    placeholder="Введите название..."
                    v-model="filter.name"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterActiveInput v-model="filter.active" v-on:valueChangedDebounced="changeFilter" />
                </th>
                <th v-if="canEdit" class="filter"></th>
                <th v-if="canEdit" class="filter"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.items" :key="item.id">
                <td class="d-flex align-items-center">{{ item.code }} - {{ item.name }}</td>
                <td>
                  <ActiveComponent :value="item.isActive" />
                </td>
                <td v-if="canEdit">
                  <IconComponent @click="editCard(item.id)" name="mode" />
                </td>
                <td v-if="canEdit">
                  <IconComponent @click="deleteCard(item.id)" name="delete" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterActiveInput from '@/common/components/FilterActiveInput';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import ActiveComponent from '@/common/ui/ActiveComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/nsi';

  export default {
    name: 'MassMediaSmtk',
    components: {
      ButtonStock,
      IconComponent,
      ActiveComponent,
      FilterInput,
      FilterActiveInput,
      DefaultFilter,
    },
    mixins: [filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},

        filter: {
          name: '',
          active: null,
        },

        pageTitle: 'Анализ СМИ. Товары',
        apiRoute: 'massMediaSmtk',
        cardName: 'MassMediaSmtkCard',
        canEdit: true,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage();
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        var params = Object.assign({}, this.request);
        params.name = this.filter.name;
        params.isActive = this.filter.active;

        API.search(this.apiRoute, params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      editCard(id) {
        this.$router.push({ name: this.cardName, params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(this.apiRoute, id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
  .table-container table {
    min-width: auto;
    width: 100%;
  }

  .imagePreviewWrapper {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
</style>
