<!-- !В компоненте используется миксин componentInput, где имеются общие методы -->
<template>
  <div
    :class="['input-group-wrapper', 'w-100', mod ? `input-group-wrapper--${mod}` : '', { clear: clearButton && value }]"
  >
    <input
      v-if="type === 'checkbox'"
      type="checkbox"
      class="form-check-input"
      :required="requiredInternal"
      v-model="value"
      :id="id"
      :disabled="disabledInternal"
    />
    <label :for="id" v-if="label && type === 'checkbox'" :class="['form-check-label', 'form-label', modLabel]">{{
      label
    }}</label>

    <label v-if="label && type !== 'checkbox'" :class="['form-label', modLabel]" v-html="label"></label>
    <Select2
      v-if="type === 'select2'"
      :options="select2Options"
      :settings="select2Settings"
      v-model="value"
      @select="select2OnSelect"
      :placeholder="placeholder || 'Не указано'"
      :disabled="disabledInternal"
      :id="id"
      class="select2"
      :class="[{ error: v$.value.$error }]"
      v-on:clear="select2OnClear"
      v-on:unselecting="select2OnClear"
    />
    <input
      v-else-if="type !== 'checkbox' && type !== 'textarea'"
      :id="id"
      :class="[modClass, { error: v$.value.$error || error }]"
      :disabled="disabledInternal"
      :required="requiredInternal"
      v-model="value"
      :type="typeInput"
      :step="type === 'number' ? step : null"
      :maxlength="maxLength > 0 ? maxLength : null"
      @input="limitInput"
      @paste="onPaste"
      :placeholder="placeholderInternal"
      ref="inputRef"
      @focus="isShowTip = true"
      @blur="isShowTip = false"
    />
    <textarea
      v-else-if="type === 'textarea'"
      :id="id"
      :class="[modClass, { error: v$.value.$error }, { 'no-resize': noResize }]"
      :style="textareaStyles"
      :disabled="disabledInternal"
      :required="requiredInternal"
      v-model="value"
      @paste="onPaste"
      :placeholder="placeholderInternal"
      ref="inputRef"
      @focus="isShowTip = true"
      @blur="isShowTip = false"
    />
    <span v-if="tip && isShowTip && accessedTip" class="form-tip">{{ tip }}</span>
    <span v-if="clearButton && value" @click="onClear" class="input-group-wrapper__clear"></span>
  </div>
  <div class="invalid-msg" v-if="v$.value.$error || (instantErrors && validate)">
    <template v-if="v$.value.maxLengthValidator.$invalid"
      >Максимальная длина поля {{ getMessageForMaxLength(v$.value.maxLengthValidator.$params.value) }}
    </template>
    <template v-else-if="v$.value.numberFixLengthValidator.$invalid"
      >Длина поля {{ v$.value.numberFixLengthValidator.$params.value }} символов, только цифры
    </template>
    <template v-else-if="v$.value.passportDepValidator.$invalid"
      >Введите данные в формате XXX-XXX, где X-цифры
    </template>
    <template v-else-if="v$.value.passportSerieValidator.$invalid">Длина поля 4 символа, только цифры </template>
    <template v-else-if="v$.value.birthCertNumValidator.$invalid">Длина поля 6 символов, только цифры </template>
    <template v-else-if="v$.value.passportNumValidator.$invalid">Длина поля 6 символов, только цифры </template>
    <template v-else-if="v$.value.snilsValidator.$invalid"
      >Введите данные в формате XXX-XXX-XXX XX, где X-цифры
    </template>
    <template v-else-if="v$.value.snilsKeyValidator.$invalid">Неверный СНИЛС</template>
    <template v-else-if="v$.value.integerValidator.$invalid">Неверный формат для целого числа</template>
    <template v-else-if="v$.value.numberValidator.$invalid">Неверный формат для числа</template>
    <template v-else-if="v$.value.yearValidator.$invalid">Неверный формат для года (целое число больше 1900)</template>
    <template v-else-if="v$.value.minValueValidator.$invalid"
      >Значение должно быть не меньше {{ v$.value.minValueValidator.$params.value }}</template
    >
    <template v-else-if="v$.value.maxValueValidator.$invalid"
      >Значение должно быть не больше {{ v$.value.maxValueValidator.$params.value }}</template
    >
    <template v-else-if="v$.value.onlyNumberValidator.$invalid"> Значение должно быть числом </template>
    <template v-else>Поле обязательно для заполнения</template>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { helpers } from '@vuelidate/validators';
  import Inputmask from 'inputmask';

  import componentInput from '@/common/mixins/componentInput';

  const validateSnils = (snilsOrig) => {
    if (!snilsOrig) {
      return true;
    }
    var snils = snilsOrig.replaceAll('-', '').replaceAll(' ', '');
    var snilsLen = 11,
      snilsMagic1 = 100,
      snilsMagic2 = 101;
    var snilsMainLen = 9;
    if (snils.length === snilsLen) {
      var checksum = 0;
      for (var i = 0; i < snilsMainLen; i++) {
        checksum += parseInt(snils.charAt(i)) * (snilsMainLen - i);
      }
      if (checksum > snilsMagic2) {
        checksum = checksum % snilsMagic2;
      }
      if (checksum === snilsMagic1 || checksum === snilsMagic2) {
        checksum = 0;
      }
      return checksum === parseInt(snils.substr(snilsMainLen));
    }
    return false;
  };
  const integerValidator = (type, scope) =>
    helpers.withParams({ type: 'integerValidator' }, (value) => {
      if (type !== 'integer') return true;
      if (!value || ('' + value).length === 0) return true;
      return {
        $valid: Number.isInteger(value * 1),
        extraParams: { scope: scope },
      };
    });

  const numberValidator = (type, scope) =>
    helpers.withParams({ type: 'numberValidator' }, (value) => {
      if (type !== 'number') return true;
      if (!value || ('' + value).length === 0) return true;
      return {
        $valid: !isNaN(value),
        extraParams: { scope: scope },
      };
    });

  const minValueValidator = (type, param, scope) =>
    helpers.withParams({ type: 'minValueValidator', value: param }, (value) => {
      if (!(type === 'number' || type === 'integer' || type === 'onlyNumber')) return true;
      if (param === null || typeof param === 'undefined' || !value) return true;
      return {
        $valid: !isNaN(value) && Number(value) >= param,
        extraParams: { scope: scope },
      };
    });

  const maxValueValidator = (type, param, scope) =>
    helpers.withParams({ type: 'maxValueValidator', value: param }, (value) => {
      if (!(type === 'number' || type === 'integer' || type === 'onlyNumber')) return true;
      if (param === null || typeof param === 'undefined' || !value) return true;
      return {
        $valid: !isNaN(value) && Number(value) <= param,
        extraParams: { scope: scope },
      };
    });

  const yearValidator = (type, scope) =>
    helpers.withParams({ type: 'yearValidator' }, (value) => {
      if (type !== 'year') return true;
      if (!value || ('' + value).length === 0) return true;
      var res = Number.isInteger(value * 1);
      if (res) res = value > 1900 && value <= 2200;
      return {
        $valid: res,
        extraParams: { scope: scope },
      };
    });

  const numberFixLengthValidator = (param, scope) =>
    helpers.withParams({ type: 'numberFixLengthValidator', value: param }, (value) => {
      if (param === 0 || !value || ('' + value).length === 0) return true;
      var re = new RegExp('^[0-9]{' + param + '}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const maxNumberLengthValidator = (param, scope) =>
    helpers.withParams({ type: 'maxNumberLengthValidator', value: param }, (value) => {
      if (param === 0 || !value || ('' + value).length === 0) return true;
      var re = new RegExp('^[0-9]{0,' + param + '}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const maxLengthValidator = (param, scope) =>
    helpers.withParams({ type: 'maxLengthValidator', value: param }, (value) => {
      if (param === 0 || !value) return true;
      return {
        $valid: ('' + value).length <= param,
        extraParams: { scope: scope },
      };
    });

  const passportSerieValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'passportSerieValidator' }, (value) => {
      if (fieldType !== 'passportSerie') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      var re = new RegExp('^[0-9]{4}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const passportNumValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'passportNumValidator' }, (value) => {
      if (fieldType !== 'passportNum') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      var re = new RegExp('^[0-9]{6}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const requiredValidator = (requiredInternal, scope, type, multiple) =>
    helpers.withParams({ type: 'requiredValidator', requiredInternal }, (value) => {
      let res = true;

      if (!requiredInternal) return res;

      if (type === 'snils' || type === 'phone' || type === 'passportDep')
        res =
          (value ? value.replaceAll('-', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '') : '') !== '';
      else if (type === 'checkbox') res = !!value;
      else if (type === 'select2') {
        if (multiple) res = value.length > 0;
        else res = value && (value > 0 || value == 'false' || value == 'true');
      } else {
        if (typeof value === 'string') {
          res = value && value.trim().length > 0;
        } else if (type === 'number') {
          res = !isNaN(value) && value != null;
        } else {
          res = value;
        }
      }

      res = !!res;

      return {
        $valid: res,
        extraParams: { scope: scope },
      };
    });

  const birthCertNumValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'birthCertNumValidator' }, (value) => {
      if (fieldType !== 'birthCertNum') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      var re = new RegExp('^[0-9]{6}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const indexValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'indexValidator' }, (value) => {
      if (fieldType !== 'index') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      var re = new RegExp('^[0-9]{6}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const passportDepValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'passportDepValidator' }, (value) => {
      if (fieldType !== 'passportDep') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      var re = new RegExp('^[0-9]{3}-[0-9]{3}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const snilsValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'snilsValidator' }, (value) => {
      if (fieldType !== 'snils') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      var re = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  const snilsKeyValidator = (useValidation, fieldType, scope) =>
    helpers.withParams({ type: 'snilsValidator' }, (value) => {
      if (fieldType !== 'snils') return true;
      if (!useValidation) return true;
      if (!value || value.length === 0) return true;
      return {
        $valid: validateSnils(value),
        extraParams: { scope: scope },
      };
    });

  const onlyNumberValidator = (fieldType, scope) =>
    helpers.withParams({ type: 'onlyNumberValidator' }, (value) => {
      if (fieldType !== 'onlyNumber') return true;
      if (!value || value.length === 0) return true;
      const re = new RegExp('[0-9]');
      return {
        $valid: re.test(value),
        extraParams: { scope: scope },
      };
    });

  export default {
    name: 'ComponentInput',
    mixins: [componentInput],
    props: {
      disabled: {
        type: Boolean,
      },
      id: [String, Number],
      modelValue: [String, Number, Object, Boolean, Array],
      required: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: [String, Number],
        default: '',
      },
      modClass: {
        type: String,
        default: '',
      },
      inputMask: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        validator: function (value) {
          return (
            [
              'phone',
              'phoneCity',
              'passportSerie',
              'passportNum',
              'birthCertNum',
              'snils',
              'passportDep',
              'time',
              'number',
              'select2',
              'checkbox',
              'index',
              'textarea',
              'integer',
              'month',
              'year',
              'onlyNumber',
            ].indexOf(value) !== -1
          );
        },
      },
      useDefaultValidation: {
        type: Boolean,
        default: true,
      },
      maxLength: {
        type: Number,
        default: 0,
      },
      fixLength: {
        type: Number,
        default: 0,
      },
      maxNumberLength: {
        type: Number,
        default: 0,
      },
      scopeName: {
        type: String,
        default: '',
      },
      select2Options: {
        type: Array,
        default: () => [],
      },
      select2Settings: {
        type: Object,
        default: () => {},
      },
      // используется в миксине componentInput
      // eslint-disable-next-line
      additionalData: {
        type: Object,
        default: () => {},
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      step: {
        type: Number,
        default: 1,
      },
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
      // модификатор-класс для внешней обертки компонента
      mod: {
        type: String,
        validator(value) {
          return ['rectangular', 'checkbox-green', 'filter', 'filter-small'].includes(value);
        },
      },
      // модификатор-класс для label
      modLabel: {
        type: String,
        validator(value) {
          return ['big', 'small-size'].includes(value);
        },
      },
      textareaStyles: {
        type: Object,
        default: () => {},
      },
      error: {
        type: Boolean,
        default: false,
      },
      clearButton: {
        type: Boolean,
        default: false,
      },
      // параметр для ошибок, которые появляются
      // вне зависимости от $touch()
      instantErrors: {
        type: Boolean,
        default: false,
      },
      noResize: {
        type: Boolean,
        default: false,
      },
      tip: {
        type: String,
        default: '',
      },
      accessedTip: {
        type: Boolean,
        default: true,
      },
    },
    watch: {
      disabled(newValue) {
        this.disabledInternal = newValue;
      },
      required(newValue) {
        this.requiredInternal = newValue;
      },
    },
    emits: ['update:modelValue', 'select2OnSelect', 'select2OnClear', 'onClear'],
    data() {
      return {
        placeholderInternal: this.placeholder,
        maskInternal: '',
        disabledInternal: this.disabled,
        requiredInternal: this.required,
        isShowTip: false,
      };
    },
    computed: {
      value: {
        get() {
          if (this.type === 'select2' && this.modelValue && !Array.isArray(this.modelValue))
            return this.modelValue.toString();
          return this.modelValue;
        },
        set(value) {
          if (this.typeInput === 'number' && value === '') value = null;
          this.$emit('update:modelValue', value);
        },
      },
      typeInput() {
        if (this.type === 'year' || this.type === 'integer') return 'number';
        return this.type;
      },
      validate() {
        return this.v$.$invalid;
      },
    },
    mounted() {
      this.fillInputParams();
      var mask = this.maskInternal || this.inputMask;
      if (mask !== '') {
        var im = new Inputmask(mask);
        //im.mask($('.form-control'));
        im.mask($(this.$refs.inputRef));
      }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        value: {
          requiredValidator: requiredValidator(this.requiredInternal, this.scopeName, this.type, this.multiple),
          passportDepValidator: passportDepValidator(this.useDefaultValidation, this.type, this.scopeName),
          passportSerieValidator: passportSerieValidator(this.useDefaultValidation, this.type, this.scopeName),
          passportNumValidator: passportNumValidator(this.useDefaultValidation, this.type, this.scopeName),
          birthCertNumValidator: birthCertNumValidator(this.useDefaultValidation, this.type, this.scopeName),
          indexValidator: indexValidator(this.useDefaultValidation, this.type, this.scopeName),
          numberFixLengthValidator: numberFixLengthValidator(this.fixLength, this.scopeName),
          maxNumberLengthValidator: maxNumberLengthValidator(this.maxNumberLength, this.scopeName),
          snilsValidator: snilsValidator(this.useDefaultValidation, this.type, this.scopeName),
          snilsKeyValidator: snilsKeyValidator(this.useDefaultValidation, this.type, this.scopeName),
          maxLengthValidator: maxLengthValidator(this.maxLength, this.scopeName),
          yearValidator: yearValidator(this.type, this.scopeName),
          numberValidator: numberValidator(this.type, this.scopeName),
          integerValidator: integerValidator(this.type, this.scope),
          minValueValidator: minValueValidator(this.type, this.min, this.scope),
          maxValueValidator: maxValueValidator(this.type, this.max, this.scope),
          onlyNumberValidator: onlyNumberValidator(this.type, this.scopeName),
        },
      };
    },
    methods: {
      limitInput(event) {
        if (this.maxLength === 0) return;
        if (event.target.value.length > this.maxLength) {
          event.target.value = event.target.value.slice(0, this.maxLength);
        }
        this.inputValue = event.target.value;
      },
      select2OnClear() {
        //debugger;
        this.$emit('select2OnClear');
      },

      onClear() {
        this.$emit('onClear');
      },
      getMessageForMaxLength(value) {
        const length = Number(value);
        if (length === 1) {
          return `${length} символ.`;
        }
        if ([2, 3, 4].includes(length)) {
          return `${length} символа.`;
        }
        return `${length} символов.`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../assets/theme/default/colors';

  .no-resize {
    resize: none;
  }

  .input-group-wrapper {
    align-items: flex-end;
    position: relative;

    input:not([type='checkbox']) {
      width: 100%;
      &:focus {
        border-color: $green;
      }
    }
    textarea {
      width: 100%;
      min-height: 40px;

      &:focus {
        border-color: $green;
      }
    }

    &__clear {
      position: absolute;
      background-color: var(--ms-clear-color, #999);
      display: inline-block;
      mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
      transition: 0.3s;
      width: 12px;
      height: 12px;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &.clear {
      input {
        padding-right: 22px !important;
      }
    }

    &--rectangular,
    &--filter {
      flex-wrap: wrap;
      display: flex;

      input,
      textarea {
        border-radius: 4px;
        padding: 9px 14px;
        font: $fira-14-20;

        &:disabled {
          background-color: $grey-disabled;
          border-color: $grey-disabled;
        }

        &::placeholder {
          font: $fira-14-20;
        }
      }
    }

    &--filter-small {
      flex-wrap: wrap;
      display: flex;

      input,
      textarea {
        border-radius: 4px;
        padding: 8px;
        font: $fira-12;

        &:disabled {
          background-color: $grey-disabled;
          border-color: $grey-disabled;
        }

        &::placeholder {
          font: $fira-12;
        }
      }
    }

    &--checkbox-green {
      .form-check-input {
        opacity: 0;
      }

      .form-check-label {
        position: relative;
        font: 16px / 20px 'Fira Sans';
        padding-left: 32px;
        cursor: pointer;

        &::before,
        &::after {
          content: '';
          position: absolute;
          border-radius: 4px;
          border: 1px solid $green;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          z-index: 0;
          background: none;
        }

        &::after {
          opacity: 0;
          border: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3crect width='20' height='20' rx='4' fill='url(%23paint0_linear_3530_38638)'/%3e%3cpath d='M15 5.8335L8.125 13.3335L5 9.92441' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_3530_38638' x1='20' y1='10' x2='0' y2='10' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2301A39D'/%3e%3cstop offset='1' stop-color='%230078C8'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
          transition: 0.5s ease;
          z-index: 1;
        }

        &.small-size {
          font: $fira-14-20;
        }
      }

      input:checked ~ .form-check-label {
        &::after {
          opacity: 1;
        }
      }
    }

    .horizontally-date & {
      margin-top: 0 !important;
    }
  }
  .form-label {
    font-weight: 400 !important;
    width: 100%;
    font: $fira-16;
  }
  .form-check.required .form-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  .form-group.required .form-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  .form-tip {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    border-radius: 4px;
    padding: 12px;
    background: $blue-pre-dark;
    font: $fira-16-20;
    color: $white;
    z-index: 10;
  }

  .select2-container {
    min-width: 10em !important;
  }
  .select2::v-deep {
    .select2-container--default .select2-selection--single .select2-selection__clear {
      right: -1px !important;
    }
  }
  .select2::v-deep {
    outline: none;
    .select2-container {
      width: 100% !important;
      max-width: 100% !important;
      border: 1px solid #d6e4ec;
      font-size: 14px;
      letter-spacing: 0.15px;
      transition: 0.3s;
      border-radius: 4px !important;
      min-height: calc(2.25rem + 6px) !important;
      background-color: #fbfbfb;
      outline: none;
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #343c44;
        margin: 0 !important;
        padding-left: 0;
      }
    }
    .select2-container--default .select2-selection .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      transform: translateY(-50%) !important;
    }
    .select2-container--default .select2-selection {
      min-height: calc(2.25rem + 4px) !important;
      line-height: 20px;
      border: 0;
      border-radius: 4px !important;
      padding: 5px 10px;
      background-color: #fbfbfb;
    }

    .select2-container--default .select2-selection--multiple {
      min-height: calc(2.25rem + 4px) !important;
      line-height: 20px;
      border: 0;
      border-radius: 4px !important;
      &:after {
        right: 14px;
      }
    }

    .select2-container--default .select2-selection .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 1px;
      border-left: 3px solid #343c44;
      border-bottom: 3px solid #343c44;
      transform: rotate(315deg);
      margin-left: -10px;
      margin-top: -6px;
      transition: 0.3s;
    }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      transform: rotate(135deg);
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      outline: none;
      background-color: #d6e4ec;
      border: 1px solid #343c44;
      border-radius: 4px;
      cursor: default;
      float: left;
      color: #343c44;
    }

    .input-group-wrapper--rectangular & {
      width: 100%;

      .select2-container {
        .select2-selection__rendered {
          font: $fira-16;
        }
      }

      .select2-container--default {
        .select2-selection {
          padding: 8px 16px;
          display: flex;
          align-items: center;

          .select2-selection__arrow {
            right: 10px;
          }
        }
      }
    }
  }
  .input-group-wrapper--redesigned {
    .select2::v-deep {
      outline: none;

      .select2-container {
        border: 1px solid #d6e4ec;
        font: $fira-16;
        .select2-selection__rendered {
          font: $fira-16;
          padding-right: 30px;
        }

        &.select2-container--disabled {
          border-color: #f3f4f6;

          & .select2-selection {
            background-color: #f3f4f6;
          }

          & .select2-selection__placeholder,
          & .select2-selection__arrow {
            opacity: 0.2;
          }
        }
      }

      .select2-container--open {
        border: 1px solid #01a39d;
      }
      .select2-container--default .select2-selection .select2-selection__arrow {
        outline: none;
        top: 50% !important;
        transform: translateY(-50%) !important;
        right: 10px;
      }
      .select2-container--default .select2-selection {
        min-height: calc(2.25rem + 4px) !important;
        line-height: 20px;
        border: 0;
        border-radius: 4px !important;
        padding: 5px 10px;
        background-color: #fbfbfb;
        display: flex;
        align-items: center;
      }

      .select2-container--default .select2-selection--multiple {
        min-height: calc(2.25rem + 4px) !important;
        line-height: 20px;
        border: 0;
        border-radius: 4px !important;

        &:after {
          right: 14px;
        }
      }

      .select2-container--default .select2-selection .select2-selection__arrow b {
        outline: none;
        border-color: unset;
        border-style: unset;
        border-width: unset;
        width: 10px;
        height: 10px;
        border-radius: 1px;
        border-left: 3px solid #343c44;
        border-bottom: 3px solid #343c44;
        transform: rotate(315deg);
        margin-left: -10px;
        margin-top: -6px;
        transition: 0.3s;
      }
      .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        transform: rotate(135deg);
      }

      .select2-container--default .select2-selection--multiple .select2-selection__choice {
        max-width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        outline: none;
        background: none;
        border: none;
        border-radius: 4px;
        cursor: default;
        float: left;
      }

      .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        color: $grey-2;
        margin-right: 2px;
        margin-left: 0;
      }

      &.error {
        .select2-container {
          border: 1px solid red;
        }
      }
    }

    &.no-target {
      .select2::v-deep {
        .select2-container--default .select2-selection--multiple::after {
          display: none;
        }

        .select2-container--default
          .select2-selection--multiple
          .select2-selection__rendered
          .select2-selection__clear {
          font: $fira-20;
          right: 52px;
          top: 70%;
        }
      }
    }
  }
</style>
