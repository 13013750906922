export default {
  methods: {
    /**
     * Метод для работы с плюральными формами
     * @param {Number} number - число, от которого зависит окончание
     * @param {Array} titles - массив склоняемого слова, элементы массива: [для нулевого значения, для нескольких значений, для многих значений]
     * @returns результат склонения
     */
    wordDeclension(number, titles) {
      const ruOrdinalRules = new Intl.PluralRules('ru');
      const pluralRule = ruOrdinalRules.select(number);

      switch (pluralRule) {
        case 'one':
          return titles[0];
        case 'few':
          return titles[1];
        default:
          return titles[2];
      }
    },
  },
};
