<template>
  <div class="financial-sector">
    <div class="d-flex align-items-end">
      <div class="financial-sector-title" id="financial-sector">Финансовый сектор</div>
      <div class="analytics-source-text">Источник: Trading Economics</div>
    </div>

    <ul class="indicators__list">
      <li class="indicators__item d-flex flex-column justify-content-between">
        <div>
          <div class="indicators__item-title">Доходость гос. ценных бумаг</div>
          <div v-if="data" class="analytics-source-text">{{ data.date }}</div>
        </div>
        <div v-if="!!data" class="d-flex justify-content-center align-items-center">
          <strong>{{ data.yield !== null ? formatNumber(data.yield) : '—' }}</strong>
          <p class="percent-sign" v-if="data.yield !== null">%</p>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <span>Нет данных.</span>
        </div>
      </li>
      <li class="indicators__item d-flex flex-column justify-content-between">
        <div>
          <div class="indicators__item-title">Капитализация рынка акций</div>
          <div v-if="data" class="analytics-source-text">{{ data.date }}</div>
          <div class="analytics-source-text">Источник: CompaniesMarketCap.com</div>
        </div>
        <div v-if="!!data" class="d-block">
          <strong class="d-inline-block">{{
            data.totalMarketCap !== null ? formatNumber(data.totalMarketCap / 1000000000.0) : '—'
          }}</strong>
          <span class="d-inline-block ml-2" v-if="data?.totalMarketCap !== null">млрд $ США</span>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <span>Нет данных.</span>
        </div>
      </li>
    </ul>
    <div class="chart-header-title" id="monetary-offer">Денежное предложение, {{ country?.currency ?? '-' }}</div>
    <div class="chart-data-container">
      <template v-if="!!data">
        <ColumnChart
          :key="chartKey"
          :format-label-function="formatLabel"
          :chart-data="chartData"
          :colors="['#37bde3', '#165e75', '#a3d8e5']"
          :labels="['m0', 'm1', 'm2', 'm3']"
        />
      </template>
      <template v-else>
        <div class="d-flex justify-content-center align-items-center">
          <span>Нет данных.</span>
        </div>
      </template>

      <div class="indicators__description">
        <p class="indicators__description-item">*0 показатель означает отсутствие данных в источнике</p>
        <p class="indicators__description-item">М0 = наличные деньги в обращении (монеты, банкноты).</p>
        <p class="indicators__description-item">
          М1 = М0 + чеки, вклады до востребования (в том числе банковские дебетовые карты), остатки средств в
          национальной валюте на расчётных счетах организаций, текущих и иных счетах до востребования населения,
          нефинансовых и финансовых (кроме кредитных) организаций.
        </p>
        <p class="indicators__description-item">М2 = М1 + срочные вклады.</p>
        <p class="indicators__description-item">
          М3 = М2 + сберегательные вклады, сертификаты и государственные облигации.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text.js';

  import ColumnChart from '@/components/columnChart';

  import Api from '@/modules/countryAnalyticsNew/api/api';
  import CountryApi from '@/modules/nsi/api/country';

  export default {
    name: 'FinancialSector',
    components: { ColumnChart },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    mixins: [text],
    data() {
      return {
        data: null,
        country: null,
      };
    },
    created() {
      if (this.countryId) {
        this.loadData();
      }
    },
    methods: {
      loadData() {
        Api.getFinancialSector(this.countryId).then((response) => {
          this.data = response.data;
          this.data.date = this.$momentFormat(this.data.modified, 'DD.MM.YYYY');
        });

        CountryApi.find(this.countryId)
          .then((response) => {
            this.country = response.data;
          })
          .catch(() => {
            this.country = null;
          });
      },
      formatLabel(value) {
        return (
          this.formatNumber(value, 0).replace('.', ',') +
          (this.maxNumbering > 0 ? ' ' + Constants.finSectorNumbering[this.maxNumbering] : '')
        );
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    computed: {
      chartKey() {
        return this.data ? (this.data.m0 + this.data.m1 + this.data.m2 + this.data.m3).toString() : '';
      },
      maxNumbering() {
        if (this.data)
          return Math.max(
            this.data.numberingM0 ?? -1,
            this.data.numberingM1 ?? -1,
            this.data.numberingM2 ?? -1,
            this.data.numberingM3 ?? -1,
          );
        else return 0;
      },
      chartData() {
        if (this.data) {
          let maxNumbering = this.maxNumbering;
          if (maxNumbering > 0) {
            return [
              (this.data.m0 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM0 ?? 0)),
              (this.data.m1 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM1 ?? 0)),
              (this.data.m2 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM2 ?? 0)),
              (this.data.m3 ?? 0) * Math.pow(0.001, maxNumbering - (this.data.numberingM3 ?? 0)),
            ];
          } else return [this.data.m0, this.data.m1, this.data.m2, this.data.m3];
        } else return [];
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/modules/countryAnalyticsNew/styles/index';
  .financial-sector-title,
  .chart-header-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }

  .chart-header-title {
    margin-top: 75px;
  }

  .financial-sector-title {
    margin-right: 60px;
  }

  .indicators__description-item {
    &:not(:first-child) {
      margin-top: 24px;
    }
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
  }

  .chart-data-container {
    padding: 30px 18px;
    margin-top: 25px;
    background: #ffffff;
    border: 1px solid #c7dbe9;
    border-radius: 12px;
  }
  .indicators__description {
    margin: 30px 50px 0 50px;
  }
</style>
