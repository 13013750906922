<template>
  <div class="container-page">
    <div v-if="page.items">
      <div class="table-container mb-16 js-table-arrow" v-if="page.items">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Дата изменения</th>
              <th>Сотрудник</th>
              <th>Поле</th>
              <th>Предыдущее значение</th>
              <th>Текущее значение</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ $momentFormat(item.modified, 'DD.MM.YYYY HH:mm:ss') }}</td>
              <td>{{ item.modifiedBy }}</td>
              <td>{{ item.propertyName }}</td>
              <td>{{ item.previousValue }}</td>
              <td>{{ item.currentValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import API from '../api/tnved';

  export default {
    name: 'NsiTnvedCardHistory',
    components: {},
    props: {
      tnvedId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},
      };
    },
    created() {
      this.loadPage(false);
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        var params = Object.assign({}, this.request);
        API.searchHistory(this.tnvedId, params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
  .table-container table {
    min-width: auto;
    width: 100%;
  }

  .imagePreviewWrapper {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
</style>
