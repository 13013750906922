<template>
  <div class="trade-investment__modal-btns" v-if="countryName">
    <ButtonStock
      class="trade-investment__modal-btns_btn"
      :title="'Соглашения ' + countryName + ' с третьими странами'"
      @click="onAgreementsButtonClick"
    />
  </div>

  <div class="trade-investment__modal-table">
    <table v-if="selectedAgreement">
      <tbody>
        <tr>
          <td>Название соглашения</td>
          <td>{{ selectedAgreement.name }}</td>
        </tr>
        <tr>
          <td>Текст соглашения и тарифные обязательства</td>
          <td>
            <ul class="trade-investment__modal-files" v-if="selectedAgreement.mainFiles">
              <li v-for="file in selectedAgreement.mainFiles" :key="file.id">
                <p class="file-name">{{ file.originalName }}</p>
                <IconComponent name="download" @click="downloadFile(file)" />
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Охват</td>
          <td>{{ selectedAgreement.tradeItemTypesStr }}</td>
        </tr>
        <tr>
          <td>Дата подписания</td>
          <td>{{ selectedAgreement.signDt }}</td>
        </tr>
        <tr>
          <td>Дата вступления в силу</td>
          <td>{{ selectedAgreement.startDt }}</td>
        </tr>
        <tr>
          <td>Стороны соглашения</td>
          <td>{{ selectedAgreement.sidesStr }}</td>
        </tr>
        <tr>
          <td>Первоначальные подписавшиеся</td>
          <td>{{ selectedAgreement.initialSignersStr }}</td>
        </tr>
        <tr>
          <td>Ответственное ведомство</td>
          <td>
            <div v-if="selectedAgreement.blocks">
              <div v-for="info in selectedAgreement.blocks" :key="info.id">
                <a :href="info.url" target="_blank">
                  {{ info.name }}
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Членство страны в региональных блоках, международных организациях/объединениях</td>
          <td>{{ selectedAgreement.responsibleDepartment }}</td>
        </tr>
        <tr>
          <td>Источники</td>
          <td>
            <div v-if="selectedAgreement.additionalInfo">
              <div v-for="info in selectedAgreement.additionalInfo" :key="info.id">
                <a :href="info.url" target="_blank">
                  {{ info.name }}
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Дополнительные сведения</td>
          <td>
            <div v-if="selectedAgreement.agreementAdditionalInfo">
              <div v-for="info in selectedAgreement.agreementAdditionalInfo" :key="info.id">
                <a :href="info.url" target="_blank">
                  {{ info.name }}
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Дополнительная информация</td>
          <td>
            <ul class="trade-investment__modal-files" v-if="selectedAgreement.additionalDocuments">
              <li v-for="document in selectedAgreement.additionalDocuments" :key="document.id">
                <p class="file-name">{{ document.name }}</p>
                <IconComponent name="download" @click="downloadFile(document.file)" />
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import API from '@/modules/countryAnalyticsNew/api/api';

  export default {
    name: 'TradeInvestmentModal',
    components: { ButtonStock, IconComponent },
    emits: ['agreementsButtonClick'],
    props: {
      countryName: {
        type: String,
        default: '',
      },
      selectedAgreementId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        selectedAgreement: null,
      };
    },
    created() {
      this.loadData();
    },
    mounted() {},
    beforeUnmount() {},
    methods: {
      downloadFile(file) {
        return Utils.downloadFile(`/api/file/download?id=${file.id}`, file.originalName);
      },
      loadData() {
        this.selectedAgreement = {};
        console.log(this.country);
        if (this.selectedAgreementId)
          API.getAgreement(this.selectedAgreementId).then((response) => {
            this.selectedAgreement = response.data;
          });
      },
      onAgreementsButtonClick() {
        this.$emit('agreementsButtonClick');
      },
    },
    watch: {
      selectedAgreementId: function () {
        this.loadData();
      },
    },
    computed: {},
  };
</script>

<style lang="scss" scoped>
  .trade-investment {
    &__modal {
      &-table {
        margin-top: 20px;
        overflow-x: auto;

        table {
          border: 1px solid #f3f3f3;
          border-radius: 3px;
          width: 740px;
          margin-bottom: 16px;

          tr {
            &:nth-of-type(odd) {
              background-color: #f3f3f3;
            }

            td {
              padding: 12px;
              font-weight: 400;
              font-size: 14px;
              line-height: 160%;
              color: #343c44;
            }
          }
        }
      }

      &-btns {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        a,
        button {
          //max-width: 250px;
          margin-right: 6px;
          margin-bottom: 8px;
          width: auto;
        }
      }

      &-files {
        li {
          display: flex;
          font-size: 14px;
          margin-bottom: 14px;

          .file-name {
            color: darkgray;
            margin-right: 16px;
          }
        }
      }
    }
  }
</style>
