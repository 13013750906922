export const routes = [
  {
    path: '/foreignmarkets/restrictmeasures',
    name: 'RestrictionMeasuresPage',
    component: () => import('../views/RestrictionMeasuresPage'),
  },
  {
    path: '/foreignmarkets/restrictmeasures/edit/:id',
    name: 'RestrictionMeasureEditPage',
    component: () => import('../views/RestrictionMeasureEditPage'),
  },
  {
    path: '/foreignmarkets/customsduties',
    name: 'MacmaporgCustomsDuties',
    component: () => import('../views/MacmaporgCustomsDuties'),
  },
  {
    path: '/foreignmarkets/customsduties/:action/:id',
    name: 'MacmaporgCustomsDutyCard',
    component: () => import('../views/MacmaporgCustomsDutyCard'),
  },
  {
    path: '/foreignmarkets/statements',
    name: 'Statements',
    component: () => import('../views/Statements'),
  },
  {
    path: '/foreignmarkets/statements/:action/:id',
    name: 'StatementCard',
    component: () => import('../views/StatementCard'),
  },
  {
    path: '/foreignmarkets/analytics',
    name: 'Analytics',
    component: () => import('../views/Analytics'),
  },
  {
    path: '/foreignmarkets/questions',
    name: 'Questions',
    component: () => import('../views/Questions'),
  },
  {
    path: '/foreignmarkets/questions/:action/:id',
    name: 'QuestionCard',
    component: () => import('../views/QuestionCard'),
  },
];
