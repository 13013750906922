import axios from 'axios';

export default {
  upload(file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post('/api/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  download(id) {
    return axios.get(`/api/file/download?id=${id}`, { responseType: 'blob' });
  },
};
