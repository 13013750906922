<template>
  <div class="statisticByMeasures">
    <h4 class="statisticByMeasures__title">Действующие ограничительные меры по отраслям и странам</h4>
    <div class="statisticByMeasures__container">
      <DoughnutContainer title="Количество действующих мер по отраслям">
        <Loader v-if="isLoadingDiagramByIndustry" />
        <ChartDonutOfPie v-else diagramType="pie" :seriesProp="diagramByIndustryData" />
      </DoughnutContainer>
      <DoughnutContainer title="Количество действующих мер по странам">
        <Loader v-if="isLoadingDiagramByCountry" />
        <ChartDonutOfPie diagramType="donut" :seriesProp="diagramByCountryData" v-else />
      </DoughnutContainer>
      <StatisticByMeasuresCard
        :diagramByCountryData="diagramByCountryData"
        :diagramByIndustryData="diagramByIndustryData"
      />
    </div>
    <h4 class="statisticByMeasures__title">Действующие ограничительные меры по типу барьера</h4>
    <div class="statisticByMeasures__container">
      <DoughnutContainer barrierType>
        <Loader v-if="isLoadingDiagramByMeasureKind" />
        <ChartDonutOfPie v-else diagramType="pie" :seriesProp="diagramByMeasureKindData" classMod="adaptive" />
      </DoughnutContainer>
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue';

  import analyticApi from '../../../api/analytics';
  import { sliceObject } from '../../../helpers/sliceObject';
  import ChartDonutOfPie from '../../chart/ChartDonutOfPie';
  import DoughnutContainer from './DoughnutContainer.vue';
  import StatisticByMeasuresCard from './StatisticByMeasuresCard.vue';

  export default {
    components: {
      DoughnutContainer,
      StatisticByMeasuresCard,
      ChartDonutOfPie,
      Loader,
    },

    data() {
      return {
        isLoadingDiagramByCountry: false,
        isLoadingDiagramByIndustry: false,
        isLoadingDiagramByMeasureKind: false,
        // данные для круговой диаграммы: количество действующих мер по странам
        diagramByCountryData: {},
        // данные для круговой диаграммы: количество действующих мер по отраслям
        diagramByIndustryData: {},
        // данные для круговой диаграммы: меры по типу барьера
        diagramByMeasureKindData: {},
      };
    },
    mounted() {
      this.isLoadingDiagramByCountry = true;
      this.isLoadingDiagramByIndustry = true;
      this.isLoadingDiagramByMeasureKind = true;
      this.fetchDiagramByCountry();
      this.fetchDiagramByIndustry();
      this.fetchDiagramByMeasureKind();
    },
    methods: {
      async fetchDiagramByCountry() {
        try {
          const diagramByCountryResolve = await analyticApi.getDiagramByCountry();

          if (!diagramByCountryResolve.data) {
            throw new Error('Ошибка');
          }

          this.diagramByCountryData = sliceObject(diagramByCountryResolve.data, 10);
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoadingDiagramByCountry = false;
        }
      },
      async fetchDiagramByIndustry() {
        try {
          const diagramByIndustryResolve = await analyticApi.getDiagramByIndustry();

          if (!diagramByIndustryResolve.data) {
            throw new Error('Ошибка');
          }

          this.diagramByIndustryData = sliceObject(diagramByIndustryResolve.data, 10);
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoadingDiagramByIndustry = false;
        }
      },
      async fetchDiagramByMeasureKind() {
        try {
          const diagramByMeasureKindResolve = await analyticApi.getDiagramByMeasureKind();

          if (!diagramByMeasureKindResolve.data) {
            throw new Error('Ошибка');
          }

          const startMeasureKindsDate = sliceObject(diagramByMeasureKindResolve.data, 9);

          const endMeasureKinds = sliceObject(diagramByMeasureKindResolve.data, null, 9);

          const otherCount = Object.values(endMeasureKinds).reduce((acc, value) => (acc += value), 0);
          if (otherCount > 0) {
            this.diagramByMeasureKindData = {
              ...startMeasureKindsDate,
              Другие: otherCount,
            };
          } else {
            this.diagramByMeasureKindData = startMeasureKindsDate;
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoadingDiagramByMeasureKind = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .statisticByMeasures {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__title {
      font: $fira-20-M;
    }

    &__container {
      display: flex;
      gap: 16px;

      @media (max-width: $tablet) {
        flex-wrap: wrap;
        max-width: 848px;
      }
    }
  }
</style>
