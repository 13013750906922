<template>
  <div class="container-page">
    <tabs>
      <tab name="Карточка товара" selected="true">
        <div>
          <div class="row">
            <div class="form-group required">
              <ComponentInput
                v-model="item.name"
                label="Наименование"
                required
                maxLength="255"
                placeholder="Введите название"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <ComponentInput
                v-model="item.shortName"
                label="Краткое наименование"
                maxLength="255"
                placeholder="Введите краткое наименование"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group required">
              <ComponentInput
                v-model="item.code"
                label="Код ТН ВЭД"
                required
                maxLength="100"
                placeholder="Введите Код ТН ВЭД"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <ComponentInput
                label="Торговые операции"
                :select2Settings="select2Settings"
                :select2Options="tradingOperations"
                v-model="item.tradingOperationIds"
                type="select2"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <ComponentInput
                label="Страны"
                :select2Settings="select2Settings"
                :select2Options="countries"
                v-model="item.countryIds"
                type="select2"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group required">
              <label for="txtDesc" class="form-label">Описание</label>
              <textarea v-model="item.description" id="txtDesc" class="form-control" rows="3"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="input-group-wrapper" v-if="canEditScenario">
                <label class="form-label">Используется в сценариях</label>
                <div v-for="scenario in scenarios" :key="scenario.id">
                  <router-link :to="`/navigator/scenarios/edit/${scenario.id}`" class="scenario-link" target="_blank">{{
                    scenario.text
                  }}</router-link>
                </div>
              </div>
              <template v-else>
                <ComponentInput
                  label="Используется в сценариях"
                  :select2Settings="select2Settings"
                  :select2Options="scenarios"
                  v-model="scenarioIds"
                  :disabled="true"
                  type="select2"
                />
              </template>
            </div>
          </div>
          <div class="row mt-10">
            <div class="checkboxInput">
              <input type="checkbox" v-model="item.isNonEnergy" id="chNonEnergy" readonly="readonly" />
              <label for="chNonEnergy">Несырьевой неэнергетический экспорт</label>
            </div>
          </div>
          <div class="row mt-10">
            <div class="checkboxInput">
              <input type="checkbox" v-model="item.isActive" id="chActive" />
              <label for="chActive">Запись активна</label>
            </div>
          </div>
        </div>
        <div class="mb-16">
          <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
          <ButtonStock title="Отмена" v-on:click="close" />
        </div>
      </tab>
      <tab name="История изменений">
        <NsiTnvedCardHistory :tnvedId="id" />
      </tab>
    </tabs>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength, required } from '@vuelidate/validators';

  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import CatalogApi from '../api/catalog';
  import CountryApi from '../api/country';
  import API from '../api/nsi';
  import NsiTnvedCardHistory from '../components/NsiTnvedCardHistory';

  export default {
    name: 'TnvedCard',
    components: { ButtonStock, ComponentInput, tabs, tab, NsiTnvedCardHistory },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        item: {
          id: 0,
          parentId: null,
          name: '',
          description: null,
          tradingOperationIds: [],
          countryIds: [],
          shortName: '',
        },
        countries: [],
        tradingOperations: [],
        scenarios: [],
        scenarioIds: [],
        nsiRoute: 'nsitnved',
        select2Settings: { language: Constants.langRu, multiple: true },
        canEditScenario: false,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();

      this.checkPermission(Constants.Permissions.ScenarioEdit).then((response) => {
        this.canEditScenario = response.data;
      });
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.nsiRoute, this.id).then((response) => {
            this.item = response.data;
          });
          CatalogApi.getTnVedScenarios(this.id).then((response) => {
            this.scenarios = response.data.map((x) => ({ id: x.id, text: x.name }));
            this.scenarioIds = this.scenarios.map((x) => x.id);
          });
        }
      },
      loadDictionaries() {
        CountryApi.search({ pageNumber: 1, pageSize: 0 })
          .then((response) => {
            this.countries = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        API.search('nsitradingoperation', { pageNumber: 1, pageSize: 0 })
          .then((response) => {
            this.tradingOperations = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        API.update(this.nsiRoute, this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.item.id) {
              this.$router.push({ name: 'TnvedCard', params: { id: this.item.id, action: 'edit' } });
            } else {
              this.$router.push({ name: 'Tnved' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Tnved' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          name: {
            required,
            maxLength: maxLength(255),
          },
          code: {
            required,
            maxLength: maxLength(100),
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
  .scenario-link {
    font-size: 14px;
  }
</style>
