<template>
  <div class="statistics-container" v-if="countryId">
    <div class="button-container">
      <ButtonStock
        @click="displayTypeId = 1"
        btnType="white"
        class="btn-switch-view"
        :class="{ selected: displayTypeId == 1 }"
        title="Диаграмма"
      />
      <ButtonStock
        @click="displayTypeId = 2"
        btnType="white"
        class="btn-switch-view ml-3"
        :class="{ selected: displayTypeId == 2 }"
        title="Таблица"
      />
    </div>

    <div v-if="displayTypeId == 1" class="statistics-data">
      <div class="d-flex statistics-title-container">
        <div class="chart-titles d-flex justify-content-between align-items-center mr-5">
          <div class="statistics-title" id="project-statistics-industries">Распределение проектов по отраслям</div>
          <div class="statistics-title" id="project-statistics-purposes" v-if="!isBarDiagram">
            Распределение проектов по целям
          </div>
        </div>
        <div class="diagram-type-switch d-flex align-items-center">
          <div class="diagram-type-title mr-2">Тип диаграммы</div>
          <SwitchComponent
            class="statistics_toggler"
            textTrue=""
            textFalse=""
            :checked="!isBarDiagram"
            @switchHandler="changeDiagramType($event)"
          />
        </div>
      </div>
      <div v-if="isBarDiagram">
        <div v-if="!projectDataLoading && projectData && projectData.byIndustry" class="chart-container">
          <ColumnChartHorizontal
            v-if="!projectDataLoading && projectData.byIndustry && projectData.byIndustry.length"
            :key="projectData.byIndustry"
            :bar-percentage="1"
            :height="60"
            :afterFitY="
              (scaleInstance) => {
                scaleInstance.width = 350;
              }
            "
            index-axis="y"
            :labels="projectData.byIndustry.map((item) => makeChartLabel(capitalize(item.id), 50))"
            :chart-data="projectData.byIndustry.map((item) => item.count)"
          />
          <div v-if="!projectData.byIndustry || projectData.byIndustry.length == 0" class="no-data">Нет данных</div>
        </div>
        <div v-if="!projectDataLoading && projectData && projectData.byPurpose" class="purpose-chart-container">
          <div class="statistics-title statistics-title-container" id="project-statistics-purposes">
            Распределение проектов по целям
          </div>
          <div class="chart-container">
            <ColumnChartHorizontal
              v-if="!projectDataLoading && projectData.byPurpose && projectData.byPurpose.length"
              :key="projectData.byPurpose"
              :bar-percentage="1"
              :height="60"
              :afterFitY="
                (scaleInstance) => {
                  scaleInstance.width = 350;
                }
              "
              index-axis="y"
              :labels="projectData.byPurpose.map((item) => makeChartLabel(capitalize(item.id), 50))"
              :chart-data="projectData.byPurpose.map((item) => item.count)"
            />
            <div v-if="!projectData.byPurpose || projectData.byPurpose.length == 0" class="no-data">Нет данных</div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex chart-container">
        <div class="donut-container" v-if="projectData.byIndustry && projectData.byIndustry.length">
          <ChartDonut
            template="stock"
            :seriesProp="
              projectData.byIndustry.map((item) => {
                return { name: item.id, count: item.count };
              })
            "
            :colors="colors"
          />
          <ul class="legend-container d-flex flex-wrap align-items-center">
            <li class="legend-item align-items-center" v-for="(item, index) in projectData.byIndustry" :key="index">
              <div :style="'background-color: ' + colors[index]" class="mr-2"></div>
              <span>{{ item.id }}</span>
            </li>
          </ul>
        </div>
        <div v-else class="donut-container donut-container-no-data">Нет данных</div>
        <div class="donut-container" v-if="projectData.byPurpose && projectData.byPurpose.length">
          <ChartDonut
            template="stock"
            :seriesProp="
              projectData.byPurpose.map((item) => {
                return { name: item.id, count: item.count };
              })
            "
            :colors="colors"
          />
          <ul class="legend-container d-flex flex-wrap align-items-center">
            <li class="legend-item align-items-center" v-for="(item, index) in projectData.byPurpose" :key="index">
              <div :style="'background-color: ' + colors[index]" class="mr-2"></div>
              <span>{{ item.id }}</span>
            </li>
          </ul>
        </div>
        <div v-else class="donut-container donut-container-no-data">Нет данных</div>
      </div>
    </div>
    <div v-else-if="displayTypeId == 2">
      <div class="list-projects__results-table table-container js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th class="col-1">Статус</th>
              <th class="col-2">Название</th>
              <th class="col-2">Российская<br />сторона</th>
              <th class="col-1">Иностранная<br />сторона</th>
              <th class="col-1">Начало</th>
              <th class="col-1">Конец</th>
              <th class="col-1">
                <div>Объем инвестиций</div>
                <div>(млн $ США)</div>
              </th>
              <!-- <th class="col-1">Возможный эконом. эффект</th> -->
              <th class="col-1">Отрасль</th>
              <th class="col-2">Описание</th>
              <th>Дата создания</th>
              <th>Дата измениня</th>
            </tr>
          </thead>
          <tbody v-if="page && page.items && page.items.length">
            <tr v-for="item in page.items" :key="item.id" @dblclick="viewCard(item)" class="cursorPointer">
              <td :class="getColor(item.statusId)">{{ item.status.name }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.participantsDomesticStr }}</td>
              <td>{{ item.participantsForeign }}</td>
              <td>
                {{ $momentFormat(item.startMonthYear, 'MMMM YYYY') }}
              </td>
              <td>{{ $momentFormat(item.endMonthYear, 'MMMM YYYY') }}</td>
              <td>
                <div v-for="(fund, idx) in item.fundingAmounts" :key="idx">
                  <div v-if="fund.year && fund.amount">{{ fund.year }} год - {{ fund.amount }} млн $ США</div>
                  <div v-else-if="fund.amount">{{ fund.amount }} млн $ США</div>
                </div>
              </td>
              <!-- <td>{{ item.potencialEffect }}</td> -->
              <td>{{ item.industry.name }}</td>
              <td>
                <p v-snip="3">{{ item.description }}</p>
              </td>
              <td>{{ item.created ? $momentFormat(item.created, 'DD.MM.YYYY HH:mm') : '-' }}</td>
              <td>{{ item.modified ? $momentFormat(item.modified, 'DD.MM.YYYY HH:mm') : '-' }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="11">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="11">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import SwitchComponent from '@/components/buttons/switchComponent';
  import ChartDonut from '@/components/ChartComponents/ChartDonut.vue';
  import ColumnChartHorizontal from '@/components/column-chart-horizontal';

  import API from '@/modules/mpkProject/api/index';

  export default {
    name: 'ProjectStatistics',
    components: { ButtonStock, SwitchComponent, ColumnChartHorizontal, ChartDonut },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
      statusId: {
        type: Number,
        default: null,
      },
    },
    emits: ['displayTypeChanged'],
    data() {
      return {
        displayTypeId: 2,
        isBarDiagram: false,
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},
        projectData: {
          byIndustry: [],
          byPurpose: [],
        },
        projectDataLoading: false,
        colors: [
          '#61C0BF',
          '#BBDED6',
          '#3BDBE4',
          '#A6E3E9',
          '#C3BEF0',
          '#CCA8E9',
          '#ABEDD8',
          '#46CDCF',
          '#FFAAA6',
          '#FFD3B5',
          '#BC9CFF',
          '#FFCEF3',
          '#FAE3D9',
          '#FFB6B9',
          '#DFF4F3',
          '#DDE7F2',
          '#878ECD',
          '#B9BBDF',
        ],
      };
    },
    created() {
      this.$watch(
        (vm) => [vm.countryId, vm.statusId, vm.displayTypeId],
        () => {
          if (this.displayTypeId == 2) {
            this.request.pageNumber = 1;
            this.loadPage();
          } else if (this.displayTypeId == 1) this.loadProjectStatistics();
        },
      );

      this.loadPage();
      this.$emit('displayTypeChanged', this.displayTypeId);
    },
    methods: {
      capitalize(str) {
        return str[0].toUpperCase() + str.slice(1);
      },
      changeDiagramType(e) {
        this.isBarDiagram = !e;
      },
      loadPage(addToExist) {
        let filter = {};
        if (this.countryId) filter.countryIds = [this.countryId];
        if (this.statusId && this.statusId != 0) filter.statusIds = [this.statusId];

        let params = Object.assign(filter, this.request);
        API.search(params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      loadProjectStatistics() {
        let filter = { pageNumber: 1, pageSize: 0 };
        if (this.countryId) filter.countryIds = [this.countryId];
        if (this.statusId && this.statusId != 0) filter.statusIds = [this.statusId];

        this.projectDataLoading = true;
        this.projectData = {
          byIndustry: [],
          byPurpose: [],
        };
        API.getProjectStatistics(filter)
          .then((response) => {
            this.projectData = response.data;
          })
          .finally(() => {
            this.projectDataLoading = false;
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      getColor(statusId) {
        return 'status-' + statusId;
      },
      viewCard(item) {
        if (item.canView) this.$router.push({ name: 'ProjectCard', params: { id: item.id } });
        else {
          Constants.alert.fire('Недостаточно прав', 'У вас недостаточно прав для просмотра данных проекта', 'error');
        }
      },
      makeChartLabel(str, limit) {
        const words = str.split(' ');
        let aux = [];
        let concat = [];

        for (let i = 0; i < words.length; i++) {
          concat.push(words[i]);
          let join = concat.join(' ');
          if (join.length > limit) {
            aux.push(join);
            concat = [];
          }
        }

        if (concat.length) {
          aux.push(concat.join(' ').trim());
        }

        return aux;
      },
    },
    watch: {
      displayTypeId: function () {
        this.$emit('displayTypeChanged', this.displayTypeId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn-switch-view {
    border-radius: 6px;
    color: #ffffff;
    border: none;
  }
  .btn-switch-view:not(:hover, .selected) {
    background: #c0c0c0;
  }

  .statistics-container {
    margin-top: 50px;
  }

  .statistics-container:deep(.slider::before) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .statistics-container:deep(input:not(:checked) + .slider::before) {
    background-image: url(~@/assets/images/svg/donut-chart.svg);
  }
  .statistics-container:deep(input:checked + .slider::before) {
    background-image: url(~@/assets/images/svg/bar-chart.svg);
  }

  .statistics-data {
    margin-top: 50px;
  }

  .statistics-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }

  .statistics-title-container {
    margin-bottom: 18px;
  }

  .statistics_toggler {
    margin: 0;
  }

  .list-projects {
    &__results {
      &-table {
        margin-bottom: 42px;
        thead {
          &:nth-of-type(1) {
            text-align: center;
          }
        }
      }
    }
  }

  .donut-container {
    width: 50%;
  }

  .legend-container {
    margin-top: 30px;
  }

  .legend-item {
    width: 50%;
    display: flex;
    padding: 5px;

    div {
      height: 20px;
      width: 20px;
      min-width: 20px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #231f20;
    }
  }

  .chart-titles {
    width: 100%;
  }

  .diagram-type {
    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #231f20;
    }
    &-switch {
      min-width: 165px;
    }
  }

  .chart-outer-container {
    margin-top: 18px;
  }

  .chart-container {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #c7dbe9;
    border-radius: 12px;
    padding: 20px;
  }

  .purpose-chart-container {
    margin-top: 65px;
  }

  .list-projects__results-table {
    margin-top: 25px;
  }

  .status-1 {
    color: #00be35 !important;
  }
  .status-2 {
    color: #5b74cf !important;
  }
  .status-3 {
    color: #f9c339 !important;
  }
  .status-4 {
    color: #b6b6b6 !important;
  }
  .status-5 {
    color: #df5561 !important;
  }
  .status-6 {
    color: #dea943 !important;
  }
</style>
