<template>
  <div class="life-index">
    <div class="d-flex mb-4 align-items-center justify-content-between">
      <h2>Позиции в мировых рейтингах</h2>
      <div class="analytics-source-text">Источник: oecdru.org<br />Период: {{ indicatorValues.year }}</div>
    </div>
    <ul class="indicators__list align-center">
      <li v-for="item in indicatorsList" :key="item.id" class="indicators__item">
        <p>{{ item.title }}</p>

        <!--        <div v-if="item.source" class="analytics-source-text">-->
        <!--          Источник: {{ item.source }}-->
        <!--        </div>-->
        <div class="indicators__item-wrapper">
          <strong>{{ getIndicatorValue(item.name) }} </strong>
          <p class="suff" v-if="item.suff">{{ item.suff }}</p>
        </div>
        <!-- <div v-if="item.dateUpdate" class="analytics-source-text">
          Дата обновления: {{ item.dateUpdate }}
        </div> -->
      </li>
    </ul>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  export default {
    name: 'lifeIndex',
    props: {
      indicatorValues: {
        type: Object,
      },
    },
    mixins: [text],
    data() {
      return {
        indicatorsList: [
          {
            title: 'Жилищные условия',
            name: 'housingExpenditure',
            suff: '%',
          },
          {
            title: 'Доход',
            name: 'householdIncome',
            suff: '$ США',
          },
          {
            title: 'Работа',
            name: 'employmentRate',
            suff: '%',
          },
          {
            title: 'Социальная поддержка',
            name: 'qualityNetwork',
            suff: '%',
          },
          {
            title: 'Образование',
            name: 'educationalAttainment',
            suff: '%',
          },
          {
            title: 'Окружающая среда',
            name: 'airPollution',
            suff: 'мг/м3',
          },
          {
            title: 'Вовлечение гражданского общества',
            name: 'stakeholderEngagement',
            suff: 'ср.знач.',
          },
          {
            title: 'Здоровье',
            name: 'lifeExpectancy',
            suff: 'лет',
          },
          {
            title: 'Удовлетворенность жизнью',
            name: 'lifeSatisfaction',
            suff: 'ср.знач.',
          },
          {
            title: 'Безопасность',
            name: 'homicideRate',
            suff: 'соотн.',
          },
          {
            title: 'Баланс между работой и личной жизнью',
            name: 'workingBalance',
            suff: '%',
          },
        ],
      };
    },

    methods: {
      getIndicatorValue(name) {
        if (this.indicatorValues)
          return this.indicatorValues[name] ? this.formatNumber(this.indicatorValues[name]) : '-';

        return '-';
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators {
    margin-top: 32px;
    &__list {
      padding: 0 8px;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 892px) {
        justify-content: space-between;
      }
      gap: 38px;
    }
    &__item {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: calc((100% / 4) - 32px);
      @media (max-width: 892px) {
        max-width: calc((100% / 3) - 32px);
      }
      @media (max-width: 580px) {
        max-width: calc((100% / 2) - 32px);
      }
      @media (max-width: 460px) {
        max-width: 100%;
      }
      width: 100%;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      p {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }
      strong {
        margin-top: 20px;
        margin-bottom: 9px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
      span {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }

      &-wrapper {
        display: flex;

        .suff {
          margin-top: 20px;
          margin-bottom: 9px;
          margin-left: 5px;
          line-height: 22px;
        }
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 113.9%;
    color: #000000;
  }

  .indicators__item .analytics-source-text {
    font-size: 10px;
    text-align: center;
  }
</style>
