import axios from 'axios';

export default {
  loadRoles: async function (request) {
    return await axios.get('api/role/search', { params: request });
  },
  loadPermissions: async function (data) {
    return await axios.get('api/permission/search', { params: data });
  },
  loadPermissionAccessMatrix: async function (moduleId) {
    return axios.get(`api/permission/accessmatrix/${moduleId}`);
  },
  updatePermissionAccessMatrix: async function (moduleId, changedMatrix) {
    /*         console.log(changedMatrix); */
    return axios.post(`/api/permission/accessmatrix/${moduleId}`, changedMatrix);
  },
  getModules: function () {
    return axios.get('/api/permission/modules');
  },
  loadPermissionsForMatrix: async function (moduleId) {
    return await axios.get(`api/permission/searchMatrix/${moduleId}`);
  },
};
