import IssueCard from '../views/IssueCard';
import IssueEapCard from '../views/IssueEapCard';
import Issues from '../views/Issues';
import IssuesEap from '../views/IssuesEap';

export const routes = [
  {
    path: '/tasks/issues/',
    name: 'Issues',
    component: Issues,
  },
  {
    path: '/tasks/issues/:action/:id',
    name: 'IssueCard',
    component: IssueCard,
  },
  {
    path: '/tasks/eap/',
    name: 'IssuesEap',
    component: IssuesEap,
  },
  {
    path: '/tasks/eap/:action/:id',
    name: 'IssueEapCard',
    component: IssueEapCard,
  },
];
