<template>
  <!-- Компонент удобно использовать в сочетании с миксином modalInfo -->
  <section>
    <ModalComponent v-model="isOpenModal" headType="redesigned" @close="closeModal" margin="mb-8">
      <template #header>
        <div class="modal__title">
          <IconComponent :name="modalIconName" />
          {{ titleModal }}
        </div>
      </template>
      <p :class="`modal__subtitle ${modalIconName && 'ml-48'}`" v-if="textModal">{{ textModal }}</p>
      <!-- блок и props используется, если необходимо сохранить исходное форматирование текста-->
      <div v-if="textModalPreWrap" class="modal__title_pre-wrap">
        {{ textModalPreWrap }}
      </div>
      <slot />
    </ModalComponent>
  </section>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ModalComponent from '@/components/modals/ModalComponent';

  export default {
    components: {
      ModalComponent,
      IconComponent,
    },
    emits: ['update:modelValue', 'closeModal'],
    props: {
      modelValue: Boolean,
      titleModal: {
        type: String,
        default: '',
      },
      textModal: {
        type: String,
        default: '',
      },
      textModalPreWrap: {
        type: String,
        default: '',
      },
      modalIconName: {
        type: String,
        validator: (value) => ['acceptIcon', 'errorIcon'].includes(value),
      },
    },
    computed: {
      isOpenModal: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
    methods: {
      closeModal() {
        this.$emit('closeModal');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    &__title {
      display: flex;
      gap: 16px;
      font: $fira-32;
      font-weight: 500;

      &_pre-wrap {
        white-space: pre-wrap;
      }
    }
  }

  .ml-48 {
    margin-left: 48px;
  }
</style>
