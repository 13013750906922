<template>
  <div class="trade-indicator">
    <div class="trade-indicator__name">{{ name }}</div>

    <div class="trade-indicator__value-header" v-if="hasCurrentValue">
      <b class="trade-indicator__current-value">{{ formatCurrency(currentValue) }}</b>
      <span class="trade-indicator__unit">{{ unit }}</span>
      <IconComponent
        class="trade-indicator__icon"
        :class="{
          'trade-indicator__icon_dir_up': isValueUp,
          'trade-indicator__icon_dir_down': isValueDown
        }"
        name="arrow_down2"
      />
    </div>
    <div v-else>
      Нет данных
    </div>

    <div
      v-if="hasPreviousPercent || hasPreviousValue"
      class="trade-indicator__diff-value"
      :class="{
        'trade-indicator__up': isValueUp,
        'trade-indicator__down': isValueDown
      }"
    >
      <template v-if="isValueUp">+</template>
      <!-- если есть предыдущее абсолютное значение, то отображаем абсолютную разницу -->
      <template v-if="hasPreviousValue">
        {{ formatCurrency(valueDiff) }} {{ unit }}
        <!-- Подсказка по изменению должна появляться только тогда, когда параметр меняет свой знак на противоположный, т.е. с + на - или наоборот -->
        <Tooltip :triggers="['click']" @click.stop v-if="needShowParameterChange">
          <slot name="tooltipContent">
            <div class="trade-indicator__help">
              Произошло изменение знака {{ name }}:
              <b class="trade-indicator__help-value">{{ formatCurrency(previousValue) }} {{ unit }}</b>
              в АППГ
            </div>
          </slot>
        </Tooltip>
      </template>
      <!-- иначе отображаем относительную разницу в процентах -->
      <template v-else>{{ formatPercent(previousPercent) }}%</template>
    </div>

    <div v-if="rating" class="trade-indicator__rating">{{ rating }}</div>

    <button v-if="hasClickListener" class="trade-indicator__button" :class="{ 'trade-indicator__button--is-active': isSelected }" type="button" @click="onClick">
      <span class="sr-only">Больше информации</span>
    </button>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Tooltip from '@/common/ui/Tooltip.vue';

  export default {
    name: 'TradeIndicator',

    mixins: [text],

    components: {
      IconComponent,
      Tooltip
    },

    props: {
      name: {
        type: String,
        required: true
      },

      unit: {
        type: String,
        default: 'млн $'
      },

      rating: {
        type: String,
        required: false
      },

      currentValue: {
        type: Number,
        required: true
      },

      previousValue: {
        type: Number,
        required: false
      },

      previousPercent: {
        type: Number,
        required: true
      },

      divider: {
        type: Number,
        default: 1000000
      },

      isSelected: {
        type: Boolean,
        default: false
      }
    },

    emits: ['click'],

    computed: {
      hasCurrentValue() {
        return this.currentValue !== undefined && this.currentValue !== null;
      },

      hasPreviousValue() {
        return this.previousValue !== undefined && this.previousValue !== null;
      },

      hasPreviousPercent() {
        return this.previousPercent !== undefined && this.previousPercent !== null;
      },

      valueDiff() {
        return this.currentValue - this.previousValue;
      },

      diffSign() {
        const value = this.hasPreviousValue ? this.valueDiff : this.previousPercent;
        return Math.sign(value);
      },

      isValueUp() {
        return this.diffSign === 1;
      },

      isValueDown() {
        return this.diffSign === -1;
      },

      hasClickListener() {
        return !!this.$.vnode.props?.onClick;
      },

      needShowParameterChange() {
        return !this.haveSameSign(this.currentValue, this.previousValue);
      }
    },

    methods: {
      formatCurrency(value) {
        return this.formatNumber(value / this.divider, 0);
      },

      formatPercent(value) {
        return this.formatNumber(value, 1);
      },

      haveSameSign(a, b) {
        return a < 0 === b < 0;
      },

      onClick() {
        this.$emit('click');
      }
    },
  }
</script>

<style lang="scss" scoped>
  .trade-indicator {
    position: relative;
    padding: 16px 12px;
    border: 1px solid $gray-light;
    display: grid;
    align-content: start;
    gap: 8px;
    font: 12px/1 'Fira Sans', sans-serif;
    border-radius: 4px;
  }

  .trade-indicator__name {
    font: $fira-14-M;
  }

  .trade-indicator__rating {
    font: $fira-12;
    color: $dark-grey-1;
  }

  .trade-indicator__value-header {
    display: flex;
    align-items: baseline;
    gap: 4px;
    white-space: nowrap;
  }

  .trade-indicator__current-value {
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    white-space: nowrap;
  }

  .trade-indicator__diff-value {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 12px;
  }

  .trade-indicator__down {
    color: $red-alert;
  }

  .trade-indicator__up {
    color: $approval-text;
  }

  .trade-indicator__icon {
    position: relative;
    z-index: 0;
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    color: $white;
    border-radius: 50%;
    cursor: default;
  }

  .trade-indicator__icon_dir_up {
    transform: rotate(0.5turn);
    background-color: $approval-text;
  }

  .trade-indicator__icon_dir_down {
    background-color: $red-alert;
  }

  .trade-indicator__help {
    font: $fira-14;
  }

  .trade-indicator__help-value {
    font: $fira-14-M;
    white-space: nowrap;
  }

  .trade-indicator__more-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
  }

  .trade-indicator__button {
    all: unset;
    position: absolute;
    inset: -1px;
    border: 1px solid transparent;
    border-radius: inherit;
    color: $blue;
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover,
    &:focus-visible {
      border-color: $blue-3;
    }
  }

  .trade-indicator__button--is-active {
    border-color: $blue;
  }
</style>
