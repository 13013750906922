<template>
  <section class="statisticByMeasuresCard">
    <div>
      <h5 class="statisticByMeasuresCard__title">
        {{ getTitle }}
      </h5>
      <ul class="statisticByMeasuresCard__list">
        <li v-for="(value, key, index) in diagramByIndustryData" :key="key" class="statisticByMeasuresCard__item">
          <div class="statisticByMeasuresCard__color" :style="{ 'background-color': chartColors[index] }" />
          <p class="statisticByMeasuresCard__quantity">{{ value }}</p>
          <p class="statisticByMeasuresCard__name">{{ key }}</p>
        </li>
      </ul>
    </div>
    <div>
      <h5 class="statisticByMeasuresCard__title">Топ-5 стран по количеству барьеров:</h5>
      <ul class="statisticByMeasuresCard__list">
        <li v-for="(value, key, index) in getSlicedObject" :key="key" class="statisticByMeasuresCard__item">
          <div class="statisticByMeasuresCard__color" :style="{ 'background-color': chartColors[index] }" />
          <p class="statisticByMeasuresCard__quantity">
            {{ value }}
          </p>
          <p class="statisticByMeasuresCard__name">{{ key }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
  import moment from 'moment';

  import globalConstants from '@/common/constants';
  import wordDeclension from '@/common/mixins/wordDeclension';

  import constants from '@/components/ChartComponents/chartConstants';

  import { sliceObject } from '../../../helpers/sliceObject';

  export default {
    mixins: [wordDeclension],
    props: {
      diagramByIndustryData: {
        type: Object,
        required: true,
        default: () => {},
      },
      diagramByCountryData: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        chartColors: constants.colors,
        date: this.$momentFormat(moment(), globalConstants.DATE_FORMATS.DD_MMMM_YYYY),
      };
    },
    computed: {
      getTitle() {
        const barrierSum = Object.values(this.diagramByIndustryData).reduce((res, item) => res + item, 0);
        return `По состоянию на ${this.date} года действует ${barrierSum} ${this.wordDeclension(barrierSum, [
          'барьеров',
          'барьера',
          'барьеров',
        ])}`;
      },
      getSlicedObject() {
        return sliceObject(this.diagramByCountryData, 5);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/theme/default/global/mixin.scss';

  .statisticByMeasuresCard {
    width: 416px;
    padding: 16px;
    @include borderAndBgCard();
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $tablet) {
      max-width: 848px;
      width: 100%;
      flex-direction: row;
    }

    @media (max-width: $laptop) {
      flex-direction: column;
    }

    &__title {
      font: $fira-20;
      letter-spacing: -0.4px;
      margin-bottom: 16px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: hidden;
    }

    &__item {
      display: grid;
      grid-template-columns: 12px 21px auto;
      gap: 8px;
      align-items: center;
    }

    &__color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &__quantity,
    &__name {
      width: max-content;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: -0.3px;
      font-family: 'Fira Sans';
    }

    &__name {
      max-width: 85%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__quantity {
      justify-self: end;
      font-weight: 500;
    }
  }
</style>
