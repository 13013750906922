<template>
  <div>
    <template v-if="summary">
      <div class="indicators__header">
        <h2 class="title title--h3">Горячие показатели торговли России с миром</h2>
        <div class="analytics-source-text d-flex align-items-center">
          <div class="indicators__analytics-source">
            <p>Источник:</p>
            <p>ФТС России</p>
          </div>
          <div class="indicators__select-wrapper">
            <Multiselect
              placeholder="Период"
              label="text"
              :searchable="true"
              v-model="currentYear"
              valueProp="id"
              track-by="text"
              :options="yearList"
              :canClear="false"
              noResultsText="Ничего не найдено."
              noOptionsText="Список пуст."
              @select="loadData"
            />
          </div>
        </div>
      </div>

      <TradeIndicatorsList justifyContent="start" class="indicators__list">
        <TradeIndicator
          name="Товарооборот"
          :currentValue="summary[indicatorValue.TOTAL_VALUE]"
          :previousPercent="summary[indicatorPreviousPercentValue.TOTAL_VALUE]"
          unit="млн $"
          :divider="1000000"
        />
        <TradeIndicator
          name="Импорт"
          :currentValue="summary[indicatorValue.IMPORT_VALUE]"
          :previousPercent="summary[indicatorPreviousPercentValue.IMPORT_VALUE]"
          unit="млн $"
          :divider="1000000"
        />
        <TradeIndicator
          name="Экспорт"
          :currentValue="summary[indicatorValue.EXPORT_VALUE]"
          :previousPercent="summary[indicatorPreviousPercentValue.EXPORT_VALUE]"
          unit="млн $"
          :divider="1000000"
        />
        <TradeIndicator
          name="HHЭ"
          :currentValue="summary[indicatorValue.EXPORT_NON_ENERGY_VALUE]"
          :previousPercent="summary[indicatorPreviousPercentValue.EXPORT_NON_ENERGY_VALUE]"
          unit="млн $"
          :divider="1000000"
        />
        <TradeIndicator
          name="Сальдо"
          :currentValue="summary[indicatorValue.BALANCE]"
          :previousPercent="summary[indicatorPreviousPercentValue.BALANCE]"
          :previousValue="summary[indicatorPreviousValue.BALANCE]"
          unit="млн $"
          :divider="1000000"
        />
      </TradeIndicatorsList>
    </template>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import Api from '../api';
  import TradeIndicator from './InternationalTrade/indicators/TradeIndicator.vue';
  import TradeIndicatorsList from './InternationalTrade/indicators/TradeIndicatorsList.vue';

  export default {
    name: 'WorldHotIndicator',
    mixins: [text],
    components: {
      TradeIndicator,
      TradeIndicatorsList,
      Multiselect,
    },
    data() {
      return {
        summary: null,
        yearList: [],
        currentYear: null,
        indicatorValue: Constants.indicator.value,
        indicatorPreviousValue: Constants.indicator.previousValue,
        indicatorPreviousPercentValue: Constants.indicator.previousPercentValue,
      };
    },
    created() {
      Api.getYearList(true, true).then((response) => {
        this.yearList = response.data;
        this.currentYear = this.yearList.slice(-1)[0].id;
        this.loadData();
      });
    },
    methods: {
      loadData() {
        this.summary = null;
        Api.getExportProductAll(this.currentYear).then((response) => {
          this.summary = response.data;
        });
      },
    },
    watch: {},
  };
</script>

<style scoped lang="scss">
  @import '../styles/indicators-wrap.scss';
  @import '@/assets/theme/default/title.scss';

  .indicators {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 16px;
    }

    &__analytics-source {
      width: 100%;
      margin-right: 10px;
      p {
        font: $fira-12;
        color: $base;
      }
    }
    &__select-wrapper {
      width: 100%;
      min-width: 140px;
    }

    &__list {
      margin-bottom: 24px;
    }
  }
</style>

<style lang="scss">
  .indicators__select-wrapper {
    .multiselect-single-label {
      padding-right: 20px;
    }
  }
</style>
