import axios from 'axios';

export default {
  getFiles: function (params) {
    return axios.get('api/referenceInfo/GetReferenceInfoFiles', { params });
  },
  createFile: function (newFile) {
    return axios.post('api/referenceInfo/Create', newFile);
  },
  changeName: function (params) {
    return axios.patch('api/referenceInfo/ChangeName', {}, { params });
  },
  changeVisibility: function (params) {
    return axios.patch('api/referenceInfo/ChangeVisability', {}, { params });
  },
  getArchiveFiles: function (params) {
    return axios.get('api/referenceInfo/GetArchiveFiles', { params });
  },
  moveToArchive: function (id) {
    return axios.patch('api/referenceInfo/MoveToArchive', {}, { params: { id } });
  },
  restoreFromArchive: function (id) {
    return axios.patch('api/referenceInfo/RestoreFromArchive', {}, { params: { id } });
  },
  removeFromArchive: function (id) {
    return axios.patch('api/referenceInfo/RemoveFromArchive', {}, { params: { id } });
  },
  getHistory: function (params) {
    return axios.get('api/referenceInfo/GetHistory', { params });
  },
};
