<template>
  <div class="map-scale-quantity">
    <div class="map-scale-quantity__name" v-if="name" v-html="name"></div>
    <ul v-if="squareBar.length" class="map-scale-quantity__scale-bar">
      <li v-for="(item, index) in squareBar" :key="index">
        <p class="map-scale-quantity__color" :style="`background-color: ${item.color}`"></p>
        <p class="map-scale-quantity__value">{{ item.value }}</p>
      </li>
    </ul>
    <div v-else class="map-scale-quantity__scale">
      <div class="map-scale-quantity__scale-line"></div>
      <div class="map-scale-quantity__scale-count" v-if="startCount || averageCount || totalCount">
        <span class="start" v-if="startCount">{{ startCount }}</span>
        <span class="average" v-if="averageCount && +averageCount < +totalCount">{{ averageCount }}</span>
        <span class="total" v-if="totalCount">{{ totalCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MapScaleQuantity',
    props: {
      name: {
        type: String,
        default: '',
      },
      startCount: {
        type: [Number, String],
        default: '0',
      },
      averageCount: {
        type: [Number, String],
        default: '',
      },
      totalCount: {
        type: [Number, String],
        default: '0',
      },
      squareBar: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style lang="scss" scoped>
  .map-scale-quantity {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;

    &__name {
      font: $fira-16-20;
    }

    &__scale {
      width: 209px;
      margin-top: 4px;

      &-line {
        background: linear-gradient(90deg, #e3e3e3 0%, #99ceff 23.97%, #2e9cff 50.23%, #0363be 76.94%, #00386b 103.77%);
        height: 10px;
      }

      &-count {
        font: $fira-14;
        margin-top: 4px;
        height: 16px;
        position: relative;

        span {
          font: inherit;
          position: absolute;
          top: 0;
        }
        .start {
          left: 0;
          transform: translateX(-50%);
        }
        .average {
          left: 38px;
        }
        .total {
          right: 0;
          transform: translateX(50%);
        }
      }

      &-bar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;

        li {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }

    &__color {
      width: 16px;
      height: 16px;
      display: inline-block;
    }

    &__value {
      font: $fira-14;
      color: $black-st;
    }

    @media (min-width: $tablet) {
      .exportPdf & {
        .map-scale-quantity__color {
          margin-bottom: -15px;
        }
      }
    }

    @media (max-width: $laptop) {
      flex-direction: column;
      gap: 16px;

      &__scale {
        margin-top: 0;
      }
    }
  }
</style>
