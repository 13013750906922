/**
 * Функция позволяет разрезать (сократить) объект по аналогии с методом Array.slice
 * @param {Object} object - Объект типа ключ-значения, который необходимо разрезать (сократить)
 * @param {Number, Null} quantity - до которого элемента сократить объект, либо null - если до конца объекта
 * @param {Number} start - с какого элемента обрезать объект (по умолчанию 0)
 * @returns сокращенный объект в соответствии с параметрами, которые указаны в аргументах
 */
export const sliceObject = (object, quantity, start = 0) => {
  let slicedArray = [];

  if (quantity) {
    slicedArray = Object.entries(object).slice(start, quantity);
  } else {
    slicedArray = Object.entries(object).slice(start);
  }

  return slicedArray.reduce((result, array) => {
    result[array[0]] = object[array[0]];
    return result;
  }, {});
};
