import Constants from '@/common/constants.js';

import foiv from '../views/foiv.vue';
import home from '../views/home.vue';
import intefaceSelected from '../views/intefaceSelected.vue';
import Widgets from '../views/Widgets.vue';
import zagran from '../views/zagran.vue';

import store from '@/store';

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Widgets,
    beforeEnter(to, from, next) {
      const role = store.state?.auth?.oidc?.user?.profile?.role;
      if (role === Constants.userRoleNames.foreignEmployeeRole) {
        return next({ name: 'foreign-office' });
      } else if (role === Constants.userRoleNames.moduleAdministratorVES) {
        return next({ name: 'HeatMapForeignEconomicRelations' });
      } else if (role === Constants.userRoleNames.subjectRF) {
        // На бэке защитили запрос с чужим регионом - если регион не входит в доступные, то вылетает авторизация
        const regionRfIds = store.state?.auth?.oidc?.profile?.employee.regionRfIds || [];
        console.log(regionRfIds);
        return next({ name: 'PassportForeignEconomicRelations', query: { regionId: regionRfIds[0] } });
      } else {
        return next();
      }
    },
  },
  {
    path: '/widgets',
    name: 'Widgets',
    component: Widgets,
  },
  {
    path: '/home',
    name: 'sekretar',
    component: home,
  },
  {
    path: '/home/country/:id',
    name: 'intefaceSelected',
    component: intefaceSelected,
  },
  {
    path: '/home/zagran/',
    name: 'zagran',
    component: zagran,
  },
  {
    path: '/home/foiv/',
    name: 'foiv',
    component: foiv,
  },
  {
    path: '/foreign-office/',
    name: 'foreign-office',
    component: () => import('../views/ForeignOffice.vue'),
  },
];
