<template>
  <form class="form-grid mt-40" :novalidate="true" @submit.prevent="save">
    <div class="form-grid__item">
      <Field label="Страна" required>
        <ComponentInput
          placeholder="Укажите страну"
          :select2Settings="select2Settings"
          :select2Options="countryList"
          v-model="item.countryId"
          v-on:select2OnSelect="changeCountry"
          type="select2"
          required
        />
      </Field>
    </div>

    <div class="form-grid__item">
      <Field label="Наименование поручения" required>
        <ComponentInput
          placeholder="Напишите текст вашего поручения"
          v-model="item.assignmentName"
          type="textarea"
          required
          mod="rectangular"
        />
      </Field>
    </div>

    <div class="form-grid__item form-grid form-grid--gutter-medium">
      <div class="form-grid__item form-grid__item--half">
        <Field label="Дата поступления" required>
          <dateComponent
            v-model="item.receiptDate"
            :clearable="true"
            required
            modClass="redesigned"
          />
        </Field>
      </div>

      <div class="form-grid__item form-grid__item--half">
        <Field label="Корреспондент" required>
          <ComponentInput
            placeholder="Выберите"
            :select2Settings="select2Settings"
            :select2Options="computedCorrespondentList"
            v-model="correspondentModel"
            type="select2"
            required
          />
        </Field>
      </div>

      <div class="form-grid__item form-grid__item--half">
        <Field label="Срок" required>
          <dateComponent
            v-model="item.deadline"
            :clearable="true"
            required
            modClass="redesigned"
          />
        </Field>
      </div>

      <div class="form-grid__item form-grid__item--half">
        <Field label="Ответственный" required>
          <ComponentInput
            placeholder="Выберите"
            :select2Settings="select2SettingsMultiple"
            :select2Options="computedResponsibleList"
            v-model="responsibleEmployeesModel"
            type="select2"
            required
            multiple
          />
        </Field>
      </div>
    </div>

    <div class="form-grid__item">
      <Field label="Связанное мероприятие/объект">
        <div class="form-grid form-grid--gutter-x-small">
          <div class="form-grid__item">
              <Multiselect
                v-model="item.eventTypeId"
                :options="eventTypeList"
                valueProp="id"
                label="text"
                placeholder="Выберите тип объекта"
                @select="item.eventId = null"
                noResultsText="Ничего не найдено"
                noOptionsText="Список пуст"
              />
          </div>
          <div class="form-grid__item">
            <Multiselect
              placeholder="Укажите связанное мероприятие"
              v-model="item.eventId"
              :options="eventFilterList"
              valueProp="id"
              label="text"
              noResultsText="Ничего не найдено"
              noOptionsText="Список пуст"
            />
          </div>
        </div>
      </Field>
    </div>

    <div class="form-grid__item form-grid__item--half">
      <Field label="Тип поручения" required>
        <ComponentInput
          placeholder="Выберите тип"
          :select2Settings="select2Settings"
          :select2Options="typeList"
          v-model="item.typeId"
          type="select2"
          required
        />
      </Field>
    </div>

    <div class="form-grid__item form-grid__item--half">
      <Field label="Приоритет" required>
        <ComponentInput
          placeholder="Выберите приоритет"
          :select2Settings="select2Settings"
          :select2Options="urgencyList"
          v-model="item.urgencyId"
          type="select2"
          required
        />
      </Field>
    </div>

    <div class="form-grid__item">
      <ButtonStock :title="item.id > 0 ? 'Сохранить поручение' : 'Создать поручение'" />
    </div>
  </form>
</template>

<script>
  // Additional
  import Multiselect from '@vueform/multiselect/src/Multiselect';
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import Field from '@/common/components/Field.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/assignment';

  export default {
    name: 'EapAssignmentCardAdd',

    components: {
      ButtonStock,
      dateComponent,
      ComponentInput,
      Field,
      Multiselect
    },

    props: {
      nn: Number,
      id: Number,
      countryId: {
        type: Number,
        default: null,
      },
      projectId: {
        type: Number,
        default: null,
      },
    },

    mixins: [security],

    setup: () => ({ v$: useVuelidate() }),

    validations() {
      return {
        item: {},
      };
    },

    data() {
      return {
        item: {},

        select2Settings: Constants.select2Settings,
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        countryList: [],
        eventList: [],
        urgencyList: [],
        typeList: [],
        correspondentList: [],
        responsibleList: [],

        eventTypeList: [
          { id: 0, text: 'Мероприятие' },
          { id: 1, text: 'Проект' },
          { id: 2, text: 'МПК' },
        ],
      };
    },

    async created() {
      const canEdit = await this.checkPermission(Constants.Permissions.EapAssignmentEdit)
        .then((response) => response?.data)
        .catch(() => false);

      if (!canEdit) {
        this.$emit('close');
        return;
      }

      Utils.loadSelectOptions('countryList', this.countryList);
      Utils.loadSelectOptions('eapAssignmentTypeList', this.typeList);
      Utils.loadSelectOptions('eapAssignmentUrgencyList', this.urgencyList);

      this.fetchCorrespondents();
      this.fetchResponsibles();

      this.init();
    },

    methods: {
      init() {
        if (this.v$ && this.v$.$error) {
          this.v$.$reset();
        }
        this.item = {
          countryId: this.countryId > 0 ? this.countryId : null,
          eventId: this.projectId > 0 ? this.projectId : null,
          eventTypeId: this.projectId > 0 ? 1 : null,
          typeId: null,
          urgencyId: null,
          correspondent: null,
          responsibleEmployees: null
        };
        this.eventList = [];

        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
            if (this.item.countryId > 0) {
              this.changeCountry(true);
            }
          });
        } else if (this.countryId && this.projectId) {
          this.changeCountry(true);
        }
      },

      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.update(this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && (response.data.id || this.item.id > 0)) {
              this.$emit('save', true);
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },

      async changeCountry(notEmpty) {
        this.eventList = [];
        if (!notEmpty) this.item.eventId = null;

        await this.fetchEventList();
      },

      async fetchEventList() {
        if (!(this.item.countryId > 0)) {
          return;
        }
        this.eventList = await API.getEventForList({ countryId: this.item.countryId })
          .then((response) => response?.data)
          .catch(() => []);
      },

      async fetchCorrespondents() {
        this.correspondentList = await API.getCorrespondentsCandidates({
          countryId: this.item?.countryId ?? null
        })
          .then((response) => response?.data)
          .catch(() => [])
      },

      async fetchResponsibles() {
        this.responsibleList = await API.getResponsibleCandidates({
          countryId: this.item?.countryId ?? null
        })
          .then((response) => response?.data)
          .catch(() => [])
      },

      mapPersonData(person) {
        const position = person.position ? `(${person.position})` : '';
        return {
          id: person.id,
          text: person.fullName + ' ' + position,
        }
      }
    },

    computed: {
      eventFilterList() {
        var list = [];
        if (this.eventList) {
          this.eventList
            .filter((x) => x.eventType == this.item.eventTypeId)
            .map((x) => ({ id: x.eventId, text: x.eventName }))
            .forEach(function (el) {
              list.push(el);
            });
        }

        return list;
      },

      computedCorrespondentList() {
        return this.correspondentList.map(this.mapPersonData);
      },

      computedResponsibleList() {
        return this.responsibleList.map(this.mapPersonData);
      },

      correspondentModel: {
        get() {
          return this.item?.correspondent?.id;
        },

        set(id) {
          Object.assign(this.item, {
            correspondent: { id }
          });
        }
      },

      responsibleEmployeesModel: {
        get() {
          return this.item?.responsibleEmployees?.map?.((item) => item.id);
        },
        set(ids) {
          Object.assign(this.item, {
            responsibleEmployees: ids.map((id) => ({ id }))
          })
        }
      }
    },

    watch: {
      nn() {
        this.init();
      },

      'item.countryId'() {
        this.fetchCorrespondents();
        this.fetchResponsibles();
      }
    },
  };
</script>

<style lang="scss" scoped>
  .form-grid {
    display: grid;
    grid-row-gap: var(--form-grid-gutter, 40px);
    grid-column-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  .form-grid--gutter-large {
    --form-grid-gutter: 40px;
  }

  .form-grid--gutter-medium {
    --form-grid-gutter: 24px;
  }

  .form-grid--gutter-small {
    --form-grid-gutter: 16px;
  }

  .form-grid--gutter-x-small {
    --form-grid-gutter: 8px;
  }

  .form-grid__item {
    grid-column: span 2;
  }

  .form-grid__item--half {
    grid-column: span 1;

    @media (max-width: $mobile) {
      grid-column: span 2;
    }
  }

  .form-grid__group {
    display: grid;
    grid-gap: 8px;
  }
</style>
