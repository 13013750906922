import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get(`/api/healthcare/search`, { params: request });
  },
  find: function (id) {
    return axios.get(`/api/healthcare/find?id=${id}`);
  },
  update: function (id, request) {
    if (id > 0) return axios.put(`/api/healthcare/update`, request);
    else return axios.post(`/api/healthcare/create`, request);
  },
  delete: function (id) {
    return axios.delete(`/api/healthcare/delete?id=${id}`);
  },
  getTemplate: function () {
    return Utils.downloadFile('/api/healthcare/template', 'HealthcareTemplate.xlsx');
  },
  uploadFile: function (file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/healthcare/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  yearList: function (id) {
    return axios.get(`/api/healthcare/yearList?countryId=${id}`);
  },
};
