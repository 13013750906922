import { NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME, NAVIGATOR_PRODUCT_GROUPS_MUTATIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export async function fetchListGroups(context, params) {
  context.state.loadPage = true;
  return await request.call(
    this,
    context,
    {
      url: links.navigator.productGroups.search,
      mutate: NAVIGATOR_PRODUCT_GROUPS_MUTATIONS_NAME.setListGroups,
      name: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.fetchListGroups,
    },
    params,
  );
}

export async function fetchMoreListGroups(context, params) {
  context.state.loadMore = true;
  return await request.call(
    this,
    context,
    {
      url: links.navigator.productGroups.search,
      mutate: NAVIGATOR_PRODUCT_GROUPS_MUTATIONS_NAME.addListGroups,
      name: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.fetchMoreListGroups,
    },
    params,
  );
}

export async function findGroup(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.productGroups.find,
      name: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.findGroup,
      pathParams: ['id'],
    },
    params,
  );
}

export async function deleteGroup(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.productGroups.CRUD,
      name: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.deleteGroup,
      method: 'delete',
      pathParams: ['id'],
    },
    params,
  );
}

export async function createGroup(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.productGroups.CRUD,
      name: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.createGroup,
      method: 'post',
    },
    params,
  );
}

export async function updateGroup(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.navigator.productGroups.CRUD,
      name: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.updateGroup,
      method: 'put',
      pathParams: ['id'],
      deleteParamsInPath: false,
    },
    params,
  );
}
