<template>
  <div class="home container-page">
    <div v-if="isLoadWidget">
      <div class="vue-grid-layout row">
        <template v-for="(el, key) in widgetListFilter" :key="el.widgetTypeId">
          <div
            class="vue-grid-item"
            :class="getClassWidth(el.widgetTypeId)"
            :style="key == 0 && !isOneCountry ? 'min-width:330px' : ''"
          >
            <div class="vue-grid-item row-1" v-if="key == 0 && !isOneCountry">
              <WidgetStock title="Интерфейс по стране" :show-close="false">
                <div class="widget-custom-in">
                  <SelectComponent
                    class="mr-6"
                    placeholder="Выберите страну"
                    :options="countryList"
                    v-model="countryId"
                  />
                  <ButtonStock @click="jumpToSelectedCountry(countryId)" title="Перейти" />
                </div>
              </WidgetStock>
            </div>

            <div :class="getClassHeight(el.widgetTypeId) + getClassWidget(el.widgetTypeId)">
              <WidgetStock :title="getName(el)" @on-gear="gearMethod" @on-close="closeMethod(el)">
                <component :is="getComponent(el)" :countryId="countrySelected"></component>
              </WidgetStock>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  import SelectComponent from '@/common/components/selectComponent.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  // Additional
  import WidgetStock from '@/components/widgets/WidgetStock.vue';

  import API from '../api/widget';

  export default {
    name: 'Widgets',
    components: {
      WidgetStock,
      ButtonStock,
      SelectComponent,
      Widget1: defineAsyncComponent(() => import('../components/Widget1.vue')),
      Widget2: defineAsyncComponent(() => import('../components/Widget2.vue')),
      Widget3: defineAsyncComponent(() => import('../components/Widget3.vue')),
      Widget4: defineAsyncComponent(() => import('../components/Widget4.vue')),
      Widget5: defineAsyncComponent(() => import('../components/Widget5.vue')),
      Widget6: defineAsyncComponent(() => import('../components/Widget6.vue')),
      Widget7: defineAsyncComponent(() => import('../components/Widget7.vue')),
      Widget8: defineAsyncComponent(() => import('../components/Widget8.vue')),
      Widget9: defineAsyncComponent(() => import('../components/Widget9.vue')),
      Widget10: defineAsyncComponent(() => import('../components/Widget10.vue')),
      Widget11: defineAsyncComponent(() => import('../components/Widget11.vue')),
      Widget12: defineAsyncComponent(() => import('../components/Widget12.vue')),
      Widget13: defineAsyncComponent(() => import('../components/Widget13.vue')),
      // Widget14: defineAsyncComponent(() => import("../components/Widget14.vue")),
      Widget15: defineAsyncComponent(() => import('../components/Widget15.vue')),
      Widget16: defineAsyncComponent(() => import('../components/Widget16.vue')),
      Widget17: defineAsyncComponent(() => import('../components/Widget17.vue')),
      Widget18: defineAsyncComponent(() => import('../components/Widget18.vue')),
      Widget19: defineAsyncComponent(() => import('../components/Widget19.vue')),
    },
    mixins: [security],
    data() {
      return {
        isNeedSelectCountry: false,
        isOneCountry: false,
        countrySelected: null,
        countryId: 0,
        countryList: [{ id: 0, text: 'Выберите страну', selected: true }],
        isLoadWidget: false,
        widgetList: [],

        layout: [
          // i = id виджета
          { w: 2, h: 2, i: 1, widget: 'widget-transparent' }, //кнопки
          { w: 5, h: 5, i: 2 }, // News
          { w: 7, h: 5, i: 3 }, // Мои задачи
          { w: 5, h: 1, i: 4, hC: 4, titleC: 'Горячие показатели' }, //Аналитика по стране
          { w: 5, h: 1, i: 5, wC: 5, hC: 2, titleC: 'Профили МПК: countryName' }, //профили мпк
          { w: 7, h: 5, i: 6 }, //календарь
          { w: 5, h: 5, i: 7 }, //проекты
          { w: 7, h: 5, i: 8 }, //поручения ЗА
          { w: 7, h: 5, i: 9 }, //поручения мпк
          { w: 7, h: 2, i: 10 }, //Мои мероприятия
          { w: 5, h: 5, i: 11 }, //анализ сми
          { w: 5, h: 5, i: 12 }, //уведомления
          { w: 2, h: 1, i: 13, widget: 'widget-transparent' }, // кнопка Библиотека документов

          { w: 6, y: 12, h: 0, i: 14, widget: 'widget-transparent' }, //Материалы к МПК
          { w: 5, h: 1, wC: 12, hC: 9, i: 15, titleC: 'Горячие показатели' }, //Горячие показатели с графиками
          { w: 12, h: 6, i: 16 }, //Проекты
          { w: 7, h: 5, i: 17 }, //Мониторинг исполнения поручений МПК
          { w: 2, h: 1, i: 18, widget: 'widget-transparent' }, //кнопка Создать мероприятие
          { w: 7, h: 5, i: 19 }, //Задачи на согласование
        ],

        layoutFoiv: [
          { w: 2, h: 2, i: 1 }, //кнопки
          { w: 5, h: 5, i: 2 }, // News
          { w: 7, h: 5, i: 3 }, // Мои задачи
          { w: 5, h: 1, i: 4, hC: 4 }, //Аналитика по стране
          { w: 5, h: 1, i: 5, wC: 5, hC: 2 }, //профили мпк
          { w: 7, h: 5, i: 6 }, //календарь
          { w: 7, h: 5, i: 7 }, //проекты
          { w: 7, h: 5, i: 8 }, //поручения ЗА
          { w: 5, h: 5, i: 9 }, //поручения мпк
          { w: 5, h: 5, i: 11 }, //анализ сми
          { w: 5, h: 5, i: 12 }, //уведомления
          { w: 2, h: 1, i: 13 }, // кнопка Библиотека документов
          { w: 2, h: 1, i: 18 }, //кнопка Создать мероприятие
          { w: 5, h: 1, wC: 12, hC: 9, i: 15 }, //Горячие показатели с графиками
        ],
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.WidgetOnMainForAllCountry).then((response) => {
        this.isNeedSelectCountry = response.data;

        Utils.searchNsi('countryList', {}).then((response) => {
          if (response.data && response.data.length) {
            response.data.forEach((i) => this.countryList.push(i));
            if (response.data.length == 1) {
              this.countrySelected = response.data[0].id;
              this.isOneCountry = true;
            } else if (!this.isNeedSelectCountry) {
              this.countrySelected = response.data[0].id;
              this.countryId = this.countrySelected;
              this.countryList.shift();
            }
          }
        });
      });

      this.loadWidget();
    },
    methods: {
      gearMethod() {
        console.log('Gear');
      },
      closeMethod(el) {
        console.log('Close');
        var options = {
          title: 'Удаление',
          text: 'Вы действительно хотите скрыть этот виджет?',
          confirmButtonText: 'Скрыть',
          cancelButtonText: 'Отмена',
          showCancelButton: true,
          icon: 'warning',
        };
        Constants.alert.fire(options).then((res) => {
          if (res.isConfirmed) {
            API.deactivateWidget(el)
              .then((response) => {
                console.log(response);
                //Constants.alert.fire('Удаление', "Вид", 'success')
                //this.loadPage()
                if (response && response.data)
                  this.widgetList = this.widgetList.filter((x) => x.widgetTypeId != el.widgetTypeId);
                else Constants.alert.fire('Удаление', Constants.commonError, 'error');
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : Constants.commonError,
                  'error',
                );
              });
          }
        });
      },
      jumpToSelectedCountry(id) {
        this.countrySelected = id > 0 ? Number(id) : null;
        //this.$router.push({ name: 'intefaceSelected', params: { id: id } })
      },
      loadWidget() {
        API.search({ onlyActive: true }).then((response) => {
          this.widgetList = response.data;
          this.isLoadWidget = true;

          if (!(this.widgetList?.length > 0)) this.$router.push({ name: 'countryAnalytics' });
        });
      },
      getComponent(el) {
        return 'Widget' + el.widgetTypeId;
      },
      getClassWidth(id) {
        var el = this.currLayout.find((x) => x.i == id);
        if (el != null) return ' col-md-' + (this.countrySelected > 0 && el.wC ? el.wC : el.w);
        //return "col-" + el.w + " row-" + el.h;

        return ' col-md-4';
      },
      getClassHeight(id) {
        var el = this.currLayout.find((x) => x.i == id);
        if (el != null) return ' row-' + (this.countrySelected > 0 && el.hC ? el.hC : el.h);
        //return "col-" + el.w + " row-" + el.h;

        return ' row-1';
      },
      getClassWidget(id) {
        var el = this.layout.find((x) => x.i == id);
        //console.log(el, id, "----- getClassWidget");
        if (el != null && el.widget) {
          //console.log(el.widget);
          return ' ' + el.widget;
        }

        return ' ';
      },
      getName(item) {
        if (this.countrySelected > 0) {
          var el = this.layout.find((x) => x.i == item.widgetTypeId);
          if (el != null && el.titleC) return el.titleC.replace('countryName', this.countrySelectedName);
        }

        return item.name;
      },
    },
    computed: {
      widgetListFilter() {
        return this.countrySelected != null ? this.widgetList : this.widgetList.filter((x) => x.viewWithoutCountry);
      },
      currLayout() {
        //console.log(this.$store.state.auth?.oidc?.profile?.roleIds);
        if (this.$store.state.auth?.oidc?.profile?.roleIds.some((x) => x == 8 || x == 7)) return this.layoutFoiv;
        return this.layout;
      },
      countrySelectedName() {
        if (this.countrySelected > 0) {
          var el = this.countryList.find((element) => element.id == this.countrySelected);
          return el ? el.text : '';
        }
        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .vue-grid-item {
    margin-bottom: 20px;
  }
  .row-0 {
    height: 60px;
  }
  .row-1 {
    height: 180px;
  }
  .row-2 {
    height: 270px;
  }
  .row-3 {
    height: 360px;
  }
  .row-4 {
    height: 450px;
  }
  .row-5 {
    height: 588px;
  }
  .row-6 {
    height: 720px;
  }
  .row-9 {
    height: 940px;
  }
</style>

<style lang="scss">
  .widget-transparent {
    .widget {
      background: transparent !important;
      box-shadow: none !important;
      border: none !important;
      .widget-header {
        display: none;
      }
    }

    .smartwidget {
      background: transparent !important;
      box-shadow: none !important;
      border: none !important;
      .widget-header {
        display: none;
      }
    }
  }
</style>
