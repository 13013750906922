export const routes = [
  {
    path: '/analyticsimport/exportpotential',
    name: 'ExportPotential',
    component: () => import('../views/ExportPotential'),
  },
  {
    path: '/analyticsimport/exportpotential/edit/:id',
    name: 'ExportPotentialCard',
    component: () => import('../views/ExportPotentialCard'),
  },
  {
    path: '/analyticsimport/exportpotentialservices',
    name: 'ExportPotentialService',
    component: () => import('../views/ExportPotentialService'),
  },
  {
    path: '/analyticsimport/exportpotentialservices/edit/:id',
    name: 'ExportPotentialServiceCard',
    component: () => import('../views/ExportPotentialServiceCard'),
  },
  {
    path: '/analyticsimport/macroeconomicindicators',
    name: 'MacroeconomicIndicators',
    component: () => import('../views/MacroeconomicIndicators'),
  },
  {
    path: '/analyticsimport/FCS',
    name: 'Fcs',
    component: () => import('../views/Fcs'),
  },
  {
    path: '/analyticsimport/Fts',
    name: 'Fts',
    component: () => import('../views/Fts'),
  },
  {
    path: '/analyticsimport/greeneconomy',
    name: 'GreenEconomy',
    component: () => import('../views/GreenEconomy'),
  },
  {
    path: '/analyticsimport/investments',
    name: 'Investments',
    component: () => import('../views/Investments'),
  },
  {
    path: '/analyticsimport/rating',
    name: 'CountryRatings',
    component: () => import('../views/CountryRatings'),
  },
  {
    path: '/analyticsimport/budgetpolicy',
    name: 'BudgetPolicy',
    component: () => import('../views/BudgetPolicy'),
  },
  {
    path: '/analyticsimport/foreigntrade',
    name: 'ForeignTrade',
    component: () => import('../views/ForeignTrade'),
  },
  {
    path: '/analyticsimport/tecworld',
    name: 'TecWorld',
    component: () => import('../views/TecWorld'),
  },
  {
    path: '/analyticsimport/tecrelrussia',
    name: 'TradeEconomicRelRussia',
    component: () => import('../views/TradeEconomicRelRussia'),
  },
  {
    path: '/analyticsimport/citizens',
    name: 'Citizens',
    component: () => import('../views/Citizens'),
  },
  {
    path: '/analyticsimport/finsector',
    name: 'FinancialSector',
    component: () => import('../views/FinancialSector'),
  },
  {
    path: '/analyticsimport/lifeindex',
    name: 'LifeIndex',
    component: () => import('../views/LifeIndex'),
  },
  {
    path: '/analyticsimport/striindex',
    name: 'StriIndex',
    component: () => import('../views/StriIndex'),
  },
  {
    path: '/analyticsimport/bankrate',
    name: 'BankRate',
    component: () => import('../views/BankRate'),
  },
  {
    path: '/analyticsimport/investmentsrussia',
    name: 'InvestmentRussia',
    component: () => import('../views/InvestmentRussia'),
  },
  {
    path: '/analyticsimport/investmentsrussia/view/:id',
    name: 'InvestmentRussiaCard',
    component: () => import('../views/InvestmentRussiaCard'),
  },
  {
    path: '/analyticsimport/realpersonalincome',
    name: 'RealDisposablePersonalIncome',
    component: () => import('../views/RealDisposablePersonalIncome'),
  },
  {
    path: '/analyticsimport/intinvestposition',
    name: 'InternationalInvestmentPosition',
    component: () => import('../views/InternationalInvestmentPosition'),
  },
  {
    path: '/analyticsimport/foreigntradeturnoverworld',
    name: 'ForeignTradeTurnoverWorld',
    component: () => import('../views/ForeignTradeTurnoverWorld'),
  },
  {
    path: '/analyticsimport/healthcare',
    name: 'Healthcare',
    component: () => import('../views/Healthcare'),
  },
  {
    path: '/analyticsimport/healthcare/edit/:id',
    name: 'HealthcareCard',
    component: () => import('../views/HealthcareCard'),
  },
  {
    path: '/analyticsimport/worldprice',
    name: 'Worldprice',
    component: () => import('../views/Worldprice'),
  },
  {
    path: '/analyticsimport/worldprice/edit/:id',
    name: 'WorldpriceCard',
    component: () => import('../views/WorldpriceCard'),
  },
  {
    path: '/analyticsimport/inflation',
    name: 'Inflation',
    component: () => import('../views/Inflation'),
  },
  {
    path: '/analyticsimport/priority-import-and-import-destination',
    name: 'PriorityImportAndImportDestination',
    component: () => import('../views/PriorityImportAndImportDestination'),
  },
  {
    path: '/analyticsimport/unfriendly-country-statuses',
    name: 'UnfriendlyCountryStatuses',
    component: () => import('../views/UnfriendlyCountryStatuses'),
  },
  {
    path: '/analyticsimport/countrycreditrating',
    name: 'CountryCreditRating',
    component: () => import('../views/CountryCreditRating'),
  },
  {
    path: '/analyticsimport/countrymapping',
    name: 'countrymapping',
    component: () => import('../views/countrymapping'),
  },
  {
    path: '/analyticsimport/favortrading',
    name: 'Favortrading',
    component: () => import('../views/Favortrading'),
  },

  {
    path: '/analyticsimport/foreigninvestment',
    name: 'Foreigninvestment',
    component: () => import('../views/Foreigninvestment'),
  },
];
