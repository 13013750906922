<template>
  <div class="country-ranking">
    <div class="country-ranking-title" id="rating-positions">Позиции в мировых рейтингах</div>
    <ul class="indicators__list align-center">
      <li v-for="item in indicatorsList" :key="item.id" class="indicators__item justify-content-between">
        <div class="d-flex flex-column">
          <div class="indicators__item-title">{{ item.title }}</div>
          <div v-if="item.source" class="analytics-source-text">Источник: {{ item.source }}</div>
          <div v-if="item.dateUpdate" class="analytics-source-text">Дата обновления: {{ item.dateUpdate }}</div>
          <div v-if="item.period" class="analytics-source-text">Период: {{ item.period }}</div>
        </div>
        <div class="d-flex flex-column">
          <strong v-if="item.count > 0 || (item.id == 1 && item.count)">
            {{ item.count }} <template v-if="item.id !== 1">место</template>
          </strong>
          <strong v-else> - </strong>
          <div v-if="item.id == 1">
            <div v-if="ratingStyleName == 'green'" class="rating-summary indicators__item-green">Инвестиционный</div>
            <div v-if="ratingStyleName == 'yellow'" class="rating-summary indicators__item-yellow">Спекулятивный</div>
            <div v-if="ratingStyleName == 'red'" class="rating-summary indicators__item-red">Пред дефолтный</div>
          </div>
          <div v-if="item.awards.length" class="award d-flex flex-column">
            <span v-for="(award, idx) in item.awards" :key="idx"> {{ award.count }} место - {{ award.country }} </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';

  import Api from '@/modules/countryAnalyticsNew/api/api';

  export default {
    name: 'CountryRanking',
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        indicatorsList: [
          {
            id: 1,
            title: 'Суверенные рейтинги\n' + '(S&P, Moody`s, Fitch)',
            source: 'Trading Economics',
            dateUpdate: '09.12.2022',
            count: 'Загрузка...',
            awards: [],
          },
          {
            id: 2,
            title: 'Индекс человеческого развития',
            source: 'Всемирный банк',
            period: '2020 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 3,
            title: 'Показатель индекса\n' + 'человеческого капитала',
            source: 'Всемирный банк',
            period: '2018 год',
            count: '23',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 4,
            title: 'Индекс глобальной конкурентоспособности',
            source: 'Trading Economics',
            period: '2019 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 5,
            title: 'Индекс глобальных финансовых рынков',
            source: 'Long Finance',
            period: '2024 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 6,
            title: 'Размер ВВП',
            source: 'Всемирный банк',
            period: '2023 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 7,
            title: 'Индекс развития электронного правительства',
            source: 'Организация объединенных наций',
            period: '2024 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
        ],
        ratingPoints: {
          moody: [
            { value: 3, ratings: ['AAA', 'A', 'Aaa', 'Aa1', 'Aa2', 'Aa3', 'A1', 'A2', 'A3', 'Baa1', 'Baa2', 'Baa3'] },
            {
              value: 2,
              ratings: [
                'Caa1',
                'B+',
                'BB',
                'Caa3',
                'BB-',
                'Ba1',
                'Ba2',
                'Ba3',
                'B1',
                'B2',
                'B3',
                'Caa',
                'CCC-',
                'B',
                'Caa2',
                'B-',
                'BB+',
              ],
            },
            { value: 1, ratings: ['Ca', 'C', 'SD', 'Ca2'] },
          ],
          standardAndPoor: [
            { value: 3, ratings: ['AAA', 'Aaa', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-', 'Baa2'] },
            {
              value: 2,
              ratings: ['BB+', 'BB', 'BB-', 'B+', 'B', 'B-', 'Ba3', 'CCC+', 'CCC', 'CCC-', 'Caa1', 'B3', 'Ba1', 'B2'],
            },
            { value: 1, ratings: ['CC', 'C'] },
          ],
          fitch: [
            { value: 3, ratings: ['AAA', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-'] },
            { value: 2, ratings: ['BB+', 'BB', 'BB-', 'B+', 'B', 'B-', 'CCC'] },
            { value: 1, ratings: ['DDD', 'DD', 'C'] },
          ],
        },
        ratingStyleName: '',
      };
    },
    created() {
      this.loadRatings();
    },
    methods: {
      loadRatings() {
        Api.getCountryRatings(this.countryId)
          .then((response) => {
            this.updateIndicators(response.data);
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      updateIndicators(data) {
        console.log(data);
        const noData = '-';
        let ind = this.indicatorsList.find((x) => x.id === 1);
        ind.count = `${data.standardAndPoor || noData} / ${data.moody || noData} / ${data.fitch || noData}`;
        ind.dateUpdate = moment(data.ratingUpdateDate).format('DD.MM.YYYY');
        ind = this.indicatorsList.find((x) => x.id === 2);
        ind.count = data.hdiRank;
        ind.awards[0].country = data.hdiRankWinner;
        ind.awards[1].count = data.russiaHdiRankPlace;
        ind = this.indicatorsList.find((x) => x.id === 3);
        ind.count = data.hciPlace;
        ind.awards[0].country = data.hciWinner;
        ind.awards[1].count = data.russiaHciPlace;
        ind = this.indicatorsList.find((x) => x.id === 4);
        ind.count = data.gciPlace;
        ind.awards[0].country = data.gciWinner;
        ind.awards[1].count = data.russiaGciPlace;

        ind = this.indicatorsList.find((x) => x.id === 5);
        ind.count = data.gfcPlace;
        ind.awards[0].country = data.gfcWinner;
        ind.awards[1].count = data.russiaGfcPlace;
        ind = this.indicatorsList.find((x) => x.id === 6);
        ind.count = data.gdpPlace;
        ind.awards[0].country = data.gdpWinner;
        ind.awards[1].count = data.russiaGdpPlace;
        ind = this.indicatorsList.find((x) => x.id === 7);
        ind.count = data.egdiPlace;
        ind.awards[0].country = data.egdiWinner;
        ind.awards[1].count = data.russiaEgdiPlace;

        this.setRatingBorderStyleName(data);
      },
      setRatingBorderStyleName(data) {
        let ratingCount = 0;
        let value = 0;

        Object.keys(this.ratingPoints).forEach((key) => {
          let rating = this.ratingPoints[key].find((x) => x.ratings.includes(data[key]));
          if (rating) {
            ratingCount++;
            value += rating.value;
          }
        });

        if (ratingCount > 0) {
          value = value / ratingCount;
          if (value > 2.5) this.ratingStyleName = 'green';
          else if (value >= 1.5) this.ratingStyleName = 'yellow';
          else this.ratingStyleName = 'red';
        } else this.ratingStyleName = 'none';
      },
    },
    watch: {
      countryId: function () {
        this.loadRatings();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/modules/countryAnalyticsNew/styles/index';
  .country-ranking-title {
    margin-top: 30px;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }
  .rating-summary {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }
  .indicatorBlock {
    display: block;
    width: 40px;
    height: 20px;
    margin-right: 8px;
  }
</style>
