<template>
  <div class="barrierDiagramByQuarter">
    <h4 class="barrierDiagramByQuarter__title">{{ title }}</h4>
    <div class="barrierDiagramByQuarter__diagram-container">
      <ChartContainer>
        <!-- Здесь диаграмма -->
        <slot name="columnChart" />
      </ChartContainer>
      <div class="barrierDiagramByQuarter__card">
        <QuantityBarrierQuarterCard title="Ущерб от выявленных барьеров в год" :barrierData="barrierData" />
      </div>
    </div>
    <ChartContainer>
      <!-- Здесь диаграмма -->
      <slot name="lineChart" />
    </ChartContainer>
  </div>
</template>

<script>
  import ChartContainer from '@/components/ChartComponents/ChartContainer.vue';

  import QuantityBarrierQuarterCard from './QuantityBarrierQuarterCard.vue';

  export default {
    components: { QuantityBarrierQuarterCard, ChartContainer },
    props: {
      title: String,
      barrierData: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .barrierDiagramByQuarter {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__title {
      font: $fira-20;
      font-weight: 500;
    }

    &__diagram-container {
      display: flex;
      gap: 16px;

      @media (max-width: $tablet) {
        flex-direction: column;
      }
    }

    &__barrier-quantity {
      font: $fira-20;
      letter-spacing: -0.4px;
    }
  }
</style>
