<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка группы ролей</p>
    </div>
    <div>
      <div class="row">
        <div class="col-12" v-if="!item.id">
          <div class="form-group required">
            <ComponentInput
              v-model="item.name"
              label="Внутреннее название"
              required
              maxLength="50"
              placeholder="Введите название группы"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group required">
            <ComponentInput
              v-model="item.title"
              label="Название"
              required
              maxLength="250"
              placeholder="Введите название группы"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <ComponentInput
              type="select2"
              v-model="item.childRoleIds"
              label="Роли"
              multiple
              :select2Settings="select2SettingsMultiple"
              :select2Options="roles"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/roles';

  export default {
    name: 'RoleGroupCard',
    components: {
      ComponentInput,
      ButtonStock,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {
          isGroupRole: true,
        },
        roles: [],
        select2SettingsMultiple: Constants.select2SettingsMultiple,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadDictionaries() {
        API.search({ pageNumber: 1, pageSize: 0 }).then((response) => {
          this.roles = response.data.items
            .filter((x) => !x.isGroupRole && (!x.parentId || (x.parentId > 0 && x.parentId == this.id)))
            .map((x) => ({ id: x.id, text: x.title }));
        });
      },
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
            if (!this.item.isGroupRole)
              this.$router.push({ name: 'RoleCard', params: { id: this.item.id, action: 'edit' } });
          });
        } else {
          this.item = {
            isActive: true,
            organizationId: 1,
          };
        }
        this.item.isGroupRole = true;
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.update(this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            console.log(this.item);
            if (this.id > 0) {
              this.loadCard();
            } else {
              this.$router.push({ name: 'Roles' });
            }
          })
          .catch((error) => {
            console.error(error?.response?.data);
            var mess = error?.response?.data ? error?.response?.data : Constants.commonError;
            mess = mess
              .replace('Role name', 'Роль с внутренним названием')
              .replace('is already taken', 'уже используется');
            Constants.alert.fire('Сохранение', mess, 'error');
          });
      },
      close() {
        this.$router.back();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
