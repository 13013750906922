<template>
  <div class="table-sort__head">
    <div class="table-sort__row first-head">
      <div class="table-sort__th column-icon"></div>
      <div class="table-sort__th column-number_small">
        <p>№</p>
      </div>
      <div class="table-sort__th column-short-name" @click="changeSorting('orderByName')">
        <p :class="getClassSorting(filters.orderByName)">Краткое наименование</p>
      </div>
      <div class="table-sort__th column-addition-date" @click="changeSorting('orderByDat')">
        <p :class="getClassSorting(filters.orderByDat)">Дата добавления</p>
      </div>
      <div class="table-sort__th column-document">
        <p>Документ</p>
      </div>
    </div>
  </div>
  <div class="table-sort__row second-head">
    <div class="table-sort__th column-icon"></div>
    <div class="table-sort__th column-number_small"></div>
    <div class="table-sort__th column-short-name"></div>
    <div class="table-sort__th column-addition-date">
      <DateComponent v-model="filters.from" dateIcon modClass="filter-small" :upperLimit="upperLimitChangeFrom" />
      <DateComponent
        v-model="filters.to"
        dateIcon
        modClass="filter-small"
        :upperLimit="upperLimitTo"
        :lowerLimit="lowerLimitChangeTo"
      />
    </div>
    <div class="table-sort__th column-document">
      <SelectMultiComponentCheckbox
        mod="filter-small"
        v-model="filters.fileExtensions"
        placeholder="Все"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="getOptionsFileExtensionList"
        required
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment/moment';

  import DateComponent from '@/common/components/dateComponent.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import Constants from '@/common/constants';

  import { VALID_REFERENCE_FILE_EXTENSIONS } from '@/modules/countryАnalytics/utils/constants';

  export default {
    name: 'ReferenceInfoTableHeader',
    components: { SelectMultiComponentCheckbox, DateComponent },
    emits: ['filter'],
    data() {
      return {
        filters: {
          orderByName: 0,
          orderByDat: 1,
          fileExtensions: [],
          from: null,
          to: null,
        },
        upperLimitTo: new Date(),
      };
    },
    computed: {
      getOptionsFileExtensionList() {
        return VALID_REFERENCE_FILE_EXTENSIONS;
      },
      upperLimitChangeFrom() {
        if (this.filters.to) {
          return moment(this.filters.to, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitChangeTo() {
        return moment(this.filters.from, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
    methods: {
      getClassSorting(order) {
        if (!order) return Constants.sort.default;
        return order === 1 ? Constants.sort.ascending : Constants.sort.descending;
      },
      changeSorting(key) {
        const order = this.filters[key];
        this.resetSorting();
        this.filters[key] = order === 0 ? 1 : order === 1 ? 2 : 0;
      },
      resetSorting() {
        this.filters.orderByDat = 0;
        this.filters.orderByName = 0;
      },
    },
    watch: {
      filters: {
        handler(newValue) {
          this.$emit('filter', newValue);
        },
        deep: true,
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/table/table-sort.scss';

  .table-sort {
    &__row {
      padding: 0;
      justify-content: flex-start;
    }

    &__th {
      &.column-addition-date {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .column-document {
      :deep(.multiselect) {
        margin: 0;
        max-width: 164px;
      }
    }
  }

  :deep(.date-component-filter-small) {
    margin-top: 0;
  }
</style>
