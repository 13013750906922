import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    card: {
      stepParticipants: [],
      stepActions: [],
      isTemplate: true,
      stepResultDocumentIds: [],
      stepRequiredDocumentIds: [],
      stepLegalSourceIds: [],
      stepVideos: [],
      stepMaterials: [],
    },
    listOrganizations: [],
    listDocuments: [],
    listLegalSources: [],
  },
};

export const NAVIGATOR_STEP_CARD_GROUPS = 'navigatorStepCard';

export const NAVIGATOR_STEP_CARD_ACTIONS_NAME = {
  getStepCard: `${NAVIGATOR_STEP_CARD_GROUPS}/getStepCard`,
  updateStepCard: `${NAVIGATOR_STEP_CARD_GROUPS}/updateStepCard`,
  createStepCard: `${NAVIGATOR_STEP_CARD_GROUPS}/createStepCard`,
  getOrganizationList: `${NAVIGATOR_STEP_CARD_GROUPS}/getOrganizationList`,
  getDocumentList: `${NAVIGATOR_STEP_CARD_GROUPS}/getDocumentList`,
  getLegalSourceList: `${NAVIGATOR_STEP_CARD_GROUPS}/getLegalSourceList`,
};

export const NAVIGATOR_STEP_CARD_MUTATIONS_NAME = {
  setStepCard: 'setStepCard',
  setOrganizationList: 'setOrganizationList',
  setDocumentList: 'setDocumentList',
  setLegalSourceList: 'setLegalSourceList',
};

export const NAVIGATOR_STEP_CARD_GETTERS_NAME = {
  getCard: `${NAVIGATOR_STEP_CARD_GROUPS}/getCard`,
  getListOrganizations: `${NAVIGATOR_STEP_CARD_GROUPS}/getListOrganizations`,
  getListDocuments: `${NAVIGATOR_STEP_CARD_GROUPS}/getListDocuments`,
  getListLegalSources: `${NAVIGATOR_STEP_CARD_GROUPS}/getListLegalSources`,
};
