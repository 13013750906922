<template>
  <div class="container-page big container-page--no-indentation">
    <div class="title-block-top title-block-top--my-tasks">
      <h2 class="title title--h2 title--my-tasks">Задачи</h2>
      <div class="default-filter-container">
        <DefaultFilter :filterValue="currentFilter" @loadFilter="onLoadFilter" />
      </div>
      <div class="filter-wrap">
        <ButtonComponent @click="filterShow = !filterShow" v-if="isBoardView" class="button-redesign--filter">
          Фильтр
          <IconComponent name="filter" />
        </ButtonComponent>
      </div>
      <div class="btns">
        <ButtonComponent
          v-if="currEmployeeId > 0"
          @click="setOnlyMy"
          :class="[{ selected: isOnlyMy }, 'button-redesign--my-task']"
          >Только мои</ButtonComponent
        >
        <div class="switcher">
          <ButtonComponent @click="changeView(false)" :class="[{ selected: !isBoardView }, 'button-redesign--switch']"
            >Таблица</ButtonComponent
          >
          <ButtonComponent @click="changeView(true)" :class="[{ selected: isBoardView }, 'button-redesign--switch']"
            >Карточки</ButtonComponent
          >
        </div>
        <ButtonComponent
          v-if="canEdit"
          @click="
            dialogNn++;
            dialogState = true;
          "
          mod="gradient-bg"
          class="button-redesign--create-task"
          >Создать задачу</ButtonComponent
        >
      </div>
    </div>
  </div>

  <!-- filter -->
  <div class="container-page big" v-if="filterShow">
    <div class="cards-filter">
      <!-- input block -->
      <!-- date -->
      <div class="cards-filter__input-container">
        <p class="title">Контрольный срок</p>
        <div class="cards-filter__input-block">
          <p class="prefix">c</p>
          <div class="date">
            <dateComponent
              :clearable="true"
              modClass="modal-request"
              modPicker="rectangular"
              v-model="filterBoard.deadlineFrom"
            />
          </div>
          <IconComponent name="dateRange" />
        </div>
        <div class="cards-filter__input-block">
          <p class="prefix">по</p>
          <div class="date">
            <dateComponent
              :clearable="true"
              modClass="modal-request"
              modPicker="rectangular"
              v-model="filterBoard.deadlineTo"
            />
          </div>
          <IconComponent name="dateRange" />
        </div>
      </div>

      <!-- multiselect -->
      <div class="cards-filter__input-container">
        <ComponentInput
          label="Приоритет"
          placeholder="Выберите приоритет"
          :select2Settings="select2Settings"
          :select2Options="taskUrgencyList"
          v-model="filterBoard.taskUrgencyId"
          mod="rectangular"
          type="select2"
        />
      </div>

      <!-- multiselect -->
      <div class="cards-filter__input-container">
        <ComponentInput
          label="Исполнитель"
          placeholder="Выберите исполнителя"
          :select2Settings="select2Settings"
          :select2Options="employeeList"
          v-model="filterBoard.executorId"
          mod="rectangular"
          type="select2"
        />
      </div>

      <!-- textarea -->
      <div class="cards-filter__input-container">
        <ComponentInput
          label="Описание"
          placeholder="Введите описание"
          v-model="filterBoard.description"
          mod="rectangular"
          type="text"
        />
      </div>
      <div class="cards-filter__input-container checkboxInput">
        <ComponentInput
          id="hideStatus"
          label="Скрывать задачи в статусе 'Готова' старше 3 месяцев"
          v-model="filterBoard.isHideOld"
          type="checkbox"
          mod="checkbox-green"
        />
      </div>

      <div class="btns">
        <ButtonComponent @click="clearBoard" mod="gradient-bg" class="button-redesign--map-apply"
          >Сброс</ButtonComponent
        >
        <ButtonComponent @click="searchBoard">Искать</ButtonComponent>
      </div>
    </div>
  </div>

  <div class="tasks">
    <div class="grand-parent" v-if="!isBoardView">
      <div class="table-container-new table-container-new--full-page">
        <table class="stockTable">
          <thead>
            <tr class="first-head">
              <th class="task-column-interaction th-check"><IconComponent name="time" /></th>
              <th class="task-column-paragraph">
                <p>№ п/п</p>
              </th>
              <th class="task-column-task">
                <p>Задача</p>
              </th>
              <th class="task-column-urgency">
                <p>Срочность</p>
              </th>
              <th class="task-column-status">
                <p>Статус</p>
              </th>
              <th class="task-column-report">
                <p>Отчет об исполнении</p>
              </th>
              <th class="task-column-initiator">
                <p>Инициатор</p>
              </th>
              <th class="task-column-executor">
                <p>Исполнитель</p>
              </th>
              <th class="task-column-control">
                <p>Контрольный срок</p>
              </th>
            </tr>
            <tr class="second-head">
              <th class="task-column-interaction"></th>
              <th class="task-column-paragraph">
                <FilterInput
                  placeholder="№"
                  modClass="small"
                  v-model="filter.id"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-task">
                <FilterInput
                  placeholder="Введите название"
                  modClass="small"
                  v-model="filter.description"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-urgency">
                <FilterInput
                  :select2Options="taskUrgencyList"
                  placeholder="Все"
                  modClass="small"
                  type="select2"
                  v-model="filter.taskUrgencyId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-status">
                <FilterInput
                  :select2Options="taskStatusList"
                  placeholder="Все"
                  modClass="small"
                  type="select2"
                  v-model="filter.taskStatusId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-report">
                <FilterInput
                  placeholder=""
                  modClass="small"
                  v-model="filter.comment"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-initiator">
                <FilterInput
                  :select2Options="managerList"
                  placeholder="Все"
                  modClass="small"
                  type="select2"
                  v-model="filter.managerId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-executor">
                <FilterInput
                  :select2Options="employeeList"
                  placeholder="Все"
                  modClass="small"
                  type="select2"
                  v-model="filter.executorId"
                  :select2Settings="select2Settings"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="task-column-control">
                <div class="date-wrap">
                  <FilterInput
                    placeholder="дд.мм.гггг"
                    modClass="small date"
                    class="input-group-wrapper--date"
                    type="date"
                    v-model="filter.deadlineFrom"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                  <FilterInput
                    placeholder="дд.мм.гггг"
                    type="date"
                    modClass="small date"
                    class="input-group-wrapper--date"
                    v-model="filter.deadlineTo"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length">
            <tr v-for="item in page.items" @dblclick="editCard(item.id)" :key="item.id">
              <td class="task-column-interaction">
                <div class="d-flex justify-content-center">
                  <IconComponent @click="editCard(item.id)" class="mr-8" name="empty_pen" />
                  <IconComponent @click="deleteCard(item.id)" v-if="canDelete(item)" name="delete" />
                </div>
              </td>
              <td class="task-column-paragraph">{{ item.id }}</td>
              <td class="task-column-task">
                {{ item.name }}
                <div v-snip="3">{{ item.description }}</div>
              </td>
              <td class="task-column-urgency">
                <span :class="['urgency-line', getClass(item.taskUrgency)]">{{ item.taskUrgency.name }}</span>
              </td>
              <td class="task-column-status">{{ item.taskStatus.name }}</td>
              <td class="task-column-report">
                <div v-snip="3">
                  {{ item.comment }}
                </div>
                <div class="reportFiles">
                  <ButtonStock
                    :title="file.originalName"
                    class="reportFiles__file"
                    btnType="fileIcon"
                    iconName="fileText"
                    mode="icon"
                    v-for="file in item.reportFiles"
                    :key="file.id"
                    @click="downloadFile(file)"
                  />
                </div>
              </td>
              <td class="task-column-initiator">
                {{ item.managerFio }}
              </td>
              <td class="task-column-executor">
                {{ item.executorFio }}
                <br />
                {{ item.executorDopFio }}
              </td>
              <td class="task-column-control">{{ $momentFormat(item.deadline, 'DD MMMM YYYY HH:mm') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container-page big" v-if="page.pagesTotal > 1">
        <div>
          <PaginationRedesigned
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>

    <div class="container-page big" v-else-if="isBoardView">
      <div class="card-block-task">
        <div class="card-block-task__top-block">
          <div
            v-for="(status, index) in taskStatusList.filter((x) => x.id > 0)"
            :key="status.id"
            :class="['card-block-task__title', { active: activeColumn === index }]"
            @click="switchActiveColumn(index)"
          >
            {{ status.text }}
          </div>
        </div>
        <div class="card-block-task__bottom-block">
          <div
            v-for="(status, index) in taskStatusList.filter((x) => x.id > 0)"
            :key="status.id"
            :class="['card-block-task__wrap-task', { active: activeColumn === index }]"
          >
            <div class="tasks-item-new" v-for="item in tasks.filter((x) => x.taskStatusId == status.id)" :key="item.id">
              <div class="tasks-item-new__number">Задача № {{ item.id }}</div>
              <div class="tasks-item-new__name" v-if="item.name">{{ item.name }}</div>
              <div class="tasks-item-new__description" v-if="item.description">{{ item.description }}</div>
              <div class="tasks-item-new__coll-wrap">
                <div class="tasks-item-new__coll coll-12" v-if="item.managerFio">
                  <div class="tasks-item-new__label">Инициатор</div>
                  <div class="tasks-item-new__info">{{ item.managerFio }}</div>
                </div>
                <div class="tasks-item-new__coll coll-12" v-if="item.executorFio">
                  <div class="tasks-item-new__label">Исполнитель</div>
                  <div class="tasks-item-new__info">{{ item.executorFio }}</div>
                </div>
                <div class="tasks-item-new__coll coll-6" v-if="item.taskUrgency.name">
                  <div class="tasks-item-new__label">Срочность</div>
                  <div class="tasks-item-new__info">
                    <span :class="['urgency-line', getClass(item.taskUrgency)]">{{ item.taskUrgency.name }}</span>
                  </div>
                </div>
                <div class="tasks-item-new__coll coll-6" v-if="item.taskStatus.name">
                  <div class="tasks-item-new__label">Статус</div>
                  <div class="tasks-item-new__info">{{ item.taskStatus.name }}</div>
                </div>
              </div>

              <div class="tasks-item-new__date" v-if="item.deadline">
                <IconComponent name="time" />
                Срок {{ $momentFormat(item.deadline, 'DD MMMM YYYY HH:mm') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalComponent headType="blue" title="Создать задачу" @close="dialogState = false" v-model="dialogState">
    <IssueCardAdd v-bind:nn="dialogNn" :isEap="false" v-on:save="afterSaveCard" v-on:close="dialogState = false" />
  </ModalComponent>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  // Additional
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/issue';
  import IssueCardAdd from '../components/IssueCardAdd.vue';

  export default {
    // TODO заменить по возможности
    // eslint-disable-next-line
    name: 'tasks',
    components: {
      IconComponent,
      ModalComponent,
      dateComponent,
      FilterInput,
      ComponentInput,
      IssueCardAdd,
      DefaultFilter,
      PaginationRedesigned,
      ButtonComponent,
      ButtonStock,
    },
    mixins: [security, filtermanager],
    data() {
      return {
        activeColumn: 0,
        isBoardView: false,
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          id: '',
          description: '',
          comment: '',
          deadlineFrom: null,
          deadlineTo: null,
          executorId: null,
          taskStatusId: null,
          taskUrgencyId: null,
          managerId: null,
        },
        filterBoard: {
          deadlineFrom: null,
          deadlineTo: null,
          taskUrgencyId: null,
          executorId: null,
          managerId: null,
          comment: '',
          description: '',
          isHideOld: true,
        },
        filterNotEmpty: false,
        page: {},
        canEdit: false,

        select2Settings: Constants.select2Settings,
        taskTypeList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        taskStatusList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        taskUrgencyList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        employeeList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        managerList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],

        filterShow: false,
        tasks: [],

        loadBoardView: false,
        dialogState: false,
        dialogNn: 1,

        currEmployeeId: 0,
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.MpkTaskAccess);

      let profile = this.$store.state.auth?.oidc?.profile;
      if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
      this.currEmployeeId = profile?.employee?.id;
      this.canEdit = profile?.employee?.id > 0;
      if (this.canEdit) {
        this.checkPermission(Constants.Permissions.MpkTaskEdit).then((response) => {
          this.canEdit = response.data;
        });
      }

      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter.filter;
          this.filterBoard = filter.filterBoard;
        }
        this.filterNotEmpty = this.isFilterNotEmpty(this.filterBoard, ['isHideOld', 'pageNumber']);
        this.loadPage(false);
        this.loadEmployee();
        this.loadManager();

        Utils.loadSelectOptions('taskTypeList', this.taskTypeList);
        //Utils.loadSelectOptions("taskStatusList", this.taskStatusList);
        Utils.searchNsi('taskStatusList', {}).then((response) => {
          this.taskStatusList = response.data.slice(1);
        });
        Utils.loadSelectOptions('taskUrgencyList', this.taskUrgencyList);
      });
    },
    computed: {
      isOnlyMy() {
        if (!this.isBoardView) return this.filter.executorId == this.currEmployeeId;
        else return this.filterBoard.executorId == this.currEmployeeId;
      },
      currentFilter() {
        let filter = {
          filter: this.filter,
          filterBoard: this.filterBoard,
        };
        return filter;
      },
    },
    methods: {
      getClass(item) {
        const { id = 1 } = item;
        let className = 'pin-red';
        switch (id) {
          case 2:
            className = 'pin-yellow';
            break;
          case 3:
            className = 'pin-green';
            break;
        }
        return className;
      },

      downloadFile(file) {
        Utils.downloadFile(`/api/file/download?id=${file.id}`, file.originalName);
      },

      switchActiveColumn(index) {
        this.activeColumn = index;
      },

      setOnlyMy() {
        if (!this.isBoardView) {
          this.filter.executorId = this.isOnlyMy ? null : this.currEmployeeId;
          this.changeFilter();
        } else {
          this.filterBoard.executorId = this.isOnlyMy ? null : this.currEmployeeId;
          this.searchBoard();
        }
      },
      canDelete(item) {
        const profile = Object.assign({}, this.$store.state.auth?.oidc?.profile);
        if (profile?.roleIds != null && profile?.roleIds.includes(1)) return true;
        return item.createdById == profile?.id;
      },
      changeView(isBoard) {
        this.isBoardView = isBoard;
        if (this.isBoardView && !this.loadBoardView) this.searchBoard();
      },
      searchBoard() {
        this.saveFilter(this.currentFilter);
        this.filterNotEmpty = this.isFilterNotEmpty(this.filterBoard, ['isHideOld', 'pageNumber']);
        const params = Object.assign(this.filterBoard, { pageNumber: 1, pageSize: 0 });
        API.search(params).then((response) => {
          this.loadBoardView = true;
          this.tasks = response.data.items;
        });
      },
      clearBoard() {
        this.filterBoard = {
          deadlineFrom: null,
          deadlineTo: null,
          taskUrgencyId: null,
          executorId: null,
          managerId: null,
          comment: '',
          isHideOld: true,
          description: '',
        };
        this.filterShow = false;
        this.searchBoard();
      },
      loadEmployee() {
        API.getEmployeeList().then((response) => {
          if (response.data) {
            response.data.forEach((el) => {
              this.employeeList.push(el);
            });
          }
        });
      },
      loadManager() {
        API.getManagerList().then((response) => {
          if (response.data) {
            response.data.forEach((el) => {
              this.managerList.push(el);
            });
          }
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        const params = Object.assign(this.filter, this.request);
        API.search(params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.currentFilter);
        this.loadPage(false);
      },
      afterSaveCard() {
        this.dialogState = false;
        this.loadPage(false);
        if (this.isBoardView) this.searchBoard();
        else this.loadBoardView = false;
      },
      editCard(id) {
        this.$router.push({ name: 'IssueCard', params: { id: id, action: this.canEdit ? 'edit' : 'view' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
                this.loadBoardView = false;
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      onLoadFilter(item) {
        this.filter = item.filter;
        this.filterBoard = item.filterBoard;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  @import '../styles/card-block.scss';

  .filter-have-value {
    color: red;
  }

  .tasks {
    &-container {
      max-height: 612px;
      overflow-y: auto;
      padding-right: 3px;
    }
  }

  .stockTable {
    tbody {
      tr {
        cursor: pointer;
        transition: 0.3;

        &:hover {
          td {
            color: #0000ee;
          }
        }
      }
    }
  }

  .view {
    &-table {
      table {
        .icon {
          opacity: 0.5;
        }

        thead {
          th {
            padding: 12px !important;
            max-width: 155px;

            &:nth-of-type(1) {
              width: 60px;
            }

            input {
              width: 100%;
            }
          }
        }

        tbody {
          td {
            padding: 12px !important;

            &:nth-of-type(1) {
              height: 61.5px;
            }
          }
        }
      }
    }
  }

  .filter-wrap {
    margin-right: 19px;
  }

  .cards {
    &-filter {
      max-width: 980px;
      width: 100%;

      &__input {
        &-container {
          margin-bottom: 16px;

          &:nth-of-type(1) {
            display: flex;
            flex-wrap: wrap;

            .title {
              width: 100%;
              margin-bottom: 16px;
            }

            .cards-filter__input-block {
              position: relative;
              display: flex;
              align-items: flex-end;

              .date {
                max-width: 150px;
                width: 100%;
                margin-left: 8px;

                &:not(:last-of-type) {
                  margin-right: 8px;
                }
              }

              .icon {
                position: absolute;
                bottom: 12px;
                right: 16px;
                pointer-events: none;
              }
            }
          }

          textarea {
            width: 100%;
          }
        }
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-item {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        max-width: 274px;
        width: 100%;
        background: rgba(232, 232, 232, 0.3);
        padding: 18px 10px 0px 10px;

        .title-inner {
          margin-bottom: 14px;
        }
      }

      .tasks-container {
        max-height: 612px;
        overflow-y: auto;
        padding-right: 3px;
      }

      .tasks-item {
        font-family: 'Fira Sans', sans-serif;
        padding: 12px 18px;
        height: 220px;
        width: 100%;
        border: 2px solid black;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }

        &__name {
          margin-bottom: 8px;

          span {
            font-weight: 700;
          }
        }

        &__about {
          margin-bottom: 10px;

          /* 3 точие */
          overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          line-clamp: 4;
          box-orient: vertical;
        }

        &__date {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .icon {
            color: #757575;
            margin-right: 8px;
          }
        }

        &__footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .arrows {
            .arrow {
              display: block;
              width: 10px;
              height: 10px;
              border: 3px solid;
              border-color: black;
              border-bottom: none;
              border-right: none;
              transform: rotate(45deg);
            }
          }

          .author {
            display: flex;
            font-size: 12px;

            .date {
              font-size: 10px;
            }

            img {
              margin-left: 8px;
              width: 34px;
              height: 34px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }

        &__about,
        &__date {
          font-size: 12px;
        }

        &.red {
          border-color: #dd2a25;

          .tasks-item__footer .arrows .arrow {
            border-color: #dd2a25;
          }
        }

        &.yellow {
          border-color: #fcad30;

          .tasks-item__footer .arrows .arrow {
            border-color: #fcad30;
          }
        }

        &.green {
          border-color: #00c6a2;

          .tasks-item__footer .arrows .arrow {
            border-color: #00c6a2;
          }
        }
      }
    }
  }

  .switcher {
    padding: 4px;
    border-radius: 4px;
    background: $grey;
    margin: 0 16px;

    @media (max-width: $mobile) {
      margin: 0 0 8px;
      order: 1;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .reportFiles {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 8px 0 0 0;

    &__file {
      display: flex;
      color: $blue-2;
      font: $fira-16;
      line-height: normal;
      margin: 0 !important;
      align-items: flex-start;

      &__icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  .urgency-line {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    font-family: 'Fira Sans';

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      transform: translateY(-50%);
      background: $red-2;
    }

    &.pin-yellow {
      &::before {
        background: $yellow-2;
      }
    }

    &.pin-green {
      &::before {
        background: $green-2;
      }
    }
  }

  @media (max-width: 768px) {
    .cards-container {
      min-width: unset;
    }

    .cards-container-item {
      max-width: unset;
      margin-bottom: 46px;
    }
  }

  @media (max-width: 450px) {
    .tasks .btns {
      flex-wrap: wrap;
    }

    .cards-filter__input-container:nth-of-type(1) .cards-filter__input-block {
      margin-bottom: 8px;
    }
  }

  @media (max-width: 375px) {
    .cards-filter__input-container:nth-of-type(1) .cards-filter__input-block {
      .prefix {
        width: 18px;
      }
    }
  }
</style>
