// общие методы для компонентов FilterInput и ComponentInput
export default {
  methods: {
    select2OnSelect(o) {
      this.$emit('select2OnSelect', {
        value: o,
        additional: this.additionalData,
      });
    },
    fillInputParams() {
      switch (this.type) {
        case 'phone':
          this.placeholderInternal = '+7(___)___-__-__';
          this.maskInternal = '+7(999)999-99-99';
          break;
        case 'phoneCity':
          this.placeholderInternal = '+7(_____)___-__';
          this.maskInternal = '+7(99999)999-99';
          break;
        case 'snils':
          this.placeholderInternal = 'XXX-XXX-XXX XX';
          this.maskInternal = '999-999-999 99';
          break;
        case 'passportSerie':
          this.placeholderInternal = 'XXXX';
          this.maskInternal = '9999';
          break;
        case 'passportNum':
          this.placeholderInternal = 'XXXXXX';
          this.maskInternal = '999999';
          break;
        case 'birthCertNum':
          this.placeholderInternal = 'XXXXXX';
          this.maskInternal = '999999';
          break;
        case 'index':
          this.placeholderInternal = 'XXXXXX';
          this.maskInternal = '999999';
          break;
        case 'passportDep':
          this.placeholderInternal = 'XXX-XXX';
          this.maskInternal = '999-999';
          break;
        case 'time':
          this.maskInternal = '99:99';
      }
    },
    onPaste(event) {
      if (this.type == 'int') {
        var reg = new RegExp('^[0-9]+$', 'g');
        if (!reg.test(event.clipboardData.getData('text'))) {
          event.preventDefault();
          return false;
        }
      }

      return true;
    },
  },
  watch: {},
};
