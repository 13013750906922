<template>
  <div class="financial-politics-container" v-if="countryId">
    <CountryRanking :countryId="countryId" />
    <Budget :countryId="countryId" />
    <MonetaryPolicy :countryId="countryId" />
    <FinancialSector :countryId="countryId" />
  </div>
</template>

<script>
  import Budget from '@/modules/countryAnalyticsNew/components/FinancialPolitics/nested/Budget';
  import CountryRanking from '@/modules/countryAnalyticsNew/components/FinancialPolitics/nested/CountryRanking';
  import FinancialSector from '@/modules/countryAnalyticsNew/components/FinancialPolitics/nested/FinancialSector';
  import MonetaryPolicy from '@/modules/countryAnalyticsNew/components/FinancialPolitics/nested/MonetaryPolicy';

  export default {
    name: 'FinancialPoliticsControl',
    components: { CountryRanking, Budget, MonetaryPolicy, FinancialSector },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {};
    },
    created() {},
    methods: {},
  };
</script>

<style lang="scss" scoped></style>
