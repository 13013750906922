<template>
  <li class="contacts__card">
    <div @click="clickCard(index)">
      <img v-if="img" :src="require(`@/assets/images/${img}`)" alt="imagesEblem" class="contacts__card-img" />
      <h3 class="contacts__card-title">{{ title }}</h3>
      <span class="contacts__card-index">{{ number }}</span>
    </div>
  </li>
</template>

<script>
  export default {
    name: 'constactsCardComponent',
    props: {
      title: {
        type: String,
        default: '',
      },
      index: {
        type: [String, Number],
        default: '',
      },
      number: {
        type: [String, Number],
        default: '',
      },
      img: {
        type: String,
        default: '',
      },
    },
    methods: {
      clickCard(id) {
        this.$emit('click', id);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/colors.scss';
  .contacts {
    &__card {
      max-width: 100%;
      width: 100%;
      &:not(:first-child) {
        margin-top: 11px;
        min-height: 150px;
        max-width: calc(50% - 10px);
        width: 100%;
        div {
          img {
            max-width: 70px;
            height: 75px;
          }
        }
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 12px 22px 18px 22px;
      background: $white;
      transition: box-shadow 0.2s ease-in-out;
      div {
        text-align: center;
        text-decoration: none;
        cursor: pointer;
      }
      &:hover {
        -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
      }
    }
    &__card-img {
      max-width: 130px;
      width: 100%;
      object-fit: cover;
    }
    &__card-title {
      margin: 16px 0 10px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #000000;
    }
    &__card-index {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: rgba(33, 33, 37, 0.5);
    }
  }
</style>
